.percent {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* box-shadow: inset 0 0 50px #000; */
  /* background-color: #222; */
  z-index: 1000;
  /* color: white; */
  margin: 0 auto;

}
body.modal-open::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
.percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.percent .number h2 {
  color: #777;
  font-weight: 700;
  font-size: 40px;
  transition: 0.5s;
}

.percent .number h2 span {
  font-size: 24px;
  color: #777;
  transition: 0.5s;
}

.text {
  position: relative;
  margin-top: 20px;
  color: #777;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s;
}

.svg-circle {
  position: relative;
  width: 150px;
  height: 150px;
  z-index: 10000;
}

.svg-circle circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #191919;
  stroke-width: 10;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}

.svg-circle circle:nth-of-type(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.card-box:nth-child(1) .svg-circle circle:nth-of-type(2) {
  stroke-dashoffset: calc(440 - ((440 * 80) / 100));
  stroke: #57abf8;
}
