.rectangle {
  width: 70%;
  height: 200px;
  background: radial-gradient(#222634, #07080c) no-repeat center;
  margin: auto;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.image {
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.titleText {
  color: #FFFFFF;
  position: absolute;
  text-transform: capitalize;
}
.carousal {
  margin-left: 70px;
  margin-top: -50px;
}

