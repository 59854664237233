@media only screen and (max-width: 899px) {
  .dataView,
  .dataRow,
  .dataCell {
    display: block;
  }
  .dataRow {
    margin-bottom: 24px;
  }
  .dataRow.isFlex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .labelCell {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 900px) {
  .dataView {
    border-collapse: collapse;
    display: table;
    width: 100%;
  }
  .dataRow {
    display: table-row;
  }
  .dataCell {
    display: table-cell;
    vertical-align: top;
  }
  .labelCell {
    border-right: 1px solid rgba(112, 112, 112, 0.12);
    width: 40%;
  }
}

.labelTitle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.labelTitleText {
  font-weight: 600;
  line-height: 1.25;
  display: flex;
  align-items: center;
}

.labelDescription {
  font-family: Montserrat, sans-serif;
  color: #02071a;
  line-height: 1.75;
}

.labelTitleIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .dataCell.isToggle {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 15px;
  }
  .labelTitleText {
    font-size: 16px;
  }
  .labelDescription {
    font-size: 13px;
  }
  .labelTitleIcon {
    width: 48.1125px;
  }
  .labelTitleAddIcon {
    height: 24.056250000000002px;
    width: 24.056250000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dataCell {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .dataCell.isToggle {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 18.125px;
  }
  .labelDescription {
    font-size: 11.875px;
  }
  .labelTitleText {
    font-size: 18.125px;
  }
  .labelTitleIcon {
    width: 64.15px;
  }
  .labelTitleAddIcon {
    height: 32.075px;
    width: 32.075px;
  }
  .dataCell:nth-of-type(2) {
    padding-left: 32.075px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dataCell {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .dataCell.isToggle {
    --toggle-padding-top: (36px / 2);
    --toggle-height: 21.75px;
  }
  .labelDescription {
    font-size: 14.25px;
  }
  .labelTitleText {
    font-size: 21.75px;
  }
  .labelTitleIcon {
    width: 76.98px;
  }
  .labelTitleAddIcon {
    height: 38.49px;
    width: 38.49px;
  }
  .dataCell:nth-of-type(2) {
    padding-left: 38.49px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dataCell {
    padding-bottom: 21.375px;
    padding-top: 21.375px;
  }
  .dataCell.isToggle {
    --toggle-padding-top: (42.75px / 2);
    --toggle-height: 25.828125px;
  }
  .labelDescription {
    font-size: 16.921875px;
  }
  .labelTitleText {
    font-size: 25.828125px;
  }
  .labelTitleIcon {
    width: 91.41375px;
  }
  .labelTitleAddIcon {
    height: 45.706875000000004px;
    width: 45.706875000000004px;
  }
  .dataCell:nth-of-type(2) {
    padding-left: 45.706875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .dataCell {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .dataCell.isToggle {
    --toggle-padding-top: (48px / 2);
    --toggle-height: 29px;
  }
  .labelDescription {
    font-size: 19px;
  }
  .labelTitleText {
    font-size: 29px;
  }
  .labelTitleIcon {
    width: 102.62px;
  }
  .labelTitleAddIcon {
    height: 51.32px;
    width: 51.31px;
  }
  .dataCell:nth-of-type(2) {
    padding-left: 51.31px;
  }
}

.labelTitleAddIcon {
  cursor: pointer;
}
