.otpinput {
    width: 50px;
    height: 55px;
    border-radius: 10px;
    background-color: #31343e;
    border: '2px solid #6d6f76';
    color: '#ffffff';
    font-size: '20px';
    font-weight: '700';
    text-align: 'center';
}