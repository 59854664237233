.inputLabel {
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .inputLabel {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .inputLabel {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .inputLabel {
    font-size: 16px;
    margin-bottom: 13.59375px;
  }
  .inputFieldContainer,
  .inputField {
    border-radius: 5px;
  }
  .inputFieldContainer {
    height: 45px;
  }
  .inputFieldContainer.loading .inputField {
    padding-right: 45px !important;
  }
  .inputFieldContainer.passwordInput:not(.loading) .inputField {
    padding-right: 40px !important;
  }
  .inputFieldContainer.passwordInput.loading .inputField {
    padding-right: 85px !important;
  }
  .inputField {
    font-size: 13px;
  }
  .inputField,
  .passwordVisibilityToggle {
    padding: 0 11px;
  }
  .passwordVisibilityToggleIcon {
    width: 18px;
  }
  .inputMessage {
    font-size: 9px;
    margin-top: 11px;
  }
  .spinnerContainer {
    margin-right: 10.78125px !important;
  }
  .spinnerContainer.loading {
    height: 23.4375px !important;
    width: 23.4375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .inputLabel {
    font-size: 21.875px;
    margin-bottom: 18.125px;
  }
  .inputFieldContainer,
  .inputField {
    border-radius: 6.25px;
  }
  .inputFieldContainer {
    height: 56.25px;
  }
  .inputFieldContainer.loading .inputField {
    padding-right: 56.25px !important;
  }
  .inputFieldContainer.passwordInput:not(.loading) .inputField {
    padding-right: 60.625px !important;
  }
  .inputFieldContainer.passwordInput.loading .inputField {
    padding-right: 116.875px !important;
  }
  .inputField {
    font-size: 14.375000000000002px;
    padding-right: 56.25px !important;
  }
  .inputField,
  .passwordVisibilityToggle {
    padding: 0 21.25px;
  }
  .passwordVisibilityToggleIcon {
    width: 18.125px;
  }
  .inputMessage {
    font-size: 11.25px;
    margin-top: 14.375000000000002px;
  }
  .spinnerContainer {
    margin-right: 12.5px !important;
  }
  .spinnerContainer.loading {
    height: 31.25px !important;
    width: 31.25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .inputLabel {
    font-size: 26.25px;
    margin-bottom: 21.75px;
  }
  .inputFieldContainer,
  .inputField {
    border-radius: 7.5px;
  }
  .inputFieldContainer {
    height: 67.5px;
  }
  .inputFieldContainer.loading .inputField {
    padding-right: 67.5px !important;
  }
  .inputFieldContainer.passwordInput:not(.loading) .inputField {
    padding-right: 64.25px !important;
  }
  .inputFieldContainer.passwordInput.loading .inputField {
    padding-right: 131.75px !important;
  }
  .inputField {
    font-size: 17.25px;
    padding-right: 67.5px !important;
  }
  .inputField,
  .passwordVisibilityToggle {
    padding: 0 25.5px;
  }
  .passwordVisibilityToggleIcon {
    width: 21.75px;
  }
  .inputMessage {
    font-size: 13.5px;
    margin-top: 17.25px;
  }
  .spinnerContainer {
    margin-right: 15px !important;
  }
  .spinnerContainer.loading {
    height: 37.5px !important;
    width: 37.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .inputLabel {
    font-size: 31.171875000000004px;
    margin-bottom: 25.828125px;
  }
  .inputFieldContainer,
  .inputField {
    border-radius: 8.90625px;
  }
  .inputFieldContainer {
    height: 80.15625px;
  }
  .inputFieldContainer.loading .inputField {
    padding-right: 80.15625px !important;
  }
  .inputFieldContainer.passwordInput:not(.loading) .inputField {
    padding-right: 68.32812px !important;
  }
  .inputFieldContainer.passwordInput.loading .inputField {
    padding-right: 148.48438px !important;
  }
  .inputField {
    font-size: 20.484375px;
    padding-right: 80.15625px !important;
  }
  .inputField,
  .passwordVisibilityToggle {
    padding: 0 30.28125px;
  }
  .passwordVisibilityToggleIcon {
    width: 25.828125px;
  }
  .inputMessage {
    font-size: 16.03125px;
    margin-top: 20.484375px;
  }
  .spinnerContainer {
    margin-right: 17.8125px !important;
  }
  .spinnerContainer.loading {
    height: 44.53125px !important;
    width: 44.53125px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .inputLabel {
    font-size: 35px;
    margin-bottom: 29px;
  }
  .inputFieldContainer,
  .inputField {
    border-radius: 10px;
  }
  .inputFieldContainer {
    height: 90px;
  }
  .inputFieldContainer.loading .inputField {
    padding-right: 90px !important;
  }
  .inputFieldContainer.passwordInput:not(.loading) .inputField {
    padding-right: 71.5px !important;
  }
  .inputFieldContainer.passwordInput.loading .inputField {
    padding-right: 161.5px !important;
  }
  .inputField {
    font-size: 23px;
    padding-right: 90px !important;
  }
  .inputField,
  .passwordVisibilityToggle {
    padding: 0 34px;
  }
  .passwordVisibilityToggleIcon {
    width: 29px;
  }
  .inputMessage {
    font-size: 18px;
    margin-top: 23px;
  }
  .spinnerContainer {
    margin-right: 20px !important;
  }
  .spinnerContainer.loading {
    height: 50px !important;
    width: 50px !important;
  }
}

.inputFieldContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #31343e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.inputField {
  background-color: transparent;
  border: 2px solid #31343e;
  color: #ffffff;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.inputField:focus {
  border-color: #6d6f76;
}

.inputField::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.inputField:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.inputField::-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.inputField::placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.passwordVisibilityToggleIcon {
  fill: #fff8 !important;
}

.passwordVisibilityToggle {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media all and (hover: hover) {
  .passwordVisibilityToggle:hover .passwordVisibilityToggleIcon {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media all and (pointer: coarse) {
  .passwordVisibilityToggle:active .passwordVisibilityToggleIcon, .passwordVisibilityToggle:target .passwordVisibilityToggleIcon {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

.inputMessage {
  color: #ffffff;
  display: block;
}

.spinnerContainer {
  overflow: hidden;
  position: absolute !important;
  right: 0;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.spinnerContainer.loading {
  opacity: 1;
}

.spinnerContainer:not(.loading) {
  height: 0 !important;
  opacity: 0;
  width: 0 !important;
}

.spinner {
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  height: calc(100% - 1px) !important;
  position: absolute !important;
  width: calc(100% - 1px) !important;
}

.spinner:not(.animatedSpinner) {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.animatedSpinner {
  -webkit-animation-duration: 550ms;
          animation-duration: 550ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: animated-spinner;
          animation-name: animated-spinner;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-left-color: transparent !important;
}

@-webkit-keyframes animated-spinner {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}

@keyframes animated-spinner {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}
