.page {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  color: #fff;
  position: relative;
  width: 100%;
}

.page *, .page {
  font-family: 'Montserrat', sans-serif !important;
}

.pageContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-right: 40px;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .pageContent {
    max-width: 1400px;
  }
}

.mainContent {
  margin: auto;
  max-width: 1920px !important;
  width: 100%;
}
