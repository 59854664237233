.header {
  position: absolute;
  width: 100%;
  height: 40px; 
  z-index: 9;
}
.backButton {
  margin: 0 20px 0 0;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Montserrat';
}

.circularProgress {
  /* display: inline-block;
  vertical-align: middle; */
  position: fixed;
  left: 50%;
  top: 50%;
  animation: none !important;
  transform: translate(-50%,-50%);
  
}

.colorChangePlay  {
  fill: #909298;
 }
 
 .colorChangePlay:hover {
   fill: #fff;
 }