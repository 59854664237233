.plan {
    padding: 30px;
    border-radius: 15px;
    color: #1D263A;
    background-color: #fff;
    transition: all 0.5s;
}
.tickster{
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
.feat{
    font-size: 14px;
    font-weight: 600
  }
.plan:hover {
    transform: translate(0, -10px);
    transition: all 0.5s;
}

.plan-blue {
    color: #fff;
    background-color: #0075FF;
}

.planHead {
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* padding-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10); */
    font-family: Montserrat-Regular;
}

.planName p {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.planPrice p {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.planPrice {
    text-align: right;
}

.planName span,
.planPrice span {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.planBody {
    width: 100%;
    padding: 25px 0;
    margin: 0;
    font-family: Montserrat-Regular;
}

.planBody li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    list-style: none;
    margin-bottom: 10px;
    grid-gap: 10px;
    grid-gap: 0;
    gap: 0;
    width: 160px;
}
.buyNow:hover{
    box-shadow: 0 0 10px black !important;
  }
  .buyNow{
    width: 100% !important;
    display: flex !important;;
    margin: 10px 0 0 0 !important;
    padding: 10px 28px !important;
    height: auto !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: none !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 35px !important;
    font-size: 15px !important;
    text-align: center !important;
    padding: 8px 28px !important;
    color: black !important;
    text-decoration: none !important;
    border: 1px solid black !important;
    background-color: transparent !important;
    transition: all 0.3s !important;
    cursor: pointer !important;
  }
.trail:hover{
    box-shadow: 0 0 10px #002E9A;
    background-image: linear-gradient(90deg, #0185FF 0, #0139F7);
  }
  .trail{
    width: 100% !important;
    display: flex !important;;
    margin: 10px 0 0 0 !important;
    padding: 10px 28px !important;
    height: auto !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 35px !important;
    font-size: 15px !important;
    text-align: center !important;
    padding: 8px 28px !important;
    color: white !important;
    text-decoration: none !important;
    background-image: linear-gradient(90deg, #0185FF 0, #0139F7);
    transition: all 0.3s !important;
    cursor: pointer !important;
  }
.planBtn {
    width: 100%;
    display: block;
    margin: 0;
    padding: 10px 28px;
    background: #0075FF;
    box-shadow: none;
    border-radius: 35px;
    font-weight: 400;
    font-size: 15px;
    color: white;
    text-align: center;
    text-transform: none;
}

@media only screen and (max-width: 1024px) {
    .planName p {
        font-size: 22px !important;
    }

    .planPrice p {
        font-size: 28px !important;
    }
    
}