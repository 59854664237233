.bigframe {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.main {
  background-color: rgb(255, 255, 255);
  height: 80vh;
  width: 60%;
  position: fixed;
  z-index: 4500000000;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
  border-radius: 10px;
  animation: slideInFromTop 0.5s ease-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  100% {
    opacity: 1;
    position: fixed;
    transform: translate(-50%, -70%);
  }
}
.backArrow {
  fill: black;
}

.ultimate {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 700;
  color: #373a53;
  margin: 20px auto 26px auto;
}

.topbtn {
  color: rgb(255, 255, 255) !important;
  background-color: #707070 !important;
  border: none;
  border-radius: 14px !important;
  margin: 10px !important;
  min-width: 10px !important;
  padding: 5px 10px !important;
  font-size: 16px;
  font-family: "Poppins";
  text-transform: capitalize;
  cursor: pointer;
}

/* .img{ 
    width:  1x;
    height:  600px;
} 
.image5{
    width:  1x;
    height:  600px;
    


} */
.box {
  text-align: center;
  /* height: 300px;
    width: 400px; */
  margin-top: 200px;
  z-index: 9;
  /* box-shadow: -99px -11px 22px #ffffff; */
  /* background-image: linear-gradient(to top, #fcfcfc, #fdfdfd, #fdfdfd, #fefefe, #fefefe, #e7e7e7, #d0d0d0, #b9b9b9, #878787, #585858, #2e2e2e, #000000);; */
}

.textbox {
  position: relative;
  font-weight: bold;
  /* margin-top: 100px; */
  padding: 37px 0px 20px 0px;
  color: black;
  background-image: linear-gradient(
    to top,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff83,
    #ffffff00
  );
  text-transform: none;
}

.upload {
  color: #ea5973;
  margin: auto;
  font-size: 19px;
  font-family: "Poppins";
  font-weight: 600;
}

.stream {
  font-size: 19px;
  font-family: "Poppins";
  font-weight: 600;
  margin: 30px auto;
  color: #373a53;
}

.featuresLabel {
  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins";
  margin-top: 16px;
  color: black;
}

.cloud {
  font-weight: 350;
  font-size: 13px;
  font-family: "Poppins";
  margin-top: 1px;
  color: black;
}

.more {
  font-weight: 400;
  margin-top: 16px;
  font-size: 15px;
  color: black;
}

.btn {
  font-weight: 100px;
  margin-top: -10px;
  border: none;
}

.skip {
  color: rgb(255, 255, 255);
  background-color: rgb(94, 91, 91);
  border: none;
  border-radius: 25px;
  width: 50px;
  cursor: pointer;
  z-index: 5;
  margin-top: 10px;
  position: relative;
  /* float: right; */
  margin-left: auto;

  margin-right: 15px;
  cursor: pointer;
}

#path_81 {
  fill: white;
  background-color: rgb(94, 91, 91);
  border-radius: 15px;
  width: 30px;
  margin-left: 15px;
  height: 30px;
  cursor: pointer;
}

.continue {
  width: 30%;
  margin: auto;
  background-color: #5aa5ef;
  border: none;
  color: white;
  height: 45px;
  font-family: "montserrat";
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}
.continue:disabled {
  opacity: 0.8;
  cursor: default;
}
@media only screen and (max-width: 600px) {
  .main {
    overflow-y: auto;
    height: 80vh;
    width: 95%;
    position: fixed;
    z-index: 4500000000;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -70%);
  }
  .ultimate {
    font-size: 22px;
    margin: 20px auto 26px auto;
  }
}

.pricingLabel {
  color: #5aa5ef;
  font-size: 1.5rem;
}

.videoPlayer {
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: bottom;
}
