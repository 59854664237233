@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');
.PPLLC .lst-kix_list_2-6 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-7 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_2-4 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-5 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-8 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_2-8 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-0 > li:before {
  content: '\0025cf  ';
}
.PPLLC ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-3 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-0 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-2 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-1 > li:before {
  content: 'o  ';
}
.PPLLC .lst-kix_list_1-2 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_1-8 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-4 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-3 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-4 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-7 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-5 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-6 > li:before {
  content: '\0025aa  ';
}
.PPLLC li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.PPLLC .lst-kix_list_2-0 > li:before {
  content: '\0025cf  ';
}
.PPLLC .lst-kix_list_2-1 > li:before {
  content: 'o  ';
}
.PPLLC .lst-kix_list_1-8 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-2 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-3 > li:before {
  content: '\0025aa  ';
}
.PPLLC ol {
  margin: 0;
  padding: 0;
}
table td,
.PPLLC table th {
  padding: 0;
}
.PPLLC .c9 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c7 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c5 {
  /* background-color: #ffffff; */
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c1 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.PPLLC .c4 {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.PPLLC .c0 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c18 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}

.PPLLC .c19 {
  text-decoration: underline;
}
.PPLLC .c20 {
  margin-left: 20pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c27 {
  vertical-align: baseline;
  font-style: normal;
}
.PPLLC .c15 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c17 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c14 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c2 {
  font-size: 10pt;
  font-family: 'Arial';
  /* color: #000000; */
  font-weight: 400;
}
.PPLLC .c8 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.PPLLC .c12 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.PPLLC .c13 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-left: 0pt;
}
.PPLLC .c16 {
  padding: 0;
  margin: 0;
}
.PPLLC .c10 {
  height: 12pt;
}
.PPLLC .c3 {
  font-style: italic;
}
.PPLLC .title {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC li {
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.PPLLC p {
  margin: 0;
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.PPLLC h1 {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h2 {
  padding-top: 0pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h3 {
  padding-top: 14pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h4 {
  padding-top: 12pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h5 {
  padding-top: 11pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h6 {
  padding-top: 10pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
