.formSection {
  display: block;
}

.title {
  color: #60676d;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .title {
    font-size: 11.875px;
    line-height: 16.25px;
    margin-bottom: -5px;
    margin-right: 30px;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .title {
    font-size: 14.25px;
    line-height: 19.5px;
    margin-bottom: -6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .title {
    font-size: 16.921875px;
    line-height: 23.15625px;
    margin-bottom: -7.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .title {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: -8px;
  }
}

.content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media only screen and (max-width: 767px) {
  .content {
    gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .content {
    gap: 24px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content {
    gap: 9.5px;
    margin-top: 27px;
  }
}

@media only screen and (min-width: 1710px) {
  .content {
    gap: 15px;
    margin-top: 32px;
  }
}
