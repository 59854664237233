.timer {
  color: #fff !important;
  text-align: center;
  padding-top: 10px;
}

.notifyText {
  padding: 11px;
  font-size: 20px;
  text-align: center;
}

.timer button {
  color: #fff !important;
  background-color: #5aa5ef !important;
  border-radius: 5px !important;
}

.otpWrapper {
  display: flex;
  justify-content: space-between;
}

.backLink {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .backLink:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .backLink:active, .backLink:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .backLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .backLink {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .backLink {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .backLink {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backLink {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .backLink {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.skipDiv {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.skipDiv:hover {
  color: #707070;
}