/* .bgLayer, */
.bgDarkLayer {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.bgDarkLayer {
  background-color: rgba(0, 0, 0, 0.25);
}
.centerCirlce {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 899px) {
  .page {
    padding-bottom: 0 !important;
  }
  .containerx {
    /* width: 60% !important; */
    width: 100% !important;
    height: 70%;
  }
}

.pageContainer {
  background: #000000;
}

@media only screen and (max-width: 899px) {
  .main {
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.previewContainer {
  min-width: 100%;
}

.topContainer,
.bottomContainer {
  width: 100%;
  /* display:flex; */
  justify-content: center;
}

.topContainer {
  position: relative;
  /* padding-bottom:0.5em; */
  /* height: 75%; */
}

.bottomContainer {
  background-color: #000000;
  align-items: flex-start;
  /* padding-top:0.5em; */
  /* height: 25%; */
  padding-left: 100px;
  padding-right: 100px;
}

.bgLayer {
  /* background-attachment: fixed; */
  background-color: #000000;
  position: relative;
  /* width: 100%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset; */
  /* border-radius: 0vw 3vw 3vw 0vw; */
}
.image_container {
  position: relative;
  display: inline-block; /* or block, depending on your layout */
}
.image_container img {
  display: block;
  width: 100%;
  height: auto;
}
.gradient_overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1));
  /* Gradients for both right-to-left and bottom-to-top */
}
.containerx {
  position: absolute;
  right: 0;
  width: 45%;
  aspect-ratio: 19 / 12;
  overflow: hidden;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.backImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.backButtonBlock {
  /* position: relative; */
  margin-left: 90px;
  /* margin-top: 40px; */

  /* left: 2%;
  top: 5%; */
  cursor: pointer;
}

.videoBackButton {
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  width: 50px;
  margin-top: 50px;
}

.backText {
  vertical-align: middle;
  font-size: 28px;
  padding: 15px;
}

.videoDetailsContainer {
  color: #fff;
  /* width: 60%; */
  top: 25%;
  position: absolute;
  left: 10%;
  /* margin-left: 116px; */
  /* margin-top: 100px; */
}

@media only screen and (max-width: 899px) {
  .videoDetailsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* min-height: 100vh !important; */
  }

  .backButtonBlock {
    margin-left: 30px;
  }

  .bottomContainer {
    padding-left: 30px;
    padding-right: 100px;
  }

  @media only screen and (max-width: 899px) {
    .bottomContainer {
      padding-left: 50px;
      display: none;
    }

    .topContainer {
      height: 100vh;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 1622px) {
    .bottomContainer {
      background-color: #000000;
      align-items: flex-start;
      /* padding-top:0.5em; */
      /* height: 25%; */
      padding-left: 100px;
      margin-top: 10px;
    }
  }

  .videoDetails {
    padding-left: 30px !important;
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .videoDetailsContainer {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .videoDetailsContainer {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .videoDetailsContainer {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .videoDetailsContainer {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .videoDetailsContainer {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .videoDetailsContainer {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .videoDetailsContainer {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1710px) {
      .videoDetailsContainer {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .videoDetailsContainer {
        padding-bottom: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .videoDetailsContainer {
        padding-bottom: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .videoDetailsContainer {
        padding-bottom: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .videoDetailsContainer {
        padding-bottom: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .videoDetailsContainer {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .videoDetailsContainer {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .videoDetailsContainer {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1710px) {
      .videoDetailsContainer {
        padding-bottom: 0;
      }
    }
  }

  .videoPlayButton {
    display: flex !important;
    width: 230px;
  }
}

.videoTitle {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  margin-bottom: 3%;
}

@media only screen and (max-width: 899px) {
  .videoTitle {
    /* margin-bottom: auto; */
    font-size: 22.400000000000002px;
  }

  .videoTitle {
    left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .videoTitle {
    font-size: 27.400000000000002px;
    z-index: 5;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoTitle {
    font-size: 24.937500000000004px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoTitle {
    font-size: 29.25px;
    /* margin-right: 45%; */
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoTitle {
    font-size: 26.50000000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoTitle {
    font-size: 33.78125000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1710px) {
  .videoTitle {
    font-size: 35px;
    margin-right: 50%;
  }
}

.videoDetails {
  display: flex;
  bottom: 0;
  width: 100%;
  color: #fff;
  /* position: absolute; */
  padding-left: 100px;
  flex-direction: column;
  top: 350px;
  margin-top: 8%;
  /* margin-bottom: 20px; */
}

.videoActions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* @media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoActions {
    margin-top: 10px;
   
   
  }
} */

/* @media only screen and (min-width: 1490px) {
  .videoActions {
    margin-top: -0px;
  }
} */

/* @media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoActions {
    margin-top: -60px;
  }
} */

.videoPlayButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 600;
  position: relative;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .videoPlayButton {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPlayButton {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
    width: 150px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPlayButton {
    font-size: 16.25px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoPlayButton {
    font-size: 19.5px;
    height: 55.5px;
    padding: 0 34.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
  }
}

/* @media only screen and (min-width: 973px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
    margin-top: 150px;
  }
} */
/* @media only screen and (width: 1622px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
    margin-top: 150px;
  }
} */
@media only screen and (min-width: 1710px) {
  .videoPlayButton {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
  }
}

/* @media only screen and (min-width: 973px) and (max-width: 1622px) {
  .videoPlayButton {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
    margin-top: -150px;
  }
} */
@media only screen and (max-width: 767px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPlayIcon {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoPlayIcon {
    height: 27px;
    margin-right: 17.25px;
    width: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPlayIcon {
    height: 32.0625px;
    margin-right: 20.48438px;
    width: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoPlayIcon {
    height: 36px;
    margin-right: 23px;
    width: 36px;
  }
}

.alignButton {
  margin-left: 1%;
}

@media only screen and (min-width: 900px) {
  .header {
    display: none;
  }
}

.videoAddButton {
  position: relative;
}

.videoAddButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 1px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .videoAddButton {
    border-width: 2px;
    font-size: 22.4px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoAddButton {
    border-width: 2px;
    font-size: 26.25px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoAddButton {
    border-width: 3px;
    font-size: 35px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoAddButton {
    border-width: 3px;
    font-size: 42px;
    height: 55px;
    width: 55px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoAddButton {
    border-width: 4px;
    font-size: 49.875px;
    height: 56.125px;
    width: 56.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoAddButton {
    border-width: 4px;
    font-size: 56px;
    height: 68px;
    width: 68px;
  }
}

.videoInfo {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .videoInfo {
    font-size: 15px;
    /* margin-left: -20px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoInfo {
    font-size: 15px;
    /* margin-left: -45px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoInfo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoInfo {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoInfo {
    font-size: 20px;
  }
}

.videoInfoSeparator {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 7.5px;
    width: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoInfoSeparator {
    height: 15px;
    margin: 0 9px;
    width: 2px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoInfoSeparator {
    height: 17.8125px;
    margin: 0 10.6875px;
    width: 3px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoInfoSeparator {
    height: 20px;
    margin: 0 12px;
    width: 3px;
  }
}

.videoDescription {
  font-weight: 600;
  line-height: 0.045em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .videoDescription {
    font-size: 14px;
    /* margin-top: 15px; */
    line-height: 1.25;
    /* margin-left: -20px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoDescription {
    font-size: 14px;
    margin-right: 20%;
    /* margin-top: 15.4375px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoDescription {
    font-size: 15px;
    margin-right: 30%;
    /* margin-top: 10.250px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoDescription {
    font-size: 15px;
    /* margin-right: 45%; */
    margin-right: 50%;
    /* margin-top: 6.5px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoDescription {
    font-size: 15.375px;
    margin-right: 45%;
    /* margin-top: 10.53125px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1710px) {
  .videoDescription {
    font-size: 24px;
    margin-right: 45%;
    /* margin-top: 50px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoInfoWrap {
    display: block;
  }
}

.loadingText {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
}

.thumbnailDiv {
  position: fixed;
  top: 0;
  right: 25px;
  width: 35%;
  height: 40%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
}

@media only screen and (max-width: 767px) {
  .thumbnailDiv {
    right: 4px;
    margin-top: 5px;
    width: 300px;
    height: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .thumbnailDiv {
    right: 5px;
    margin-top: 10px;
    width: 350px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .thumbnailDiv {
    right: 20px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .thumbnailDiv {
    right: 20px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .thumbnailDiv {
    /* right:20px;  */
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .thumbnailDiv {
    right: 1px;
  }
}

/* .react-multiple-carousel__arrow--right{
  margin-left: 100px;
} */

.videoInfoWrap {
  margin-top: 5px;
}
