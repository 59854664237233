.devices {
    margin: 0rem auto;
    max-width: 1280px;
    position: relative;
  }
  
  @media only screen and (max-width: 767px) {
    .devices {
      max-width: 512px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .devices {
      max-width: 900px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .devices {
      max-width: 1280px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .devices {
      max-width: 1408px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .devices {
      max-width: 1536px;
    }
  }
  
  .devicesTitle {
    font-weight: 700;
    margin-top: 24px;
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
    .devicesTitle {
      font-size: 25px;
      margin-bottom: 33.599999999999994px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .devicesTitle {
      font-size: 28px;
      margin-bottom: 39.375px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .devicesTitle {
      font-size: 30px;
      margin-bottom: 52.5px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .devicesTitle {
      font-size: 34px;
      margin-bottom: 62.99999999999999px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .devicesTitle {
      font-size: 38px;
      margin-bottom: 74.8125px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .devicesTitle {
      font-size: 40px;
      margin-bottom: 84px;
    }
  }


  .deviceList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  
    @media only screen and (max-width: 767px) {
      .deviceNameList {
        display: none;
      }
    }

  .deviceItem {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
    .deviceItem {
      margin-bottom: 24px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .deviceItem {
      -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
    }
  }
  
  .deviceFrame {
    color: #fff;
    height: 180px;
    max-width: 200px;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center bottom;
    object-position: center bottom;
    position: relative;
    width: 100%;
  }
  
  .deviceCategory {
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    color: #CACACA;
  }
  
  @media only screen and (max-width: 1440px) {
    .deviceCategory {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    .deviceCategory {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 767px) {
    .deviceFrame {
      width: 100px;
    }
  }
  
  @media only screen and (max-width: 1440px) {
    .deviceCategory {
      margin: 15.28125px 0 0 0;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .deviceCategory {
      margin: 44.375px 0 35.625px 0;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .deviceCategory {
      margin: 53.25px 0 42.75px 0;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .deviceCategory {
      font-size: 26.9375px;
      margin: 63.234375px 0 50.765625px 0;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .deviceCategory {
      font-size: 30px;
      margin: 71px 0 57px 0;
    }
  }
  
  .deviceNameList {
    position: relative;
  }
  
  .deviceName {
    color: #CECECE;
    /* font-weight: 300; */
  }
  
  @media only screen and (max-width: 1199px) {
    .deviceName {
      font-size: 19px;
      margin-bottom: 3px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .deviceName {
      font-size: 18.5px;
      margin-bottom: 3.33px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .deviceName {
      font-size: 21.59375px;
      margin-bottom: 3.7px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .deviceName {
      font-size: 25px;
      margin-bottom: 4px;
    }
  }
  