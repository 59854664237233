.stepStatus {
  color: #fff;
  margin-bottom: 1em;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .stepStatus {
    text-align: center;
  }
}
