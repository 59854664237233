.progressBoxWrap {
  /* bottom: 0%;
  right: 0; */
  /* position: fixed;
  height: 200px;
  width: 300px;
  opacity: 1; */
  /* z-index: 1000; */
  outline: none;
  z-index: 1000;
  box-sizing: border-box;
  max-width: 384px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 40%);
  margin: 48px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 384px;
  max-height: 490px;
  overflow: hidden;
  -webkit-animation: slide-up 450ms cubic-bezier(0, 0, 0.2, 1);
  animation: slide-up 450ms cubic-bezier(0, 0, 0.2, 1);
}

.header {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #000;
  color: #fff;
  justify-content: space-between;
}

.headerText {
  font-size: 14px;
}

.icon {
  /* margin-right: 20px; */
  cursor: pointer;
}

.fileName {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 14px;
}

.fileItem {
  padding: 5px 8px 0;
  /* display: grid;
  grid-template-columns: 30px 1fr 120px 25px; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.uploadProgress {
  color: #606060;
  font-size: 14px;
}

.editIcon {
  cursor: pointer;
}

.statusIcon {
  color: green;
}
.errorMsg {
  font-size: 12px;
  color: red;
}

.errorIcon {
  color: red;
}