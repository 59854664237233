.cardNumber {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  border: 1px solid #bdc4c9 !important;
  grid-row: 1 / 2;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

.expirationDate {
  margin-top: 10px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  border: 1px solid #bdc4c9 !important;
  grid-column: 1 / 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

@media only screen and (max-width: 419px) {
  .expirationDate {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
  }
}

.cvvNumber {
  margin-top: 10px;
  border: 1px solid #bdc4c9 !important;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

@media only screen and (max-width: 419px) {
  .cvvNumber {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3 / 4 !important;
  }
}
