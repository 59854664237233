.cardNumber {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.expirationDate {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

@media only screen and (max-width: 419px) {
  .expirationDate {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
  }
}

.cvvNumber {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

@media only screen and (max-width: 419px) {
  .cvvNumber {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3 / 4 !important;
  }
}
