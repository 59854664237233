.header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #000;
  -webkit-box-shadow: 0px 3px 6px #00000029;
          box-shadow: 0px 3px 6px #00000029;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .header {
    height: 66.25px;
    padding: 0 calc(66.25px / 3);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .header {
    height: 79.5px;
    padding: 0 calc(79.5px / 3);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header {
    height: 94.40625px;
    padding: 0 calc(94.40625px / 3);
  }
}

@media only screen and (min-width: 1710px) {
  .header {
    height: 106px;
    padding: 0 calc(106px / 3);
  }
}

.logo {
  height: 70%;
  position: relative;
}

.helpCenterButton {
  color: #667583;
  font: normal normal normal 15px/21px Poppins;
}

.page {
  margin: 0 auto;
  max-width: 1920px;
}

@media only screen and (max-width: 419px) {
  .page {
    padding: 32px 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 899px) {
  .page {
    padding: 26px 32px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page {
    padding: 44px 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page {
    padding: 62.25px 95.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page {
    padding: 73.921875px 113.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page {
    padding: 83px 127px;
  }
}

.title {
  color: #1c1f2b;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .title {
    font-size: 24.375px;
    line-height: 22.03125px;
    margin-bottom: 6.874999999999999px;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .title {
    font-size: 29.25px;
    line-height: 29.375px;
    margin-bottom: 8.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .title {
    font-size: 34.734375px;
    line-height: 35.25px;
    margin-bottom: 9.796875px;
  }
}

@media only screen and (min-width: 1710px) {
  .title {
    font-size: 39px;
    line-height: 47px;
    margin-bottom: 11px;
  }
}

.subTitle,
.terms {
  color: #1c1f2b;
  font-weight: 300;
  margin: 0 !important;
}

@media only screen and (max-width: 899px) {
  .subTitle,
  .terms {
    font-size: 11.875px;
    line-height: 16.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .subTitle,
  .terms {
    font-size: 14.25px;
    line-height: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .subTitle,
  .terms {
    font-size: 16.921875px;
    line-height: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .subTitle,
  .terms {
    font-size: 19px;
    line-height: 26px;
  }
}

.termsContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .termsContainer {
    margin-top: 36.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .termsContainer {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .termsContainer {
    margin-top: 51.65625px;
  }
}

@media only screen and (min-width: 1710px) {
  .termsContainer {
    margin-top: 58px;
  }
}

.termsCheckbox {
  background-color: #fff;
  border: 1px solid #bbc2c9;
  cursor: pointer;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
}

.termsCheckbox.checked {
  background-color: #007aff !important;
  border-color: #006aff !important;
}

@media only screen and (max-width: 899px) {
  .termsCheckbox {
    border-radius: 3.125px;
    height: 14.375000000000002px;
    margin-right: 12.75px;
    width: 14.375000000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .termsCheckbox {
    border-radius: 3.75px;
    height: 17.25px;
    margin-right: 12.75px;
    width: 17.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .termsCheckbox {
    border-radius: 4.453125px;
    height: 20.484375px;
    margin-right: 15.140625px;
    width: 20.484375px;
  }
}

@media only screen and (min-width: 1710px) {
  .termsCheckbox {
    border-radius: 5px;
    height: 23px;
    margin-right: 17px;
    width: 23px;
  }
}

.termsCheckboxIcon {
  color: #fff;
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.terms a {
  color: #1172d2 !important;
  font-weight: 500;
  text-decoration: none;
}

@media all and (hover: hover) {
  .terms a:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .terms a:active, .terms a:target {
    text-decoration: underline;
  }
}

.continueButton {
  border: 1px solid #5aa5ef;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.continueButton:not(:disabled) {
  background-color: #fff;
}

.continueButton:disabled {
  cursor: default;
  background-color: #fff;
  color: #999 !important;
}
.continueButton:disabled:hover{
  background-color: #fff;
  color: #999 !important;
}
@media only screen and (max-width: 899px) {
  .continueButton {
    font-size: 11.875px;
    height: 26.875px;
    margin-top: 36.25px;
    width: 195.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .continueButton {
    font-size: 14.25px;
    height: 32.25px;
    margin-top: 0px;
    width: 234.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .continueButton {
    font-size: 16.921875px;
    height: 38.296875px;
    margin-top: 51.65625px;
    width: 278.765625px;
  }
}

@media only screen and (min-width: 1710px) {
  .continueButton {
    font-size: 19px;
    height: 43px;
    margin-top: 58px;
    width: 313px;
  }
}

.continueButton:hover {
  background-color: #5aa5ef;
  color: #000;
}
