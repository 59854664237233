.main {
    background-color: rgb(255, 255, 255);
    /* height: 80%;
    width: 75%; */
    margin-top: 55px;
    position: relative;
    height: 80vh;
    width: 60%;
    /* z-index: -2; */
    /* text-transform: none; */
}

.backArrow {
    fill: black;
}

.ultimate {
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 600;
    color: black;
    margin: auto;
}

.top-btn {
    color: rgb(255, 255, 255) !important;
    ;
    background-color: rgb(94, 91, 91) !important;
    ;
    border: none;
    border-radius: 14px !important;
    ;
    margin: 10px !important;
    min-width: 10px !important;
    padding: 5px 10px !important;
    font-size: 16px;
    font-family: 'Poppins';
}

/* .img{ 
    width:  1x;
    height:  600px;
} 
.image5{
    width:  1x;
    height:  600px;
    


} */
.box {
    text-align: center;
    /* height: 300px;
    width: 400px; */
    margin-top: 200px;
    z-index: 9;
    /* box-shadow: -99px -11px 22px #ffffff; */
    /* background-image: linear-gradient(to top, #fcfcfc, #fdfdfd, #fdfdfd, #fefefe, #fefefe, #e7e7e7, #d0d0d0, #b9b9b9, #878787, #585858, #2e2e2e, #000000);; */
}

.text-box {
    position: relative;
    font-weight: bold;
    /* margin-top: 100px; */
    padding: 37px 0px 20px 0px;
    color: black;
    background-image: linear-gradient(to top, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff83, #ffffff00);
    ;
    text-transform: none;
}

.upload {
    color: #EA5973;
    margin: auto;
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
}

.stream {
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
    margin: auto;
    color: #373A53;
}

.featuresLabel {
    font-weight: 400;
    font-size: 13px;
    font-family: 'Poppins';
    margin-top: 8px;
    color: black;
}

.cloud {
    font-weight: 350;
    font-size: 13px;
    font-family: 'Poppins';
    margin-top: 1px;
    color: black;
}

.more {
    font-weight: 400;
    margin-top: 16px;
    font-size: 15px;
    color: black;
}

.btn {
    font-weight: 100px;
    margin-top: -10px;
    border: none;
}

.skip {
    color: rgb(255, 255, 255);
    background-color: rgb(94, 91, 91);
    border: none;
    border-radius: 25px;
    width: 50px;
    cursor: pointer;
    z-index: 5;
    margin-top: 10px;
    position: relative;
    /* float: right; */
    margin-left: auto;

    margin-right: 15px;
    cursor: pointer;
}

#path_81 {
    fill: white;
    background-color: rgb(94, 91, 91);
    border-radius: 15px;
    width: 30px;
    margin-left: 15px;
    height: 30px;
    cursor: pointer;

}

.continue {
    width: 30%;
    margin: auto;
    background-color: #5aa5ef;
    border: none;
    color: white;
    height: 45px;
    font-family: 'montserrat';
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .main {
        overflow-y: auto;
    }
}

.pricingLabel {
    color: #5aa5ef;
    font-size: 1.5rem;
}

.videoPlayer {
    height: 100% !important;
    width: 100% !important;
    border-radius: 10px;
}

.videoPlayer video {
    object-fit: fill;
    border-radius: 10px;
}