.pageContainer {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}

.page {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-right: 40px;
    }
  }
}

.pageContent {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
}

.pageContent,
.pageContent * {
  font-family: 'Montserrat';
}

.gettingStarted {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}

@media only screen and (min-width: 768px) {
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-top: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-bottom: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-bottom: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-bottom: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-bottom: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-bottom: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-bottom: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-bottom: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-bottom: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-left: max(12px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-left: max(16px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-left: max(20px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-left: max(24px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-left: max(28px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-left: max(32px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-left: max(36px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-left: max(40px, env(safe-area-inset-left));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-left: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-left: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-left: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-left: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-left: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-left: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-left: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-left: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-right: max(12px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-right: max(16px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-right: max(20px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-right: max(24px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-right: max(28px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-right: max(32px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-right: max(36px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-right: max(40px, env(safe-area-inset-right));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .gettingStarted {
        padding-right: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .gettingStarted {
        padding-right: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .gettingStarted {
        padding-right: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .gettingStarted {
        padding-right: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .gettingStarted {
        padding-right: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .gettingStarted {
        padding-right: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .gettingStarted {
        padding-right: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .gettingStarted {
        padding-right: 40px;
      }
    }
  }
}

.message {
  color: #fff;
  font-weight: 900;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .message {
    font-size: 34px;
    margin-bottom: 20px;
    margin-top: 64px;
  }
  .actionsContainer {
    gap: 16px;
    -ms-grid-columns: 274px;
        grid-template-columns: 274px;
    margin-bottom: 64px;
    margin-top: 32px;
  }
  .action {
    border-width: 3px;
    height: 160px;
    padding: 24px;
  }
  .actionImage {
    width: 53px;
  }
  .actionText {
    font-size: 12px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .message {
    font-size: 35px;
    margin-bottom: 25px;
  }
  .actionsContainer {
    gap: 20px;
    -ms-grid-columns: 243.75px 243.75px;
        grid-template-columns: 243.75px 243.75px;
  }
  .action {
    border-width: 2px;
    height: 243.75px;
    padding: 15px;
  }
  .actionImage {
    height: 72.5px;
    width: 72.5px;
  }
  .actionText {
    font-size: 12.5px;
    margin-top: 25.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .message {
    font-size: 42px;
    margin-bottom: 30px;
  }
  .actionsContainer {
    gap: 24px;
    -ms-grid-columns: 292.5px 292.5px;
        grid-template-columns: 292.5px 292.5px;
  }
  .action {
    border-width: 2px;
    height: 292.5px;
    padding: 18px;
  }
  .actionImage {
    height: 87px;
    width: 87px;
  }
  .actionText {
    font-size: 15px;
    margin-top: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .message {
    font-size: 49.875px;
    margin-bottom: 35.625px;
  }
  .actionsContainer {
    gap: 28.5px;
    -ms-grid-columns: 347.34375px 347.34375px;
        grid-template-columns: 347.34375px 347.34375px;
  }
  .action {
    border-width: 2.671875px;
    height: 347.34375px;
    padding: 21.375px;
  }
  .actionImage {
    height: 103.3125px;
    width: 103.3125px;
  }
  .actionText {
    font-size: 17.8125px;
    margin-top: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .message {
    font-size: 56px;
    margin-bottom: 40px;
  }
  .actionsContainer {
    gap: 32px;
    -ms-grid-columns: 390px 390px;
        grid-template-columns: 390px 390px;
  }
  .action {
    border-width: 3px;
    height: 390px;
    padding: 24px;
  }
  .actionImage {
    height: 116px;
    width: 116px;
  }
  .actionText {
    font-size: 20px;
    margin-top: 41px;
  }
}

.actionsContainer {
  display: -ms-grid;
  display: grid;
}

.action {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-color: #5aa5ef;
  border-radius: 10px;
  border-style: solid;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (min-width: 768px) {
  .action {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.actionImage {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
}

.actionText {
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .actionText {
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .actionText {
    text-align: center;
  }
}
