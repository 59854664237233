.title {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: 'Montserrat' !important;

}
.content {
  text-align: center;
  color: #000;
  font-family: 'Montserrat' !important;
  font-weight: 500;
  padding: 0 10px 0;

}
.actionContainer {
  display: grid;
}
.cancelButton{
  text-transform: none;
  font-family: 'Montserrat' !important;
  color: #FFFFFF;
  background-color:#5AA5EF;
  font-size: 16px !important;
  font-weight: 500;

}
.continueButton{
  text-transform: none;
  color: #000000;
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  padding: 15px 0;
}
.dialogContainer {
  border-radius: 15px;
}
