@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url(../fonts/HelveticaNeue-Bold.ttf);
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(../fonts/Montserrat-SemiBold.ttf);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular;
  font-size: 18px;
  /* color: #fff; */
  overflow-x: hidden;
  background: radial-gradient(#222634, #07080c) no-repeat center;
}

.section {
  padding-top: 50px;
}

.container {
  width: 1320px;
  padding: 0 15px;
  margin: auto;
}

.title {
  font-family: Montserrat-Bold;
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
  color: #fff;
}

.subtitle {
  width: 650px;
  max-width: 100%;
  margin: 0 auto 100px auto;
  line-height: 32px;
  text-align: center;
  font-size: 18px;
  opacity: 0.75;
  font-family: Montserrat-Regular;
  color: #fff;
  margin-bottom: 10px;
}

.MuiCircularProgress-colorPrimary {
  margin: 0 auto;
  display: flex;
}
.external-header {
  width: 100%;
  height: 600px;
  font-family: Montserrat-Bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url("../img/header.png") center no-repeat;
  background-size: cover;
}

.btn {
  border-radius: 35px;
  font-size: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 8px 28px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.3s;
}

h1 {
  font-size: 45px;
  text-transform: uppercase;
}

.header-contents span {
  font-size: 35px;
}

.header-contents img {
  height: 15px;
  width: 15px;
  margin: 0 20px;
}

.header-contents .btn {
  font-family: Montserrat-SemiBold;
  display: block;
  font-size: 22px;
  border-radius: 55px;
  padding: 25px 60px;
  margin-top: 10%;
}

.header-btn {
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

nav {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  padding: 15px 90px;
  place-content: center space-between;
}

.logo {
  width: 200px;
  margin-top: 20px;
}

.help-btn {
  font-family: Montserrat-Medium;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
}

.help-btn img {
  height: 18px;
  margin-right: 6px;
}

.signup-btns {
  font-family: HelveticaNeue-Bold;
  margin-top: 25px;
}

.transparent:hover {
  color: #555;
  background-color: #fff;
  transition: all 0.3s;
}

.blue {
  border-color: transparent;
  border-style: none;
  box-shadow: 0 0 6px #002e9a;
  background-image: linear-gradient(90deg, #0185ff 0, #0139f7);
}

.blue:hover {
  box-shadow: 0 0 20px #002e9a;
}

.white {
  color: #1787fc;
  background-color: #fff;
}

.white:hover {
  box-shadow: 0 0 20px #fff;
}

.platforms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}

.platforms img {
  max-height: 60px;
}

.platforms img:first-child,
.platforms img:last-child {
  /* height: 40px; */
}

.col {
  width: calc(100% / 3);
  margin: 0 15px;
}
.clearWidfth {
  width: 290px;
  margin: 0 15px;
}
.how-to-use {
  color: #fff;
}

.plans,
.how-to-use,
.access {
  display: flex;
  place-content: center;
}

.plan {
  padding: 16px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
  transition: all 0.5s;
}

.plan:hover {
  transform: translate(0, -10px);
  transition: all 0.5s;
}

.plan-blue {
  color: #fff;
  background-color: #0075ff;
}

.plan-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.plan-name p,
.plan-price p {
  margin: 0;
  font-size: 24px;
}

.plan-price {
  text-align: right;
}

.plan-name span,
.plan-price span {
  font-size: 13px;
  opacity: 0.7;
}

.plan-body {
  width: 100%;
  padding: 25px 0;
  margin: 0;
}

.plan-body li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  list-style: none;
  margin-bottom: 10px;
}

.plan .btn {
  width: 100%;
  display: block;
  margin: 0;
  padding: 10px 28px;
}

.plan .blue {
  background: #0075ff;
  box-shadow: none;
}

.how-to-use,
.access {
  text-align: center;
}

.title-margin {
  margin-bottom: 50px;
}

.how-to-use .col img {
  height: 250px;
  max-width: 100%;
}

.how-to-use .col div,
.access .col div {
  font-family: Montserrat-Bold;
  font-size: 30px;
  margin: 10px 0 30px;
}

.how-to-use .col p,
.access .col p {
  font-size: 18px;
  margin: 0;
  opacity: 0.7;
}

.access-title {
  font-size: 35px;
  color: #fff;
}

.start-streaming {
  color: #fff;
  font-size: 55px !important;
}

.view-share-preserve {
  color: #fff;
}

.access .col img {
  width: 120px;
}

.access .col div {
  text-transform: uppercase;
  font-size: 25px;
}

.access .col div,
.access .col p {
  color: #cacaca;
  opacity: 1;
}

.access .col p {
  line-height: 25px;
}

footer {
  padding-top: 100px;
}

footer .row {
  display: flex;
  place-content: center;
}

footer .col {
  width: 40%;
  padding: 0 15px;
}

footer .col:first-child {
  width: 60%;
}

footer .col img {
  max-width: 100%;
}

.footer-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.footer-content .content {
  position: relative;
  padding: 40px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 72px 108px rgba(23, 135, 252, 0.12);
}

.footer-content .content div {
  font-family: Montserrat-Bold;
  color: #213053;
  font-size: 35px;
}

.footer-content .content p {
  color: #000;
  font-size: 16px;
}

.footer-content .content img {
  height: 350px;
  position: absolute;
  top: 70px;
  right: -170px;
  z-index: 1;
  animation: cube 6s infinite;
}

.footer-content .content .btn {
  width: 165px;
  display: block;
  margin: 50px auto 0 auto;
  border-radius: 10px;
  padding: 15px 30px;
  box-shadow: 0 26px 92px rgba(0, 117, 255, 0.5);
  background: #0075ff;
  font-family: Montserrat-Regular;
}

@keyframes cube {
  0% {
    transform: translateY(-80px) rotateZ(-25deg);
  }

  50% {
    transform: translateY(0px) rotateZ(0deg);
  }

  100% {
    transform: translateY(-80px) rotateZ(-25deg);
  }
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  padding: 30px 30px 10px;
  margin-top: 80px;
}

.footer-bottom div {
  display: flex;
  align-items: center;
}

.footer-bottom img {
  height: 32px;
}

.footer-bottom p {
  font-size: 11px;
  margin-left: 15px;
  color: #fff;
  white-space: nowrap;
}

.footer-bottom ul {
  padding: 0;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}

.footer-bottom ul li {
  display: inline-block;
  margin-left: 14px;
  list-style: none;
  font-size: 11px;
}

.footer-bottom ul li a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

.footer-bottom ul li a:hover {
  color: #0075ff;
  transition: all 0.3s;
}

/* Small devices (landscape phones, 576px and up) */

/* @media (min-width: 576px) { */

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
  .start-streaming {
    color: #fff;
    font-size: 40px !important;
  }
  .col {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .hide {
    display: none;
  }

  .platforms {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .platforms img {
    padding: 5px;
  }

  .platforms .google-cast {
    height: 25px !important;
    width: 105px;
    margin: auto 0 auto 0;
  }

  .google-cast-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container {
    width: 100%;
  }

  nav {
    padding: 0px;
  }

  footer .col {
    width: 50%;
  }

  .footer-content .content div {
    font-size: 30px;
  }

  .footer-bottom {
    flex-direction: column-reverse;
    padding: 30px 15px 10px;
  }

  .platforms img {
    /* height: 35px !important; */
    /* max-width: 130px !important; */
  }

  .plan {
    padding: 20px;
  }

  .plan-name p,
  .plan-price p {
    font-size: 18px;
  }

  .plan-body li {
    font-size: 14px;
  }

  .how-to-use .col img {
    height: 200px;
  }

  .access-title {
    font-size: 25px;
  }

  .how-to-use .col div,
  .access .col div {
    font-size: 20px;
  }

  .how-to-use .col p,
  .access .col p {
    font-size: 16px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }

  .footer-bottom ul {
    padding: 0 120px 0 0;
  }
}

@media (max-width: 463px) {
  .signup-btns .btn {
    padding: 10px 20px !important;
    border-radius: 20px;
    font-size: 14px;
  }

  .header-contents .btn {
    font-size: 12px !important;
    padding: 12px 26px !important;
    width: 70%;
  }

  .pricing .subtitle {
    font-size: 13px;
  }
}

.pricing .subtitle {
  padding: 0 25px 0 25px;
}

@media (max-width: 600px) {
  .logo {
    width: 50px;
    margin-top: 15px;
  }

  h1 {
    font-size: 32px;
  }

  .header-contents span {
    font-size: 20px;
  }

  .header-contents .btn {
    font-size: 13px;
    padding: 15px 26px;
  }

  .store-icons img {
    height: 50px !important;
  }

  .platforms .play-icon {
    /* margin-left: 10px; */
  }

  /* .platforms{
        justify-content: space-around;
    } */

  .footer-bottom ul {
    justify-content: center;
  }

  .title {
    font-size: 30px;
  }

  .plans {
    flex-direction: column;
    padding: 0 30px;
  }

  .how-to-use {
    flex-direction: column;
    padding: 0 25px;
  }

  .plan {
    width: 85%;
    margin: 30px auto 0;
  }

  .how-to-use .col:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .how-to-use .col:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .how-to-use .col:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  /* .how-to-use .col{
        text-align: left;
        margin-top: 30px;
    } */

  /* .how-to-use .col:nth-child(2){
        text-align: right;
    } */

  /* .how-to-use .col:nth-child(1) div{
        padding-left: 20px;
    }
    
    .how-to-use .col:nth-child(2) div p{
        padding-right: 20px;
    }

    .how-to-use .col:nth-child(3) div p{
        padding-left: 50px;
    } */

  footer .col {
    width: 100%;
  }

  .access .col img {
    width: 70px;
  }
}

@media (max-width: 463px) {
  .mob-spacing {
    margin: 0 3px !important;
  }

  .footer-bottom ul li {
    margin-left: 14px;
    text-align: center;
    justify-content: center;
  }
}

.how-to-use div {
  font-size: 32px !important;
}

.platforms img {
  height: 35px;
}
