@media only screen and (max-width: 899px) {
  .pageContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .dashboard {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard {
    margin: 32px auto;
    max-width: 420px;
  }
}

@media only screen and (min-width: 900px) {
  .dashboard {
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
  }
}

.sectionsContainer {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .sectionsContainer {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .sectionsContainer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 100%;
  }
}

.section {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}

.sectionTitle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sectionTitle > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.videoUploadSection {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.videoUploadSectionContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #707070;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.uploadIcon {
  position: relative;
}

.uploadButton {
  background-color: #1172d2 !important;
  border-radius: 0 !important;
}

.videoAnalyticsSection {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.storageSection {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.userManagementSection {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.addUserButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #5aa5ef;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  margin-left: 2.5%;
}

.userRequest {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}

.userRequestText {
  overflow: hidden;
}

.userRequestName {
  font-size: 600 !important;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 600;
}

.userRequestEmail {
  color: #b2b2b2;
}

.userRequestName,
.userRequestEmail {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userRequestActionsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.userRequestActionButton {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: 700;
  line-height: 0;
  margin-left: 2%;
  font-family: 'Montserrat' !important;
}

@media all and (hover: hover) {
  .userRequestActionButton.delete:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton.delete:active,
  .userRequestActionButton.delete:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .userRequestActionButton:not(.delete):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton:not(.delete):active,
  .userRequestActionButton:not(.delete):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

.videoAnalyticsList {
  width: 100%;
}

.videoAnalyticsList thead {
  font-weight: 600;
}

.videoAnalyticsList td:nth-child(2) {
  text-align: right;
}

.storageSectionContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.storageManagementButton {
  background-color: #c9c9c9;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
}

.storageInfoBar {
  background-color: transparent;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5rem;
  overflow: hidden;
}

.storageInfoBarContent {
  color: #fff;
  display: inline;
  line-height: 1.6;
  margin-left: -2.5px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.storageInfoBarContent.storageInfoBarVideos {
  background-color: #5aa5ef;
  /* width: 38.4476%; */
}

.storageInfoBarContent.storageInfoBarPhotos {
  background-color: #ff4040;
  /* width: 27.0758%; */
}

.storageInfoBarContent.storageInfoBarAvailable {
  text-align: right;
  background-color: #000;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.accessRequestNotes {
  line-height: 5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 4px;
  }
  .uploadIcon {
    height: 41px;
    width: 41px;
  }
  .videoUploadSection {
    height: 184px;
  }
  .videoAnalyticsSection {
    height: 216px;
  }
  .section {
    border-radius: 12.65625px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .videoUploadSectionContent {
    border-radius: 8.90625px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 11px;
  }
  .userRequestEmail {
    font-size: 9px;
  }
  .sectionTitle {
    margin-bottom: 14px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .storageInfoBar {
    height: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sectionsContainer {
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .uploadIcon {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .section {
    border-radius: 16.875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .videoUploadSectionContent {
    border-radius: 11.875px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 13px;
  }
  .userRequestEmail {
    font-size: 9px;
  }
  .sectionTitle {
    margin-bottom: 14.25px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 126.25px;
  }
  .storageInfoBar {
    height: 23px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sectionsContainer {
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .uploadIcon {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .section {
    border-radius: 20.25px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .videoUploadSectionContent {
    border-radius: 14.25px;
  }
  .addUserButton {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .userRequestName {
    font-size: 12.75px;
  }
  .userRequestEmail {
    font-size: 10.5px;
  }
  .sectionTitle {
    margin-bottom: 14.25px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 15px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 17.25px;
    height: 34px;
    width: 151.5px;
  }
  .storageInfoBar {
    height: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sectionsContainer {
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .uploadIcon {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .section {
    border-radius: 24.046875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .videoUploadSectionContent {
    border-radius: 16.921875px;
  }
  .addUserButton {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .userRequestName {
    font-size: 15.265625px;
  }
  .userRequestEmail {
    font-size: 12.46875px;
  }
  .sectionTitle {
    margin-bottom: 16.921875px;
  }
  .userRequest {
    margin-top: 25.828125px;
  }
  .userRequestActionButton {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 17.8125px;
  }
  .storageInfoText {
    font-size: 16.03125px;
  }
  .storageManagementButton {
    font-size: 20.484375px;
    height: 34px;
    width: 179.90625px;
  }
  .storageInfoBar {
    height: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 33px;
  }
  .uploadIcon {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .section {
    border-radius: 27px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
  }
  .videoUploadSectionContent {
    border-radius: 19px;
  }
  .addUserButton {
    font-size: 36px;
    height: 44px;
    width: 44px;
  }
  .userRequestName {
    font-size: 17px;
  }
  .userRequestEmail {
    font-size: 14px;
  }
  .sectionTitle {
    margin-bottom: 19px;
  }
  .userRequest {
    margin-top: 29px;
  }
  .userRequestActionButton {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 20px;
  }
  .storageInfoText {
    font-size: 18px;
  }
  .storageManagementButton {
    font-size: 23px;
    height: 34px;
    width: 202px;
  }
  .storageInfoBar {
    height: 31px;
  }
}
.headerTheme {
  background-color: black;
}

.deleteActive {
  background-color: #fff !important;
  color:#FF0000 !important;
  border-color: #000 !important;
}



