.paymentCard {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #70707031;
  border-radius: 3.48mm;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.itsAlink {
  text-align: right;
  width: 100%;
  margin-bottom: 7px;
  color: #1172d2;
  text-decoration: none; /* Remove underline initially */
  display: inline-block; /* Allows proper hover effect */
}

.itsAlink:hover {
  text-decoration: underline; /* Add underline on hover */
}
.leftico{
  margin-left: auto;
  color: gray;
    cursor: default;
    font-weight: 500;
}
.remaing {
  font-size: 12.46875px !important;
  margin-bottom: 8.90625px !important;
  text-transform: capitalize;
  margin-top: 7.125px !important;
  color: red !important;
  font-family: "Poppins";
}
@media only screen and (max-width: 899px) {
  .paymentCard {
    margin-top: 24px;
    border-radius: 11.71875px;
    height: 140.625px;
    padding: 23.4375px;
    width: 228.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .paymentCard {
    border-radius: 15.625px;
    height: 187.5px;
    padding: 31.25px;
    width: 305px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .paymentCard {
    border-radius: 18.75px;
    height: 225px;
    padding: 37.5px;
    width: 366px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .paymentCard {
    border-radius: 22.265625px;
    height: 267.1875px;
    padding: 44.53125px;
    width: 434.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .paymentCard {
    border-radius: 25px;
    height: 300px;
    padding: 50px;
    width: 488px;
  }
}

.paymentCardNum {
  color: #02071a;
  font: normal normal bold 20px/100px Montserrat;
  letter-spacing: -0.57px;
}

@media only screen and (max-width: 899px) {
  .paymentCardNum {
    font-size: 12px;
    padding: 0 0 23.4375px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .paymentCardNum {
    font-size: 12.5px;
    padding: 0 0 31.25px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .paymentCardNum {
    font-size: 15px;
    padding: 0 0 37.5px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .paymentCardNum {
    font-size: 17.8125px;
    padding: 0 0 44.53125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .paymentCardNum {
    font-size: 20px;
    padding: 0 0 50px 0;
  }
}

.paymentCardProvider {
  bottom: 5%;
  position: absolute;
  height: 25%;
  right: 5%;
}
.labelLinks_container_main {
  /* background-color: gray; */
  /* display: flex; */
}

.labelLinks_container_main .labelLinks_container_sub {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.labelLinks_container_sub:not(:last-child) {
  border-bottom: 1px solid gray;
}
.labelLinks {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.link {
  color: #1172d2;
  font-weight: 500;
  cursor: pointer;
}

.linkDisabled {
  color: gray;
  cursor: default;
}
.linkDisabled:hover {
  text-decoration: none !important;
}
.firstLabel {
  display: flex;
}

.firstLabel,
.colorBlack {
  font-weight: 700;
  color: #02071a;
}
.secondLabel {
  color: #757575;
}

.theMain {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  /* padding: 0 1rem; */
  padding: 3% 12%;
}
.mainBox {
  font-size: inherit;
  max-width: 500px;
}
.main_top {
  padding-bottom: 1rem;
}
.main_top > .main_subHeading {
  font-size: 16pt;
}
.main_top > .main_heading {
  font-weight: 500;
  margin: 0 !important;
  font-size: 30pt;
}

.main_subHeading {
  max-width: 400px;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0.3rem 0 2rem 0;
}

.main_box_buttons {
  display: flex;
  justify-content: space-between;
}
.main_box_buttons > button {
  font-size: 15px;
  width: 45%;
  padding: 0.8rem;
  outline: none;
  border: none;
  background-color: #dedede;
  /* width: 100%; */

  margin: 0.5rem 0;
  font-weight: 600;
  cursor: pointer;
}
.main_box_buttons > button.firstButton {
  background-color: #1172d2;
  color: white;
}
.main_box {
  border: 2px solid #707070;
  background-color: white;
  padding: 1rem;
}
.main_box_list ul li {
  font-weight: 500;
  margin: 1rem 0;
}

.main_button {
  font-size: inherit;
  padding: 0.5rem 0rem;
  outline: none;
  border: none;
  background-color: #dedede;
  width: 100%;
  margin: 0.5rem 0;
  font-weight: 600;
  cursor: pointer;
}

.reActiveButton {
  color: #fff !important;
  background-color: #f59716;
}

.main_heading {
  font-size: inherit;
  font-weight: 500;
}
.main {
  text-transform: uppercase;
  color: #02071a;
  font-family: "Montserrat";
  position: relative;
  width: 100%;
  padding: 1.5rem 0;
  /* font-size: 26px!important; */
}
@media only screen and (max-width: 480px) {
  .firstLabel,
  .colorBlack,
  .link,
  .secondLabel {
font-size: 14px;
align-items: center;
  }
  .labelLinks {
    gap: 20px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .main {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .main {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .main {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .main {
    font-size: 20px;
  }
}

.headerTheme {
  background-color: black !important;
}

.redColor {
  color: #f80808;
  font-weight: 500;
  font-size: 14px;
}
.expired {
  font-family: "poppins";
  font-size: 16px;
  margin: 0;
}
.redColorExpired {
  color: #f80808;
  font-weight: 500;
  font-size: 12px;
}

  .main_button:hover {
    background-color: dimgrey;
  }
  .disabled {
    opacity: 0.5; /* Example styling for disabled button */
    cursor: not-allowed;
  }
  .disabled:hover {
    opacity: 0.5; /* Example styling for disabled button */
    cursor: not-allowed;
    /* background-color: #dedede; */
  }
.link:hover {
  text-decoration: underline;
}

.main_box_buttons > button.firstButton:hover {
  background-color: #5aa5ef;
}

.main_box_buttons > button.secondButton:hover {
  background-color: #707070;
}
