.react_player {
  z-index: 0;
}

.colorChangePlay  {
  fill: #909298;
}
   
.colorChangePlay:hover {
  fill: #fff;
}

.toolTip:after {
  content: " ";
  position: absolute;
  right: 62px;
  bottom: -9px;
  border-top: 9px solid #707070;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

.timeStampInThumb {
  position: relative;
  bottom: 77.5px;
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: 500;
}

.timeStampInThumbWithOutImage {
  position: relative;
  bottom: 75px;
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: 500;
}

.toolTipWithOutImage:after {
  content: " ";
  position: absolute;
  right: 17px;
  bottom: -9px;
  border-top: 9px solid #707070;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

.backButton {
  margin: 14px 20px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Montserrat';
  z-index: 2;
  width: 25%;
}

@media only screen and (max-width: 899px) {
  .backButton {
    font-size: 16px;
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .backButton {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .backButton {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backButton {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .backButton {
    margin: 12px 20px !important;
  }
}

.colorChangeControl {
  opacity: 0.51;
}

.colorChangeControl:hover {
  opacity: 1;
}

.box {
  width: 50px;
  height: 170px;
  background-color: #707070;
  color: #000;
  padding: 24px 15px 0px 15px;  /* position: relative; */
  margin: 40px;
  float: left;
  display: block;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  /* right: 90px; */
  top: 65px;
}

.box.arrowTop {
  margin-top: 10px;
}

.box.arrowTop:after {
  content: " ";
  position: absolute;
  right: 19px;
  top: -8px;
  border-top: none;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 8px solid #707070;
}

@media only screen and (max-width: 319px) {
  .box {
    right: 1%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .box {
    right: 1%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 550px) {
  .box {
    right: 1%;
  }
}
@media only screen and (min-width: 551px) and (max-width: 767px) {
  .box {
    right: 3%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .box {
    right: 6%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .box {
    right: 7%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .box {
    right: 8%;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .box {
    right: 8%;
  }
}
@media only screen and (min-width: 1710px) {
  .box {
    right: 9%;
  }
}

.playAgain {
  font-weight: 700;
  font-family: 'Montserrat';
  font-size: 26px;
  color: #fff;
}

.colorChangePause {
  stroke: #959595
}

.colorChangePause:hover {
  stroke: #fff;
} 

.timeStamp {
  font-family: 'Montserrat';
  font-size: 16px;
}