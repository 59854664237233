.userRequest {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 24px;
  }

  .groupMemberStyle{
    display: flex;
  }

  .profileIcon {
    content:attr(data-letters);
    display:inline-block;
    font-size:1.3em;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:#F0F0F0;
    vertical-align:middle;
    margin-right:1em;
    color:#000;
    border: 1px solid #000;  
  }

  @media only screen and (max-width: 767px) {
    .profileIcon {
      width: 1.5em !important;
      height: 1.5em !important;
      line-height: 1.5em !important;
      margin-right: .3em !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .profileIcon {
      width: 2em;
      height: 2em;
      line-height: 2em;
      margin-right: .5em;
    }
  }
  
  .userRequestText {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  
  .userRequestName {
    font-weight: 600;
    line-height: 1.3;
    text-transform: capitalize;
  }
  
  .userRequestEmail {
    color: #b2b2b2;
  }
  
  .userRequestName,
  .userRequestEmail {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .userRequestActionsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    place-self: center;
  }
  
  .userRequestActionButton {
    background-color: transparent;
    border: 2px solid #5aa5ef;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-weight: 600;
    line-height: 0;
    /* margin-left: 2%; */
    margin-right: 4rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  @media all and (hover: hover) {
    .userRequestActionButton.delete:hover {
      background-color: #ff4040 !important;
      border-color: #ff4040 !important;
      color: #fff !important;
    }
  }
  
  @media all and (pointer: coarse) {
    .userRequestActionButton.delete:active,
    .userRequestActionButton.delete:target {
      background-color: #ff4040 !important;
      border-color: #ff4040 !important;
      color: #fff !important;
    }
  }
  
  @media all and (hover: hover) {
    .userRequestActionButton:not(.delete):hover {
      background-color: #5aa5ef !important;
      color: #fff !important;
    }
  }
  
  @media all and (pointer: coarse) {
    .userRequestActionButton:not(.delete):active,
    .userRequestActionButton:not(.delete):target {
      background-color: #5aa5ef !important;
      color: #fff !important;
    }
  }
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: 12px;
    }
    .userRequest {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .userRequestName {
      font-size: 17px;
      max-width: 130px;
    }
    .userRequestEmail {
      font-size: 13px;
      max-width: 130px;
    }  
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: 16px;
    }
    .userRequest {
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .userRequestName {
      font-size: 17px;
      max-width: 130px;
    }
    .userRequestEmail {
      font-size: 13px;
      max-width: 130px;
    }  
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: 20px;
    }
    .userRequest {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .userRequestName {
      font-size: 18px;
      max-width: 150px;
    }
    .userRequestEmail {
      font-size: 13px;
      max-width: 150px;
    }  
  }
  
  @media only screen and (max-width: 767px) {
    .profileIcon {
      width: 1.5em !important;
      height: 1.5em !important;
      line-height: 1.5em !important;
      margin-right: .3em !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .sectionsContainer {
      gap: 23px;
      margin-top: 4px;
    }
    .uploadIcon {
      height: 41px;
      width: 41px;
    }
    .videoUploadSection {
      height: 184px;
    }
    .videoAnalyticsSection {
      height: 216px;
    }
    .section {
      border-radius: 12.65625px;
      -webkit-box-shadow: #00000029 0px 3px 6px;
      box-shadow: #00000029 0px 3px 6px;
      margin-top: 20px;
      padding: 15px;
    }
    .videoUploadSectionContent {
      border-radius: 8.90625px;
    }
    .addUserButton {
      font-size: 25px;
      height: 30px;
      width: 30px;
    }
    .sectionTitle {
      margin-bottom: 14px;
    }
    .userRequest {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .userRequestActionButton {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .videoAnalyticsList,
    .storageInfoBar {
      font-size: 14px;
    }
    .storageInfoText {
      font-size: 13.5px;
    }
    .storageManagementButton {
      font-size: 14.375000000000002px;
      height: 34px;
      width: 94.6875px;
    }
    .storageInfoBar {
      height: 23px;
    }
    .pendingViewersContainer {
      padding: 32px 6px !important;
    }
    .allViewersContainer {
      padding: 32px 12px;
    }
    .viewerSearchinput {
      font-size: 12px;
      height: 26px;
      width: 321px;
    }
    .userRequestName {
      font-size: 20px;
      max-width: 200px;
    }
    .userRequestEmail {
      font-size: 14px;
      max-width: 200px;
    }  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sectionsContainer {
      gap: 14.375000000000002px;
      margin-top: 20.625px;
    }
    .uploadIcon {
      height: 61.5625px;
      margin: 32px;
      width: 61.5625px;
    }
    .section {
      border-radius: 16.875px;
      -webkit-box-shadow: #00000029 0px 3px 6px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 18.75px;
    }
    .videoUploadSectionContent {
      border-radius: 11.875px;
    }
    .addUserButton {
      font-size: 25px;
      height: 30px;
      width: 30px;
    }
    .userRequestName {
      font-size: 20px;
      max-width: 200px;
    }
    .userRequestEmail {
      font-size: 14px;
      max-width: 200px;
    }
    .sectionTitle {
      margin-bottom: 14.25px;
    }
    .userRequest {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .userRequestActionButton {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .videoAnalyticsList,
    .storageInfoBar {
      font-size: 14px;
    }
    .storageInfoText {
      font-size: 13.5px;
    }
    .storageManagementButton {
      font-size: 14.375000000000002px;
      height: 34px;
      width: 126.25px;
    }
    .storageInfoBar {
      height: 23px;
    }
    .pendingTitle {
      font-size: 18px;  
    }
    .pendingViewersContainer {
      padding: 32px 6px !important;
    }
    .allViewersContainer {
      padding: 32px 18px;
    }
    .viewerSearchinput {
      font-size: 12px;
      height: 26px;
      width: 321px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .profileIcon {
      width: 2em;
      height: 2em;
      line-height: 2em;
      margin-right: .5em;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    .sectionsContainer {
      gap: 17.25px;
      margin-top: 24.750000000000004px;
    }
    .uploadIcon {
      height: 73.875px;
      margin: 32px;
      width: 73.875px;
    }
    .section {
      border-radius: 20.25px;
      -webkit-box-shadow: #00000029 0px 3px 6px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 22.5px;
    }
    .videoUploadSectionContent {
      border-radius: 14.25px;
    }
    .addUserButton {
      font-size: 27px;
      height: 33px;
      width: 33px;
    }
    .userRequestName {
      font-size: 19.75px;
      max-width: 200px;
    }
    .userRequestEmail {
      font-size: 10.5px;
    }
    .sectionTitle {
      margin-bottom: 14.25px;
    }
    .userRequest {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .userRequestActionButton {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .videoAnalyticsList,
    .storageInfoBar {
      font-size: 15px;
    }
    .storageInfoText {
      font-size: 13.5px;
    }
    .storageManagementButton {
      font-size: 17.25px;
      height: 34px;
      width: 151.5px;
    }
    .storageInfoBar {
      height: 23.250000000000004px;
    }
    .pendingTitle {
      font-size: 21px;  
    }
    .pendingViewersContainer {
      padding: 32px 6px !important;
    }
    .allViewersContainer {
      padding: 21px 34px 0px 0px;
    }
    .viewerSearchinput {
      font-size: 11px;
      height: 25px;
      width: 267px;
    }
  }

  @media only screen and (min-width: 1130px) and (max-width: 1440px) {
    .userRequestActionButton {
      font-size: 12.25px;
      height: 21.953125px;
      width: 68.0625px;
      margin-right: 2rem;
    }}
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sectionsContainer {
      gap: 20.484375px;
      margin-top: 29.390625000000004px;
    }
    .uploadIcon {
      height: 87.7265625px;
      margin: 32px;
      width: 87.7265625px;
    }
    .section {
      border-radius: 24.046875px;
      -webkit-box-shadow: #00000029 0px 3px 6px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 26.71875px;
    }
    .videoUploadSectionContent {
      border-radius: 16.921875px;
    }
    .addUserButton {
      font-size: 32.0625px;
      height: 39.1875px;
      width: 39.1875px;
    }
    .userRequestName {
      font-size: 19.265625px;
    }
    .userRequestEmail {
      font-size: 15.46875px;
    }
    .sectionTitle {
      margin-bottom: 16.921875px;
    }
    .userRequest {
      margin-top: 25.828125px;
    }
    .userRequestActionButton {
      font-size: 12.25px;
      height: 21.953125px;
      width: 68.0625px;
    }
    .videoAnalyticsList,
    .storageInfoBar {
      font-size: 17.8125px;
    }
    .storageInfoText {
      font-size: 16.03125px;
    }
    .storageManagementButton {
      font-size: 20.484375px;
      height: 34px;
      width: 179.90625px;
    }
    .storageInfoBar {
      height: 27.609375000000004px;
    }
    .pendingTitle {
      font-size: 27px;  
    }
    .allViewersContainer {
      padding: 32px 216px 0px 0px;  
    }
    .viewerSearchinput {
      font-size: 15px;
      height: 33px;
      width: 407px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .sectionsContainer {
      gap: 23px;
      margin-top: 33px;
    }
    .uploadIcon {
      height: 98.5px;
      margin: 32px;
      width: 98.5px;
    }
    .section {
      border-radius: 27px;
      -webkit-box-shadow: #00000029 0px 3px 6px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 30px;
    }
    .videoUploadSectionContent {
      border-radius: 19px;
    }
    .addUserButton {
      font-size: 36px;
      height: 44px;
      width: 44px;
    }
    .userRequestName {
      font-size: 22px;
    }
    .userRequestEmail {
      font-size: 14px;
    }
    .sectionTitle {
      margin-bottom: 19px;
    }
    .userRequest {
      margin-top: 29px;
    }
    .userRequestActionButton {
      font-size: 15px;
      height: 29px;
      width: 84px;
    }
    .videoAnalyticsList,
    .storageInfoBar {
      font-size: 20px;
    }
    .storageInfoText {
      font-size: 18px;
    }
    .storageManagementButton {
      font-size: 23px;
      height: 34px;
      width: 202px;
    }
    
    .storageInfoBar {
      height: 31px;
    }
    .pendingTitle {
      font-size: 31px;  
    }
    .allViewersContainer {
      padding: 32px 98px 0px 0px;
    }
    .viewerSearchinput {
      font-size: 18px;
      height: 43px;
      width: 581px;
    }
  }
  
  .deleteActive {
    background-color: #fff !important;
    color:#FF0000 !important;
    border-color: #000 !important;
  }