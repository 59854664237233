.skipbtn:hover{
    color: red;
}
.popup .closebtn:hover{
    color: red;
}
.skipbtn{
    color: #fff;
    font-size: 15px;
    margin-top: -5px;
    background: none;
    position: absolute;
    margin-top: -30px;
    margin-left: -835px;
    border: none;
    height: 20px;
    width: 50px;
}
@media only screen and (max-width: 767px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -350px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -560px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -650px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
.closebtn:hover{
    fill: red;
}
/* @media only screen and (max-width: 767px) { */
.popup{
   height: 50%;
   

} 
/* } */
.modalclosebtn{
    height: 25px; 
    fill: white; 
    position: absolute;
    margin-top: -35px;
    margin-left: 830px;
}
.modalclosebtn:hover {
    fill: red;
}
@media only screen and (max-width: 767px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 360px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 570px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 650px;
    }
}
.player{
    width: 900px;
}
@media only screen and (max-width: 767px) {
    .player{
       width: 400px;
     
   }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .player{
        width: 600px;
      
    }

}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .player{
        width: 700px;
      
    }

}