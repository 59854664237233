.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-shadow: rgba(0, 0, 0, 0.4) 0 0 16px;
}

.page,
.page * {
  font-family: Montserrat, sans-serif !important;
}

.pageContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.main {
  background-color: transparent;
  margin: 0 auto;
  /* min-height: 100% !important; */
  /* max-width: 1920px; */
}

/* @media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .main {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .main {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .main {
    --site-padding: 110px !important;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .main {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .main {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .main {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .main {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .main {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .main {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .main {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .main {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .main {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .main {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
} */

.mainContent {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .mainContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .mainContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .mainContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .mainContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .mainContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .mainContent {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .mainContent {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .mainContent {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .mainContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .mainContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .mainContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .mainContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .mainContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .mainContent {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .mainContent {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .mainContent {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .mainContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .mainContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .mainContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .mainContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .mainContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .mainContent {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .mainContent {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .mainContent {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .mainContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .mainContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .mainContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .mainContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .mainContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .mainContent {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .mainContent {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .mainContent {
      padding-right: var(--site-padding);
    }
  }
}
