.hiddenFormField {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.accountDataContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.accountData {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .accountData {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .accountData {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .accountData {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .accountData {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .accountData {
    font-size: 20px;
  }
}

.actionButton {
  color: #000 !important;
}

.inputFieldContainer {
  background-color: transparent !important;
}

.inputFieldContainer img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.inputField {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.inputFieldLabel {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.passwordStrengthBar {
  background-color: #ddd;
}

.passwordStrengthNotes {
  color: #444;
}

.avatarEditButton {
  color: #5aa5ef;
  font-weight: 600;
}

.accountDataAction {
  color: rgba(2, 7, 26, 0.4);
}

.avatarEditButton,
.accountDataAction {
  cursor: pointer;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .avatarEditButton:hover,
  .accountDataAction:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .avatarEditButton:active, .avatarEditButton:target,
  .accountDataAction:active,
  .accountDataAction:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 15px;
    margin-left: 30px;
  }
}

.profilePicture {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (max-width: 899px) {
  .profilePicture {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profilePicture {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profilePicture {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profilePicture {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .profilePicture {
    height: 98px;
    width: 98px;
  }
}

.profilePictureIcon {
  height: 50%;
  width: 50%;
}

.allViewersContainer {
  float: left;
  width: 50%;  
  padding: 32px 0px;
}

.pendingViewersContainer {
  float: left;
  width: 50%;  
  padding: 32px 24px;
}

.pendingTitle {
  font-family: 'Poppins';
  font-weight: 500;
}

.profileIcon {
  content:attr(data-letters);
  display:inline-block;
  font-size:1.3em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:#F0F0F0;
  vertical-align:middle;
  margin-right:1em;
  color:#000;
  border: 1px solid #000;  
}

.viewerSearchinput {
  background-color: #F0F0F0;
  border: none;
  color: #8E8E8E;
  font-weight: 500;
}
input.viewerSearchinput[type="text"] {
  padding: 0px 15px;
}

.userRequest {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}

.userRequestText {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.userRequestName {
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
}

.userRequestEmail {
  color: #b2b2b2;
}

.userRequestName,
.userRequestEmail {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userRequestActionsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.userRequestActionButton {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
  margin-top: -1px;
}

@media all and (hover: hover) {
  .userRequestActionButton.delete:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton.delete:active,
  .userRequestActionButton.delete:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .userRequestActionButton:not(.delete):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton:not(.delete):active,
  .userRequestActionButton:not(.delete):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 319px) {
  .imageGroup {
    padding-top: 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .imageGroup {
    padding-top: 16px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .imageGroup {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .profileIcon {
    width: 1.5em !important;
    height: 1.5em !important;
    line-height: 1.5em !important;
    margin-right: .3em !important;
  }
}

@media only screen and (max-width: 767px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 4px;
  }
  .uploadIcon {
    height: 41px;
    width: 41px;
  }
  .videoUploadSection {
    height: 184px;
  }
  .videoAnalyticsSection {
    height: 216px;
  }
  .section {
    border-radius: 12.65625px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .videoUploadSectionContent {
    border-radius: 8.90625px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 15px;
  }
  .userRequestEmail {
    font-size: 10px;
  }
  .sectionTitle {
    margin-bottom: 14px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 7px;
    height: 14px;
    width: 39px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .storageInfoBar {
    height: 23px;
  }
  .pendingViewersContainer {
    padding: 32px 6px !important;
  }
  .allViewersContainer {
    padding: 32px 0px 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sectionsContainer {
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .uploadIcon {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .section {
    border-radius: 16.875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .videoUploadSectionContent {
    border-radius: 11.875px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 17px;
  }
  .userRequestEmail {
    font-size: 11px;
  }
  .sectionTitle {
    margin-bottom: 14.25px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 9px;
    height: 19.75px;
    width: 51px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 126.25px;
  }
  .storageInfoBar {
    height: 23px;
  }
  .pendingTitle {
    font-size: 18px;  
  }
  .pendingViewersContainer {
    padding: 32px 6px !important;
  }
  .allViewersContainer {
    padding: 32px 0px 0px 0px;
  }
  .viewerSearchinput {
    font-size: 12px;
    height: 26px;
    width: 321px;
  }
}
@media only screen and (max-width: 1440px) {
  .profileIcon {
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin-right: .5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sectionsContainer {
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .uploadIcon {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .section {
    border-radius: 20.25px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .videoUploadSectionContent {
    border-radius: 14.25px;
  }
  .addUserButton {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .userRequestName {
    font-size: 19.75px;
  }
  .userRequestEmail {
    font-size: 12.5px;
  }
  .sectionTitle {
    margin-bottom: 14.25px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 12.25px;
    height: 21.953125px;
    width: 68.0625px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 15px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 17.25px;
    height: 34px;
    width: 151.5px;
  }
  .storageInfoBar {
    height: 23.250000000000004px;
  }
  .pendingTitle {
    font-size: 21px;  
  }
  .pendingViewersContainer {
    padding: 32px 6px !important;
  }
  .allViewersContainer {
    padding: 21px 0px 0px 0px;
  }
  .viewerSearchinput {
    font-size: 11px;
    height: 25px;
    width: 267px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sectionsContainer {
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .uploadIcon {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .section {
    border-radius: 24.046875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .videoUploadSectionContent {
    border-radius: 16.921875px;
  }
  .addUserButton {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .userRequestName {
    font-size: 20.265625px;
  }
  .userRequestEmail {
    font-size: 13.46875px;
  }
  .sectionTitle {
    margin-bottom: 16.921875px;
  }
  .userRequest {
    margin-top: 25.828125px;
  }
  .userRequestActionButton {
    font-size: 12.25px;
    height: 21.953125px;
    width: 68.0625px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 17.8125px;
  }
  .storageInfoText {
    font-size: 16.03125px;
  }
  .storageManagementButton {
    font-size: 20.484375px;
    height: 34px;
    width: 179.90625px;
  }
  .storageInfoBar {
    height: 27.609375000000004px;
  }
  .pendingTitle {
    font-size: 27px;  
  }
  .allViewersContainer {
    padding: 32px 0px 0px 0px;  
  }
  .viewerSearchinput {
    font-size: 15px;
    height: 33px;
    width: 407px;
  }
}

@media only screen and (min-width: 1710px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 33px;
  }
  .uploadIcon {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .section {
    border-radius: 27px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
  }
  .videoUploadSectionContent {
    border-radius: 19px;
  }
  .addUserButton {
    font-size: 36px;
    height: 44px;
    width: 44px;
  }
  .userRequestName {
    font-size: 23px;
  }
  .userRequestEmail {
    font-size: 14px;
  }
  .sectionTitle {
    margin-bottom: 19px;
  }
  .userRequest {
    margin-top: 29px;
  }
  .userRequestActionButton {
    font-size: 15px;
    height: 29px;
    width: 84px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 20px;
  }
  .storageInfoText {
    font-size: 18px;
  }
  .storageManagementButton {
    font-size: 23px;
    height: 34px;
    width: 202px;
  }
  
  .storageInfoBar {
    height: 31px;
  }
  .pendingTitle {
    font-size: 31px;  
  }
  .allViewersContainer {
    padding: 32px 0px 0px 0px;
  }
  .viewerSearchinput {
    font-size: 18px;
    height: 43px;
    width: 581px;
  }
}

.deleteActive {
  background-color: #fff !important;
  color:#FF0000 !important;
  border-color: #000 !important;
}

.successorRestrictContainer {
  display: grid;
  align-items: center;
  min-height: 100%;
  text-align: center;
  justify-content: center;

}

.restrictBox {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  max-width: 450px;
  padding: 42px;
}

.message {
  font-weight: 700;
}
.upgradeButton {
  background-color: #fff;
  border: 1px solid#C9C9C9;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 120px;
  font-size: 14px;
  margin-top: 20px;
}
