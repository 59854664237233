.pageContainer {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.pageContainerVideoBanner {
  background: #0A0B0F !important;
}

.mainContent {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.mainContent.loading {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading, .whatsNewHeading, .imageBannerHeading {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
}

.heading {
  margin-bottom: 4%;
}

@media only screen and (max-width: 435px) {
  .whatsNewHeading {
    height: 270px;
  }
  .imageBannerHeading {
    height: 209px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 767) {
  .imageBannerHeading {
    height: 330px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 899px) {
  .whatsNewHeading {
    height: 240px;
  }
  .imageBannerHeading {
    height: 330px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .heading {
    border-radius: 13.125px;
  }
  .whatsNewHeading {
    height: 250px;
  }
  .imageBannerHeading {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .heading {
    border-radius: 15.749999999999998px;
  }
  .whatsNewHeading {
    height: 260px;
  }
  .imageBannerHeading {
    height: 300px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heading {
    border-radius: 18.703125px;
  }
  .whatsNewHeading {
    height: 262px;
  }
  .imageBannerHeading {
    height: 300px;
  }
}

@media only screen and (min-width: 1710px) {
  .heading {
    border-radius: 21px;
  }
  .whatsNewHeading {
    height: 262px;
  }
}

.headingBG {
  border: none;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.headingBGFade {
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.headingText {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: capitalize;
  z-index: 10;
  display: table-cell;
  vertical-align: middle;
  font-size: 2.2rem;
}

.subHeadingText {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: capitalize;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}

.noContentText {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}

@media only screen and (max-width: 767px) {
  .headingText {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
  .whatsNewHeadingText {
    font-size: 16px !important;
  }
  .imageBannerHeadingText {
    font-size: 16px;
  }
  .heroImage {
    height: 180px !important;
  }
  /* .albumBannerImage {
    height: 10px !important;
  } */
}

@media only screen and (min-width: 768px) {
  .headingText {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headingText {
    font-size: 25.78125px;
    max-width: 440px;
    width: 55%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headingText {
    font-size: 34.375px;
    max-width: 540px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headingText {
    font-size: 41.25px;
    max-width: 640px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headingText {
    font-size: 30px;
    width: 40%;
    max-width: 740px;
  }
}

@media only screen and (min-width: 1710px) {
  .headingText {
    font-size: 55px;
    max-width: 850px;
  }
  .imageBannerHeading {
    height: 300px;
  }
}

.imageGroup {
  position: relative;
  margin-bottom: 3%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .layoutImageGroup {
      margin-top: -99px;
      margin-bottom: -63px;
    }
    .videoBannerImageGroup {
      margin-top: -99px;
      margin-bottom: -63px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .layoutImageGroup {
      margin-top: -92px;
      margin-bottom: -67px;
    }
    .videoBannerImageGroup {
      margin-top: -92px;
      margin-bottom: -67px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .layoutImageGroup {
      margin-top: -95px;
      margin-bottom: -69px;
    }
    .videoBannerImageGroup {
      margin-top: -95px;
      margin-bottom: -69px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .layoutImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .videoBannerImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .layoutImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .videoBannerImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .layoutImageGroup {
      margin-top: -90px;
      margin-bottom: -60px;
    }
    .videoBannerImageGroup {
      margin-top: -90px;
      margin-bottom: -60px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .layoutImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .videoBannerImageGroup {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .layoutImageGroup {
      margin-top: -65px;
      margin-bottom: -54px;
    }
    .videoBannerImageGroup {
      margin-top: -65px;
      margin-bottom: -54px;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: 40px;
    }
  }
}

.imageGroupTitle {
  color: #faf7f7;
}

.imageGroupTitle::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .imageGroupTitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupTitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupTitle {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupTitle {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupTitle {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupTitle {
    font-size: 28px;
  }
}

.imageGroupImagesWrapper {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesWrapper {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesWrapper {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesWrapper {
    --site-padding: 110px !important;
  }
}

.imageGroupImagesContainer:not(.isGrid) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.imageGroupImagesContainer:not(.isGrid)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
}

.imageGroupImagesContainer:not(.isGrid) .imageGroupImagesContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.imageGroupImagesContainer.isGrid {
  display: block;
}

.imageGroupImagesContainer.isGrid .imageGroupImagesContent {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.imageGroupImagesContainer.isGrid .imageGroupImageLink {
  width: 100%;
}

.imageGroupImageLink {
  color: #ffffff;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.imageGroupImageLink::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.imageGroupImageLink div {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.imageGroupImageLink:hover {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
  box-shadow: 7px 4px 6px 4px rgba(0, 0, 0, 0.3), 7px 4px 6px 4px rgba(0, 0, 0, 0.3);
}


.imageGroupImageLink h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.imageGroupImageLink.dummy {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.imageGroupImageLink.categoryButton::before {
  display: none !important;
}

.categoriesLoader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 256px;
}

.categoryButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize !important;
}

.categoryButton.active {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .categoryButton {
    font-size: 14px;
    height: 79.21875px;
  }
  .bannerTypeCategoryButton {
    height: 40px;
    font-size: 10px;
  }
  .albumTitle {
    font-size: 25px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .categoryButton {
    font-size: 14px;
    height: 80.62500000000001px;
  }
  .bannerTypeCategoryButton {
    height: 50px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoryButton {
    font-size: 17.5px;
    height: 88.75000000000001px;
  }
  .bannerTypeCategoryButton {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoryButton {
    font-size: 21px;
    height: 100.515625px;
  }
  .bannerTypeCategoryButton {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoryButton {
    font-size: 24.9375px;
    height: 120px;
  }
  .bannerTypeCategoryButton {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoryButton {
    font-size: 28px;
    height: 120px;
  }
  .bannerTypeCategoryButton {
    height: 50px;
    font-size: 12px;
  }
}

.categoryButton.dummy {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.selectedCategoryButton {
  margin-top: 23px;
  border: 3px solid;
  border-radius: 6vh;
  /* height: 8vh; */
  border-color: #fff;
  display: flex;
  max-width: 180px;
  float: left;
}

.selectedCategoryTitle {
  /* font-size: 1vw; */
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 4px;
  display: flex;
  align-items: center;
}

.closeButton {
  color: #fff;
  z-index: 100;
}

.closeButtonWrapper {
  display: flex;
  align-items: center;
  padding: 5px;
}

@media only screen and (max-width: 319px) {
  .selectedCategoryButton {
    margin-top: 5px !important;
    height: 21px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .selectedCategoryTitle {
    font-size: 8px;
  }
  .selectedCategoryButton {
    margin-top: 6px;
    height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectedCategoryTitle {
    font-size: 10px;
  }
  .selectedCategoryButton {
    margin-top: 9px;
    height: 29px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectedCategoryTitle {
    font-size: 11px;
  }
  .selectedCategoryButton {
    margin-top: 10px;
    height: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectedCategoryTitle {
    font-size: 12px;
  }
  .selectedCategoryButton {
    margin-top: 15px;  
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectedCategoryTitle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectedCategoryTitle {
    font-size: 14px;
  }
  /* .headingText {
    margin-right: 110px !important;
  } */
}
/* .albumBannerContainer {
  width: 90%;
} */

.textAlignFit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headerWrapper {
  display: flex;
  width: 100%;
  margin-bottom: -10px;
}

.imageBannerHeaderWrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.whatsNewWrapper {
  width: 90%;
}

.headingContainer {
  display: table;
  width: 0%;
  margin-bottom: 300px;
}
.imageBannerContainer {
  width: 70%;
  margin: auto;
}
.imageBannerHeadingText {
  /* text-align: start !important; */
  /* vertical-align: top !important; */
    align-items: center;
    margin: auto;
    justify-content: center;
    position: absolute;
    /* width: 40%; */
    padding: 10px;
}

.heroImage {
  width: 100%;
  height: 275px;
}

.albumBannerImage {
  width: 100%;
  height: 275px;
  position: relative;
  cursor: pointer;
}

.albumTitle {
  position: absolute; 
  top: 30px; 
  left: 50px; 
  color: #fff;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.rounded {
  border-radius: 50px;
}