.allGroupContainer {
  float: left;
  width: 100%;
  padding: 20px 0px;
}

.groupTitle {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 13px 25px 3px 0px;
  max-width: 396px;
  display: inline-block;
  vertical-align: middle;
}

.memberContainer {
  padding: 0px 0px 10px 0px;
  display: grid;
  /* grid-template-columns: 500px 1fr 1fr 150px; */
}

.videoHeading {
  font-weight: 500;
  padding-bottom: 15px;
}

.memberlist {
  /* width: 40%;
  float: left; */
}
.videoList {
  /* width: 25%;
  float: left; */
  padding: 10px 10px 10px 10px;
}

.albumlist {
  /* width: 25%;
  float: left; */
  padding: 10px 10px 10px 10px;
}

.action {
  /* width: 10%; */
}

.itemList {
  max-height: 157px;
  overflow-y: auto;
}

.saveButton {
  cursor: pointer;
  width: 95%;
}
.loaderButton {
  background: white;
  border: #5aa5ef 2px solid;
  cursor: pointer;
  width: max-content;
  border-radius: 8px;
  min-height: 42px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 500;
  padding: 5px 25px;
}
.loaderButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.disableButton {
  cursor: default;
  opacity: 0.4;
  width: 95%;
}

.groupWrapper {
  min-height: 270px;
}

.selectIcon {
  margin-right: 7px;
  vertical-align: middle;
  cursor: pointer;
}

.itemList::-webkit-scrollbar {
  width: 5px;
}

.itemList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.itemList::-webkit-scrollbar-thumb {
  background: #6e6f6e;
  border-radius: 25px;
}

.itemList::-webkit-scrollbar-thumb:hover {
  background: #6e6f6e;
}

/* .action{
  width: 10%;
  float: right;
} */

.expandIcon {
  vertical-align: middle;
  font-size: 45px !important;
  cursor: pointer;
  position: relative;
  top: 5px;
}

.addMemberText {
  vertical-align: middle;
  font-weight: 600;
  color: #5aa5ef;
  cursor: pointer;
  font-family: "Poppins";
}

.videoTitle {
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 11ch; */
  vertical-align: middle;
}

.addIconStyle {
  vertical-align: middle;
  color: #5aa5ef;
  cursor: pointer;
}

.deleteGroup {
  color: #ff0000;
  margin-left: 5%;
  font-weight: 600;
  vertical-align: middle;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 319px) {
  .groupTitle {
    font-size: 20px;
  }
  .addMemberText {
    font-size: 11px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .videoHeading {
    font-size: 15px;
  }
  .videoTitle {
    font-size: 13px;
    width: 200px;
  }
  .deleteGroup {
    font-size: 11px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .groupTitle {
    font-size: 21px;
  }
  .addMemberText {
    font-size: 13px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .videoHeading {
    font-size: 15px;
  }
  .videoTitle {
    font-size: 13px;
    width: 200px;
  }
  .deleteGroup {
    font-size: 12px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .groupTitle {
    font-size: 22px;
  }
  .addMemberText {
    font-size: 14px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .videoHeading {
    font-size: 22px;
  }
  .videoTitle {
    /* font-size: 14px;
    width: 200px; */
    font-size: 22px;
    width: 310px;
    margin-left: 7px;
  }
  .deleteGroup {
    font-size: 13px;
  }
  .action {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .groupTitle {
    font-size: 21.875px;
  }
  .addMemberText {
    font-size: 15px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .videoHeading {
    font-size: 22px;
  }
  .videoTitle {
    /* font-size: 7px;
    width: 56px; */
    font-size: 22px;
    width: 400px;
    margin-left: 7px;
  }
  .deleteGroup {
    font-size: 14px;
  }
  .action {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1129px) {
  .groupTitle {
    font-size: 26.25px;
  }
  .addMemberText {
    font-size: 16px;
  }
  .albumlist,
  .videoList {
    padding: 10px 10px 10px 23px;
  }
  .videoHeading {
    font-size: 18px;
  }
  .videoTitle {
    /* font-size: 7px;
    width: 61px; */
    font-size: 22px;
    width: 400px;
    margin-left: 7px;
  }

  .deleteGroup {
    font-size: 15px;
  }
  /* .action{
    width: 6%;
  } */
  .selectIcon {
    margin-right: 3px;
  }
  /* .memberContainer {
    grid-template-columns: 300px 1fr 1fr 90px;
  } */
  .action {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1130px) and (max-width: 1440px) {
  .groupTitle {
    font-size: 26.25px;
  }
  .addMemberText {
    font-size: 16px;
  }
  /* .albumlist, .videoList {
    padding: 10px 10px 10px 28px;
  } */
  .videoHeading {
    font-size: 18px;
  }
  .videoTitle {
    font-size: 12px;
    width: 130px;
  }
  .deleteGroup {
    font-size: 15px;
  }
  .memberContainer {
    grid-template-columns: 340px 1fr 1fr 90px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .groupTitle {
    font-size: 31.171875000000004px;
  }
  .addMemberText {
    font-size: 17px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .videoHeading {
    font-size: 19px;
  }
  .videoTitle {
    font-size: 17px;
    width: 170px;
  }
  .deleteGroup {
    font-size: 16px;
  }
  .memberContainer {
    grid-template-columns: 400px 1fr 1fr 100px;
  }
}

@media only screen and (min-width: 1710px) {
  .groupTitle {
    font-size: 35px;
  }
  .addMemberText {
    font-size: 18px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 20px;
  } */
  .videoHeading {
    font-size: 20px;
  }
  .videoTitle {
    font-size: 18px;
    width: 200px;
  }
  .deleteGroup {
    font-size: 17px;
  }
  .memberContainer {
    grid-template-columns: 500px 1fr 1fr 150px;
  }
}

@media only screen and (max-width: 767px) {
  .memberContainer {
    display: block;
  }
}
