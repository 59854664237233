.rectangle {
  width: 70%;
  height: 100px;
  background: radial-gradient(#222634, #07080c) no-repeat center;
  margin: auto;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.text {
  color: #FFFFFF;
  position: absolute;
  margin: auto;
}
.carousal {
  margin-left: 6%;
  margin-right: 6%;
}

.carousel-container {
  height: 100% !important;
}
/* .react-multi-carousel-list{
  justify-content: center;
} */
.carousal .react-multi-carousel-track {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: start;
  gap: 20px 15px !important;
  margin: 0 auto;
}
/* .carousal .react-multi-carousel-track {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  grid-gap: 20px 15px !important;
  gap: 20px 15px !important;
  margin: 0 auto;
}
@media screen and (max-width: 780px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr 1fr;
  }
} */
@media screen and (max-width: 480px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr ;
  justify-content: center;
  /* margin: 0 auto; */
  }
}
@media only screen and (max-width: 1300px) and (min-width: 481px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  }
}
.carousal .react-multiple-carousel__arrow--right {
  display: none;
}
.carousal .react-multiple-carousel__arrow--right .image {
    width: 100%;
    /* height: 100%; */
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .7;
    display: flex;
    align-items: center;
    justify-content: center;

}
/* .react-multiple-carousel__arrow--left {
  display: none;
} */