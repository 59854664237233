.pageContainer {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}
.flexico{
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}
.page {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-top: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-top: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-top: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-top: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-top: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-top: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-top: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-top: 40px;
    }
  }
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
} */

/* @supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: 40px;
    }
  }
} */

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-left: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-left: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-left: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-left: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-left: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-left: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-left: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-right: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-right: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-right: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-right: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-right: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-right: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .page {
      padding-right: 40px;
    }
  }
}

.pageContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.pageContent,
.pageContent * {
  font-family: 'Montserrat';
}

.access {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* margin-bottom: 130px; */
}
.dragIconContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .dragIconContainer {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIconContainer {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIconContainer {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIconContainer {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIconContainer {
    margin-right: 33.33px;
  }
}

.dragIcon {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .dragIcon {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIcon {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIcon {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIcon {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIcon {
    height: 33.33px;
    width: 33.33px;
  }
}
@media only screen and (max-width: 767px) {
  .access {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) {
  @supports (padding: max(0px)) {
    /* @media only screen and (max-width: 319px) {
      .access {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    } */
  }

  /* @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-top: 40px;
      }
    }
  } */
  /* @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  } */
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-bottom: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-bottom: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-bottom: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-bottom: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-bottom: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-bottom: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-bottom: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access {
        padding-bottom: 40px;
      }
    }
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-left: max(12px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-left: max(16px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-left: max(20px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-left: max(24px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-left: max(28px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-left: max(32px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-left: max(36px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1710px) {
      .access {
        padding-left: max(40px, env(safe-area-inset-left));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-left: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-left: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-left: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-left: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-left: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-left: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-left: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access {
        padding-left: 40px;
      }
    }
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-right: max(12px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-right: max(16px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-right: max(20px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-right: max(24px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-right: max(28px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-right: max(32px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-right: max(36px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1710px) {
      .access {
        padding-right: max(40px, env(safe-area-inset-right));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-right: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-right: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-right: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-right: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-right: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-right: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-right: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access {
        padding-right: 40px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .message {
    font-size: 34px;
    /* margin-bottom: 20px; */
    margin-top: 64px;
  }

  .profileLinksContainer {
    gap: 1px;
    /* padding: 44px 0; */
  }

  .profileLinksContainer.has1Item {
    -ms-grid-columns: 132px;
    grid-template-columns: 132px;
  }

  .profileLinksContainer.has2Item {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }

  .profileLinksContainer.has3Item {
    -ms-grid-columns: 132px 132px 132px;
    grid-template-columns: 132px 132px 132px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 132px 132px 132px;
    grid-template-columns: 132px 132px 132px;
  }

  .profileImage {
    border-radius: 10px;
    border-width: 3px;
    height: 132px;
  }

  .dp {
    height: 51px;
    width: 51px;
  }

  .profileName,
  .myContentButton {
    font-size: 12px;
  }

  .myContentButton {
    border-radius: 5px;
    height: 47px;
    margin-bottom: 96px;
    margin-top: 54px;
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .profileLinksContainer.has3Item {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .message {
    font-size: 35px;
    /* margin-bottom: 25px; */
  }

  .profileLinksContainer {
    gap: 1.125px;
  }

  .profileLinksContainer.has1Item {
    -ms-grid-columns: 151.875px;
    grid-template-columns: 151.875px;
  }

  .profileLinksContainer.has2Item {
    -ms-grid-columns: 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px;
  }

  .profileLinksContainer.has3Item {
    -ms-grid-columns: 151.875px 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px 151.875px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 151.875px 151.875px 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px 151.875px 151.875px;
  }

  .profileImage {
    border-radius: 6.25px;
    border-width: 2px;
    height: 151.875px;
  }

  .dp {
    height: 45px;
    width: 45px;
  }

  .profileName,
  .myContentButton {
    font-size: 12.5px;
  }

  .myContentButton {
    border-radius: 3.125px;
    height: 40.625px;
    margin-top: 28.750000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .message {
    font-size: 42px;
    /* margin-bottom: 30px; */
  }

  .profileLinksContainer {
    gap: 1.75px;
  }

  .profileLinksContainer.has1Item {
    -ms-grid-columns: 182.25px;
    grid-template-columns: 182.25px;
  }

  .profileLinksContainer.has2Item {
    -ms-grid-columns: 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px;
  }

  .profileLinksContainer.has3Item {
    -ms-grid-columns: 182.25px 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px 182.25px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 182.25px 182.25px 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px 182.25px 182.25px;
  }

  .profileImage {
    border-radius: 7.5px;
    border-width: 2px;
    height: 182.25px;
  }

  .dp {
    height: 54px;
    width: 54px;
  }


  .profileName,
  .myContentButton {
    font-size: 15px;
  }

  .myContentButton {
    border-radius: 3.75px;
    height: 48.75px;
    margin-top: 34.5px;
    width: 282px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .message {
    font-size: 49.875px;
    /* margin-bottom: 35.625px; */
  }

  .profileLinksContainer {
    gap: 1.078125px;
  }

  .profileLinksContainer.has1Item {
    -ms-grid-columns: 216.42188px;
    grid-template-columns: 216.42188px;
  }

  .profileLinksContainer.has2Item {
    -ms-grid-columns: 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px;
  }

  .profileLinksContainer.has3Item {
    -ms-grid-columns: 216.42188px 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px 216.42188px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 216.42188px 216.42188px 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px 216.42188px 216.42188px;
  }

  .profileImage {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 216.421875px;
  }

  .dp {
    height: 64.125px;
    width: 64.125px;
  }

  .profileName,
  .myContentButton {
    font-size: 17.8125px;
  }

  .myContentButton {
    border-radius: 4.453125px;
    height: 57.89062499999999px;
    margin-top: 40.96875px;
    width: 334.875px;
  }
}

@media only screen and (min-width: 1710px) {
  .message {
    font-size: 56px;
    /* margin-bottom: 40px; */
  }

  .profileLinksContainer {
    gap: 2px;
  }

  .profileLinksContainer.has1Item {
    -ms-grid-columns: 243px;
    grid-template-columns: 243px;
  }

  .profileLinksContainer.has2Item {
    -ms-grid-columns: 243px 243px;
    grid-template-columns: 243px 243px;
  }

  .profileLinksContainer.has3Item {
    -ms-grid-columns: 243px 243px 243px;
    grid-template-columns: 243px 243px 243px;
  }

  .profileLinksContainer.has4Item {
    -ms-grid-columns: 243px 243px 243px 243px;
    grid-template-columns: 243px 243px 243px 243px;
  }

  .profileImage {
    border-radius: 10px;
    border-width: 3px;
    height: 243px;
  }

  .dp {
    height: 72px;
    width: 72px;
  }

  .profileName,
  .myContentButton {
    font-size: 20px;
  }

  .myContentButton {
    border-radius: 5px;
    height: 65px;
    margin-top: 46px;
    width: 376px;
  }
}

.message {
  color: #fff;
  font-weight: 900;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .message .messageSpace {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .message .messageBreak {
    display: none;
  }
}

@media only screen and (max-width: 319px) {
  .message {
    width: 200px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .message {
    width: 280px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .message {
    width: 430px;
    max-width: 80%;
  }
}

.removeProjectorText {
  margin-top: 7px !important;
}

.profileLinksContainer {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* margin: auto; */
}

.accessButtonBelow {
  display: block !important;
}

.accessButtonBelow {
  display: block !important;
}

.profileLink {
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
}
.profileLinkDnd {
  color: #fff;
  text-align: center;
  margin-right: 20px;
  width: 100%;
}
.profileLinkDnd:not(:first-child) {
  margin-top: 15px;
  }
.requestProfileAccess {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  margin-top: 4%;
}
.blockReq {
  background-color: transparent;
  border: none;
  opacity: 0.3;
  margin: 0;
  padding: 0;
  margin-top: 4%;
}

.requestProfileAccess .profileImage {
  /* background-color: #31343e;
  border: none; */
}

.requestProfileAccess .dp {
  height: 56px !important;
  width: 56px !important;
}
.blockReq .dp {
  height: 56px !important;
  width: 56px !important;
}

.requestProfileAccess .bottomDp {
  height: 30px !important;
  width: 30px !important;
}
.blockReq .bottomDp {
  height: 30px !important;
  width: 30px !important;
}

.profileImage {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border-color: #5aa5ef;
  border-style: solid; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.dp {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

@media only screen and (max-width: 767px) {
  .dp {
    height: 58px;
    width: 52px;
  }
}

@media only screen and (min-width: 768px) {
  .dp {
    height: 64px;
    width: 64px;
  }
}

.profileName {
  display: inline-block;
  font-weight: 600;
  font-size: 1.5rem;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 15%;
}
.profileNameDnd {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .profileName {
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 899px) {
  .profileName {
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 20px;
  }
}

.requestProfileAccess .profileName {
  color: #5AA5EF;
  font-size: 0.8rem !important;
}
.blockReq .profileName {
  color: grey;
  font-size: 0.8rem !important;
}

.myContentButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid #6d6f76;
  color: #6d6f76;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 24px;
}

.modal {
  border-radius: 12px;
}

.modal :global(.MuiDialog-paper) {
  margin: calc(var(--site-padding)) !important;
  width: 100%;
}

.modalHeading {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .modalHeading {
    padding: 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .modalHeading {
    padding: 24px 24px 16px 24px !important;
  }
}

.modalHeadingContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 419px) {
  .modalHeadingContent {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .modalHeadingContent {
    font-size: 17px;
  }
}

.addPersonIconContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #5aa5ef;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: auto;
  margin-right: 12px;
}

@media only screen and (max-width: 767px) {
  .addPersonIconContainer {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .addPersonIconContainer {
    height: 40px;
    width: 40px;
  }
}

.addPersonIcon {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 767px) {
  .modalContent {
    padding: 12px 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .modalContent {
    padding: 0 24px !important;
  }
}

.modalEmailInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f8f9fa;
  border: 2px solid transparent;
  border-left: none;
  border-radius: 4px 4px 0 0;
  border-right: none;
  color: #000;
  font-size: 16px;
  height: 44px;
  margin-top: 16px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.modalEmailInput::-webkit-input-placeholder {
  color: #8e8e8e;
}

.modalEmailInput:-ms-input-placeholder {
  color: #8e8e8e;
}

.modalEmailInput::-ms-input-placeholder {
  color: #8e8e8e;
}

.modalEmailInput::placeholder {
  color: #8e8e8e;
}

.modalEmailInput:focus {
  border-bottom-color: #5aa5ef;
}

.personButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 12px;
  text-align: left;
  width: 100%;
}

@media all and (hover: hover) {
  .personButton:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {

  .personButton:active,
  .personButton:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.personButtonDP {
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  margin-right: 12px;
  position: relative;
  width: 40px;
}

.personDPImage,
.personDPText {
  border-radius: 50%;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.personDPText {
  background-color: #14f47b;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.5;
}

.personButtonTextContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.personButtonName,
.personButtonEmail {
  font-size: 14px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.personButtonName {
  color: #000000;
  text-transform: capitalize;
}

.personButtonEmail {
  color: #63676c;
  text-transform: lowercase;
}

.modalActions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .modalActions {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    padding: 14px 18px 14px 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .modalActions {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    padding: 18px 24px !important;
  }
}

.modalDoneButton {
  font-size: 16px;
  height: 44px !important;
  text-transform: capitalize;
}

.vewMyContent {
  margin-top: 0;
  color: #fff;
}

.manageProjectorButton {
  border: 1px solid #6D6F76 !important;
  color: #6D6F76 !important;
  margin-bottom: 2% !important;
  text-transform: none !important;
  /* width: 15%; */
  min-width: 85px !important;
  max-width: 200px;
  z-index: 1000;
  cursor: pointer;
}

.bottomQuarterEcllipse {
  max-height: 130px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

.ovalShape {
  position: relative;
  bottom: 30px;
  filter: blur(50px);
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileContainerDnd {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.manageProjectorButton:hover {
  background-color: #31343e !important;
}

.customRightArrow {
  /* right: 20% !important;
  top: calc(50% - 120px) !important; */
  top: 25% !important;
  position: absolute;
}

.customLeftArrow {
  /* left: 20% !important;
  top: calc(50% - 120px) !important; */
  top: 25% !important;
  position: absolute;
}

.customContainerClass {
  position: unset !important;
}

.carouselWrapper {
  height: 300px;
}

@media only screen and (max-width: 464px) {
  .customContainerClass {
    width: 200px !important;
  }
}

@media only screen and (min-width: 464px) and (max-width: 1024px) {
  .customContainerClass {
    width: 400px !important;
  }

  .customArrowButtonGroup {
    top: 45%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1700px) {
  .customContainerClass {
    width: 800px !important;
  }

  .profileItem{
    max-width: 200px;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2652px) {
  .customContainerClass {
    width: 1275px !important;
  }

  .profileItem{
    max-width: 250px;
  }
}

@media only screen and (min-width: 3000px) {
  .customContainerClass {
   width: 2000px !important;
  }
}

.customArrowButtonGroup {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 25%;
  width: 80%;
  left: 10%;
}

@media only screen and (max-width: 464px) {
  .customArrowButtonGroup {
    top: 45%;
  }

}


@media only screen and (min-width: 420px) and (max-width: 767px) {
  .customArrowButtonGroup {
    top: 36%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .customArrowButtonGroup {
    top: 32%;
  }
}

.bottomName {
  margin-top: 15px;
  margin-bottom: 15px;
}

.customDotListClass {
  margin-top: 10px;
  margin-bottom: 10px;
}

.profileLinksContainer {
  margin-top: 4%;
  margin-bottom: 4%;
}
