.accesswrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* max-height: auto; */
    /* position: fixed; */
}

.leftPart {
    /* flex-grow: 1; */
    text-align: center;
    /* display: inline-block; */
    /* position:relative; */
    float: left;
    width: 50%;
}

.rightPart {
    /* flex-grow: 1; */
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
    float: right;
    width: 50%;
}

@media only screen and (max-width: 767px) {
    .leftPart {
      display: none;
    }
    .rightPart {
        text-align: center;
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .accessBox {
        max-width: 261px !important;
    height: 261px !important;
    }
    .signUpButton {
        min-width: 160px !important;
    }
    .yellowCap {
        left: 12% !important;
    }
}

@media only screen and (min-width: 768px) {
    .signUpButton {
        min-width: 148px !important;
    }
}

.accessBox {
    /* max-width: 400px; */
    /* min-height: 350px; */
    /* max-height: 300px; */
    background-color: #fff;
    border-radius: 50px;
    margin-top: 50%;
}

.accessTitle {
    color: #000;
    font-weight: 700;
    font-size: 30px;
    text-align: left !important;
    /* padding: 23px; */
}

.signUpButton {
    color: #fff;
    text-transform: none;
    border-radius: 30px !important;
    border: 1px solid #707070;
    background-color: #0075FF;
    height: 51px !important;
    font-size: 19px !important;
    float: left;
    /* margin-left: 20px !important; */
    font-weight: 500 !important;
}

.imageBox {
  display: inline-block;
  position:relative;
}

.image {
    max-width: 90%;
    max-height: 100%;
    width: 90%;
    float: right;
    /* max-width: 100%; */
   
}

.description {
    color: #000;
    font-size: 18px;
    text-align: left !important;
    /* padding: 0 23px; */
}

.treeImage {
    position: absolute;
    right: 60%;
    top: 69%;
    width: 25%;
    max-width: 25%;
}

.yellowSquare {
    /* position: absolute; */
    right: 60%;
    top: 69%;
    width: 23%;
    max-width: 23%;
}

.accessContainer {
  display: inline-block;
  position:relative;
}

.yellowCap {
    position: absolute;
    top: 36%;
    left: 44%;
}

@media only screen and (max-width: 767px) {
    .accessBox {
        width: 292px;
        height: 227px;
        border-radius: 27px;
        padding: 23px;
      }
      .yellowCap {
          left: 55% !important;
          width: 93% !important;
          top: 40%;
      }
      .accessTitle {
          font-size: 20px !important;
          padding-bottom: 0px;
      }
      .description {
          font-size: 12px;
      }
      .accessContainer {
          margin-bottom: 7px;
      }
      .signUpButton {
        height: 33px !important;
        font-size: 15px !important;
      }
      .rightArrow {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .accessBox {
        width: 292px;
        height: 227px;
        border-radius: 27px;
        padding: 23px;
      }
      .yellowCap {
          left: 55% !important;
          width: 93% !important;
          top: 40%;
      }
      .accessTitle {
          font-size: 20px !important;
          padding-bottom: 0px;
      }
      .description {
          font-size: 12px;
      }
      .accessContainer {
          margin-bottom: 7px;
      }
      .signUpButton {
        height: 33px !important;
        font-size: 15px !important;
      }
      .rightArrow {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .accessBox {
        width: 330px;
        height: 265px;
        border-radius: 25px;
        padding: 20px;
      }
      .yellowCap {
          left: 55% !important;
          width: 95% !important;
          top: 40%;
      }
      .accessTitle {
          font-size: 25px !important;
          padding-bottom: 0px;
      }
      .description {
          font-size: 14px;
      }
      .accessContainer {
          margin-bottom: 55px;
      }
      .signUpButton {
        height: 42px !important;
        font-size: 16px !important;
      }
      .rightArrow {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .accessBox {
        width: 395px;
        height: 320px;
        padding: 30px;
      }
      .yellowCap {
          left: 45% !important;
      }
      .accessTitle {
          font-size: 30px !important;
          padding-bottom: 2px;
      }
      .description {
          font-size: 17px;
      }
      .accessContainer {
          margin-bottom: 28px;
      }
      .signUpButton {
          height: 43px !important;
          font-size: 18px !important;
      }
      .rightArrow {
        position: relative;
        top: 6px;
        left: 16px;
    } 
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .accessBox {
        width: 487px;
        height: 390px;
        padding: 40px;
      }
      .yellowCap {
          left: 55% !important;
      }
      .accessTitle {
          font-size: 37px !important;
      }
      .description {
          font-size: 19px;
      }
      .accessContainer {
          margin-bottom: 28px;
      }
      .rightArrow {
        position: relative;
        top: 7px;
        left: 16px;
    } 
  }
  
  @media only screen and (min-width: 1710px) {
    .accessBox {
      width: 500px;
      height: 400px;
      padding: 50px;
    }
    .yellowCap {
        left: 54% !important;
    }
    .accessTitle {
        font-size: 40px !important;
    }
    .description {
        font-size: 19px;
    }
    .rightArrow {
        position: relative;
        top: 6px;
        left: 16px;
    } 
  }

.signUpButtonWrapper {
    display: inline-flex;
}

.rightArrow {
    width: 12px;
    height: 12px;
}