.pageTitle {
  color: #02071a;
}

.sectionTitle {
  color: #000;
}

.pageTitle,
.sectionTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .pageTitle {
    font-size: 23px;
    line-height: 26.875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pageTitle {
    font-size: 23px;
    line-height: 26.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageTitle {
    font-size: 26.25px;
    line-height: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageTitle {
    font-size: 31.171875000000004px;
    line-height: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .pageTitle {
    font-size: 35px;
    line-height: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .sectionTitle {
    font-size: 23px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .sectionTitle {
    font-size: 23px;
    margin-top: 3.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sectionTitle {
    font-size: 24.750000000000004px;
    margin-top: 4.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sectionTitle {
    font-size: 29.390625000000004px;
    margin-top: 5.34375px;
  }
}

@media only screen and (min-width: 1710px) {
  .sectionTitle {
    font-size: 33px;
    margin-top: 6px;
  }
}
