.switch {
  border: none;
  border-radius: var(--toggle-height);
  cursor: pointer;
  height: var(--toggle-height);
  margin: 0;
  position: relative;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  width: calc(2.5 * var(--toggle-height));
}

.button {
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
          box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  height: calc(1.5 * var(--toggle-height));
  position: absolute;
  top: calc(-0.25 * var(--toggle-height));
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  width: calc(1.5 * var(--toggle-height));
}

.button:not(.isOn) {
  left: calc(-0.25 * var(--toggle-height));
}

.button.isOn {
  left: calc(1.25 * var(--toggle-height));
}
