.main_page {
  font-size: inherit;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.redColorExpired {
  color: #F80808;
  font-weight: 500;
  font-size: 12px;
}
.headings {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}
.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /* background-color: blue; */
}
.cardContainer {
  width: 95%;
  max-width: 800px;
  padding: 1rem;
  /* background-color: red; */
}
.cardDetails {
  padding: 1rem 0;
}
.primary_heading {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
.secondary_heading {
  font-size: 1rem;
  font-weight: 500;
}
.button_container {
  display: flex;
  justify-content: center;
}
.button_container > button {
  font-size: 1rem;
  /* width: 45%; */
  padding: 0.5rem 1.2rem;
  outline: none;
  border: #ff0000 2px solid;
  color: #ff0000;
  background-color: #ffffff;
  /* width: 100%; */

  margin: 0.5rem 0;
  font-weight: 500;
  cursor: pointer;
}

.button_container > button:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.labelLinks {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.link {
  color: #1172d2;
  font-weight: 500;
  cursor: pointer;
}
.firstLabel,
.colorBlack {
  font-weight: 700;
  color: #02071a;
}
.secondLabel {
  color: #757575;
}
.preferred {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #02071a;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 0.6rem;
  border: 1px #707070 solid;
  border-radius: 6px;
  padding: 3px 0.8rem;
  cursor: pointer;
  height: max-content;
}

.preferred:hover {
  background-color: #757575;
}
.message {
  font-weight: 500;
  padding: 1rem 0;
  font-size: 0.8rem;
}
.editButton {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: black;
}
.editButton:hover:not(:last-child, :disabled) {
  color: #1172d2;
}
.editButton:hover:not(:first-child) {
  color: #ff0000;
}
.editButtonsMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headerTheme {
  background-color: black;
}

.dropDownAnimation  {
  animation: growOut 700ms ease-in-out forwards;
  transform-origin: top center
}

@keyframes growOut {
  0% {
     transform: scale(0)
 }
 80% {
     transform: scale(1.1)
 }
 100% {
     transform: scale(1)
 }
}

.secondLink:hover {
  color: #000;
}