.header {
  width: 100%;
}

.headerContent {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .headerContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .headerContent {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.homeLink {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  text-decoration: none;
}

.headerLinksContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .headerLinksContainer {
    margin-top: 44px;
  }
}

.headerLink {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .headerLink {
    font-size: 12px;
  }
  .headerLink:first-of-type {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headerLink {
    margin-left: 16.40625px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headerLink {
    margin-left: 21.875px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headerLink {
    margin-left: 16px;
    font-size: 16px !important;
    height: 40px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerLink {
    margin-left: 20px;
    font-size: 20px !important;
    height: 48px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .headerLink {
    margin-left: 24px;
    font-size: 24px !important;
    height: 56px !important;
  }
}
