.planWrapper {
  text-align: center;
  padding-top: 6rem;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .planWrapper {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .planWrapper {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .planWrapper {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .planWrapper {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .planWrapper {
    max-width: 1536px;
  }
}
.heroHeading {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: none;
}

.description {
  color: #707070;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  text-transform: none;
  font-size: 20px;
}

@media only screen and (max-width: 899px) {
  .heroHeading {
    font-size: 45px;
    margin-bottom: 140px;
    margin-top: 6rem;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroHeading {
    font-size: 50px;
    margin-bottom: 7rem;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroHeading {
    font-size: 55px;
    margin-bottom: 8rem;
  }
}

@media only screen and (min-width: 1710px) {
  .heroHeading {
    font-size: 60px;
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .useList {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .useList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.planBox {
  min-width: 400px;
  min-height: 370px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.blueBackGround {
  background-color: #0075FF;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.title {
  color: #fff;
  font-size: 30px;
  padding: 17px;
  font-weight: 700;
}

.planDescription {
  color: #1D263A;
  font-size: 15px;
}
.horizontalLine {
  margin: 30px;
}

.planDetails {
  color: #000;
  font-size: 18px;
  padding: 0 20px;
}

.signUpButton {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border: 1px solid #707070;
  background-color: #0075FF;
  height: 51px !important;
  margin: 80px 10px 30px 10px;
  font-size: 18px !important;
  min-width: 280px !important;
}

.textColourWhite {
  color: #fff !important;
}

.backGroundWhite {
  background-color: #fff !important;
}

.textColourBlue {
  color: #0075FF !important;
}

.planNameWrapper {
  text-align: left;
}

.planPriceWrapper {
  text-align: right;
}

.price {
  color: #000;
  font-size: 30px;
}

.useWrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .useWrapper {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .useWrapper {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
  }
}

.useFrame {
  color: #fff;
  height: 300px;
  max-width: 350px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center bottom;
  object-position: center bottom;
  position: relative;
  width: 100%;
}

.firstIconChange {
  height: 329px !important;
  max-width: 270px !important;
}

.secondIconChange {
  height: 377px !important;
  max-width: 418px !important;
  left: 22px;
}

.adjustPosition1 {
  position: relative !important;
  bottom: 28px !important;
}

.adjustPosition2 {
  position: relative !important;
  bottom: 68px !important;
  left: 5px !important;
}
.adjustTitle {
  position: relative;
  bottom: 12px;
}

.contentWrapper {
  margin-top: 12px;
}