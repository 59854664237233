.inputGrid {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 430px) {
  .inputGrid {
    gap: 20px;
    -ms-grid-columns: 1fr !important;
        grid-template-columns: 1fr !important;
        padding-right: 12px;
  }
}
@media only screen and (max-width: 889px) {
  .inputGrid {
    margin-left: 5px;
  }
}


@media only screen and (min-width: 420px) and (max-width: 767px) {
  .inputGrid {
    gap: 20px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .inputGrid {
    gap: 24px 28px;
    -ms-grid-columns: 174px 174px 174px;
        grid-template-columns: 174px 174px 174px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .inputGrid {
    gap: 28.5px 45.421875px;
    -ms-grid-columns: 206.625px 206.625px 206.625px;
        grid-template-columns: 206.625px 206.625px 206.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .inputGrid {
    gap: 15px 51px;
    -ms-grid-columns: 232px 232px 232px;
        grid-template-columns: 232px 232px 232px;
  }
}
