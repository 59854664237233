.heroHeading {
    color: #FFFFFF;
    font-weight: normal;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
  }
  
  @media only screen and (max-width: 899px) {
    .heroHeading {
      font-size: 34px;
      margin-bottom: 16.25px;
      margin-top: 28px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    .heroHeading {
      font-size: 45px;
      margin-bottom: 19.5px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .heroHeading {
      font-size: 50px;
      margin-bottom: 23.15625px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .heroHeading {
      font-size: 55px;
      margin-bottom: 26px;
    }
  }
  .headingWrapper {
      width: 100%;
      text-align: center;
      /* margin-top: 40px; */
  }

  .viewSharePreserveWrapper {
    padding: 10px;
  }

  .textStyle {
      font-size: 30px;
      padding: 0 20px;
  }

  .signUpButton {
    
    color: #fff;
    text-transform: none;
    border-radius: 30px !important;
    border: 1px solid #707070;
    background-color: #084FF9;
    box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset;
    -webkit-box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset;
    height: 51px !important;
    margin: 80px 10px 30px 10px;
  }

  .deviceWrapper {
    margin-top: -6rem;
      /* display: inline; */
  }
  .deviceIcon {
    margin: 0 20px;
  }
  .adjustChromCastIcon {
    position: relative;
    top: 97px;
  }

  .adjustFireTV {
    position: relative;
    top: 25px;
  }

  @media only screen and (max-width: 1260px) {
    .hideIcon {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1260px) {
    .deviceWrapper {
      margin-top: 3rem !important;
    }
  }

  @media only screen and (max-width: 392px) {
    .deviceWrapper {
      display: flex;
    }
  }