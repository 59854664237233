@import url('https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98');
.SAgreement .lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
.SAgreement .lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
.SAgreement .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.SAgreement ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.SAgreement .lst-kix_list_3-0 > li:before {
  content: '\0025cf  ';
}
.SAgreement .lst-kix_list_3-1 > li:before {
  content: 'o  ';
}
.SAgreement .lst-kix_list_3-2 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.SAgreement .lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.SAgreement ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
.SAgreement .lst-kix_list_3-5 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_3-4 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
.SAgreement .lst-kix_list_3-3 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_3-8 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.SAgreement .lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.SAgreement .lst-kix_list_3-6 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.SAgreement .lst-kix_list_3-7 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.SAgreement .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.SAgreement ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.SAgreement ol.lst-kix_list_2-2 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-3 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
.SAgreement .lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.SAgreement ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
.SAgreement ol.lst-kix_list_2-1 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_4-8 > li:before {
  content: '' counter(lst-ctn-kix_list_4-8, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-7 > li:before {
  content: '' counter(lst-ctn-kix_list_4-7, upper-latin) '. ';
}
.SAgreement ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.SAgreement ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
.SAgreement ol.lst-kix_list_2-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-7 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.SAgreement ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
.SAgreement .lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.SAgreement .lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.SAgreement .lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
.SAgreement ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
.SAgreement ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
.SAgreement ol.lst-kix_list_1-3 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.SAgreement .lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
.SAgreement .lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.SAgreement ol.lst-kix_list_1-5 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
.SAgreement .lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
.SAgreement ol.lst-kix_list_1-1 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-7 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
.SAgreement ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-2 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
.SAgreement ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
.SAgreement .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
.SAgreement ol.lst-kix_list_1-8 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-6 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-3 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.SAgreement .lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, upper-latin) '. ';
}
.SAgreement .lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
.SAgreement .lst-kix_list_4-1 > li:before {
  content: '' counter(lst-ctn-kix_list_4-1, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.SAgreement ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.SAgreement .lst-kix_list_4-4 > li:before {
  content: '' counter(lst-ctn-kix_list_4-4, upper-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.SAgreement .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.SAgreement .lst-kix_list_4-3 > li:before {
  content: '' counter(lst-ctn-kix_list_4-3, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-5 > li:before {
  content: '' counter(lst-ctn-kix_list_4-5, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-2 > li:before {
  content: '' counter(lst-ctn-kix_list_4-2, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-6 > li:before {
  content: '' counter(lst-ctn-kix_list_4-6, upper-latin) '. ';
}
.SAgreement .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
.SAgreement ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.SAgreement ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.SAgreement ol.lst-kix_list_4-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-1 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
.SAgreement ol.lst-kix_list_4-2 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-3 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
.SAgreement ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
.SAgreement ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
.SAgreement ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.SAgreement ol.lst-kix_list_4-8 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
.SAgreement ol.lst-kix_list_4-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
}
.SAgreement .lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
}
.SAgreement ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.SAgreement ol.lst-kix_list_4-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-7 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-3 > li:before {
  content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
}
.SAgreement .lst-kix_list_1-4 > li:before {
  content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
}
.SAgreement .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.SAgreement .lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.SAgreement .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.SAgreement .lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.SAgreement .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.SAgreement .lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
}
.SAgreement .lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
}
.SAgreement li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.SAgreement .lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
.SAgreement .lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.SAgreement .lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.SAgreement .lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.SAgreement .lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
.SAgreement ol {
  margin: 0;
  padding: 0;
}
table td,
.SAgreement table th {
  padding: 0;
}
.SAgreement .c5 {
  /* background-color: #ffffff; */
  color: #0070e0;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c0 {
  margin-left: 60pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 9pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c17 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.SAgreement .c7 {
  /* background-color: #ffffff; */
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c2 {
  margin-left: 58.5pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c15 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c8 {
  /* background-color: #ffffff; */
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c3 {
  /* color: #0d0d0d; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c19 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c4 {
  /* color: #0d0d0d; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c1 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 18pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c10 {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c24 {
  margin-left: 60pt;
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c28 {
  /* background-color: #ffffff; */
  -webkit-text-decoration-skip: none;
  /* color: #000000; */
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.SAgreement .c12 {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.SAgreement .c9 {
  font-size: 10pt;
  font-family: 'Arial';
  /* color: #0d0d0d; */
  font-weight: 400;
}
.SAgreement .c16 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.SAgreement .c11 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.SAgreement .c18 {
  font-weight: 400;
  font-size: 12pt;
  font-family: 'Times New Roman';
}
.SAgreement .c22 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 700;
}
.SAgreement .c14 {
  font-weight: 400;
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement .c26 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.SAgreement .c21 {
  margin-left: 18pt;
  padding-left: 0pt;
}
.SAgreement .c27 {
  vertical-align: baseline;
  font-style: normal;
}
.SAgreement .c13 {
  color: inherit;
  text-decoration: inherit;
}
.SAgreement .c23 {
  padding: 0;
  margin: 0;
}
.SAgreement .c20 {
  height: 12pt;
}
.SAgreement .title {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement li {
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement p {
  margin: 0;
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement h1 {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h2 {
  padding-top: 18pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h3 {
  padding-top: 14pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h4 {
  padding-top: 12pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h5 {
  padding-top: 11pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h6 {
  padding-top: 10pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement {
  padding: 1rem 2rem;
}
