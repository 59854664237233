@media only screen and (max-width: 767px) {
  .logoImage {
    height: 52px !important;
  }
}
.logoDiv {
  margin-bottom: 44px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.logoImage {
  height: 106px;
}
.flexico {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}
.each {
  max-width: 444px;
  display: block; /* Ensure block-level behavior */
}

.each > img {
  display: block; /* Ensure the image is treated as a block element */
  margin: 0 auto; /* Center the image horizontally */
}
.headx {
  font-family: "Montserrat" !important;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: white;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.marginTop44{
  margin-top: 44px;
}
.subheadx {
  font-family: "Montserrat" !important;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  white-space: pre-wrap;
  color: #808080;
  margin-bottom: 47px;
}
@media only screen and (min-width: 768px) {
  .logo {
    margin-bottom: 64px;
    margin-top: 24px;
  }
}

.authPage {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  min-height: 100vh;
  position: relative;
  width: 100%;
  padding: 40px;
}

.authPage,
.authPage * {
  font-family: "Montserrat" !important;
}
.backLink {
  color: #888888;
  position: absolute;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .marginTop44{
    margin-top: 30px;
  }
  .logo {
    margin-bottom: 17px;
    margin-top: 77px;
  }
  .authPage {
    padding: 20px 20px 100px 20px;
  }
  .flexico {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
  }
  .each {
    max-width: 430px;
    display: block; /* Ensure block-level behavior */
  }
  .each > img {
    width: 105px;
  }
  .headx {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 19px;
  }
  .subheadx {
    font-size: 13px;
    text-align: center;
    color: #808080;
    margin-bottom: 25px;
  }
}
@media all and (hover: hover) {
  .backLink:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .backLink:active,
  .backLink:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .backLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
  }
}

@media only screen and (min-width: 768px) {
  .backLink {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .backLink {
    font-size: 15.625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .backLink {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backLink {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .backLink {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .authPageContent {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .authPageContent {
    max-width: 650px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .authPageContent {
    max-width: 700px;
  }
}

@media only screen and (min-width: 1710px) {
  .authPageContent {
    max-width: 750px;
  }
}
