.body {
    background-color: white;
    margin: auto;
    width: 70%;
    padding: 4%;
    text-align: center;
}

.headerContent {
    justify-content: center;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 40px;
}

.slick-next {
    right: -35px;
    margin-top: -30px;
}

.slick-prev {
    left: -35px;
    margin-top: -30px;
}

.slick-arrow {
    size: 10px;
}

.closeButton {
    margin-top: 15px;
    margin-right: 15px;
}

.headerText {
    font-weight: bold;
    font-size: large;
    margin-top: 0px;
}

.slider {
    margin-top: 10px;
}

.updateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(85, 178, 212);
    width: 100px;
    height: 100px;
    margin-left: 330px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.updateButton .Notification {
    font-size: 30px;
    color: rgb(9, 113, 232);
    height: 60px;
    width: 60px;
}

h3 {
    font-size: x-large;
    align-items: center;
    text-align: center;
}

form p {
    font-size: small;
    align-items: center;
    text-align: center;

    font-weight: 700;
}

button {
    color: white;
    background-color: rgb(52, 120, 230);
    width: fit-content;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40px;
    border: none;
    margin-top: 0px;
    /* margin-left: 280px; */
}

.promotionDescriptiontext {
    align-items: center;
    text-align: center;
    font-size: x-large;
    color: black;
    text-transform: none;
}

.promotionDescriptiontext span {
    color: rgb(61, 177, 231)
}