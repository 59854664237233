.gridssz {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    grid-row-gap: 38px;
    row-gap: 60px;
    grid-column-gap: 1fr;
    width: 100%;
  }

  .flexfifty{
    display: flex; 
  }
  .unhidePls {
    border: 2px solid #000000;
    border-radius: 5px;
    font-size: 18px;
    align-items: center;
    font-weight: 600;
    background: white;
    color: #000000;
    cursor: pointer;
    width: 100px;
    height: 29px;
    margin-left: auto;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
    display: flex;
  }
  .hiddenX{
    width: 30px;
    margin-left: 15px;
    opacity: 1;
    cursor: pointer;
  }
  .visibleEye{
    width: 30px;
    margin-left: 15px;
    opacity: .5;
    cursor: wait;
  }
  .notification {
    position: fixed;
    bottom: 10%;
    transform: translate(-50%, -10%);
    z-index: 1000000;
    left: 50%;
    padding: 5px 20px 7px 20px;
    background-color: #333;
    color: #fff;
    max-width: 450px;
    border-radius: 4px;
    opacity: 0;
    font-size: 15px;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .notification.show {
    opacity: 1;
  }
  .theDissmisal{
    position: fixed;
    bottom: 10%;
    transform: translate(-50%, -10%);
    z-index: 1000000;
    left: 50%;
    background-color: #333;
    padding: 15px 20px;
    border-radius: 10px;
    max-width: 600px;
    color: #fff;
  }
  .closeTillWait {

    opacity: 0;
    font-size: 15px;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .closeTillWait.show {
    opacity: 1;
  }
  .closerX{
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
  }
  .the_Hieghter {
    height: 350px;
    overflow: auto;
    width: 100%;
    padding-right: 40px;
  }
  .eyeCatcher {
    position: absolute;
    top: 50%;
    width: 30px;
    right: 15px;
  }
  .loading {
    text-align: center;
  }
  /* WebKit-based browsers */
  .the_Hieghter::-webkit-scrollbar {
    width: 8px; /* Adjust as needed */
  }
  
  .the_Hieghter::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .the_Hieghter::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }
  
  /* Mozilla Firefox */
  .the_Hieghter {
    scrollbar-width: thin;
    scrollbar-color: #cccccc transparent;
  }
  
  /* Microsoft Edge and Internet Explorer */
  .the_Hieghter {
    -ms-overflow-style: none; /* Hide scrollbar */
  }
  
  .the_Hieghter::-ms-scrollbar {
    display: none; /* Hide scrollbar */
  }
  .mySmallMail {
    margin: 0 0 0 15px;
    text-align: left;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: -0.44px;
    color: #b2b2b2;
    opacity: 1;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .widhthMax {
    max-width: 1508px;
    margin-right: auto;
  }
  .searcher::placeholder {
    font-size: 20px;
    color: gray;
  }
  .fullFledge {
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    height: 100%;
    top: 0;
    left: 0;
  }
  .theList {
    background-color: transparent !important;
    color: black;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: start;
  }
  .eachName {
    margin: 0;
    font-size: 22px;
    line-height: 1.25;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .modal {
    position: absolute;
    z-index: 999;
    width: 55%;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 30px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 12px;
    opacity: 1;
    z-index: 9999;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-circle {
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  .loading-circle_mini {
    width: 25px;
    height: 25px;
    animation: spin .5s linear infinite;
    display: flex;
    margin: 0 auto;
  }
  
  .circle {
    stroke: #333;
    stroke-dasharray: 80;
    stroke-dashoffset: 60;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 60;
    }
    50% {
      stroke-dashoffset: 0;
      stroke: #ccc;
    }
    100% {
      stroke-dashoffset: -60;
      stroke: #333;
    }
  }
  .marginTopic {
    margin-top: 25px;
  }
  .boldtext {
    font-family: "Poppins" !important;
    letter-spacing: -1.69px;
    color: #02071a;
    opacity: 1;
    font-size: 35px;
    font-weight: 600;
    margin: 0;
  }
  .flexcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nonBoldxtext {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.69px;
    color: #02071a;
    user-select: text;
    opacity: 1;
    font-size: 35px;
    font-weight: 500;
  }
  .linkSelectText {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    color: #5aa5ef;
    opacity: 1;
    font-size: 18px;
    font-weight: 300;
    user-select: all !important;
    transition: text-shadow 0.3s ease; /* Add a smooth transition */
  }
  
  .linkSelectText:hover {
    /* font-weight: 400; */
    text-shadow: 0 0 10px rgba(90, 165, 239, 0.7); /* Neon light effect */
  }
  
  .nonColurxtext {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    color: #02071a;
    opacity: 0.32;
    font-size: 18px;
    font-weight: 300;

  }
  .flextr {
    display: flex;
  }
  .buttonrEG {
    max-width: max-content;
    white-space: nowrap;
    height: 50px;
    margin: 0;
    cursor: pointer;
    background: #5aa5ef 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: 0px;
    font-size: 16px;
    padding: 16px 20px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
  }
  .invite {
    margin: 0;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.16px;
    color: #5aa5ef;
    opacity: 1;
    cursor: pointer;
  }
  .alignleft {
    text-align: right !important;
  }
  .inviteer {
    text-align: left;
    background: transparent;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.16px;
    cursor: pointer;
    color: #5aa5ef;
    font-size: 20px;
    opacity: 1;
    padding-left: 0;
  }
  .req {
    width: 50px;
    object-fit: cover;
    object-position: center;
  }
  .searcher {
    width: 100%;
    background: transparent;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border-bottom: 3px #1172d2 solid;
    height: auto;
    border-top: none;
    font-size: 25px;
    border-left: none;
    border-right: none;
  }
  .noMARGIN {
    margin: 0;
  }
  .margiTop {
    margin-top: 16px;
  }
  .userName {
    text-align: left;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-size: 30px;
    opacity: 1;
  }
  .userNameONmenu {
    text-align: left;
    font-family: Montserrat-Regular !important;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
  }
  .close {
    margin-left: auto;
  }
  .SearchResulter {
    height: 80px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    z-index: 121;
    position: absolute;
    width: 94%;
    background-color: #f8f9fa;
  }
  .asWhom {
    text-align: left;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.11px;
    font-size: 20;
    font-family: 600;
    color: #02071a;
    opacity: 1;
  }
  .copy{
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
  .modelFelx {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
  .flexico {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .flexTree {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
  }
  .mt {
    margin-top: 78px;
  }
  .absolute {
    position: absolute;
    right: 53px;
    top: 40%;
    cursor: pointer;
  }
  .relativez {
    position: relative;
  }
  .flexter {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: start;
    margin-bottom: 28px;
  }
  .rounder {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 16px;
    font-family: Montserrat-Regular;
    letter-spacing: -0.78px;
    color: #000000;
    opacity: 1;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  .rounderChar {
    border-radius: 100%;
    width: 55px;
    height: 55px;
    padding: 20px;
    font-family: Montserrat-Regular;
    letter-spacing: -0.78px;
    color: white;
    opacity: 1;
    font-weight: 700;
    font-size: 24px;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  .UserName {
    font-family: "Montserrat" !important;
    letter-spacing: -0.78px;
    font-weight: 600;
    font-size: 18px;
    margin-left: 15px;
    margin-bottom: 0;
    color: #000000;
    opacity: 1;
    margin-top: 0;
  }
  .edit {
    width: 20px;
    margin-left: auto;
  }
  .Usermail {
    font-family: "Montserrat" !important;
    letter-spacing: -0.78px;
    font-weight: 400;
    font-size: 14px;
    color: #b2b2b2;
    opacity: 1;
    margin: 0 0 0 15px;
    text-align: left;
  }
  .chipToInputBox {
    padding-top: 50px;
  }
  .chip{
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
  .adME {
    background: #5aa5ef 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    min-width: 109px;
    margin-left: auto;
    cursor: pointer;
    font-size: 25px;
    height: 62px;
    margin-top: 73px;
  }
  .slectedMailBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
    justify-content: start;
  }
  .remove {
    width: 100px;
    height: 29px;
    display: flex;
    margin: 0 0 0 auto;
    border: 2px solid #000000;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    background-color: transparent;
    letter-spacing: 0px;
    color: #ff0000;
    font-size: 18px;
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .flexManager {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexStarter {
    display: flex;
    gap: 100px;
    justify-content: start;
    align-items: start;
  }
  .accessHim {
    border: 2px solid #000000;
    border-radius: 5px;
    font-size: 18px;
    align-items: center;
    font-weight: 600;
    background: white;
    color: #000000;
    cursor: pointer;
    width: 100px;
    height: 29px;
    margin-left: auto;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
  }
  .Non_access {
    border: 2px solid red;
    margin-top: 0;
    background: white;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100px;
    height: 29px;
    opacity: 1;
    color: red;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .w100 {
    width: 100%;
  }
  .liex {
    height: 105px;
    width: 4px;
    background: #6e6f6e 0% 0% no-repeat padding-box;
    border: 1px solid #6e6f6e;
    border-radius: 2px;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .unhidePls {
      font-size: 14px;
    }
    .gridssz {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      row-gap: 10px;
      column-gap: 10px;
    }
    .the_Hieghter {
      height: auto;
      overflow: auto;
      width: 96%;
      padding-right: 10px;
    }
    .mySmallMail {
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  