* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-display: none;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  -webkit-font-kerning: auto;
  font-kerning: auto;
  -webkit-font-language-override: normal;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size-adjust: none;
  font-stretch: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  -webkit-font-variant-ligatures: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
}

body {
  font-family: "Poppins";
  margin: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grecaptcha-badge {
  display: none !important;
}
.spinex {
  border-top: 7px solid #007bff; /* Change the spinner color here */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #007bff;
  height: 20px;
  width: 20px;
}
:root {
  overflow-x: hidden;
}
.Toastify__toast {
  cursor: default !important;
  height: 75px !important;
}
.Toastify__toast-container--bottom-right {
  bottom: 3em !important;
}
.custom-toast {
}
/* Spinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
select > option {
  border: none;
}
.spinner {
  border: 7px solid rgba(0, 0, 0, 0.3);
  border-top: 7px solid #007bff; /* Change the spinner color here */
  border-radius: 50%;
  width: 44px;
  height: 44px;
  animation: spin 1s linear infinite; /* Apply the rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loaderspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 899px) {
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    height: 26.875px;
    width: 26.875px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #007bff;
    height: 32.25px;
    width: 32.25px;
  }
}

.theFiexedWidth {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 12222222;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.rotatingloader {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 10px solid #f3f3f3; /* Light grey border */
  border-top: 10px solid #3498db; /* Blue border */
  animation: loaderspin 1s linear infinite;
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #007bff;
    height: 38.296875px;
    width: 38.296875px;
  }
}
.socialLinks:hover {
  color: #007bff !important;
  cursor: pointer;
}
.socialLinks {
  color: white !important;
  cursor: pointer;
  all: unset;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 7px;
  height: 7px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  animation: dotPulse 1.5s infinite ease-in-out;
}

@keyframes dotPulse {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.MuiAutocomplete-option {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.fixedProgressBar {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: radial-gradient(closest-side at 50% 50%, #292e40 0, #07080c 100%);
  overflow: hidden;
}
.moving-progress-bar-container {
  width: 50%;
  height: 4px;
  background-color: #f0f0f0;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moving-progress-bar {
  width: 100%;
  height: 100%;
  position: relative;
}

.progress {
  width: 0%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  animation: progressAnimation 2s ease-in-out forwards; /* Adjust animation duration as needed */
}
.fullProgress {
  width: 98%;
  height: 100%;
  background-color: #d01717;
  position: absolute;
  top: 0;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: #007bff;
  }
  100% {
    width: 98%;
    background-color: #007bff;
  }
}
.progressRed {
  width: 0%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  animation: progressRed 1s ease-in-out forwards; /* Adjust animation duration as needed */
}

@keyframes progressRed {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: red;
  }
  100% {
    width: 98%;
    background-color: red;
  }
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px 0px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 0 0 0 !important;
}
@media only screen and (max-width: 319px) {
  :root {
    --site-padding: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  :root {
    --site-padding: 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  :root {
    --site-padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  :root {
    --site-padding: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  :root {
    --site-padding: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  :root {
    --site-padding: 32px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  :root {
    --site-padding: 36px;
  }
}

@media only screen and (min-width: 1710px) {
  :root {
    --site-padding: 40px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 40px);
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: 40px;
    }
  }
}

.image-item {
  /* padding: 20px 0 20px 0px; */
  /* width: auto !important; */
  /* aspect-ratio: 16 / 9; */
  padding-right: 15px !important;
  transform-style: initial;
  /* min-height: 300px;
  background-color: red; */
}
.image-item:not(:first-child) {
  /* padding-right: 15px !important; */
}
.spclMargin {
  /* margin: 0 7.5px !important; */
}
.image-item_images {
  width: max-content !important;
  /* margin-right: 30px; */
  transform-style: initial;
}
.image-item_images:not(:first-child) {
  padding-left: 15px;
}
.image-item_catergory {
  width: 310px !important;
  margin-right: 15px;
  transform-style: initial;
}
.image-item_catergoryViewMyProj {
  /* width: 310px !important; */
  padding-right: 15px;
  transform-style: initial;
}
.category-item {
  width: 150px !important;
  margin-right: 15px;
}

.react-multi-carousel-track {
  transform-style: initial;
  /* gap: 20px; */
}

.carousel-container {
  height: 270px;
}

.custom-dot-list-style {
  justify-content: flex-end;
  margin-right: 25px;
}

.react-multi-carousel-dot button {
  background: #fff;
}

.react-multi-carousel-dot--active button {
  background: rgb(52, 120, 230);
}
.carousel-item-padding-40-px:first-child {
  margin-left: -20px;
}
.carousel-item-padding-40-px {
  padding-left: 20px;
}
.carousel-item-padding-image:not(:first-child) {
  padding-left: 5px;
}
.carousel-item-padding-30-px {
  padding-left: 20px;
}
.carousel-item-padding-30-px:first-child {
  margin-left: -20px;
}
.custom_arrow {
  background: transparent !important;
  color: white;
  margin-bottom: 37px;
  width: 20px;
  height: 20px;
  /* display: none; */
}

.custom_arrow_profile {
  background: transparent !important;
  color: white;
  margin-bottom: 13px;
  width: 20px;
  height: 20px;
}

.custom_arrow:hover,
.custom_arrow_category:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  z-index: 1000 !important;
}

.custom_arrow_category {
  width: 20px;
  height: 20px;
}

.layout_arrow_category {
  margin-bottom: -6px;
  width: 10px;
  height: 10px;
  min-width: 25px;
  min-height: 25px;
}

.layout_arrow_category:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  z-index: 1000 !important;
}

/* .react-multiple-carousel__arrow:hover {
  background: transparent;
} */
.slide-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
}

.album-image-item {
  width: 211px !important;
  margin-right: 27px;
}
.image-gallery-left-nav {
  left: 25% !important;
}

.image-gallery-right-nav {
  right: 25% !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 100px;
  width: 80px;
}
.image-gallery-thumbnail {
  margin-top: 0px !important;
  height: auto !important;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: max-content !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  margin: 0 4px !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: flex !important;
  position: relative;
  justify-content: center !important;
}
@media only screen and (min-width: 1901px) {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 50% !important;
    margin-top: 0 !important;
  }
  .image-gallery-play-button {
    left: 50% !important;
    top: 50%;
    margin-top: 0;
    padding-left: 0px !important;
    transform: translate(-50%, -50%) !important;
  }
  .image-gallery-play-button > .image-gallery-svg {
    height: 60px !important;
    width: 60px !important;
  }
}
@media only screen and (max-width: 480px) {
  .image-gallery-play-button {
    left: 50% !important;
    top: 50% !important;
    margin-top: 0 !important;
    transform: translate(-50%, -50%);
    margin-left: 9px;
  }
  .image-gallery-thumbnail {
    width: max-content !important;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 65px;
    padding-right: unset !important;
    padding-left: unset !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 51% !important;
    margin-top: 0;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 48px !important;
    width: 24px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 749px) {
  .image-gallery-play-button {
    left: 45% !important;
    top: 45% !important;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 72px !important;
    width: 66px !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 45% !important;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1900px) {
  .image-gallery-play-button {
    left: 47% !important;
    margin-top: 0 !important;
    padding: 20px 0 !important;
    top: 41% !important;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 100px !important;
    width: 80px !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 45% !important;
  }
}
.react-multiple-carousel__arrow_photo {
  min-width: 42px;
  min-height: 113px;
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0) !important;
}

/* .react-multiple-carousel__arrow, .react-multiple-carousel__arrow_photo { */
/* min-width: 42px;
  min-height: 113px;
  border-radius: 0px; */
/* background-color: rgba(0, 0, 0, 0.5); */
/* background: rgba(255, 0, 0, 0) !important; */
/* } */

.custom_arrow_photolist {
  background: #707070;
  color: white;
  margin-bottom: 7px;
  min-height: 81px;
  border-radius: 0px !important;
  min-width: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  min-height: 100vh;
  /* max-height: calc(100vh - 0px); */
}

.image-gallery-thumbnails-wrapper {
  bottom: 84px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover !important;
  cursor: pointer;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 65px;
  padding-right: 6px;
  padding-left: 6px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid transparent;
  -ms-transform: scale(1.2);
  /* IE 9 */
  -webkit-transform: scale(1.2);
  /* Safari 3-8 */
  transform: scale(1.2);
}

.carousel-container {
  overflow: visible !important;
}

.image-item-whats-new {
  width: 320px !important;
  margin-right: 22px;
}

.carousel-profile-container-center {
  justify-content: center;
}

.align-center {
  display: flex;
  justify-content: center;
}
.align-first {
  display: flex;
  justify-content: center;
}

.carousel-container-for-whats-new {
  overflow-x: hidden;
  right: 0;
  overflow-wrap: break-word;
  height: 250px;
}

.carousel-container-album-banner {
  overflow: visible;
}

.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
  height: 300px;
}

@media only screen and (max-width: 319px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .carousel-profile-container {
    width: 600px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .carousel-profile-container {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .carousel-profile-container {
    width: 600px;
  }

  .custom_arrow_profile {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .carousel-profile-container {
    width: 600px;
  }

  .custom_arrow_profile {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .carousel-profile-container {
    width: 930px;
  }
}

.PhoneInput {
  background-color: #31343e;
  border: 2px solid #31343e;
  color: #ffffff;
  height: 60px;
  /* position: absolute; */
  width: 70%;
  border-radius: 5px;
}

.PhoneInputInput:focus {
  border-color: #6d6f76 !important;
}

.PhoneInputInput::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput::-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

/* .PhoneInputInput::placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 15px;
  font-weight: 600;
} */
.PhoneInputInput::placeholder {
  font-family: Montserrat-Regular;
  color: #8e8e8e;
  font-size: 19px;
}

.PhoneInputInput {
  font-size: 15px;
  font-family: Montserrat-Regular;
  background-color: transparent;
  opacity: 0.9;
  border: none;
  color: #fff;
}

.PhoneInputInput {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 15px;
}

.PhoneInputCountry {
  margin-left: 10px;
}

.carousel-container-album-banner {
  overflow: visible;
}

.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
}

.react-multiple-carousel__arrow--right {
  z-index: 98;
  position: absolute;
  top: 27%;
  right: 0px;
  margin-top: 7px;
}
.react-multiple-carousel__arrow--rightx {
  z-index: 98;
  position: absolute;
  top: 27%;
  right: 0px;
  /* margin-top: 8px; */
}

.react-multiple-carousel__arrow
  react-multiple-carousel__arrow--right_photolist {
  z-index: 98;
  position: absolute;
  top: 26%;
  right: 0px;
}

.react-multiple-carousel__arrow--left {
  left: 0px;
  z-index: 98;
  position: absolute;
  top: 27%;
  margin-top: 7px;
}
.react-multiple-carousel__arrow--leftx {
  left: 0px;
  z-index: 98;
  position: absolute;
  top: 27%;
  /* margin-top: 8px;  */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 0px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 0px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 4px;
  }
}
@media only screen and (max-width: 570px) {
  .align-first {
    display: flex;
    justify-content: start !important;
  }
  .carousel-container {
    overflow: auto !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 3px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    z-index: 98;
    position: absolute;
    top: 16%;
    right: 0px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile-item {
    scale: 0.9;
  }
}
@media only screen and (max-width: 767px) {
  .profile-item {
    scale: 0.8;
  }
}
