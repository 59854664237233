.iamfixed{
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
z-index: 1000000;
}
.cloud{
    object-fit: cover;
    width: 200px;
    height: 200px;
}