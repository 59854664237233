.settingsPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100vh - var(--header-height));
}

.pageContent {
  background-color: #fff;
  padding-left: 100px;
}

.contentWrapper {
  display: block;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media only screen and (max-width: 769px) {
  .contentWrapper {
    padding: 32px 16px;
  }
  .pageContent {
    background-color: #fff;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .contentWrapper {
    padding: 58.5px 36.5625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentWrapper {
    padding: 78px 48.75px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentWrapper {
    padding: 93.6px 58.5px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentWrapper {
    padding: 111.15px 69.46875px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1710px) {
  .contentWrapper {
    padding: 124.8px 78px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

.pageTitle {
  color: #02071a;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 899px) {
  .pageTitle {
    font-size: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageTitle {
    font-size: 21.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageTitle {
    font-size: 26.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageTitle {
    font-size: 31.171875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .pageTitle {
    font-size: 35px;
  }
}

.pageDescription {
  color: #02071a;
  font-weight: 300;
  opacity: 0.32;
}

@media only screen and (max-width: 899px) {
  .pageDescription {
    font-size: 17px;
    line-height: 1.3;
    margin: 8px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageDescription {
    font-size: 27.499999999999996px;
    margin: 23.125px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageDescription {
    font-size: 33px;
    margin: 27.75px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageDescription {
    font-size: 39.1875px;
    margin: 32.953125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .pageDescription {
    font-size: 44px;
    margin: 0;
  }
}

.pageDivider {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.18;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .pageDivider {
    margin-top: 22.2px;
    margin-bottom: 29.6px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageDivider {
    margin-bottom: 34.6875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageDivider {
    margin-bottom: 41.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageDivider {
    margin-bottom: 49.42969px;
  }
}

@media only screen and (min-width: 1710px) {
  .pageDivider {
    margin-bottom: 55.5px;
  }
}

.sidebar {
  background-color: #f0f0f0;
  display: block;
  min-height: calc(100vh - var(--header-height));
}

@media only screen and (max-width: 899px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .sidebar {
    width: 192.50000000000003px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sidebar {
    width: 231.00000000000003px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar {
    width: 274.3125px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar {
    width: 308px;
  }
}

.sidebarContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-height);
}

.sidebarLink {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #02071a;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-family: 'Montserrat';
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .sidebarLink {
    font-size: 12.5px;
    height: 68.75px;
    margin-top: 26.875px;
    padding: 0 53.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sidebarLink {
    font-size: 15px;
    height: 82.5px;
    margin-top: 32.25px;
    padding: 0 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebarLink {
    font-size: 17.8125px;
    height: 97.96875px;
    margin-top: 38.29688px;
    padding: 0 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebarLink {
    font-size: 20px;
    height: 110px;
    margin-top: 43px;
    padding: 0 86px;
  }
}

.currentSidebarLink {
  cursor: pointer;
}

.currentSidebarLink::before, .currentSidebarLink::after {
  content: ' ';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
}

.currentSidebarLink::before {
  background-color: #5aa5ef;
  left: 0;
  opacity: 0.08;
  width: 100%;
}

.currentSidebarLink::after {
  background-color: #5aa5ef;
  right: 0;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .currentSidebarLink::after {
    width: 4.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .currentSidebarLink::after {
    width: 5.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .currentSidebarLink::after {
    width: 6.234375px;
  }
}

@media only screen and (min-width: 1710px) {
  .currentSidebarLink::after {
    width: 7px;
  }
}
.headerTheme {
  background-color: black;
}
