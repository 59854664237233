.loader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2em var(--site-padding);
  position: relative;
  width: 100%;
}
.placeEnd {
  justify-content: center;
  display: block;
}

@media only screen and (max-width: 900px) {
  .placeEnd {
    justify-content: center;
    display: flex;
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
    text-align: center;
    align-items: center;
    width: max-content;
    height: max-content;
    margin-bottom: 10px;
  }
  .playlistMe {
    cursor: pointer;
    width: 44px;
    height: auto;
  }
  .loader {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .loader {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.loaderContent {
  display: inline;
  white-space: nowrap;
}

.page {
  background-color: #ffffff;
}

@media only screen and (max-width: 899px) {
  .pageTitle {
    display: none;
  }
}

.pageContent {
  padding-left: 103px !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  background-color: #f7f7f7;
}

@media only screen and (max-width: 1199px) {
  .name {
    margin-bottom: 11.51px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .name {
    margin-bottom: 11.625000000000002px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .name {
    margin-bottom: 13.804687500000002px;
  }
}

@media only screen and (min-width: 1710px) {
  .name {
    margin-bottom: 15.5px;
  }
}

.categoryContainer,
.categoryContainerImages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categoryContainer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .categoryContainer {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
}

.editIcon {
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .editIcon {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .editIcon {
    display: none;
  }
}

.dragIconContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .dragIconContainer {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIconContainer {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIconContainer {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIconContainer {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIconContainer {
    margin-right: 33.33px;
  }
}

.dragIcon {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .dragIcon {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dragIcon {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dragIcon {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dragIcon {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .dragIcon {
    height: 33.33px;
    width: 33.33px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentGroupContainer {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentGroupContainer {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentGroupContainer {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentGroupContainer {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentGroupContainer {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentGroupContainer {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentGroupContainer {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentGroupContainer {
      padding-top: 40px;
    }
  }
}

.contentGroupContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 540px 1fr;
  grid-template-columns: 540px 1fr;
}

.contentGroup {
  border-collapse: collapse;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .contentGroup {
    margin-bottom: 44px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroup {
    margin-bottom: 65.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroup {
    margin-bottom: 78.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroup {
    margin-bottom: 93.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroup {
    margin-bottom: 105px;
  }
}

.contentGroupHead,
.contentGroupBody {
  display: block;
}

.contentGroupHeadRow,
.contentGroupBodyRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 var(--site-padding);
  position: relative;
}

.contentGroupBodyRow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contentGroupBodyRow.dragged {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
}

.contentGroupBodyRow:not(.dragged)::before {
  border-top: 1px solid rgba(112, 112, 112, 0.18);
  content: " ";
  display: block;
  left: var(--site-padding);
  position: absolute;
  top: 0;
  width: calc(100% - (2 * var(--site-padding)));
}

.contentGroupHeadCell {
  border-bottom: 2px solid rgba(112, 112, 112, 0.18);
}

@media only screen and (max-width: 767px) {
  .contentGroupHeadCell:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .contentGroupHeadCell:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupHeadCell:nth-child(1) {
    font-size: 26.25px !important;
    line-height: 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupHeadCell:nth-child(1) {
    font-size: 31.171875000000004px !important;
    line-height: 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupHeadCell:nth-child(1) {
    font-size: 35px !important;
    line-height: 43px !important;
  }
}

@media only screen and (max-width: 899px) {
  .contentGroupHeadCell:nth-child(2) {
    height: 38.12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupHeadCell:nth-child(2) {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupHeadCell:nth-child(2) {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupHeadCell:nth-child(2) {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupHeadCell:nth-child(2) {
    height: 64px;
  }
}

.contentGroupHeadCell,
.contentGroupBodyCell {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .contentGroupHeadCell:nth-child(1),
  .contentGroupBodyCell:nth-child(1) {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupHeadCell:nth-child(1),
  .contentGroupBodyCell:nth-child(1) {
    width: calc(100% - 98.125px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupHeadCell:nth-child(1),
  .contentGroupBodyCell:nth-child(1) {
    width: calc(100% - 117.75px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupHeadCell:nth-child(1),
  .contentGroupBodyCell:nth-child(1) {
    width: calc(100% - 139.828125px);
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupHeadCell:nth-child(1),
  .contentGroupBodyCell:nth-child(1) {
    width: calc(100% - 157px);
  }
}

.contentGroupHeadCell:nth-child(2),
.contentGroupBodyCell:nth-child(2) {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    width: 98.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    width: 117.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    width: 139.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupHeadCell:nth-child(2),
  .contentGroupBodyCell:nth-child(2) {
    width: 157px;
  }
}

.contentGroupBodyCell:nth-child(1) {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .contentGroupBodyCell {
    height: 130px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentGroupBodyCell {
    height: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupBodyCell {
    height: 143.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupBodyCell {
    height: 170.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupBodyCell {
    height: 191px;
  }
}

.contentGroupText {
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .contentGroupText {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupText {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentGroupText {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentGroupText {
    font-size: 24px;
  }
}

.imageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .imageContainer {
    height: 71px;
    width: 132px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageContainer {
    height: 80.25px;
    width: 150px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageContainer {
    height: 95.296875px;
    width: 178.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .imageContainer {
    height: 107px;
    width: 200px;
  }
}

.image {
  background-color: #eee;
  border: 1px solid #707070;
  border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) -7px 0 10px -10px;
  box-shadow: rgba(0, 0, 0, 0.5) -7px 0 10px -10px;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.image:nth-child(2) {
  margin-left: 20%;
}

.image:nth-child(3) {
  margin-left: 40%;
}

.image:nth-child(4),
.image:nth-child(5) {
  margin-left: 60%;
}

.image:nth-child(5) {
  background-color: transparent !important;
  border: none !important;
}

.totalImages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f4f4;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 100%;
  padding: 3px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
  .totalImages {
    font-size: 13px;
    height: 29px;
    min-width: 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .totalImages {
    font-size: 15px;
    height: 32.25px;
    min-width: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .totalImages {
    font-size: 17.8125px;
    height: 38.296875px;
    min-width: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .totalImages {
    font-size: 20px;
    height: 43px;
    min-width: 43px;
  }
}

.contentLayoutHeader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .contentLayoutHeader {
    height: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentLayoutHeader {
    height: 82.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentLayoutHeader {
    height: 99.00000000000001px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentLayoutHeader {
    height: 117.56250000000001px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentLayoutHeader {
    height: 132px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentLayoutHeader {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentLayoutHeader {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentLayoutHeader {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentLayoutHeader {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentLayoutHeader {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentLayoutHeader {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentLayoutHeader {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentLayoutHeader {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentLayoutHeader {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentLayoutHeader {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentLayoutHeader {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentLayoutHeader {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentLayoutHeader {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentLayoutHeader {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentLayoutHeader {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentLayoutHeader {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentLayoutHeader {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentLayoutHeader {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentLayoutHeader {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentLayoutHeader {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentLayoutHeader {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentLayoutHeader {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentLayoutHeader {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentLayoutHeader {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentLayoutHeader {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentLayoutHeader {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentLayoutHeader {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentLayoutHeader {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentLayoutHeader {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentLayoutHeader {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentLayoutHeader {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentLayoutHeader {
      padding-right: 40px;
    }
  }
}

.createButtonsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .createButtonsContainer {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
  }
}

.createButtonWrapper {
  position: relative;
}

.createButtonWrapper,
.createButtonWrapper * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (max-width: 899px) {
  .createMenuContainer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    padding: 16px !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

@media only screen and (min-width: 900px) {
  .createMenuContainer {
    position: relative;
  }
}

.createMenu {
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  -webkit-box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  /* border: 0.5px solid #707070; */
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 3px 6px #00000029; */
  /* box-shadow: 0px 3px 6px #00000029; */
  z-index: 10;
  padding-top: 10px !important;
}

@media only screen and (max-width: 899px) {
  .createMenu {
    max-width: 420px;
    padding: 18px 24px !important;
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .createMenu {
    position: absolute;
    right: 0;
    top: calc(100% - 4px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenu {
    padding: 0 27.5px 9.16667px 27.5px;
    width: 369.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenu {
    padding: 0 33px 11px 33px;
    width: 443.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenu {
    padding: 0 39.1875px 13.0625px 39.1875px;
    width: 526.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenu {
    padding: 0 44px 14.66667px 44px;
    width: 591px;
  }
}

.createMenuTitle {
  font-weight: 500;
  line-height: 2;
}

@media only screen and (max-width: 899px) {
  .createMenuTitle {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenuTitle {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenuTitle {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenuTitle {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenuTitle {
    font-size: 20px;
  }
}

.categoryDropdownContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.createMenuInput {
  border: 1px solid #5aa5ef;
  display: block;
  font-size: 1em;
  margin-bottom: 1.5em;
  margin-top: 0.3em;
  outline: none;
  padding: 0 0.4em;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .createMenuInput {
    height: 45px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenuInput {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenuInput {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenuInput {
    height: 40.078125px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenuInput {
    height: 45px;
  }
}

.createMenuDropdown {
  display: inline-block;
  position: relative;
}

.createMenuDropdownScrim,
.createButtonScrim {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.createMenuDropdownList {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  line-height: 2.2;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  white-space: nowrap;
  z-index: 120;
}

.createMenuDropdownList:not(.alignRight) {
  left: 0;
}

.createMenuDropdownList.alignRight {
  right: 0;
}

@media only screen and (max-width: 899px) {
  .createMenuDropdownList {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenuDropdownList {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenuDropdownList {
    border-radius: 7.5px;
    font-size: 16.03125px;
    padding: 13.5px 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenuDropdownList {
    border-radius: 8.90625px;
    font-size: 16.03125px;
    padding: 16px 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenuDropdownList {
    border-radius: 10px;
    font-size: 18px;
    padding: 18px 36px;
  }
}

.createMenuDropdownInput {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media only screen and (max-width: 767px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenuDropdownInput {
    font-size: 16px;
  }
}

.createMenuDropdownInput,
.createMenuDropdownItem {
  cursor: pointer;
  position: relative;
}

.createMenuButtons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .createMenuButtons {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
}

.createMenuButtonsRight {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .createMenuButtonsRight {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .createMenuButtonsRight {
    margin-left: auto;
  }
}

.createMenuCancelButton {
  background-color: transparent;
  border: 1px solid #707070;
}

.createMenuCreateButton {
  background-color: #5aa5ef;
  border: none;
  color: #fff;
}

.createMenuCancelButton,
.createMenuCreateButton {
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

@media only screen and (min-width: 900px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    margin-left: 0.8em;
  }
}

@media only screen and (max-width: 899px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 13px;
    height: 37px;
    margin: 2em 0.8em 0 0.8em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    font-size: 8.125px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    font-size: 9.75px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    font-size: 11.578125px;
    height: 39.1875px;
    width: 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .createMenuCancelButton,
  .createMenuCreateButton {
    font-size: 13px;
    height: 44px;
    width: 86px;
  }
}

.createButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
}

.contentGroupBox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  width: 515px;
  height: 116px;
}

.contentGroupTitle {
  font-size: 15px;
}

@media only screen and (max-width: 400px) {
  .contentGroupBox {
    margin-top: 20px;
    width: 316px !important;
    height: 91px;
    border-radius: 20px;
    margin-left: 8.2px;
  }
}

@media only screen and (max-width: 899px) {
  .createButton:not(.compact) {
    display: none;
  }
  .createButton.compact {
    background-color: transparent;
    border-radius: 20px;
    color: #000000 !important;
    font-size: 13px;
    font-weight: 500;
    height: 32px;
    margin-left: 8.2px;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 357px;
    height: 91px;
    border-radius: 20px;
    margin-left: 8.2px;
  }
}

@media only screen and (max-width: 900px) {
  .createButton.compact {
    display: none;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 450px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .titleStyle {
    font-size: 20px;
  }
  .chooseOrder {
    font-size: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .createButton {
    border-radius: 6.25px;
    font-size: 13px;
    height: 50px;
    margin-left: 25.625px;
    padding: 0 25.625px;
    width: 223.125px;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 370px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .contentGroupContainer {
    -ms-grid-columns: 500px 1fr;
    grid-template-columns: 500px 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .createButton {
    border-radius: 7.5px;
    font-size: 15px;
    height: 60px;
    margin-left: 30.75px;
    padding: 0 30.75px;
    width: 267.75px;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 390px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .contentGroupContainer {
    -ms-grid-columns: 530px 1fr;
    grid-template-columns: 530px 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .createButton {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 71.25px;
    margin-left: 36.515625px;
    padding: 0 36.515625px;
    width: 317.953125px;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 408px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .contentGroupContainer {
    -ms-grid-columns: 545px 1fr;
    grid-template-columns: 545px 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .createButton {
    border-radius: 10px;
    font-size: 20px;
    height: 80px;
    margin-left: 41px;
    padding: 0 41px;
    width: 357px;
  }
  .contentGroupBox {
    margin-top: 20px;
    width: 475px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .contentGroupContainer {
    -ms-grid-columns: 600px 1fr;
    grid-template-columns: 600px 1fr;
  }
}

@media only screen and (max-width: 1290px) {
  .contentGroupContainer {
    display: block;
  }
  .slideShowContainer {
    margin: 20px 27px;
  }
}

.contentWrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 200px;
  grid-template-columns: 1fr 200px;
  position: relative;
}

.contentGroupWrapper {
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  margin: 0 27px;
  padding: 10px 36px 30px 0px;
  border-radius: 20px;
}

.slideShowContainer {
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  /* margin: 0 27px; */
  /* padding: 10px 36px 30px 0px; */
  border-radius: 20px;
  padding: 20px 0;
  max-height: 400px;
  max-width: 550px;
  margin-bottom: 20px;
}

.actionWrapper {
  width: 25%;
}

.chooseOrder {
  font-size: 32px;
  font-weight: 700;
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .addIcon {
    height: 12.8px;
    margin-right: -6.4px;
    width: 12.8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .addIcon {
    height: 15px;
    margin-right: -7.5px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addIcon {
    height: 20px;
    margin-right: -10px;
    width: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addIcon {
    height: 24px;
    margin-right: -12px;
    width: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addIcon {
    height: 28.5px;
    margin-right: -14.25px;
    width: 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .addIcon {
    height: 32px;
    margin-right: -16px;
    width: 32px;
  }
}

.editButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-color: #ffffff33;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
a.editMenuButton,
button.editMenuButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 0;
  color: #000000;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
}
a.editbutton {
  border-top: solid;
}
@media only screen and (max-width: 899px) {
  .editButton {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editButton {
    border-radius: 6.25px;
    font-size: 12.5px;
    height: 45.62499999999999px;
    padding: 0 15.625px;
    width: 98.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editButton {
    border-radius: 7.5px;
    font-size: 15px;
    height: 54.74999999999999px;
    padding: 0 18.75px;
    width: 117.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editButton {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 65.015625px;
    padding: 0 22.265625px;
    width: 139.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .editButton {
    border-radius: 10px;
    font-size: 20px;
    height: 73px;
    padding: 0 25px;
    width: 157px;
  }
}

.editButtonDropdown {
  background-color: #000;
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editButtonDropdown {
    font-size: 16.25px;
    height: 19.375000000000004px;
    width: 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editButtonDropdown {
    font-size: 19.5px;
    height: 23.250000000000004px;
    width: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editButtonDropdown {
    font-size: 23.15625px;
    height: 27.609375000000004px;
    width: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .editButtonDropdown {
    font-size: 26px;
    height: 31px;
    width: 31px;
  }
}

.headerTheme {
  background-color: black;
}

.alignMiddle {
  justify-content: center;
  display: flex;
  margin-left: 25px;
}

.titleStyle {
  font-size: 24px;
  /* width: 70%; */
  justify-content: flex-start;
  display: inline-flex;
  font-family: "Poppins";
  font-weight: 700;
}

.editContentType {
  padding: 13px 35px;
  font-size: 15px;
  font-weight: 500;
}

.editContentTypeRef:hover {
  background-color: #707070;
  color: #fff;
}

.editContentTypeRef {
  border-style: solid;
  border-width: 2px;
  padding: 10 10;
  border-color: #e7e7e7;
  color: #000;
  border-radius: 8px;
}

.dragIconContainerType {
  margin-left: 2%;
}
.contentTypeTitle {
  width: 76%;
  /* float: left; */
  position: relative;
  justify-content: flex-start;
  display: flex;
  color: #000;
  margin-left: -3%;
}

.editContentTypeRefPosition {
  position: relative;
  right: 12px !important;
}

.albumSettingsDiv {
  margin-top: 30px;
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.saveButtonForSettings {
  display: grid;
  background-color: #5aa5ef;
  max-height: 20px;
  font-size: 10px;
  max-width: 90px;
  min-width: 80px;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-top: 5px;
  vertical-align: middle;
  align-items: center;
  margin-left: 20px;
}

.selectTransition {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
  /* margin-left: 83px; */
}

.saveButtonForSettingDisabled {
  opacity: 0.6;
  pointer-events: none;
}

.addMorePhotoContainer {
  margin-top: 44px;
  cursor: pointer;
}
.addMorePhoto {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 15px;
  margin-left: 6px;
}
.addMoreIcon {
  vertical-align: middle;
  cursor: pointer !important;
}

.addMorePhotoIcon {
  vertical-align: middle;
  cursor: pointer !important;
  margin-right: 7px !important;
  position: relative;
  bottom: 2px;
}

.slideShowTransitions {
  -webkit-appearance: listbox !important ;
  margin-left: 43px;
  width: 140px;
}

.albumSettingsBundle {
  padding: 0 20px 10px 20px;
}
@media only screen and (min-width: 1100px) {
  .createTitle {
    margin-top: -4px;
    font-weight: 500;
  }
}
.buttonDiv {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 99;
}

.buttonGroups {
  display: inline-grid;
}

.buttonWithText {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.createTitle {
  /* margin-top: 18px; */
  font-weight: 500;
}

.createPlayListTitle {
  margin-top: -4px;
  font-weight: 500;
  margin-left: 25px;
}

.subCategoryGroup {
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  margin-top: 20px;
  width: 408px;
  height: 500px;
  border-radius: 20px;
  margin-left: 39.2px;
}

.subCategoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
}

.addNewCategoryWrap {
  padding: 0 20px 0 20px;
}

.editSubCatButton {
  font-size: 15px;
  cursor: pointer;
}

.editSubCatButton:hover {
  color: #5aa5ef;
}

.removeSubCatButton {
  font-size: 13px;
  /* width: 119px!important; */
  border: 2px solid #000000;
  height: 26px;
  align-items: center;
  display: flex;
  color: #ff0000;
  text-align: center;
  border-radius: 7px;
  padding: 2px;
  justify-content: center;
  cursor: pointer;
}

.addButton {
  font-size: 18px;
  border: 3px solid #5aa5ef;
  color: #5aa5ef;
  width: 91px;
  height: 35px;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addButtonWrap {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.existingSubCatWrap {
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  margin-top: 10px;
}

.existingSubCatWrap::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.existingSubCatWrap::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.existingSubCatWrap::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.existingSubCatWrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.subCatDiv {
  margin-bottom: 7px;
  margin-top: 7px;
}

.betaComingSoonWrap {
  display: flex;
  justify-content: space-between;
  padding: 30px 10px 5px;
}

.betaComingSoonButton {
  background-color: #019f32;
  color: #fff;
  padding: 0px 6px;
  font-size: 15px;
  height: 22px;
}

.comingSoonIcon {
  padding: 2px;
}

.addTitle {
  font-weight: 600;
  font-size: 16px;
}

.backButton {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.headerDiv {
  display: flex;
  justify-content: space-between;
}

.selectionBox {
  display: flex;
}

.arrowIcon {
  cursor: pointer;
  margin-left: 8px;
}

@media only screen and (max-height: 780px) {
  .backgroundContainer {
    height: 192px;
  }
  .backgroundImageList {
    max-height: 149px;
  }
}

.backgroundContainer {
  width: 388px;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.backgroundImageList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  margin: 10px 0;
}

.backgroundImageList::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.backgroundImageList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.backgroundImageList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.backgroundImageList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loaderContent {
  display: inline;
  white-space: nowrap;
  font-size: 15px;
}

.backGroundImage:active {
  border-color: #5aa5ef;
}

.buttonOnEnd {
  display: flex;
  justify-content: end;
}

@media only screen and (min-width: 612px) and (max-width: 1290px) {
  .contentGroupWrapper {
    max-width: 493px;
  }
  .contentGroupBox {
    width: 411px !important;
  }
}

@media only screen and (min-width: 450px) and (max-width: 611px) {
  .subCategoryGroup {
    width: 323px;
  }
  .contentGroupWrapper {
    max-width: 400px;
  }
  .contentGroupBox {
    width: 323px !important;
  }
  .titleStyle {
    font-size: 19px;
  }
  .chooseOrder {
    font-size: 22px;
  }
}

@media only screen and (max-width: 449px) {
  .subCategoryGroup {
    width: 300px;
    margin-left: 11.2px;
  }
  .contentGroupWrapper {
    max-width: 325px;
  }
  .contentGroupBox {
    width: 300px !important;
    margin-left: 11.2px;
    height: 85px;
  }
  .titleStyle {
    font-size: 19px;
  }
  .chooseOrder {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentGroupContainer {
    -ms-grid-columns: 500px 1fr;
    grid-template-columns: 500px 1fr;
  }
  .contentGroupBox {
    width: 360px;
  }
}


@media only screen and (min-width: 390px) and (max-width: 900px) {
  .addMorePhoto {
    font-size: 9px;
  }
  .slideShowTransitions {
    margin-left: 54px;
    width: 116px;
    font-size: 10px;
    height: 25px;
  }
  .comingSoonIcon {
    padding: 4px;
  }
  /* .selectTransition {
  margin-left: 30px;
} */
}

@media only screen and (max-width: 499px) {
  .slideShowContainer {
    max-width: 320px;
  }
  .slideShowTransitions {
    margin-left: 54px;
    width: 116px;
    font-size: 10px;
    height: 25px;
  }
  .comingSoonIcon {
    padding: 6px;
  }
  /* .selectTransition {
    margin-left: 30px;
  } */
  .slideShowTransitions {
    margin-left: 21px;
  }
  .betaComingSoonButton {
    font-size: 6px;
    height: 9px;
    text-align: center;
    width: 70px;
    vertical-align: middle;
    margin-top: 7px;
    padding: 0;
  }
}

.updateButtonContainer {
  display: flex;
  justify-content: center;
}

.bannerUpdateButtonContainer {
  justify-content: end;
}

.updateButton {
  max-width: 300px;
  font-family: Poppins, sans-serif;
  min-width: 150px;
  background-color: #2a2929;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  margin-top: 30px;
  padding: 1px;
  cursor: pointer;
}

.bannerUpdateButton {
  max-width: 120px;
  min-width: 110px;
  background-color: #2a2929;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  padding: 1px;
  cursor: pointer;
  font-size: 14px;
  height: 20px;
}

.changeValue {
  background-color: #5aa5ef;
}

.bannerSettingForMedia {
  grid-template-columns: repeat(3, 1fr);
}

.addContent {
  font-size: 15px;
  color: #5aa5ef;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.previewImage {
  background-color: #0002;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .buttonGroups {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 30px;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 11px;
    width: 100%;
  }
  .previewImage {
    height: 78px;
    width: 145.8px;
  }
  /* .albumImage {
      width: 72px !important;
    } */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .previewImage,
  .previewText {
    height: 66.875px;
    width: 125px;
  }
  /* .albumImage {
      width: 62.5px !important;
    } */
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .previewImage,
  .previewText {
    height: 80.25px;
    width: 150px;
  }
  /* .albumImage {
      width: 75px !important;
    } */
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewImage,
  .previewText {
    height: 95.296875px;
    width: 178.125px;
  }
  /* .albumImage {
      width: 89px !important;
    } */
}

@media only screen and (min-width: 1710px) {
  .previewImage,
  .previewText {
    height: 107px;
    width: 200px;
  }
  /* .albumImage {
      width: 100px !important;
    } */
}

@media only screen and (max-width: 899px) {
  .headerCell {
    font-size: 15px !important;
    height: 60.5px !important;
  }
  .createTitle, .createPlayListTitle {
    margin-top: -4px;
    font-weight: 500;
    margin-left: 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headerCell {
    font-size: 15px !important;
    height: 37.8125px !important;
  }
  .headerCell.checkboxCell {
    width: 40.3125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headerCell {
    font-size: 15px !important;
    height: 45.375px !important;
  }
  .headerCell.checkboxCell {
    width: 48.375px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerCell {
    font-size: 17.8125px !important;
    height: 53.8828125px !important;
  }
  .headerCell.checkboxCell {
    width: 57.44531px;
  }
}

@media only screen and (min-width: 1710px) {
  .headerCell {
    font-size: 20px !important;
    height: 60.5px !important;
  }
  .headerCell.checkboxCell {
    width: 64.5px;
  }
}

.bodyCell.videoPreviewCell {
  border-right-style: solid;
  border-right-width: 1px;
}

@media only screen and (max-width: 899px) {
  .buttonDiv {
    position: relative;
    right: unset;
    top: 0;
    z-index: 10;
  }
  .pageTitle {
    display: none !important;
  }
  .pageContent {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .tableHeader {
    display: none !important;
  }
  .tableBody * {
    border: none !important;
  }
  .tableRow:first-of-type .videoPreviewCell {
    padding-top: 18px !important;
  }
  .videoPreviewCell {
    padding-bottom: 9px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 9px !important;
  }
  .bodyCell:not(.videoPreviewCell) {
    display: none !important;
  }
}

.closeButton {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 17px;
  height: 22px;
  cursor: pointer;
}

.selectedIemContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 200px;
  background-color: #c9c9c9;
  margin: 3px;
  height: 20px;
}

.selectedItemTitle {
  font-size: 13px;
  padding: 0 10px;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.selectedItemWrapper {
  margin-left: 4px;
  margin-top: 15px;
}

.alignRow {
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.alignRow::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.alignRow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.alignRow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.alignRow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.imageColumn {
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 319px) {
  .alignRow {
    grid-template-columns: 1fr !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .alignRow {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .alignRow {
    grid-template-columns: 1fr 1fr !important;
  }
}

.imageSelected {
  opacity: 0.5;
}

.tickBotton {
  position: absolute;
  width: 25px;
  height: 25px;
  z-index: 1000;
}

.photoHeading {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5aa5ef !important;
}

.updateButton:hover {
  background-color: #2a2929;
}

.addButton:hover {
  background-color: #5aa5ef;
  color: #fff;
}

.removeSubCatButton:hover {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.addContent:hover {
  color: #555;
}

.backButton:hover {
  color: #707070;
}
