@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.section {
  height: 63vh;
}
@media only screen and (max-width: 899px) {
  .pageContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.dashboard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 3%;
}

@media only screen and (max-width: 899px) {
  .dashboard {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard {
    margin: 32px auto;
    max-width: 420px;
  }
}

@media only screen and (min-width: 900px) {
  .dashboard {
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
    padding-left: 131px;
  }
}

.sectionsContainer {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .sectionsContainer {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .sectionsContainer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 100%;
  }
}

.section {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}

.sectionTitle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-family: Poppins, sans-serif;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sectionTitle > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  white-space: nowrap;
  font-weight: 600;
}

.videoUploadSection {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.videoUploadSectionContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px dashed #707070;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.upgradeSection {
  border: 1px dashed #707070;
  display: table;
  /* min-height: 120px; */
  border-radius: 10px;
  /* margin-top: 15px; */
  padding: 8px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  max-height: 125px;
}

.detailsPart {
  /* width: 50%; */
  /* float: left; */
}

.actionPart {
  /* float: right; */
  /* width: 50%; */
  /* float: right; */
  display: flex;
  align-items: center;
  min-height: 120px;
  vertical-align: middle;
}

@media only screen and (max-width: 350px) {
  .upgradeSection {
    display: block;
  }
  .actionPart {   
    min-height: 75px;
  }
}

.manageWrapper {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.upgradeText {
  padding: 10px;
}

.uploadIcon {
  position: relative;
}

.uploadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #1172d2;
  border-radius: 0 !important;
  font-family: 'Poppins';
}

.uploadButton:hover {
  background-color: #579ce1;
}

.inviteButton:hover {
  background-color: #1172d2;
}

.storageManagementButton:hover {
  background-color: dimgray;
}

.upgradeButton:hover {
  background-color: #555;
}

.videoAnalyticsSection {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 3
}

.storageSection {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.userManagementSection {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.addUserButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #5aa5ef;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  margin-left: 2.5%;
}

.addUserButton:hover {
  background-color: #5aa5ef;
  color: #fff;
  border-color: #5aa5ef;
}

.userRequest {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 10px;
}

.userRequestText {
  overflow: hidden;
  font-family: 'Poppins';
}

.userRequestName {
  font-size: 600;
  line-height: 1.3;
  text-transform: capitalize;
}

.userRequestEmail {
  color: #b2b2b2;
}

.userRequestName,
.userRequestEmail {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userRequestActionsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.userRequestActionButton {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
  margin-top: -1px;
}

@media all and (hover: hover) {
  .userRequestActionButton.delete:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton.delete:active,
  .userRequestActionButton.delete:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .userRequestActionButton:not(.delete):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton:not(.delete):active,
  .userRequestActionButton:not(.delete):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

.videoAnalyticsList {
  width: 100%;
}

.videoAnalyticsList thead {
  font-weight: 600;
}

.videoAnalyticsList td:nth-child(2) {
  text-align: right;
}

.storageSectionContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.storageManagementButton {
  background-color: #c9c9c9;
  border: none;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgradeButton {
  background-color: #fff;
  border: 2px solid #C9C9C9;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .upgradeSection {
    display: block;
  }
  .actionPart {   
    min-height: 30px;
  }
}
.storageBarText {
  font-size: 14px;
  color: white;
  font-family: 'Poppins';
  text-align: center;
  margin: 2px;
  font-weight: 500;
}

.storageInfoBar {
  background-color: transparent;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5rem;
  overflow: hidden;
}

.storageInfoBarContent {
  color: #fff;
  display: inline;
  line-height: 1.6;
  margin-left: -2.5px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.storageInfoBarContent.storageInfoBarVideos {
  background-color: #5aa5ef;
  /* width: 38.4476%; */
}

.storageInfoBarContent.storageInfoBarPhotos {
  background-color: #ff4040;
  /* width: 27.0758%; */
}

.storageInfoBarContent.storageInfoBarAvailable {
  text-align: right;
  background-color: #000;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.accessRequestNotes {
  line-height: 5;
  text-align: center;
  font-family: 'Poppins';
}

.storageInfoText {
  font-weight: 500;
  font-family: 'Poppins';
}

@media only screen and (max-width: 767px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 4px;
  }
  .uploadIcon {
    height: 41px;
    width: 41px;
  }
  .videoUploadSection {
    height: 184px;
  }
  .videoAnalyticsSection {
    /* height: 216px; */
    min-height: auto;
  }
  .section {
    border-radius: 12.65625px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .videoUploadSectionContent {
    border-radius: 8.90625px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 17px;
  }
  .userRequestEmail {
    font-size: 9px;
  }
  .sectionTitle {
    margin-bottom: 4px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 13.5px;
  }
  .storageManagementButton {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .upgradeButton {
    font-size: 12.375000000000002px;
    height: 34px;
    width: 75.6875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sectionsContainer {
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .uploadIcon {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .section {
    border-radius: 16.875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .videoUploadSectionContent {
    border-radius: 11.875px;
  }
  .addUserButton {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .userRequestName {
    font-size: 17px;
  }
  .userRequestEmail {
    font-size: 9px;
  }
  .sectionTitle {
    margin-bottom: 4px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 14px;
  }
  .storageInfoText {
    font-size: 11.5px;
  }
  .storageManagementButton {
    font-size: 12.375px;
    height: 21px;
    width: 78.25px;
  }
  .upgradeButton {
    font-size: 12.375px;
    height: 24px;
    width: 78.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sectionsContainer {
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .uploadIcon {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .section {
    border-radius: 20.25px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .videoUploadSectionContent {
    border-radius: 14.25px;
  }
  .addUserButton {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .userRequestName {
    font-size: 18.75px;
  }
  .userRequestEmail {
    font-size: 10.5px;
  }
  .sectionTitle {
    margin-bottom: 4px;
  }
  .userRequest {
    margin-top: 22px;
  }
  .userRequestActionButton {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 15px;
  }
  .storageInfoText {
    font-size: 12.5px;
  }
  .storageManagementButton {
    font-size: 12.25px;
    height: 22px;
    width: 95.5px;
  }
  .upgradeButton {
    font-size: 12.25px;
    height: 26px;
    width: 95.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sectionsContainer {
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .uploadIcon {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .section {
    border-radius: 24.046875px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .videoUploadSectionContent {
    border-radius: 16.921875px;
  }
  .addUserButton {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .userRequestName {
    font-size: 22.265625px;
  }
  .userRequestEmail {
    font-size: 12.46875px;
  }
  .sectionTitle {
    margin-bottom: 7px;
  }
  .userRequest {
    margin-top: 25.828125px;
  }
  .userRequestActionButton {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 17.8125px;
  }
  .storageInfoText {
    font-size: 14.03125px;
  }
  .storageManagementButton {
    font-size: 15.484375px;
    height: 28px;
    width: 140.90625px;
  }
  .upgradeButton {
    font-size: 15.484375px;
    height: 28px;
    width: 140.90625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sectionsContainer {
    gap: 23px;
    margin-top: 33px;
  }
  .uploadIcon {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .section {
    border-radius: 27px;
    -webkit-box-shadow: #00000029 0px 3px 6px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
    height: 63vh;
  }
  .videoUploadSectionContent {
    border-radius: 19px;
  }
  .addUserButton {
    font-size: 36px;
    height: 44px;
    width: 44px;
    font-family: 'Poppins';
  }
  .userRequestName {
    font-size: 25px;
  }
  .userRequestEmail {
    font-size: 14px;
  }
  .sectionTitle {
    margin-bottom: 8px;
  }
  .userRequest {
    margin-top: 29px;
  }
  .userRequestActionButton {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
  .videoAnalyticsList,
  .storageInfoBar {
    font-size: 20px;
  }
  .storageInfoText {
    font-size: 16px;
  }
  .storageManagementButton {
    font-size: 23px;
    height: 34px;
    font-family: 'Poppins';
    width: 202px;
  }
  .upgradeButton {
    font-size: 19px;
    height: 34px;
    width: 140px;
  }
}
.headerTheme {
  background-color: black;
}

.lineStyle {
  margin-top: 41px;
  display: flex;
  justify-content: center;
}

.iconColumn {
  display: flex;
  justify-content: center;
}

.videoTitle {
  width: 28ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .videoUploadIconPosition {
    margin-right: -100px;
  }
  .albumUploadIconPosition {
    margin-left: -100px;
  }  
  .lineStyle {
    height: 40px !important;
    margin-top: 1px;
  }  
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoUploadIconPosition {
    margin-right: -1px;
  }
  .albumUploadIconPosition {
    margin-left: -1px;
  }  
  .lineStyle {
    height: 44px !important;
    margin-top: 41px;
  }  
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoUploadIconPosition {
    margin-right: -3px;
  }
  .albumUploadIconPosition {
    margin-left: -3px;
  }  
  .lineStyle {
    height: 48px !important;
    margin-top: 46px;
  }  
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoUploadIconPosition {
    margin-right: -3px;
  }
  .albumUploadIconPosition {
    margin-left: -3px;
  }
  .lineStyle {
    height: 67px !important;
    margin-top: 36px;
  }  
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoUploadIconPosition {
    margin-right: -20px;
  }
  .albumUploadIconPosition {
    margin-left: -20px;
  }  
  .lineStyle {
    height: 78px !important;
    margin-top: 39px;
  }  
}

@media only screen and (min-width: 1710px) {
  .videoUploadIconPosition {
    margin-right: -35px;
  }
  .albumUploadIconPosition {
    margin-left: -35px;
  }  
}

.pendingInvite {
  color: #FF0000;
  margin-right: 10px;
  font-size: 16px;
  width: 153px;
  background: transparent;
  font-family: 'Montserrat';
  float: right;
  border: none;
  position: relative;
  left: 35px;
  cursor: pointer;
  margin-top: 0px;
}

.needYourAttention {
  display: grid;
  align-items: center;
  min-height: 100%;
  text-align: center;
  justify-content: center;
}

.needYourAttentionBox {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  max-width: 950px;
  padding: 42px;
}

.updatePaymentButton {
  background-color: #5AA5EF;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 230px;
  font-size: 14px;
  margin-top: 20px;
  padding: 12px;
}

.needAttentionTitle {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 500;
}

.attentionDescription {
  margin-top: 10px;
  font-size: 15px;
}

.circularLoader {
  text-align: center;
  padding-top: 30px;
}
.referFriendWrapper {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.referText {
  font-size: 13px;
}

.inviteButton {
  min-width: 150px;
  max-width: 200px;
  max-height: 30px;
  background-color: #5aa5ef;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  border-radius: 5px;
}

.inviteButtonWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.remainingDays {
  color: #5AA5EF;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.viewerList {
  /* max-height: 180px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.viewerList::-webkit-scrollbar {
  width: 5px;
}

.viewerList::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.viewerList::-webkit-scrollbar-thumb {
  background: #6E6F6E; 
  border-radius: 25px;
}

.viewerList::-webkit-scrollbar-thumb:hover {
  background: #6E6F6E; 
}

.referralRewardmsg {
  color: red;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

.redeemCreditWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.textAlign {
  display: flex;
  align-items: center;
}

@media only screen and (max-height: 640px) {
  .viewerList {
    max-height: 120px;
  }
}

@media only screen and (min-height: 641px) and (max-height: 740px) {
  .viewerList {
    max-height: 150px;
  }
}

@media only screen and (min-height: 741px) and (max-height: 840px) {
  .viewerList {
    max-height: 180px;
  }
}

@media only screen and (min-height: 841px) and (max-height: 940px) {
  .viewerList {
    max-height: 250px;
  }
}

@media only screen and (min-height: 941px) and (max-height: 1040px) {
  .viewerList {
    max-height: 300px;
  }
}

@media only screen and (min-height: 1041px) and (max-height: 1140px) {
  .viewerList {
    max-height: 380px;
  }
}

@media only screen and (min-height: 1141px) {
  .viewerList {
    max-height: 450px;
  }
}
