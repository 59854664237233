.link {
  color: #1172d2;
  cursor: pointer;
  font-weight: 600 !important;
  text-decoration: none;
}

@media all and (hover: hover) {
  .link:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .link:active,
  .link:target {
    text-decoration: underline;
  }
}
