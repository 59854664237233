.backLink {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

.signInLink {
  color: #888888;
  position: absolute;
}

@media all and (hover: hover) {
  .backLink:hover {
    color: #fff;
    text-decoration: none;
  }
  .signInLink:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .backLink:active, .backLink:target {
    color: #fff;
    text-decoration: none;
  }
  .signInLink:active, .signInLink:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .backLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
  .signInLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    right: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 767px) {
  .backLink {
    font-weight: 600;
  }
  .signInLink {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .backLink {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
  .signInLink {
    font-size: 15.625px !important;
    right: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .backLink {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
  .signInLink {
    font-size: 18.75px;
    right: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .backLink {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
  .signInLink {
    font-size: 22.265625px;
    right: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .backLink {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
  .signInLink {
    font-size: 25px;
    right: 80px;
    top: 80px;
  }
}

.inputLabel {
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .inputLabel {
    font-size: 16px;
    margin-bottom: 13.59375px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .inputLabel {
    font-size: 21.875px;
    margin-bottom: 18.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .inputLabel {
    font-size: 26.25px;
    margin-bottom: 21.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .inputLabel {
    font-size: 31.171875000000004px;
    margin-bottom: 25.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .inputLabel {
    font-size: 35px;
    margin-bottom: 29px;
  }
}

.description {
  color: #888888;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.expireTime {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.buttonBlock {
  display: flex;
  justify-content: center;
  
}

.alignForm {
  margin-top: -30px;
}

.skipDiv {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.resendDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}

.resendDiv button {
  width: 200px;
  height: 50px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
}

.resendDiv button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.otpInput {
  width: 55px;
  height: 50px;
  border-radius: 10px;
}

@media only screen and (max-width: 419px) {
  .otpInputs input[type=tel] {
    width: 45px !important;
    height: 45px !important;
  }
}

