.backOut {
  text-align: left;
  font-family: Montserrat-Regular;
  letter-spacing: 0px;
  color: #888888;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
}
.useamin{    
  color: white;
  margin-top: 9px;
  font-size: 13px;
  padding: 0px 13px;
}
.mosert{
  font-family: 'Montserrat' !important;
}
.widthController {
  max-width: 1920px;
  padding: 50px 50px;
  margin: 0 auto;
}
.marginerAutos {
  margin: 0 auto;
}
.Logosz {
  width: 250px;
  margin-bottom: 40px;
  object-fit: contain;
}
.flexSyntdom {
  display: flex;
  justify-content: center;
}
.middleFledge {
  max-width: 36%;
  margin: 0 auto;
}
.newAccount {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 30px;
  font-weight: 700;
}
.Enter {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0 0 0;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  font-weight: 500;
}
.inputFull {
  width: 100% !important;
  padding: 20px;
  height: 70px !important;
  font-family: Montserrat-Regular;
  color: white;
  background: #31343e;
  border: none;
  font-size: 20px !important;
  border-radius: 10px;
  margin-top: 20px;
}
.positionOfLoader {
  position: absolute;
  top: 36%;
  right: 0px;
}
.inputFullEmail {
  width: 100% !important;
  padding: 20px;
  height: 70px !important;
  font-family: Montserrat-Regular;
  color: white;
  background: #31343e;
  border: none;
  font-size: 20px !important;
  border-radius: 10px;
  margin-top: 20px;
}
.PhoneInputInput{
  font-size: 20px !important;
}
.flexMan {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 8px;
  width: 100%;
}
.inputFull ::placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFullEmail ::placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.Thebg {
    width: 100%;
    padding: 14px 13px;
    height: auto;
    font-family: Montserrat-Regular;
    color: white;
    background: #31343e;
    border: none;
    font-size: 12px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: left;
}
.privacy {
  color: #5aa5ef;
  font-weight: 600;
}
.privacy:hover {
  color: #04519d;
  font-weight: 600;
}
.redErr{
    font-size: 12px;
    color: red;
    margin: 0;
}
.aggre {
  background: #5aa5ef 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  height: 85px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}
.disabledaggre{
  background: #5aa5ef 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  height: 85px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  width: 100%;
  margin-top: 10px;
  opacity: 0.6 !important;
}
.marginx{
    margin-top: 20px;
}
.redisher{
    color: red;
    font-size: 15px;
}
@media only screen and (max-width: 600px) {
    .middleFledge {
        max-width:100%;
      }
      .widthController {
        max-width: 100%;
        padding: 10px 10px;
      }
      .newAccount {
        font-size: 24px;
        font-weight: 600;
      }
      .Enter {
        font-size: 15px;
      }
  }