.planWrapper {
  text-align: center;
  padding-top: 6rem;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .planWrapper {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .planWrapper {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .planWrapper {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .planWrapper {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .planWrapper {
    max-width: 1536px;
  }
}
.heroHeading {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: none;
}

.description {
  color: #FFFFFF;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  text-transform: none;
  font-size: 19px;
  padding: 0 26%;
  opacity:0.70;
}

@media only screen and (max-width: 767px) {
  .description {
    padding: 0 8%;
  }
}

@media only screen and (min-width: 528px) and  (max-width: 899px) {
  .heroHeading {
    font-size: 40px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
  .planBox {
    width: 400px !important;
  } 
  .signUpButton {
    width: 350px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .heroHeading {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .planBox {
    width: 400px !important;
  }
  .signUpButton {
    width: 290px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .heroHeading {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .planBox {
    width: 400px !important;
  }
  .signUpButton {
    width: 320px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroHeading {
    font-size: 50px;
    margin-bottom: 23.15625px;
  }
  .planBox {
    width: 450px !important;
  }
  .signUpButton {
    width: 380px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .heroHeading {
    font-size: 55px;
    margin-bottom: 26px;
  }
  .planBox {
    width: 500px !important;
  }
  .signUpButton {
    width: 400px !important;
  }
}

.planList {
  padding-top: 6rem;
}

@media only screen and (max-width: 1119px) {
  .planList {
    display: table !important; 
    margin: 0 auto;
  }
  /* .planBox {
    width: 430px !important;
  } */
}

/* @media only screen and (min-width: 528px) and (max-width: 1119px) {
  .planBox {
    width: 400px !important;
  } 
  .signUpButton {
    width: 350px !important;
  }
} */

@media only screen and (max-width: 527px) {
  .planBox {
    width: 280px !important;
  }
  .signUpButton {
    width: 240px !important;
  }
}

@media only screen and (min-width: 768px) {
  .planList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.planBox {
  max-width: 530px;
  max-height: 400px;
  background-color: #FFFFFF;
  border-radius: 10px;
  min-width: 320px;
  margin: 0 20px;
}

@media only screen and (min-width: 1200px) {
  /* .planBox {
    width: 380px !important;
  } */
  /* .signUpButton {
    width: 316px !important;
  } */
}

@media only screen and (max-width: 1200px) {
  .planBox {
    margin-top: 40px;
  }
}

.blueBackGround {
  background-color: #0075FF;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}

.title {
  color: #000;
  font-size: 20px;
  padding: 5px;
}

.planDescription {
  color: #1D263A;
  font-size: 15px;
  padding: 10px 5px;
}
.horizontalLine {
  margin: 20px 30px 10px 30px;
}


.planDetails {
  color: #000;
  font-size: 18px;
  padding: 0 10px;
}

.signUpButton {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border: 1px solid #707070;
  background-color: #0075FF;
  height: 51px !important;
  margin: 50px 10px 50px 10px;
  font-size: 18px !important;
  /* min-width: 280px !important; */
}

.textColourWhite {
  color: #fff !important;
}

.backGroundWhite {
  background-color: #fff !important;
}

.textColourBlue {
  color: #0075FF !important;
}

.planNameWrapper {
  text-align: left;
}

.planPriceWrapper {
  text-align: right;
}

.price {
  color: #000;
  font-size: 30px;
}