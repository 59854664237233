.positioningModel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.bigCover{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1000000;
}
.imgCenter {
  position: absolute;
  bottom: 0;
  z-index: 100000;
  left: 50%;
  width: 180px;
  transform: translate(-50%, 0%);
}
.dragging {
  border-color: #1e90ff; /* Change border color when dragging */
  background-color: rgba(30, 144, 255, 1); /* Add a background color effect */
  z-index: 100000;
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3); /* Add shadow effect */
}
.bringCenter {
  position: absolute;
  /* width: calc(100% - 80px); */
  max-width: 100%;
  max-height: 80vh;
  background-color: white;
  overflow-y: scroll;
  overflow-x: scroll;
  top: 50%;
  border-radius: 5px;
  left: 50%;
  padding: 30px;
  transform: translate(-50%, -50%);
  z-index: 10001;
}
.addnewForm {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
/* .addnewForm > input {
  border: 1px solid grey;
  padding: 6px;
  height: 100%;
} */
.addnewForm > button {
  padding: 6px;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.addnewForm > button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.closeMeBro {
  margin-top: 0 !important;
  cursor: pointer;
  position: absolute;
  background-color: transparent !important;
  color: gray;
  top: 0;
  height: 100%;
  right: -2px;
  display: flex;
  align-items: start;
}
.cancelPlz {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  min-width: 70px;
  font-family: "Poppins";
}
.noCancel {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  font-family: "Poppins";
}
.Cancelxx {
  width: 20px;
  margin-top: 5px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}
.customDiv {
  width: 100%;
  background: white;
  position: absolute;
  height: 100%;
  font-size: 16px;
  padding: 0 5px;
  color: black;
  font-family: "POPPINS";
}
::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}
.flexico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.overScrollMe {
  position: relative;
  width: 100%;
  max-height: 45vh;
  overflow-y: scroll;
}
.linex {
  width: 100%;
  background-color: #707070;
  opacity: 15%;
  height: 1px;
}
.description {
  height: 54px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
  padding: 12px 11px;
  font-size: 14px;
  font-family: "poppins";
  margin-top: 7px;
}
.tabletC {
display: flex;
gap: 5px;
align-items: center;
justify-content: center;
}
.titles {
  height: 54px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
  padding: 12px 11px;
  font-size: 14px;
  font-family: "poppins";
  border: 1px solid grey;
  width: 120px;
}
.heading {
  font-family: "Poppins";
  color: #585858;
  font-weight: 500;
  font-size: 23px;
  padding-left: 16px;
}
.headingh3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  color: #585858;
  font-size: 12px;
  padding-left: 16px;
}
.TbleCel {
  font-family: "Poppins";
  color: #585858;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
.nameBal {
  width: 200px; /* Set the width at which you want the text to be truncated */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sizeBal {
  width: 100px; /* Set the width at which you want the text to be truncated */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  text-align: center;
}

.previewContainer,
.previewText {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.previewImage {
  width: 145px;
  height: 77px;
  background-color: transparent;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.cancel {
  width: max-content;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Poppins";
  color: #585858;
  padding: 5px 8px;
  font-weight: 600;
  background-color: white;
  font-size: 14px;
  border: 1px solid #585858;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
}
.save {
  font-family: "Poppins";
  cursor: pointer;
  color: white;
  font-weight: 600;
  display: flex;
  width: 126px;
  align-items: center;
  width: max-content;
  padding: 5px 8px;
  height: 26px;
  border-radius: 13px;
  justify-content: center;
  background-color: #585858;
  font-size: 14px;
}
.save:hover {
  opacity: 0.5;
}
.cancel:hover {
  opacity: 0.5;
}
.flexButtun {
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
}
.flexButton {
  display: flex;
  align-items: center;
}
.labelButton {
  /* display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
  font-size: 12px; */

  font-family: "Poppins";
  cursor: pointer;
  color: white;
  font-weight: 600;
  display: flex;
  width: 126px;
  align-items: center;
  width: max-content;
  padding: 5px 8px;
  height: 26px;
  border-radius: 13px;
  justify-content: center;
  background-color: #585858;
  font-size: 14px;
}
.labelButton:hover {
  opacity: 0.5;
}
.hiddenInput {
  display: none;
}
