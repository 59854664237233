.pageContainer {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.mainContent {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
}

.mainContent.loading {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.searchInput {
  /* background-color: #ffffffee; */
  background-color: #656565;
  color: white;
  border: none;
  font-size: 32px;
  margin: 0;
  padding: 13pt 10pt;
  width: 100%;
  border-radius: 1pt;
  /* height: 50pt; */
}
.searchInput::placeholder {
  font-weight: 500;
  color: white;
}
.imageGroup {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: 40px;
    }
  }
}

.imageGroupTitle {
  color: #faf7f7;
}

.imageGroupTitle::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .imageGroupTitle {
    font-size: 14px;
  }
  .searchInput {
    margin-top: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupTitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupTitle {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupTitle {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupTitle {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupTitle {
    font-size: 28px;
  }
}

.imageGroupImagesWrapper {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesWrapper {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesWrapper {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesWrapper {
    --site-padding: 110px !important;
  }
}

.imageGroupImagesContainer:not(.isGrid) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.imageGroupImagesContainer:not(.isGrid)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
}

.imageGroupImagesContainer:not(.isGrid) .imageGroupImagesContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.imageGroupImagesContainer.isGrid {
  display: block;
}

.imageGroupImagesContainer.isGrid .imageGroupImagesContent {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.imageGroupImagesContainer.isGrid .imageGroupImageLink {
  width: 100%;
}

.imageGroupImageLink {
  color: #ffffff;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.imageGroupImageLink::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.imageGroupImageLink div {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.imageGroupImageLink h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.imageGroupImageLink.dummy {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.imageGroupImage {
  height: calc(100% - 36px);
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.imageGroupImageLink.categoryButton::before {
  display: none !important;
}
