.addViewerData {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 280px;
  overflow-y: auto;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .personButtonDP {
    height: 44px;
    margin-right: 11px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButtonDP {
    height: 45.75px;
    margin-right: 11.4375px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButtonDP {
    height: 54.328125px;
    margin-right: 13.58203px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .personButtonDP {
    height: 60px;
    margin-right: 15px;
    width: 60px;
  }
}

.personButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  margin-bottom: 4px;
  text-align: left;
  width: 100%;
}

.personButton.selected {
  border: 2px solid #007aff;
}

@media only screen and (max-width: 1199px) {
  .personButton {
    border-radius: 6.25px;
    margin-bottom: 6px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButton {
    border-radius: 7.5px;
    margin-bottom: 6.75px;
    padding: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButton {
    border-radius: 8.90625px;
    margin-bottom: 8.01562px;
    padding: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .personButton {
    border-radius: 10px;
    margin-bottom: 9px;
    padding: 18px;
  }
}

@media all and (hover: hover) {
  .personButton:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {
  .personButton:active,
  .personButton:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.personButtonDP {
  border-radius: 50%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.personDPImage,
.personDPText {
  border-radius: 50%;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.personDPText {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #14f47b;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
}

.personButtonTextContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.personButtonName,
.personButtonEmail {
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.personButtonName {
  color: #000000;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButtonName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButtonName {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .personButtonName {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .personButtonEmail {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .personButtonEmail {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .personButtonEmail {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButtonEmail {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButtonEmail {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .personButtonEmail {
    font-size: 22px;
  }
}

.personButtonEmail {
  color: #63676c;
  text-transform: lowercase;
}

@media only screen and (max-width: 899px) {
  .contentBox {
    font-size: 17px;
    padding: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .contentBox {
    font-size: 17px;
    padding: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .contentBox {
    font-size: 18.75px;
    padding: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .contentBox {
    font-size: 22.265625px;
    padding: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .contentBox {
    font-size: 25px;
    padding: 25px;
  }
}

.addViewerData {
  color: #02071a;
  font-family: Montserrat, sans-serif;
  line-height: 3;
}

@media only screen and (max-width: 899px) {
  .addViewerData {
    font-size: 12.5px;
    margin-top: 8px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addViewerData {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addViewerData {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addViewerData {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .addViewerData {
    font-size: 20px;
  }
}

.acceptUserBox {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .acceptUserBox {
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .acceptUserBox {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .acceptUserBox {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (max-width: 899px) {
  .acceptUserBox {
    padding: 16.40625px 18.75px;
  }
}

@media only screen and (min-width: 768px) {
  .acceptUserBox {
    margin-top: 1.5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .acceptUserBox {
    padding: 21.875px 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .acceptUserBox {
    padding: 26.25px 30px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .acceptUserBox {
    padding: 31.171875000000004px 35.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .acceptUserBox {
    padding: 35px 40px;
  }
}

.acceptUserBoxTitle {
  color: #141720;
  font-weight: 600;
  line-height: 1.8;
  text-align: right;
}

@media only screen and (max-width: 899px) {
  .acceptUserBoxTitle {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .acceptUserBoxTitle {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .acceptUserBoxTitle {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .acceptUserBoxTitle {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .acceptUserBoxTitle {
    font-size: 25px;
  }
}

.acceptUserBoxDescription {
  color: #02071a;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  text-align: right;
}

@media only screen and (max-width: 899px) {
  .acceptUserBoxDescription {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .acceptUserBoxDescription {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .acceptUserBoxDescription {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .acceptUserBoxDescription {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .acceptUserBoxDescription {
    font-size: 15px;
  }
}

.userBoxRequest {
  background-color: #fff;
}

@media only screen and (max-width: 899px) {
  .userBoxRequest {
    margin-top: 22.5px;
    padding: 24px 24px;
  }
}

@media only screen and (min-width: 900px) {
  .userBoxRequest {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .userBoxRequest {
    margin-top: 15px;
    padding: 10px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .userBoxRequest {
    margin-top: 18px;
    padding: 12px 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .userBoxRequest {
    margin-top: 21.375px;
    padding: 14.25px 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .userBoxRequest {
    margin-top: 24px;
    padding: 16px 32px;
  }
}

.userBoxRequestUserName {
  font-weight: 600;
}

.modalDivider,
.userBoxDivider {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.14;
}

@media only screen and (max-width: 899px) {
  .modalDivider,
  .userBoxDivider {
    margin: 16px 0 16px 0;
  }
}

@media only screen and (min-width: 900px) {
  .modalDivider,
  .userBoxDivider {
    margin: 24px 0 24px 0;
  }
}

.userBoxRequestActions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .userBoxRequestActions {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
}

.userBoxRequestActionButton {
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .userBoxRequestActionButton {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    font-size: 12px !important;
    height: 40px !important;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 900px) {
  .userBoxRequestActionButton {
    margin-left: 7px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .userBoxRequestActionButton {
    font-size: 10px !important;
    height: 31.875px !important;
    width: 110.625px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .userBoxRequestActionButton {
    font-size: 12px !important;
    height: 38.25px !important;
    width: 132.75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .userBoxRequestActionButton {
    font-size: 14.25px !important;
    height: 45.421875px !important;
    width: 157.640625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .userBoxRequestActionButton {
    font-size: 16px !important;
    height: 51px !important;
    width: 177px !important;
  }
}

.userBoxRequestAcceptButton {
  color: #000;
}

.addBoxContainer {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 899px) {
  .addBoxContainer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 900px) {
  .addBoxContainer {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.addBox {
  position: relative;
}

@media only screen and (min-width: 900px) {
  .addBox {
    border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  }
}

@media only screen and (min-width: 900px) {
  .addBox.alignRight {
    text-align: right;
  }
  .addBox.alignRight .addBoxTitle {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.addBoxTitle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #141720;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .addBoxTitle {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addBoxTitle {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addBoxTitle {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addBoxTitle {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .addBoxTitle {
    font-size: 25px;
  }
}

.addBoxTitleIcon {
  color: #000;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .addBoxTitleIcon {
    height: 24.056250000000002px;
    margin-left: 24.056250000000002px;
    width: 24.056250000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addBoxTitleIcon {
    height: 32.075px;
    margin-left: 32.075px;
    width: 32.075px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addBoxTitleIcon {
    height: 38.49px;
    margin-left: 38.49px;
    width: 38.49px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addBoxTitleIcon {
    height: 45.706875000000004px;
    margin-left: 45.706875000000004px;
    width: 45.706875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .addBoxTitleIcon {
    height: 51.32px;
    margin-left: 51.32px;
    width: 51.31px;
  }
}

@media only screen and (max-width: 899px) {
  .addBoxDescription {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addBoxDescription {
    font-size: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addBoxDescription {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addBoxDescription {
    font-size: 14.25px;
    margin-bottom: 35.625px;
    margin-top: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .addBoxDescription {
    font-size: 16px;
    margin-bottom: 40px;
    margin-top: 16px;
  }
}

.tableHeaderCell {
  color: #141720;
  font-family: Poppins, sans-serif !important;
}

@media only screen and (max-width: 899px) {
  .tableHeaderCell {
    border-top: 1px solid rgba(112, 112, 112, 0.18) !important;
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .tableHeaderCell {
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .tableHeaderCell {
    font-size: 18.75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .tableHeaderCell {
    font-size: 22.265625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .tableHeaderCell {
    font-size: 25px !important;
  }
}

.tableBodyCell {
  color: #5aa5ef !important;
  font-size: 15px !important;
}

@media only screen and (max-width: 899px) {
  .tableContainer {
    margin-bottom: 2em;
  }
}

@media only screen and (min-width: 900px) {
  .tableContainer {
    margin-right: 2em;
  }
}

.addViewerBox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .addViewerBox {
    margin-top: 6.00586px;
    padding: 12.01172px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addViewerBox {
    height: 128.125px;
    margin-top: 8.00781px;
    padding: 16.01562px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addViewerBox {
    height: 153.75px;
    margin-top: 9.60938px;
    padding: 19.21875px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addViewerBox {
    height: 182.578125px;
    margin-top: 11.41113px;
    padding: 22.82227px;
  }
}

@media only screen and (min-width: 1710px) {
  .addViewerBox {
    height: 205px;
    margin-top: 12.8125px;
    padding: 25.625px;
  }
}

.addViewerBoxText {
  color: #5aa5ef;
  line-height: 1.2;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .addViewerBoxText {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 900px) {
  .addViewerBoxText {
    margin-bottom: 12px;
  }
}

.addViewerBoxButton {
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .addViewerBoxButton {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addViewerBoxButton {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addViewerBoxButton {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addViewerBoxButton {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .addViewerBoxButton {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 899px) {
  .tableHeaderCell,
  .tableBodyCell {
    height: 57px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .tableHeaderCell,
  .tableBodyCell {
    height: 57px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .tableHeaderCell,
  .tableBodyCell {
    height: 57px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .tableHeaderCell,
  .tableBodyCell {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .tableHeaderCell,
  .tableBodyCell {
    height: 64px;
  }
}

@media only screen and (max-width: 899px) {
  .individualViewerAddBox {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
  }
}

.modalDescription {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

@media only screen and (max-width: 899px) {
  .modalDescription {
    font-size: 11px;
    margin-bottom: 1.5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .modalDescription {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .modalDescription {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modalDescription {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .modalDescription {
    font-size: 15px;
  }
}

.viewersCountContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  border-top: 1px solid rgba(112, 112, 112, 0.18);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .viewersCountContainer {
    border-bottom: none !important;
    height: 40.15625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewersCountContainer {
    height: 46.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewersCountContainer {
    height: 56.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewersCountContainer {
    height: 66.796875px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewersCountContainer {
    height: 75px;
  }
}

.viewersCountTitle,
.viewerDetailsColumnName {
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .viewersCountTitle,
  .viewerDetailsColumnName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewersCountTitle,
  .viewerDetailsColumnName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewersCountTitle,
  .viewerDetailsColumnName {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewersCountTitle,
  .viewerDetailsColumnName {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewersCountTitle,
  .viewerDetailsColumnName {
    font-size: 25px;
  }
}

.viewersCount {
  color: #5aa5ef !important;
}

@media only screen and (max-width: 899px) {
  .viewersCount {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewersCount {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewersCount {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewersCount {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewersCount {
    font-size: 15px;
  }
}

.viewerDetailsInputGrid {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 899px) {
  .viewerDetailsInputGrid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 900px) {
  .viewerDetailsInputGrid {
    -ms-grid-columns: auto 1fr 1fr 1fr;
    grid-template-columns: auto 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 899px) {
  .viewerDetailsInputGrid {
    gap: 0 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewerDetailsInputGrid {
    gap: 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewerDetailsInputGrid {
    gap: 0 30px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewerDetailsInputGrid {
    gap: 0 35.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewerDetailsInputGrid {
    gap: 0 40px;
  }
}

@media only screen and (max-width: 899px) {
  .viewerDetailsColumnName,
  .viewerDetailsIndex,
  .viewerDetailsInputBox {
    padding: 5.2734375px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewerDetailsColumnName,
  .viewerDetailsIndex,
  .viewerDetailsInputBox {
    padding: 7.03125px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewerDetailsColumnName,
  .viewerDetailsIndex,
  .viewerDetailsInputBox {
    padding: 8.4375px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewerDetailsColumnName,
  .viewerDetailsIndex,
  .viewerDetailsInputBox {
    padding: 10.01953125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .viewerDetailsColumnName,
  .viewerDetailsIndex,
  .viewerDetailsInputBox {
    padding: 11.25px 0;
  }
}

.viewerDetailsIndex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .viewerDetailsIndex {
    display: none;
  }
}

.viewerDetailsColumnName {
  color: #585858;
}

@media only screen and (max-width: 899px) {
  .viewerDetailsColumnName {
    display: none;
  }
}

.viewerDetailsInputBox {
  position: relative;
}

@media only screen and (min-width: 900px) {
  .viewerDetailsInputGridDivider {
    display: none;
  }
}

.viewerDetailsInput {
  background-color: #fafafa;
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  outline: none;
  padding: 0 12px;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .viewerDetailsInput {
    font-size: 17px;
    height: 37.5px;
  }
}

@media only screen and (min-width: 900px) {
  /* .viewerDetailsInput::-webkit-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput:-ms-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput::-ms-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput::placeholder {
    opacity: 0;
  } */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewerDetailsInput {
    font-size: 17px;
    height: 37.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewerDetailsInput {
    font-size: 18.75px;
    height: 45px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewerDetailsInput {
    font-size: 22.265625px;
    height: 53.4375px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewerDetailsInput {
    font-size: 25px;
    height: 60px;
  }
}

.modalCancelButton,
.modalContinueButton {
  color: #000 !important;
}

.userRequestActionsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.userRequestActionButton {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
}

@media all and (hover: hover) {
  .userRequestActionButton.delete:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .userRequestActionButton.delete:active,
  .userRequestActionButton.delete:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 767px) {
  .userRequestActionButton {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .userRequestActionButton {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .userRequestActionButton {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .userRequestActionButton {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .userRequestActionButton {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
}
