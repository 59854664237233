.videoPlayer {
    /* position: absolute; */
    background: transparent;
    /* z-index: 10; */
    transition: transform 0.5s cubic-bezier(0,0,0.2,1),opacity 0.5s cubic-bezier(0,0,0.2,1),-webkit-transform .5s cubic-bezier(0,0,0.2,1),opacity .5s cubic-bezier(0,0,0.2,1);
    transition: all 0.5s ease-in-out;
    aspect-ratio: 16/10;
  }

  
.videoPlayerWraper {
  width: 100%;
  height: 200px;
  position: relative;
}

.thumbnailClass {
  width: 100%;
  height: 100%;
}

.react_player {
  z-index: 0;
  height: 100% !important;
  background: #07080c !important;
}

.detailsWrapper {
  position: relative;
  top: 160px;
  color: #fff;
  /* padding: 0 10px; */
  background-color: transparent;
}

.timeStamp {
  font-family: 'Montserrat';
  font-size: 13px;
}

.thumbNail {
  position: relative;
  width: 100%;
  top: 0px;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;

}
.thumbNail:hover{
  border: 2px solid white;
}