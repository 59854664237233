@media (min-width: 744px) and (max-width: 1023px) {
  .mainDiv {
    margin-top: 350px !important;
    position: absolute;
    width: 80%;
    padding: 2%;
  }
}
.invalid {
  font-weight: 500;
  font-family: "Poppins";
  color: red;
  margin: 5px 0;
  font-size: 12px;
  
}
.mangerx {
  font-weight: 500;
  color: black;
  font-family: "Poppins";
  margin-bottom: 0;
  margin-top: 12px;
  font-size: 14px;
}
.affiliatedCode {
  border-bottom-style: solid;
  border-bottom-color: #d4d4d4;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-color: #d4d4d4;
  border-top-width: 1px;
  color: #686868;
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: auto;
  font-family: "Poppins";
  font-size: 18px;
  width: 100%;
  border-left: navajowhite;
  border-right: navajowhite;
}
.affiliatedCode ::placeholder {
  color: #686868;
  font-size: 18px;
}
@media (min-width: 420px) and (max-width: 744px) {
  .mainDiv {
    margin-top: 350px !important;
    position: relative;
    width: 80%;
    padding: 2%;
  }
}

@media (max-width: 420px) {
  .mainDiv {
    margin-top: 450px;
    padding: 2%;
    position: relative;
    width: 80%;
  }
  .projectorSubTitle {
    font-size: 12px !important;
  }
}

@media (max-width: 1023px) {
  .mainDiv {
    margin-left: 0%;
  }
}

/*
  .title {
    transform: scale(1.1);
    margin-left: 50px;

  }

  .subTitle {
    transform: scale(1.1);
    margin-left: 50px;

  }

  .priceMain {} */

/* .subPrice {
    margin-right: 20px;
    transform: translate(128px, -18px);
    scale: 0.85;
  } */
/* 
  .promoCode {
    scale: 0.85;
  }

  .total {
    transform: scale(0.85);
  }

  .logoDiv {
    transform: scale(0.85);
  }
} */

@media (min-width: 1023px) {
  .mainDiv {
    align-items: end;
    border-left-style: solid;
    border-left-color: #d4d4d4;
    border-left-width: 1px;
    padding-left: 100px;
    padding-right: 20px;
    overflow: hidden;
    /* margin-block: auto; */
    min-width: 400px;
  }
}

.projectorPriceDiv {
  margin-left: auto;
}

.price {
  font-weight: 700;
  margin-top: auto;
}

.subTotal {
  margin-top: auto;
  font-weight: 700;
}

.title {
  font-size: 18px;
  color: #848484;
  font-weight: 400;
  margin-top: 0;
}

.subTitle {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0px;
}

.logoDiv {
  display: flex;
}

.logoDivImg {
  background-color: black;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  padding-left: 1%;
  padding-top: 1%;
  text-align: center;
}

.projectorTitleDiv {
  margin-left: 10%;
}

.projectorTitle {
  font-weight: 700;
  margin-top: 12px;
  font-size: 18px;
}
.promoTitle {
  font-weight: 700;
  margin-top: auto;
  margin-bottom: 0;
  font-size: 14px;
  color: #848484;
}

.projectorSubTitle {
  font-size: 11px;
  margin-top: -20px;
  font-weight: 300;
  color: #848484;
}

.total {
  display: flex;
  font-weight: 500;
  /* border-top-style: solid;
  border-top-color: #d4d4d4;
  border-top-width: 1px; */
}

.totalPrice {
  margin-left: auto;
  font-weight: 700;
   margin-top: 12px;
}
.promoPrice {
  margin-left: auto;
  margin-top: 0px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  color: #bb2124;
}

.regularDate{
  display: flex;
}
.promoCode {
  /* border-top-style: solid; */
  border-top-color: #d4d4d4;
  border-top-width: 1px;
  /* color: #686868; */
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: auto;
  width: 250px;

  font-size: 10px;
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .mainDiv {
    margin-top: 10px !important;
  }
}
