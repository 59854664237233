.mobileviewbody {
    background-color:#323232;
    margin-top: -550px;
    width: 100%;
    height: 50px;
    /* text-align: center; */
}

.modaltext{
    font-size: small;
    margin-left: 25px;
    margin-top: 5px;
    line-height: 1rem;
    color: aliceblue;
}