.shimmerWrapper {
  display: flex;
  flex-direction: row;
  animation: shimmer 1s infinite;
  /* -webkit-mask:linear-gradient(-60deg,#000 30%,#202020,#404040 70%) right/300% 100%; */
   margin-top: 20px;
   -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  position: absolute;
  width: 100%;
  top: 10px;
  padding: 0 80px;
}

.lightShimmerWrap {
  padding: 0 20px !important;
  /* margin-top: 80px; */
}

.shimmerBox {
  width: 350px !important;
  height: 145px;
  border: none;
  border-radius: 10px;
  /* background: pink; */
  margin-right: 10px;
  /* border: 1px solid; */
  float: left;
  min-width: 250px;
  /* animation: fadein 2s infinite; */
  /* -moz-animation: fadein 2s; */
  /* Firefox */
  /* -webkit-animation: fadein 2s; */
  /* Safari and Chrome */
  /* -o-animation: fadein 2s; */
  /* Opera */

}

.shimmerBox:nth-child(1) {
  animation-delay: 0s;
  background-color: #515151;
}

.shimmerBox:nth-child(2) {
  animation-delay: 0.4s;
  background-color: #4A4A4A;
}

.shimmerBox:nth-child(3) {
  animation-delay: 0.8s;
  background-color: #464646;
}

.shimmerBox:nth-child(4) {
  animation-delay: 1.2s;
  background-color:  #404040;
}

.shimmerBox:nth-child(5) {
  animation-delay: 1.6s;
  background-color:  #3C3B3B;
}

.shimmerBox:nth-child(6) {
  animation-delay: 2s;
  background-color:  #393535;
}

.shimmerLight {
  width: 350px !important;
  height: 145px;
  border: none;
  border-radius: 10px;
  /* background: pink; */
  margin-right: 10px;
  /* border: 1px solid; */
  float: left;
  min-width: 250px;
}

.shimmerLight:nth-child(1) {
  animation-delay: 0s;
  background-color: #ebebe0;
}

.shimmerLight:nth-child(2) {
  animation-delay: 0.4s;
  background-color:  #e2e2d0;
}

.shimmerLight:nth-child(3) {
  animation-delay: 0.8s;
  background-color: #d8d8c0;
}

@keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

 @keyframes shimmer {
 100% {-webkit-mask-position:left}
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

.lineShimmerWrap {
  position: absolute;
  width: 100%;
  margin-top: 50px;

}