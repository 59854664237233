.signInLink {
  color: #888888;
  position: absolute;
}
.backLink {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .signInLink:hover {
    color: #fff;
    text-decoration: none;
  }
  .backLink:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .signInLink:active, .signInLink:target {
    color: #fff;
    text-decoration: none;
  }
  .backLink:active, .backLink:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .signInLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    right: 18px;
    top: 18px;
  }
  .backLink {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .signInLink {
    font-weight: 600;
  }
  .backLink {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .signInLink {
    font-size: 15.625px !important;
    right: 50px !important;
    top: 50px !important;
  }
  .backLink {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .signInLink {
    font-size: 18.75px;
    right: 60px;
    top: 60px;
  }
  .backLink {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .signInLink {
    font-size: 22.265625px;
    right: 71.25px;
    top: 71.25px;
  }
  .backLink {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .signInLink {
    font-size: 25px;
    right: 80px;
    top: 80px;
  }
  .backLink {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.termsContainer {
  background-color: #31343e;
}

@media only screen and (max-width: 899px) {
  .termsContainer {
    border-radius: 6.25px;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .termsContainer {
    border-radius: 6.25px !important;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .termsContainer {
    border-radius: 7.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .termsContainer {
    border-radius: 8.90625px;
    padding: 12.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .termsContainer {
    border-radius: 10px;
    padding: 16px;
  }
}

.terms {
  color: #eee;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8;
}

@media only screen and (max-width: 899px) {
  .terms {
    font-size: 11px;
    line-height: 1.5 !important;
    margin-bottom: 27.2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .terms {
    margin-bottom: 13.6px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .terms {
    font-size: 12px;
    margin-bottom: 25.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .terms {
    font-size: 14.25px;
    margin-bottom: 30.28125px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .terms {
    font-size: 16px;
    margin-bottom: 34px;
    padding: 16px;
  }
}

.newsletter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .newsletter {
    margin: 16px 8px 24px 0;
  }
}

@media only screen and (min-width: 768px) {
  .newsletter {
    margin: 16px 8px 32px 4px;
  }
}

@media only screen and (max-width: 899px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 25.625px;
    width: 25.625px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 36.515625px !important;
    width: 36.515625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 30.75px;
    width: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 36.515625px;
    width: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .newsletter :global(.MuiSvgIcon-root) {
    height: 41px;
    width: 41px;
  }
}

.newsletterCheckbox {
  border-radius: 50% !important;
  color: #5aa5ef !important;
}

.newsletterText {
  color: #eee;
  cursor: pointer;
  line-height: 1.25;
  margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
  .newsletterText {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .newsletterText {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .newsletterText {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .newsletterText {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .newsletterText {
    font-size: 18px;
  }
}

.notes {
  color: #ddd;
  font-weight: 300;
  line-height: 1.7;
  position: relative;
}

.notes::before {
  background-color: #5aa5ef;
  border-radius: 4px;
  content: ' ';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .notes::before {
    width: 2px;
  }
}

@media only screen and (min-width: 768px) {
  .notes::before {
    width: 4px;
  }
}

@media only screen and (max-width: 899px) {
  .notes {
    font-size: 12px;
    margin: 44px 0;
    padding-left: 18.5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .notes {
    font-size: 13.749999999999998px !important;
    margin: 50px 0 !important;
    padding-left: 16.5625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .notes {
    font-size: 16.5px;
    margin: 60px 0;
    padding-left: 16.875px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .notes {
    font-size: 19.59375px;
    margin: 71.25px 0;
    padding-left: 16.4765625px;
  }
}

@media only screen and (min-width: 1710px) {
  .notes {
    font-size: 22px;
    margin: 80px 0;
    padding-left: 18.5px;
  }
}

.notesEmail {
  color: #fff;
  font-weight: 500;
}

@media only screen and (max-width: 899px) {
  .notesEmail {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .notesEmail {
    font-size: 15.625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .notesEmail {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .notesEmail {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .notesEmail {
    font-size: 25px;
  }
}

.nameFields {
  display: flex;
}

.nameFields > *:first-child {
  margin-right: 10px;
}

.linkClass > a {
  color: #5AA5EF;
}

.reCaptchaContainer {
  /* display: flex; */
  /* justify-content: center; */
  margin-bottom: 40px;
  margin-left: 18px;
}