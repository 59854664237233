.pageContainer {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}
/* .previewTitle .title2{
  margin-top: 60px;

} */
.gridz{
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  gap: 15px;
  justify-content: center;
}
.mainContent {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
}

.mainContent.loading {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .heading {
    height: 183px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .heading {
    border-radius: 13.125px;
    height: 159.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .heading {
    border-radius: 15.749999999999998px;
    height: 191.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heading {
    border-radius: 18.703125px;
    height: 227.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .heading {
    border-radius: 21px;
    height: 255px;
  }
}

.headingBG {
  border: none;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.headingBGFade {
  background-color: rgba(255, 255, 255, 0.2);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.headingText {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: uppercase;
  /* z-index: 10; */
}

@media only screen and (max-width: 767px) {
  .headingText {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .headingText {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headingText {
    font-size: 25.78125px;
    margin-right: 33.75px;
    max-width: 240px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headingText {
    font-size: 34.375px;
    margin-right: 45px;
    max-width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headingText {
    font-size: 41.25px;
    margin-right: 54px;
    max-width: 384px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headingText {
    font-size: 48.984375px;
    margin-right: 64.125px;
    max-width: 456px;
  }
}

@media only screen and (min-width: 1710px) {
  .headingText {
    font-size: 55px;
    margin-right: 72px;
    max-width: 512px;
  }
}

.imageGroup {
  position: relative;

  margin-bottom: 1%;
  /* margin-bottom: 3%; */
  padding-top: 1rem;
}

.paddingTop0{
  padding-top: 0;
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: max(8px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: max(10px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: max(14px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: max(16px, env(safe-area-inset-top));
      margin-left: -45px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: max(18px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: max(22px, env(safe-area-inset-top));
    }
  }
} */

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: 8px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: 10px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: 14px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: 18px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: 22px;
    }
  }
}

.imageGroupTitle {
  color: #faf7f7;
}

/* .marginTitle {
  margin: 0;
} */
.paddingTopForImage {
  padding-top: 35px !important;
}

.imageGroupTitle::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .imageGroupTitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupTitle {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupTitle {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupTitle {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupTitle {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupTitle {
    font-size: 28px;
  }
}

.imageGroupImagesWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
.imageGroupImagesAllVideo {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesWrapper {
    --site-padding: 82.5px !important;
  }
  .imageGroupImagesAllVideo {
    --site-padding: 82.5px !important;
  }
}
@media only screen and (max-width: 709px) {
  .imageGroupImagesWrapper {
    padding-bottom: 30px;
  }
  .imageGroupImagesAllVideo {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesWrapper {
    --site-padding: 97.96875px !important;
  }
  .imageGroupImagesAllVideo {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesWrapper {
    --site-padding: 110px !important;
  }
  .imageGroupImagesAllVideo {
    --site-padding: 110px !important;
  }
}

.imageGroupImagesContainer:not(.isGrid) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.imageGroupImagesContainer:not(.isGrid)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .imageGroupImagesContainer:not(.isGrid) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImagesContainer:not(.isGrid) {
      padding-right: var(--site-padding);
    }
  }
}

.imageGroupImagesContainer:not(.isGrid) .imageGroupImagesContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer:not(.isGrid) .imageGroupImageLink,
  .imageGroupImagesContainer:not(.isGrid) .categoryButton {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.imageGroupImagesContainer.isGrid {
  display: block;
}

.imageGroupImagesContainer.isGrid .imageGroupImagesContent {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .imageGroupImagesContainer.isGrid .imageGroupImagesContent {
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .carousels{
    justify-content: center !important;
  }
}

.imageGroupImagesContainer.isGrid .imageGroupImageLink {
  width: 100%;
}

.imageGroupImageLink {
  color: #ffffff;
}


.imageGroupImageLink h4::first-letter {
  text-transform: uppercase;
}

.videoPlayer {
  /* position: absolute; */
  width: 350px;
  height: 300px;
  background-color: red;
  z-index: 10;
  transition: transform .1s cubic-bezier(0,0,0.2,1),opacity .1s cubic-bezier(0,0,0.2,1),-webkit-transform .1s cubic-bezier(0,0,0.2,1),opacity .1s cubic-bezier(0,0,0.2,1);
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
} */

.imageGroupImageLink.dummy {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}
.imageGroupImageCarousel {
  height: 250px;
}

.imageGroupImage {
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 5px;
  z-index: 1 !important;
}
.imageGroupImage:hover {
  border: 2px solid white;
}
.imageGroupImageInner {
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  aspect-ratio: 1;
  border-radius: 5px;
  z-index: 1 !important;
}
.imageGroupImageInner:hover {
  border: 2px solid white;
}
.albumTransition:hover {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
  box-shadow: 8px 4px 8px 4px rgba(0, 0, 0, 0.2), 20px 6px 20px 6px rgba(0, 0, 0, 0.19);
}


.imageGroupImageLink.categoryButton::before {
  display: none !important;
}

.categoriesLoader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 256px;
}

.categoryButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize !important;
}

.categoryButton.active {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .categoryButton {
    font-size: 14px;
    height: 79.21875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .categoryButton {
    font-size: 14px;
    height: 105.62500000000001px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoryButton {
    font-size: 17.5px;
    height: 126.75000000000001px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoryButton {
    font-size: 21px;
    height: 150.515625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoryButton {
    font-size: 24.9375px;
    height: 169px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoryButton {
    font-size: 28px;
    height: 110px;  }
}

.categoryButton.dummy {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.carousel-container {
  width: 500px;
  height: 200px;
}

.item-align {
  padding: 20px 0 20px 20px;
}
.image-item-isGrid {
  flex: none !important ;
}

.gallery {
  float: left;
  width: 300px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
}

.headingWrapper {
  display: flex;
  align-items: center;
}

.clearAll {
  color: #faf7f7;
  margin-left: 15px;
  /* margin-top: 60px; */
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .clearAll {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 32px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .clearAll {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 32px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .clearAll {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 76px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .clearAll {
      color: #faf7f7;
      margin-left: 15px;
      /* margin-top: 86px; */
      cursor: pointer;
      font-size: small;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .clearAll {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 26px; */
    cursor: pointer;
    
  }
}

@media only screen and (min-width: 1710px) {
  .clearAll {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 26px; */
    cursor: pointer;
  }
}

.imageGroupImagePreview {
  height: calc(100% - 76px) !important;
  
}

.previewTitle {
  position: relative;
  bottom: 40px;
}

.hoverZoom:hover {
  transform: scale(1.1);
  transition: transform 0.4s;
}

.hoverZoom:hover > a > :first-child {
  border: white 2px solid;
  border-radius: 5px;
}

.imageGroupImagesContainer {
  /* padding-right: max(var(--site-padding), env(safe-area-inset-right)); */
}

/* .left-arrow {
  right: calc(1% + -10px);
  position: fixed;
  z-index: 1000;
  margin-left: -2px;
  scale: 0.7;
  margin-top: -10px;
} */

.carouselGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
}
