form #card-element {
  border-color: #bdc4c9;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  outline-color: #1172d2;
}

@media only screen and (max-width: 899px) {
  form #card-element {
    border-radius: 3.125px;
    border-width: 0.46875px;
    font-size: 10px;
    height: 30px;
    line-height: 21px;
    padding: 7.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  form #card-element {
    border-radius: 3.75px;
    border-width: 0.5625px;
    font-size: 11.25px;
    height: 36px;
    line-height: 21px;
    padding: 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  form #card-element {
    border-radius: 4.453125px;
    border-width: 0.66796875px;
    font-size: 13.359375px;
    height: 42.75px;
    line-height: 21px;
    padding: 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  form #card-element {
    border-radius: 5px;
    border-width: 0.75px;
    font-size: 15px;
    height: 48px;
    line-height: 21px;
    padding: 12px;
  }
}

form #card-element::-webkit-input-placeholder {
  color: #767676;
}

form #card-element:-ms-input-placeholder {
  color: #767676;
}

form #card-element::-ms-input-placeholder {
  color: #767676;
}

form #card-element::placeholder {
  color: #767676;
}

.card {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .card {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .card {
    width: 31.5%;
  }
}
