@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Ubuntu:wght@400;500&display=swap);
.button_button__1gabC {
  align-items: center;
  border-style: solid;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  margin: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition-duration: 200ms;
}

.button_button__1gabC:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.button_button__1gabC.button_blockText__3MB5s {
  font-weight: 600;
}

@media all and (hover: hover) {
  .button_button__1gabC.button_blackColor__3vP6A:hover {
    background-color: #000 !important;
  }
  .button_button__1gabC.button_blackColor__3vP6A:hover.button_glow__1IP4f {
    box-shadow: #000 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_blackColor__3vP6A:active,
  .button_button__1gabC.button_blackColor__3vP6A:target {
    background-color: #000 !important;
  }
  .button_button__1gabC.button_blackColor__3vP6A:active.button_glow__1IP4f,
  .button_button__1gabC.button_blackColor__3vP6A:target.button_glow__1IP4f {
    box-shadow: #000 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button_button__1gabC.button_blueColor__2TJv7:hover {
    background-color: #5aa5ef !important;
  }
  .button_button__1gabC.button_blueColor__2TJv7:hover.button_glow__1IP4f {
    box-shadow: #5aa5ef 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_blueColor__2TJv7:active,
  .button_button__1gabC.button_blueColor__2TJv7:target {
    background-color: #5aa5ef !important;
  }
  .button_button__1gabC.button_blueColor__2TJv7:active.button_glow__1IP4f,
  .button_button__1gabC.button_blueColor__2TJv7:target.button_glow__1IP4f {
    box-shadow: #5aa5ef 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button_button__1gabC.button_darkblueColor__3RpuQ:hover:not(.button_outlined__2JYUo) {
    background-color: #002aff !important;
  }
  .button_button__1gabC.button_darkblueColor__3RpuQ:hover.button_outlined__2JYUo {
    background-color: #5aa5ef !important;
  }
  .button_button__1gabC.button_darkblueColor__3RpuQ:hover.button_glow__1IP4f {
    box-shadow: #002aff 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_darkblueColor__3RpuQ:active:not(.button_outlined__2JYUo),
  .button_button__1gabC.button_darkblueColor__3RpuQ:target:not(.button_outlined__2JYUo) {
    background-color: #002aff !important;
  }
  .button_button__1gabC.button_darkblueColor__3RpuQ:active.button_outlined__2JYUo,
  .button_button__1gabC.button_darkblueColor__3RpuQ:target.button_outlined__2JYUo {
    background-color: #5aa5ef !important;
  }
  .button_button__1gabC.button_darkblueColor__3RpuQ:active.button_glow__1IP4f,
  .button_button__1gabC.button_darkblueColor__3RpuQ:target.button_glow__1IP4f {
    box-shadow: #002aff 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button_button__1gabC.button_grayColor__9W012:hover {
    background-color: #c6c6c6 !important;
  }
  .button_button__1gabC.button_grayColor__9W012:hover.button_glow__1IP4f {
    box-shadow: #c6c6c6 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_grayColor__9W012:active,
  .button_button__1gabC.button_grayColor__9W012:target {
    background-color: #c6c6c6 !important;
  }
  .button_button__1gabC.button_grayColor__9W012:active.button_glow__1IP4f,
  .button_button__1gabC.button_grayColor__9W012:target.button_glow__1IP4f {
    box-shadow: #c6c6c6 0 0 32px 0px;
  }
}

@media all and (hover: hover) {
  .button_button__1gabC.button_whiteColor__13Qks:hover {
    background-color: #fff !important;
  }
  .button_button__1gabC.button_whiteColor__13Qks:hover.button_glow__1IP4f {
    box-shadow: #fff 0 0 32px 0px;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_whiteColor__13Qks:active,
  .button_button__1gabC.button_whiteColor__13Qks:target {
    background-color: #fff !important;
  }
  .button_button__1gabC.button_whiteColor__13Qks:active.button_glow__1IP4f,
  .button_button__1gabC.button_whiteColor__13Qks:target.button_glow__1IP4f {
    box-shadow: #fff 0 0 32px 0px;
  }
}

.button_button__1gabC:not(.button_outlined__2JYUo) {
  border-color: transparent;
}

.button_button__1gabC:not(.button_outlined__2JYUo).button_blackColor__3vP6A {
  background-color: #000;
}

.button_button__1gabC:not(.button_outlined__2JYUo).button_blueColor__2TJv7,
.button_button__1gabC:not(.button_outlined__2JYUo).button_darkblueColor__3RpuQ {
  background-color: #5aa5ef;
}

.button_button__1gabC:not(.button_outlined__2JYUo).button_grayColor__9W012 {
  background-color: #c6c6c6;
  color: #000 !important;
}

.button_button__1gabC:not(.button_outlined__2JYUo).button_whiteColor__13Qks {
  background-color: #fff;
}

.button_button__1gabC.button_outlined__2JYUo {
  background-color: transparent;
}

.button_button__1gabC.button_outlined__2JYUo.button_blackColor__3vP6A {
  border-color: #000;
  color: #000;
}

@media all and (hover: hover) {
  .button_button__1gabC.button_outlined__2JYUo.button_blackColor__3vP6A:hover {
    color: #fff;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_outlined__2JYUo.button_blackColor__3vP6A:active,
  .button_button__1gabC.button_outlined__2JYUo.button_blackColor__3vP6A:target {
    color: #fff;
  }
}

.button_button__1gabC.button_outlined__2JYUo.button_blueColor__2TJv7,
.button_button__1gabC.button_outlined__2JYUo.button_darkblueColor__3RpuQ {
  border-color: #5aa5ef;
  color: #5aa5ef;
}

@media all and (hover: hover) {
  .button_button__1gabC.button_outlined__2JYUo.button_blueColor__2TJv7:hover,
  .button_button__1gabC.button_outlined__2JYUo.button_darkblueColor__3RpuQ:hover {
    color: #fff;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_outlined__2JYUo.button_blueColor__2TJv7:active,
  .button_button__1gabC.button_outlined__2JYUo.button_blueColor__2TJv7:target,
  .button_button__1gabC.button_outlined__2JYUo.button_darkblueColor__3RpuQ:active,
  .button_button__1gabC.button_outlined__2JYUo.button_darkblueColor__3RpuQ:target {
    color: #fff;
  }
}

.button_button__1gabC.button_outlined__2JYUo.button_grayColor__9W012 {
  border-color: #c6c6c6;
  color: #000;
}

@media all and (hover: hover) {
  .button_button__1gabC.button_outlined__2JYUo.button_grayColor__9W012:hover {
    color: #000;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_outlined__2JYUo.button_grayColor__9W012:active,
  .button_button__1gabC.button_outlined__2JYUo.button_grayColor__9W012:target {
    color: #000;
  }
}

.button_button__1gabC.button_outlined__2JYUo.button_whiteColor__13Qks {
  border-color: #fff;
  color: #fff !important;
}

@media all and (hover: hover) {
  .button_button__1gabC.button_outlined__2JYUo.button_whiteColor__13Qks:hover {
    color: #000 !important;
  }
}

@media all and (pointer: coarse) {
  .button_button__1gabC.button_outlined__2JYUo.button_whiteColor__13Qks:active,
  .button_button__1gabC.button_outlined__2JYUo.button_whiteColor__13Qks:target {
    color: #000 !important;
  }
}

.button_button__1gabC.button_fullWidth__2sbL4 {
  max-width: 100%;
  width: 100%;
}
.button_button__1gabC.button_mobWidth__2vKHi {
  max-width: 100%;
  width: -webkit-max-content;
  width: max-content;
  margin: 0 auto;
  display: flex;
  min-width: 220px;
}

.button_button__1gabC.button_alignCenter__1_vL_ {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 767px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 5px;
    border-width: 2px;
    font-size: 16px;
    height: 40px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 16px;
    height: 43px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 18px;
    height: 46.50000000000001px;
    padding: 0 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 5.8333333333333px;
    border-width: 2.1875px;
    font-size: 18.75px;
    height: 46.50000000000001px;
    padding: 0 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 7.125px;
    border-width: 2.671875px;
    font-size: 22.265625px;
    height: 55.21875px;
    padding: 0 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .button_button__1gabC:not(.button_large__3skYc) {
    border-radius: 8px;
    border-width: 3px;
    font-size: 25px;
    height: 62px;
    padding: 0 31px;
  }
}

@media only screen and (max-width: 767px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 6.25px;
    border-width: 1.6px;
    font-size: 14px;
    height: 50px;
    padding: 0 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 6.25px;
    border-width: 2.25px;
    font-size: 14.399999999999999px;
    height: 53.75px;
    padding: 0 19;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 6.25px;
    border-width: 2.25px;
    font-size: 17.5px;
    height: 53.75px;
    padding: 0 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 7.5px;
    border-width: 2.25px;
    font-size: 21px;
    height: 64.5px;
    padding: 0 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    font-size: 24.9375px;
    height: 76.59375px;
    padding: 0 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .button_button__1gabC.button_large__3skYc {
    border-radius: 10px;
    border-width: 3px;
    font-size: 28px;
    height: 86px;
    padding: 0 36px;
  }
}

.group-selector_groupContainer__iwwHO {
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  max-height: 200px;
  overflow-y: auto;
}
.group-selector_right_close__2x-as {
    width: 100%;
    margin-left: auto;
    justify-content: end;
    cursor: pointer;
    display: flex;
}
.group-selector_group__1PyAd {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  line-height: 1.8;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
}

@media all and (hover: hover) {
  .group-selector_group__1PyAd:not(.group-selector_addGroup__1-TSw):hover {
    color: #5aa5ef !important;
  }
}

@media all and (pointer: coarse) {
  .group-selector_group__1PyAd:not(.group-selector_addGroup__1-TSw):active, .group-selector_group__1PyAd:not(.group-selector_addGroup__1-TSw):target {
    color: #5aa5ef !important;
  }
}

.group-selector_group__1PyAd.group-selector_addGroup__1-TSw {
  color: #5aa5ef;
}

@media only screen and (max-width: 767px) {
  .group-selector_group__1PyAd {
    font-size: 15px;
  }
  .group-selector_groupContainer__iwwHO {
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 23px;
  }
  .group-selector_visibilityTitle__y9TMR {
    font-size: 20.03125px;
    margin-top: 12px;
  }
  .group-selector_visibilityDescription__27WsC {
    font-size: 14px;
  }
  .group-selector_visibilityContainer__3XPf2 {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .group-selector_visibilityContainer__3XPf2 .MuiTypography-root {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .group-selector_group__1PyAd {
    font-size: 15px;
  }
  .group-selector_groupContainer__iwwHO {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .group-selector_group__1PyAd {
    font-size: 15px;
  }
  .group-selector_groupContainer__iwwHO {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .group-selector_group__1PyAd {
    font-size: 15px;
  }
  .group-selector_groupContainer__iwwHO {
    padding: 17.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .group-selector_group__1PyAd {
    font-size: 17.8125px;
  }
  .group-selector_groupContainer__iwwHO {
    padding: 20.484375px;
  }
}

@media only screen and (min-width: 1710px) {
  .group-selector_group__1PyAd {
    font-size: 20px;
  }
  .group-selector_groupContainer__iwwHO {
    padding: 23px;
  }
}

.main {
    background-color: rgb(255, 255, 255);
    /* height: 80%;
    width: 75%; */
    margin-top: 55px;
    position: relative;
    height: 80vh;
    width: 60%;
    /* z-index: -2; */
    /* text-transform: none; */
}

.backArrow {
    fill: black;
}

.ultimate {
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 600;
    color: black;
    margin: auto;
}

.top-btn {
    color: rgb(255, 255, 255) !important;
    ;
    background-color: rgb(94, 91, 91) !important;
    ;
    border: none;
    border-radius: 14px !important;
    ;
    margin: 10px !important;
    min-width: 10px !important;
    padding: 5px 10px !important;
    font-size: 16px;
    font-family: 'Poppins';
}

/* .img{ 
    width:  1x;
    height:  600px;
} 
.image5{
    width:  1x;
    height:  600px;
    


} */
.box {
    text-align: center;
    /* height: 300px;
    width: 400px; */
    margin-top: 200px;
    z-index: 9;
    /* box-shadow: -99px -11px 22px #ffffff; */
    /* background-image: linear-gradient(to top, #fcfcfc, #fdfdfd, #fdfdfd, #fefefe, #fefefe, #e7e7e7, #d0d0d0, #b9b9b9, #878787, #585858, #2e2e2e, #000000);; */
}

.text-box {
    position: relative;
    font-weight: bold;
    /* margin-top: 100px; */
    padding: 37px 0px 20px 0px;
    color: black;
    background-image: linear-gradient(to top, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff83, #ffffff00);
    ;
    text-transform: none;
}

.upload {
    color: #EA5973;
    margin: auto;
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
}

.stream {
    font-size: 19px;
    font-family: 'Poppins';
    font-weight: 600;
    margin: auto;
    color: #373A53;
}

.featuresLabel {
    font-weight: 400;
    font-size: 13px;
    font-family: 'Poppins';
    margin-top: 8px;
    color: black;
}

.cloud {
    font-weight: 350;
    font-size: 13px;
    font-family: 'Poppins';
    margin-top: 1px;
    color: black;
}

.more {
    font-weight: 400;
    margin-top: 16px;
    font-size: 15px;
    color: black;
}

.btn {
    font-weight: 100px;
    margin-top: -10px;
    border: none;
}

.skip {
    color: rgb(255, 255, 255);
    background-color: rgb(94, 91, 91);
    border: none;
    border-radius: 25px;
    width: 50px;
    cursor: pointer;
    z-index: 5;
    margin-top: 10px;
    position: relative;
    /* float: right; */
    margin-left: auto;

    margin-right: 15px;
    cursor: pointer;
}

#path_81 {
    fill: white;
    background-color: rgb(94, 91, 91);
    border-radius: 15px;
    width: 30px;
    margin-left: 15px;
    height: 30px;
    cursor: pointer;

}

.continue {
    width: 30%;
    margin: auto;
    background-color: #5aa5ef;
    border: none;
    color: white;
    height: 45px;
    font-family: 'montserrat';
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .main {
        overflow-y: auto;
    }
}

.pricingLabel {
    color: #5aa5ef;
    font-size: 1.5rem;
}

.videoPlayer {
    height: 100% !important;
    width: 100% !important;
    border-radius: 10px;
}

.videoPlayer video {
    object-fit: fill;
    border-radius: 10px;
}
.skipbtn:hover{
    color: red;
}
.popup .closebtn:hover{
    color: red;
}
.skipbtn{
    color: #fff;
    font-size: 15px;
    margin-top: -5px;
    background: none;
    position: absolute;
    margin-top: -30px;
    margin-left: -835px;
    border: none;
    height: 20px;
    width: 50px;
}
@media only screen and (max-width: 767px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -350px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -560px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .skipbtn{
        color: #fff;
        font-size: 15px;
        margin-top: -5px;
        background: none;
        position: absolute;
        margin-top: -30px;
        margin-left: -650px;
        border: none;
        height: 20px;
        width: 50px;
    }
}
.closebtn:hover{
    fill: red;
}
/* @media only screen and (max-width: 767px) { */
.popup{
   height: 50%;
   

} 
/* } */
.modalclosebtn{
    height: 25px; 
    fill: white; 
    position: absolute;
    margin-top: -35px;
    margin-left: 830px;
}
.modalclosebtn:hover {
    fill: red;
}
@media only screen and (max-width: 767px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 360px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 570px;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .modalclosebtn{
        height: 25px; 
        fill: white; 
        position: absolute;
        margin-top: -35px;
        margin-left: 650px;
    }
}
.player{
    width: 900px;
}
@media only screen and (max-width: 767px) {
    .player{
       width: 400px;
     
   }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .player{
        width: 600px;
      
    }

}
@media only screen and (min-width: 900px) and (max-width: 1119px) {
    .player{
        width: 700px;
      
    }

}
.TrailVideoPopUp_bigframe__19_9e {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

.TrailVideoPopUp_main__2Brrp {
  background-color: rgb(255, 255, 255);
  height: 80vh;
  width: 60%;
  position: fixed;
  z-index: 4500000000;
  top: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
          transform: translate(-50%, -70%);
  border-radius: 10px;
  -webkit-animation: TrailVideoPopUp_slideInFromTop__4yy65 0.5s ease-out;
          animation: TrailVideoPopUp_slideInFromTop__4yy65 0.5s ease-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
@-webkit-keyframes TrailVideoPopUp_slideInFromTop__4yy65 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
  }
  100% {
    opacity: 1;
    position: fixed;
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
}
@keyframes TrailVideoPopUp_slideInFromTop__4yy65 {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
  }
  100% {
    opacity: 1;
    position: fixed;
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
}
.TrailVideoPopUp_backArrow__3HQ_P {
  fill: black;
}

.TrailVideoPopUp_ultimate__3ft6c {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 700;
  color: #373a53;
  margin: 20px auto 26px auto;
}

.TrailVideoPopUp_topbtn__29jdx {
  color: rgb(255, 255, 255) !important;
  background-color: #707070 !important;
  border: none;
  border-radius: 14px !important;
  margin: 10px !important;
  min-width: 10px !important;
  padding: 5px 10px !important;
  font-size: 16px;
  font-family: "Poppins";
  text-transform: capitalize;
  cursor: pointer;
}

/* .img{ 
    width:  1x;
    height:  600px;
} 
.image5{
    width:  1x;
    height:  600px;
    


} */
.TrailVideoPopUp_box__2NfwU {
  text-align: center;
  /* height: 300px;
    width: 400px; */
  margin-top: 200px;
  z-index: 9;
  /* box-shadow: -99px -11px 22px #ffffff; */
  /* background-image: linear-gradient(to top, #fcfcfc, #fdfdfd, #fdfdfd, #fefefe, #fefefe, #e7e7e7, #d0d0d0, #b9b9b9, #878787, #585858, #2e2e2e, #000000);; */
}

.TrailVideoPopUp_textbox__1wQnE {
  position: relative;
  font-weight: bold;
  /* margin-top: 100px; */
  padding: 37px 0px 20px 0px;
  color: black;
  background-image: linear-gradient(
    to top,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff,
    #ffffff83,
    #ffffff00
  );
  text-transform: none;
}

.TrailVideoPopUp_upload__1L2Zc {
  color: #ea5973;
  margin: auto;
  font-size: 19px;
  font-family: "Poppins";
  font-weight: 600;
}

.TrailVideoPopUp_stream__1k0Fg {
  font-size: 19px;
  font-family: "Poppins";
  font-weight: 600;
  margin: 30px auto;
  color: #373a53;
}

.TrailVideoPopUp_featuresLabel__lWkdt {
  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins";
  margin-top: 16px;
  color: black;
}

.TrailVideoPopUp_cloud__-6_QT {
  font-weight: 350;
  font-size: 13px;
  font-family: "Poppins";
  margin-top: 1px;
  color: black;
}

.TrailVideoPopUp_more__1nhR6 {
  font-weight: 400;
  margin-top: 16px;
  font-size: 15px;
  color: black;
}

.TrailVideoPopUp_btn__3rg0b {
  font-weight: 100px;
  margin-top: -10px;
  border: none;
}

.TrailVideoPopUp_skip__Dggmi {
  color: rgb(255, 255, 255);
  background-color: rgb(94, 91, 91);
  border: none;
  border-radius: 25px;
  width: 50px;
  cursor: pointer;
  z-index: 5;
  margin-top: 10px;
  position: relative;
  /* float: right; */
  margin-left: auto;

  margin-right: 15px;
  cursor: pointer;
}

#TrailVideoPopUp_path_81__3krcG {
  fill: white;
  background-color: rgb(94, 91, 91);
  border-radius: 15px;
  width: 30px;
  margin-left: 15px;
  height: 30px;
  cursor: pointer;
}

.TrailVideoPopUp_continue__1CgRC {
  width: 30%;
  margin: auto;
  background-color: #5aa5ef;
  border: none;
  color: white;
  height: 45px;
  font-family: "montserrat";
  font-weight: 600;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
}
.TrailVideoPopUp_continue__1CgRC:disabled {
  opacity: 0.8;
  cursor: default;
}
@media only screen and (max-width: 600px) {
  .TrailVideoPopUp_main__2Brrp {
    overflow-y: auto;
    height: 80vh;
    width: 95%;
    position: fixed;
    z-index: 4500000000;
    top: 67%;
    left: 50%;
    -webkit-transform: translate(-50%, -70%);
            transform: translate(-50%, -70%);
  }
  .TrailVideoPopUp_ultimate__3ft6c {
    font-size: 22px;
    margin: 20px auto 26px auto;
  }
}

.TrailVideoPopUp_pricingLabel__2E2hE {
  color: #5aa5ef;
  font-size: 1.5rem;
}

.TrailVideoPopUp_videoPlayer__Sa_xg {
  height: 100% !important;
  width: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  object-position: bottom;
}

.gridssz {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    grid-row-gap: 38px;
    grid-row-gap: 60px;
    row-gap: 60px;
    grid-column-gap: 1fr;
    width: 100%;
  }

  .flexfifty{
    display: flex; 
  }
  .unhidePls {
    border: 2px solid #000000;
    border-radius: 5px;
    font-size: 18px;
    align-items: center;
    font-weight: 600;
    background: white;
    color: #000000;
    cursor: pointer;
    width: 100px;
    height: 29px;
    margin-left: auto;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
    display: flex;
  }
  .hiddenX{
    width: 30px;
    margin-left: 15px;
    opacity: 1;
    cursor: pointer;
  }
  .visibleEye{
    width: 30px;
    margin-left: 15px;
    opacity: .5;
    cursor: wait;
  }
  .notification {
    position: fixed;
    bottom: 10%;
    -webkit-transform: translate(-50%, -10%);
            transform: translate(-50%, -10%);
    z-index: 1000000;
    left: 50%;
    padding: 5px 20px 7px 20px;
    background-color: #333;
    color: #fff;
    max-width: 450px;
    border-radius: 4px;
    opacity: 0;
    font-size: 15px;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .notification.show {
    opacity: 1;
  }
  .theDissmisal{
    position: fixed;
    bottom: 10%;
    -webkit-transform: translate(-50%, -10%);
            transform: translate(-50%, -10%);
    z-index: 1000000;
    left: 50%;
    background-color: #333;
    padding: 15px 20px;
    border-radius: 10px;
    max-width: 600px;
    color: #fff;
  }
  .closeTillWait {

    opacity: 0;
    font-size: 15px;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  
  .closeTillWait.show {
    opacity: 1;
  }
  .closerX{
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 10px;
  }
  .the_Hieghter {
    height: 350px;
    overflow: auto;
    width: 100%;
    padding-right: 40px;
  }
  .eyeCatcher {
    position: absolute;
    top: 50%;
    width: 30px;
    right: 15px;
  }
  .loading {
    text-align: center;
  }
  /* WebKit-based browsers */
  .the_Hieghter::-webkit-scrollbar {
    width: 8px; /* Adjust as needed */
  }
  
  .the_Hieghter::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .the_Hieghter::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }
  
  /* Mozilla Firefox */
  .the_Hieghter {
    scrollbar-width: thin;
    scrollbar-color: #cccccc transparent;
  }
  
  /* Microsoft Edge and Internet Explorer */
  .the_Hieghter {
    -ms-overflow-style: none; /* Hide scrollbar */
  }
  
  .the_Hieghter::-ms-scrollbar {
    display: none; /* Hide scrollbar */
  }
  .mySmallMail {
    margin: 0 0 0 15px;
    text-align: left;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: -0.44px;
    color: #b2b2b2;
    opacity: 1;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .widhthMax {
    max-width: 1508px;
    margin-right: auto;
  }
  .searcher::-webkit-input-placeholder {
    font-size: 20px;
    color: gray;
  }
  .searcher:-ms-input-placeholder {
    font-size: 20px;
    color: gray;
  }
  .searcher::placeholder {
    font-size: 20px;
    color: gray;
  }
  .fullFledge {
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    height: 100%;
    top: 0;
    left: 0;
  }
  .theList {
    background-color: transparent !important;
    color: black;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: start;
  }
  .eachName {
    margin: 0;
    font-size: 22px;
    line-height: 1.25;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .modal {
    position: absolute;
    z-index: 999;
    width: 55%;
    top: 20%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 20px 30px;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 12px;
    opacity: 1;
    z-index: 9999;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .loading-circle {
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }
  .loading-circle_mini {
    width: 25px;
    height: 25px;
    -webkit-animation: spin .5s linear infinite;
            animation: spin .5s linear infinite;
    display: flex;
    margin: 0 auto;
  }
  
  .circle {
    stroke: #333;
    stroke-dasharray: 80;
    stroke-dashoffset: 60;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;
  }
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 60;
    }
    50% {
      stroke-dashoffset: 0;
      stroke: #ccc;
    }
    100% {
      stroke-dashoffset: -60;
      stroke: #333;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 60;
    }
    50% {
      stroke-dashoffset: 0;
      stroke: #ccc;
    }
    100% {
      stroke-dashoffset: -60;
      stroke: #333;
    }
  }
  .marginTopic {
    margin-top: 25px;
  }
  .boldtext {
    font-family: "Poppins" !important;
    letter-spacing: -1.69px;
    color: #02071a;
    opacity: 1;
    font-size: 35px;
    font-weight: 600;
    margin: 0;
  }
  .flexcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nonBoldxtext {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.69px;
    color: #02071a;
    -webkit-user-select: text;
        -ms-user-select: text;
            user-select: text;
    opacity: 1;
    font-size: 35px;
    font-weight: 500;
  }
  .linkSelectText {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    color: #5aa5ef;
    opacity: 1;
    font-size: 18px;
    font-weight: 300;
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
    transition: text-shadow 0.3s ease; /* Add a smooth transition */
  }
  
  .linkSelectText:hover {
    /* font-weight: 400; */
    text-shadow: 0 0 10px rgba(90, 165, 239, 0.7); /* Neon light effect */
  }
  
  .nonColurxtext {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    color: #02071a;
    opacity: 0.32;
    font-size: 18px;
    font-weight: 300;

  }
  .flextr {
    display: flex;
  }
  .buttonrEG {
    max-width: -webkit-max-content;
    max-width: max-content;
    white-space: nowrap;
    height: 50px;
    margin: 0;
    cursor: pointer;
    background: #5aa5ef 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: 0px;
    font-size: 16px;
    padding: 16px 20px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
  }
  .invite {
    margin: 0;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.16px;
    color: #5aa5ef;
    opacity: 1;
    cursor: pointer;
  }
  .alignleft {
    text-align: right !important;
  }
  .inviteer {
    text-align: left;
    background: transparent;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.16px;
    cursor: pointer;
    color: #5aa5ef;
    font-size: 20px;
    opacity: 1;
    padding-left: 0;
  }
  .req {
    width: 50px;
    object-fit: cover;
    object-position: center;
  }
  .searcher {
    width: 100%;
    background: transparent;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border-bottom: 3px #1172d2 solid;
    height: auto;
    border-top: none;
    font-size: 25px;
    border-left: none;
    border-right: none;
  }
  .noMARGIN {
    margin: 0;
  }
  .margiTop {
    margin-top: 16px;
  }
  .userName {
    text-align: left;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 500;
    font-size: 30px;
    opacity: 1;
  }
  .userNameONmenu {
    text-align: left;
    font-family: Montserrat-Regular !important;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
  }
  .close {
    margin-left: auto;
  }
  .SearchResulter {
    height: 80px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    z-index: 121;
    position: absolute;
    width: 94%;
    background-color: #f8f9fa;
  }
  .asWhom {
    text-align: left;
    font-family: "Poppins", sans-serif !important;
    letter-spacing: -1.11px;
    font-size: 20;
    font-family: 600;
    color: #02071a;
    opacity: 1;
  }
  .copy{
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
  .modelFelx {
    display: flex;
    justify-content: start;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  .flexico {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  .flexTree {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    width: 100%;
  }
  .mt {
    margin-top: 78px;
  }
  .absolute {
    position: absolute;
    right: 53px;
    top: 40%;
    cursor: pointer;
  }
  .relativez {
    position: relative;
  }
  .flexter {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: start;
    margin-bottom: 28px;
  }
  .rounder {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 16px;
    font-family: Montserrat-Regular;
    letter-spacing: -0.78px;
    color: #000000;
    opacity: 1;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  .rounderChar {
    border-radius: 100%;
    width: 55px;
    height: 55px;
    padding: 20px;
    font-family: Montserrat-Regular;
    letter-spacing: -0.78px;
    color: white;
    opacity: 1;
    font-weight: 700;
    font-size: 24px;
    background: #f0f0f0 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }
  .UserName {
    font-family: "Montserrat" !important;
    letter-spacing: -0.78px;
    font-weight: 600;
    font-size: 18px;
    margin-left: 15px;
    margin-bottom: 0;
    color: #000000;
    opacity: 1;
    margin-top: 0;
  }
  .edit {
    width: 20px;
    margin-left: auto;
  }
  .Usermail {
    font-family: "Montserrat" !important;
    letter-spacing: -0.78px;
    font-weight: 400;
    font-size: 14px;
    color: #b2b2b2;
    opacity: 1;
    margin: 0 0 0 15px;
    text-align: left;
  }
  .chipToInputBox {
    padding-top: 50px;
  }
  .chip{
    font-family: Poppins, sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
  .adME {
    background: #5aa5ef 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    min-width: 109px;
    margin-left: auto;
    cursor: pointer;
    font-size: 25px;
    height: 62px;
    margin-top: 73px;
  }
  .slectedMailBox {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    justify-content: start;
  }
  .remove {
    width: 100px;
    height: 29px;
    display: flex;
    margin: 0 0 0 auto;
    border: 2px solid #000000;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    background-color: transparent;
    letter-spacing: 0px;
    color: #ff0000;
    font-size: 18px;
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }
  .flexManager {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexStarter {
    display: flex;
    grid-gap: 100px;
    gap: 100px;
    justify-content: start;
    align-items: start;
  }
  .accessHim {
    border: 2px solid #000000;
    border-radius: 5px;
    font-size: 18px;
    align-items: center;
    font-weight: 600;
    background: white;
    color: #000000;
    cursor: pointer;
    width: 100px;
    height: 29px;
    margin-left: auto;
    margin-top: 0;
    opacity: 1;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
  }
  .Non_access {
    border: 2px solid red;
    margin-top: 0;
    background: white;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100px;
    height: 29px;
    opacity: 1;
    color: red;
    text-align: center;
    font-family: "Montserrat" !important;
    letter-spacing: 0px;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .w100 {
    width: 100%;
  }
  .liex {
    height: 105px;
    width: 4px;
    background: #6e6f6e 0% 0% no-repeat padding-box;
    border: 1px solid #6e6f6e;
    border-radius: 2px;
    opacity: 1;
  }
  @media only screen and (max-width: 600px) {
    .unhidePls {
      font-size: 14px;
    }
    .gridssz {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      grid-row-gap: 10px;
      row-gap: 10px;
      grid-column-gap: 10px;
      -webkit-column-gap: 10px;
              column-gap: 10px;
    }
    .the_Hieghter {
      height: auto;
      overflow: auto;
      width: 96%;
      padding-right: 10px;
    }
    .mySmallMail {
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
.header_header__2MyKg {
  /* background-color: #fff;
  color: #000; */
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.header_headerTheme__2i-sg {
  background-color: black;
}

.header_header__2MyKg,
.header_header__2MyKg * {
  font-family: "Poppins", sans-serif !important;
}

.header_header__2MyKg.header_noHeader__1kvs8 {
  display: none !important;
}

@media only screen and (max-width: 899px) {
  .header_header__2MyKg.header_transparentCompactView__xvIg0 {
    /* background-color: transparent; */
    color: #fff !important;
  }
  .header_header__2MyKg.header_transparentCompactView__xvIg0.header_pageScrolled__1mSx- {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
  }
  .header_header__2MyKg.header_transparentCompactView__xvIg0.header_pageScrolled__1mSx-:not(.header_glassCompactHeaderBG__1xHGA) {
    background-color: #1f2330;
  }
  .header_header__2MyKg.header_transparentCompactView__xvIg0.header_pageScrolled__1mSx-.header_glassCompactHeaderBG__1xHGA {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .header_header__2MyKg:not(.header_transparentCompactView__xvIg0) {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px 0;
  }
  .header_alignButton__19vsW {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 900px) {
  .header_header__2MyKg {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px 0;
  }
  .header_header__2MyKg.header_noRegularHeader__2if1d {
    display: none !important;
  }
  .header_alignButton__19vsW {
    margin-left: 25px;
  }
}

@media only screen and (max-width: 899px) {
  .header_header__2MyKg,
  .header_headerFill__1lcs9 {
    height: 64px;
  }
  html {
    --header-height: 64px;
  }
  .header_videoBannerHeader__1MFq8 {
    height: 0 !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_header__2MyKg,
  .header_headerFill__1lcs9 {
    height: 69.375px;
  }
  html {
    --header-height: 69.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_header__2MyKg,
  .header_headerFill__1lcs9 {
    height: 83.25px;
  }
  html {
    --header-height: 83.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_header__2MyKg,
  .header_headerFill__1lcs9 {
    height: 98.859375px;
  }
  html {
    --header-height: 98.859375px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_header__2MyKg,
  .header_headerFill__1lcs9 {
    height: 111px;
  }
  html {
    --header-height: 111px;
  }
}

@media only screen and (max-width: 899px) {
  .header_regularView__1DUae {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) {
  .header_compactView__Qncsn {
    display: none !important;
  }
}

.header_headerContent__3oiG_ {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_headerContent__3oiG_ {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_headerContent__3oiG_ {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_headerContent__3oiG_ {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_headerContent__3oiG_ {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_headerContent__3oiG_ {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_headerContent__3oiG_ {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_headerContent__3oiG_ {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_headerContent__3oiG_ {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_headerContent__3oiG_ {
      padding-left: 12px;
    }
  }
  @media only screen and (max-width: 480px) {
    .header_profileMenuLink__3S2_U {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_headerContent__3oiG_ {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_headerContent__3oiG_ {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_headerContent__3oiG_ {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_headerContent__3oiG_ {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_headerContent__3oiG_ {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_headerContent__3oiG_ {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_headerContent__3oiG_ {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_headerContent__3oiG_ {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_headerContent__3oiG_ {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_headerContent__3oiG_ {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_headerContent__3oiG_ {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_headerContent__3oiG_ {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_headerContent__3oiG_ {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_headerContent__3oiG_ {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_headerContent__3oiG_ {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_headerContent__3oiG_ {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_headerContent__3oiG_ {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_headerContent__3oiG_ {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_headerContent__3oiG_ {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_headerContent__3oiG_ {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_headerContent__3oiG_ {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_headerContent__3oiG_ {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_headerContent__3oiG_ {
      padding-right: 40px;
    }
  }
}

.header_options__2SF7N {
  align-items: center;
  display: flex;
}

.header_menuButton__1HDR2 {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;
}

.header_menuIcon__YG9-Y {
  /* color: #c000; */
  height: 36px;
  width: 36px;
}

.header_uploadButton__3UfO- {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
}
.header_uploadButton__3UfO-:hover {
  background-color: #5aa5ef;
  /* cursor: not-allowed !important; */
  color: white;
}
.header_disableduploadButton__1pT53 {
  background-color: black !important;
  cursor: not-allowed !important;
  opacity: 0.6;
}
.header_disableduploadButton__1pT53:hover {
  cursor: not-allowed !important;
  color: white !important;
  background-color: black !important;
}
.header_disableduploadButton__1pT53:hover >.header_uploadText__1olHt {
  cursor: not-allowed !important;
  color: white !important;
}
.header_uploadButton__3UfO-.header_compactView__Qncsn {
  margin-right: 24px;
  padding: 0;
}

.header_uploadButton__3UfO-.header_compactView__Qncsn .header_uploadIcon__m-ssl {
  height: 24px;
}

.header_uploadButton__3UfO-.header_regularView__1DUae {
  border: 1px solid #707070;
}

.header_uploadButton__3UfO-.header_regularView__1DUae .header_uploadIcon__m-ssl {
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  line-height: 1;
}

.header_profileMenuContainer__2P1HB,
.header_notificationAnimation__2Rnyw {
  -webkit-animation: header_growOut__KND94 700ms ease-in-out forwards;
          animation: header_growOut__KND94 700ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

@-webkit-keyframes header_growOut__KND94 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes header_growOut__KND94 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .header_profileMenuContainer__2P1HB {
    padding: 12.75px 25.5px 25.5px 25.5px;
    right: 12.75px !important;
    top: 12.75px !important;
    width: 286.5px !important;
  }
  .header_profileMenuCurrentProfileDetailsIcon__2Iwlh {
    height: 38.085px;
    margin-left: 12.7px;
    width: 38.085px;
  }
  .header_profileMenuDivider__nj703 {
    margin: 12.75px 0 25.5px 0;
  }
  .header_profileMenuProfilesContainer__cOujo {
    margin-bottom: 36px;
  }
  .header_profileMenuProfile__9PlgG {
    margin: 18px 0;
  }
  .header_profileMenuProfileIcon__13hlk {
    height: 29.535px;
    width: 29.535px;
  }
  .header_profileMenuLink__3S2_U {
    margin: 24px 0;
  }
  .header_profileMenuProfileName__3fslf {
    margin-left: 27px;
  }
  .header_profileMenuProfileName__3fslf,
  .header_profileMenuCurrentProfileDetailsText__35W1Q,
  .header_profileMenuLink__3S2_U {
    font-size: 17.25px;
  }
  .header_dropdownIcon__gsjvv {
    font-size: 16.5px;
    height: 21px;
    line-height: 1.2;
    margin-right: 6px;
    width: 21px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae {
    height: 41.25px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae .header_profileIcon__2RzQu {
    height: 41.25px;
    width: 41.25px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae {
    height: 41.25px;
    margin-right: 35.1675px;
    padding: 0px 16.25px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae .header_uploadIcon__m-ssl {
    border-radius: 2.25px;
    font-size: 18px;
    height: 18px;
    margin-left: 12px;
    width: 18px;
  }
  .header_uploadText__1olHt {
    font-size: 16px;
  }
  .header_alignIcon__iHTwf {
    margin-right: 15px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_profileMenuContainer__2P1HB {
    padding: 15.1406px 30.28125px 30.28125px 30.28125px;
    right: 15.140625px !important;
    top: 15.140625px !important;
    width: 340.21875px !important;
  }
  .header_profileMenuCurrentProfileDetailsIcon__2Iwlh {
    height: 45.2259375px;
    margin-left: 15.1406px;
    width: 45.2259375px;
  }
  .header_profileMenuDivider__nj703 {
    margin: 15.1406px 0 30.28125px 0;
  }
  .header_profileMenuProfilesContainer__cOujo {
    margin-bottom: 42.75px;
  }
  .header_profileMenuProfile__9PlgG {
    margin: 21.375px 0;
  }
  .header_profileMenuProfileIcon__13hlk {
    height: 35.0728125px;
    width: 35.0728125px;
  }
  .header_profileMenuLink__3S2_U {
    margin: 28.5px 0;
  }
  .header_profileMenuProfileName__3fslf {
    margin-left: 32.0625px;
  }
  .header_profileMenuProfileName__3fslf,
  .header_profileMenuCurrentProfileDetailsText__35W1Q,
  .header_profileMenuLink__3S2_U {
    font-size: 20.484375px;
  }
  .header_dropdownIcon__gsjvv {
    font-size: 19.59375px;
    height: 24.9375px;
    line-height: 1.275;
    margin-right: 7.125px;
    width: 24.9375px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae {
    height: 48.98px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae .header_profileIcon__2RzQu {
    height: 48.98px;
    width: 48.98px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae {
    height: 48.984375px;
    margin-right: 41.76140625px;
    padding: 0 24.046875px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae .header_uploadIcon__m-ssl {
    border-radius: 2.671875px;
    font-size: 21.375px;
    height: 21.375px;
    margin-left: 14.25px;
    width: 21.375px;
  }
  .header_uploadText__1olHt {
    font-size: 17px;
  }
  .header_alignIcon__iHTwf {
    margin-right: 30px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .header_profileMenuContainer__2P1HB {
    padding: 17px 34px 34px 34px;
    right: 17px !important;
    top: 17px !important;
    width: 382px !important;
  }
  .header_profileMenuCurrentProfileDetailsIcon__2Iwlh {
    height: 50.78px;
    margin-left: 17px;
    width: 50.78px;
  }
  .header_profileMenuDivider__nj703 {
    margin: 17px 0 34px 0;
  }
  .header_profileMenuProfilesContainer__cOujo {
    margin-bottom: 48px;
  }
  .header_profileMenuProfile__9PlgG {
    margin: 24px 0;
  }
  .header_profileMenuProfileIcon__13hlk {
    height: 39.38px;
    width: 39.38px;
  }
  .header_profileMenuLink__3S2_U {
    margin: 32px 0;
  }
  .header_profileMenuProfileName__3fslf {
    margin-left: 36px;
  }
  .header_profileMenuProfileName__3fslf,
  .header_profileMenuCurrentProfileDetailsText__35W1Q,
  .header_profileMenuLink__3S2_U {
    font-size: 23px;
  }
  .header_dropdownIcon__gsjvv {
    font-size: 22px;
    height: 28px;
    line-height: 1.35;
    margin-right: 8px;
    width: 28px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae {
    height: 55px;
  }
  .header_profileButton__2D8lI.header_regularView__1DUae .header_profileIcon__2RzQu {
    height: 55px;
    width: 55px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae {
    height: 55px;
    margin-right: 46.89px;
    padding: 0 27px;
  }
  .header_uploadButton__3UfO-.header_regularView__1DUae .header_uploadIcon__m-ssl {
    border-radius: 3px;
    font-size: 24px;
    height: 24px;
    margin-left: 16px;
    width: 24px;
  }
  .header_uploadText__1olHt {
    font-size: 18px;
  }
}

.header_uploadText__1olHt {
  color: #fff;
  font-weight: 400;
}
.header_uploadButton__3UfO-:hover > .header_uploadText__1olHt {
  color: #000;
}
.header_profileButton__2D8lI {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
}

.header_profileButton__2D8lI.header_compactView__Qncsn {
  border: 1px solid #fff;
  border-radius: 99999999999999999999px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  padding: 0;
}

.header_profileButton__2D8lI.header_compactView__Qncsn .header_profileMenuCurrentProfileDetailsIcon__2Iwlh {
  margin-left: 0;
  width: 100%;
}

.header_profileButton__2D8lI.header_compactView__Qncsn .header_profileIcon__2RzQu {
  height: 22px;
}

@media only screen and (min-width: 900px) {
  .header_profileBar__2fI-D {
    display: none !important;
  }
}

.header_profileBarContent__1ASBT {
  max-width: 420px;
  width: calc(100vw - 32px);
}

.header_profileBarProfilesContainer__bmijK {
  color: #fff;
  display: flex;
  overflow-x: auto;
  position: relative;
  width: 100%;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.header_profileBarProfilesContainer__bmijK::-webkit-scrollbar {
  display: none;
}

.header_profileBarProfilesContent__JLvbH {
  display: flex;
  flex-grow: 1;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: max(25px, env(safe-area-inset-top));
  }
}

@supports not (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: 25px;
  }
}

@supports (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: max(25px, env(safe-area-inset-right));
  }
}

@supports not (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: 25px;
  }
}

@supports (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: max(25px, env(safe-area-inset-left));
  }
}

@supports not (padding: max(0px)) {
  .header_profileBarProfilesContent__JLvbH {
    padding: 25px;
  }
}

.header_profileBarProfile__2m25n {
  color: #fff;
  flex-shrink: 0;
  margin-right: 11px;
  width: 97px;
}

.header_profileBarProfile__2m25n:last-of-type {
  margin-right: 0;
}

.header_profileBarProfileIconContainer__2APol {
  align-items: center;
  border: 2px solid #efefef;
  border-radius: 19px;
  display: flex;
  height: 97px;
  justify-content: center;
  width: 100%;
}

.header_profileBarProfileIcon__3atGc {
  color: #fff;
  height: 50.2px;
  width: 50.2px;
}

.header_profileBarProfileAddIcon__oh45U {
  align-items: center;
  background-color: #fff;
  border-radius: 32px;
  color: #000;
  display: flex;
  font-size: 36px;
  height: 44px;
  justify-content: center;
  line-height: 1;
  width: 44px;
}

.header_profileBarProfileName__18-Q3 {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
}

.header_profileBarLinksContainer__2_EUH {
  padding: 0 35px 35px 35px;
}

.header_profileBarLink__2mRlw {
  color: #ffffff;
  display: block;
  font-size: 15px;
  font-weight: 500;
  margin: 28px 0;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .header_profileMenu__1tQ_- {
    display: none !important;
  }
}

.header_profileMenu__1tQ_- {
  padding: 0 !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileMenu__1tQ_- {
      margin-right: max(-1px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileMenu__1tQ_- {
      margin-right: -1px;
    }
  }
}

.header_profileMenuCurrentProfileDetails__2L-sh {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.header_profileMenuCurrentProfileDetailsIcon__2Iwlh {
  flex-shrink: 0;
}
.header_profileMenuCurrentProfileDetailsIcon__2Iwlh.header_border__3uPis {
  border-radius: 9999999999999999px;
  border: 1px solid;
  object-fit: cover;
  object-position: top;
}
.header_profileMenuCurrentProfileDetailsIcon__2Iwlh.header_border2__1kLJA {
  border-radius: 9999999999999999px;
  border: 2px solid;
}
.header_profileMenuDivider__nj703 {
  background-color: #000;
  border: none;
  height: 1px;
}

.header_profileMenuLinksContainer__3821f {
  position: relative;
}

.header_profileMenuProfile__9PlgG {
  align-items: center;
  display: flex;
}

.header_profileMenuProfileIcon__13hlk {
  align-items: center;
  color: #000;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  object-fit: cover;
  object-position: center;
  position: relative;
}
.header_profileMenuProfileIcon__13hlk.header_border__3uPis {
  border: 1px solid black;
  border-radius: 999999999999999999999999px;
}
.header_profileMenuProfileIcon__13hlk.header_border2__1kLJA {
  border: 2px solid black;
  border-radius: 999999999999999999999999px;
}
.header_profileMenuProfileName__3fslf {
  color: #000;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header_profileMenuLink__3S2_U {
  color: #000;
  font-family: "Poppins";
  display: block;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header_profileMenuLink__3S2_U:last-of-type {
  margin-bottom: 10px;
}

@media only screen and (max-width: 899px) {
  .header_transparentCompactView__xvIg0 .header_menuIcon__YG9-Y {
    color: #fff !important;
  }
  .header_transparentCompactView__xvIg0 .header_uploadButton__3UfO-.header_compactView__Qncsn,
  .header_transparentCompactView__xvIg0 .header_profileButton__2D8lI.header_compactView__Qncsn {
    border-color: #fff !important;
    border-width: 2px;
  }
  .header_transparentCompactView__xvIg0 .header_uploadButton__3UfO-.header_compactView__Qncsn .header_uploadIcon__m-ssl,
  .header_transparentCompactView__xvIg0 .header_uploadButton__3UfO-.header_compactView__Qncsn .header_profileIcon__2RzQu,
  .header_transparentCompactView__xvIg0 .header_profileButton__2D8lI.header_compactView__Qncsn .header_uploadIcon__m-ssl,
  .header_transparentCompactView__xvIg0 .header_profileButton__2D8lI.header_compactView__Qncsn .header_profileIcon__2RzQu,
  .header_uploadButton__3UfO-.header_compactView__Qncsn .header_uploadIcon__m-ssl {
    color: #fff !important;
  }
}

.header_profileIcon__2RzQu {
  display: block;
  object-fit: contain;
  object-position: center;
  padding: 6%;
}

.header_dropdownIcon__gsjvv {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.header_headerFill__1lcs9 {
  flex-grow: 0;
  flex-shrink: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
}

.header_headerFill__1lcs9.header_noHeader__1kvs8 {
  display: none !important;
}

@media only screen and (min-width: 900px) {
  .header_headerFill__1lcs9.header_noRegularHeader__2if1d {
    display: none !important;
  }
}

@media all and (hover: hover) {
  .header_profileBarLink__2mRlw:hover,
  .header_profileMenuLink__3S2_U:hover,
  .header_profileMenuProfile__9PlgG:hover {
    text-decoration: underline;
  }
  .header_fontBold__2vBlv:hover {
    font-weight: 700;
  }
}

@media all and (pointer: coarse) {
  .header_profileBarLink__2mRlw:active,
  .header_profileBarLink__2mRlw:target,
  .header_profileMenuLink__3S2_U:active,
  .header_profileMenuLink__3S2_U:target,
  .header_profileMenuProfile__9PlgG:active,
  .header_profileMenuProfile__9PlgG:target {
    text-decoration: underline;
  }
}

.header_profileHeader__3ELwe {
  z-index: 100;
  position: relative;
}

.header_profileHeader__3ELwe.header_hidden__3bfLo {
  display: none !important;
}

.header_header__2MyKg.header_hidden__3bfLo {
  display: none !important;
}

.header_profileHeader__3ELwe,
.header_profileHeaderContent__yv07G {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

.header_profileHeader__3ELwe,
.header_profileHeader__3ELwe *,
.header_profileHeaderContent__yv07G,
.header_profileHeaderContent__yv07G * {
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .header_profileHeader__3ELwe,
  .header_profileHeaderContent__yv07G {
    display: none !important;
  }
}

.header_profileHeaderContent__yv07G {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(82.5px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(97.96875px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-left: max(110px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 82.5px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 97.96875px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-left: 110px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(82.5px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(97.96875px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-right: max(110px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 82.5px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 97.96875px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .header_profileHeaderContent__yv07G {
      padding-right: 110px;
    }
  }
}

.header_namePosition__1H8AM {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  max-width: 10ch;
}

.header_leftContent__2T1kB,
.header_rightContent__3C0ot,
.header_profileHeaderIconButton__2e6sl {
  align-items: center;
  display: inline-flex;
}

.header_profileHeaderIconButton__2e6sl {
  color: #ffffff;
}

.header_profileHeaderIconButton__2e6sl:last-of-type {
  margin-right: 0;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_profileHeaderIconButton__2e6sl {
    margin-right: 65px;
  }
  .header_namePosition__1H8AM {
    margin-right: 65px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_profileHeaderIconButton__2e6sl {
    margin-right: 78px;
  }
  .header_namePosition__1H8AM {
    margin-right: 78px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_profileHeaderIconButton__2e6sl {
    margin-right: 92.625px;
  }
  .header_namePosition__1H8AM {
    margin-right: 92.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_profileHeaderIconButton__2e6sl {
    margin-right: 104px;
  }
  .header_namePosition__1H8AM {
    margin-right: 104px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_profileHeaderIconButtonIcon__1yIxC {
    height: 27.1875px;
    margin-right: 11.875px;
    width: 27.1875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_profileHeaderIconButtonIcon__1yIxC {
    height: 32.625px;
    margin-right: 14.25px;
    width: 32.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_profileHeaderIconButtonIcon__1yIxC {
    height: 38.7421875px;
    margin-right: 11px;
    width: 38.7421875px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_profileHeaderIconButtonIcon__1yIxC {
    height: 43.5px;
    margin-right: 19px;
    width: 43.5px;
  }
}

.header_profileHeaderIconButtonText__2TlBx {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  background-color: transparent;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  position: relative; /* This ensures the spinner is positioned relative to this container */
}

.header_profileHeaderIconButtonText__2TlBx:hover,
.header_uploadText__1olHt:hover {
  /* color: #000; */
  cursor: pointer;
}
.header_profileHeaderIconButtonText__2TlBx:disabled {
  color: #fff;
  cursor: default;
}
/* .uploadButton:hover > .uploadText {
  color: #000 !important;
} */

.header_textMouseOver__250JM,
.header_profileMouseOver__10TEz {
  color: #5aa5ef;
}

.header_uploadButtonHover__3sVV1:hover {
  background-color: #5aa5ef !important;
  color:  #000 !important;
}
.header_uploadButton__3UfO-:disabled,.header_uploadButtonHover__3sVV1:disabled {
  /* background-color: black !important; */
  /* color: #fff; */
  opacity: 0.5;
}

.header_notificationIconHover__2GmyU:hover,
.header_profileMenuCurrentProfileDetailsIcon__2Iwlh:hover {
  color: #5aa5ef;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_profileHeaderIconButtonText__2TlBx {
    font-size: 11.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_profileHeaderIconButtonText__2TlBx {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_profileHeaderIconButtonText__2TlBx {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_profileHeaderIconButtonText__2TlBx {
    font-size: 19px;
  }
}
.header_backButton__W2Upz {
  position: absolute;
  /* top: 0;
  left: 0; */
  -webkit-transform: translateX(-60px);
          transform: translateX(-60px);
}

.header_notificationIcon__14yjU {
  color: white;
  margin-left: -26px;
}

.header_videoUploadIcon__2r1pO {
  color: white;
  /* margin-left: -26px; */
}

.header_myProjectorLink__33cv4 {
  color: #fff;
}

.header_profileIconPosition__u5llf {
  margin-right: 25px;
}

.header_iconColor__1vYTt {
  color: #1172d2;
  margin-left: 6px;
}

.header_allProjectorAlign__1qSEo {
  margin-left: 38px;
}

.header_notificationDropDown__2Ls3y {
  max-width: 400px;
  min-width: 200px;
  /* height: 800px; */
  /* overflow: auto; */
  /* overflow-x: hidden */
  /* overflow-y: auto;
  max-height: 400px */
}

.header_notificationIconBlack__2XyWK {
  color: #000;
  margin-left: 16px;
  margin-top: 18px;
}

.header_notficatonIcon__3l69S {
  top: 15px;
  position: relative;
  left: 13px;
}

.header_notfHeading__1qsYf {
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  padding: 15px;
  font-family: "Montserrat" !important;
}

.header_dividerAlign__HNud6 {
  margin-left: 18px !important;
  color: #707070 !important;
  /* border-style : solid !important; */
  outline-width: medium !important;
  margin-right: 14px !important;
}

.header_notificationMenu__wD04t {
  /* overflow-y: auto;
  max-height: 600px */
  max-height: 600px;
  overflow-y: scroll;
  min-width: 500px;
}

.header_invitationHeading__2AAUO {
  color: #000 !important;
  font-family: "Montserrat" !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.header_notificationContainer__zwd44 {
  margin: 5px 11px 9px 17px;
}

.header_clearAllIcon__20QdE {
  color: #000 !important;
  margin-top: 10px;
  font-size: 12px !important;
  text-transform: none !important;
  font-family: "Montserrat" !important;
  justify-content: flex-end;
}
.header_generalMessage__-8zX- {
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header_divSpace__1tVXk {
  margin-top: 10px;
  margin-bottom: 13px;
}

.header_notfDivider__13UkD {
  margin-left: 3px !important;
  color: #707070 !important;
  outline-width: medium !important;
  margin-right: 7px !important;
}

.header_loaderDiv__XTtJF {
  justify-content: center;
  display: flex;
}

.header_clearIcon__1fgBm {
  color: #000 !important;
  margin-top: 10px;
  font-size: 12px !important;
  text-transform: none !important;
  font-family: "Montserrat" !important;
}

.header_clearAllIconAlign__2Ejdg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header_clearAllNotification__1VJSe {
  display: flex;
}

.header_headingSpace__s3AJF {
  margin-bottom: 14px !important;
}

@media only screen and (max-width: 384px) {
  .header_clearAllIcon__20QdE {
    font-size: 10px !important;
  }
  .header_clearIcon__1fgBm {
    font-size: 10px !important;
  }
}

.header_logoImage__Ru_vs {
  width: 50%;
  height: 90%;
  cursor: pointer;
}

.header_successImage__EzUte {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.header_errorIcon__YXGdn {
  width: 24px;
  height: 24px;
}

.header_videoIcon__2_Oem {
  color: #d93025;
}

.header_titleforVideoUpload__1YboM {
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 6px !important;
}
.header_remainingtimeforVideoUpload__Etz-E {
  display: grid;
  justify-content: right;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 11px !important;
  align-items: center;
}

.header_cancelIcon__1NhMw {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.header_circularProgressBar__1XXY0 {
  -webkit-animation: none !important;
          animation: none !important;
}

.header_videoBannerContainer__1jrvy {
  width: 100%;
  height: 450px;
  margin-top: 100px;
}
.header_heroImage__1L63l {
  width: 100%;
  height: 450px;
  position: relative;
}

.header_videoBannerHeader__1MFq8 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header_videoDetails__3OhVs {
  display: flex;
  bottom: 0;
  width: 100%;
  color: #fff;
  position: absolute;
  padding-left: 100px;
  flex-direction: column;
  height: 150px;
}

.header_videoActions__1i2bJ {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header_videoActions__1i2bJ {
    margin-bottom: -10px;
    margin-left: 5px;
  }
  .header_videoDetails__3OhVs {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoActions__1i2bJ {
    margin-bottom: 12px;
  }
  .header_videoDetails__3OhVs {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoActions__1i2bJ {
    margin-bottom: 12px;
  }
  .header_videoDetails__3OhVs {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoActions__1i2bJ {
    margin-bottom: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoActions__1i2bJ {
    margin-bottom: 16.921875px;
  }
  .header_videoBannerContainer__1jrvy {
    height: 500px;
  }
  .header_heroImage__1L63l {
    height: 530px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoActions__1i2bJ {
    margin-bottom: 19px;
  }
  .header_videoBannerContainer__1jrvy {
    height: 530px;
  }
  .header_heroImage__1L63l {
    height: 550px;
  }
}

.header_videoPlayButton__1emHX {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  position: relative;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .header_videoPlayButton__1emHX {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoPlayButton__1emHX {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoPlayButton__1emHX {
    font-size: 16.25px;
    height: 56px;
    padding: 0 30px;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoPlayButton__1emHX {
    font-size: 19.5px;
    height: 55.5px;
    padding: 0 34.5px;
    margin-top: 30px;
    right: 16px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoPlayButton__1emHX {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoPlayButton__1emHX {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
  }
}

@media only screen and (max-width: 767px) {
  .header_videoPlayIcon__HK45z {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoPlayIcon__HK45z {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoPlayIcon__HK45z {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoPlayIcon__HK45z {
    height: 27px;
    margin-right: 17.25px;
    width: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoPlayIcon__HK45z {
    height: 32.0625px;
    margin-right: 20.48438px;
    width: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoPlayIcon__HK45z {
    height: 36px;
    margin-right: 23px;
    width: 36px;
  }
}

.header_videoTitle__NDJ9Y {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  /* max-width: 17ch; */
  /* white-space: nowrap; */
  line-height: 1em;
  height: 2em;
  /* color: #fff; */
  color: #fff;
  /* width: 60%; */
  top: 45%;
  position: absolute;
  left: 100px;
}

@media only screen and (max-width: 899px) {
  .header_videoTitle__NDJ9Y {
    margin-bottom: auto;
    font-size: 22.400000000000002px;
  }
}

@media only screen and (max-width: 767px) {
  .header_videoTitle__NDJ9Y {
    font-size: 22.400000000000002px;
    left: 25px;
    margin-top: 85px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoTitle__NDJ9Y {
    font-size: 22.937500000000004px;
    left: 30px;
    margin-top: 85px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoTitle__NDJ9Y {
    font-size: 22.25px;
    /* margin-right: 45%; */
    left: 30px;
    margin-top: 90px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoTitle__NDJ9Y {
    font-size: 26.50000000000001px;
    margin-right: 50%;
    margin-top: 90px;
    left: 80px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoTitle__NDJ9Y {
    font-size: 33.78125000000001px;
    margin-right: 50%;
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoTitle__NDJ9Y {
    font-size: 35px;
    margin-right: 50%;
  }
}

.header_videoAddButton__3cntY {
  position: relative;
}

.header_videoAddButton__3cntY {
  align-items: center;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .header_videoAddButton__3cntY {
    border-width: 2px;
    font-size: 22.4px;
    height: 56px;
    margin-left: 11.2px;
    width: 56px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoAddButton__3cntY {
    border-width: 2px;
    font-size: 26.25px;
    height: 56px;
    margin-left: 13.125px;
    width: 56px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoAddButton__3cntY {
    border-width: 3px;
    font-size: 35px;
    height: 56px;
    margin-left: 17.5px;
    width: 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoAddButton__3cntY {
    border-width: 3px;
    font-size: 42px;
    height: 55px;
    margin-left: 21px;
    width: 55px;
    right: 10px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoAddButton__3cntY {
    border-width: 4px;
    font-size: 49.875px;
    height: 56.125px;
    margin-left: 24.9375px;
    width: 56.125px;
    margin-top: 1px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoAddButton__3cntY {
    border-width: 4px;
    font-size: 56px;
    height: 68px;
    margin-left: 28px;
    width: 68px;
  }
}

.header_videoInfoWrap__29CKu {
  display: block;
}

.header_videoInfo__2astu {
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .header_videoInfo__2astu {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoInfo__2astu {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoInfo__2astu {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoInfo__2astu {
    font-size: 15px;
    right: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoInfo__2astu {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoInfo__2astu {
    font-size: 20px;
  }
}

.header_videoInfoSeparator__11zHz {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .header_videoInfoSeparator__11zHz {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_videoInfoSeparator__11zHz {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_videoInfoSeparator__11zHz {
    height: 15px;
    margin: 0 7.5px;
    width: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_videoInfoSeparator__11zHz {
    height: 15px;
    margin: 0 9px;
    width: 2px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_videoInfoSeparator__11zHz {
    height: 17.8125px;
    margin: 0 10.6875px;
    width: 3px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_videoInfoSeparator__11zHz {
    height: 20px;
    margin: 0 12px;
    width: 3px;
  }
}
.header_updatePaymentButton__1c2DJ {
  background-color: #5aa5ef;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  width: 200px;
  font-size: 14px;
  margin-top: 20px;
  padding: 5px;
  margin-bottom: 15px;
}

.header_semiOvalWrap__1JYz1 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.header_box__JDf-z {
  height: 40px;
  width: 40%;
  background-image: radial-gradient(
    ellipse at top center,
    #073d96 79%,
    transparent 70%
  );
  position: absolute;
  top: 0;
  -webkit-filter: blur(50px);
          filter: blur(50px);
}

.header_selectedTab__1Q96h {
  text-decoration: underline;
  text-underline-offset: 5px !important;
  -webkit-text-decoration-color: #5aa5ef;
          text-decoration-color: #5aa5ef;
  text-decoration-thickness: 3px;
}
.header_iconMouseOver__1ITCT > path:nth-of-type(2) {
  fill: #5aa5ef !important;
}

.header_searchIconMouseOver__1t_hH > g > path:nth-of-type(2) {
  fill: #5aa5ef !important;
}

@media only screen and (max-width: 899px) {
  .components_pageContent__3jW-R {
    display: flex;
  }
}

.components_dashboard__3-dMf {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .components_dashboard__3-dMf {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 767px) {
  .components_dashboard__3-dMf {
    margin: 32px auto;
    max-width: 420px;
  }
}

@media only screen and (min-width: 900px) {
  .components_dashboard__3-dMf {
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
  }
}

.components_sectionsContainer__2ZKAO {
  flex-grow: 1;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .components_sectionsContainer__2ZKAO {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .components_sectionsContainer__2ZKAO {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 100%;
  }
}

.components_section__1RdhR {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}

.components_sectionTitle__SkgJB {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.components_sectionTitle__SkgJB > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.components_videoUploadSection__358wP {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.components_videoUploadSectionContent__ncEZv {
  align-items: center;
  border: 1px dashed #707070;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.components_uploadIcon__3Zk4B {
  position: relative;
}

.components_uploadButton__38mTW {
  background-color: #1172d2 !important;
  border-radius: 0 !important;
}

.components_videoAnalyticsSection__14vn5 {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.components_storageSection__2y02r {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.components_userManagementSection__T_Jfq {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.components_addUserButton__2A5Se {
  align-items: center;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #5aa5ef;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 1;
  margin-left: 2.5%;
}

.components_userRequest__yypIj {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.components_userRequestText__mlnCc {
  overflow: hidden;
}

.components_userRequestName__1dQj- {
  font-size: 600 !important;
  line-height: 1.3;
  text-transform: capitalize;
  font-weight: 600;
}

.components_userRequestEmail__2OMR5 {
  color: #b2b2b2;
}

.components_userRequestName__1dQj-,
.components_userRequestEmail__2OMR5 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.components_userRequestActionsContainer__8NIki {
  display: flex;
}

.components_userRequestActionButton__2S-vR {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 700;
  line-height: 0;
  margin-left: 2%;
  font-family: 'Montserrat' !important;
}

@media all and (hover: hover) {
  .components_userRequestActionButton__2S-vR.components_delete__2eGt9:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .components_userRequestActionButton__2S-vR.components_delete__2eGt9:active,
  .components_userRequestActionButton__2S-vR.components_delete__2eGt9:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .components_userRequestActionButton__2S-vR:not(.components_delete__2eGt9):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .components_userRequestActionButton__2S-vR:not(.components_delete__2eGt9):active,
  .components_userRequestActionButton__2S-vR:not(.components_delete__2eGt9):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

.components_videoAnalyticsList__23oki {
  width: 100%;
}

.components_videoAnalyticsList__23oki thead {
  font-weight: 600;
}

.components_videoAnalyticsList__23oki td:nth-child(2) {
  text-align: right;
}

.components_storageSectionContent__2VvPt {
  display: flex;
  flex-direction: column;
}

.components_storageManagementButton__2FPKL {
  background-color: #c9c9c9;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
}

.components_storageInfoBar__3QXaR {
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  margin-top: 0.5rem;
  overflow: hidden;
}

.components_storageInfoBarContent__3bpxZ {
  color: #fff;
  display: inline;
  line-height: 1.6;
  margin-left: -2.5px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.components_storageInfoBarContent__3bpxZ.components_storageInfoBarVideos__1-djf {
  background-color: #5aa5ef;
  /* width: 38.4476%; */
}

.components_storageInfoBarContent__3bpxZ.components_storageInfoBarPhotos__2aY0g {
  background-color: #ff4040;
  /* width: 27.0758%; */
}

.components_storageInfoBarContent__3bpxZ.components_storageInfoBarAvailable__1RTVc {
  text-align: right;
  background-color: #000;
  flex-grow: 1;
}

.components_accessRequestNotes__2jsp1 {
  line-height: 5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .components_sectionsContainer__2ZKAO {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 4px;
  }
  .components_uploadIcon__3Zk4B {
    height: 41px;
    width: 41px;
  }
  .components_videoUploadSection__358wP {
    height: 184px;
  }
  .components_videoAnalyticsSection__14vn5 {
    height: 216px;
  }
  .components_section__1RdhR {
    border-radius: 12.65625px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .components_videoUploadSectionContent__ncEZv {
    border-radius: 8.90625px;
  }
  .components_addUserButton__2A5Se {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .components_userRequestName__1dQj- {
    font-size: 11px;
  }
  .components_userRequestEmail__2OMR5 {
    font-size: 9px;
  }
  .components_sectionTitle__SkgJB {
    margin-bottom: 14px;
  }
  .components_userRequest__yypIj {
    margin-top: 22px;
  }
  .components_userRequestActionButton__2S-vR {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
  .components_videoAnalyticsList__23oki,
  .components_storageInfoBar__3QXaR {
    font-size: 14px;
  }
  .components_storageInfoText__2MC7i {
    font-size: 13.5px;
  }
  .components_storageManagementButton__2FPKL {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .components_storageInfoBar__3QXaR {
    height: 23px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .components_sectionsContainer__2ZKAO {
    grid-gap: 14.375000000000002px;
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .components_uploadIcon__3Zk4B {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .components_section__1RdhR {
    border-radius: 16.875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .components_videoUploadSectionContent__ncEZv {
    border-radius: 11.875px;
  }
  .components_addUserButton__2A5Se {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .components_userRequestName__1dQj- {
    font-size: 13px;
  }
  .components_userRequestEmail__2OMR5 {
    font-size: 9px;
  }
  .components_sectionTitle__SkgJB {
    margin-bottom: 14.25px;
  }
  .components_userRequest__yypIj {
    margin-top: 22px;
  }
  .components_userRequestActionButton__2S-vR {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
  .components_videoAnalyticsList__23oki,
  .components_storageInfoBar__3QXaR {
    font-size: 14px;
  }
  .components_storageInfoText__2MC7i {
    font-size: 13.5px;
  }
  .components_storageManagementButton__2FPKL {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 126.25px;
  }
  .components_storageInfoBar__3QXaR {
    height: 23px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .components_sectionsContainer__2ZKAO {
    grid-gap: 17.25px;
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .components_uploadIcon__3Zk4B {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .components_section__1RdhR {
    border-radius: 20.25px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .components_videoUploadSectionContent__ncEZv {
    border-radius: 14.25px;
  }
  .components_addUserButton__2A5Se {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .components_userRequestName__1dQj- {
    font-size: 12.75px;
  }
  .components_userRequestEmail__2OMR5 {
    font-size: 10.5px;
  }
  .components_sectionTitle__SkgJB {
    margin-bottom: 14.25px;
  }
  .components_userRequest__yypIj {
    margin-top: 22px;
  }
  .components_userRequestActionButton__2S-vR {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
  .components_videoAnalyticsList__23oki,
  .components_storageInfoBar__3QXaR {
    font-size: 15px;
  }
  .components_storageInfoText__2MC7i {
    font-size: 13.5px;
  }
  .components_storageManagementButton__2FPKL {
    font-size: 17.25px;
    height: 34px;
    width: 151.5px;
  }
  .components_storageInfoBar__3QXaR {
    height: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .components_sectionsContainer__2ZKAO {
    grid-gap: 20.484375px;
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .components_uploadIcon__3Zk4B {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .components_section__1RdhR {
    border-radius: 24.046875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .components_videoUploadSectionContent__ncEZv {
    border-radius: 16.921875px;
  }
  .components_addUserButton__2A5Se {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .components_userRequestName__1dQj- {
    font-size: 15.265625px;
  }
  .components_userRequestEmail__2OMR5 {
    font-size: 12.46875px;
  }
  .components_sectionTitle__SkgJB {
    margin-bottom: 16.921875px;
  }
  .components_userRequest__yypIj {
    margin-top: 25.828125px;
  }
  .components_userRequestActionButton__2S-vR {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
  .components_videoAnalyticsList__23oki,
  .components_storageInfoBar__3QXaR {
    font-size: 17.8125px;
  }
  .components_storageInfoText__2MC7i {
    font-size: 16.03125px;
  }
  .components_storageManagementButton__2FPKL {
    font-size: 20.484375px;
    height: 34px;
    width: 179.90625px;
  }
  .components_storageInfoBar__3QXaR {
    height: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .components_sectionsContainer__2ZKAO {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 33px;
  }
  .components_uploadIcon__3Zk4B {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .components_section__1RdhR {
    border-radius: 27px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
  }
  .components_videoUploadSectionContent__ncEZv {
    border-radius: 19px;
  }
  .components_addUserButton__2A5Se {
    font-size: 36px;
    height: 44px;
    width: 44px;
  }
  .components_userRequestName__1dQj- {
    font-size: 17px;
  }
  .components_userRequestEmail__2OMR5 {
    font-size: 14px;
  }
  .components_sectionTitle__SkgJB {
    margin-bottom: 19px;
  }
  .components_userRequest__yypIj {
    margin-top: 29px;
  }
  .components_userRequestActionButton__2S-vR {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
  .components_videoAnalyticsList__23oki,
  .components_storageInfoBar__3QXaR {
    font-size: 20px;
  }
  .components_storageInfoText__2MC7i {
    font-size: 18px;
  }
  .components_storageManagementButton__2FPKL {
    font-size: 23px;
    height: 34px;
    width: 202px;
  }
  .components_storageInfoBar__3QXaR {
    height: 31px;
  }
}
.components_headerTheme__3sdph {
  background-color: black;
}

.components_deleteActive__2ozF1 {
  background-color: #fff !important;
  color:#FF0000 !important;
  border-color: #000 !important;
}




.logo_logo__1LJl2 {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1440px) {
  .logo_logo__1LJl2:not(.logo_large__DdbTD) .logo_logoImage__2XjHL {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo_logo__1LJl2:not(.logo_large__DdbTD) .logo_logoImage__2XjHL {
    height: 56px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo_logo__1LJl2:not(.logo_large__DdbTD) .logo_logoImage__2XjHL {
    height: 64px;
  }
}

@media only screen and (max-width: 767px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 66.305px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 70.34375px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 72.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 78.74999999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 80.45312499999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo_logo__1LJl2.logo_large__DdbTD .logo_logoImage__2XjHL {
    height: 85px;
  }
}

.logo_logoImage__2XjHL {
  position: relative;
}


@media only screen and (max-width: 1440px) {
  .logo_logo__1LJl2.logo_footerLogo__3NW31 .logo_logoImage__2XjHL {
    height: 33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .logo_logo__1LJl2.logo_footerLogo__3NW31 .logo_logoImage__2XjHL {
    height: 35px;
  }
}

@media only screen and (min-width: 1710px) {
  .logo_logo__1LJl2.logo_footerLogo__3NW31 .logo_logoImage__2XjHL {
    height: 37px;
  }
}

.share-screen_hiddenFormField__tc8Dj {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.share-screen_loader__1eXS6 {
  margin-top: -4px;
  color: #5AA5EF;
}

.share-screen_shareScreen__2GK7R {
  background-color: transparent;
  width: 100%;
}

.share-screen_shareScreen__2GK7R,
.share-screen_shareScreen__2GK7R * {
  font-family: Poppins, sans-serif;
}

.share-screen_shareScreen__2GK7R .MuiDialog-paper {
  margin: calc(var(--site-padding)) !important;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreen__2GK7R .MuiDialog-paper {
    max-width: 499.21875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreen__2GK7R .MuiDialog-paper {
    max-width: 665.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreen__2GK7R .MuiDialog-paper {
    max-width: 798.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreen__2GK7R .MuiDialog-paper {
    max-width: 948.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreen__2GK7R .MuiDialog-paper {
    max-width: 1065px;
  }
}

.share-screen_linkShare__3dmHO {
  background-color: #fff;
  display: block !important;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .share-screen_linkShare__3dmHO,
  .share-screen_shareScreenActions__2ntT6,
  .share-screen_shareScreenHeading__W6Pg4 {
    border-radius: 5.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_linkShare__3dmHO,
  .share-screen_shareScreenActions__2ntT6,
  .share-screen_shareScreenHeading__W6Pg4 {
    border-radius: 7.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_linkShare__3dmHO,
  .share-screen_shareScreenActions__2ntT6,
  .share-screen_shareScreenHeading__W6Pg4 {
    border-radius: 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_linkShare__3dmHO,
  .share-screen_shareScreenActions__2ntT6,
  .share-screen_shareScreenHeading__W6Pg4 {
    border-radius: 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_linkShare__3dmHO,
  .share-screen_shareScreenActions__2ntT6,
  .share-screen_shareScreenHeading__W6Pg4 {
    border-radius: 12px;
  }
}

.share-screen_shareScreenHeading__W6Pg4 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenHeading__W6Pg4 {
    padding: 24px 24px 0 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenHeading__W6Pg4 {
    padding: 31px 31px 0 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenHeading__W6Pg4 {
    padding: 32.25px 32.25px 0 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenHeading__W6Pg4 {
    padding: 38.296875px 38.296875px 0 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenHeading__W6Pg4 {
    padding: 43px 43px 0 43px !important;
  }
}

.share-screen_shareScreenHeadingContent__1zI04 {
  align-items: center;
  color: #000;
  display: flex;
  font-weight: 400 !important;
  line-height: 1.2;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenHeadingContent__1zI04 {
    font-size: 16.875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenHeadingContent__1zI04 {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenHeadingContent__1zI04 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenHeadingContent__1zI04 {
    font-size: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenHeadingContent__1zI04 {
    font-size: 36px;
  }
}

.share-screen_addPersonIconContainer__35x24 {
  border-radius: 50%;
  color: #fff;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: auto;
  position: relative;
}

.share-screen_addPersonImageContainer__3a6yn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.share-screen_addPersonImageContainer__3a6yn:not(.share-screen_hasBG__3-OiO) {
  border: 2px solid gray;
}

.share-screen_addPersonImageContainer__3a6yn.share-screen_hasBG__3-OiO {
  background-color: #5aa5ef;
}

label.share-screen_addPersonImageContainer__3a6yn {
  cursor: pointer;
}

.share-screen_imagePreview__1mt4C {
  border: none;
  border-radius: 50%;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.share-screen_imageDeleteButton__1tQHz {
  background-color: #ff0000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 50%;
  left: -10%;
  position: absolute;
  top: -10%;
  transition-duration: 150ms;
  width: 50%;
}

@media all and (hover: hover) {
  .share-screen_imageDeleteButton__1tQHz:hover {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media all and (pointer: coarse) {
  .share-screen_imageDeleteButton__1tQHz:active, .share-screen_imageDeleteButton__1tQHz:target {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

.share-screen_imageDeleteButton__1tQHz::after {
  background-color: #fff;
  content: ' ';
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
}

@media only screen and (max-width: 1199px) {
  .share-screen_addPersonIcon__3ii_O {
    height: 27px;
    width: 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_addPersonIcon__3ii_O {
    height: 28.5px;
    width: 28.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_addPersonIcon__3ii_O {
    height: 33.84375px;
    width: 33.84375px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_addPersonIcon__3ii_O {
    height: 38px;
    width: 38px;
  }
}

.share-screen_shareScreenContent__1WjeS {
  position: relative;
}

.share-screen_shareScreenContent__1WjeS::before {
  background-color: #fff;
  content: ' ';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenContent__1WjeS {
    padding: 12px 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenContent__1WjeS {
    padding: 15.5px 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenContent__1WjeS {
    padding: 16.125px 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenContent__1WjeS {
    padding: 19.14844px 38.29688px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenContent__1WjeS {
    padding: 21.5px 43px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .share-screen_addPersonIconContainer__35x24,
  .share-screen_personButtonDP__3TG03 {
    height: 44px;
    margin-right: 11px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_addPersonIconContainer__35x24,
  .share-screen_personButtonDP__3TG03 {
    height: 45.75px;
    margin-right: 11.4375px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_addPersonIconContainer__35x24,
  .share-screen_personButtonDP__3TG03 {
    height: 54.328125px;
    margin-right: 13.58203px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_addPersonIconContainer__35x24,
  .share-screen_personButtonDP__3TG03 {
    height: 60px;
    margin-right: 15px;
    width: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .share-screen_titleInput__13z-r {
    height: 44px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_titleInput__13z-r {
    height: 45.75px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_titleInput__13z-r {
    height: 54.328125px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_titleInput__13z-r {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .share-screen_shareScreenEmailInput__2C_gp {
    /* height: 54px; */
    margin-top: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenEmailInput__2C_gp {
    /* height: 58.5px; */
    margin-top: 29.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenEmailInput__2C_gp {
    /* height: 69.46875px; */
    margin-top: 34.734375px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenEmailInput__2C_gp {
    /* height: 78px; */
    margin-top: 39px;
  }
}

.share-screen_titleInput__13z-r,
.share-screen_shareScreenEmailInput__2C_gp {
  -webkit-appearance: none;
          appearance: none;
  background-color: #f8f9fa;
  border: 2px solid transparent;
  border-left: none;
  border-radius: 4px;
  border-right: none;
  color: #000;
  font-size: 16px;
  outline: none;
  /* position: relative; */
  width: 100%;
  /* margin-bottom: 76px; */
}

.share-screen_titleInput__13z-r::-webkit-input-placeholder,
.share-screen_shareScreenEmailInput__2C_gp::-webkit-input-placeholder {
  color: #8e8e8e;
}

.share-screen_titleInput__13z-r:-ms-input-placeholder,
.share-screen_shareScreenEmailInput__2C_gp:-ms-input-placeholder {
  color: #8e8e8e;
}

.share-screen_titleInput__13z-r::-webkit-input-placeholder, .share-screen_shareScreenEmailInput__2C_gp::-webkit-input-placeholder {
  color: #8e8e8e;
}

.share-screen_titleInput__13z-r:-ms-input-placeholder, .share-screen_shareScreenEmailInput__2C_gp:-ms-input-placeholder {
  color: #8e8e8e;
}

.share-screen_titleInput__13z-r::placeholder,
.share-screen_shareScreenEmailInput__2C_gp::placeholder {
  color: #8e8e8e;
}

.share-screen_titleInput__13z-r:focus,
.share-screen_shareScreenEmailInput__2C_gp:focus {
  border-bottom-color: #5aa5ef;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 1199px) {
  .share-screen_titleInput__13z-r,
  .share-screen_shareScreenEmailInput__2C_gp {
    font-size: 17px;
    /* padding: 0 22px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_titleInput__13z-r,
  .share-screen_shareScreenEmailInput__2C_gp {
    font-size: 18px;
    /* padding: 0 24px; */
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_titleInput__13z-r,
  .share-screen_shareScreenEmailInput__2C_gp {
    font-size: 21.375px;
    /* padding: 0 28.5px; */
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_titleInput__13z-r,
  .share-screen_shareScreenEmailInput__2C_gp {
    font-size: 24px;
    /* padding: 0 32px; */
  }
}

.share-screen_personButton__3kT-h {
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: start;
  /* margin-left: -10px; */
  /* margin-bottom: 4px; */
  text-align: left;
  /* width: 100%; */
}

.share-screen_personButton__3kT-h.share-screen_selected__DZnhf {
  border: 2px solid #007aff;
}

@media only screen and (max-width: 1199px) {
  .share-screen_personButton__3kT-h {
    border-radius: 6.25px;
    /* margin-bottom: 6px; */
    /* padding: 12px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_personButton__3kT-h {
    border-radius: 7.5px;
    /* margin-bottom: 6.75px; */
    /* padding: 13.5px; */
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_personButton__3kT-h {
    border-radius: 8.90625px;
    /* margin-bottom: 8.01562px; */
    /* padding: 16.03125px; */
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_personButton__3kT-h {
    border-radius: 10px;
    /* margin-bottom: 9px; */
    /* padding: 18px; */
  }
}

@media all and (hover: hover) {
  .share-screen_personButton__3kT-h:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {
  .share-screen_personButton__3kT-h:active, .share-screen_personButton__3kT-h:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.share-screen_personButtonDP__3TG03 {
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
}

.share-screen_personDPImage__2DE8k,
.share-screen_personDPText__3MLO8 {
  border-radius: 50%;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.share-screen_personDPText__3MLO8 {
  align-items: center;
  background: var(--color-black);
  /* background-color: #14f47b; */
  color: #fff;
  display: flex;
  font-size: 24px;
  justify-content: center;
  line-height: 1;
}

.share-screen_personButtonTextContent__3OsfW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.share-screen_personButtonName__1Z7Tb,
.share-screen_personButtonEmail__2Qjcz {
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.share-screen_personButtonName__1Z7Tb {
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_personButtonName__1Z7Tb {
    font-size: 24px;
  }
}

.share-screen_notes__3bf77,
.share-screen_selectionHeading__-5_7v {
  color: #8e8e8e;
}

@media only screen and (max-width: 1199px) {
  .share-screen_selectionHeading__-5_7v {
    margin-bottom: 6px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_selectionHeading__-5_7v {
    margin-bottom: 6.75px;
    margin-top: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_selectionHeading__-5_7v {
    margin-bottom: 8.01562px;
    margin-top: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_selectionHeading__-5_7v {
    margin-bottom: 9px;
    margin-top: 18px;
  }
}

.share-screen_notes__3bf77 {
  line-height: 3.5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_notes__3bf77,
  .share-screen_personButtonEmail__2Qjcz,
  .share-screen_selectionHeading__-5_7v {
    font-size: 22px;
  }
}

.share-screen_personButtonEmail__2Qjcz {
  color: #63676c;
  text-transform: lowercase;
}

.share-screen_shareScreenActions__2ntT6 {
  display: flex;
}

.share-screen_shareScreenActions__2ntT6:nth-of-type(1) {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenActions__2ntT6 {
    justify-content: center !important;
    padding: 12px 24px !important;
  }
}

@media only screen and (min-width: 900px) {
  .share-screen_shareScreenActions__2ntT6 {
    justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenActions__2ntT6 {
    padding: 15.5px 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenActions__2ntT6 {
    padding: 16.125px 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenActions__2ntT6 {
    padding: 19.14844px 38.29688px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenActions__2ntT6 {
    padding: 21.5px 43px !important;
  }
}

.share-screen_linkShare__3dmHO {
  background-color: #fff;
}

@media only screen and (max-width: 899px) {
  .share-screen_linkShare__3dmHO {
    font-size: 14px !important;
    margin-top: 13.71429px !important;
    padding: 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_linkShare__3dmHO {
    font-size: 15px !important;
    margin-top: 17.71429px !important;
    padding: 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_linkShare__3dmHO {
    font-size: 16.5px !important;
    margin-top: 18.42857px !important;
    padding: 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_linkShare__3dmHO {
    font-size: 19.59375px !important;
    margin-top: 21.88393px !important;
    padding: 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_linkShare__3dmHO {
    font-size: 22px !important;
    margin-top: 24.57143px !important;
    padding: 43px !important;
  }
}

.share-screen_shareScreenDoneButton__3ATo- {
  font-size: 16px;
  height: 44px !important;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenDoneButton__3ATo- {
    font-size: 15px !important;
    height: 34px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenDoneButton__3ATo- {
    font-size: 15px !important;
    height: 37.5px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenDoneButton__3ATo- {
    font-size: 15px !important;
    height: 45px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenDoneButton__3ATo- {
    font-size: 17.8125px !important;
    height: 53.4375px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenDoneButton__3ATo- {
    font-size: 20px !important;
    height: 60px !important;
  }
}

@media only screen and (max-width: 899px) {
  .share-screen_linkIcon__1SAZb {
    height: 20.625px;
    margin-right: 10.3125px;
    width: 20.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_linkIcon__1SAZb {
    height: 27.499999999999996px;
    margin-right: 13.75px;
    width: 27.499999999999996px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_linkIcon__1SAZb {
    height: 33px;
    margin-right: 16.5px;
    width: 33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_linkIcon__1SAZb {
    height: 39.1875px;
    margin-right: 19.59375px;
    width: 39.1875px;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_linkIcon__1SAZb {
    height: 44px;
    margin-right: 22px;
    width: 44px;
  }
}

@media only screen and (max-width: 899px) {
  .share-screen_shareScreenLinkText__1Km2Q {
    margin: 11.25px 0 6.42857px 0 !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .share-screen_shareScreenLinkText__1Km2Q {
    margin: 11.25px 0 6.42857px 0 !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .share-screen_shareScreenLinkText__1Km2Q {
    margin: 13.5px 0 7.71429px 0 !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .share-screen_shareScreenLinkText__1Km2Q {
    margin: 16.03125px 0 9.16071px 0 !important;
  }
}

@media only screen and (min-width: 1710px) {
  .share-screen_shareScreenLinkText__1Km2Q {
    margin: 18px 0 10.28571px 0 !important;
  }
}

.share-screen_shareScreenLinkButton__1DdHA {
  color: #5aa5ef;
  cursor: pointer;
  text-align: right;
}

.share-screen_titleInput__13z-r {
  background-color: transparent !important;
  border-bottom-color: #ccc;
  border-radius: 0 !important;
  padding: 0 12px !important;
}

.share-screen_subTitle__8QKeJ {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

input.input {
  border: none;
  height: 100%;
  background-color: transparent;
  font-size: inherit;
  width: 100%;
}
span.emailTags {
  margin-right: 0.5rem;
  border-radius: 999999999999999999999999px;
  border: 3px solid;
  /* display: flex; */
  padding: 0.2rem 0.5rem;
}
span.emailTags > .MuiSvgIcon-root {
  font-size: inherit;
  margin: 0 0 -2px 0;
  /* color: red; */
  cursor: pointer;
}

.popperComponent {
  width: 100px
}


.percent {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* box-shadow: inset 0 0 50px #000; */
  /* background-color: #222; */
  z-index: 1000;
  /* color: white; */
  margin: 0 auto;

}
body.modal-open::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}
.percent .number {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.percent .number h2 {
  color: #777;
  font-weight: 700;
  font-size: 40px;
  transition: 0.5s;
}

.percent .number h2 span {
  font-size: 24px;
  color: #777;
  transition: 0.5s;
}

.text {
  position: relative;
  margin-top: 20px;
  color: #777;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s;
}

.svg-circle {
  position: relative;
  width: 150px;
  height: 150px;
  z-index: 10000;
}

.svg-circle circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #191919;
  stroke-width: 10;
  stroke-linecap: round;
  -webkit-transform: translate(5px, 5px);
          transform: translate(5px, 5px);
}

.svg-circle circle:nth-of-type(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.card-box:nth-child(1) .svg-circle circle:nth-of-type(2) {
  stroke-dashoffset: calc(440 - ((440 * 80) / 100));
  stroke: #57abf8;
}

.upload-modal_publishedContainer__TZDDU,
.upload-modal_publishedContent__3-LmT {
  align-items: center;
  display: flex;
  justify-content: center;
}
.upload-modal_shimming__ZxFD2 {
  border-radius: 5px;
  background-color: #d6d6d6;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
/* .progressTracker{
  border: none !important;
} */
.upload-modal_addToExisting__uquVe {
text-align: center;
}
.upload-modal_req__32d37 {
  display: flex;
  align-items: baseline;
  grid-gap: 5px;
  gap: 5px;
}
.upload-modal_Cancelxx__1C-3c{
  width: 20px;
  margin-top: 5px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}
.upload-modal_customDiv__3ENrt {
  width: 100%;
  background: white;
  position: absolute;
  height: 100%;
  font-size: 16px;
  padding: 0 5px;
  color: black;
  font-family: "POPPINS";
}
.upload-modal_fleo__2Vixg {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: start;
  margin-top: 2px;
  justify-content: start;
}
.upload-modal_cancelPlz__3qsnx {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  min-width: 70px;
  font-family: 'Poppins';
}
.upload-modal_noCancel__2E0E7 {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Poppins';
}
.upload-modal_closeMeBro__2kvAf {
  margin-top: 0 !important;
  cursor: pointer;
  position: absolute;
  background-color: transparent !important;
  color: gray;
  top: 0;
  height: 100%;
  right: -2px;
  display: flex;
  align-items: start;
}
.upload-modal_loaderAfterClose__B2eWJ {
}
.upload-modal_textx__2dM64 {
  margin: 0 auto;
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1000000000;
}
.upload-modal_marginX__2QaTV {
  z-index: 1000000000;
  margin: 0 auto;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.upload-modal_absol___YTAn {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.6;
  z-index: 100000;
  top: 50%;
  left: 50%;
}
.upload-modal_optionalInput__3R5ns {
  font-size: 13px;
  color: gray;
  font-weight: 400;
}

.upload-modal_videoPublishedIcon__FPu53 {
  height: 20vh !important;
  width: 20vh !important;
}

.upload-modal_videoPublishedText__EW-6k {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  text-align: center;
}
.upload-modal_customloader__1lYa_ {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #766df4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-animation: upload-modal_s3__1vRPA 1s infinite linear;
          animation: upload-modal_s3__1vRPA 1s infinite linear;
}
@-webkit-keyframes upload-modal_s3__1vRPA {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes upload-modal_s3__1vRPA {
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@media only screen and (max-width: 767px) {
  .upload-modal_videoPublishedText__EW-6k {
    color: #fff !important;
    font-size: 15px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 14px;
    margin-left: 45px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_videoPublishedText__EW-6k {
    font-size: 17px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 14px;
    margin-left: 34px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_videoPublishedText__EW-6k {
    font-size: 17px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 14px;
    margin-left: 47px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_videoPublishedText__EW-6k {
    font-size: 17px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 15px;
    margin-left: 42px;
    margin-top: 7px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_videoPublishedText__EW-6k {
    font-size: 19.59375px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 17px;
    margin-left: 47px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_videoPublishedText__EW-6k {
    font-size: 22px;
  }
  .upload-modal_loadingAlbum__19Gth {
    font-size: 18px;
    margin-left: 57px;
    margin-top: 10px;
  }
}

.upload-modal_selectBoxContainer__1bSOO {
  display: flex;
  flex-direction: column;
  position: relative;
}

.upload-modal_selectBoxContainer__1bSOO.upload-modal_disabled__2DKp6 {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

@media only screen and (min-width: 768px) {
  .upload-modal_selectBoxContainer__1bSOO.upload-modal_playlist__3Xafi {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
  }
  .upload-modal_selectBoxContainer__1bSOO.upload-modal_playlist__3Xafi .upload-modal_selectBox__14QpR {
    margin-top: 0 !important;
  }
}

.upload-modal_selectBox__14QpR {
  align-items: center;
  border: 2px solid #d9d9da;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBox__14QpR {
    border-radius: 4px;
    padding: 16px;
  }
}

.upload-modal_selectBoxText__3WHMl {
  max-width: 100%;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 20px - (20px / 2));
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 23.90625px - (23.90625px / 2));
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 31.875px - (31.875px / 2));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 38.25px - (38.25px / 2));
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 45.421875px - (45.421875px / 2));
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxText__3WHMl {
    width: calc(100% - 51px - (51px / 2));
  }
}

.upload-modal_selectBoxLabel__1rzGH {
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxLabel__1rzGH {
    font-size: 22px;
  }
}

.upload-modal_selectBoxDescription__2lEYf {
  font-weight: 300;
  margin-top: 0.5em;
  display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxDescription__2lEYf {
    font-size: 16px;
  }
}

.upload-modal_selectBoxDropdownIcon__4mmRs {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    border: 1px solid #000000;
    font-size: 1em;
    height: 20px;
    width: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    border: 1px solid #707070;
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    height: 38.5px;
    width: 38.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    height: 45.5px;
    width: 45.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxDropdownIcon__4mmRs {
    height: 51px;
    width: 51px;
  }
}

.upload-modal_selectBoxCreateHeader__1cb-8 {
  display: flex;
  justify-content: space-between;
}

.upload-modal_selectBoxCreateInput__2Wtsh {
  border: 1px solid #000;
  font-size: 1em;
  margin: 1em 0;
  padding: 0.5em;
  width: 100%;
}

.upload-modal_selectBoxCreateTitle__2f-Aj {
  display: block;
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.upload-modal_selectBackgroundText__3c_Wu {
  display: block;
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  margin-top: 7px;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 14px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 12px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 10px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 12px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 14.25px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 12.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 14.25px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 12.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxCreateTitle__2f-Aj,
  .upload-modal_selectBoxCreateInput__2Wtsh,
  .upload-modal_selectBoxCreateBackButton__3kdRi,
  .upload-modal_selectBoxCreateAddButton__1ZihF {
    font-size: 16px;
  }
  .upload-modal_selectBackgroundText__3c_Wu {
    font-size: 14px;
  }
}

.upload-modal_selectBoxCreateBackButton__3kdRi,
.upload-modal_selectBoxCreateAddButton__1ZihF {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
}

.upload-modal_selectBoxCreateAddButton__1ZihF {
  border: 2px solid #5aa5ef;
  border-radius: 0.5em;
  color: #5aa5ef;
  padding: 0.25em 0.75em;
  margin-top: 15px;
}

.upload-modal_selectBoxCreateAddButton__1ZihF:disabled {
  opacity: 0.75;
}

.upload-modal_selectBoxCreateFooter__2lEX8 {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 16px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 18.75px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 30px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 35.625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenuContent__8befe {
    padding: 40px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenu__18R3J {
    border-radius: 4.6875px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenu__18R3J {
    border-radius: 6.25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenu__18R3J {
    border-radius: 7.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenu__18R3J {
    border-radius: 8.90625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBox__14QpR,
  .upload-modal_selectBoxMenu__18R3J {
    border-radius: 10px !important;
  }
}

.upload-modal_selectBoxOptionsTitle__2m604 {
  font-weight: 600 !important;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 16px !important;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 18.75px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 30px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 35.625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxOptionsTitle__2m604 {
    margin-bottom: 40px !important;
  }
}

.upload-modal_selectBoxMenuContent__8befe {
  background-color: #fff;
}

.upload-modal_selectBoxMenu__18R3J {
  margin-top: -24px;
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxMenu__18R3J {
    bottom: 0 !important;
    height: 90% !important;
    left: 0 !important;
    max-width: unset !important;
    position: fixed !important;
    top: unset !important;
    width: 100% !important;
  }
}

.upload-modal_selectBoxOptionsList__migFX {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(12px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(16px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(20px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-top: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(12px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(16px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(20px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-bottom: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-left: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_selectBoxOptionsList__migFX {
      padding-right: 0 !important;
    }
  }
}

.upload-modal_selectBoxOption__1Kb-E {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 2.5 !important;
}

.upload-modal_selectBoxOption__1Kb-E.upload-modal_addButton__1LwLc {
  color: #5aa5ef !important;
}

@media all and (hover: hover) {
  .upload-modal_selectBoxOption__1Kb-E:not(.upload-modal_addButton__1LwLc):hover {
    color: #5aa5ef !important;
  }
}

@media all and (pointer: coarse) {
  .upload-modal_selectBoxOption__1Kb-E:not(.upload-modal_addButton__1LwLc):active,
  .upload-modal_selectBoxOption__1Kb-E:not(.upload-modal_addButton__1LwLc):target {
    color: #5aa5ef !important;
  }
}

@media only screen and (max-width: 767px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 17.8125px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_selectBoxOption__1Kb-E {
    font-size: 20px !important;
  }
}

.upload-modal_categoriesContainer__1oES9 {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .upload-modal_categoriesContainer__1oES9 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_categoriesContainer__1oES9 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.upload-modal_visibilityContainer__1C37- {
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(180, 145, 145, 0.416);
  margin: 2em 0;
}

.upload-modal_visibilityContainer__1C37- .MuiTypography-root {
  font-weight: 300;
}

.upload-modal_visibilityTitle__1Fx1Q {
  margin: 0;
  opacity: 0.9;
}

.upload-modal_visibilityDescription__2RpjV {
  font-weight: 300;
  margin: 0 0 1.75em 0;
}

.upload-modal_visibilityRadio__2GVnd,
.upload-modal_visibilityRadio__2GVnd * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (max-width: 767px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 20.03125px;
    margin-top: 12px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 14px;
  }
  .upload-modal_visibilityContainer__1C37- {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 16.5px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 12px;
  }
  .upload-modal_visibilityContainer__1C37- {
    padding: 25.875px;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 16.5px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 12px;
  }
  .upload-modal_visibilityContainer__1C37- {
    padding: 34.5px;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 16.5px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 12px;
  }
  .upload-modal_visibilityContainer__1C37- {
    padding: 41.4px;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 19.59375px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 14.25px;
  }
  .upload-modal_visibilityContainer__1C37- {
    padding: 49.1625px;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_visibilityTitle__1Fx1Q {
    font-size: 22px;
  }
  .upload-modal_visibilityDescription__2RpjV {
    font-size: 16px;
  }
  .upload-modal_visibilityContainer__1C37- {
    padding: 55.2px;
  }
  .upload-modal_visibilityContainer__1C37- .MuiTypography-root {
    font-size: 16px !important;
  }
}

.upload-modal_navButton__2xGJk {
  flex-shrink: 0;
  grid-gap: 5px;
  gap: 5px;
  text-transform: capitalize !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .upload-modal_navButton__2xGJk {
    color: #fff !important;
    /* width: calc(50% - 8px); */
    /* width: 130px; */
  }
  .upload-modal_navButton__2xGJk:last-of-type {
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_navButton__2xGJk {
    color: #000 !important;
  }
}

@media all and (hover: hover) {
  .upload-modal_navButton__2xGJk:disabled:hover {
    background-color: transparent !important;
  }
}

@media all and (pointer: coarse) {
  .upload-modal_navButton__2xGJk:disabled:active,
  .upload-modal_navButton__2xGJk:disabled:target {
    background-color: transparent !important;
  }
}

@media all and (hover: hover) {
  .upload-modal_navButton__2xGJk:not(:disabled):hover {
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .upload-modal_navButton__2xGJk:not(:disabled):active,
  .upload-modal_navButton__2xGJk:not(:disabled):target {
    color: #fff !important;
  }
}

.upload-modal_videoPreview__370DD {
  background-color: #b6b6b64b;
  display: block;
  flex-grow: 0;
  height: 100%;
}

.upload-modal_videoPreviewThumbnail__3idrp {
  background-color: #fff;
  border: none;
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.upload-modal_videoPreviewDetails__2kZ7D td:first-of-type {
  color: #8d8d8d;
  padding: 0;
}

.upload-modal_videoPreviewDetails__2kZ7D td:nth-child(2) {
  padding: 0 0 0 10px;
}

.upload-modal_videoPreviewDetailsTitle__KYI_f {
  font-weight: 500;
  margin: 0;
}

.upload-modal_videoPreviewLink__1FYKR {
  align-items: center;
  background-color: #fff;
  color: #b9b8b8;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  padding: 1em;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.upload-modal_videoPreviewLink__1FYKR::selection {
  background-color: #000;
  color: #fff;
}

.upload-modal_contentBoxTitleContainer__yOcby {
  position: relative;
}

.upload-modal_contentBoxTitle__2SVrD {
  color: #585858;
  line-height: 1;
  margin: 0;
}

.upload-modal_contentBoxDescription__Fb9Iz {
  color: #918e8e;
  font-weight: 300;
  line-height: 1.25;
  margin: 6px 0 0 0;
}

.upload-modal_generateThumbnailProgress__2VxMZ {
  color: #313030;
  font-weight: 400;
  line-height: 1.25;
  margin: 2px 0 0 0;
  font-size: 14px !important;
}

@media only screen and (max-width: 767px) {
  .upload-modal_contentBoxDescription__Fb9Iz {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_summaryBoxSection__2XIa0 {
    width: 100%;
  }
  .upload-modal_summaryBoxSection__2XIa0 .upload-modal_contentBoxSectionWrapper__WJhTE {
    display: flex !important;
    width: 100%;
  }
  .upload-modal_summaryBoxSection__2XIa0 .upload-modal_visibilityContainer__1C37- {
    flex-grow: 1;
    height: 100%;
  }
  .upload-modal_summaryBoxSection__2XIa0 .upload-modal_visibilityContainer__1C37-,
  .upload-modal_summaryBoxSection__2XIa0 .upload-modal_videoPreview__370DD {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .upload-modal_summaryBoxSection__2XIa0 {
    width: 100%;
  }
  .upload-modal_summaryBoxSection__2XIa0 .upload-modal_contentBoxTitleContainer__yOcby {
    display: none;
  }
}

.upload-modal_thumbnailBoxSection__1TJRY {
  background-color: transparent !important;
}

.upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxTitle__2SVrD {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxTitle__2SVrD {
    color: #fff;
    margin-bottom: 24px;
  }
}

.upload-modal_videoThumbnailsContainer__2Vs87 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.upload-modal_thumbnailUploadButton__1Cy46 {
  background-color: #b7b7b7;
  cursor: pointer;
}

.upload-modal_videoThumbnail__1Ymx4 {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.upload-modal_videoThumbnail__1Ymx4::before {
  content: " ";
  display: block;
  height: 100%;
  padding-top: 80%;
  width: 100%;
}

.upload-modal_videoThumbnailAddIcon__3mkNW {
  height: 40%;
  object-fit: contain;
  object-position: center;
  position: absolute;
  width: 40%;
}

.upload-modal_videoThumbnailImage__3aXCH {
  border: none;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_contentBoxContainer__1b0Ah {
      padding-bottom: 40px;
    }
  }
}

.upload-modal_contentBoxSectionWrapper__WJhTE {
  position: relative;
}

.upload-modal_contentBoxSection__NtBRn {
  background-color: #fff;
  display: block;
}

.upload-modal_contentBoxSection__NtBRn:nth-child(2) {
  margin-top: 5%;
}

.upload-modal_contentBox__3qIY_ {
  flex-grow: 1;
}

.upload-modal_contentBox__3qIY_.upload-modal_flex__13LCS {
  display: flex;
  position: relative;
}

.upload-modal_inputField__2mVoF {
  align-items: center;
  border: 2px solid #dadadb;
  cursor: text !important;
  display: flex;
  width: 100%;
}

.upload-modal_inputField__2mVoF.upload-modal_alignLeft__1qiQV,
.upload-modal_inputField__2mVoF.upload-modal_alignLeft__1qiQV * {
  text-align: left !important;
}

.upload-modal_inputField__2mVoF.upload-modal_inputFieldFocus__1KNca {
  border-color: #5aa5ef !important;
}

.upload-modal_inputField__2mVoF.upload-modal_inputFieldFocus__1KNca .upload-modal_inputFieldLabel__1k0LT {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.upload-modal_inputFieldText__3qCGw {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.upload-modal_inputFieldLabel__1k0LT {
  color: #000 !important;
  font-weight: 600 !important;
  opacity: 0.9 !important;
  order: 1;
  transition-duration: 200ms;
}

@media only screen and (max-width: 767px) {
  .upload-modal_inputFieldLabel__1k0LT {
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
}

@media only screen and (min-width: 768px) {
  .upload-modal_inputFieldLabel__1k0LT {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
}

.upload-modal_input__1FqOi {
  border: none;
  color: #918e8e;
  font-family: "Montserrat" !important;
  line-height: 1;
  order: 2;
  outline: none;
  padding: 0;
  resize: none;
  text-overflow: ellipsis;
  width: 100%;
}

.upload-modal_progressTracker__389jq {
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: flex;
  position: relative;
  z-index: 10;
}

.upload-modal_currentStepName__bHx40 {
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .upload-modal_currentStepName__bHx40 {
    display: none;
  }
}

.upload-modal_progressTrackerStepContainer__3_jFl {
  align-items: center;
  display: flex;
}

.upload-modal_progressTrackerStepContainer__3_jFl:first-of-type {
  margin-left: 0 !important;
}

.upload-modal_progressTrackerStepBar__bSnJ1 {
  border: 1px solid #02071a;
  height: 0px;
  opacity: 0.1;
}

.upload-modal_progressTrackerStepCount__3lfPB {
  align-items: center;
  background-color: #ededed;
  border-radius: 50%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  justify-content: center;
}

.upload-modal_currentProgressStep__3r-f- .upload-modal_progressTrackerStepCount__3lfPB {
  background-color: #01040f;
  color: #fff;
}

.upload-modal_progressTrackerStepName__2kpk0 {
  color: #b9b8b8;
}

.upload-modal_currentProgressStep__3r-f- .upload-modal_progressTrackerStepName__2kpk0 {
  color: #5aa5ef;
}

.upload-modal_backButton__ODFBl,
.upload-modal_closeButton__1_8Nx {
  flex-grow: 0;
  flex-shrink: 0;
}

.upload-modal_backButton__ODFBl {
  color: #fff;
  cursor: pointer;
  margin-left: -12px;
}

@media only screen and (min-width: 768px) {
  .upload-modal_backButton__ODFBl {
    display: none;
  }
}

.upload-modal_closeButton__1_8Nx {
  cursor: pointer;
  margin-left: 2%;
}

@media only screen and (max-width: 767px) {
  .upload-modal_closeButton__1_8Nx {
    display: none;
  }
}

.upload-modal_header__2UF_u {
  flex-grow: 0;
  flex-shrink: 0;
}

.upload-modal_headerContent__KnKk0 {
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
}

@media only screen and (max-width: 767px) {
  .upload-modal_headerContent__KnKk0 {
    justify-content: flex-start;
  }
}

.upload-modal_headerText__3hejE {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-modal_headerTextSelectable__WU3Co {
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: text;
}

.upload-modal_content__1QNND {
  display: flex;
  flex-direction: column;
}

.upload-modal_body__3lmog {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .upload-modal_body__3lmog {
    background-color: #1a1d2a;
  }
  .upload-modal_addToExisting__uquVe {
    font-size: 12px;
    color: #fff;
  }
  .upload-modal_addAsNewButton__1il_S {
    padding: 0px !important;
    height: 20px !important;
    width: 53px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    padding: 0px !important;
    height: 20px !important;
    width: 53px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
  .upload-modal_cancelButton__3oM2A {
    border-color: #fff !important;
    width: 100px !important;
  }
  .upload-modal_nextButton__3gpRd {
    color: #fff !important;
    width: 100px !important;
  }
  .upload-modal_iconContainer__2XIW0 {
    /* width: 50px !important;
    height: 38px !important; */
    display: none;
  }
  .upload-modal_processingText__1xyLb {
    font-size: 10px !important;
    color: #fff;
    margin-top: 3px;
  }
  /* .addMorePhoto {
    width: 90% !important;
    height: 150px !important;
  }
  .selectedImage {
    width: 90% !important;
    height: 150px !important;
  } */
}

@media only screen and (min-width: 768px) {
  .upload-modal_body__3lmog {
    background-color: #ffffff;
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_body__3lmog {
    height: calc(100vh - (var(--header-height))) !important;
  }
}

@media only screen and (min-width: 900px) {
  .upload-modal_body__3lmog {
    height: calc(100vh - (var(--header-height))) !important;
    /* upload-modal-height-rs */
    /* height: calc(100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)) !important; */
  }
}

@media only screen and (max-height: 568px) {
  .upload-modal_body__3lmog {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.upload-modal_addFilesSection__1f_r0 {
  cursor: pointer;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .upload-modal_addFilesSection__1f_r0 {
      padding-right: 40px;
    }
  }
}

.upload-modal_addFilesSection__1f_r0,
.upload-modal_addFilesSectionImageContainer__1To-R {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.upload-modal_addFilesSectionImageContainer__1To-R {
  background-color: #faf8f8;
  border-radius: 50%;
}

.upload-modal_addFilesSectionImage__3SlmH {
  height: 100%;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.upload-modal_addFilesSectionText__13NIP {
  color: #9f9e9e;
  text-align: center;
}

.upload-modal_addFilesSectionTextTitle__27AtO {
  font-weight: 500;
}

.upload-modal_addFilesSectionTextDescription__5dPcS {
  font-weight: 300;
}

.upload-modal_addFilesSectionButton__5UEPl {
  align-items: center;
  background-color: transparent;
  border-color: #dbdbdb;
  border-style: solid;
  color: #7b7c7e;
  display: flex;
  font-weight: 600;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}

.upload-modal_addFilesSectionButton__5UEPl:hover {
  background-color: #9f9e9e !important;
}

.upload-modal_previewFilesSectionButton__2ZP_Z {
  align-items: center;
  background-color: transparent;
  border-color: #dbdbdb;
  border-style: solid;
  color: #7b7c7e;
  display: flex;
  font-weight: 600;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}

.upload-modal_footer__3azQM {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 24px !important;
}

@media only screen and (max-width: 767px) {
  .upload-modal_footer__3azQM {
    display: none;
  }
}

.upload-modal_footerContent__Tng1Q {
  border-top-color: rgba(112, 112, 112, 0.15);
  border-top-style: solid;
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}

.upload-modal_footerContentInTwoEnd__3CALa {
  border-top-color: rgba(112, 112, 112, 0.15);
  border-top-style: solid;
  display: -ms-flexbox;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .upload-modal_footerContent__Tng1Q {
    margin-left: auto;
    max-width: 360px;
  }
  /* .footerContentInTwoEnd {
    margin-left: auto;
    max-width: 360px;
  } */
}

.upload-modal_uploadTerms__32UZJ {
  color: #5c5757;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .upload-modal_videoPreview__370DD {
    margin-left: 16px;
    margin-top: 16px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxDescription__Fb9Iz {
    margin-bottom: 24.75px;
  }
  .upload-modal_videoThumbnailsContainer__2Vs87 {
    grid-gap: 11px;
    gap: 11px;
  }
  .upload-modal_videoThumbnail__1Ymx4 {
    border-radius: 5px;
  }
  .upload-modal_contentBoxSection__NtBRn {
    border-radius: 5px;
    padding: 16px;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 51px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY {
    padding: 0;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxTitleContainer__yOcby {
    padding: 0;
  }
  .upload-modal_categoriesContainer__1oES9 {
    grid-gap: 16px;
    gap: 16px;
  }
  .upload-modal_selectBox__14QpR,
  .upload-modal_inputField__2mVoF {
    border-radius: 5px !important;
  }
  .upload-modal_inputField__2mVoF {
    margin-top: 16px;
  }
  .upload-modal_inputFieldLabel__1k0LT {
    font-size: 17px;
    height: 17px;
    margin-bottom: 17px;
    text-align: center;
  }
  .upload-modal_input__1FqOi {
    font-size: 15px;
    text-align: center;
  }
  .upload-modal_contentBoxTitleContainer__yOcby {
    padding: 0 0 12px 0;
  }
  .upload-modal_contentBoxTitle__2SVrD {
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 18px;
  }
  .upload-modal_videoPreview__370DD {
    display: none;
  }
  .upload-modal_inputField__2mVoF {
    border-radius: 10px;
  }
  .upload-modal_inputField__2mVoF {
    padding: 20px;
  }
  .upload-modal_progressTracker__389jq {
    display: -ms-grid !important;
    display: grid !important;
    grid-gap: 14.65px;
    gap: 14.65px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 12px 0 24px 0;
  }
  .upload-modal_progressTrackerStepContainer__3_jFl {
    background-color: #707070;
    border-radius: 5px;
    height: 5px;
    opacity: 0.33;
  }
  .upload-modal_currentProgressStep__3r-f- {
    background-color: #fff !important;
    opacity: 1 !important;
  }
  .upload-modal_progressTrackerStepBar__bSnJ1 {
    display: none;
  }
  .upload-modal_progressTrackerStepCount__3lfPB {
    display: none;
  }
  .upload-modal_progressTrackerStepName__2kpk0 {
    display: none;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    height: 70px;
    margin-bottom: 12.714px;
    width: 70px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    border-radius: 4.6875px;
    border-width: 3px;
    display: none;
  }
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    border-radius: 4.6875px;
    border-width: 3px;
    margin: auto;
  }
  .upload-modal_addFilesSectionImage__3SlmH {
    width: 36.5625px;
  }
  .upload-modal_addFilesSectionText__13NIP {
    font-size: 9px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    margin: auto;
  }
  .upload-modal_addFilesSection__1f_r0 {
    background-color: #fff;
    border-radius: 5px;
    padding: 51px 0;
    width: 100%;
  }
  .upload-modal_footerContent__Tng1Q {
    border-top-width: 0px;
  }
  .upload-modal_footerContentInTwoEnd__3CALa {
    border-top-width: 0px;
  }
  .upload-modal_progressTracker__389jq {
    border-bottom-width: 0px;
  }
  .upload-modal_headerContent__KnKk0 {
    border-bottom-width: 0px;
    padding-bottom: 28px;
  }
  .upload-modal_closeButton__1_8Nx {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
  .upload-modal_headerText__3hejE {
    color: #fff !important;
    font-size: 21px !important;
    font-weight: 500 !important;
  }
  .upload-modal_body__3lmog {
    height: 100%;
  }
  .upload-modal_header__2UF_u {
    padding: 24px 24px 0 24px !important;
  }
  .upload-modal_content__1QNND {
    padding: 0 24px !important;
  }
  .upload-modal_footer__3azQM {
    padding-top: 24px;
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-bottom: unset !important;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-left: unset !important;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .upload-modal_footer__3azQM {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .upload-modal_footer__3azQM {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .upload-modal_footer__3azQM {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .upload-modal_footer__3azQM {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .upload-modal_footer__3azQM {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .upload-modal_footer__3azQM {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .upload-modal_footer__3azQM {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .upload-modal_footer__3azQM {
        padding-right: unset !important;
      }
    }
  }
  .upload-modal_uploadTerms__32UZJ {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_videoPreview__370DD {
    margin-left: 10px;
    margin-top: 28.75px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxDescription__Fb9Iz {
    margin-bottom: 27.21094px;
  }
  .upload-modal_videoThumbnailsContainer__2Vs87 {
    grid-gap: 12.09375px;
    gap: 12.09375px;
  }
  .upload-modal_videoThumbnail__1Ymx4 {
    border-radius: 5px;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 28.359375px;
  }
  .upload-modal_categoriesContainer__1oES9 {
    grid-gap: 10px;
    gap: 10px;
  }
  .upload-modal_selectBox__14QpR,
  .upload-modal_inputField__2mVoF {
    margin-top: 10px;
  }
  .upload-modal_inputFieldLabel__1k0LT {
    font-size: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_input__1FqOi {
    font-size: 14px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D {
    padding: 22.5px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp,
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_inputField__2mVoF {
    border-radius: 6.25px;
  }
  .upload-modal_videoPreviewThumbnail__3idrp {
    height: 160px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp {
    width: 286.21931260229px;
  }
  .upload-modal_inputField__2mVoF {
    padding: 22.5px 26.25px;
  }
  .upload-modal_progressTracker__389jq {
    padding-bottom: 23px;
    padding-top: 23px;
  }
  .upload-modal_progressTrackerStepContainer__3_jFl {
    margin-left: 8.125px;
  }
  .upload-modal_progressTrackerStepBar__bSnJ1 {
    margin-left: 8.125px;
    width: 102.5px;
  }
  .upload-modal_progressTrackerStepCount__3lfPB {
    font-size: 12.5px;
    height: 40px;
    width: 40px;
  }
  .upload-modal_progressTrackerStepName__2kpk0 {
    font-size: 10px;
    margin-left: 8.125px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    height: 118.125px;
    margin-bottom: 11.55262px;
    width: 118.125px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    border-radius: 6.25px;
    border-width: 1.875px;
    height: 39.375px;
    margin-top: 9.68625px;
    padding: 0 31.875px;
    width: 121.875px;
  }
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    border-radius: 6.25px;
    border-width: 1.875px;
    height: 39.375px;
    margin-top: 9.68625px;
    padding: 0 31.875px;
    width: 121.875px;
  }
  .upload-modal_addFilesSectionImage__3SlmH {
    height: 48.75px;
    width: 48.75px;
  }
  .upload-modal_navButton__2xGJk,
  .upload-modal_contentBoxDescription__Fb9Iz,
  .upload-modal_addFilesSectionButton__5UEPl,
  .upload-modal_addFilesSectionText__13NIP .upload-modal_previewFilesSectionButton__2ZP_Z {
    font-size: 12px !important;
  }
  .upload-modal_addFilesSection__1f_r0 {
    margin: auto;
  }
  .upload-modal_footerContent__Tng1Q {
    border-top-width: 1.78125px;
    padding-top: 19.21875px;
  }
  .upload-modal_footerContentInTwoEnd__3CALa {
    border-top-width: 1.78125px;
    padding-top: 19.21875px;
  }
  .upload-modal_progressTracker__389jq {
    border-bottom-width: 1.78125px;
  }
  .upload-modal_headerContent__KnKk0 {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .upload-modal_closeButton__1_8Nx {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
  .upload-modal_contentBoxTitle__2SVrD,
  .upload-modal_headerText__3hejE {
    font-size: 18.75px;
  }
  .upload-modal_body__3lmog {
    border-radius: 5px;
    max-width: 1033.5px;
  }
  .upload-modal_header__2UF_u {
    padding: 19.21875px 30.703124999999996px 0 30.703124999999996px !important;
  }
  .upload-modal_content__1QNND {
    padding: 23px 30.70312px !important;
  }
  .upload-modal_footer__3azQM {
    padding: 0 30.703124999999996px 19.21875px 30.703124999999996px !important;
  }
  .upload-modal_uploadTerms__32UZJ {
    font-size: 10.5px;
    max-width: 573.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_videoPreviewThumbnail__3idrp {
    height: 190.9375px !important;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp {
    width: 341.56249999999994px !important;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 37.8125px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .upload-modal_videoPreview__370DD {
    margin-left: 12px;
    margin-top: 34.5px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxDescription__Fb9Iz {
    margin-bottom: 32.65313px;
  }
  .upload-modal_videoThumbnailsContainer__2Vs87 {
    grid-gap: 14.512500000000001px;
    gap: 14.512500000000001px;
  }
  .upload-modal_videoThumbnail__1Ymx4 {
    border-radius: 5px;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 15px;
  }
  .upload-modal_categoriesContainer__1oES9 {
    grid-gap: 12px;
    gap: 12px;
  }
  .upload-modal_selectBox__14QpR,
  .upload-modal_inputField__2mVoF {
    margin-top: 12px;
  }
  .upload-modal_inputFieldLabel__1k0LT {
    font-size: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_input__1FqOi {
    font-size: 14px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D {
    padding: 27px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp,
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_inputField__2mVoF {
    border-radius: 7.5px;
  }
  .upload-modal_videoPreviewThumbnail__3idrp {
    height: 229.125px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp {
    width: 409.87499999999994px;
  }
  .upload-modal_inputField__2mVoF {
    padding: 10px 21.499999999999996px;
  }
  .upload-modal_progressTracker__389jq {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .upload-modal_progressTrackerStepContainer__3_jFl {
    margin-left: 9.75px;
  }
  .upload-modal_progressTrackerStepBar__bSnJ1 {
    margin-left: 9.75px;
    width: 123px;
  }
  .upload-modal_progressTrackerStepCount__3lfPB {
    font-size: 15px;
    height: 48px;
    width: 48px;
  }
  .upload-modal_progressTrackerStepName__2kpk0 {
    font-size: 12px;
    margin-left: 9.75px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    height: 141.75px;
    margin-bottom: 13.86315px;
    width: 141.75px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    border-radius: 7.5px;
    border-width: 2.25px;
    height: 47.25px;
    margin-top: 11.6235px;
    padding: 0 38.25px;
    width: 146.25px;
  }
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    border-radius: 7.5px;
    border-width: 2.25px;
    height: 47.25px;
    margin-top: 11.6235px;
    padding: 0 38.25px;
    width: 146.25px;
  }
  .upload-modal_addFilesSectionImage__3SlmH {
    height: 58.5px;
    width: 58.5px;
  }
  .upload-modal_navButton__2xGJk,
  .upload-modal_contentBoxDescription__Fb9Iz,
  .upload-modal_addFilesSectionButton__5UEPl,
  .upload-modal_addFilesSectionText__13NIP,
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    font-size: 12px !important;
  }
  .upload-modal_addFilesSection__1f_r0 {
    margin: auto;
  }
  .upload-modal_footerContent__Tng1Q {
    border-top-width: 1.78125px;
    padding-top: 10px;
  }
  .upload-modal_footerContentInTwoEnd__3CALa {
    border-top-width: 1.78125px;
    padding-top: 30.75px;
  }
  .upload-modal_progressTracker__389jq {
    border: none;
    border-bottom-width: 1.78125px;
  }
  .upload-modal_headerContent__KnKk0 {
    border-bottom-width: 1.78125px;
    padding-bottom:11px;
  }
  .upload-modal_closeButton__1_8Nx {
    height: 18px;
    margin-right: -27px;
    margin-top: -36px;
    width: 18px;
  }
  .upload-modal_contentBoxTitle__2SVrD,
  .upload-modal_headerText__3hejE {
    font-size: 22.5px;
  }
  .upload-modal_body__3lmog {
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
  .upload-modal_header__2UF_u {
    padding: 20px 49.12499999999999px 0 49.12499999999999px !important;
  }
  .upload-modal_content__1QNND {
    padding: 0px 49.125px !important;
  }
  .upload-modal_footer__3azQM {
    padding: 0 49.12499999999999px 30.75px 49.12499999999999px !important;
  }
  .upload-modal_uploadTerms__32UZJ {
    font-size: 10.5px;
    max-width: 688.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_videoPreview__370DD {
    margin-left: 14.25px;
    margin-top: 40.96875px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxDescription__Fb9Iz {
    margin-bottom: 38.77559px;
  }
  .upload-modal_videoThumbnailsContainer__2Vs87 {
    grid-gap: 17.23359375px;
    gap: 17.23359375px;
  }
  .upload-modal_videoThumbnail__1Ymx4 {
    border-radius: 5px;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 15px;
  }
  .upload-modal_categoriesContainer__1oES9 {
    grid-gap: 14.25px;
    gap: 14.25px;
  }
  .upload-modal_selectBox__14QpR,
  .upload-modal_inputField__2mVoF {
    margin-top: 14.25px;
  }
  .upload-modal_inputFieldLabel__1k0LT {
    font-size: 17.8125px;
    height: 17.8125px;
    margin-bottom: 17.8125px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_input__1FqOi {
    font-size: 15px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D {
    padding: 32.0625px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp,
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_inputField__2mVoF {
    border-radius: 8.90625px;
  }
  .upload-modal_videoPreviewThumbnail__3idrp {
    height: 272.0859375px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp {
    width: 486.72656249999994px;
  }
  .upload-modal_inputField__2mVoF {
    padding: 10px 20.40625px;
  }
  .upload-modal_progressTracker__389jq {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .upload-modal_progressTrackerStepContainer__3_jFl {
    margin-left: 11.578125px;
  }
  .upload-modal_progressTrackerStepBar__bSnJ1 {
    margin-left: 11.578125px;
    width: 146.0625px;
  }
  .upload-modal_progressTrackerStepCount__3lfPB {
    font-size: 17.8125px;
    height: 57px;
    width: 57px;
  }
  .upload-modal_progressTrackerStepName__2kpk0 {
    font-size: 14.25px;
    margin-left: 11.578125px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    height: 168.328125px;
    margin-bottom: 16.46249px;
    width: 168.328125px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 56.109375px;
    margin-top: 13.80291px;
    padding: 0 45.421875px;
    width: 173.671875px;
  }
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 56.109375px;
    margin-top: 13.80291px;
    padding: 0 45.421875px;
    width: 173.671875px;
  }
  .upload-modal_addFilesSectionImage__3SlmH {
    height: 69.46875px;
    width: 69.46875px;
  }
  .upload-modal_navButton__2xGJk,
  .upload-modal_contentBoxDescription__Fb9Iz,
  .upload-modal_addFilesSectionButton__5UEPl,
  .upload-modal_addFilesSectionText__13NIP,
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    font-size: 14.25px !important;
  }
  .upload-modal_addFilesSection__1f_r0 {
    margin: auto;
  }
  .upload-modal_footerContent__Tng1Q {
    border-top-width: 1.78125px;
    padding-top: 10px;
  }
  .upload-modal_footerContentInTwoEnd__3CALa {
    border-top-width: 1.78125px;
    padding-top: 36.515625px;
  }
  .upload-modal_progressTracker__389jq {
    border-bottom-width: 1.78125px;
  }
  .upload-modal_headerContent__KnKk0 {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .upload-modal_closeButton__1_8Nx {
    height: 21.375px;
    margin-right: -32.0625px;
    margin-top: -42.75px;
    width: 21.375px;
  }
  .upload-modal_contentBoxTitle__2SVrD,
  .upload-modal_headerText__3hejE {
    font-size: 26.71875px;
  }
  .upload-modal_body__3lmog {
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
  .upload-modal_header__2UF_u {
    padding: 20px 58.33593749999999px 0 58.33593749999999px !important;
  }
  .upload-modal_content__1QNND {
    padding: 0px 58.33594px !important;
  }
  .upload-modal_footer__3azQM {
    padding: 0 58.33593749999999px 10px 58.33593749999999px !important;
  }
  .upload-modal_uploadTerms__32UZJ {
    font-size: 12.46875px;
    max-width: 817.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_videoPreview__370DD {
    margin-left: 16px;
    margin-top: 46px;
  }
  .upload-modal_thumbnailBoxSection__1TJRY .upload-modal_contentBoxDescription__Fb9Iz {
    margin-bottom: 43.5375px;
  }
  .upload-modal_videoThumbnailsContainer__2Vs87 {
    grid-gap: 19.35px;
    gap: 19.35px;
  }
  .upload-modal_videoThumbnail__1Ymx4 {
    border-radius: 5px;
  }
  .upload-modal_contentBoxSection__NtBRn:nth-child(2) {
    margin-top: 30.5px;
  }
  .upload-modal_categoriesContainer__1oES9 {
    grid-gap: 16px;
    gap: 16px;
  }
  .upload-modal_selectBox__14QpR,
  .upload-modal_inputField__2mVoF {
    margin-top: 16px;
  }
  .upload-modal_inputFieldLabel__1k0LT {
    font-size: 20px;
    height: 20px;
    margin-bottom: 20px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_input__1FqOi {
    font-size: 16px;
  }
  .upload-modal_videoPreviewDetails__2kZ7D {
    padding: 36px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp,
  .upload-modal_videoPreviewDetails__2kZ7D,
  .upload-modal_inputField__2mVoF {
    border-radius: 10px;
  }
  .upload-modal_videoPreviewThumbnail__3idrp {
    height: 305.5px;
  }
  .upload-modal_videoPreview__370DD,
  .upload-modal_videoPreviewThumbnail__3idrp {
    width: 546.5px;
  }
  .upload-modal_inputField__2mVoF {
    padding: 20px 20px;
  }
  .upload-modal_progressTracker__389jq {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .upload-modal_progressTrackerStepContainer__3_jFl {
    margin-left: 13px;
  }
  .upload-modal_progressTrackerStepBar__bSnJ1 {
    margin-left: 13px;
    width: 164px;
  }
  .upload-modal_progressTrackerStepCount__3lfPB {
    font-size: 20px;
    height: 64px;
    width: 64px;
  }
  .upload-modal_progressTrackerStepName__2kpk0 {
    font-size: 16px;
    margin-left: 13px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    height: 189px;
    margin-bottom: 18.4842px;
    width: 189px;
  }
  .upload-modal_addFilesSectionButton__5UEPl {
    border-radius: 10px;
    border-width: 3px;
    height: 63px;
    margin-top: 15.498px;
    padding: 0 51px;
    width: 195px;
  }
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    border-radius: 10px;
    border-width: 3px;
    height: 63px;
    margin-top: 15.498px;
    padding: 0 51px;
    width: 195px;
  }
  .upload-modal_addFilesSectionImage__3SlmH {
    height: 78px;
    width: 78px;
  }
  .upload-modal_navButton__2xGJk,
  .upload-modal_contentBoxDescription__Fb9Iz,
  .upload-modal_addFilesSectionButton__5UEPl,
  .upload-modal_addFilesSectionText__13NIP,
  .upload-modal_previewFilesSectionButton__2ZP_Z {
    font-size: 16px !important;
  }
  .upload-modal_addFilesSection__1f_r0 {
    margin: auto;
  }
  .upload-modal_footerContent__Tng1Q {
    border-top-width: 2px;
    padding-top: 16px;
  }
  .upload-modal_footerContentInTwoEnd__3CALa {
    border-top-width: 2px;
    padding-top: 41px;
  }
  .upload-modal_progressTracker__389jq {
    border-bottom-width: 2px;
  }
  .upload-modal_headerContent__KnKk0 {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
  .upload-modal_closeButton__1_8Nx {
    height: 24px;
    margin-right: -36px;
    margin-top: -48px;
    width: 24px;
  }
  .upload-modal_contentBoxTitle__2SVrD,
  .upload-modal_headerText__3hejE {
    font-size: 30px;
  }
  .upload-modal_body__3lmog {
    border-radius: 10px;
    max-width: 1378px;
  }
  .upload-modal_header__2UF_u {
    padding: 25px 65.5px 0 65.5px !important;
  }
  .upload-modal_content__1QNND {
    padding: 0px 65.5px !important;
  }
  .upload-modal_footer__3azQM {
    padding: 0 65.5px 14px 65.5px !important;
  }
  .upload-modal_uploadTerms__32UZJ {
    font-size: 14px;
    max-width: 918px;
  }
}

.upload-modal_iconColumn__3OUcP {
  display: flex;
  justify-content: center !important;
  flex-direction: initial;
}

.upload-modal_separator__p0CqP {
  display: flex;
  justify-content: center;
}

.upload-modal_resizeSeparator__hs0ql {
  border-left: 2px solid rgba(112, 112, 112, 0.15) !important;
  height: 800px;
  margin-top: -92px;
}

.upload-modal_actionContainer__-257t {
  margin-top: 10px;
}

.upload-modal_alignCenterContainer__2X5m3 {
  margin: 0 auto;
  margin-bottom: 100%;
}

.upload-modal_responsive__1Ts9O {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

.upload-modal_gallery__2drZf img {
  width: 100%;
  height: auto;
}

.upload-modal_gallery__2drZf {
  border: 1px solid #ccc;
}

.upload-modal_imageColumn__2Ivdk {
  /* float: left;
  width: 33.33%; */
  padding: 10px;
  position: relative;
}

.upload-modal_closeIcon__X-wf2 {
  position: absolute;
  right: 35px;
  color: #fff;
  background-color: #000;
  margin-top: 4px;
  cursor: pointer;
}

.upload-modal_alignRow__3XOqF {
  overflow: auto;
}

.upload-modal_addMorePhoto__3aMqt {
  border-color: #1583dd;
  border-width: 2px;
  border-style: dashed;
  text-align: center;
  height: 200px;
  width: 100%;
}

.upload-modal_addIconContainer__paSjb {
  position: relative;
  top: calc(50% - 10px);
}

.upload-modal_addIcon__2I8-U {
  color: #1583dd;
  width: 100%;
  max-width: 400px;
}

.upload-modal_addPhotoText__2sVhE {
  color: #1583dd;
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 17px;
}

.upload-modal_cancelButton__3oM2A {
  border-color: #000000;
  color: #ff0000;
  margin: 14px;
}

.upload-modal_nextButton__3gpRd {
  border-color: #5aa5ef;
  color: #000;
  margin: 14px;
}

.upload-modal_albumList__1jLQQ {
  max-height: 128px;
  overflow-y: auto;
  width: 101%;
  display: grid;
  overflow-x: hidden;
}

.upload-modal_alignRow__3XOqF::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.upload-modal_alignRow__3XOqF::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.upload-modal_alignRow__3XOqF::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.upload-modal_alignRow__3XOqF::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.upload-modal_albumTitle__1u1IS {
  font-weight: 600;
}

.upload-modal_albumList__1jLQQ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.upload-modal_albumList__1jLQQ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.upload-modal_albumList__1jLQQ::-webkit-scrollbar-thumb {
  background: #1172d2;
  border-radius: 25px;
}

/* Handle on hover */
.upload-modal_albumList__1jLQQ::-webkit-scrollbar-thumb:hover {
  background: #1172d2;
}

.upload-modal_addToExisting__uquVe {
  font-weight: 600;
}

.upload-modal_albumTitleText__2GY6h {
  font-weight: 600;
  font-family: "Poppins";
  position: relative;
  bottom: 2px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18ch;
  vertical-align: middle;
}

.upload-modal_iconTextContainer__3-9IT {
  display: inline-block;
}

.upload-modal_checkBox__2W0jT {
  vertical-align: middle;
}

.upload-modal_albumListContainer__GNvii {
  border-radius: 4vh;
  border: 3px solid;
  border-color: #eaeaea;
  width: 150%;
  margin-left: -30%;
  margin-top: 13px;
  padding: 9px;
}
.upload-modal_albumListContainer__GNvii {
  border-radius: 4vh;
  border: 3px solid;
  border-color: #eaeaea;
width: 100%;
    margin-left: unset;
  margin-top: 13px;
  padding: 9px;
}


.upload-modal_buttonGroup__3gK06 {
  /* position: absolute; */
  /* width: 100%; */
  /* left: 50%;
  transform: translate(-50%, 0px); */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  /* grid-gap: 10px; */
  grid-gap: 10px;
  gap: 10px;
}


.upload-modal_addAsNewButton__1il_S {
  font-weight: 600;
  background: #eaeaea;
  border-radius: 15px;
  padding: 9px;
  cursor: pointer;
  height: 42px;
  width: 130px;
  text-align: center;
}

.upload-modal_chooseAlbum__lZ6FS {
  background: #1172d2;
  font-weight: 600;
  border-radius: 15px;
  padding: 9px;
  color: #fff;
  cursor: pointer;
  height: 42px;
  width: 130px;
  text-align: center;
}

.upload-modal_noAlbumFound__2Pfiv {
  color: #000;
  text-align: center;
  font-weight: 500;
}

.upload-modal_iconColor__uLFcI {
  color: #707070 !important;
  margin-right: 6px;
}

.upload-modal_stopIcon__3GDTO {
  margin-left: -6px;
  color: #1172d2 !important;
}

.upload-modal_photoUploadColumn__lPErn {
  position: relative;
}

.upload-modal_videoUploadColumn__34Q8w {
  position: relative;
}

@media only screen and (max-width: 319px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: 102px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    right: 19px;
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 2vh;
    width: 60%;
    margin-left: -25%;
    padding: 11px;
  }
  .upload-modal_addToExisting__uquVe {
    font-size: 8px !important;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    right: 27px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_alignRow__3XOqF {
    grid-template-columns: 1fr !important;
  }
  .upload-modal_navButton__2xGJk {
    width: 80px;
  }
  .upload-modal_photoUploadColumn__lPErn .upload-modal_addFilesSectionImageContainer__1To-R {
    left: -15px;
    position: relative;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: 14px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: 102px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    right: 19px;
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 2vh;
    width: 65%;
    margin-left: -25%;
    padding: 11px;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    right: 32px;
  }
  .upload-modal_addToExisting__uquVe {
    font-size: 9px !important;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_alignRow__3XOqF {
    grid-template-columns: 1fr 1fr !important;
  }
  .upload-modal_navButton__2xGJk {
    width: 100px;
  }
  .upload-modal_photoUploadColumn__lPErn .upload-modal_addFilesSectionImageContainer__1To-R {
    left: -15px;
    position: relative;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: 14px !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 6px;
    padding: 5px;
    height: 37px;
    width: 115px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: 48px !important;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    /* right: 19px; */
    left: 19px;
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 2vh;
    width: 81%;
    margin-left: -14%;
    padding: 11px;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    right: 5px;
  }
  .upload-modal_albumTitleText__2GY6h {
    font-size: 15px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 6px;
    padding: 5px;
    height: 37px;
    width: 115px;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: -21px !important;
  }
  .upload-modal_alignRow__3XOqF {
    grid-template-columns: 1fr 1fr !important;
  }
  .upload-modal_navButton__2xGJk {
    width: 130px;
  }
  .upload-modal_photoUploadColumn__lPErn .upload-modal_addFilesSectionImageContainer__1To-R {
    left: 27px;
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: 35px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    left: 33px;
    /* right: 19px; */
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 2vh;
    width: 103%;
    margin-left: -25%;
    padding: 11px;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    right: 26px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: -33px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    /* left: 93px; */
    left: 36px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    /* right: 19px; */
    left: 37px;
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 3vh;
    width: 107%;
    margin-left: -26%;
    padding: 13px;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    right: 15px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: 0;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    /* right: 42px; */
    /* left: 37px; */
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 3vh;
    width: 100%;
    margin-left: unset;
    padding: 14px;
  }
  .upload-modal_addToExisting__uquVe {
    position: relative;
    /* right: 15px; */
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_withoutAlbum__-77aU {
    left: -47px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    /* left: 66px; */
    right: 9px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    /* right: 42px; */
    /* left: 49px; */
  }
  .upload-modal_albumListContainer__GNvii {
    border-radius: 3vh;
    width: 100%;
    margin-left: unset;
    padding: 14px;
  }
  .upload-modal_albumList__1jLQQ {
    max-height: 138px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_withoutAlbum__-77aU {
    right: 60px;
  }
}

@media only screen and (min-width: 1710px) {
  .upload-modal_addAsNewButton__1il_S {
    border-radius: 10px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .upload-modal_photoUploadColumn__lPErn {
    left: -3px;
  }
  .upload-modal_albumListContainer__GNvii {
    /* margin-left: -40%; */
    padding: 16px;
  }
  .upload-modal_albumList__1jLQQ {
    padding: 12px;
    max-height: 150px;
  }
  .upload-modal_videoUploadColumn__34Q8w {
    /* right: 56px; */
    /* left: 41px; */
  }
  .upload-modal_withoutAlbum__-77aU {
    left: -41px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    border-radius: 10px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
}

@media only screen and (min-height: 740px) and (max-height: 840px) {
  .upload-modal_albumList__1jLQQ {
    max-height: 105px;
  }
  .upload-modal_addAsNewButton__1il_S {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .upload-modal_albumListContainer__GNvii {
    margin-top: 1px;
    padding: 11px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    margin-bottom: 1.86315px;
  }
}

@media only screen and (max-height: 739px) {
  .upload-modal_albumList__1jLQQ {
    max-height: 100px !important;
  }
  .upload-modal_addAsNewButton__1il_S {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .upload-modal_chooseAlbum__lZ6FS {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .upload-modal_albumListContainer__GNvii {
    margin-top: 1px;
    padding: 11px;
  }
  .upload-modal_addFilesSectionImageContainer__1To-R {
    margin-bottom: 1.86315px;
  }
}

.upload-modal_progressbar__h1BoW {
  width: 50%;
  padding: 10px;
  /* position: relative; */
  /* right: 10%; */
  /* display: inline-block; */
}

.upload-modal_progressbarIn1__1bLPy {
  right: 42%;
}

.upload-modal_progressbarIn2__1P-2Y {
  right: 34%;
}

.upload-modal_progressbarIn3__2DUox {
  right: 33%;
}

.upload-modal_backgroundImageList__1gikb {
  display: grid;
  grid-template-columns: 200px 200px;
  /* display: flex;
  flex-grow: unset;
  flex-wrap: wrap; */
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.upload-modal_backgroundImageList__1gikb::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.upload-modal_backgroundImageList__1gikb::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.upload-modal_backgroundImageList__1gikb::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.upload-modal_backgroundImageList__1gikb::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .backgroundImageList .backGroundImage {
  width: 50%;
} */
/* 
.backgroundImageList img {
  width: calc(100% - (30px * 2));
  margin: 30px;
} */

.upload-modal_buttonOnTwoEnd__3APUq {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.upload-modal_buttonOnEnd__1xeMY {
  display: flex;
  justify-content: end;
  grid-gap: 10px;
  gap: 10px;
}

.upload-modal_iconContainer__2XIW0 {
  width: 125px;
  height: 50px;
  border-radius: 12px;
  background-color: #b7b7b7;
}

.upload-modal_videoUploadIcon__2zlIu {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.upload-modal_leftContainer__3Dwfl {
  width: 80%;
  display: flex;
}

.upload-modal_processingText__1xyLb {
  font-weight: 600;
  font-size: 15px;
}

.upload-modal_dropDownAnimation__1xGra {
  -webkit-animation: upload-modal_growOut__IFehr 700ms ease-in-out forwards;
          animation: upload-modal_growOut__IFehr 700ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

@-webkit-keyframes upload-modal_growOut__IFehr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes upload-modal_growOut__IFehr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.upload-modal_alignRow__3XOqF {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.upload-modal_selectedImage__1rFeD {
  height: 200px;
  width: 100%;
}

.upload-modal_progressContainer__1e04s {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
/* upload percentage */
.upload-modal_uploadpercentage__25HK4 {
  position: absolute;
  background: #fff;
  border: 2px solid #dadadb;
  border-radius: 7.5px;
  /* top: 50%; */
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  place-items: center;
  /* width: 268px; */
  /* left: 50%; */
  /* min-height: 5rem; */
  /* transform: translate(-50%, 17%); */
}
/* @media (max-width:900px) {
  .uploadpercentage{
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 3rem;
    transform: translate(-50%, 17%);
    transform: translate(-41, -50px);
  }
} */
@media (max-width: 600px) {
  .upload-modal_uploadpercentage__25HK4 {
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 5rem;
    -webkit-transform: translate(-50%, 17%);
            transform: translate(-50%, 17%);
    -webkit-transform: translate(-41, -50px);
            transform: translate(-41, -50px);
  }
}
@media (max-width: 480px) {
  .upload-modal_uploadpercentage__25HK4 {
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 5rem;
    -webkit-transform: translate(-50%, 17%);
            transform: translate(-50%, 17%);
    -webkit-transform: translate(-41, -50px);
            transform: translate(-41, -50px);
  }
}
.upload-modal_generatingMsg__3CkCQ {
  padding-left: 20px;
  padding-right: 20px;
}

.text_pageTitle__2s85Y {
  color: #02071a;
}

.text_sectionTitle__2m2EC {
  color: #000;
}

.text_pageTitle__2s85Y,
.text_sectionTitle__2m2EC {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .text_pageTitle__2s85Y {
    font-size: 23px;
    line-height: 26.875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .text_pageTitle__2s85Y {
    font-size: 23px;
    line-height: 26.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .text_pageTitle__2s85Y {
    font-size: 26.25px;
    line-height: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .text_pageTitle__2s85Y {
    font-size: 31.171875000000004px;
    line-height: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .text_pageTitle__2s85Y {
    font-size: 35px;
    line-height: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .text_sectionTitle__2m2EC {
    font-size: 23px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .text_sectionTitle__2m2EC {
    font-size: 23px;
    margin-top: 3.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .text_sectionTitle__2m2EC {
    font-size: 24.750000000000004px;
    margin-top: 4.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .text_sectionTitle__2m2EC {
    font-size: 29.390625000000004px;
    margin-top: 5.34375px;
  }
}

@media only screen and (min-width: 1710px) {
  .text_sectionTitle__2m2EC {
    font-size: 33px;
    margin-top: 6px;
  }
}

.info-modal_title__2BKzf {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: 'Montserrat' !important;

}
.info-modal_content__1JhzA {
  text-align: center;
  color: #000;
  font-family: 'Montserrat' !important;
  font-weight: 500;
  padding: 0 10px 0;

}
.info-modal_actionContainer__1F7h9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-modal_cancelButton__1ealx{
  text-transform: none;
  font-family: 'Montserrat' !important;
  color: #FFFFFF;
  background-color:#5AA5EF;
  font-size: 16px !important;
  font-weight: 500;

}

.info-modal_actionButton__PzrjP {
  color: #FC1527;
  text-transform: none;
  font-family: 'Montserrat' !important;
  font-size: 15px !important;
  font-weight: 500;
  background-color: transparent;
}

.info-modal_continueButton__1zAcA{
  text-transform: none;
  color: #000000;
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  padding: 15px 0;
}
.info-modal_dialogContainer__3wRN7 {
  border-radius: 15px;
}

.info-modal_closeButton__1vKi5 {
  display: flex;
  justify-content: right;
  float: right;
  margin: 7px;
}
.body {
    background-color: white;
    margin: auto;
    width: 70%;
    padding: 4%;
    text-align: center;
}

.headerContent {
    justify-content: center;
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 40px;
}

.slick-next {
    right: -35px;
    margin-top: -30px;
}

.slick-prev {
    left: -35px;
    margin-top: -30px;
}

.slick-arrow {
    size: 10px;
}

.closeButton {
    margin-top: 15px;
    margin-right: 15px;
}

.headerText {
    font-weight: bold;
    font-size: large;
    margin-top: 0px;
}

.slider {
    margin-top: 10px;
}

.updateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(85, 178, 212);
    width: 100px;
    height: 100px;
    margin-left: 330px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.updateButton .Notification {
    font-size: 30px;
    color: rgb(9, 113, 232);
    height: 60px;
    width: 60px;
}

h3 {
    font-size: x-large;
    align-items: center;
    text-align: center;
}

form p {
    font-size: small;
    align-items: center;
    text-align: center;

    font-weight: 700;
}

button {
    color: white;
    background-color: rgb(52, 120, 230);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 40px;
    border: none;
    margin-top: 0px;
    /* margin-left: 280px; */
}

.promotionDescriptiontext {
    align-items: center;
    text-align: center;
    font-size: x-large;
    color: black;
    text-transform: none;
}

.promotionDescriptiontext span {
    color: rgb(61, 177, 231)
}
.progressBox_progressBoxWrap__18k2S {
  /* bottom: 0%;
  right: 0; */
  /* position: fixed;
  height: 200px;
  width: 300px;
  opacity: 1; */
  /* z-index: 1000; */
  outline: none;
  z-index: 1000;
  box-sizing: border-box;
  max-width: 384px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 40%);
  margin: 48px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 384px;
  max-height: 490px;
  overflow: hidden;
  -webkit-animation: progressBox_slide-up__HuEas 450ms cubic-bezier(0, 0, 0.2, 1);
  animation: progressBox_slide-up__HuEas 450ms cubic-bezier(0, 0, 0.2, 1);
}

.progressBox_header__1hqdu {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #000;
  color: #fff;
  justify-content: space-between;
}

.progressBox_headerText__1udHX {
  font-size: 14px;
}

.progressBox_icon__1St4H {
  /* margin-right: 20px; */
  cursor: pointer;
}

.progressBox_fileName__17tN9 {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 14px;
}

.progressBox_fileItem__2yPdw {
  padding: 5px 8px 0;
  /* display: grid;
  grid-template-columns: 30px 1fr 120px 25px; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.progressBox_uploadProgress__2eBU9 {
  color: #606060;
  font-size: 14px;
}

.progressBox_editIcon__1xyyW {
  cursor: pointer;
}

.progressBox_statusIcon__1Ohvn {
  color: green;
}
.progressBox_errorMsg__cwJtQ {
  font-size: 12px;
  color: red;
}

.progressBox_errorIcon__3Ah8M {
  color: red;
}
.modal_navButton__1uOIe {
  flex-shrink: 0;
  text-transform: capitalize !important;
}

@media all and (hover: hover) {
  .modal_navButton__1uOIe:disabled:hover {
    background-color: transparent !important;
    color: #000 !important;
  }
}

@media all and (pointer: coarse) {
  .modal_navButton__1uOIe:disabled:active,
  .modal_navButton__1uOIe:disabled:target {
    background-color: transparent !important;
    color: #000 !important;
  }
}

@media all and (hover: hover) {
  .modal_navButton__1uOIe:hover {
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .modal_navButton__1uOIe:active,
  .modal_navButton__1uOIe:target {
    color: #fff !important;
  }
}

@media only screen and (max-width: 767px) {
  .modal_navButton__1uOIe {
    color: #fff !important;
    width: calc(50% - 8px);
  }
  .modal_navButton__1uOIe:last-of-type {
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .modal_navButton__1uOIe {
    color: #000 !important;
  }
  .modal_navButton__1uOIe:last-of-type {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .modal_navButton__1uOIe {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .modal_navButton__1uOIe {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modal_navButton__1uOIe {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .modal_navButton__1uOIe {
    font-size: 16px !important;
  }
}

.modal_backButton__6vlki,
.modal_closeButton__2UPdH {
  flex-grow: 0;
  flex-shrink: 0;
}

.modal_backButton__6vlki {
  color: #000;
  cursor: pointer;
  margin-left: -12px;
}

@media only screen and (min-width: 768px) {
  .modal_backButton__6vlki {
    display: none;
  }
}

.modal_closeButton__2UPdH {
  cursor: pointer;
  margin-left: 2%;
}

@media only screen and (max-width: 767px) {
  .modal_closeButton__2UPdH {
    display: none;
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .modal_closeButton__2UPdH {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .modal_closeButton__2UPdH {
    height: 18px;
    margin-right: -27px;
    margin-top: -36px;
    width: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modal_closeButton__2UPdH {
    height: 21.375px;
    margin-right: -32.0625px;
    margin-top: -42.75px;
    width: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .modal_closeButton__2UPdH {
    height: 24px;
    margin-right: -36px;
    margin-top: -48px;
    width: 24px;
  }
}

.modal_header__3L-1m {
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 10;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-top: max(
        var(--site-padding),
        env(safe-area-inset-top)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-top: max(19.21875px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-top: max(30.75px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-top: max(36.51562px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-top: max(41px, env(safe-area-inset-top)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-top: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-top: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-top: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-top: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_header__3L-1m {
      padding-right: var(--site-padding) !important;
    }
  }
}

.modal_headerContent__6JxdT {
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .modal_headerContent__6JxdT {
    border-bottom-width: 0px;
    justify-content: flex-start;
    padding-bottom: var(--site-padding);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .modal_headerContent__6JxdT {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .modal_headerContent__6JxdT {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modal_headerContent__6JxdT {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .modal_headerContent__6JxdT {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
}

.modal_headerText__3s87E {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .modal_headerText__3s87E {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .modal_headerText__3s87E {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .modal_headerText__3s87E {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modal_headerText__3s87E {
    font-size: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .modal_headerText__3s87E {
    font-size: 30px;
  }
}

.modal_content__2NKx8 {
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      );
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      );
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      );
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-bottom: max(
        var(--site-padding),
        env(safe-area-inset-bottom)
      );
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-bottom: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_content__2NKx8 {
      padding-right: var(--site-padding) !important;
    }
  }
}

.modal_content__2NKx8 > * {
  flex-shrink: 0;
}

.modal_body__3MQ0W {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .modal_body__3MQ0W {
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 900px) {
  .modal_body__3MQ0W {
    height: calc(
      100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (max-width: 767px) {
  .modal_body__3MQ0W {
    height: 100vh !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .modal_body__3MQ0W {
    --site-padding: 30.703124999999996px;
    border-radius: 5px;
    height: calc(100vh - (var(--header-height))) !important;
    max-width: 1033.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .modal_body__3MQ0W {
    --site-padding: 49.12499999999999px;
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .modal_body__3MQ0W {
    --site-padding: 58.33593749999999px;
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .modal_body__3MQ0W {
    --site-padding: 65.5px;
    border-radius: 10px;
    max-width: 1378px;
  }
}

@media only screen and (max-height: 568px) {
  .modal_body__3MQ0W {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.modal_footer__3mI0R {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 24px !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-top: max(unset, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-top: max(19.21875px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-top: max(30.75px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-top: max(30.75px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-top: max(36.51562px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-top: max(41px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-top: unset !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-top: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-top: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-top: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-top: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-bottom: max(19.21875px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-bottom: max(30.75px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-bottom: max(30.75px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-bottom: max(36.51562px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-bottom: max(41px, env(safe-area-inset-bottom)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-bottom: 32px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-bottom: 19.21875px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-bottom: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-bottom: 30.75px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-bottom: 36.51562px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-bottom: 41px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-left: max(
        var(--site-padding),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-left: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-left: var(--site-padding) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .modal_footer__3mI0R {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .modal_footer__3mI0R {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .modal_footer__3mI0R {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .modal_footer__3mI0R {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .modal_footer__3mI0R {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .modal_footer__3mI0R {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .modal_footer__3mI0R {
      padding-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .modal_footer__3mI0R {
      padding-right: var(--site-padding) !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .modal_footer__3mI0R {
    display: none;
    padding-top: 24px;
  }
}

.modal_footerContent__m2ck8 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.modal_footerContentForcontent__3WXe0 {
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .modal_footerContent__m2ck8 {
    margin-left: auto;
    max-width: 360px;
  }
}

.modal_selectedCount__3UHMg {
  font-size: 15px;
  font-weight: 500;
}

.Drope_iamfixed__1PTNo{
    position: fixed;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
z-index: 1000000;
}
.Drope_cloud__1o863{
    object-fit: cover;
    width: 200px;
    height: 200px;
}
/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_newCategoryContainer__IYQER {
    font-size: 11.25px;
  }
}
.videos_customDropdown__2WCo7 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.videos_selectedOption__2PVOa {
  font-size: 11px;
    margin-left: 5px;
    font-weight: 400;
}
.videos_dropdownHeader__2GpJJ {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videos_dropdownArrow__Vnjnp {
  margin-left: 10px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.videos_dropdownArrow__Vnjnp.videos_open__kKR-C {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.videos_dropdownList__1zZLl {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  max-height: 350px;
  overflow-y: auto;
  list-style-type: none;
}

.videos_dropdownItem__3xyf3 {
  padding: 10px;
  transition: background-color 0.2s ease;
}

.videos_dropdownItem__3xyf3:hover {
  background-color: #e0e0e0;
}

.videos_dropdownItem__3xyf3.videos_selected__1EBgO {
  background-color: #d0d0d0;
  font-weight: bold;
}

.videos_linex__2d1kf {
  height: 0.5px;
  background: #e1e2eb;
  opacity: 0.1;
  margin: 0;
  width: 89%;
  position: fixed;
  margin-right: 60px;
  margin-top: -3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.videos_linexr__26DWV {
  height: 0.5px;
  background: #e1e2eb;
  opacity: 0.3;
  margin: 0;
  width: 89%;
  position: fixed;
  margin-right: 60px;
  margin-top: -3px;
}
.videos_sticlyMe__13NLI {
  position: -webkit-sticky;
  position: sticky;
  top: 20%;
  z-index: 1000;
}
.videos_actionButton__1LBhU {
  color: #000 !important;
}
.videos_search_input__3Zt0M {
  padding: 10px;
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 4px; /* Add rounded corners */
  font-size: 16px;
  width: 200px; /* Adjust the width as needed */
}
.videos_search_container__20gDz {
  display: flex;
  align-items: center;
}
.videos_search_button__2d35g {
  padding: 10px 11px;
  background-color: #5aa5ef;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-size: 17px;
  border-radius: 11px;
  cursor: pointer;
  margin-left: 10px;
}
.videos_search_button__2d35g > img {
  width: 20px;
}
.videos_search_input__3Zt0M::-webkit-input-placeholder {
  font-family: "Poppins";
  font-size: 17px;
}
.videos_search_input__3Zt0M:-ms-input-placeholder {
  font-family: "Poppins";
  font-size: 17px;
}
.videos_search_input__3Zt0M::placeholder {
  font-family: "Poppins";
  font-size: 17px;
}
.videos_flexBorad__3wAp1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.videos_droperSelection__1w3lk {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  height: auto;
  background-color: #5aa5ef;
  padding: 4px 6px;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.videos_droperMain__3IYBq {
  cursor: pointer;
  text-align: center;
  color: white;
  display: flex;
  font-weight: 600;
  height: 100%;
  font-family: "Poppins";
  background-color: #5aa5ef;
  border: none;
  border-radius: 8px;
  font-size: 17px;
}
.videos_droperMain__3IYBq > option {
  border: none;
  font-size: 17px;
  font-family: "Poppins";
  color: black;
  background-color: white;
  font-weight: 600;
}
.videos_fiterRemover__3n9Q1 {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.videos_dropdiv__3BBZC {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 60px;
  margin-bottom: 18px;
  padding-left: 10px;
}
.videos_cancelPlz__YOGlQ {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  font-family: "Poppins";
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
}
.videos_cancelPlz__YOGlQ:hover {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_newCategoryContainer__IYQER {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_newCategoryContainer__IYQER {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_newCategoryContainer__IYQER {
    font-size: 18px;
  }
}

.videos_newCategoryInput__1tNlq {
  border: 1px solid #000;
  display: block;
  font-size: inherit;
  margin: 0;
  padding: 6px 12px;
  width: 100%;
}

.videos_newCategoryBar__2MOUg {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.videos_newCategoryButton__2hDgY {
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.videos_newCategoryButtonCreate__1q1OR {
  background-color: #000;
  color: #fff;
  line-height: 1.5;
  margin-top: 1em;
  padding: 0 16px;
}

.videos_preLoadDataContainer__3qS94 {
  position: relative;
}

.videos_preLoadData__1JUu5 {
  position: relative;
}

.videos_preloadDataContent__2X2SA {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4em;
  position: relative;
  white-space: nowrap;
}

.videos_fixedPreferenceContainer__3RdpO {
  --useless: none;
}

.videos_cancelEditMenu__2EtY_ {
  color: #5aa5ef;
  font-weight: 500;
}

@media all and (hover: hover) {
  .videos_cancelEditMenu__2EtY_:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .videos_cancelEditMenu__2EtY_:active,
  .videos_cancelEditMenu__2EtY_:target {
    text-decoration: underline;
  }
}

.videos_page__3yQF4 {
  background-color: #ffffff;
}

.videos_preferences__18qUZ {
  align-items: center;
  background-color: #000;
  color: #fff;
  justify-content: space-between;
  padding: 0 !important;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.videos_theFlex__24kWy {
  display: flex;
  flex-direction: row;
}
.videos_preferences__18qUZ .videos_headerCell__1aXeX {
  border: none !important;
}

.videos_preferenceContainerFill__1fq46 {
  display: none;
  position: relative;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_preferencesContent__TtLsY,
  .videos_preferenceContainerFill__1fq46 {
    height: 51.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_preferencesContent__TtLsY,
  .videos_preferenceContainerFill__1fq46 {
    height: 61.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_preferencesContent__TtLsY,
  .videos_preferenceContainerFill__1fq46 {
    height: 73.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_preferencesContent__TtLsY,
  .videos_preferenceContainerFill__1fq46 {
    height: 82px;
  }
}

.videos_preferencesContent__TtLsY {
  width: 100%;
}

.videos_preferencesContent__TtLsY,
.videos_preferencesContentButtons__2ikt_ {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.videos_prefOptions__322rp {
  align-items: center;
  display: -ms-inline-flexbox;
  display: flex;
  grid-gap: 28px;
  gap: 28px;
  margin-left: 35px;
}
.videos_editOptions__3M9eb {
  align-items: center;
  display: -ms-inline-flexbox;
  display: flex;
  grid-gap: 28px;
  gap: 28px;
}
.videos_numVideosSelected__3QAXe {
  color: #fff;
}
.videos_numVideosSelected__3QAXe:hover {
  color: #449ce1;
}
.videos_prefDropDown__3g68g {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  position: relative;
}

.videos_prefDropDownText__oZtnE {
  color: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_numVideosSelected__3QAXe,
  .videos_prefDropDownText__oZtnE {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_numVideosSelected__3QAXe,
  .videos_prefDropDownText__oZtnE {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_numVideosSelected__3QAXe,
  .videos_prefDropDownText__oZtnE {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_numVideosSelected__3QAXe,
  .videos_prefDropDownText__oZtnE {
    font-size: 20px;
  }
}

.videos_prefDropDownIcon__3ZRLy {
  align-items: center;
  background-color: #ffffff33;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_prefDropDownIcon__3ZRLy {
    font-size: 12.5px;
    height: 19.375000000000004px;
    margin-left: 3.75px;
    width: 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_prefDropDownIcon__3ZRLy {
    font-size: 15px;
    height: 23.250000000000004px;
    margin-left: 4.5px;
    width: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_prefDropDownIcon__3ZRLy {
    font-size: 17.8125px;
    height: 27.609375000000004px;
    margin-left: 5.34375px;
    width: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_prefDropDownIcon__3ZRLy {
    font-size: 20px;
    height: 31px;
    margin-left: 6px;
    width: 31px;
  }
}

.videos_prefDropDownIcon__3ZRLy.videos_prefDropDownActive__1FfQ5 {
  background-color: #ffffffd1;
  color: #000;
}

.videos_prefDropdownScrim__1Qmur {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.videos_preferenceContainer__3VBIJ {
  border-bottom: 0 !important;
  position: relative;
  width: 100%;
  z-index: 97;
}

.videos_categoriesMenuOptionsContainer__ZHBAn {
  box-shadow: 0px 3px 6px #00000029;
  left: 0 !important;
  max-height: 200px;
  overflow-y: auto;
  -webkit-transform: none !important;
  transform: none !important;
  width: 80%;
}

.videos_categoriesMenuOption__C-PaM {
  cursor: pointer;
}

.videos_categoriesMenuOption__C-PaM.videos_categoriesMenuOptionAdd__eoSPS {
  background-color: transparent;
  border: none;
  color: #5aa5ef;
  display: block;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_categoriesMenuOption__C-PaM,
  .videos_categoriesMenuOption__C-PaM.videos_categoriesMenuOptionAdd__eoSPS {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_categoriesMenuOption__C-PaM,
  .videos_categoriesMenuOption__C-PaM.videos_categoriesMenuOptionAdd__eoSPS {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_categoriesMenuOption__C-PaM,
  .videos_categoriesMenuOption__C-PaM.videos_categoriesMenuOptionAdd__eoSPS {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_categoriesMenuOption__C-PaM,
  .videos_categoriesMenuOption__C-PaM.videos_categoriesMenuOptionAdd__eoSPS {
    font-size: 18px;
  }
}

.videos_categoriesMenuOptionsContainer__ZHBAn,
.videos_optionsContainer__3TSRd {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  left: 50%;
  line-height: 2.2;
  position: absolute;
  top: calc(100% + 4px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 120;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_categoriesMenuOptionsContainer__ZHBAn,
  .videos_optionsContainer__3TSRd {
    border-radius: 6.25px;
    font-size: 11.25px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_categoriesMenuOptionsContainer__ZHBAn,
  .videos_optionsContainer__3TSRd {
    border-radius: 7.5px;
    font-size: 13.5px;
    padding: 13.5px 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_categoriesMenuOptionsContainer__ZHBAn,
  .videos_optionsContainer__3TSRd {
    border-radius: 8.90625px;
    font-size: 16.03125px;
    padding: 16px 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_categoriesMenuOptionsContainer__ZHBAn,
  .videos_optionsContainer__3TSRd {
    border-radius: 10px;
    font-size: 18px;
    padding: 18px 36px;
  }
}

.videos_option__HKDIJ {
  white-space: nowrap;
}

.videos_option__HKDIJ:hover {
  color: #579ce1;
}

.videos_categoriesMenuContainer__1HpNJ {
  display: flex;
}

.videos_categoriesMenu__1VQen {
  margin-right: 5%;
  position: relative;
  width: 100%;
}

.videos_categoriesMenuTitle__1SZIx,
.videos_categoriesMenuInput__3ALYH {
  display: block;
  width: 100%;
}

.videos_editMenu__TuD_M {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  left: 0;
  position: relative;
  top: 100%;
  width: 100%;
  z-index: 5;
}

.videos_editMenu__TuD_M,
.videos_editMenu__TuD_M * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_editMenu__TuD_M {
    padding: 0 29.375px 29.375px 29.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_editMenu__TuD_M {
    padding: 0 35.25px 35.25px 35.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_editMenu__TuD_M {
    padding: 0 41.859375px 41.859375px 41.859375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_editMenu__TuD_M {
    padding: 0 47px 47px 47px;
  }
}

.videos_editMenuUpdateButton__Vp0-x {
  background-color: #000;
  border: none;
  color: #fff;
  line-height: 2;
  margin: 0;
  padding: 0 22px;
}

.videos_editMenuUpdateButton__Vp0-x:disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.videos_editMenuUpdateButton__Vp0-x:not(:disabled) {
  cursor: pointer;
}

@media all and (hover: hover) {
  .videos_editMenuUpdateButton__Vp0-x:not(:disabled):hover {
    opacity: 0.8;
  }
}

@media all and (pointer: coarse) {
  .videos_editMenuUpdateButton__Vp0-x:not(:disabled):active,
  .videos_editMenuUpdateButton__Vp0-x:not(:disabled):target {
    opacity: 0.8;
  }
}

.videos_editMenuTitle__3cDbw,
.videos_editMenuInput__2Aevx {
  font-family: inherit !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_editMenuTitle__3cDbw,
  .videos_editMenuInput__2Aevx {
    width: 423.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_editMenuTitle__3cDbw,
  .videos_editMenuInput__2Aevx {
    width: 508.50000000000006px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_editMenuTitle__3cDbw,
  .videos_editMenuInput__2Aevx {
    width: 603.84375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_editMenuTitle__3cDbw,
  .videos_editMenuInput__2Aevx {
    width: 678px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_editMenuTitle__3cDbw,
  .videos_categoriesMenuTitle__1SZIx,
  .videos_editMenuUpdateButton__Vp0-x {
    font-size: 13.749999999999998px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_editMenuTitle__3cDbw,
  .videos_categoriesMenuTitle__1SZIx,
  .videos_editMenuUpdateButton__Vp0-x {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_editMenuTitle__3cDbw,
  .videos_categoriesMenuTitle__1SZIx,
  .videos_editMenuUpdateButton__Vp0-x {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_editMenuTitle__3cDbw,
  .videos_categoriesMenuTitle__1SZIx,
  .videos_editMenuUpdateButton__Vp0-x {
    font-size: 22px;
  }
}

.videos_editMenuTitle__3cDbw,
.videos_categoriesMenuTitle__1SZIx {
  font-weight: 500;
  line-height: 2.5;
}

.videos_categoriesMenuInput__3ALYH {
  align-items: center;
  color: #8b8b8b;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 2.5% !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_categoriesMenuInput__3ALYH {
    height: 40.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_categoriesMenuInput__3ALYH {
    height: 48.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_categoriesMenuInput__3ALYH {
    height: 57.89062499999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_categoriesMenuInput__3ALYH {
    height: 65px;
  }
}

.videos_categoriesMenuInputIcon__1V6Y7 {
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_categoriesMenuInputIcon__1V6Y7 {
    font-size: 12.5px !important;
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_categoriesMenuInputIcon__1V6Y7 {
    font-size: 15px !important;
    height: 38px;
    width: 38px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_categoriesMenuInputIcon__1V6Y7 {
    font-size: 17.8125px !important;
    height: 45.5px;
    width: 45.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_categoriesMenuInputIcon__1V6Y7 {
    font-size: 20px !important;
    height: 51px;
    width: 51px;
  }
}

.videos_editMenuInput__2Aevx {
  resize: none;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_editMenuInput__2Aevx {
    height: 88.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_editMenuInput__2Aevx {
    height: 106.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_editMenuInput__2Aevx {
    height: 126.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_editMenuInput__2Aevx {
    height: 142px;
  }
}

.videos_categoriesMenuInput__3ALYH,
.videos_editMenuInput__2Aevx {
  border: 1px solid #707070;
  outline: none;
  padding: 4px;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_categoriesMenuInput__3ALYH,
  .videos_editMenuInput__2Aevx {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_categoriesMenuInput__3ALYH,
  .videos_editMenuInput__2Aevx {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_categoriesMenuInput__3ALYH,
  .videos_editMenuInput__2Aevx {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_categoriesMenuInput__3ALYH,
  .videos_editMenuInput__2Aevx {
    font-size: 18px;
  }
}

.videos_closeButton__D6eel {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-duration: 200ms;
}

@media all and (hover: hover) {
  .videos_closeButton__D6eel:hover {
    background-color: #000;
    color: #fff;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media all and (pointer: coarse) {
  .videos_closeButton__D6eel:active,
  .videos_closeButton__D6eel:target {
    background-color: #000;
    color: #fff;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_closeButton__D6eel {
    font-size: 21.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_closeButton__D6eel {
    font-size: 26.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_closeButton__D6eel {
    font-size: 31.171875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_closeButton__D6eel {
    font-size: 35px;
  }
}

.videos_filterBar__1cRbD {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .videos_filterBar__1cRbD {
    background-color: #f4f4f4;
    height: 40px;
    padding: 0 12px;
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .videos_filterBar__1cRbD {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .videos_filterBar__1cRbD {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_filterBar__1cRbD {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_filterBar__1cRbD {
    height: 41.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_filterBar__1cRbD {
    height: 48.984375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_filterBar__1cRbD {
    height: 55px;
  }
}

@media only screen and (max-width: 899px) {
  .videos_filterButtonIcon__S6FQG {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_filterButtonIcon__S6FQG {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_filterButtonIcon__S6FQG {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_filterButtonIcon__S6FQG {
    font-size: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_filterButtonIcon__S6FQG {
    font-size: 30px;
  }
}

@media only screen and (max-width: 899px) {
  .videos_filterButtonText__Umpor {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_filterButtonText__Umpor {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_filterButtonText__Umpor {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_filterButtonText__Umpor {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_filterButtonText__Umpor {
    font-size: 20px;
  }
}

.videos_filterButtonText__Umpor {
  color: #b7b7b7;
  line-height: 1.5;
}

@media only screen and (max-width: 899px) {
  .videos_filterButtonText__Umpor {
    display: none;
  }
}

.videos_filterButton__3YK7n {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .videos_filterButtonIcon__S6FQG {
    color: #909090;
  }
}

@media only screen and (min-width: 900px) {
  .videos_filterButtonIcon__S6FQG {
    color: #5aa5ef !important;
  }
}

@media only screen and (min-width: 900px) {
  .videos_filterBarOrder__2ED8a {
    display: none;
  }
}

.videos_pageContent__2D5GN {
  overflow-y: hidden;
  padding-left: 103px;
}

.videos_videoListContainer__3hyIT::-webkit-scrollbar {
  width: 80px; /* Adjust this to make the scrollbar thinner */
  height: 8px; /* Adjust this to make the scrollbar thinner */
}
.videos_videoListContainer__3hyIT {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 57vh;
  min-height: 57vh;
  width: 100%;
}
.videos_tableHeaderSticky__3iAKj {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 90;
  background: #faf8f8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1); /* Add a box shadow at the bottom */
}
.videos_tableBody__cdlaG {
  overflow: auto;
  max-height: 100px;
}
@media only screen and (max-width: 899px) {
  .videos_pageTitle__3NM0r {
    display: none !important;
  }
  .videos_pageContent__2D5GN {
    padding-top: 0 !important;
    padding-left: 10px;
  }
  .videos_tableHeader__1OEnB {
    display: none !important;
  }
  .videos_tableBody__cdlaG * {
    border: none !important;
  }
  .videos_tableRow__1Ilei:first-of-type .videos_videoPreviewCell__3Yg3V {
    padding-top: 18px !important;
  }
  .videos_videoPreviewCell__3Yg3V {
    padding-bottom: 9px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 9px !important;
  }
  .videos_bodyCell__3fiHD:not(.videos_videoPreviewCell__3Yg3V) {
    display: none !important;
  }
}

@media only screen and (max-width: 899px) {
  .videos_headerCell__1aXeX {
    font-size: 15px !important;
    height: 60.5px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_headerCell__1aXeX {
    font-size: 15px !important;
    height: 37.8125px !important;
  }
  .videos_headerCell__1aXeX.videos_checkboxCell__2yWFf {
    width: 40.3125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_headerCell__1aXeX {
    font-size: 15px !important;
    height: 45.375px !important;
  }
  .videos_headerCell__1aXeX.videos_checkboxCell__2yWFf {
    width: 48.375px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_headerCell__1aXeX {
    font-size: 17.8125px !important;
    height: 53.8828125px !important;
  }
  .videos_headerCell__1aXeX.videos_checkboxCell__2yWFf {
    width: 57.44531px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_headerCell__1aXeX {
    font-size: 20px !important;
    height: 60.5px !important;
  }
  .videos_headerCell__1aXeX.videos_checkboxCell__2yWFf {
    width: 64.5px;
  }
}

.videos_bodyCell__3fiHD {
  font-size: 20px;
}

.videos_bodyCell__3fiHD.videos_videoPreviewCell__3Yg3V {
  border-right-style: solid;
  border-right-width: 1px;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_bodyCell__3fiHD {
    height: 86.56249999999999px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_bodyCell__3fiHD {
    height: 103.87499999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_bodyCell__3fiHD {
    height: 123.35156249999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_bodyCell__3fiHD {
    height: 138.5px;
  }
}

@media only screen and (min-width: 900px) {
  .videos_selectedRow__2l_8Y {
    background-color: #ededed;
  }
}

.videos_previewContainer__H79Ez,
.videos_previewText__2eHYw {
  align-items: center;
  display: flex;
}

.videos_previewImage__232hw {
  background-color: #0002;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .videos_previewImage__232hw {
    height: 78px;
    width: 145.8px;
  }
  .videos_albumImage__Sh14z {
    width: 72px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_previewImage__232hw,
  .videos_previewText__2eHYw {
    height: 66.875px;
    width: 125px;
  }
  .videos_albumImage__Sh14z {
    width: 62.5px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_previewImage__232hw,
  .videos_previewText__2eHYw {
    height: 80.25px;
    width: 150px;
  }
  .videos_albumImage__Sh14z {
    width: 75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_previewImage__232hw,
  .videos_previewText__2eHYw {
    height: 95.296875px;
    width: 178.125px;
  }
  .videos_albumImage__Sh14z {
    width: 89px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_previewImage__232hw,
  .videos_previewText__2eHYw {
    height: 107px;
    width: 200px;
  }
  .videos_albumImage__Sh14z {
    width: 100px !important;
  }
}

.videos_previewText__2eHYw {
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .videos_previewText__2eHYw {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_previewText__2eHYw {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_previewText__2eHYw {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_previewText__2eHYw {
    margin-left: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_previewText__2eHYw {
    margin-left: 28px;
  }
}

.videos_previewTitle__3JZsc::first-letter,
.videos_previewDescription__2eYse::first-letter {
  text-transform: capitalize;
}

.videos_previewTitle__3JZsc {
  color: #02071a;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.videos_previewDescription__2eYse {
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}
@media only screen and (max-width: 899px) {
  .videos_previewTitle__3JZsc {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_previewTitle__3JZsc {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_previewTitle__3JZsc {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_previewTitle__3JZsc {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_previewTitle__3JZsc {
    font-size: 19px;
  }
}

.videos_previewDescription__2eYse {
  color: #02071a;
  line-height: 1.2;
  opacity: 0.9;
}

@media only screen and (max-width: 899px) {
  .videos_previewDescription__2eYse {
    font-size: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .videos_tableHeaderSticky__3iAKj {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_previewDescription__2eYse {
    font-size: 8.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_previewDescription__2eYse {
    font-size: 10.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_previewDescription__2eYse {
    font-size: 12.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_previewDescription__2eYse {
    font-size: 14px;
  }
}

.videos_previewTitle__3JZsc,
.videos_previewDescription__2eYse {
  display: block;
  text-align: left;
  width: 100%;
}

.videos_checkbox__2pmtI {
  background-color: #0002;
  border-radius: 50%;
  cursor: pointer;
  height: 44px;
  position: relative;
  width: 44px;
  border: 1px solid !important;
}

.videos_checkbox__2pmtI.videos_checked__3nfzc,
.videos_checkbox__2pmtI.videos_indeterminate__3Tv2h {
  background-color: #000;
  /* border-color: #fff; */
  border-style: solid;
}

.videos_checkbox__2pmtI.videos_indeterminate__3Tv2h.videos_header__23cIN {
  border-color: #000;
}

.videos_checkbox__2pmtI.videos_indeterminate__3Tv2h::after {
  background-color: #fff;
  content: " ";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
}

@media only screen and (max-width: 899px) {
  .videos_checkbox__2pmtI {
    border-width: 3.75px;
    height: 26.875px;
    width: 26.875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_checkbox__2pmtI {
    border-width: 3.75px;
    height: 26.875px;
    width: 26.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_checkbox__2pmtI {
    border-width: 4.5px;
    height: 32.25px;
    width: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_checkbox__2pmtI {
    border-width: 5.34375px;
    height: 38.296875px;
    width: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_checkbox__2pmtI {
    border-width: 6px;
    height: 43px;
    width: 43px;
  }
}
.videos_flexCenter__2hYh1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videos_flexLeft__3gvQp {
  justify-content: left !important;
}

.videos_headerTheme__2wrAg {
  background-color: black;
}

.videos_expandIcon__3YdTk {
  vertical-align: middle;
  color: #1172d2;
  font-size: 45px !important;
  cursor: pointer;
  position: relative;
  left: 112px;
  bottom: 25px;
}

.videos_albumPhotoContainer__3Jt3x {
  display: flex;
  justify-content: left;
  margin: 23px 24px;
}

.videos_photoStyle__32Kvc {
  display: inline-block;
}

.videos_showImage__12rUJ {
  width: 200px !important;
  margin-right: 22px;
}

.videos_photolistWrapper__2zuMp {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (max-width: 899px) {
  .videos_photolistWrapper__2zuMp {
    width: 870px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videos_photolistWrapper__2zuMp {
    width: 850px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videos_photolistWrapper__2zuMp {
    width: 1151px;
  }
  .videos_showImage__12rUJ {
    width: 180px !important;
    margin-right: 22px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videos_photolistWrapper__2zuMp {
    width: 100%;
  }
  .videos_showImage__12rUJ {
    width: 188px !important;
    margin-right: 22px;
  }
}

@media only screen and (min-width: 1710px) {
  .videos_photolistWrapper__2zuMp {
    width: 1400px;
  }
}

.videos_addMorePhotoContainer__241i7 {
  position: relative;
  float: left;
  margin-left: 4px;
}
.videos_addMorePhoto__ossD_ {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 13px;
}
.videos_addMoreIcon__2t-rK {
  vertical-align: middle;
  width: 16px !important;
}

/* .titleAndIcon{
  display: inline-flex;
  
} */

.videos_customArrow__2hjaO {
  background-color: #707070;
  width: 3px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.videos_dotFlashing__19Smy {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: videos_dotFlashingAnimated__VKH28 1s infinite linear alternate;
          animation: videos_dotFlashingAnimated__VKH28 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.videos_dotFlashing__19Smy::before,
.videos_dotFlashing__19Smy::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.videos_dotFlashing__19Smy::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: videos_dotFlashingAnimated__VKH28 1s infinite alternate;
          animation: videos_dotFlashingAnimated__VKH28 1s infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.videos_dotFlashing__19Smy::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  -webkit-animation: videos_dotFlashingAnimated__VKH28 1s infinite alternate;
          animation: videos_dotFlashingAnimated__VKH28 1s infinite alternate;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

@-webkit-keyframes videos_dotFlashingAnimated__VKH28 {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@keyframes videos_dotFlashingAnimated__VKH28 {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.videos_pageLink__3Vyne:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.videos_pagination__1ncwz {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.videos_pageLink__3Vyne {
  padding: 0.375rem 0.75rem;
}

.videos_pageLink__3Vyne {
  font-size: 15px;
  font-weight: 600;
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.videos_pageItem__2wCxV.videos_active__3Rurc .videos_pageLink__3Vyne {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.videos_pageItem__2wCxV:not(:first-child) .videos_pageLink__3Vyne {
  margin-left: -1px;
}

.videos_pageItem__2wCxV {
  cursor: pointer !important;
}

.videos_editMouseOver__3Pyeg:hover {
  color: #579ce1;
}

.videos_prefDropDownText__oZtnE:hover {
  color: #579ce1;
}

.videos_progressIndicatorTitle__3FvcU {
  font-family: "Montserrat-Bold" !important;
  white-space: nowrap;
}

.videos_progressIndicatorSubTitle__2nNMn {
  font-family: "Montserrat-Regular" !important;
  white-space: nowrap;
}

.videos_expand__1cAas {
  margin-left: 15px;
  border: 1px solid;
  border-radius: 25px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.videos_processingVideo__1_785 {
  background-color: rgb(225, 225, 225);
}

.videos_dummyLoaderImageCaption__o6sj6 {
  width: 100%;
}

.Listing_positioningModel__2nHAk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.Listing_bigCover__1aTWV{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: 1000000;
}
.Listing_imgCenter__1UuKG {
  position: absolute;
  bottom: 0;
  z-index: 100000;
  left: 50%;
  width: 180px;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
}
.Listing_dragging__1joJZ {
  border-color: #1e90ff; /* Change border color when dragging */
  background-color: rgba(30, 144, 255, 1); /* Add a background color effect */
  z-index: 100000;
  box-shadow: 0 4px 20px rgba(30, 144, 255, 0.3); /* Add shadow effect */
}
.Listing_bringCenter__Z8-R_ {
  position: absolute;
  /* width: calc(100% - 80px); */
  max-width: 100%;
  max-height: 80vh;
  background-color: white;
  overflow-y: scroll;
  overflow-x: scroll;
  top: 50%;
  border-radius: 5px;
  left: 50%;
  padding: 30px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10001;
}
.Listing_addnewForm__1gEG_ {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
/* .addnewForm > input {
  border: 1px solid grey;
  padding: 6px;
  height: 100%;
} */
.Listing_addnewForm__1gEG_ > button {
  padding: 6px;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
}
.Listing_addnewForm__1gEG_ > button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.Listing_closeMeBro__2IRJ7 {
  margin-top: 0 !important;
  cursor: pointer;
  position: absolute;
  background-color: transparent !important;
  color: gray;
  top: 0;
  height: 100%;
  right: -2px;
  display: flex;
  align-items: start;
}
.Listing_cancelPlz__3vrLY {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  min-width: 70px;
  font-family: "Poppins";
}
.Listing_noCancel__3s_vi {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  font-family: "Poppins";
}
.Listing_Cancelxx__H2mPX {
  width: 20px;
  margin-top: 5px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}
.Listing_customDiv__2n830 {
  width: 100%;
  background: white;
  position: absolute;
  height: 100%;
  font-size: 16px;
  padding: 0 5px;
  color: black;
  font-family: "POPPINS";
}
::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}
.Listing_flexico__1JyPA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.Listing_overScrollMe__2sXy4 {
  position: relative;
  width: 100%;
  max-height: 45vh;
  overflow-y: scroll;
}
.Listing_linex__u8QGR {
  width: 100%;
  background-color: #707070;
  opacity: 15%;
  height: 1px;
}
.Listing_description__3xvfu {
  height: 54px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
  padding: 12px 11px;
  font-size: 14px;
  font-family: "poppins";
  margin-top: 7px;
}
.Listing_tabletC__34Iw_ {
display: flex;
grid-gap: 5px;
gap: 5px;
align-items: center;
justify-content: center;
}
.Listing_titles__l9Ox7 {
  height: 54px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 4px;
  padding: 12px 11px;
  font-size: 14px;
  font-family: "poppins";
  border: 1px solid grey;
  width: 120px;
}
.Listing_heading__1o6Iy {
  font-family: "Poppins";
  color: #585858;
  font-weight: 500;
  font-size: 23px;
  padding-left: 16px;
}
.Listing_headingh3__3SNzR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  color: #585858;
  font-size: 12px;
  padding-left: 16px;
}
.Listing_TbleCel__Xljur {
  font-family: "Poppins";
  color: #585858;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
.Listing_nameBal__2274p {
  width: 200px; /* Set the width at which you want the text to be truncated */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Listing_sizeBal__1jrD6 {
  width: 100px; /* Set the width at which you want the text to be truncated */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
  text-align: center;
}

.Listing_previewContainer__2OapY,
.Listing_previewText__2MSWD {
  align-items: center;
  display: flex;
}

.Listing_previewImage__1KmDu {
  width: 145px;
  height: 77px;
  background-color: transparent;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}
.Listing_cancel__2BDSz {
  width: -webkit-max-content;
  width: max-content;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Poppins";
  color: #585858;
  padding: 5px 8px;
  font-weight: 600;
  background-color: white;
  font-size: 14px;
  border: 1px solid #585858;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
}
.Listing_save__1uzvG {
  font-family: "Poppins";
  cursor: pointer;
  color: white;
  font-weight: 600;
  display: flex;
  width: 126px;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 5px 8px;
  height: 26px;
  border-radius: 13px;
  justify-content: center;
  background-color: #585858;
  font-size: 14px;
}
.Listing_save__1uzvG:hover {
  opacity: 0.5;
}
.Listing_cancel__2BDSz:hover {
  opacity: 0.5;
}
.Listing_flexButtun__r6B-t {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: start;
  align-items: center;
}
.Listing_flexButton__3Gaiu {
  display: flex;
  align-items: center;
}
.Listing_labelButton__3uQe_ {
  /* display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  transition: background-color 0.3s ease;
  font-size: 12px; */

  font-family: "Poppins";
  cursor: pointer;
  color: white;
  font-weight: 600;
  display: flex;
  width: 126px;
  align-items: center;
  width: -webkit-max-content;
  width: max-content;
  padding: 5px 8px;
  height: 26px;
  border-radius: 13px;
  justify-content: center;
  background-color: #585858;
  font-size: 14px;
}
.Listing_labelButton__3uQe_:hover {
  opacity: 0.5;
}
.Listing_hiddenInput__1CxPs {
  display: none;
}

.sidebar_sidebar__UFtgu {
  background-color: transparent;
  color: #000 !important;
  font-weight: 600 !important;
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar_sidebarContent__3y71r {
  position: fixed !important;
  left: 0;
  background: white;
  z-index: 100 !important;
}
.sidebar_existButtun__3yHNC {
  margin: 0 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  opacity: 1;
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0px;
  cursor: pointer;
  color: #1a1c22;
  opacity: 1;
}
.sidebar_existButtun__3yHNC:hover {
  background: #579ce1 !important;
}
.sidebar_accessedCompny__2PjQO {
  display: flex;
  background-color: #1172d2;
  width: 100%;
  z-index: 111;
  cursor: pointer;
  position: fixed;
  height: 39px;
  align-items: center;
  justify-content: start;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  padding: 5px 23px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.sidebar_link__298pT {
  align-items: center !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
  font-weight: 600 !important;
  justify-content: center !important;
  position: relative !important;
  /* height: 80px; */
}
@media only screen and (min-width: 900px) {
  .sidebar_sidebar__UFtgu {
    position: relative;
  }
}
.sidebar_currentLink__RDrLj {
  align-items: center !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
  font-weight: 600 !important;
  margin-bottom: 44px;
  justify-content: center !important;
  position: relative !important;
  border-right: 1px solid #5aa5ef;
}
@media only screen and (max-width: 899px) {
  .sidebar_sidebar__UFtgu {
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 98;
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebar__UFtgu {
        padding-bottom: -1px;
      }
    }
  }
  .sidebar_sidebar__UFtgu.sidebar_noBottomBar__1Pwb0 {
    display: none !important;
  }
}

.sidebar_noFixedSidebar__1he0G {
  display: none !important;
}

@media only screen and (max-width: 899px) {
  .sidebar_noBottomBar__1Pwb0 {
    display: none !important;
  }
}

.sidebar_sidebarFill__1oXwS {
  opacity: 0;
  order: 100;
  position: relative !important;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_sidebarFill__1oXwS {
      padding-bottom: -1px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .sidebar_sidebarFill__1oXwS {
    display: none;
  }
}

.sidebar_sidebarContent__3y71r {
  width: 100%;
  position: fixed;
  left: 0;
  background: transparent;
  z-index: 10000000;
}

.sidebar_sidebarContent__3y71r::-webkit-scrollbar {
  width: 4px;
}

.sidebar_sidebarContent__3y71r::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar_sidebarContent__3y71r::-webkit-scrollbar-thumb {
  background: #6e6f6e;
  border-radius: 25px;
}

.sidebar_sidebarContent__3y71r::-webkit-scrollbar-thumb:hover {
  background: #6e6f6e;
}

@media only screen and (max-width: 899px) {
  .sidebar_sidebarContent__3y71r {
    display: flex;
    height: 49px;
    justify-content: space-around;
    position: relative;
  }
}

@media only screen and (min-width: 900px) {
  .sidebar_sidebarContent__3y71r {
    display: block;
    max-height: calc(100vh - var(--header-height));
    overflow-y: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-top: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-bottom: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar_sidebarContent__3y71r {
        padding-left: -1px;
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebar_sidebarContent__3y71r {
    width: 77.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar_sidebarContent__3y71r {
    width: 91.734375px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar_sidebarContent__3y71r {
    width: 103px;
  }
}

.sidebar_link__298pT {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  position: relative;
  /* height: 80px; */
}

@media only screen and (max-width: 899px) {
  .sidebar_link__298pT {
    height: 100%;
    width: 49px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebar_link__298pT {
    margin: 33px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar_link__298pT {
    margin: 39.1875px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar_link__298pT {
    margin: 44px 0;
  }
}

.sidebar_link__298pT:first-of-type {
  margin-top: 0 !important;
}

@media only screen and (max-width: 899px) {
  .sidebar_currentLink__RDrLj {
    color: #5aa5ef;
    height: 100%;
    width: 49px;
  }
}

.sidebar_currentLink__RDrLj::before {
  background-color: #5aa5ef;
  content: " ";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 899px) {
  .sidebar_currentLink__RDrLj::before {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebar_currentLink__RDrLj::before {
    width: 3px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar_currentLink__RDrLj::before {
    width: 3.5625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar_currentLink__RDrLj::before {
    width: 4px;
  }
}

.sidebar_linkIcon__1AXgn {
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .sidebar_linkIcon__1AXgn {
    height: 28.9px;
    width: 28.9px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebar_linkIcon__1AXgn {
    height: 32.625px;
    margin-top: 12px;
    width: 32.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar_linkIcon__1AXgn {
    height: 38.7421875px;
    width: 38.7421875px;
    margin: 14.25px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar_linkIcon__1AXgn {
    height: 43.5px;
    margin-top: 16px;
    width: 43.5px;
  }
}

.sidebar_linkName__Ozqe4 {
  line-height: 1.2;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .sidebar_linkName__Ozqe4 {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebar_linkName__Ozqe4 {
    font-size: 10.5px;
    margin-bottom: 7.5px;
    margin-top: 6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebar_linkName__Ozqe4 {
    font-size: 12.46875px !important;
    margin-bottom: 8.90625px !important;
    margin-top: 7.125px !important;
    color: #000 !important;
    font-family: "Poppins";
  }
}

@media only screen and (min-width: 1710px) {
  .sidebar_linkName__Ozqe4 {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 8px;
  }
}

.sidebar_slidingSidebarContainer__2qUxd {
  position: relative;
}

.sidebar_slidingSidebar__383H8 {
  max-width: 360px;
  width: calc(100vw - 32px);
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebar__383H8 {
      padding-bottom: 40px;
    }
  }
}

.sidebar_logoContainer__DjXlB {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

@media only screen and (max-width: 899px) {
  .sidebar_logoContainer__DjXlB {
    background-color: #000;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_logoContainer__DjXlB {
      padding-right: 40px;
    }
  }
}

.sidebar_slidingSidebarLinksContainer__3A_nr {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLinksContainer__3A_nr {
      padding-bottom: 40px;
    }
  }
}

.sidebar_slidingSidebarLink__3ThM6 {
  align-items: center;
  color: #000;
  display: flex;
  height: 48px;
  padding: 12px 0;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(28px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(32px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(36px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: max(40px, env(safe-area-inset-left)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-left: 40px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(32px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(36px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: max(40px, env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebar_slidingSidebarLink__3ThM6 {
      padding-right: 40px !important;
    }
  }
}

@media all and (hover: hover) {
  .sidebar_slidingSidebarLink__3ThM6:hover {
    background-color: #eee;
  }
}

@media all and (pointer: coarse) {
  .sidebar_slidingSidebarLink__3ThM6:active,
  .sidebar_slidingSidebarLink__3ThM6:target {
    background-color: #eee;
  }
}

.sidebar_currentSlidingSidebarLink__3eA71 {
  color: #5aa5ef;
}

.sidebar_slidingSidebarLinkIcon__8I9Dt {
  height: 23px;
  width: 23px;
}

.sidebar_slidingSidebarLinkName__2wAxy {
  font-size: 15px;
  margin-left: 21.5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar_slidingSidebarChildLink__33ohx:not(.sidebar_currentSlidingSidebarLink__3eA71) {
  color: #777777;
}

.sidebar_slidingSidebarChildLink__33ohx .sidebar_slidingSidebarLinkName__2wAxy {
  font-size: 15px;
  margin-left: 72px;
}

.sidebar_chevronUp__2yGqC {
  margin-left: 12px;
  transition-duration: 200ms;
}

.page_page__1ZAMY {
  background-color: #faf8f8;
  display: flex;
  flex-grow: 1;
  height: 100%;
  min-height: calc(100vh - var(--header-height));
  position: relative;
  width: 100%;
}
.page_holdMe__31NMG{
  overflow: hidden;
  height: 600px;
}
/* Add this style to your existing styles */
.page_sidebarWrapperFixed__16mPE {
  position: fixed;
  top: 72px; /* Adjust this value to set the vertical position of the sidebar */
  left: 0; /* Adjust this value to set the horizontal position of the sidebar */
  bottom: 0; /* You can use 'bottom' instead of 'top' if you want the sidebar fixed to the bottom */
  width: auto; /* Adjust the width of the sidebar as per your requirement */
}

.page_pageWrapper__WOjfl {
  display: flex;
}

.page_mainWrapper__iDgDK {
  flex: 1 1;
  display: flex;
}

.page_iamfixed__2-2JZ{
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.page_cloud__1O2nR{
  object-fit: cover;
  width: 200px;
  height: 200px;
  margin-bottom: 100px;
}


@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page_contentWrapper__F2DI6 {
    flex: 1 1;
    padding-left: 59px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_contentWrapper__F2DI6 {
    flex: 1 1;
    padding-left: 74px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_contentWrapper__F2DI6 {
    flex: 1 1;
    padding-left: 85px;
  }
}

/* Optional: To create some spacing between the sidebar and the main content */
.page_sidebarWrapperFixed__16mPE + .page_contentWrapper__F2DI6 {
  margin-left: 20px; /* Adjust this value as needed */
  display: flex;
}

.page_page__1ZAMY.page_noDefaultBg__218LE {
  background-color: unset;
}

.page_accessPageBackGround__cK-p_ {
  background-color: black;
}

@media only screen and (max-width: 899px) {
  .page_page__1ZAMY {
    flex-direction: column;
  }
}

.page_pageContent__T9eSs {
  flex-grow: 1;
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContentBottom {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContentBottom {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContentBottom {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContentBottom {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContentBottom {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContentBottom {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContentBottom {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContentBottom {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContentBottom {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContentBottom {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContentBottom {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContentBottom {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContentBottom {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContentBottom {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContentBottom {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContentBottom {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
} */

/* @supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-left: 40px;
    }
  }
} */

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
} */

/* @supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .pageContent {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .pageContent {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .pageContent {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .pageContent {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .pageContent {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .pageContent {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .pageContent {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .pageContent {
      padding-right: 40px;
    }
  }
} */

.profile-page_page__2Uedm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-shadow: rgba(0, 0, 0, 0.4) 0 0 16px;
}

.profile-page_page__2Uedm,
.profile-page_page__2Uedm * {
  font-family: Montserrat, sans-serif !important;
}

.profile-page_pageContent__2Ma0v {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;
}

.profile-page_main__3bNoQ {
  background-color: transparent;
  margin: 0 auto;
  min-height: 100% !important;
  max-width: 2600px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile-page_main__3bNoQ {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile-page_main__3bNoQ {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .profile-page_main__3bNoQ {
    --site-padding: 110px !important;
  }
}


.profile-page_mainContent__1zAzX {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile-page_mainContent__1zAzX {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile-page_mainContent__1zAzX {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile-page_mainContent__1zAzX {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile-page_mainContent__1zAzX {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile-page_mainContent__1zAzX {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile-page_mainContent__1zAzX {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile-page_mainContent__1zAzX {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile-page_mainContent__1zAzX {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile-page_mainContent__1zAzX {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile-page_mainContent__1zAzX {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile-page_mainContent__1zAzX {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile-page_mainContent__1zAzX {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile-page_mainContent__1zAzX {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile-page_mainContent__1zAzX {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile-page_mainContent__1zAzX {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile-page_mainContent__1zAzX {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile-page_mainContent__1zAzX {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile-page_mainContent__1zAzX {
      padding-right: var(--site-padding);
    }
  }
}

.confirmation-modal_title__10UBM {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: 'Montserrat' !important;

}
.confirmation-modal_content__1JXBD {
  text-align: center;
  color: #000;
  max-width: 300px;
  /* margin-left: 122px; */
  font-family: 'Montserrat' !important;
  font-weight: 500;

}
.confirmation-modal_actionContainer__3GAkc {
  /* margin : 4px 75px 18px 89px; */
  display: flex;
  justify-content: center;
  width: 100%;
}
.confirmation-modal_cancelButton__2Uwid{
  text-transform: none;
  font-family: 'Montserrat' !important;
  color: #000;
  background-color:#DEDEDE;
  font-size: 16px !important;
  font-weight: 500;
  /* margin-right: 89px; */

}
.confirmation-modal_continueButton__Fl9-_{
  text-transform: none;
  color: #FF0000;
  background-color: #fff;
  font-family: 'Montserrat' !important;
  border-color: #707070 !important;
  font-size: 16px !important;
  font-weight: 500;
  margin-right: 14px;
}
.confirmation-modal_dialogContainer__3arH6 {
  border-radius: 15px;
}
.confirmation-modal_mainContent__2uYal {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .confirmation-modal_title__10UBM {
    font-size: 20px !important;
  }
  .confirmation-modal_content__1JXBD {
    font-size: 15px;
  }
}
.video-preview_page__21k4b {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-shadow: rgba(0, 0, 0, 0.4) 0 0 16px;
}

.video-preview_page__21k4b,
.video-preview_page__21k4b * {
  font-family: Montserrat, sans-serif !important;
}

.video-preview_pageContent__MNQpN {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.video-preview_main__2CKA- {
  background-color: transparent;
  margin: 0 auto;
  /* min-height: 100% !important; */
  /* max-width: 1920px; */
}

/* @media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .main {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .main {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .main {
    --site-padding: 110px !important;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .main {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .main {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .main {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .main {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .main {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .main {
      padding-bottom: max(var(--site-padding), env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .main {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .main {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .main {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .main {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .main {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .main {
      padding-bottom: var(--site-padding);
    }
  }
} */

.video-preview_mainContent__3yqp4 {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .video-preview_mainContent__3yqp4 {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .video-preview_mainContent__3yqp4 {
      padding-right: var(--site-padding);
    }
  }
}

.projector-shimmer_shimmerWrapper__1gPeW {
  display: flex;
  flex-direction: row;
  -webkit-animation: projector-shimmer_shimmer__buzxp 1s infinite;
          animation: projector-shimmer_shimmer__buzxp 1s infinite;
  /* -webkit-mask:linear-gradient(-60deg,#000 30%,#202020,#404040 70%) right/300% 100%; */
   margin-top: 20px;
   -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: projector-shimmer_shimmer__buzxp;
  -webkit-animation-timing-function: linear;
  -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  position: absolute;
  width: 100%;
  top: 10px;
  padding: 0 80px;
}

.projector-shimmer_lightShimmerWrap__Qaals {
  padding: 0 20px !important;
  /* margin-top: 80px; */
}

.projector-shimmer_shimmerBox__1xIvP {
  width: 350px !important;
  height: 145px;
  border: none;
  border-radius: 10px;
  /* background: pink; */
  margin-right: 10px;
  /* border: 1px solid; */
  float: left;
  min-width: 250px;
  /* animation: fadein 2s infinite; */
  /* -moz-animation: fadein 2s; */
  /* Firefox */
  /* -webkit-animation: fadein 2s; */
  /* Safari and Chrome */
  /* -o-animation: fadein 2s; */
  /* Opera */

}

.projector-shimmer_shimmerBox__1xIvP:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  background-color: #515151;
}

.projector-shimmer_shimmerBox__1xIvP:nth-child(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  background-color: #4A4A4A;
}

.projector-shimmer_shimmerBox__1xIvP:nth-child(3) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
  background-color: #464646;
}

.projector-shimmer_shimmerBox__1xIvP:nth-child(4) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
  background-color:  #404040;
}

.projector-shimmer_shimmerBox__1xIvP:nth-child(5) {
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
  background-color:  #3C3B3B;
}

.projector-shimmer_shimmerBox__1xIvP:nth-child(6) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  background-color:  #393535;
}

.projector-shimmer_shimmerLight__365qn {
  width: 350px !important;
  height: 145px;
  border: none;
  border-radius: 10px;
  /* background: pink; */
  margin-right: 10px;
  /* border: 1px solid; */
  float: left;
  min-width: 250px;
}

.projector-shimmer_shimmerLight__365qn:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  background-color: #ebebe0;
}

.projector-shimmer_shimmerLight__365qn:nth-child(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  background-color:  #e2e2d0;
}

.projector-shimmer_shimmerLight__365qn:nth-child(3) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
  background-color: #d8d8c0;
}

@keyframes projector-shimmer_fadein__l6KCi {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@-webkit-keyframes projector-shimmer_fadein__l6KCi {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

 @-webkit-keyframes projector-shimmer_shimmer__buzxp {
 100% {-webkit-mask-position:left}
}

 @keyframes projector-shimmer_shimmer__buzxp {
 100% {-webkit-mask-position:left}
}

@-webkit-keyframes projector-shimmer_placeholderShimmer__2pmZ6 {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

.projector-shimmer_lineShimmerWrap__1A2IH {
  position: absolute;
  width: 100%;
  margin-top: 50px;

}
.album-player_header__qFSfV {
  position: absolute;
  width: 100%;
  height: 40px; 
  z-index: 9;
}
.album-player_backButton__1s5m3 {
  margin: 0 20px 0 0;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Montserrat';
}

.album-player_circularProgress__3ef_o {
  /* display: inline-block;
  vertical-align: middle; */
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-animation: none !important;
          animation: none !important;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  
}

.album-player_colorChangePlay__2MlXE  {
  fill: #909298;
 }
 
 .album-player_colorChangePlay__2MlXE:hover {
   fill: #fff;
 }
.mobileviewbody {
    background-color:#323232;
    margin-top: -550px;
    width: 100%;
    height: 50px;
    /* text-align: center; */
}

.modaltext{
    font-size: small;
    margin-left: 25px;
    margin-top: 5px;
    line-height: 1rem;
    color: aliceblue;
}
.access_pageContainer__R3FQE {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}
.access_flexico__sPLTG{
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.access_page__3RXUJ {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-top: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-top: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-top: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-top: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-top: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-top: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-top: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-top: 40px;
    }
  }
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
} */

/* @supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page {
      padding-bottom: 40px;
    }
  }
} */

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-left: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-left: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-left: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-left: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-left: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-left: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-left: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .access_page__3RXUJ {
      padding-right: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .access_page__3RXUJ {
      padding-right: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .access_page__3RXUJ {
      padding-right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access_page__3RXUJ {
      padding-right: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access_page__3RXUJ {
      padding-right: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access_page__3RXUJ {
      padding-right: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access_page__3RXUJ {
      padding-right: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    .access_page__3RXUJ {
      padding-right: 40px;
    }
  }
}

.access_pageContent__1Sh-F {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.access_pageContent__1Sh-F,
.access_pageContent__1Sh-F * {
  font-family: 'Montserrat';
}

.access_access__2049V {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 130px; */
}
.access_dragIconContainer__2XLKB {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .access_dragIconContainer__2XLKB {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .access_dragIconContainer__2XLKB {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .access_dragIconContainer__2XLKB {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .access_dragIconContainer__2XLKB {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .access_dragIconContainer__2XLKB {
    margin-right: 33.33px;
  }
}

.access_dragIcon__1WCWV {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .access_dragIcon__1WCWV {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .access_dragIcon__1WCWV {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .access_dragIcon__1WCWV {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .access_dragIcon__1WCWV {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .access_dragIcon__1WCWV {
    height: 33.33px;
    width: 33.33px;
  }
}
@media only screen and (max-width: 767px) {
  .access_access__2049V {
    flex-grow: 1;
    height: 100%;
  }
}

@media only screen and (min-width: 768px) {
  @supports (padding: max(0px)) {
    /* @media only screen and (max-width: 319px) {
      .access {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    } */
  }

  /* @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-top: 40px;
      }
    }
  } */
  /* @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .access {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  } */
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access_access__2049V {
        padding-bottom: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access_access__2049V {
        padding-bottom: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access_access__2049V {
        padding-bottom: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access_access__2049V {
        padding-bottom: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access_access__2049V {
        padding-bottom: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access_access__2049V {
        padding-bottom: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access_access__2049V {
        padding-bottom: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access_access__2049V {
        padding-bottom: 40px;
      }
    }
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access_access__2049V {
        padding-left: max(12px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access_access__2049V {
        padding-left: max(16px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access_access__2049V {
        padding-left: max(20px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access_access__2049V {
        padding-left: max(24px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access_access__2049V {
        padding-left: max(28px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access_access__2049V {
        padding-left: max(32px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access_access__2049V {
        padding-left: max(36px, env(safe-area-inset-left));
      }
    }

    @media only screen and (min-width: 1710px) {
      .access_access__2049V {
        padding-left: max(40px, env(safe-area-inset-left));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access_access__2049V {
        padding-left: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access_access__2049V {
        padding-left: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access_access__2049V {
        padding-left: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access_access__2049V {
        padding-left: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access_access__2049V {
        padding-left: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access_access__2049V {
        padding-left: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access_access__2049V {
        padding-left: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access_access__2049V {
        padding-left: 40px;
      }
    }
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access_access__2049V {
        padding-right: max(12px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access_access__2049V {
        padding-right: max(16px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access_access__2049V {
        padding-right: max(20px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access_access__2049V {
        padding-right: max(24px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access_access__2049V {
        padding-right: max(28px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access_access__2049V {
        padding-right: max(32px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access_access__2049V {
        padding-right: max(36px, env(safe-area-inset-right));
      }
    }

    @media only screen and (min-width: 1710px) {
      .access_access__2049V {
        padding-right: max(40px, env(safe-area-inset-right));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .access_access__2049V {
        padding-right: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .access_access__2049V {
        padding-right: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .access_access__2049V {
        padding-right: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .access_access__2049V {
        padding-right: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .access_access__2049V {
        padding-right: 28px;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .access_access__2049V {
        padding-right: 32px;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .access_access__2049V {
        padding-right: 36px;
      }
    }

    @media only screen and (min-width: 1710px) {
      .access_access__2049V {
        padding-right: 40px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .access_message__1BLiP {
    font-size: 34px;
    /* margin-bottom: 20px; */
    margin-top: 64px;
  }

  .access_profileLinksContainer__rLGFY {
    grid-gap: 1px;
    gap: 1px;
    /* padding: 44px 0; */
  }

  .access_profileLinksContainer__rLGFY.access_has1Item__1x0Mw {
    -ms-grid-columns: 132px;
    grid-template-columns: 132px;
  }

  .access_profileLinksContainer__rLGFY.access_has2Item__uowZ5 {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }

  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 132px 132px 132px;
    grid-template-columns: 132px 132px 132px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 132px 132px 132px;
    grid-template-columns: 132px 132px 132px;
  }

  .access_profileImage__1DopC {
    border-radius: 10px;
    border-width: 3px;
    height: 132px;
  }

  .access_dp__1cCaM {
    height: 51px;
    width: 51px;
  }

  .access_profileName__kzfTs,
  .access_myContentButton__15vuE {
    font-size: 12px;
  }

  .access_myContentButton__15vuE {
    border-radius: 5px;
    height: 47px;
    margin-bottom: 96px;
    margin-top: 54px;
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 132px 132px;
    grid-template-columns: 132px 132px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .access_message__1BLiP {
    font-size: 35px;
    /* margin-bottom: 25px; */
  }

  .access_profileLinksContainer__rLGFY {
    grid-gap: 1.125px;
    gap: 1.125px;
  }

  .access_profileLinksContainer__rLGFY.access_has1Item__1x0Mw {
    -ms-grid-columns: 151.875px;
    grid-template-columns: 151.875px;
  }

  .access_profileLinksContainer__rLGFY.access_has2Item__uowZ5 {
    -ms-grid-columns: 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px;
  }

  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 151.875px 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px 151.875px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 151.875px 151.875px 151.875px 151.875px;
    grid-template-columns: 151.875px 151.875px 151.875px 151.875px;
  }

  .access_profileImage__1DopC {
    border-radius: 6.25px;
    border-width: 2px;
    height: 151.875px;
  }

  .access_dp__1cCaM {
    height: 45px;
    width: 45px;
  }

  .access_profileName__kzfTs,
  .access_myContentButton__15vuE {
    font-size: 12.5px;
  }

  .access_myContentButton__15vuE {
    border-radius: 3.125px;
    height: 40.625px;
    margin-top: 28.750000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .access_message__1BLiP {
    font-size: 42px;
    /* margin-bottom: 30px; */
  }

  .access_profileLinksContainer__rLGFY {
    grid-gap: 1.75px;
    gap: 1.75px;
  }

  .access_profileLinksContainer__rLGFY.access_has1Item__1x0Mw {
    -ms-grid-columns: 182.25px;
    grid-template-columns: 182.25px;
  }

  .access_profileLinksContainer__rLGFY.access_has2Item__uowZ5 {
    -ms-grid-columns: 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px;
  }

  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 182.25px 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px 182.25px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 182.25px 182.25px 182.25px 182.25px;
    grid-template-columns: 182.25px 182.25px 182.25px 182.25px;
  }

  .access_profileImage__1DopC {
    border-radius: 7.5px;
    border-width: 2px;
    height: 182.25px;
  }

  .access_dp__1cCaM {
    height: 54px;
    width: 54px;
  }


  .access_profileName__kzfTs,
  .access_myContentButton__15vuE {
    font-size: 15px;
  }

  .access_myContentButton__15vuE {
    border-radius: 3.75px;
    height: 48.75px;
    margin-top: 34.5px;
    width: 282px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .access_message__1BLiP {
    font-size: 49.875px;
    /* margin-bottom: 35.625px; */
  }

  .access_profileLinksContainer__rLGFY {
    grid-gap: 1.078125px;
    gap: 1.078125px;
  }

  .access_profileLinksContainer__rLGFY.access_has1Item__1x0Mw {
    -ms-grid-columns: 216.42188px;
    grid-template-columns: 216.42188px;
  }

  .access_profileLinksContainer__rLGFY.access_has2Item__uowZ5 {
    -ms-grid-columns: 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px;
  }

  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 216.42188px 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px 216.42188px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 216.42188px 216.42188px 216.42188px 216.42188px;
    grid-template-columns: 216.42188px 216.42188px 216.42188px 216.42188px;
  }

  .access_profileImage__1DopC {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 216.421875px;
  }

  .access_dp__1cCaM {
    height: 64.125px;
    width: 64.125px;
  }

  .access_profileName__kzfTs,
  .access_myContentButton__15vuE {
    font-size: 17.8125px;
  }

  .access_myContentButton__15vuE {
    border-radius: 4.453125px;
    height: 57.89062499999999px;
    margin-top: 40.96875px;
    width: 334.875px;
  }
}

@media only screen and (min-width: 1710px) {
  .access_message__1BLiP {
    font-size: 56px;
    /* margin-bottom: 40px; */
  }

  .access_profileLinksContainer__rLGFY {
    grid-gap: 2px;
    gap: 2px;
  }

  .access_profileLinksContainer__rLGFY.access_has1Item__1x0Mw {
    -ms-grid-columns: 243px;
    grid-template-columns: 243px;
  }

  .access_profileLinksContainer__rLGFY.access_has2Item__uowZ5 {
    -ms-grid-columns: 243px 243px;
    grid-template-columns: 243px 243px;
  }

  .access_profileLinksContainer__rLGFY.access_has3Item__24h_d {
    -ms-grid-columns: 243px 243px 243px;
    grid-template-columns: 243px 243px 243px;
  }

  .access_profileLinksContainer__rLGFY.access_has4Item__wUts3 {
    -ms-grid-columns: 243px 243px 243px 243px;
    grid-template-columns: 243px 243px 243px 243px;
  }

  .access_profileImage__1DopC {
    border-radius: 10px;
    border-width: 3px;
    height: 243px;
  }

  .access_dp__1cCaM {
    height: 72px;
    width: 72px;
  }

  .access_profileName__kzfTs,
  .access_myContentButton__15vuE {
    font-size: 20px;
  }

  .access_myContentButton__15vuE {
    border-radius: 5px;
    height: 65px;
    margin-top: 46px;
    width: 376px;
  }
}

.access_message__1BLiP {
  color: #fff;
  font-weight: 900;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .access_message__1BLiP .access_messageSpace__PfRWC {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .access_message__1BLiP .access_messageBreak__3jQbX {
    display: none;
  }
}

@media only screen and (max-width: 319px) {
  .access_message__1BLiP {
    width: 200px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .access_message__1BLiP {
    width: 280px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .access_message__1BLiP {
    width: 430px;
    max-width: 80%;
  }
}

.access_removeProjectorText__3PTjf {
  margin-top: 7px !important;
}

.access_profileLinksContainer__rLGFY {
  display: -ms-grid;
  display: grid;
  justify-content: center;
  /* margin: auto; */
}

.access_accessButtonBelow__2SeO8 {
  display: block !important;
}

.access_accessButtonBelow__2SeO8 {
  display: block !important;
}

.access_profileLink__1ef8i {
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin-right: 20px;
}
.access_profileLinkDnd__F1Kj7 {
  color: #fff;
  text-align: center;
  margin-right: 20px;
  width: 100%;
}
.access_profileLinkDnd__F1Kj7:not(:first-child) {
  margin-top: 15px;
  }
.access_requestProfileAccess__2W3mu {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  margin-top: 4%;
}
.access_blockReq__1aZdg {
  background-color: transparent;
  border: none;
  opacity: 0.3;
  margin: 0;
  padding: 0;
  margin-top: 4%;
}

.access_requestProfileAccess__2W3mu .access_profileImage__1DopC {
  /* background-color: #31343e;
  border: none; */
}

.access_requestProfileAccess__2W3mu .access_dp__1cCaM {
  height: 56px !important;
  width: 56px !important;
}
.access_blockReq__1aZdg .access_dp__1cCaM {
  height: 56px !important;
  width: 56px !important;
}

.access_requestProfileAccess__2W3mu .access_bottomDp__3KqWu {
  height: 30px !important;
  width: 30px !important;
}
.access_blockReq__1aZdg .access_bottomDp__3KqWu {
  height: 30px !important;
  width: 30px !important;
}

.access_profileImage__1DopC {
  align-items: center;
  /* border-color: #5aa5ef;
  border-style: solid; */
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.access_dp__1cCaM {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 767px) {
  .access_dp__1cCaM {
    height: 58px;
    width: 52px;
  }
}

@media only screen and (min-width: 768px) {
  .access_dp__1cCaM {
    height: 64px;
    width: 64px;
  }
}

.access_profileName__kzfTs {
  display: inline-block;
  font-weight: 600;
  font-size: 1.5rem;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 15%;
}
.access_profileNameDnd__3q2T_ {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .access_profileName__kzfTs {
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 899px) {
  .access_profileName__kzfTs {
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    margin-top: 20px;
  }
}

.access_requestProfileAccess__2W3mu .access_profileName__kzfTs {
  color: #5AA5EF;
  font-size: 0.8rem !important;
}
.access_blockReq__1aZdg .access_profileName__kzfTs {
  color: grey;
  font-size: 0.8rem !important;
}

.access_myContentButton__15vuE {
  align-items: center;
  background-color: transparent;
  border: 2px solid #6d6f76;
  color: #6d6f76;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: center;
  padding: 0 24px;
}

.access_modal__1Rlmn {
  border-radius: 12px;
}

.access_modal__1Rlmn .MuiDialog-paper {
  margin: calc(var(--site-padding)) !important;
  width: 100%;
}

.access_modalHeading__3_q-q {
  flex-shrink: 0;
}

@media only screen and (max-width: 767px) {
  .access_modalHeading__3_q-q {
    padding: 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .access_modalHeading__3_q-q {
    padding: 24px 24px 16px 24px !important;
  }
}

.access_modalHeadingContent__22DJo {
  align-items: center;
  color: #000;
  display: flex;
}

@media only screen and (max-width: 419px) {
  .access_modalHeadingContent__22DJo {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .access_modalHeadingContent__22DJo {
    font-size: 17px;
  }
}

.access_addPersonIconContainer__33Y3K {
  align-items: center;
  background-color: #5aa5ef;
  border-radius: 50%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: auto;
  margin-right: 12px;
}

@media only screen and (max-width: 767px) {
  .access_addPersonIconContainer__33Y3K {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .access_addPersonIconContainer__33Y3K {
    height: 40px;
    width: 40px;
  }
}

.access_addPersonIcon__10jQA {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 767px) {
  .access_modalContent__1AvWe {
    padding: 12px 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .access_modalContent__1AvWe {
    padding: 0 24px !important;
  }
}

.access_modalEmailInput__3GxN7 {
  -webkit-appearance: none;
  appearance: none;
  background-color: #f8f9fa;
  border: 2px solid transparent;
  border-left: none;
  border-radius: 4px 4px 0 0;
  border-right: none;
  color: #000;
  font-size: 16px;
  height: 44px;
  margin-top: 16px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
}

.access_modalEmailInput__3GxN7::-webkit-input-placeholder {
  color: #8e8e8e;
}

.access_modalEmailInput__3GxN7:-ms-input-placeholder {
  color: #8e8e8e;
}

.access_modalEmailInput__3GxN7::placeholder {
  color: #8e8e8e;
}

.access_modalEmailInput__3GxN7:focus {
  border-bottom-color: #5aa5ef;
}

.access_personButton__14Qgl {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 12px;
  text-align: left;
  width: 100%;
}

@media all and (hover: hover) {
  .access_personButton__14Qgl:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {

  .access_personButton__14Qgl:active,
  .access_personButton__14Qgl:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.access_personButtonDP__2z2OK {
  border-radius: 50%;
  flex-shrink: 0;
  height: 40px;
  margin-right: 12px;
  position: relative;
  width: 40px;
}

.access_personDPImage__A64bL,
.access_personDPText__1UKi7 {
  border-radius: 50%;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.access_personDPText__1UKi7 {
  background-color: #14f47b;
  color: #fff;
  display: flex;
  font-size: 24px;
  justify-content: center;
  line-height: 1.5;
}

.access_personButtonTextContent___OLNc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.access_personButtonName__k-rsF,
.access_personButtonEmail__3j-2L {
  font-size: 14px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.access_personButtonName__k-rsF {
  color: #000000;
  text-transform: capitalize;
}

.access_personButtonEmail__3j-2L {
  color: #63676c;
  text-transform: lowercase;
}

.access_modalActions__1SO1A {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .access_modalActions__1SO1A {
    justify-content: center !important;
    padding: 14px 18px 14px 18px !important;
  }
}

@media only screen and (min-width: 768px) {
  .access_modalActions__1SO1A {
    justify-content: flex-end !important;
    padding: 18px 24px !important;
  }
}

.access_modalDoneButton__RH3G2 {
  font-size: 16px;
  height: 44px !important;
  text-transform: capitalize;
}

.access_vewMyContent__36Gqq {
  margin-top: 0;
  color: #fff;
}

.access_manageProjectorButton__2JCod {
  border: 1px solid #6D6F76 !important;
  color: #6D6F76 !important;
  margin-bottom: 2% !important;
  text-transform: none !important;
  /* width: 15%; */
  min-width: 85px !important;
  max-width: 200px;
  z-index: 1000;
  cursor: pointer;
}

.access_bottomQuarterEcllipse__2eubs {
  max-height: 130px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
}

.access_ovalShape__3Cdho {
  position: relative;
  bottom: 30px;
  -webkit-filter: blur(50px);
          filter: blur(50px);
}

.access_profileContainer__3sb9L {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.access_profileContainerDnd__2D4X6 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.access_manageProjectorButton__2JCod:hover {
  background-color: #31343e !important;
}

.access_customRightArrow__2ymMa {
  /* right: 20% !important;
  top: calc(50% - 120px) !important; */
  top: 25% !important;
  position: absolute;
}

.access_customLeftArrow__3595j {
  /* left: 20% !important;
  top: calc(50% - 120px) !important; */
  top: 25% !important;
  position: absolute;
}

.access_customContainerClass__1YXh4 {
  position: unset !important;
}

.access_carouselWrapper__2ORFG {
  height: 300px;
}

@media only screen and (max-width: 464px) {
  .access_customContainerClass__1YXh4 {
    width: 200px !important;
  }
}

@media only screen and (min-width: 464px) and (max-width: 1024px) {
  .access_customContainerClass__1YXh4 {
    width: 400px !important;
  }

  .access_customArrowButtonGroup__2lvXv {
    top: 45%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1700px) {
  .access_customContainerClass__1YXh4 {
    width: 800px !important;
  }

  .access_profileItem__2W0mk{
    max-width: 200px;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 2652px) {
  .access_customContainerClass__1YXh4 {
    width: 1275px !important;
  }

  .access_profileItem__2W0mk{
    max-width: 250px;
  }
}

@media only screen and (min-width: 3000px) {
  .access_customContainerClass__1YXh4 {
   width: 2000px !important;
  }
}

.access_customArrowButtonGroup__2lvXv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 25%;
  width: 80%;
  left: 10%;
}

@media only screen and (max-width: 464px) {
  .access_customArrowButtonGroup__2lvXv {
    top: 45%;
  }

}


@media only screen and (min-width: 420px) and (max-width: 767px) {
  .access_customArrowButtonGroup__2lvXv {
    top: 36%;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .access_customArrowButtonGroup__2lvXv {
    top: 32%;
  }
}

.access_bottomName__2K3Py {
  margin-top: 15px;
  margin-bottom: 15px;
}

.access_customDotListClass__2bSld {
  margin-top: 10px;
  margin-bottom: 10px;
}

.access_profileLinksContainer__rLGFY {
  margin-top: 4%;
  margin-bottom: 4%;
}

.Input_inputLabel__1_SCZ {
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .Input_inputLabel__1_SCZ {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .Input_inputLabel__1_SCZ {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .Input_inputLabel__1_SCZ {
    font-size: 16px;
    margin-bottom: 13.59375px;
  }
  .Input_inputFieldContainer__3rByp,
  .Input_inputField__EnXSE {
    border-radius: 5px;
  }
  .Input_inputFieldContainer__3rByp {
    height: 45px;
  }
  .Input_inputFieldContainer__3rByp.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 45px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a:not(.Input_loading__13eV1) .Input_inputField__EnXSE {
    padding-right: 40px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 85px !important;
  }
  .Input_inputField__EnXSE {
    font-size: 13px;
  }
  .Input_inputField__EnXSE,
  .Input_passwordVisibilityToggle__2qYKf {
    padding: 0 11px;
  }
  .Input_passwordVisibilityToggleIcon__3kjZT {
    width: 18px;
  }
  .Input_inputMessage__1qxkK {
    font-size: 9px;
    margin-top: 11px;
  }
  .Input_spinnerContainer__RZeuj {
    margin-right: 10.78125px !important;
  }
  .Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
    height: 23.4375px !important;
    width: 23.4375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .Input_inputLabel__1_SCZ {
    font-size: 21.875px;
    margin-bottom: 18.125px;
  }
  .Input_inputFieldContainer__3rByp,
  .Input_inputField__EnXSE {
    border-radius: 6.25px;
  }
  .Input_inputFieldContainer__3rByp {
    height: 56.25px;
  }
  .Input_inputFieldContainer__3rByp.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 56.25px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a:not(.Input_loading__13eV1) .Input_inputField__EnXSE {
    padding-right: 60.625px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 116.875px !important;
  }
  .Input_inputField__EnXSE {
    font-size: 14.375000000000002px;
    padding-right: 56.25px !important;
  }
  .Input_inputField__EnXSE,
  .Input_passwordVisibilityToggle__2qYKf {
    padding: 0 21.25px;
  }
  .Input_passwordVisibilityToggleIcon__3kjZT {
    width: 18.125px;
  }
  .Input_inputMessage__1qxkK {
    font-size: 11.25px;
    margin-top: 14.375000000000002px;
  }
  .Input_spinnerContainer__RZeuj {
    margin-right: 12.5px !important;
  }
  .Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
    height: 31.25px !important;
    width: 31.25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .Input_inputLabel__1_SCZ {
    font-size: 26.25px;
    margin-bottom: 21.75px;
  }
  .Input_inputFieldContainer__3rByp,
  .Input_inputField__EnXSE {
    border-radius: 7.5px;
  }
  .Input_inputFieldContainer__3rByp {
    height: 67.5px;
  }
  .Input_inputFieldContainer__3rByp.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 67.5px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a:not(.Input_loading__13eV1) .Input_inputField__EnXSE {
    padding-right: 64.25px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 131.75px !important;
  }
  .Input_inputField__EnXSE {
    font-size: 17.25px;
    padding-right: 67.5px !important;
  }
  .Input_inputField__EnXSE,
  .Input_passwordVisibilityToggle__2qYKf {
    padding: 0 25.5px;
  }
  .Input_passwordVisibilityToggleIcon__3kjZT {
    width: 21.75px;
  }
  .Input_inputMessage__1qxkK {
    font-size: 13.5px;
    margin-top: 17.25px;
  }
  .Input_spinnerContainer__RZeuj {
    margin-right: 15px !important;
  }
  .Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
    height: 37.5px !important;
    width: 37.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .Input_inputLabel__1_SCZ {
    font-size: 31.171875000000004px;
    margin-bottom: 25.828125px;
  }
  .Input_inputFieldContainer__3rByp,
  .Input_inputField__EnXSE {
    border-radius: 8.90625px;
  }
  .Input_inputFieldContainer__3rByp {
    height: 80.15625px;
  }
  .Input_inputFieldContainer__3rByp.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 80.15625px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a:not(.Input_loading__13eV1) .Input_inputField__EnXSE {
    padding-right: 68.32812px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 148.48438px !important;
  }
  .Input_inputField__EnXSE {
    font-size: 20.484375px;
    padding-right: 80.15625px !important;
  }
  .Input_inputField__EnXSE,
  .Input_passwordVisibilityToggle__2qYKf {
    padding: 0 30.28125px;
  }
  .Input_passwordVisibilityToggleIcon__3kjZT {
    width: 25.828125px;
  }
  .Input_inputMessage__1qxkK {
    font-size: 16.03125px;
    margin-top: 20.484375px;
  }
  .Input_spinnerContainer__RZeuj {
    margin-right: 17.8125px !important;
  }
  .Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
    height: 44.53125px !important;
    width: 44.53125px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .Input_inputLabel__1_SCZ {
    font-size: 35px;
    margin-bottom: 29px;
  }
  .Input_inputFieldContainer__3rByp,
  .Input_inputField__EnXSE {
    border-radius: 10px;
  }
  .Input_inputFieldContainer__3rByp {
    height: 90px;
  }
  .Input_inputFieldContainer__3rByp.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 90px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a:not(.Input_loading__13eV1) .Input_inputField__EnXSE {
    padding-right: 71.5px !important;
  }
  .Input_inputFieldContainer__3rByp.Input_passwordInput__1Gm3a.Input_loading__13eV1 .Input_inputField__EnXSE {
    padding-right: 161.5px !important;
  }
  .Input_inputField__EnXSE {
    font-size: 23px;
    padding-right: 90px !important;
  }
  .Input_inputField__EnXSE,
  .Input_passwordVisibilityToggle__2qYKf {
    padding: 0 34px;
  }
  .Input_passwordVisibilityToggleIcon__3kjZT {
    width: 29px;
  }
  .Input_inputMessage__1qxkK {
    font-size: 18px;
    margin-top: 23px;
  }
  .Input_spinnerContainer__RZeuj {
    margin-right: 20px !important;
  }
  .Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
    height: 50px !important;
    width: 50px !important;
  }
}

.Input_inputFieldContainer__3rByp {
  align-items: center;
  background-color: #31343e;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.Input_inputField__EnXSE {
  background-color: transparent;
  border: 2px solid #31343e;
  color: #ffffff;
  flex-grow: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.Input_inputField__EnXSE:focus {
  border-color: #6d6f76;
}

.Input_inputField__EnXSE::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.Input_inputField__EnXSE:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.Input_inputField__EnXSE::placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.Input_passwordVisibilityToggleIcon__3kjZT {
  fill: #fff8 !important;
}

.Input_passwordVisibilityToggle__2qYKf {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media all and (hover: hover) {
  .Input_passwordVisibilityToggle__2qYKf:hover .Input_passwordVisibilityToggleIcon__3kjZT {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media all and (pointer: coarse) {
  .Input_passwordVisibilityToggle__2qYKf:active .Input_passwordVisibilityToggleIcon__3kjZT, .Input_passwordVisibilityToggle__2qYKf:target .Input_passwordVisibilityToggleIcon__3kjZT {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

.Input_inputMessage__1qxkK {
  color: #ffffff;
  display: block;
}

.Input_spinnerContainer__RZeuj {
  overflow: hidden;
  position: absolute !important;
  right: 0;
  transition-duration: 200ms;
}

.Input_spinnerContainer__RZeuj.Input_loading__13eV1 {
  opacity: 1;
}

.Input_spinnerContainer__RZeuj:not(.Input_loading__13eV1) {
  height: 0 !important;
  opacity: 0;
  width: 0 !important;
}

.Input_spinner__9JN-x {
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  height: calc(100% - 1px) !important;
  position: absolute !important;
  width: calc(100% - 1px) !important;
}

.Input_spinner__9JN-x:not(.Input_animatedSpinner__2Sm8o) {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Input_animatedSpinner__2Sm8o {
  -webkit-animation-duration: 550ms;
          animation-duration: 550ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: Input_animated-spinner__1NDt9;
          animation-name: Input_animated-spinner__1NDt9;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-left-color: transparent !important;
}

@-webkit-keyframes Input_animated-spinner__1NDt9 {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}

@keyframes Input_animated-spinner__1NDt9 {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}

.styles_hiddenFormField__2Hhgl {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.styles_accountDataContainer__3suwu {
  align-items: center;
  display: flex;
  width: 100%;
}

.styles_accountData__2GT3S {
  flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .styles_accountData__2GT3S {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .styles_accountData__2GT3S {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .styles_accountData__2GT3S {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .styles_accountData__2GT3S {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .styles_accountData__2GT3S {
    font-size: 20px;
  }
}

.styles_actionButton__WyKgI {
  color: #000 !important;
}

.styles_inputFieldContainer__2RfHQ {
  background-color: transparent !important;
}

.styles_inputFieldContainer__2RfHQ img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.styles_inputField__20aJS {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.styles_inputFieldLabel__3TfPt {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.styles_passwordStrengthBar__3FMP4 {
  background-color: #ddd;
}

.styles_passwordStrengthNotes__2cOGH {
  color: #444;
}

.styles_avatarEditButton__3U1t5 {
  color: #5aa5ef;
  font-weight: 600;
}

.styles_accountDataAction__3SQX1 {
  color: rgba(2, 7, 26, 0.4);
}

.styles_avatarEditButton__3U1t5,
.styles_accountDataAction__3SQX1 {
  cursor: pointer;
  flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .styles_avatarEditButton__3U1t5:hover,
  .styles_accountDataAction__3SQX1:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .styles_avatarEditButton__3U1t5:active, .styles_avatarEditButton__3U1t5:target,
  .styles_accountDataAction__3SQX1:active,
  .styles_accountDataAction__3SQX1:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .styles_avatarEditButton__3U1t5,
  .styles_accountDataAction__3SQX1 {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .styles_avatarEditButton__3U1t5,
  .styles_accountDataAction__3SQX1 {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .styles_avatarEditButton__3U1t5,
  .styles_accountDataAction__3SQX1 {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .styles_avatarEditButton__3U1t5,
  .styles_accountDataAction__3SQX1 {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .styles_avatarEditButton__3U1t5,
  .styles_accountDataAction__3SQX1 {
    font-size: 15px;
    margin-left: 30px;
  }
}

.styles_profilePicture__26sZ2 {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .styles_profilePicture__26sZ2 {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .styles_profilePicture__26sZ2 {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .styles_profilePicture__26sZ2 {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .styles_profilePicture__26sZ2 {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .styles_profilePicture__26sZ2 {
    height: 98px;
    width: 98px;
  }
}

.styles_profilePictureIcon__MZce7 {
  height: 50%;
  width: 50%;
}

.auth_hiddenFormField__1Q8hi {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}
.auth_conditons__2zZTc > p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: left;
  font-size: 16px;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: 'Montserrat' !important;
  font-weight: 300;
}
.auth_conditons__2zZTc{
  margin-top: 16px;
}
@media only screen and (max-width: 767px) {
  .auth_logo__388pU {
    margin-bottom: 44px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .auth_logo__388pU {
    margin-bottom: 64px;
    margin-top: 24px;
  }
}

.auth_authPage__hstme {
  align-items: center;
  background: radial-gradient(circle at center, #1f2330, #07080c);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.auth_authPage__hstme,
.auth_authPage__hstme * {
  font-family: 'Montserrat' !important;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPage__hstme {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPage__hstme {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPage__hstme {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPage__hstme {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPage__hstme {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPage__hstme {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPage__hstme {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPage__hstme {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPage__hstme {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPage__hstme {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPage__hstme {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPage__hstme {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPage__hstme {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPage__hstme {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPage__hstme {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPage__hstme {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPage__hstme {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPage__hstme {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPage__hstme {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPage__hstme {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPage__hstme {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPage__hstme {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPage__hstme {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPage__hstme {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPage__hstme {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPage__hstme {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPage__hstme {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPage__hstme {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPage__hstme {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPage__hstme {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPage__hstme {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPage__hstme {
      padding-bottom: 40px;
    }
  }
}

.auth_authPageContent__pxqo_ {
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(0, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .auth_authPageContent__pxqo_ {
      padding-right: 40px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .auth_authPageContent__pxqo_ {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .auth_authPageContent__pxqo_ {
    max-width: 650px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_authPageContent__pxqo_ {
    max-width: 700px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_authPageContent__pxqo_ {
    max-width: 750px;
  }
}

@media only screen and (max-width: 1199px) {
  .auth_suggestionText__2g-P3,
  .auth_suggestionLink__22DrE {
    font-size: 13px;
    margin-top: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .auth_suggestionText__2g-P3,
  .auth_suggestionLink__22DrE {
    font-size: 13.5px;
    margin-top: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_suggestionText__2g-P3,
  .auth_suggestionLink__22DrE {
    font-size: 16.03125px;
    margin-top: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_suggestionText__2g-P3,
  .auth_suggestionLink__22DrE {
    font-size: 18px;
    margin-top: 18px;
  }
}

.auth_suggestionText__2g-P3 {
  color: #ddd;
}

.auth_suggestionLink__22DrE {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

@media all and (hover: hover) {
  .auth_suggestionLink__22DrE:hover {
    color: #fff !important;
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .auth_suggestionLink__22DrE:active, .auth_suggestionLink__22DrE:target {
    color: #fff !important;
    text-decoration: underline;
  }
}

.auth_recommendationText__tS1gK {
  color: #ccc;
  margin-top: 1em;
  font-weight: 500;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .auth_recommendationText__tS1gK {
    font-size: 15px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .auth_recommendationText__tS1gK {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .auth_recommendationText__tS1gK {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_recommendationText__tS1gK {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_recommendationText__tS1gK {
    font-size: 28px;
  }
}

.auth_passwordStrengthBar__1gFBK {
  background-color: #3d3f47;
  border-radius: 8px;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .auth_passwordStrengthBar__1gFBK {
    height: 4px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .auth_passwordStrengthBar__1gFBK {
    height: 8px !important;
    margin-top: 15.9375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .auth_passwordStrengthBar__1gFBK {
    height: 8px;
    margin-top: 19.125px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_passwordStrengthBar__1gFBK {
    height: 8.90625px;
    margin-top: 22.7109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_passwordStrengthBar__1gFBK {
    height: 10px;
    margin-top: 25.5px;
  }
}

.auth_passwordStrengthBarContent__2OYhs {
  border-radius: 8px;
  height: 100%;
  transition-duration: 200ms;
}

.auth_passwordStrengthBarContent__2OYhs.auth_weak__3Bn75 {
  background-color: #ff0000;
}

.auth_passwordStrengthBarContent__2OYhs.auth_medium__3G7K3 {
  background-color: #5aa5ef;
}

.auth_passwordStrengthBarContent__2OYhs.auth_strong__3xM4q {
  background-color: #5aa5ef;
}

.auth_passwordStrengthNotes__1ZooP {
  color: #ddd;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .auth_passwordStrengthNotes__1ZooP {
    font-size: 9px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .auth_passwordStrengthNotes__1ZooP {
    font-size: 11.25px !important;
    margin-top: 15.9375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .auth_passwordStrengthNotes__1ZooP {
    font-size: 13.5px;
    margin-top: 19.125px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_passwordStrengthNotes__1ZooP {
    font-size: 16.03125px;
    margin-top: 22.7109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_passwordStrengthNotes__1ZooP {
    font-size: 18px;
    margin-top: 25.5px;
  }
}

.auth_currentPasswordStrength__2ucnn {
  display: none;
  margin-left: 2%;
  text-transform: capitalize;
}

.auth_currentPasswordStrength__2ucnn.auth_weak__3Bn75, .auth_currentPasswordStrength__2ucnn.auth_medium__3G7K3, .auth_currentPasswordStrength__2ucnn.auth_strong__3xM4q {
  display: inline-block !important;
}

.auth_currentPasswordStrength__2ucnn.auth_weak__3Bn75 {
  color: #ff0000;
}

.auth_currentPasswordStrength__2ucnn.auth_medium__3G7K3 {
  color: #5aa5ef;
}

.auth_currentPasswordStrength__2ucnn.auth_strong__3xM4q {
  color: #5aa5ef;
}

.auth_backText__19LRM {
  vertical-align: middle;
  font-size: 28px;
  padding: 15px;
  color: #fff;
  font-family: 500;
  cursor: pointer;
}

.auth_videoBackButton__1mSiw {
  cursor: pointer;
  margin-left: -14px;
  position: relative;
  vertical-align: middle;
  width: 50px;
}

.auth_backButtonBlock__2niAQ {
  width: 100%;
  margin-left: 4%;
  position: fixed;
  top: 4%;
}

@media only screen and (max-width: 419px) {
  .auth_backText__19LRM {
    font-size: 17px;
    padding: 8px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: 2px;
    width: 27px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .auth_backText__19LRM {
    font-size: 16px;
    padding: 4px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: 4px;
    width: 27px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .auth_backText__19LRM {
    font-size: 22px;
    padding: 9px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: -4px;
    width: 37px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .auth_backText__19LRM {
    font-size: 23px;
    padding: 10px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: -5px;
    width: 40px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .auth_backText__19LRM {
    font-size: 25px;
    padding: 15px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: -6px;
    width: 45px;
  }
}

@media only screen and (min-width: 1710px) {
  .auth_backText__19LRM {
    font-size: 28px;
    padding: 15px;
  }
  .auth_videoBackButton__1mSiw {
    margin-left: -14px;
    width: 50px;
  }
}
.page_settingsPage__2wJEr {
  display: flex;
  min-height: calc(100vh - var(--header-height));
}

.page_pageContent__1mPw0 {
  background-color: #fff;
  padding-left: 100px;
}

.page_contentWrapper__11FdL {
  display: block;
  flex-grow: 1;
}

@media only screen and (max-width: 769px) {
  .page_contentWrapper__11FdL {
    padding: 32px 16px;
  }
  .page_pageContent__1mPw0 {
    background-color: #fff;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .page_contentWrapper__11FdL {
    padding: 58.5px 36.5625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_contentWrapper__11FdL {
    padding: 78px 48.75px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_contentWrapper__11FdL {
    padding: 93.6px 58.5px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_contentWrapper__11FdL {
    padding: 111.15px 69.46875px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

@media only screen and (min-width: 1710px) {
  .page_contentWrapper__11FdL {
    padding: 124.8px 78px;
    max-height: calc(100vh - var(--header-height));
    overflow-x: auto;
  }
}

.page_pageTitle__2d7Lk {
  color: #02071a;
  font-weight: 600;
  line-height: 1;
}

@media only screen and (max-width: 899px) {
  .page_pageTitle__2d7Lk {
    font-size: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageTitle__2d7Lk {
    font-size: 21.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageTitle__2d7Lk {
    font-size: 26.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageTitle__2d7Lk {
    font-size: 31.171875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageTitle__2d7Lk {
    font-size: 35px;
  }
}

.page_pageDescription__2AHug {
  color: #02071a;
  font-weight: 300;
  opacity: 0.32;
}

@media only screen and (max-width: 899px) {
  .page_pageDescription__2AHug {
    font-size: 17px;
    line-height: 1.3;
    margin: 8px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageDescription__2AHug {
    font-size: 27.499999999999996px;
    margin: 23.125px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageDescription__2AHug {
    font-size: 33px;
    margin: 27.75px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageDescription__2AHug {
    font-size: 39.1875px;
    margin: 32.953125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageDescription__2AHug {
    font-size: 44px;
    margin: 0;
  }
}

.page_pageDivider__vES3w {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.18;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .page_pageDivider__vES3w {
    margin-top: 22.2px;
    margin-bottom: 29.6px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageDivider__vES3w {
    margin-bottom: 34.6875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageDivider__vES3w {
    margin-bottom: 41.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageDivider__vES3w {
    margin-bottom: 49.42969px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageDivider__vES3w {
    margin-bottom: 55.5px;
  }
}

.page_sidebar__1tb3m {
  background-color: #f0f0f0;
  display: block;
  min-height: calc(100vh - var(--header-height));
}

@media only screen and (max-width: 899px) {
  .page_sidebar__1tb3m {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_sidebar__1tb3m {
    width: 192.50000000000003px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_sidebar__1tb3m {
    width: 231.00000000000003px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_sidebar__1tb3m {
    width: 274.3125px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_sidebar__1tb3m {
    width: 308px;
  }
}

.page_sidebarContent__PvU7n {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-height);
}

.page_sidebarLink__3gJyY {
  align-items: center;
  color: #02071a;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-family: 'Montserrat';
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_sidebarLink__3gJyY {
    font-size: 12.5px;
    height: 68.75px;
    margin-top: 26.875px;
    padding: 0 53.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_sidebarLink__3gJyY {
    font-size: 15px;
    height: 82.5px;
    margin-top: 32.25px;
    padding: 0 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_sidebarLink__3gJyY {
    font-size: 17.8125px;
    height: 97.96875px;
    margin-top: 38.29688px;
    padding: 0 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_sidebarLink__3gJyY {
    font-size: 20px;
    height: 110px;
    margin-top: 43px;
    padding: 0 86px;
  }
}

.page_currentSidebarLink__18hW4 {
  cursor: pointer;
}

.page_currentSidebarLink__18hW4::before, .page_currentSidebarLink__18hW4::after {
  content: ' ';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
}

.page_currentSidebarLink__18hW4::before {
  background-color: #5aa5ef;
  left: 0;
  opacity: 0.08;
  width: 100%;
}

.page_currentSidebarLink__18hW4::after {
  background-color: #5aa5ef;
  right: 0;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_currentSidebarLink__18hW4::after {
    width: 4.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_currentSidebarLink__18hW4::after {
    width: 5.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_currentSidebarLink__18hW4::after {
    width: 6.234375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_currentSidebarLink__18hW4::after {
    width: 7px;
  }
}
.page_headerTheme__1s5jB {
  background-color: black;
}

@media only screen and (max-width: 899px) {
  .data-view_dataView__1gj1J,
  .data-view_dataRow__2gZTv,
  .data-view_dataCell__3jgGH {
    display: block;
  }
  .data-view_dataRow__2gZTv {
    margin-bottom: 24px;
  }
  .data-view_dataRow__2gZTv.data-view_isFlex__NSVnT {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
  }
  .data-view_labelCell__2LPYq {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 900px) {
  .data-view_dataView__1gj1J {
    border-collapse: collapse;
    display: table;
    width: 100%;
  }
  .data-view_dataRow__2gZTv {
    display: table-row;
  }
  .data-view_dataCell__3jgGH {
    display: table-cell;
    vertical-align: top;
  }
  .data-view_labelCell__2LPYq {
    border-right: 1px solid rgba(112, 112, 112, 0.12);
    width: 40%;
  }
}

.data-view_labelTitle__iPSN1 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.data-view_labelTitleText__1f3Lp {
  font-weight: 600;
  line-height: 1.25;
  display: flex;
  align-items: center;
}

.data-view_labelDescription__3T9M6 {
  font-family: Montserrat, sans-serif;
  color: #02071a;
  line-height: 1.75;
}

.data-view_labelTitleIcon__NdnQT {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .data-view_dataCell__3jgGH.data-view_isToggle__3dftI {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 15px;
  }
  .data-view_labelTitleText__1f3Lp {
    font-size: 16px;
  }
  .data-view_labelDescription__3T9M6 {
    font-size: 13px;
  }
  .data-view_labelTitleIcon__NdnQT {
    width: 48.1125px;
  }
  .data-view_labelTitleAddIcon__38ix8 {
    height: 24.056250000000002px;
    width: 24.056250000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .data-view_dataCell__3jgGH {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .data-view_dataCell__3jgGH.data-view_isToggle__3dftI {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 18.125px;
  }
  .data-view_labelDescription__3T9M6 {
    font-size: 11.875px;
  }
  .data-view_labelTitleText__1f3Lp {
    font-size: 18.125px;
  }
  .data-view_labelTitleIcon__NdnQT {
    width: 64.15px;
  }
  .data-view_labelTitleAddIcon__38ix8 {
    height: 32.075px;
    width: 32.075px;
  }
  .data-view_dataCell__3jgGH:nth-of-type(2) {
    padding-left: 32.075px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .data-view_dataCell__3jgGH {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .data-view_dataCell__3jgGH.data-view_isToggle__3dftI {
    --toggle-padding-top: (36px / 2);
    --toggle-height: 21.75px;
  }
  .data-view_labelDescription__3T9M6 {
    font-size: 14.25px;
  }
  .data-view_labelTitleText__1f3Lp {
    font-size: 21.75px;
  }
  .data-view_labelTitleIcon__NdnQT {
    width: 76.98px;
  }
  .data-view_labelTitleAddIcon__38ix8 {
    height: 38.49px;
    width: 38.49px;
  }
  .data-view_dataCell__3jgGH:nth-of-type(2) {
    padding-left: 38.49px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .data-view_dataCell__3jgGH {
    padding-bottom: 21.375px;
    padding-top: 21.375px;
  }
  .data-view_dataCell__3jgGH.data-view_isToggle__3dftI {
    --toggle-padding-top: (42.75px / 2);
    --toggle-height: 25.828125px;
  }
  .data-view_labelDescription__3T9M6 {
    font-size: 16.921875px;
  }
  .data-view_labelTitleText__1f3Lp {
    font-size: 25.828125px;
  }
  .data-view_labelTitleIcon__NdnQT {
    width: 91.41375px;
  }
  .data-view_labelTitleAddIcon__38ix8 {
    height: 45.706875000000004px;
    width: 45.706875000000004px;
  }
  .data-view_dataCell__3jgGH:nth-of-type(2) {
    padding-left: 45.706875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .data-view_dataCell__3jgGH {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .data-view_dataCell__3jgGH.data-view_isToggle__3dftI {
    --toggle-padding-top: (48px / 2);
    --toggle-height: 29px;
  }
  .data-view_labelDescription__3T9M6 {
    font-size: 19px;
  }
  .data-view_labelTitleText__1f3Lp {
    font-size: 29px;
  }
  .data-view_labelTitleIcon__NdnQT {
    width: 102.62px;
  }
  .data-view_labelTitleAddIcon__38ix8 {
    height: 51.32px;
    width: 51.31px;
  }
  .data-view_dataCell__3jgGH:nth-of-type(2) {
    padding-left: 51.31px;
  }
}

.data-view_labelTitleAddIcon__38ix8 {
  cursor: pointer;
}

.input-field_inputLabel__2UpCP {
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
  width: 100%;
}
.input-field_errrMessage__BO92z{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
@media only screen and (max-width: 767px) {
  .input-field_inputLabel__2UpCP {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .input-field_inputLabel__2UpCP {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .input-field_inputLabel__2UpCP {
    font-size: 16px;
    margin-bottom: 13.59375px;
  }
  .input-field_inputFieldContainer__2HAHx,
  .input-field_inputField__3cdaW {
    border-radius: 5px;
  }
  .input-field_inputFieldContainer__2HAHx {
    height: 45px;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 45px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9:not(.input-field_loading__3LLHr) .input-field_inputField__3cdaW {
    padding-right: 40px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 85px !important;
  }
  .input-field_inputField__3cdaW {
    font-size: 16px !important;
  }
  .input-field_inputField__3cdaW,
  .input-field_passwordVisibilityToggle__2y0Om {
    padding: 0 11px;
  }
  .input-field_passwordVisibilityToggleIcon__3H0Lz {
    width: 18px;
  }
  .input-field_signupPasswordVisibilityToggleIcon__2tvQo {
    width: 18px;
  }
  .input-field_inputMessage__1Yolk {
    font-size: 9px;
    margin-top: 11px;
  }
  .input-field_spinnerContainer__1yHLw {
    margin-right: 10.78125px !important;
  }
  .input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
    height: 23.4375px !important;
    width: 23.4375px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .input-field_inputLabel__2UpCP {
    font-size: 21.875px;
    margin-bottom: 18.125px;
  }
  .input-field_inputFieldContainer__2HAHx,
  .input-field_inputField__3cdaW {
    border-radius: 6.25px;
  }
  .input-field_inputFieldContainer__2HAHx {
    height: 56.25px;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 56.25px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9:not(.input-field_loading__3LLHr) .input-field_inputField__3cdaW {
    padding-right: 60.625px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 116.875px !important;
  }
  .input-field_inputField__3cdaW {
    font-size: 14.375000000000002px;
    padding-right: 56.25px !important;
  }
  .input-field_inputField__3cdaW,
  .input-field_passwordVisibilityToggle__2y0Om {
    padding: 0 21.25px;
  }
  .input-field_passwordVisibilityToggleIcon__3H0Lz {
    width: 18.125px;
  }
  .input-field_signupPasswordVisibilityToggleIcon__2tvQo {
    width: 18.125px;
  }
  .input-field_inputMessage__1Yolk {
    font-size: 11.25px;
    margin-top: 14.375000000000002px;
  }
  .input-field_spinnerContainer__1yHLw {
    margin-right: 12.5px !important;
  }
  .input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
    height: 31.25px !important;
    width: 31.25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .input-field_inputLabel__2UpCP {
    font-size: 26.25px;
    margin-bottom: 21.75px;
  }
  .input-field_inputFieldContainer__2HAHx,
  .input-field_inputField__3cdaW {
    border-radius: 7.5px;
  }
  .input-field_inputFieldContainer__2HAHx {
    height: 67.5px;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 67.5px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9:not(.input-field_loading__3LLHr) .input-field_inputField__3cdaW {
    padding-right: 64.25px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 131.75px !important;
  }
  .input-field_inputField__3cdaW {
    font-size: 17.25px;
    padding-right: 67.5px !important;
  }
  .input-field_inputField__3cdaW,
  .input-field_passwordVisibilityToggle__2y0Om {
    padding: 0 25.5px;
  }
  .input-field_passwordVisibilityToggleIcon__3H0Lz {
    width: 21.75px;
  }
  .input-field_signupPasswordVisibilityToggleIcon__2tvQo {
    width: 21.75px;
  }
  .input-field_inputMessage__1Yolk {
    font-size: 13.5px;
    margin-top: 17.25px;
  }
  .input-field_spinnerContainer__1yHLw {
    margin-right: 15px !important;
  }
  .input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
    height: 37.5px !important;
    width: 37.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .input-field_inputLabel__2UpCP {
    font-size: 31.171875000000004px;
    margin-bottom: 25.828125px;
  }
  .input-field_inputFieldContainer__2HAHx,
  .input-field_inputField__3cdaW {
    border-radius: 8.90625px;
  }
  .input-field_inputFieldContainer__2HAHx {
    height: 80.15625px;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 80.15625px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9:not(.input-field_loading__3LLHr) .input-field_inputField__3cdaW {
    padding-right: 68.32812px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 148.48438px !important;
  }
  .input-field_inputField__3cdaW {
    font-size: 20.484375px;
    padding-right: 80.15625px !important;
  }
  .input-field_inputField__3cdaW,
  .input-field_passwordVisibilityToggle__2y0Om {
    padding: 0 30.28125px;
  }
  .input-field_passwordVisibilityToggleIcon__3H0Lz {
    width: 25.828125px;
  }
  .input-field_signupPasswordVisibilityToggleIcon__2tvQo {
    width: 25.828125px;
  }
  .input-field_inputMessage__1Yolk {
    font-size: 16.03125px;
    margin-top: 20.484375px;
  }
  .input-field_spinnerContainer__1yHLw {
    margin-right: 17.8125px !important;
  }
  .input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
    height: 44.53125px !important;
    width: 44.53125px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .input-field_inputLabel__2UpCP {
    font-size: 35px;
    margin-bottom: 29px;
  }
  .input-field_inputFieldContainer__2HAHx,
  .input-field_inputField__3cdaW {
    border-radius: 10px;
  }
  .input-field_inputFieldContainer__2HAHx {
    height: 90px;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 90px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9:not(.input-field_loading__3LLHr) .input-field_inputField__3cdaW {
    padding-right: 71.5px !important;
  }
  .input-field_inputFieldContainer__2HAHx.input-field_passwordInput__3R6P9.input-field_loading__3LLHr .input-field_inputField__3cdaW {
    padding-right: 161.5px !important;
  }
  .input-field_inputField__3cdaW {
    font-size: 23px;
    padding-right: 90px !important;
  }
  .input-field_inputField__3cdaW,
  .input-field_passwordVisibilityToggle__2y0Om {
    padding: 0 34px;
  }
  .input-field_passwordVisibilityToggleIcon__3H0Lz {
    width: 29px;
  }
  .input-field_inputMessage__1Yolk {
    font-size: 18px;
    margin-top: 23px;
  }
  .input-field_spinnerContainer__1yHLw {
    margin-right: 20px !important;
  }
  .input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
    height: 50px !important;
    width: 50px !important;
  }
}

.input-field_inputFieldContainer__2HAHx {
  align-items: center;
  background-color: #31343e;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  position: relative;
  width: 100%;
}

.input-field_inputField__3cdaW {
  background-color: transparent;
  border: 2px solid #31343e;
  color: #ffffff;
  flex-grow: 1;
  height: 100%;
  position: absolute;
  width: 100%;
}

.input-field_inputField__3cdaW:focus {
  border-color: #6d6f76;
}

.input-field_inputField__3cdaW::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.input-field_inputField__3cdaW:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.input-field_inputField__3cdaW::placeholder {
  color: #8e8e8e;
  line-height: 61px;
}

.input-field_passwordVisibilityToggleIcon__3H0Lz {
  fill: #fff8 !important;
}

.input-field_signupPasswordVisibilityToggleIcon__2tvQo {
  fill: #fff8 !important;
}

.input-field_passwordVisibilityToggle__2y0Om {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media all and (hover: hover) {
  .input-field_passwordVisibilityToggle__2y0Om:hover .input-field_passwordVisibilityToggleIcon__3H0Lz {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media all and (pointer: coarse) {
  .input-field_passwordVisibilityToggle__2y0Om:active .input-field_passwordVisibilityToggleIcon__3H0Lz, .input-field_passwordVisibilityToggle__2y0Om:target .input-field_passwordVisibilityToggleIcon__3H0Lz {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

.input-field_inputMessage__1Yolk {
  color: #ffffff;
  display: block;
}

.input-field_spinnerContainer__1yHLw {
  overflow: hidden;
  position: absolute !important;
  right: 0;
  transition-duration: 200ms;
}

.input-field_spinnerContainer__1yHLw.input-field_loading__3LLHr {
  opacity: 1;
}

.input-field_spinnerContainer__1yHLw:not(.input-field_loading__3LLHr) {
  height: 0 !important;
  opacity: 0;
  width: 0 !important;
}

.input-field_spinner__2PSLl {
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  height: calc(100% - 1px) !important;
  position: absolute !important;
  width: calc(100% - 1px) !important;
}

.input-field_spinner__2PSLl:not(.input-field_animatedSpinner__1fnM7) {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.input-field_animatedSpinner__1fnM7 {
  -webkit-animation-duration: 550ms;
          animation-duration: 550ms;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: input-field_animated-spinner__2dlG8;
          animation-name: input-field_animated-spinner__2dlG8;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  border-left-color: transparent !important;
}

@-webkit-keyframes input-field_animated-spinner__2dlG8 {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}

@keyframes input-field_animated-spinner__2dlG8 {
  from {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(359deg);
            transform: rotateZ(359deg);
  }
}

.account_hiddenFormField__1n3sI {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.account_accountDataContainer__2UTYs {
  align-items: center;
  display: flex;
  width: 100%;
}

.account_accountData__1MlX8 {
  flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .account_accountData__1MlX8 {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .account_accountData__1MlX8 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .account_accountData__1MlX8 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .account_accountData__1MlX8 {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .account_accountData__1MlX8 {
    font-size: 20px;
  }
}

.account_actionButton__2H1J8 {
  color: #000 !important;
}

.account_inputFieldContainer__20aov {
  background-color: transparent !important;
}

.account_inputFieldContainer__20aov img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.account_inputField__3bVa5 {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.account_inputFieldLabel__3pD_v {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.account_passwordStrengthBar__2g3g2 {
  background-color: #ddd;
}

.account_passwordStrengthNotes__gH-Q8 {
  color: #444;
}

.account_avatarEditButton__1pEau {
  color: #5aa5ef;
  font-weight: 600;
  position: relative;
  top: 38px;
}

.account_profileImageUploadForm__3S3Kr {
  justify-content: space-between;
  display: flex;
}

.account_accountDataAction__312vs {
  color: rgba(2, 7, 26, 0.4);
}

.account_avatarEditButton__1pEau,
.account_accountDataAction__312vs {
  cursor: pointer;
  flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .account_avatarEditButton__1pEau:hover,
  .account_accountDataAction__312vs:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .account_avatarEditButton__1pEau:active, .account_avatarEditButton__1pEau:target,
  .account_accountDataAction__312vs:active,
  .account_accountDataAction__312vs:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .account_avatarEditButton__1pEau,
  .account_accountDataAction__312vs {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .account_avatarEditButton__1pEau,
  .account_accountDataAction__312vs {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .account_avatarEditButton__1pEau,
  .account_accountDataAction__312vs {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .account_avatarEditButton__1pEau,
  .account_accountDataAction__312vs {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .account_avatarEditButton__1pEau,
  .account_accountDataAction__312vs {
    font-size: 15px;
    margin-left: 30px;
  }
}

.account_profilePicture__2lVIY {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .account_profilePicture__2lVIY {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .account_profilePicture__2lVIY {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .account_profilePicture__2lVIY {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .account_profilePicture__2lVIY {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .account_profilePicture__2lVIY {
    height: 98px;
    width: 98px;
  }
}

.account_profilePictureIcon__2IbIw {
  height: 50%;
  width: 50%;
}

.account_switch__3KWDv {
  border: none;
  border-radius: var(--toggle-height);
  cursor: pointer;
  height: var(--toggle-height);
  margin: 0;
  position: relative;
  transition-duration: 200ms;
  width: calc(2.5 * var(--toggle-height));
}

.account_button__L73qy {
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  height: calc(1.5 * var(--toggle-height));
  position: absolute;
  top: calc(-0.25 * var(--toggle-height));
  transition-duration: 200ms;
  width: calc(1.5 * var(--toggle-height));
}

.account_button__L73qy:not(.account_isOn__2vIU-) {
  left: calc(-0.25 * var(--toggle-height));
}

.account_button__L73qy.account_isOn__2vIU- {
  left: calc(1.25 * var(--toggle-height));
}


.manage-payment-methods_main_page__2nwMV {
  font-size: inherit;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.manage-payment-methods_redColorExpired__2B2Wo {
  color: #F80808;
  font-weight: 500;
  font-size: 12px;
}
.manage-payment-methods_headings__1rgDP {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}
.manage-payment-methods_main_container__N6LIv {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /* background-color: blue; */
}
.manage-payment-methods_cardContainer__3KGvZ {
  width: 95%;
  max-width: 800px;
  padding: 1rem;
  /* background-color: red; */
}
.manage-payment-methods_cardDetails__1iqwL {
  padding: 1rem 0;
}
.manage-payment-methods_primary_heading__2G0o2 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.5rem 0;
}
.manage-payment-methods_secondary_heading__2TOzF {
  font-size: 1rem;
  font-weight: 500;
}
.manage-payment-methods_button_container__2NgMZ {
  display: flex;
  justify-content: center;
}
.manage-payment-methods_button_container__2NgMZ > button {
  font-size: 1rem;
  /* width: 45%; */
  padding: 0.5rem 1.2rem;
  outline: none;
  border: #ff0000 2px solid;
  color: #ff0000;
  background-color: #ffffff;
  /* width: 100%; */

  margin: 0.5rem 0;
  font-weight: 500;
  cursor: pointer;
}

.manage-payment-methods_button_container__2NgMZ > button:hover {
  background-color: #ff0000;
  color: #ffffff;
}
.manage-payment-methods_labelLinks__1Ufgt {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.manage-payment-methods_link__4Ds7S {
  color: #1172d2;
  font-weight: 500;
  cursor: pointer;
}
.manage-payment-methods_firstLabel__1QDYr,
.manage-payment-methods_colorBlack__1n_2_ {
  font-weight: 700;
  color: #02071a;
}
.manage-payment-methods_secondLabel__3Loq8 {
  color: #757575;
}
.manage-payment-methods_preferred__3KQMh {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #02071a;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 0.6rem;
  border: 1px #707070 solid;
  border-radius: 6px;
  padding: 3px 0.8rem;
  cursor: pointer;
  height: -webkit-max-content;
  height: max-content;
}

.manage-payment-methods_preferred__3KQMh:hover {
  background-color: #757575;
}
.manage-payment-methods_message__1f--V {
  font-weight: 500;
  padding: 1rem 0;
  font-size: 0.8rem;
}
.manage-payment-methods_editButton__W7ZEC {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 1rem;
  cursor: pointer;
  font-weight: 600;
  color: black;
}
.manage-payment-methods_editButton__W7ZEC:hover:not(:last-child, :disabled) {
  color: #1172d2;
}
.manage-payment-methods_editButton__W7ZEC:hover:not(:first-child) {
  color: #ff0000;
}
.manage-payment-methods_editButtonsMenu__cJkbi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.manage-payment-methods_headerTheme__2BBfV {
  background-color: black;
}

.manage-payment-methods_dropDownAnimation__1phFw  {
  -webkit-animation: manage-payment-methods_growOut__1Ly8V 700ms ease-in-out forwards;
          animation: manage-payment-methods_growOut__1Ly8V 700ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center
}

@-webkit-keyframes manage-payment-methods_growOut__1Ly8V {
  0% {
     -webkit-transform: scale(0);
             transform: scale(0)
 }
 80% {
     -webkit-transform: scale(1.1);
             transform: scale(1.1)
 }
 100% {
     -webkit-transform: scale(1);
             transform: scale(1)
 }
}

@keyframes manage-payment-methods_growOut__1Ly8V {
  0% {
     -webkit-transform: scale(0);
             transform: scale(0)
 }
 80% {
     -webkit-transform: scale(1.1);
             transform: scale(1.1)
 }
 100% {
     -webkit-transform: scale(1);
             transform: scale(1)
 }
}

.manage-payment-methods_secondLink__10vME:hover {
  color: #000;
}
.page-layout_header__Kl1VJ {
  align-items: center;
  background-color: #000;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .page-layout_header__Kl1VJ {
    height: 66.25px;
    padding: 0 calc(66.25px / 3);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_header__Kl1VJ {
    height: 79.5px;
    padding: 0 calc(79.5px / 3);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_header__Kl1VJ {
    height: 94.40625px;
    padding: 0 calc(94.40625px / 3);
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_header__Kl1VJ {
    height: 106px;
    padding: 0 calc(106px / 3);
  }
}

.page-layout_logo__1pgZo {
  height: 70%;
  position: relative;
}

.page-layout_helpCenterButton__HgK-z {
  color: #667583;
  font: normal normal normal 15px/21px Poppins;
}

.page-layout_page__3N1HQ {
  margin: 0 auto;
  max-width: 1920px;
}

@media only screen and (max-width: 419px) {
  .page-layout_page__3N1HQ {
    padding: 32px 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 899px) {
  .page-layout_page__3N1HQ {
    padding: 26px 32px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page-layout_page__3N1HQ {
    padding: 44px 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page-layout_page__3N1HQ {
    padding: 62.25px 95.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_page__3N1HQ {
    padding: 73.921875px 113.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_page__3N1HQ {
    padding: 83px 127px;
  }
}

.page-layout_title__zbFyP {
  color: #1c1f2b;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .page-layout_title__zbFyP {
    font-size: 24.375px;
    line-height: 22.03125px;
    margin-bottom: 6.874999999999999px;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_title__zbFyP {
    font-size: 29.25px;
    line-height: 29.375px;
    margin-bottom: 8.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_title__zbFyP {
    font-size: 34.734375px;
    line-height: 35.25px;
    margin-bottom: 9.796875px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_title__zbFyP {
    font-size: 39px;
    line-height: 47px;
    margin-bottom: 11px;
  }
}

.page-layout_subTitle__2r1oM,
.page-layout_terms__3Aoaa {
  color: #1c1f2b;
  font-weight: 300;
  margin: 0 !important;
}

@media only screen and (max-width: 899px) {
  .page-layout_subTitle__2r1oM,
  .page-layout_terms__3Aoaa {
    font-size: 11.875px;
    line-height: 16.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_subTitle__2r1oM,
  .page-layout_terms__3Aoaa {
    font-size: 14.25px;
    line-height: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_subTitle__2r1oM,
  .page-layout_terms__3Aoaa {
    font-size: 16.921875px;
    line-height: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_subTitle__2r1oM,
  .page-layout_terms__3Aoaa {
    font-size: 19px;
    line-height: 26px;
  }
}

.page-layout_termsContainer__r292p {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .page-layout_termsContainer__r292p {
    margin-top: 36.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_termsContainer__r292p {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_termsContainer__r292p {
    margin-top: 51.65625px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_termsContainer__r292p {
    margin-top: 58px;
  }
}

.page-layout_termsCheckbox__3Do6V {
  background-color: #fff;
  border: 1px solid #bbc2c9;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.page-layout_termsCheckbox__3Do6V.page-layout_checked__1OIeZ {
  background-color: #007aff !important;
  border-color: #006aff !important;
}

@media only screen and (max-width: 899px) {
  .page-layout_termsCheckbox__3Do6V {
    border-radius: 3.125px;
    height: 14.375000000000002px;
    margin-right: 12.75px;
    width: 14.375000000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_termsCheckbox__3Do6V {
    border-radius: 3.75px;
    height: 17.25px;
    margin-right: 12.75px;
    width: 17.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_termsCheckbox__3Do6V {
    border-radius: 4.453125px;
    height: 20.484375px;
    margin-right: 15.140625px;
    width: 20.484375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_termsCheckbox__3Do6V {
    border-radius: 5px;
    height: 23px;
    margin-right: 17px;
    width: 23px;
  }
}

.page-layout_termsCheckboxIcon__2e2bE {
  color: #fff;
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.page-layout_terms__3Aoaa a {
  color: #1172d2 !important;
  font-weight: 500;
  text-decoration: none;
}

@media all and (hover: hover) {
  .page-layout_terms__3Aoaa a:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .page-layout_terms__3Aoaa a:active, .page-layout_terms__3Aoaa a:target {
    text-decoration: underline;
  }
}

.page-layout_continueButton__CjZw1 {
  border: 1px solid #5aa5ef;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.page-layout_continueButton__CjZw1:not(:disabled) {
  background-color: #fff;
}

.page-layout_continueButton__CjZw1:disabled {
  cursor: default;
  background-color: #fff;
  color: #999 !important;
}
.page-layout_continueButton__CjZw1:disabled:hover{
  background-color: #fff;
  color: #999 !important;
}
@media only screen and (max-width: 899px) {
  .page-layout_continueButton__CjZw1 {
    font-size: 11.875px;
    height: 26.875px;
    margin-top: 36.25px;
    width: 195.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_continueButton__CjZw1 {
    font-size: 14.25px;
    height: 32.25px;
    margin-top: 0px;
    width: 234.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_continueButton__CjZw1 {
    font-size: 16.921875px;
    height: 38.296875px;
    margin-top: 51.65625px;
    width: 278.765625px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_continueButton__CjZw1 {
    font-size: 19px;
    height: 43px;
    margin-top: 58px;
    width: 313px;
  }
}

.page-layout_continueButton__CjZw1:hover {
  background-color: #5aa5ef;
  color: #000;
}

.form-section_formSection__pB2fG {
  display: block;
}

@media only screen and (max-width: 899px) {
  .form-section_formSection__pB2fG {
    margin-bottom: calc(28.125px + 5px);
    margin-top: 28.125px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .form-section_formSection__pB2fG {
    margin-bottom: calc(33.75px + 6px);
    margin-top: 33.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .form-section_formSection__pB2fG {
    margin-bottom: calc(40.078125px + 7.125px);
    margin-top: 40.078125px;
  }
}

@media only screen and (min-width: 1710px) {
  .form-section_formSection__pB2fG {
    margin-bottom: calc(45px + 8px);
    margin-top: 45px;
  }
}

.form-section_title__3klEh {
  color: #60676d;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .form-section_title__3klEh {
    font-size: 11.875px;
    line-height: 16.25px;
    margin-bottom: -5px;
    justify-content: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .form-section_title__3klEh {
    font-size: 14.25px;
    line-height: 19.5px;
    margin-bottom: -6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .form-section_title__3klEh {
    font-size: 16.921875px;
    line-height: 23.15625px;
    margin-bottom: -7.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .form-section_title__3klEh {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: -8px;
  }
}

.form-section_content__1WCac {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media only screen and (max-width: 767px) {
  .form-section_content__1WCac {
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .form-section_content__1WCac {
    grid-gap: 24px;
    gap: 24px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .form-section_content__1WCac {
    grid-gap: 28.5px;
    gap: 28.5px;
    margin-top: 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .form-section_content__1WCac {
    grid-gap: 32px;
    gap: 32px;
    margin-top: 32px;
  }
}

.payment-field_cardNumber___TRLp {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  border: 1px solid #bdc4c9 !important;
  grid-row: 1 / 2;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

.payment-field_expirationDate__2th8n {
  margin-top: 10px;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  border: 1px solid #bdc4c9 !important;
  grid-column: 1 / 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

@media only screen and (max-width: 419px) {
  .payment-field_expirationDate__2th8n {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
  }
}

.payment-field_cvvNumber__1ntaS {
  margin-top: 10px;
  border: 1px solid #bdc4c9 !important;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  height: 42px;
  padding: 0 10.6875px;
  border-radius: 4.453125px;
  font-family: 'Poppins';
}

@media only screen and (max-width: 419px) {
  .payment-field_cvvNumber__1ntaS {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3 / 4 !important;
  }
}

.input-grid_inputGrid__2U3il {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 419px) {
  .input-grid_inputGrid__2U3il {
    grid-gap: 20px;
    gap: 20px;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 899px) {
  .input-grid_content__2-2MQ {
    margin-left: 5px;
    width: 222px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .input-grid_inputGrid__2U3il {
    grid-gap: 20px;
    gap: 20px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .input-grid_inputGrid__2U3il {
    grid-gap: 24px 28px;
    gap: 24px 28px;
    -ms-grid-columns: 174px 174px 174px;
        grid-template-columns: 174px 174px 174px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .input-grid_inputGrid__2U3il {
    grid-gap: 9.5px 45.421875px;
    gap: 9.5px 45.421875px;
    -ms-grid-columns: 206.625px 206.625px 206.625px;
        grid-template-columns: 206.625px 206.625px 206.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .input-grid_inputGrid__2U3il {
    grid-gap: 15px 51px;
    gap: 15px 51px;
    -ms-grid-columns: 232px 232px 232px;
        grid-template-columns: 232px 232px 232px;
  }
}

.input-field_inputField__6qUBr {
  border-color: #bdc4c9;
  border-style: solid;
  font-family: "Poppins", sans-serif;
  outline-color: #1172d2;
}

@media only screen and (max-width: 899px) {
  .input-field_inputField__6qUBr {
    border-radius: 3.125px;
    border-width: 0.46875px;
    font-size: 10px;
    height: 30px;
    line-height: 21px;
    padding: 0 7.5px;
  }
}
@media only screen and (max-width: 480px) {
  .input-field_inputField__6qUBr {
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .input-field_inputField__6qUBr {
    border-radius: 3.75px;
    border-width: 0.5625px;
    font-size: 11.25px;
    height: 36px;
    line-height: 21px;
    padding: 0 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .input-field_inputField__6qUBr {
    border-radius: 4.453125px;
    border-width: 0.66796875px;
    font-size: 13.359375px;
    height: 42.75px;
    line-height: 21px;
    padding: 0 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  .input-field_inputField__6qUBr {
    border-radius: 5px;
    border-width: 0.75px;
    font-size: 15px;
    height: 48px;
    line-height: 21px;
    padding: 0 12px;
  }
}

.input-field_inputField__6qUBr::-webkit-input-placeholder {
  color: #767676;
}

.input-field_inputField__6qUBr:-ms-input-placeholder {
  color: #767676;
}

.input-field_inputField__6qUBr::placeholder {
  color: #767676;
}

.billing_paymentCard__2fCKo {
  align-items: center;
  border: 2px solid #70707031;
  border-radius: 3.48mm;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.billing_itsAlink__2mQ-G {
  text-align: right;
  width: 100%;
  margin-bottom: 7px;
  color: #1172d2;
  text-decoration: none; /* Remove underline initially */
  display: inline-block; /* Allows proper hover effect */
}

.billing_itsAlink__2mQ-G:hover {
  text-decoration: underline; /* Add underline on hover */
}
.billing_leftico__3O2Yo{
  margin-left: auto;
  color: gray;
    cursor: default;
    font-weight: 500;
}
.billing_remaing__2If41 {
  font-size: 12.46875px !important;
  margin-bottom: 8.90625px !important;
  text-transform: capitalize;
  margin-top: 7.125px !important;
  color: red !important;
  font-family: "Poppins";
}
@media only screen and (max-width: 899px) {
  .billing_paymentCard__2fCKo {
    margin-top: 24px;
    border-radius: 11.71875px;
    height: 140.625px;
    padding: 23.4375px;
    width: 228.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .billing_paymentCard__2fCKo {
    border-radius: 15.625px;
    height: 187.5px;
    padding: 31.25px;
    width: 305px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .billing_paymentCard__2fCKo {
    border-radius: 18.75px;
    height: 225px;
    padding: 37.5px;
    width: 366px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .billing_paymentCard__2fCKo {
    border-radius: 22.265625px;
    height: 267.1875px;
    padding: 44.53125px;
    width: 434.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .billing_paymentCard__2fCKo {
    border-radius: 25px;
    height: 300px;
    padding: 50px;
    width: 488px;
  }
}

.billing_paymentCardNum__NF1Yz {
  color: #02071a;
  font: normal normal bold 20px/100px Montserrat;
  letter-spacing: -0.57px;
}

@media only screen and (max-width: 899px) {
  .billing_paymentCardNum__NF1Yz {
    font-size: 12px;
    padding: 0 0 23.4375px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .billing_paymentCardNum__NF1Yz {
    font-size: 12.5px;
    padding: 0 0 31.25px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .billing_paymentCardNum__NF1Yz {
    font-size: 15px;
    padding: 0 0 37.5px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .billing_paymentCardNum__NF1Yz {
    font-size: 17.8125px;
    padding: 0 0 44.53125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .billing_paymentCardNum__NF1Yz {
    font-size: 20px;
    padding: 0 0 50px 0;
  }
}

.billing_paymentCardProvider__1T6GZ {
  bottom: 5%;
  position: absolute;
  height: 25%;
  right: 5%;
}
.billing_labelLinks_container_main__EHRQN {
  /* background-color: gray; */
  /* display: flex; */
}

.billing_labelLinks_container_main__EHRQN .billing_labelLinks_container_sub__lKcid {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.billing_labelLinks_container_sub__lKcid:not(:last-child) {
  border-bottom: 1px solid gray;
}
.billing_labelLinks__2PEwK {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.billing_link__2tXrs {
  color: #1172d2;
  font-weight: 500;
  cursor: pointer;
}

.billing_linkDisabled__1syBA {
  color: gray;
  cursor: default;
}
.billing_linkDisabled__1syBA:hover {
  text-decoration: none !important;
}
.billing_firstLabel__OJcYP {
  display: flex;
}

.billing_firstLabel__OJcYP,
.billing_colorBlack__2hJm5 {
  font-weight: 700;
  color: #02071a;
}
.billing_secondLabel__JBhBj {
  color: #757575;
}

.billing_theMain__3KDtX {
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  /* padding: 0 1rem; */
  padding: 3% 12%;
}
.billing_mainBox__1uVi8 {
  font-size: inherit;
  max-width: 500px;
}
.billing_main_top__2VxDa {
  padding-bottom: 1rem;
}
.billing_main_top__2VxDa > .billing_main_subHeading__1sHTy {
  font-size: 16pt;
}
.billing_main_top__2VxDa > .billing_main_heading__smpb6 {
  font-weight: 500;
  margin: 0 !important;
  font-size: 30pt;
}

.billing_main_subHeading__1sHTy {
  max-width: 400px;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0.3rem 0 2rem 0;
}

.billing_main_box_buttons__1R6Ek {
  display: flex;
  justify-content: space-between;
}
.billing_main_box_buttons__1R6Ek > button {
  font-size: 15px;
  width: 45%;
  padding: 0.8rem;
  outline: none;
  border: none;
  background-color: #dedede;
  /* width: 100%; */

  margin: 0.5rem 0;
  font-weight: 600;
  cursor: pointer;
}
.billing_main_box_buttons__1R6Ek > button.billing_firstButton__1bjJi {
  background-color: #1172d2;
  color: white;
}
.billing_main_box__3a8ax {
  border: 2px solid #707070;
  background-color: white;
  padding: 1rem;
}
.billing_main_box_list__2YIFd ul li {
  font-weight: 500;
  margin: 1rem 0;
}

.billing_main_button__2WWDl {
  font-size: inherit;
  padding: 0.5rem 0rem;
  outline: none;
  border: none;
  background-color: #dedede;
  width: 100%;
  margin: 0.5rem 0;
  font-weight: 600;
  cursor: pointer;
}

.billing_reActiveButton__g-2Md {
  color: #fff !important;
  background-color: #f59716;
}

.billing_main_heading__smpb6 {
  font-size: inherit;
  font-weight: 500;
}
.billing_main__1k6vv {
  text-transform: uppercase;
  color: #02071a;
  font-family: "Montserrat";
  position: relative;
  width: 100%;
  padding: 1.5rem 0;
  /* font-size: 26px!important; */
}
@media only screen and (max-width: 480px) {
  .billing_firstLabel__OJcYP,
  .billing_colorBlack__2hJm5,
  .billing_link__2tXrs,
  .billing_secondLabel__JBhBj {
font-size: 14px;
align-items: center;
  }
  .billing_labelLinks__2PEwK {
    grid-gap: 20px;
    gap: 20px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .billing_main__1k6vv {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .billing_main__1k6vv {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .billing_main__1k6vv {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .billing_main__1k6vv {
    font-size: 20px;
  }
}

.billing_headerTheme__2mKCs {
  background-color: black !important;
}

.billing_redColor__2Os0O {
  color: #f80808;
  font-weight: 500;
  font-size: 14px;
}
.billing_expired__3XqhG {
  font-family: "poppins";
  font-size: 16px;
  margin: 0;
}
.billing_redColorExpired__3g46w {
  color: #f80808;
  font-weight: 500;
  font-size: 12px;
}

  .billing_main_button__2WWDl:hover {
    background-color: dimgrey;
  }
  .billing_disabled__1K8w7 {
    opacity: 0.5; /* Example styling for disabled button */
    cursor: not-allowed;
  }
  .billing_disabled__1K8w7:hover {
    opacity: 0.5; /* Example styling for disabled button */
    cursor: not-allowed;
    /* background-color: #dedede; */
  }
.billing_link__2tXrs:hover {
  text-decoration: underline;
}

.billing_main_box_buttons__1R6Ek > button.billing_firstButton__1bjJi:hover {
  background-color: #5aa5ef;
}

.billing_main_box_buttons__1R6Ek > button.billing_secondButton__2rn0G:hover {
  background-color: #707070;
}

.connected-accounts_addViewerData__2VIEl {
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 280px;
  overflow-y: auto;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .connected-accounts_personButtonDP__Gasu4 {
    height: 44px;
    margin-right: 11px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_personButtonDP__Gasu4 {
    height: 45.75px;
    margin-right: 11.4375px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_personButtonDP__Gasu4 {
    height: 54.328125px;
    margin-right: 13.58203px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_personButtonDP__Gasu4 {
    height: 60px;
    margin-right: 15px;
    width: 60px;
  }
}

.connected-accounts_personButton__cgM6W {
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  margin: 0;
  margin-bottom: 4px;
  text-align: left;
  width: 100%;
}

.connected-accounts_personButton__cgM6W.connected-accounts_selected__2cbKv {
  border: 2px solid #007aff;
}

@media only screen and (max-width: 1199px) {
  .connected-accounts_personButton__cgM6W {
    border-radius: 6.25px;
    margin-bottom: 6px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_personButton__cgM6W {
    border-radius: 7.5px;
    margin-bottom: 6.75px;
    padding: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_personButton__cgM6W {
    border-radius: 8.90625px;
    margin-bottom: 8.01562px;
    padding: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_personButton__cgM6W {
    border-radius: 10px;
    margin-bottom: 9px;
    padding: 18px;
  }
}

@media all and (hover: hover) {
  .connected-accounts_personButton__cgM6W:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {
  .connected-accounts_personButton__cgM6W:active,
  .connected-accounts_personButton__cgM6W:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.connected-accounts_personButtonDP__Gasu4 {
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
}

.connected-accounts_personDPImage__2V5Li,
.connected-accounts_personDPText__2oiQR {
  border-radius: 50%;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.connected-accounts_personDPText__2oiQR {
  align-items: center;
  background-color: #14f47b;
  color: #fff;
  display: flex;
  font-size: 24px;
  justify-content: center;
  line-height: 1;
}

.connected-accounts_personButtonTextContent__3Fc3z {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.connected-accounts_personButtonName__1-8xv,
.connected-accounts_personButtonEmail__2q4X8 {
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.connected-accounts_personButtonName__1-8xv {
  color: #000000;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_personButtonName__1-8xv {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_personButtonEmail__2q4X8 {
    font-size: 22px;
  }
}

.connected-accounts_personButtonEmail__2q4X8 {
  color: #63676c;
  text-transform: lowercase;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_contentBox__3Clif {
    font-size: 17px;
    padding: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_contentBox__3Clif {
    font-size: 17px;
    padding: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_contentBox__3Clif {
    font-size: 18.75px;
    padding: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_contentBox__3Clif {
    font-size: 22.265625px;
    padding: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_contentBox__3Clif {
    font-size: 25px;
    padding: 25px;
  }
}

.connected-accounts_addViewerData__2VIEl {
  color: #02071a;
  font-family: Montserrat, sans-serif;
  line-height: 3;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addViewerData__2VIEl {
    font-size: 12.5px;
    margin-top: 8px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addViewerData__2VIEl {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addViewerData__2VIEl {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addViewerData__2VIEl {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addViewerData__2VIEl {
    font-size: 20px;
  }
}

.connected-accounts_acceptUserBox__229nt {
  background-color: #f7f7f7;
}

@media only screen and (max-width: 767px) {
  .connected-accounts_acceptUserBox__229nt {
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .connected-accounts_acceptUserBox__229nt {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .connected-accounts_acceptUserBox__229nt {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_acceptUserBox__229nt {
    padding: 16.40625px 18.75px;
  }
}

@media only screen and (min-width: 768px) {
  .connected-accounts_acceptUserBox__229nt {
    margin-top: 1.5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_acceptUserBox__229nt {
    padding: 21.875px 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_acceptUserBox__229nt {
    padding: 26.25px 30px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_acceptUserBox__229nt {
    padding: 31.171875000000004px 35.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_acceptUserBox__229nt {
    padding: 35px 40px;
  }
}

.connected-accounts_acceptUserBoxTitle__1Qd2L {
  color: #141720;
  font-weight: 600;
  line-height: 1.8;
  text-align: right;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_acceptUserBoxTitle__1Qd2L {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_acceptUserBoxTitle__1Qd2L {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_acceptUserBoxTitle__1Qd2L {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_acceptUserBoxTitle__1Qd2L {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_acceptUserBoxTitle__1Qd2L {
    font-size: 25px;
  }
}

.connected-accounts_acceptUserBoxDescription__E6-AS {
  color: #02071a;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  text-align: right;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_acceptUserBoxDescription__E6-AS {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_acceptUserBoxDescription__E6-AS {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_acceptUserBoxDescription__E6-AS {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_acceptUserBoxDescription__E6-AS {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_acceptUserBoxDescription__E6-AS {
    font-size: 15px;
  }
}

.connected-accounts_userBoxRequest__1ADtI {
  background-color: #fff;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_userBoxRequest__1ADtI {
    margin-top: 22.5px;
    padding: 24px 24px;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_userBoxRequest__1ADtI {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_userBoxRequest__1ADtI {
    margin-top: 15px;
    padding: 10px 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_userBoxRequest__1ADtI {
    margin-top: 18px;
    padding: 12px 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_userBoxRequest__1ADtI {
    margin-top: 21.375px;
    padding: 14.25px 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_userBoxRequest__1ADtI {
    margin-top: 24px;
    padding: 16px 32px;
  }
}

.connected-accounts_userBoxRequestUserName__hJNMQ {
  font-weight: 600;
}

.connected-accounts_modalDivider__3nU4p,
.connected-accounts_userBoxDivider__1QzaO {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.14;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_modalDivider__3nU4p,
  .connected-accounts_userBoxDivider__1QzaO {
    margin: 16px 0 16px 0;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_modalDivider__3nU4p,
  .connected-accounts_userBoxDivider__1QzaO {
    margin: 24px 0 24px 0;
  }
}

.connected-accounts_userBoxRequestActions__UnWHg {
  display: flex;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_userBoxRequestActions__UnWHg {
    justify-content: space-between;
    width: 100%;
  }
}

.connected-accounts_userBoxRequestActionButton__3zk8D {
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    flex-grow: 0;
    font-size: 12px !important;
    height: 40px !important;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    margin-left: 7px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    font-size: 10px !important;
    height: 31.875px !important;
    width: 110.625px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    font-size: 12px !important;
    height: 38.25px !important;
    width: 132.75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    font-size: 14.25px !important;
    height: 45.421875px !important;
    width: 157.640625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_userBoxRequestActionButton__3zk8D {
    font-size: 16px !important;
    height: 51px !important;
    width: 177px !important;
  }
}

.connected-accounts_userBoxRequestAcceptButton__3d53L {
  color: #000;
}

.connected-accounts_addBoxContainer__1a3y1 {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addBoxContainer__1a3y1 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_addBoxContainer__1a3y1 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.connected-accounts_addBox__9DuHS {
  position: relative;
}

@media only screen and (min-width: 900px) {
  .connected-accounts_addBox__9DuHS {
    border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_addBox__9DuHS.connected-accounts_alignRight__1-omL {
    text-align: right;
  }
  .connected-accounts_addBox__9DuHS.connected-accounts_alignRight__1-omL .connected-accounts_addBoxTitle__m0aIX {
    justify-content: flex-end;
  }
}

.connected-accounts_addBoxTitle__m0aIX {
  align-items: center;
  color: #141720;
  display: flex;
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addBoxTitle__m0aIX {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addBoxTitle__m0aIX {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addBoxTitle__m0aIX {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addBoxTitle__m0aIX {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addBoxTitle__m0aIX {
    font-size: 25px;
  }
}

.connected-accounts_addBoxTitleIcon__3A2yV {
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addBoxTitleIcon__3A2yV {
    height: 24.056250000000002px;
    margin-left: 24.056250000000002px;
    width: 24.056250000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addBoxTitleIcon__3A2yV {
    height: 32.075px;
    margin-left: 32.075px;
    width: 32.075px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addBoxTitleIcon__3A2yV {
    height: 38.49px;
    margin-left: 38.49px;
    width: 38.49px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addBoxTitleIcon__3A2yV {
    height: 45.706875000000004px;
    margin-left: 45.706875000000004px;
    width: 45.706875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addBoxTitleIcon__3A2yV {
    height: 51.32px;
    margin-left: 51.32px;
    width: 51.31px;
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addBoxDescription__jlYHV {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addBoxDescription__jlYHV {
    font-size: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addBoxDescription__jlYHV {
    font-size: 12px;
    margin-bottom: 30px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addBoxDescription__jlYHV {
    font-size: 14.25px;
    margin-bottom: 35.625px;
    margin-top: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addBoxDescription__jlYHV {
    font-size: 16px;
    margin-bottom: 40px;
    margin-top: 16px;
  }
}

.connected-accounts_tableHeaderCell__rgvAx {
  color: #141720;
  font-family: Poppins, sans-serif !important;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_tableHeaderCell__rgvAx {
    border-top: 1px solid rgba(112, 112, 112, 0.18) !important;
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_tableHeaderCell__rgvAx {
    font-size: 17px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_tableHeaderCell__rgvAx {
    font-size: 18.75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_tableHeaderCell__rgvAx {
    font-size: 22.265625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_tableHeaderCell__rgvAx {
    font-size: 25px !important;
  }
}

.connected-accounts_tableBodyCell__2v-zk {
  color: #5aa5ef !important;
  font-size: 15px !important;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_tableContainer__1gkz7 {
    margin-bottom: 2em;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_tableContainer__1gkz7 {
    margin-right: 2em;
  }
}

.connected-accounts_addViewerBox__3Shvh {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addViewerBox__3Shvh {
    margin-top: 6.00586px;
    padding: 12.01172px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addViewerBox__3Shvh {
    height: 128.125px;
    margin-top: 8.00781px;
    padding: 16.01562px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addViewerBox__3Shvh {
    height: 153.75px;
    margin-top: 9.60938px;
    padding: 19.21875px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addViewerBox__3Shvh {
    height: 182.578125px;
    margin-top: 11.41113px;
    padding: 22.82227px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addViewerBox__3Shvh {
    height: 205px;
    margin-top: 12.8125px;
    padding: 25.625px;
  }
}

.connected-accounts_addViewerBoxText__1yZQM {
  color: #5aa5ef;
  line-height: 1.2;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addViewerBoxText__1yZQM {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_addViewerBoxText__1yZQM {
    margin-bottom: 12px;
  }
}

.connected-accounts_addViewerBoxButton__2YU11 {
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_addViewerBoxButton__2YU11 {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_addViewerBoxButton__2YU11 {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_addViewerBoxButton__2YU11 {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_addViewerBoxButton__2YU11 {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_addViewerBoxButton__2YU11 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_tableHeaderCell__rgvAx,
  .connected-accounts_tableBodyCell__2v-zk {
    height: 57px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_tableHeaderCell__rgvAx,
  .connected-accounts_tableBodyCell__2v-zk {
    height: 57px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_tableHeaderCell__rgvAx,
  .connected-accounts_tableBodyCell__2v-zk {
    height: 57px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_tableHeaderCell__rgvAx,
  .connected-accounts_tableBodyCell__2v-zk {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_tableHeaderCell__rgvAx,
  .connected-accounts_tableBodyCell__2v-zk {
    height: 64px;
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_individualViewerAddBox__zP5l4 {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-row: 3 / 4;
  }
}

.connected-accounts_modalDescription__2IG-W {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_modalDescription__2IG-W {
    font-size: 11px;
    margin-bottom: 1.5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_modalDescription__2IG-W {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_modalDescription__2IG-W {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_modalDescription__2IG-W {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_modalDescription__2IG-W {
    font-size: 15px;
  }
}

.connected-accounts_viewersCountContainer__3INbr {
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  border-top: 1px solid rgba(112, 112, 112, 0.18);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewersCountContainer__3INbr {
    border-bottom: none !important;
    height: 40.15625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewersCountContainer__3INbr {
    height: 46.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewersCountContainer__3INbr {
    height: 56.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewersCountContainer__3INbr {
    height: 66.796875px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewersCountContainer__3INbr {
    height: 75px;
  }
}

.connected-accounts_viewersCountTitle__2oKz-,
.connected-accounts_viewerDetailsColumnName__3P1s7 {
  font-weight: 600;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewersCountTitle__2oKz-,
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewersCountTitle__2oKz-,
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewersCountTitle__2oKz-,
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewersCountTitle__2oKz-,
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewersCountTitle__2oKz-,
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    font-size: 25px;
  }
}

.connected-accounts_viewersCount__29Oug {
  color: #5aa5ef !important;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewersCount__29Oug {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewersCount__29Oug {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewersCount__29Oug {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewersCount__29Oug {
    font-size: 13.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewersCount__29Oug {
    font-size: 15px;
  }
}

.connected-accounts_viewerDetailsInputGrid__3K9Wo {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 900px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    -ms-grid-columns: auto 1fr 1fr 1fr;
    grid-template-columns: auto 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    grid-gap: 0 40px;
    gap: 0 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    grid-gap: 0 25px;
    gap: 0 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    grid-gap: 0 30px;
    gap: 0 30px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    grid-gap: 0 35.625px;
    gap: 0 35.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewerDetailsInputGrid__3K9Wo {
    grid-gap: 0 40px;
    gap: 0 40px;
  }
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7,
  .connected-accounts_viewerDetailsIndex__3e9g3,
  .connected-accounts_viewerDetailsInputBox__1qCCJ {
    padding: 5.2734375px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7,
  .connected-accounts_viewerDetailsIndex__3e9g3,
  .connected-accounts_viewerDetailsInputBox__1qCCJ {
    padding: 7.03125px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7,
  .connected-accounts_viewerDetailsIndex__3e9g3,
  .connected-accounts_viewerDetailsInputBox__1qCCJ {
    padding: 8.4375px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7,
  .connected-accounts_viewerDetailsIndex__3e9g3,
  .connected-accounts_viewerDetailsInputBox__1qCCJ {
    padding: 10.01953125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7,
  .connected-accounts_viewerDetailsIndex__3e9g3,
  .connected-accounts_viewerDetailsInputBox__1qCCJ {
    padding: 11.25px 0;
  }
}

.connected-accounts_viewerDetailsIndex__3e9g3 {
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsIndex__3e9g3 {
    display: none;
  }
}

.connected-accounts_viewerDetailsColumnName__3P1s7 {
  color: #585858;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsColumnName__3P1s7 {
    display: none;
  }
}

.connected-accounts_viewerDetailsInputBox__1qCCJ {
  position: relative;
}

@media only screen and (min-width: 900px) {
  .connected-accounts_viewerDetailsInputGridDivider__3yQ-B {
    display: none;
  }
}

.connected-accounts_viewerDetailsInput__UPRPo {
  background-color: #fafafa;
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  outline: none;
  padding: 0 12px;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .connected-accounts_viewerDetailsInput__UPRPo {
    font-size: 17px;
    height: 37.5px;
  }
}

@media only screen and (min-width: 900px) {
  /* .viewerDetailsInput::-webkit-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput:-ms-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput::-ms-input-placeholder {
    opacity: 0;
  }
  .viewerDetailsInput::placeholder {
    opacity: 0;
  } */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .connected-accounts_viewerDetailsInput__UPRPo {
    font-size: 17px;
    height: 37.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .connected-accounts_viewerDetailsInput__UPRPo {
    font-size: 18.75px;
    height: 45px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_viewerDetailsInput__UPRPo {
    font-size: 22.265625px;
    height: 53.4375px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_viewerDetailsInput__UPRPo {
    font-size: 25px;
    height: 60px;
  }
}

.connected-accounts_modalCancelButton__3o-3w,
.connected-accounts_modalContinueButton__rPnAn {
  color: #000 !important;
}

.connected-accounts_userRequestActionsContainer__2lk9X {
  display: flex;
}
.connected-accounts_userRequestActionButton__1q_YV {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
}

@media all and (hover: hover) {
  .connected-accounts_userRequestActionButton__1q_YV.connected-accounts_delete__1O4R6:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .connected-accounts_userRequestActionButton__1q_YV.connected-accounts_delete__1O4R6:active,
  .connected-accounts_userRequestActionButton__1q_YV.connected-accounts_delete__1O4R6:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 767px) {
  .connected-accounts_userRequestActionButton__1q_YV {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .connected-accounts_userRequestActionButton__1q_YV {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .connected-accounts_userRequestActionButton__1q_YV {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .connected-accounts_userRequestActionButton__1q_YV {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .connected-accounts_userRequestActionButton__1q_YV {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
}

.content-layout_loader__1AnRA {
  align-items: center;
  display: flex;
  padding: 2em var(--site-padding);
  position: relative;
  width: 100%;
}
.content-layout_placeEnd__1ctdm {
  justify-content: center;
  display: block;
}

@media only screen and (max-width: 900px) {
  .content-layout_placeEnd__1ctdm {
    justify-content: center;
    display: flex;
    padding: 5px 10px;
    background-color: white;
    color: black;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s, -webkit-transform 0.2s;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s, -webkit-transform 0.2s;
    text-align: center;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 10px;
  }
  .content-layout_playlistMe__2Dgi0 {
    cursor: pointer;
    width: 44px;
    height: auto;
  }
  .content-layout_loader__1AnRA {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .content-layout_loader__1AnRA {
    justify-content: flex-start;
  }
}

.content-layout_loaderContent__yQn0K {
  display: inline;
  white-space: nowrap;
}

.content-layout_page__L-u9v {
  background-color: #ffffff;
}

@media only screen and (max-width: 899px) {
  .content-layout_pageTitle__2sDCt {
    display: none;
  }
}

.content-layout_pageContent__1vr6r {
  padding-left: 103px !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  background-color: #f7f7f7;
}

@media only screen and (max-width: 1199px) {
  .content-layout_name__3hVNS {
    margin-bottom: 11.51px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_name__3hVNS {
    margin-bottom: 11.625000000000002px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_name__3hVNS {
    margin-bottom: 13.804687500000002px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_name__3hVNS {
    margin-bottom: 15.5px;
  }
}

.content-layout_categoryContainer__3o2sQ,
.content-layout_categoryContainerImages__3d3zC {
  align-items: center;
  display: flex;
}

.content-layout_categoryContainer__3o2sQ {
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .content-layout_categoryContainer__3o2sQ {
    justify-content: space-between;
    width: 100%;
  }
}

.content-layout_editIcon__2t73C {
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .content-layout_editIcon__2t73C {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout_editIcon__2t73C {
    display: none;
  }
}

.content-layout_dragIconContainer__1Ed7_ {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout_dragIconContainer__1Ed7_ {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_dragIconContainer__1Ed7_ {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_dragIconContainer__1Ed7_ {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_dragIconContainer__1Ed7_ {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_dragIconContainer__1Ed7_ {
    margin-right: 33.33px;
  }
}

.content-layout_dragIcon__2XLVt {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .content-layout_dragIcon__2XLVt {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_dragIcon__2XLVt {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_dragIcon__2XLVt {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_dragIcon__2XLVt {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_dragIcon__2XLVt {
    height: 33.33px;
    width: 33.33px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentGroupContainer__3AXT7 {
      padding-top: 40px;
    }
  }
}

.content-layout_contentGroupContainer__3AXT7 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 540px 1fr;
  grid-template-columns: 540px 1fr;
}

.content-layout_contentGroup__3bwRC {
  border-collapse: collapse;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .content-layout_contentGroup__3bwRC {
    margin-bottom: 44px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentGroup__3bwRC {
    margin-bottom: 65.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroup__3bwRC {
    margin-bottom: 78.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroup__3bwRC {
    margin-bottom: 93.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroup__3bwRC {
    margin-bottom: 105px;
  }
}

.content-layout_contentGroupHead__2MxFV,
.content-layout_contentGroupBody__VEUNp {
  display: block;
}

.content-layout_contentGroupHeadRow__IjFKY,
.content-layout_contentGroupBodyRow__2HQnP {
  display: flex;
  padding: 0 var(--site-padding);
  position: relative;
}

.content-layout_contentGroupBodyRow__2HQnP {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content-layout_contentGroupBodyRow__2HQnP.content-layout_dragged__2-8ek {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
}

.content-layout_contentGroupBodyRow__2HQnP:not(.content-layout_dragged__2-8ek)::before {
  border-top: 1px solid rgba(112, 112, 112, 0.18);
  content: " ";
  display: block;
  left: var(--site-padding);
  position: absolute;
  top: 0;
  width: calc(100% - (2 * var(--site-padding)));
}

.content-layout_contentGroupHeadCell__nr2EF {
  border-bottom: 2px solid rgba(112, 112, 112, 0.18);
}

@media only screen and (max-width: 767px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1) {
    font-size: 26.25px !important;
    line-height: 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1) {
    font-size: 31.171875000000004px !important;
    line-height: 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1) {
    font-size: 35px !important;
    line-height: 43px !important;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2) {
    height: 38.12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2) {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2) {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2) {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2) {
    height: 64px;
  }
}

.content-layout_contentGroupHeadCell__nr2EF,
.content-layout_contentGroupBodyCell__3Fyvt {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
    width: calc(100% - 98.125px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
    width: calc(100% - 117.75px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
    width: calc(100% - 139.828125px);
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(1),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
    width: calc(100% - 157px);
  }
}

.content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
.content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
    width: 98.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
    width: 117.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
    width: 139.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupHeadCell__nr2EF:nth-child(2),
  .content-layout_contentGroupBodyCell__3Fyvt:nth-child(2) {
    width: 157px;
  }
}

.content-layout_contentGroupBodyCell__3Fyvt:nth-child(1) {
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .content-layout_contentGroupBodyCell__3Fyvt {
    height: 130px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentGroupBodyCell__3Fyvt {
    height: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupBodyCell__3Fyvt {
    height: 143.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupBodyCell__3Fyvt {
    height: 170.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupBodyCell__3Fyvt {
    height: 191px;
  }
}

.content-layout_contentGroupText__1f_23 {
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .content-layout_contentGroupText__1f_23 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupText__1f_23 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentGroupText__1f_23 {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentGroupText__1f_23 {
    font-size: 24px;
  }
}

.content-layout_imageContainer__37p30 {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .content-layout_imageContainer__37p30 {
    height: 71px;
    width: 132px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_imageContainer__37p30 {
    height: 80.25px;
    width: 150px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_imageContainer__37p30 {
    height: 95.296875px;
    width: 178.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_imageContainer__37p30 {
    height: 107px;
    width: 200px;
  }
}

.content-layout_image__1uR6r {
  background-color: #eee;
  border: 1px solid #707070;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) -7px 0 10px -10px;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.content-layout_image__1uR6r:nth-child(2) {
  margin-left: 20%;
}

.content-layout_image__1uR6r:nth-child(3) {
  margin-left: 40%;
}

.content-layout_image__1uR6r:nth-child(4),
.content-layout_image__1uR6r:nth-child(5) {
  margin-left: 60%;
}

.content-layout_image__1uR6r:nth-child(5) {
  background-color: transparent !important;
  border: none !important;
}

.content-layout_totalImages__2ZxS8 {
  align-items: center;
  background-color: #f5f4f4;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  left: 100%;
  padding: 3px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
  .content-layout_totalImages__2ZxS8 {
    font-size: 13px;
    height: 29px;
    min-width: 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_totalImages__2ZxS8 {
    font-size: 15px;
    height: 32.25px;
    min-width: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_totalImages__2ZxS8 {
    font-size: 17.8125px;
    height: 38.296875px;
    min-width: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_totalImages__2ZxS8 {
    font-size: 20px;
    height: 43px;
    min-width: 43px;
  }
}

.content-layout_contentLayoutHeader__N7xJw {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .content-layout_contentLayoutHeader__N7xJw {
    height: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_contentLayoutHeader__N7xJw {
    height: 82.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentLayoutHeader__N7xJw {
    height: 99.00000000000001px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_contentLayoutHeader__N7xJw {
    height: 117.56250000000001px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_contentLayoutHeader__N7xJw {
    height: 132px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout_contentLayoutHeader__N7xJw {
      padding-right: 40px;
    }
  }
}

.content-layout_createButtonsContainer__jJjba {
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout_createButtonsContainer__jJjba {
    justify-content: flex-end;
    width: 100%;
  }
}

.content-layout_createButtonWrapper__F8jXR {
  position: relative;
}

.content-layout_createButtonWrapper__F8jXR,
.content-layout_createButtonWrapper__F8jXR * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuContainer__2ETGb {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 16px !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout_createMenuContainer__2ETGb {
    position: relative;
  }
}

.content-layout_createMenu__28C2i {
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  -webkit-box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  /* border: 0.5px solid #707070; */
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 3px 6px #00000029; */
  /* box-shadow: 0px 3px 6px #00000029; */
  z-index: 10;
  padding-top: 10px !important;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenu__28C2i {
    max-width: 420px;
    padding: 18px 24px !important;
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout_createMenu__28C2i {
    position: absolute;
    right: 0;
    top: calc(100% - 4px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenu__28C2i {
    padding: 0 27.5px 9.16667px 27.5px;
    width: 369.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenu__28C2i {
    padding: 0 33px 11px 33px;
    width: 443.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenu__28C2i {
    padding: 0 39.1875px 13.0625px 39.1875px;
    width: 526.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenu__28C2i {
    padding: 0 44px 14.66667px 44px;
    width: 591px;
  }
}

.content-layout_createMenuTitle__1hVKH {
  font-weight: 500;
  line-height: 2;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuTitle__1hVKH {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenuTitle__1hVKH {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenuTitle__1hVKH {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenuTitle__1hVKH {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenuTitle__1hVKH {
    font-size: 20px;
  }
}

.content-layout_categoryDropdownContainer__1Xmkj {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-layout_createMenuInput___kesn {
  border: 1px solid #5aa5ef;
  display: block;
  font-size: 1em;
  margin-bottom: 1.5em;
  margin-top: 0.3em;
  outline: none;
  padding: 0 0.4em;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuInput___kesn {
    height: 45px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenuInput___kesn {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenuInput___kesn {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenuInput___kesn {
    height: 40.078125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenuInput___kesn {
    height: 45px;
  }
}

.content-layout_createMenuDropdown__Uz_B7 {
  display: inline-block;
  position: relative;
}

.content-layout_createMenuDropdownScrim__18gkl,
.content-layout_createButtonScrim__1yk-u {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.content-layout_createMenuDropdownList__1lwoV {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  line-height: 2.2;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  white-space: nowrap;
  z-index: 120;
}

.content-layout_createMenuDropdownList__1lwoV:not(.content-layout_alignRight__cUK4O) {
  left: 0;
}

.content-layout_createMenuDropdownList__1lwoV.content-layout_alignRight__cUK4O {
  right: 0;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuDropdownList__1lwoV {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenuDropdownList__1lwoV {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenuDropdownList__1lwoV {
    border-radius: 7.5px;
    font-size: 16.03125px;
    padding: 13.5px 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenuDropdownList__1lwoV {
    border-radius: 8.90625px;
    font-size: 16.03125px;
    padding: 16px 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenuDropdownList__1lwoV {
    border-radius: 10px;
    font-size: 18px;
    padding: 18px 36px;
  }
}

.content-layout_createMenuDropdownInput__3XFGI {
  align-items: center;
  display: inline-flex;
}

@media only screen and (max-width: 767px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenuDropdownInput__3XFGI {
    font-size: 16px;
  }
}

.content-layout_createMenuDropdownInput__3XFGI,
.content-layout_createMenuDropdownItem__3FAjI {
  cursor: pointer;
  position: relative;
}

.content-layout_createMenuButtons__19reb {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuButtons__19reb {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.content-layout_createMenuButtonsRight__9CdHt {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuButtonsRight__9CdHt {
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout_createMenuButtonsRight__9CdHt {
    margin-left: auto;
  }
}

.content-layout_createMenuCancelButton__2lYqO {
  background-color: transparent;
  border: 1px solid #707070;
}

.content-layout_createMenuCreateButton__5d7mx {
  background-color: #5aa5ef;
  border: none;
  color: #fff;
}

.content-layout_createMenuCancelButton__2lYqO,
.content-layout_createMenuCreateButton__5d7mx {
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

@media only screen and (min-width: 900px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    margin-left: 0.8em;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    flex-grow: 1;
    font-size: 13px;
    height: 37px;
    margin: 2em 0.8em 0 0.8em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    font-size: 8.125px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    font-size: 9.75px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    font-size: 11.578125px;
    height: 39.1875px;
    width: 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createMenuCancelButton__2lYqO,
  .content-layout_createMenuCreateButton__5d7mx {
    font-size: 13px;
    height: 44px;
    width: 86px;
  }
}

.content-layout_createButton__p-NsV {
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.content-layout_contentGroupBox__c66H_ {
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  width: 515px;
  height: 116px;
}

.content-layout_contentGroupTitle__3KkRC {
  font-size: 15px;
}

@media only screen and (max-width: 400px) {
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 316px !important;
    height: 91px;
    border-radius: 20px;
    margin-left: 8.2px;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout_createButton__p-NsV:not(.content-layout_compact__1FYMk) {
    display: none;
  }
  .content-layout_createButton__p-NsV.content-layout_compact__1FYMk {
    background-color: transparent;
    border-radius: 20px;
    color: #000000 !important;
    font-size: 13px;
    font-weight: 500;
    height: 32px;
    margin-left: 8.2px;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 357px;
    height: 91px;
    border-radius: 20px;
    margin-left: 8.2px;
  }
}

@media only screen and (max-width: 900px) {
  .content-layout_createButton__p-NsV.content-layout_compact__1FYMk {
    display: none;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 450px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .content-layout_titleStyle__2AlWi {
    font-size: 20px;
  }
  .content-layout_chooseOrder__1AfWv {
    font-size: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_createButton__p-NsV {
    border-radius: 6.25px;
    font-size: 13px;
    height: 50px;
    margin-left: 25.625px;
    padding: 0 25.625px;
    width: 223.125px;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 370px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .content-layout_contentGroupContainer__3AXT7 {
    -ms-grid-columns: 500px 1fr;
    grid-template-columns: 500px 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_createButton__p-NsV {
    border-radius: 7.5px;
    font-size: 15px;
    height: 60px;
    margin-left: 30.75px;
    padding: 0 30.75px;
    width: 267.75px;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 390px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .content-layout_contentGroupContainer__3AXT7 {
    -ms-grid-columns: 530px 1fr;
    grid-template-columns: 530px 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_createButton__p-NsV {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 71.25px;
    margin-left: 36.515625px;
    padding: 0 36.515625px;
    width: 317.953125px;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 408px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .content-layout_contentGroupContainer__3AXT7 {
    -ms-grid-columns: 545px 1fr;
    grid-template-columns: 545px 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_createButton__p-NsV {
    border-radius: 10px;
    font-size: 20px;
    height: 80px;
    margin-left: 41px;
    padding: 0 41px;
    width: 357px;
  }
  .content-layout_contentGroupBox__c66H_ {
    margin-top: 20px;
    width: 475px;
    height: 121px;
    border-radius: 20px;
    margin-left: 39.2px;
  }
  .content-layout_contentGroupContainer__3AXT7 {
    -ms-grid-columns: 600px 1fr;
    grid-template-columns: 600px 1fr;
  }
}

@media only screen and (max-width: 1290px) {
  .content-layout_contentGroupContainer__3AXT7 {
    display: block;
  }
  .content-layout_slideShowContainer__3lOT7 {
    margin: 20px 27px;
  }
}

.content-layout_contentWrapper__-WNqK {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 200px;
  grid-template-columns: 1fr 200px;
  position: relative;
}

.content-layout_contentGroupWrapper__od6HO {
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  margin: 0 27px;
  padding: 10px 36px 30px 0px;
  border-radius: 20px;
}

.content-layout_slideShowContainer__3lOT7 {
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  /* margin: 0 27px; */
  /* padding: 10px 36px 30px 0px; */
  border-radius: 20px;
  padding: 20px 0;
  max-height: 400px;
  max-width: 550px;
  margin-bottom: 20px;
}

.content-layout_actionWrapper__2_LYb {
  width: 25%;
}

.content-layout_chooseOrder__1AfWv {
  font-size: 32px;
  font-weight: 700;
  padding: 0 0 0 30px;
}

@media only screen and (max-width: 767px) {
  .content-layout_addIcon__2R9VQ {
    height: 12.8px;
    margin-right: -6.4px;
    width: 12.8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .content-layout_addIcon__2R9VQ {
    height: 15px;
    margin-right: -7.5px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_addIcon__2R9VQ {
    height: 20px;
    margin-right: -10px;
    width: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_addIcon__2R9VQ {
    height: 24px;
    margin-right: -12px;
    width: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_addIcon__2R9VQ {
    height: 28.5px;
    margin-right: -14.25px;
    width: 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_addIcon__2R9VQ {
    height: 32px;
    margin-right: -16px;
    width: 32px;
  }
}

.content-layout_editButton__26kLR {
  align-items: center;
  background-color: #fff;
  color: #000;
  border-color: #ffffff33;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
a.content-layout_editMenuButton__1I69L,
button.content-layout_editMenuButton__1I69L {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 0;
  color: #000000;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
}
a.content-layout_editbutton__29gSX {
  border-top: solid;
}
@media only screen and (max-width: 899px) {
  .content-layout_editButton__26kLR {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_editButton__26kLR {
    border-radius: 6.25px;
    font-size: 12.5px;
    height: 45.62499999999999px;
    padding: 0 15.625px;
    width: 98.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_editButton__26kLR {
    border-radius: 7.5px;
    font-size: 15px;
    height: 54.74999999999999px;
    padding: 0 18.75px;
    width: 117.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_editButton__26kLR {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 65.015625px;
    padding: 0 22.265625px;
    width: 139.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_editButton__26kLR {
    border-radius: 10px;
    font-size: 20px;
    height: 73px;
    padding: 0 25px;
    width: 157px;
  }
}

.content-layout_editButtonDropdown__18boF {
  background-color: #000;
  border-radius: 50%;
  flex-shrink: 0;
  color: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_editButtonDropdown__18boF {
    font-size: 16.25px;
    height: 19.375000000000004px;
    width: 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_editButtonDropdown__18boF {
    font-size: 19.5px;
    height: 23.250000000000004px;
    width: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_editButtonDropdown__18boF {
    font-size: 23.15625px;
    height: 27.609375000000004px;
    width: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_editButtonDropdown__18boF {
    font-size: 26px;
    height: 31px;
    width: 31px;
  }
}

.content-layout_headerTheme__1gIPq {
  background-color: black;
}

.content-layout_alignMiddle__iHeaT {
  justify-content: center;
  display: flex;
  margin-left: 25px;
}

.content-layout_titleStyle__2AlWi {
  font-size: 24px;
  /* width: 70%; */
  justify-content: flex-start;
  display: inline-flex;
  font-family: "Poppins";
  font-weight: 700;
}

.content-layout_editContentType__LA0cc {
  padding: 13px 35px;
  font-size: 15px;
  font-weight: 500;
}

.content-layout_editContentTypeRef__skoK8:hover {
  background-color: #707070;
  color: #fff;
}

.content-layout_editContentTypeRef__skoK8 {
  border-style: solid;
  border-width: 2px;
  padding: 10 10;
  border-color: #e7e7e7;
  color: #000;
  border-radius: 8px;
}

.content-layout_dragIconContainerType__35M2p {
  margin-left: 2%;
}
.content-layout_contentTypeTitle__1hd0r {
  width: 76%;
  /* float: left; */
  position: relative;
  justify-content: flex-start;
  display: flex;
  color: #000;
  margin-left: -3%;
}

.content-layout_editContentTypeRefPosition__3UTh3 {
  position: relative;
  right: 12px !important;
}

.content-layout_albumSettingsDiv__2fcsC {
  margin-top: 30px;
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.content-layout_saveButtonForSettings__wvsLi {
  display: grid;
  background-color: #5aa5ef;
  max-height: 20px;
  font-size: 10px;
  max-width: 90px;
  min-width: 80px;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-top: 5px;
  vertical-align: middle;
  align-items: center;
  margin-left: 20px;
}

.content-layout_selectTransition__1gpWS {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
  /* margin-left: 83px; */
}

.content-layout_saveButtonForSettingDisabled__SoqHi {
  opacity: 0.6;
  pointer-events: none;
}

.content-layout_addMorePhotoContainer__3ICsk {
  margin-top: 44px;
  cursor: pointer;
}
.content-layout_addMorePhoto__3RkC1 {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 15px;
  margin-left: 6px;
}
.content-layout_addMoreIcon__HPDIF {
  vertical-align: middle;
  cursor: pointer !important;
}

.content-layout_addMorePhotoIcon__3qiPf {
  vertical-align: middle;
  cursor: pointer !important;
  margin-right: 7px !important;
  position: relative;
  bottom: 2px;
}

.content-layout_slideShowTransitions__7DzWr {
  -webkit-appearance: listbox !important ;
  margin-left: 43px;
  width: 140px;
}

.content-layout_albumSettingsBundle__qJz1x {
  padding: 0 20px 10px 20px;
}
@media only screen and (min-width: 1100px) {
  .content-layout_createTitle__3olr7 {
    margin-top: -4px;
    font-weight: 500;
  }
}
.content-layout_buttonDiv__14UkG {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 99;
}

.content-layout_buttonGroups__2fOnB {
  display: inline-grid;
}

.content-layout_buttonWithText__2KeJp {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.content-layout_createTitle__3olr7 {
  /* margin-top: 18px; */
  font-weight: 500;
}

.content-layout_createPlayListTitle__UmTNQ {
  margin-top: -4px;
  font-weight: 500;
  margin-left: 25px;
}

.content-layout_subCategoryGroup__2TrXE {
  align-items: center;
  background-color: #fff;
  font-family: "Poppins";
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  margin-top: 20px;
  width: 408px;
  height: 500px;
  border-radius: 20px;
  margin-left: 39.2px;
}

.content-layout_subCategoryHeader__sfZDt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
}

.content-layout_addNewCategoryWrap__1l1m3 {
  padding: 0 20px 0 20px;
}

.content-layout_editSubCatButton__2g9HS {
  font-size: 15px;
  cursor: pointer;
}

.content-layout_editSubCatButton__2g9HS:hover {
  color: #5aa5ef;
}

.content-layout_removeSubCatButton__vLVK1 {
  font-size: 13px;
  /* width: 119px!important; */
  border: 2px solid #000000;
  height: 26px;
  align-items: center;
  display: flex;
  color: #ff0000;
  text-align: center;
  border-radius: 7px;
  padding: 2px;
  justify-content: center;
  cursor: pointer;
}

.content-layout_addButton__3jZ7W {
  font-size: 18px;
  border: 3px solid #5aa5ef;
  color: #5aa5ef;
  width: 91px;
  height: 35px;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-layout_addButtonWrap__2rUqu {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.content-layout_existingSubCatWrap__3MQWj {
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  margin-top: 10px;
}

.content-layout_existingSubCatWrap__3MQWj::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.content-layout_existingSubCatWrap__3MQWj::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-layout_existingSubCatWrap__3MQWj::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.content-layout_existingSubCatWrap__3MQWj::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-layout_subCatDiv__1lCxy {
  margin-bottom: 7px;
  margin-top: 7px;
}

.content-layout_betaComingSoonWrap__KWueH {
  display: flex;
  justify-content: space-between;
  padding: 30px 10px 5px;
}

.content-layout_betaComingSoonButton__2jfWq {
  background-color: #019f32;
  color: #fff;
  padding: 0px 6px;
  font-size: 15px;
  height: 22px;
}

.content-layout_comingSoonIcon__1uxNB {
  padding: 2px;
}

.content-layout_addTitle__1XqXs {
  font-weight: 600;
  font-size: 16px;
}

.content-layout_backButton__17-4a {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.content-layout_headerDiv__KbIVE {
  display: flex;
  justify-content: space-between;
}

.content-layout_selectionBox__2EW7U {
  display: flex;
}

.content-layout_arrowIcon__SkQmI {
  cursor: pointer;
  margin-left: 8px;
}

@media only screen and (max-height: 780px) {
  .content-layout_backgroundContainer__3lqxF {
    height: 192px;
  }
  .content-layout_backgroundImageList__t7RHa {
    max-height: 149px;
  }
}

.content-layout_backgroundContainer__3lqxF {
  width: 388px;
  height: 300px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.content-layout_backgroundImageList__t7RHa {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  margin: 10px 0;
}

.content-layout_backgroundImageList__t7RHa::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.content-layout_backgroundImageList__t7RHa::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-layout_backgroundImageList__t7RHa::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.content-layout_backgroundImageList__t7RHa::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-layout_loaderContent__yQn0K {
  display: inline;
  white-space: nowrap;
  font-size: 15px;
}

.content-layout_backGroundImage__59rgA:active {
  border-color: #5aa5ef;
}

.content-layout_buttonOnEnd__1FdD9 {
  display: flex;
  justify-content: end;
}

@media only screen and (min-width: 612px) and (max-width: 1290px) {
  .content-layout_contentGroupWrapper__od6HO {
    max-width: 493px;
  }
  .content-layout_contentGroupBox__c66H_ {
    width: 411px !important;
  }
}

@media only screen and (min-width: 450px) and (max-width: 611px) {
  .content-layout_subCategoryGroup__2TrXE {
    width: 323px;
  }
  .content-layout_contentGroupWrapper__od6HO {
    max-width: 400px;
  }
  .content-layout_contentGroupBox__c66H_ {
    width: 323px !important;
  }
  .content-layout_titleStyle__2AlWi {
    font-size: 19px;
  }
  .content-layout_chooseOrder__1AfWv {
    font-size: 22px;
  }
}

@media only screen and (max-width: 449px) {
  .content-layout_subCategoryGroup__2TrXE {
    width: 300px;
    margin-left: 11.2px;
  }
  .content-layout_contentGroupWrapper__od6HO {
    max-width: 325px;
  }
  .content-layout_contentGroupBox__c66H_ {
    width: 300px !important;
    margin-left: 11.2px;
    height: 85px;
  }
  .content-layout_titleStyle__2AlWi {
    font-size: 19px;
  }
  .content-layout_chooseOrder__1AfWv {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_contentGroupContainer__3AXT7 {
    -ms-grid-columns: 500px 1fr;
    grid-template-columns: 500px 1fr;
  }
  .content-layout_contentGroupBox__c66H_ {
    width: 360px;
  }
}


@media only screen and (min-width: 390px) and (max-width: 900px) {
  .content-layout_addMorePhoto__3RkC1 {
    font-size: 9px;
  }
  .content-layout_slideShowTransitions__7DzWr {
    margin-left: 54px;
    width: 116px;
    font-size: 10px;
    height: 25px;
  }
  .content-layout_comingSoonIcon__1uxNB {
    padding: 4px;
  }
  /* .selectTransition {
  margin-left: 30px;
} */
}

@media only screen and (max-width: 499px) {
  .content-layout_slideShowContainer__3lOT7 {
    max-width: 320px;
  }
  .content-layout_slideShowTransitions__7DzWr {
    margin-left: 54px;
    width: 116px;
    font-size: 10px;
    height: 25px;
  }
  .content-layout_comingSoonIcon__1uxNB {
    padding: 6px;
  }
  /* .selectTransition {
    margin-left: 30px;
  } */
  .content-layout_slideShowTransitions__7DzWr {
    margin-left: 21px;
  }
  .content-layout_betaComingSoonButton__2jfWq {
    font-size: 6px;
    height: 9px;
    text-align: center;
    width: 70px;
    vertical-align: middle;
    margin-top: 7px;
    padding: 0;
  }
}

.content-layout_updateButtonContainer__Yrnom {
  display: flex;
  justify-content: center;
}

.content-layout_bannerUpdateButtonContainer__3ULJ4 {
  justify-content: end;
}

.content-layout_updateButton__3uZym {
  max-width: 300px;
  font-family: Poppins, sans-serif;
  min-width: 150px;
  background-color: #2a2929;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  margin-top: 30px;
  padding: 1px;
  cursor: pointer;
}

.content-layout_bannerUpdateButton__2fAL6 {
  max-width: 120px;
  min-width: 110px;
  background-color: #2a2929;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  padding: 1px;
  cursor: pointer;
  font-size: 14px;
  height: 20px;
}

.content-layout_changeValue__Cb21Z {
  background-color: #5aa5ef;
}

.content-layout_bannerSettingForMedia__1pL0E {
  grid-template-columns: repeat(3, 1fr);
}

.content-layout_addContent__2f4xM {
  font-size: 15px;
  color: #5aa5ef;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.content-layout_previewImage__2blGM {
  background-color: #0002;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .content-layout_buttonGroups__2fOnB {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 30px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 11px;
    width: 100%;
  }
  .content-layout_previewImage__2blGM {
    height: 78px;
    width: 145.8px;
  }
  /* .albumImage {
      width: 72px !important;
    } */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_previewImage__2blGM,
  .content-layout_previewText__3sgMZ {
    height: 66.875px;
    width: 125px;
  }
  /* .albumImage {
      width: 62.5px !important;
    } */
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_previewImage__2blGM,
  .content-layout_previewText__3sgMZ {
    height: 80.25px;
    width: 150px;
  }
  /* .albumImage {
      width: 75px !important;
    } */
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_previewImage__2blGM,
  .content-layout_previewText__3sgMZ {
    height: 95.296875px;
    width: 178.125px;
  }
  /* .albumImage {
      width: 89px !important;
    } */
}

@media only screen and (min-width: 1710px) {
  .content-layout_previewImage__2blGM,
  .content-layout_previewText__3sgMZ {
    height: 107px;
    width: 200px;
  }
  /* .albumImage {
      width: 100px !important;
    } */
}

@media only screen and (max-width: 899px) {
  .content-layout_headerCell__dK6nE {
    font-size: 15px !important;
    height: 60.5px !important;
  }
  .content-layout_createTitle__3olr7, .content-layout_createPlayListTitle__UmTNQ {
    margin-top: -4px;
    font-weight: 500;
    margin-left: 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout_headerCell__dK6nE {
    font-size: 15px !important;
    height: 37.8125px !important;
  }
  .content-layout_headerCell__dK6nE.content-layout_checkboxCell__1OXtM {
    width: 40.3125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout_headerCell__dK6nE {
    font-size: 15px !important;
    height: 45.375px !important;
  }
  .content-layout_headerCell__dK6nE.content-layout_checkboxCell__1OXtM {
    width: 48.375px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout_headerCell__dK6nE {
    font-size: 17.8125px !important;
    height: 53.8828125px !important;
  }
  .content-layout_headerCell__dK6nE.content-layout_checkboxCell__1OXtM {
    width: 57.44531px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout_headerCell__dK6nE {
    font-size: 20px !important;
    height: 60.5px !important;
  }
  .content-layout_headerCell__dK6nE.content-layout_checkboxCell__1OXtM {
    width: 64.5px;
  }
}

.content-layout_bodyCell__3JXTt.content-layout_videoPreviewCell__3ZZ7m {
  border-right-style: solid;
  border-right-width: 1px;
}

@media only screen and (max-width: 899px) {
  .content-layout_buttonDiv__14UkG {
    position: relative;
    right: unset;
    top: 0;
    z-index: 10;
  }
  .content-layout_pageTitle__2sDCt {
    display: none !important;
  }
  .content-layout_pageContent__1vr6r {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .content-layout_tableHeader__2B5xL {
    display: none !important;
  }
  .content-layout_tableBody__2Ps1I * {
    border: none !important;
  }
  .content-layout_tableRow__1B8C0:first-of-type .content-layout_videoPreviewCell__3ZZ7m {
    padding-top: 18px !important;
  }
  .content-layout_videoPreviewCell__3ZZ7m {
    padding-bottom: 9px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 9px !important;
  }
  .content-layout_bodyCell__3JXTt:not(.content-layout_videoPreviewCell__3ZZ7m) {
    display: none !important;
  }
}

.content-layout_closeButton__26liM {
  flex-grow: 0;
  flex-shrink: 0;
  width: 17px;
  height: 22px;
  cursor: pointer;
}

.content-layout_selectedIemContainer__3jw7k {
  display: flex;
  justify-content: space-between;
  max-width: 200px;
  background-color: #c9c9c9;
  margin: 3px;
  height: 20px;
}

.content-layout_selectedItemTitle__EdMGc {
  font-size: 13px;
  padding: 0 10px;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.content-layout_selectedItemWrapper__3JLA3 {
  margin-left: 4px;
  margin-top: 15px;
}

.content-layout_alignRow__1wze7 {
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.content-layout_alignRow__1wze7::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.content-layout_alignRow__1wze7::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-layout_alignRow__1wze7::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.content-layout_alignRow__1wze7::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content-layout_imageColumn__1frWf {
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 319px) {
  .content-layout_alignRow__1wze7 {
    grid-template-columns: 1fr !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .content-layout_alignRow__1wze7 {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .content-layout_alignRow__1wze7 {
    grid-template-columns: 1fr 1fr !important;
  }
}

.content-layout_imageSelected__YvEZ7 {
  opacity: 0.5;
}

.content-layout_tickBotton__3hMad {
  position: absolute;
  width: 25px;
  height: 25px;
  z-index: 1000;
}

.content-layout_photoHeading__1Ztl5 {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #5aa5ef !important;
}

.content-layout_updateButton__3uZym:hover {
  background-color: #2a2929;
}

.content-layout_addButton__3jZ7W:hover {
  background-color: #5aa5ef;
  color: #fff;
}

.content-layout_removeSubCatButton__vLVK1:hover {
  background-color: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.content-layout_addContent__2f4xM:hover {
  color: #555;
}

.content-layout_backButton__17-4a:hover {
  color: #707070;
}

.content-layout-dashboard_loader__rtnjK {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 2em var(--site-padding);
  position: relative;
  width: 100%;
}
.content-layout-dashboard_existButtun__ak8km {
  /* margin: 0 15px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3D3E49;
  border-radius: 9px;
  opacity: 1;
  min-width: 89px;
  /* max-width: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  cursor: pointer;
  color: #282D33;
  opacity: 1;
  padding: 6.5px 16.5px;
}
.content-layout-dashboard_existButtun__ak8km:hover {
  /* background: #579ce1 !important; */
  color: #579ce1;
}
.content-layout-dashboard_discard__3cte0:hover {
  color: white;
}
.content-layout-dashboard_discard__3cte0 {
  color: #F74140;
  background: transparent;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.content-layout-dashboard_accessedCompny__rLjt7 {
  display: flex;
  background-color: #5AA5EF;
  width: 60%;
  grid-gap: 20px;
  gap: 20px;
  z-index: 111;
  cursor: pointer;
  position: fixed;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  /* margin-top: -33px !important; */
  padding: 5px 23px;
  letter-spacing: 0px;
  opacity: 1;
}
@media only screen and (max-width: 899px) {
  .content-layout-dashboard_accessedCompny__rLjt7 {
    top:64px;
  }
}
@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_accessedCompny__rLjt7 {
    top: 111px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_accessedCompny__rLjt7 {
    top:70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_accessedCompny__rLjt7 {
    top:83.25px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_accessedCompny__rLjt7 {
    top: 98.859375px;
  }
}
.content-layout-dashboard_visibility__zIQl_ {
  margin: 0;
  font-size: 12px;
  font-family: "Poppins";
}
.content-layout-dashboard_loaderContent__NqqWb {
  display: inline;
  white-space: nowrap;
}

.content-layout-dashboard_page__tvPsT {
  background-color: #ffffff;
}

.content-layout-dashboard_pageContent__13kMx {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.content-layout-dashboard_removeButton__HHvdg {
  background-color: transparent;
  border: none;
  color: #716f6f;
  cursor: pointer;
  white-space: nowrap;
}
.content-layout-dashboard_removeButtonRuniing__gCpDp {
  background-color: transparent;
  border: none;
  color: red;
  cursor: default;
  white-space: nowrap;
}

@media all and (hover: hover) {
  .content-layout-dashboard_removeButton__HHvdg:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .content-layout-dashboard_removeButton__HHvdg:active,
  .content-layout-dashboard_removeButton__HHvdg:target {
    text-decoration: underline;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_removeButtonRuniing__gCpDp,
  .content-layout-dashboard_removeButton__HHvdg {
    font-size: 11.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_removeButtonRuniing__gCpDp,
  .content-layout-dashboard_removeButton__HHvdg {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_removeButtonRuniing__gCpDp,
  .content-layout-dashboard_removeButton__HHvdg {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_removeButtonRuniing__gCpDp,
  .content-layout-dashboard_removeButton__HHvdg {
    font-size: 13px;
  }
}

.content-layout-dashboard_contentGroupTextContainer__pevdw {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupTextContainer__pevdw {
    margin-left: 17.5px;
    max-width: 173px;
  }
  .content-layout-dashboard_accessedCompny__rLjt7 {
    width: 100%;
    justify-content: start;
    bottom: 0;
    padding: 5px 13px;
    height: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupTextContainer__pevdw {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupTextContainer__pevdw {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupTextContainer__pevdw {
    margin-left: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupTextContainer__pevdw {
    margin-left: 28px;
  }
}

.content-layout-dashboard_contentGroupTitle__1uO8a::first-letter,
.content-layout-dashboard_contentGroupDescription__2rh39::first-letter {
  text-transform: capitalize;
}

.content-layout-dashboard_contentGroupTitle__1uO8a {
  color: #02071a;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupTitle__1uO8a {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupTitle__1uO8a {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupTitle__1uO8a {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupTitle__1uO8a {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupTitle__1uO8a {
    font-size: 19px;
  }
}

.content-layout-dashboard_contentGroupDescription__2rh39 {
  color: #02071a;
  line-height: 1.2;
  opacity: 0.9;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupDescription__2rh39 {
    font-size: 8px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupDescription__2rh39 {
    font-size: 8.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupDescription__2rh39 {
    font-size: 10.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupDescription__2rh39 {
    font-size: 12.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupDescription__2rh39 {
    font-size: 14px;
  }
}

.content-layout-dashboard_contentGroupTitle__1uO8a,
.content-layout-dashboard_contentGroupDescription__2rh39 {
  display: block;
  text-align: left;
  max-width: 400px;
}

.content-layout-dashboard_categoryContainer__27f_4,
.content-layout-dashboard_categoryContainerImages__7g4Qe {
  align-items: center;
  display: flex;
}

.content-layout-dashboard_categoryContainer__27f_4 {
  justify-content: space-between;
}

.content-layout-dashboard_editIcon___--_a {
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_editIcon___--_a {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-dashboard_editIcon___--_a {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm {
    height: 38.12px !important;
    padding: 0 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm {
    height: 64px;
  }
}

.content-layout-dashboard_contentGroupBodyRow__3oDQI {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--site-padding);
  position: relative;
  width: 100%;
}

.content-layout-dashboard_contentGroupBodyRow__3oDQI.content-layout-dashboard_dragged__3ru6i {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: #f1f1f1aa;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
}

.content-layout-dashboard_contentGroupBodyRow__3oDQI:not(.content-layout-dashboard_dragged__3ru6i)::before {
  border-bottom: 1px solid rgba(112, 112, 112, 0.18);
  content: " ";
  display: block;
  left: var(--site-padding);
  position: absolute;
  top: 100%;
  width: calc(100% - (2 * var(--site-padding)));
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB:nth-child(1) {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupHeadCell__2l8Sm:nth-child(2),
  .content-layout-dashboard_contentGroupBodyCell__2T_DB:nth-child(2) {
    display: none;
  }
}

.content-layout-dashboard_contentGroupBodyCell__2T_DB {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB {
    height: 130px;
    padding: 0 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB {
    height: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB {
    height: 143.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB {
    height: 170.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupBodyCell__2T_DB {
    height: 191px;
  }
}

.content-layout-dashboard_dragIconContainer__ou5yG {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_dragIconContainer__ou5yG {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_dragIconContainer__ou5yG {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_dragIconContainer__ou5yG {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_dragIconContainer__ou5yG {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_dragIconContainer__ou5yG {
    margin-right: 33.33px;
  }
}

.content-layout-dashboard_dragIcon__qPqGS {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_dragIcon__qPqGS {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-dashboard_dragIcon__qPqGS {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_dragIcon__qPqGS {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_dragIcon__qPqGS {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_dragIcon__qPqGS {
    height: 33.33px;
    width: 33.33px;
  }
}

.content-layout-dashboard_pageContent__13kMx {
  display: flex;
  padding-bottom: 0;
}

.content-layout-dashboard_pageContent__13kMx > * {
  flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_pageContent__13kMx {
    flex-direction: column;
  }
}

.content-layout-dashboard_preview__3cA_q {
  background-color: #f9f9f9;
}
.content-layout-dashboard_header__1L_fz {
  align-items: center;
  display: flex;
  height: var(--header-height);
}
.content-layout-dashboard_videoBackButton__20EvD {
  cursor: pointer;
  margin-left: -14px;
  position: relative;
  vertical-align: middle;
  margin-top: -23px;
  margin-right: -40px;
  margin-left: -34px;
}

@media only screen and (min-width: 900px) {
  .content-layout-dashboard_header__1L_fz {
    display: none;
  }
}
@media only screen and (min-width: 900px) {
  .content-layout-dashboard_preview__3cA_q {
    max-height: calc(100vh - var(--header-height));
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
    width: 40%;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-left)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-left: calc(2 * var(--site-padding)) !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: max(
        calc(2 * var(--site-padding)),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_preview__3cA_q {
      padding-right: calc(2 * var(--site-padding)) !important;
    }
  }
}

.content-layout-dashboard_previewImage__1bt8s {
  background-color: #ffffff;
  border: 1px solid #707070;
  margin-bottom: calc(var(--site-padding) / 2);
  /* padding-top: 60%; */
  width: 100%;
}

.content-layout-dashboard_previewTitleLoading__1Xs1Z {
  color: #000000;
  margin-right: 0.5em;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_previewTitleLoading__1Xs1Z {
    font-size: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .content-layout-dashboard_previewTitleLoading__1Xs1Z {
    font-size: 25.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_previewTitleLoading__1Xs1Z {
    font-size: 30.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_previewTitleLoading__1Xs1Z {
    font-size: 34px;
  }
}

.content-layout-dashboard_previewTitle__JYbU5 {
  background-color: transparent;
  border: 2px solid transparent;
  color: #000000;
  font-family: "Poppins", sans-serif;
  margin: 0;
  margin-right: 0.5em;
  transition-duration: 150ms;
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-layout-dashboard_previewTitle__JYbU5:not(:disabled) {
  background-color: #fff;
  border-color: #007aff !important;
  border-radius: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.content-layout-dashboard_previewTitle__JYbU5:disabled {
  color: #000000;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_previewTitle__JYbU5 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .content-layout-dashboard_previewTitle__JYbU5 {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_previewTitle__JYbU5 {
    font-size: 30.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_previewTitle__JYbU5 {
    font-size: 34px;
  }
}

.content-layout-dashboard_previewDetails__2nKzv {
  color: #818181;
  margin-top: calc(var(--site-padding) / 2.5);
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_previewDetails__2nKzv {
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .content-layout-dashboard_previewDetails__2nKzv {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_previewDetails__2nKzv {
    font-size: 15.140625px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_previewDetails__2nKzv {
    font-size: 17px;
  }
}

.content-layout-dashboard_contentGroupContainer__1LEOl {
  background-color: #f1f1f1;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_contentGroupContainer__1LEOl {
    flex-grow: 1;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-dashboard_contentGroupContainer__1LEOl {
    width: 60%;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-dashboard_contentGroupContainer__1LEOl {
      padding-bottom: 40px;
    }
  }
}

.content-layout-dashboard_contentGroup__Km-Tu {
  border-collapse: collapse;
  width: 100%;
  position: relative;
}

.content-layout-dashboard_contentGroupText__1ub3_ {
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .content-layout-dashboard_contentGroupText__1ub3_ {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_contentGroupText__1ub3_ {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_contentGroupText__1ub3_ {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_contentGroupText__1ub3_ {
    font-size: 24px;
  }
}

.content-layout-dashboard_imageContainer__2pi7V {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .content-layout-dashboard_imageContainer__2pi7V {
    height: 71px;
    min-width: 132px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-dashboard_imageContainer__2pi7V {
    height: 80.25px;
    min-width: 150px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_imageContainer__2pi7V {
    height: 95.296875px;
    min-width: 178.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_imageContainer__2pi7V {
    height: 107px;
    min-width: 200px;
  }
}

.content-layout-dashboard_image__q0ORN {
  background-color: #eee;
  border: 1px solid #707070;
  border-radius: 5px;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.content-layout-dashboard_previewInputContainer__3c88c {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  width: 100%;
}

.content-layout-dashboard_previewIcon__3TmDN {
  color: #818181;
  cursor: pointer;
  position: relative;
}

.content-layout-dashboard_backButton__3NO7Q {
  margin: auto 20px;
  font-size: 23px;
  color: #5983c3;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-dashboard_previewIcon__3TmDN {
    font-size: 22px;
    height: 22px;
    width: 22px;
  }
  .content-layout-dashboard_backButton__3NO7Q {
    display: none;
  }
  .content-layout-dashboard_positionStyle__p1Q-I {
    margin-top: 14px;
    margin-left: -23px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .content-layout-dashboard_previewIcon__3TmDN {
    font-size: 25.5px;
    height: 25.5px;
    width: 25.5px;
  }
  .content-layout-dashboard_backButton__3NO7Q {
    font-size: 18px;
    margin-left: -52px;
    margin-right: -4px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_previewIcon__3TmDN {
    font-size: 30.28125px;
    height: 30.28125px;
    width: 30.28125px;
  }
  .content-layout-dashboard_backButton__3NO7Q {
    font-size: 18px;
    margin-left: -62px;
    margin-right: 4px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_previewIcon__3TmDN {
    font-size: 34px;
    height: 34px;
    width: 34px;
  }
  .content-layout-dashboard_backButton__3NO7Q {
    font-size: 20px;
    margin-left: -62px;
    margin-right: -5px;
  }
}

.content-layout-dashboard_dataText__3vpcO {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2em;
}

.content-layout-dashboard_body__3ENgr {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
}

.content-layout-dashboard_headerContent__2sdOL {
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
}

.content-layout-dashboard_headerText__3cXeM {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-layout-dashboard_header__1L_fz {
  flex-grow: 0;
  flex-shrink: 0;
}

.content-layout-dashboard_content__1iUa7 {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .content-layout-dashboard_body__3ENgr {
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-dashboard_body__3ENgr {
    height: calc(
      100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    ) !important;
  }
}

@media only screen and (max-width: 767px) {
  .content-layout-dashboard_body__3ENgr {
    height: 100vh !important;
  }
  .content-layout-dashboard_header__1L_fz {
    padding: 24px 24px 0 24px !important;
  }
  .content-layout-dashboard_headerContent__2sdOL {
    justify-content: flex-start;
    border-bottom-width: 0px;
    padding-bottom: 28px;
  }
  .content-layout-dashboard_headerText__3cXeM {
    color: #fff;
    font-size: 21px;
    font-weight: 500;
  }
  .content-layout-dashboard_content__1iUa7 {
    padding: 0 24px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .content-layout-dashboard_body__3ENgr {
    --site-padding: 30.703124999999996px;
    border-radius: 5px;
    height: calc(100vh - (var(--header-height))) !important;
    max-width: 1033.5px;
  }
  .content-layout-dashboard_header__1L_fz {
    padding: 19.21875px 30.703124999999996px 0 30.703124999999996px !important;
  }
  .content-layout-dashboard_headerContent__2sdOL {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .content-layout-dashboard_headerText__3cXeM {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .content-layout-dashboard_body__3ENgr {
    --site-padding: 49.12499999999999px;
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
  .content-layout-dashboard_header__1L_fz {
    padding: 30.75px 49.12499999999999px 0 49.12499999999999px !important;
  }
  .content-layout-dashboard_headerContent__2sdOL {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .content-layout-dashboard_headerText__3cXeM {
    font-size: 22.5px;
  }
  .content-layout-dashboard_content__1iUa7 {
    padding: 23px 49.125px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-dashboard_body__3ENgr {
    --site-padding: 58.33593749999999px;
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
  .content-layout-dashboard_header__1L_fz {
    padding: 36.515625px 58.33593749999999px 0 58.33593749999999px !important;
  }
  .content-layout-dashboard_headerContent__2sdOL {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .content-layout-dashboard_headerText__3cXeM {
    font-size: 26.71875px;
  }
  .content-layout-dashboard_content__1iUa7 {
    padding: 23px 58.33594px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-dashboard_body__3ENgr {
    --site-padding: 65.5px;
    border-radius: 10px;
    max-width: 1378px;
  }
  .content-layout-dashboard_header__1L_fz {
    padding: 41px 65.5px 0 65.5px !important;
  }
  .content-layout-dashboard_headerContent__2sdOL {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
  .content-layout-dashboard_headerText__3cXeM {
    font-size: 30px;
  }
}

@media only screen and (max-height: 568px) {
  .content-layout-dashboard_body__3ENgr {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.content-layout-dashboard_addMorePhotoContainer__12Vbo {
  margin-top: 44px;
  cursor: pointer;
}
.content-layout-dashboard_addMorePhoto__2rgNe {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 15px;
  margin-left: 6px;
}
.content-layout-dashboard_addMoreIcon__cHyZS {
  vertical-align: middle;
  cursor: pointer !important;
}

.content-layout-dashboard_addMorePhotoIcon__3xKzL {
  vertical-align: middle;
  cursor: pointer !important;
  margin-right: 7px !important;
  position: relative;
  bottom: 2px;
}

.content-layout-dashboard_editBackground__1-o_0 {
  float: right;
  font-size: 18px;
  color: #818181;
  cursor: pointer;
}

.content-layout-dashboard_backgroundContainer__3_WB0 {
  width: 388px;
  height: 300px;
}

.content-layout-dashboard_backClose__3-ZvI {
  float: right;
  cursor: pointer;
}

.content-layout-dashboard_backgroundImageList__2Og90 {
  display: table-row;
  vertical-align: middle;
  position: absolute;
  margin: 38px 92px;
  width: 283px;
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.content-layout-dashboard_backgroundImageList__2Og90::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.content-layout-dashboard_backgroundImageList__2Og90::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.content-layout-dashboard_backgroundImageList__2Og90::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.content-layout-dashboard_backgroundImageList__2Og90::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#content-layout-dashboard_background-menu__3fYOS {
  overflow: hidden;
}

.content-layout-dashboard_changeButton__3DR7P {
  border-color: #5aa5ef;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 174px;
  left: 93px;
  margin: 7px auto;
  cursor: pointer;
}

.content-layout-dashboard_backGroundImage__Lg2vx:active {
  border-color: #5aa5ef;
}

.content-layout-dashboard_backgroundPreview__25D9W {
  border-radius: 2vh;
  background-color: #2f303d;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 53px;
  color: white;
  margin-bottom: 30px;
  width: 470px;
  height: 115px;
}

.content-layout-dashboard_backTitleContainer__2WtSg {
  display: inline-flex;
}
#content-layout-dashboard_background-menu__3fYOS {
  top: 388px;
}
.content-layout-dashboard_previewImageBack__233gx {
  /* background-color: #ffffff; */
  border: 1px solid #707070;
  margin-bottom: calc(var(--site-padding) / 7);
  width: 100%;
  height: 168px;
  display: inline-grid;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 34px;
  margin-top: 4%;
  margin-bottom: 3%;
  border-radius: 3vh;
}

@media only screen and (max-height: 780px) {
  .content-layout-dashboard_backgroundContainer__3_WB0 {
    height: 192px;
  }
  .content-layout-dashboard_backgroundImageList__2Og90 {
    max-height: 149px;
  }
}

.content-layout-dashboard_slideShowTransitions__2jxkC {
  -webkit-appearance: listbox !important ;
  margin-left: 20px;
  width: 120px;
}

.content-layout-dashboard_albumSettingsDiv__u8Xk8 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.content-layout-dashboard_saveButtonForSettings__uOT0N {
  display: grid;
  background-color: #5aa5ef;
  max-height: 20px;
  font-size: 10px;
  max-width: 90px;
  min-width: 80px;
  color: #ffffff;
  text-align: center;
  border-radius: 2px;
  margin-top: 5px;
  vertical-align: middle;
  align-items: center;
  margin-left: 20px;
}

.content-layout-dashboard_selectTransition__Z8R3j {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.content-layout-dashboard_saveButtonForSettingDisabled__1-8hQ {
  opacity: 0.6;
  cursor: not-allowed;
}

.content-layout-dashboard_dropDownAnimation__yHqZO {
  -webkit-animation: content-layout-dashboard_growOut__3LTMc 700ms ease-in-out forwards;
          animation: content-layout-dashboard_growOut__3LTMc 700ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center;
}

@-webkit-keyframes content-layout-dashboard_growOut__3LTMc {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes content-layout-dashboard_growOut__3LTMc {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

* {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  font-display: none;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal, auto, normal, normal, normal, "subs" off, "sups" off;
  font-kerning: auto;
  -webkit-font-language-override: normal;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size-adjust: none;
  font-stretch: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  -webkit-font-variant-ligatures: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-touch-callout: none;
}

body {
  font-family: "Poppins";
  margin: 0;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.grecaptcha-badge {
  display: none !important;
}
.spinex {
  border-top: 7px solid #007bff; /* Change the spinner color here */
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #007bff;
  height: 20px;
  width: 20px;
}
:root {
  overflow-x: hidden;
}
.Toastify__toast {
  cursor: default !important;
  height: 75px !important;
}
.Toastify__toast-container--bottom-right {
  bottom: 3em !important;
}
.custom-toast {
}
/* Spinner.css */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
select > option {
  border: none;
}
.spinner {
  border: 7px solid rgba(0, 0, 0, 0.3);
  border-top: 7px solid #007bff; /* Change the spinner color here */
  border-radius: 50%;
  width: 44px;
  height: 44px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite; /* Apply the rotation animation */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loaderspin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@media only screen and (max-width: 899px) {
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #007bff;
    height: 26.875px;
    width: 26.875px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #007bff;
    height: 32.25px;
    width: 32.25px;
  }
}

.theFiexedWidth {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 12222222;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.rotatingloader {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 10px solid #f3f3f3; /* Light grey border */
  border-top: 10px solid #3498db; /* Blue border */
  -webkit-animation: loaderspin 1s linear infinite;
          animation: loaderspin 1s linear infinite;
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.3);
    border-top: 5px solid #007bff;
    height: 38.296875px;
    width: 38.296875px;
  }
}
.socialLinks:hover {
  color: #007bff !important;
  cursor: pointer;
}
.socialLinks {
  color: white !important;
  cursor: pointer;
  all: unset;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 7px;
  height: 7px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  -webkit-animation: dotPulse 1.5s infinite ease-in-out;
          animation: dotPulse 1.5s infinite ease-in-out;
}

@-webkit-keyframes dotPulse {
  0%,
  100% {
    opacity: 0.3;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes dotPulse {
  0%,
  100% {
    opacity: 0.3;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

.dot:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.dot:nth-child(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.dot:nth-child(3) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.MuiAutocomplete-option {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.fixedProgressBar {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: radial-gradient(closest-side at 50% 50%, #292e40 0, #07080c 100%);
  overflow: hidden;
}
.moving-progress-bar-container {
  width: 50%;
  height: 4px;
  background-color: #f0f0f0;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.moving-progress-bar {
  width: 100%;
  height: 100%;
  position: relative;
}

.progress {
  width: 0%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  -webkit-animation: progressAnimation 2s ease-in-out forwards;
          animation: progressAnimation 2s ease-in-out forwards; /* Adjust animation duration as needed */
}
.fullProgress {
  width: 98%;
  height: 100%;
  background-color: #d01717;
  position: absolute;
  top: 0;
}

@-webkit-keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: #007bff;
  }
  100% {
    width: 98%;
    background-color: #007bff;
  }
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: #007bff;
  }
  100% {
    width: 98%;
    background-color: #007bff;
  }
}
.progressRed {
  width: 0%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  -webkit-animation: progressRed 1s ease-in-out forwards;
          animation: progressRed 1s ease-in-out forwards; /* Adjust animation duration as needed */
}

@-webkit-keyframes progressRed {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: red;
  }
  100% {
    width: 98%;
    background-color: red;
  }
}

@keyframes progressRed {
  0% {
    width: 0%;
    background-color: transparent;
  }
  98% {
    width: 97%;
    background-color: red;
  }
  100% {
    width: 98%;
    background-color: red;
  }
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px 0px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 0 0 0 !important;
}
@media only screen and (max-width: 319px) {
  :root {
    --site-padding: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  :root {
    --site-padding: 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  :root {
    --site-padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  :root {
    --site-padding: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  :root {
    --site-padding: 28px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  :root {
    --site-padding: 32px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  :root {
    --site-padding: 36px;
  }
}

@media only screen and (min-width: 1710px) {
  :root {
    --site-padding: 40px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: calc(env(safe-area-inset-bottom) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: calc(env(safe-area-inset-left) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: calc(env(safe-area-inset-top) + 40px);
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 12px);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 16px);
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 20px);
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 24px);
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 28px);
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 32px);
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 36px);
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: calc(env(safe-area-inset-right) + 40px);
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-bottom: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-bottom: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-bottom: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-bottom: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-bottom: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-bottom: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-bottom: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-bottom: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-left: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-left: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-left: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-left: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-left: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-left: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-left: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-left: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-top: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-top: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-top: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-top: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-top: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-top: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-top: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-top: 40px;
    }
  }

  @media only screen and (max-width: 319px) {
    :root {
      --site-padding-right: 12px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 419px) {
    :root {
      --site-padding-right: 16px;
    }
  }

  @media only screen and (min-width: 420px) and (max-width: 767px) {
    :root {
      --site-padding-right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    :root {
      --site-padding-right: 24px;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    :root {
      --site-padding-right: 28px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    :root {
      --site-padding-right: 32px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    :root {
      --site-padding-right: 36px;
    }
  }

  @media only screen and (min-width: 1710px) {
    :root {
      --site-padding-right: 40px;
    }
  }
}

.image-item {
  /* padding: 20px 0 20px 0px; */
  /* width: auto !important; */
  /* aspect-ratio: 16 / 9; */
  padding-right: 15px !important;
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transform-style: initial;
          transform-style: initial;
  /* min-height: 300px;
  background-color: red; */
}
.image-item:not(:first-child) {
  /* padding-right: 15px !important; */
}
.spclMargin {
  /* margin: 0 7.5px !important; */
}
.image-item_images {
  width: -webkit-max-content !important;
  width: max-content !important;
  /* margin-right: 30px; */
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transform-style: initial;
          transform-style: initial;
}
.image-item_images:not(:first-child) {
  padding-left: 15px;
}
.image-item_catergory {
  width: 310px !important;
  margin-right: 15px;
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transform-style: initial;
          transform-style: initial;
}
.image-item_catergoryViewMyProj {
  /* width: 310px !important; */
  padding-right: 15px;
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transform-style: initial;
          transform-style: initial;
}
.category-item {
  width: 150px !important;
  margin-right: 15px;
}

.react-multi-carousel-track {
  -webkit-transform-style: flat;
          transform-style: flat;
  -webkit-transform-style: initial;
          transform-style: initial;
  /* gap: 20px; */
}

.carousel-container {
  height: 270px;
}

.custom-dot-list-style {
  justify-content: flex-end;
  margin-right: 25px;
}

.react-multi-carousel-dot button {
  background: #fff;
}

.react-multi-carousel-dot--active button {
  background: rgb(52, 120, 230);
}
.carousel-item-padding-40-px:first-child {
  margin-left: -20px;
}
.carousel-item-padding-40-px {
  padding-left: 20px;
}
.carousel-item-padding-image:not(:first-child) {
  padding-left: 5px;
}
.carousel-item-padding-30-px {
  padding-left: 20px;
}
.carousel-item-padding-30-px:first-child {
  margin-left: -20px;
}
.custom_arrow {
  background: transparent !important;
  color: white;
  margin-bottom: 37px;
  width: 20px;
  height: 20px;
  /* display: none; */
}

.custom_arrow_profile {
  background: transparent !important;
  color: white;
  margin-bottom: 13px;
  width: 20px;
  height: 20px;
}

.custom_arrow:hover,
.custom_arrow_category:hover {
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  z-index: 1000 !important;
}

.custom_arrow_category {
  width: 20px;
  height: 20px;
}

.layout_arrow_category {
  margin-bottom: -6px;
  width: 10px;
  height: 10px;
  min-width: 25px;
  min-height: 25px;
}

.layout_arrow_category:hover {
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  z-index: 1000 !important;
}

/* .react-multiple-carousel__arrow:hover {
  background: transparent;
} */
.slide-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
}

.album-image-item {
  width: 211px !important;
  margin-right: 27px;
}
.image-gallery-left-nav {
  left: 25% !important;
}

.image-gallery-right-nav {
  right: 25% !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 100px;
  width: 80px;
}
.image-gallery-thumbnail {
  margin-top: 0px !important;
  height: auto !important;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: -webkit-max-content !important;
  width: max-content !important;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  margin: 0 4px !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: flex !important;
  position: relative;
  justify-content: center !important;
}
@media only screen and (min-width: 1901px) {
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 50% !important;
    margin-top: 0 !important;
  }
  .image-gallery-play-button {
    left: 50% !important;
    top: 50%;
    margin-top: 0;
    padding-left: 0px !important;
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
  }
  .image-gallery-play-button > .image-gallery-svg {
    height: 60px !important;
    width: 60px !important;
  }
}
@media only screen and (max-width: 480px) {
  .image-gallery-play-button {
    left: 50% !important;
    top: 50% !important;
    margin-top: 0 !important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin-left: 9px;
  }
  .image-gallery-thumbnail {
    width: -webkit-max-content !important;
    width: max-content !important;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 65px;
    padding-right: unset !important;
    padding-left: unset !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 51% !important;
    margin-top: 0;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 48px !important;
    width: 24px !important;
  }
}
@media only screen and (min-width: 481px) and (max-width: 749px) {
  .image-gallery-play-button {
    left: 45% !important;
    top: 45% !important;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 72px !important;
    width: 66px !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 45% !important;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1900px) {
  .image-gallery-play-button {
    left: 47% !important;
    margin-top: 0 !important;
    padding: 20px 0 !important;
    top: 41% !important;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 100px !important;
    width: 80px !important;
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    padding: 0px 10px !important;
    top: 45% !important;
  }
}
.react-multiple-carousel__arrow_photo {
  min-width: 42px;
  min-height: 113px;
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.5);
  background: rgba(255, 0, 0, 0) !important;
}

/* .react-multiple-carousel__arrow, .react-multiple-carousel__arrow_photo { */
/* min-width: 42px;
  min-height: 113px;
  border-radius: 0px; */
/* background-color: rgba(0, 0, 0, 0.5); */
/* background: rgba(255, 0, 0, 0) !important; */
/* } */

.custom_arrow_photolist {
  background: #707070;
  color: white;
  margin-bottom: 7px;
  min-height: 81px;
  border-radius: 0px !important;
  min-width: 30px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  min-height: 100vh;
  /* max-height: calc(100vh - 0px); */
}

.image-gallery-thumbnails-wrapper {
  bottom: 84px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover !important;
  cursor: pointer;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  height: 65px;
  padding-right: 6px;
  padding-left: 6px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid transparent;
  /* IE 9 */
  -webkit-transform: scale(1.2);
  /* Safari 3-8 */
  transform: scale(1.2);
}

.carousel-container {
  overflow: visible !important;
}

.image-item-whats-new {
  width: 320px !important;
  margin-right: 22px;
}

.carousel-profile-container-center {
  justify-content: center;
}

.align-center {
  display: flex;
  justify-content: center;
}
.align-first {
  display: flex;
  justify-content: center;
}

.carousel-container-for-whats-new {
  overflow-x: hidden;
  right: 0;
  overflow-wrap: break-word;
  height: 250px;
}

.carousel-container-album-banner {
  overflow: visible;
}

.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
  height: 300px;
}

@media only screen and (max-width: 319px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .carousel-profile-container {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .carousel-profile-container {
    width: 600px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .carousel-profile-container {
    width: 600px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .carousel-profile-container {
    width: 600px;
  }

  .custom_arrow_profile {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .carousel-profile-container {
    width: 600px;
  }

  .custom_arrow_profile {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .carousel-profile-container {
    width: 930px;
  }
}

.PhoneInput {
  background-color: #31343e;
  border: 2px solid #31343e;
  color: #ffffff;
  height: 60px;
  /* position: absolute; */
  width: 70%;
  border-radius: 5px;
}

.PhoneInputInput:focus {
  border-color: #6d6f76 !important;
}

.PhoneInputInput::-webkit-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput:-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

.PhoneInputInput::-ms-input-placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 12px;
  font-weight: 500;
}

/* .PhoneInputInput::placeholder {
  color: #8e8e8e;
  line-height: 61px;
  font-size: 15px;
  font-weight: 600;
} */
.PhoneInputInput::-webkit-input-placeholder {
  font-family: Montserrat-Regular;
  color: #8e8e8e;
  font-size: 19px;
}
.PhoneInputInput:-ms-input-placeholder {
  font-family: Montserrat-Regular;
  color: #8e8e8e;
  font-size: 19px;
}
.PhoneInputInput::placeholder {
  font-family: Montserrat-Regular;
  color: #8e8e8e;
  font-size: 19px;
}

.PhoneInputInput {
  font-size: 15px;
  font-family: Montserrat-Regular;
  background-color: transparent;
  opacity: 0.9;
  border: none;
  color: #fff;
}

.PhoneInputInput {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 15px;
}

.PhoneInputCountry {
  margin-left: 10px;
}

.carousel-container-album-banner {
  overflow: visible;
}

.carousel-item-albumbanner {
  padding-left: 20px;
  padding-right: 20px;
}

.react-multiple-carousel__arrow--right {
  z-index: 98;
  position: absolute;
  top: 27%;
  right: 0px;
  margin-top: 7px;
}
.react-multiple-carousel__arrow--rightx {
  z-index: 98;
  position: absolute;
  top: 27%;
  right: 0px;
  /* margin-top: 8px; */
}

.react-multiple-carousel__arrow
  react-multiple-carousel__arrow--right_photolist {
  z-index: 98;
  position: absolute;
  top: 26%;
  right: 0px;
}

.react-multiple-carousel__arrow--left {
  left: 0px;
  z-index: 98;
  position: absolute;
  top: 27%;
  margin-top: 7px;
}
.react-multiple-carousel__arrow--leftx {
  left: 0px;
  z-index: 98;
  position: absolute;
  top: 27%;
  /* margin-top: 8px;  */
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 0px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 0px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 4px;
  }
}
@media only screen and (max-width: 570px) {
  .align-first {
    display: flex;
    justify-content: start !important;
  }
  .carousel-container {
    overflow: auto !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile-item {
    scale: 0.8;
  }
  .react-multiple-carousel__arrow--leftx,
  .react-multiple-carousel__arrow--rightx {
    margin-top: 3px;
  }
  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    z-index: 98;
    position: absolute;
    top: 16%;
    right: 0px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile-item {
    scale: 0.9;
  }
}
@media only screen and (max-width: 767px) {
  .profile-item {
    scale: 0.8;
  }
}

.dashboard_section__MEWcX {
  height: 63vh;
}
@media only screen and (max-width: 899px) {
  .dashboard_pageContent__3B72_ {
    display: flex;
  }
}

.dashboard_dashboard__ELkFV {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3%;
}

@media only screen and (max-width: 899px) {
  .dashboard_dashboard__ELkFV {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard_dashboard__ELkFV {
    margin: 32px auto;
    max-width: 420px;
  }
}

@media only screen and (min-width: 900px) {
  .dashboard_dashboard__ELkFV {
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
    padding-left: 131px;
  }
}

.dashboard_sectionsContainer__2drcU {
  flex-grow: 1;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .dashboard_sectionsContainer__2drcU {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .dashboard_sectionsContainer__2drcU {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 100%;
  }
}

.dashboard_section__MEWcX {
  background-color: #fff;
  overflow: hidden;
  width: 100%;
}

.dashboard_sectionTitle__3Dx19 {
  -webkit-box-align: center;
  -ms-flex-align: center;
  font-family: Poppins, sans-serif;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.dashboard_sectionTitle__3Dx19 > *:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  white-space: nowrap;
  font-weight: 600;
}

.dashboard_videoUploadSection__2ePLZ {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.dashboard_videoUploadSectionContent__aMe_O {
  align-items: center;
  border: 1px dashed #707070;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}

.dashboard_upgradeSection__28orJ {
  border: 1px dashed #707070;
  display: table;
  /* min-height: 120px; */
  border-radius: 10px;
  /* margin-top: 15px; */
  padding: 8px;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  max-height: 125px;
}

.dashboard_detailsPart__3PkNQ {
  /* width: 50%; */
  /* float: left; */
}

.dashboard_actionPart__2xhr4 {
  /* float: right; */
  /* width: 50%; */
  /* float: right; */
  display: flex;
  align-items: center;
  min-height: 120px;
  vertical-align: middle;
}

@media only screen and (max-width: 350px) {
  .dashboard_upgradeSection__28orJ {
    display: block;
  }
  .dashboard_actionPart__2xhr4 {   
    min-height: 75px;
  }
}

.dashboard_manageWrapper__31uJa {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.dashboard_upgradeText__1nNle {
  padding: 10px;
}

.dashboard_uploadIcon__1R12k {
  position: relative;
}

.dashboard_uploadButton__12TZp {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  background-color: #1172d2;
  border-radius: 0 !important;
  font-family: 'Poppins';
}

.dashboard_uploadButton__12TZp:hover {
  background-color: #579ce1;
}

.dashboard_inviteButton__Hfdxh:hover {
  background-color: #1172d2;
}

.dashboard_storageManagementButton__1bOXT:hover {
  background-color: dimgray;
}

.dashboard_upgradeButton__2o89T:hover {
  background-color: #555;
}

.dashboard_videoAnalyticsSection__DH8JN {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 3
}

.dashboard_storageSection__sro7x {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.dashboard_userManagementSection__3fW4A {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 3 / 4;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.dashboard_addUserButton__Z081c {
  align-items: center;
  border: 1px solid #707070;
  border-radius: 50%;
  color: #5aa5ef;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 1;
  margin-left: 2.5%;
}

.dashboard_addUserButton__Z081c:hover {
  background-color: #5aa5ef;
  color: #fff;
  border-color: #5aa5ef;
}

.dashboard_userRequest__6f8wS {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 10px;
}

.dashboard_userRequestText__1MMoH {
  overflow: hidden;
  font-family: 'Poppins';
}

.dashboard_userRequestName__1Xero {
  font-size: 600;
  line-height: 1.3;
  text-transform: capitalize;
}

.dashboard_userRequestEmail__803yG {
  color: #b2b2b2;
}

.dashboard_userRequestName__1Xero,
.dashboard_userRequestEmail__803yG {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dashboard_userRequestActionsContainer__1QFmz {
  display: flex;
}

.dashboard_userRequestActionButton__66ppY {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  font-family: 'Poppins';
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
  margin-top: -1px;
}

@media all and (hover: hover) {
  .dashboard_userRequestActionButton__66ppY.dashboard_delete__1GCfW:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .dashboard_userRequestActionButton__66ppY.dashboard_delete__1GCfW:active,
  .dashboard_userRequestActionButton__66ppY.dashboard_delete__1GCfW:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .dashboard_userRequestActionButton__66ppY:not(.dashboard_delete__1GCfW):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .dashboard_userRequestActionButton__66ppY:not(.dashboard_delete__1GCfW):active,
  .dashboard_userRequestActionButton__66ppY:not(.dashboard_delete__1GCfW):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

.dashboard_videoAnalyticsList__1rvN_ {
  width: 100%;
}

.dashboard_videoAnalyticsList__1rvN_ thead {
  font-weight: 600;
}

.dashboard_videoAnalyticsList__1rvN_ td:nth-child(2) {
  text-align: right;
}

.dashboard_storageSectionContent__31peO {
  display: flex;
  flex-direction: column;
}

.dashboard_storageManagementButton__1bOXT {
  background-color: #c9c9c9;
  border: none;
  border-radius: 5px;
  font-family: Poppins, sans-serif;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_upgradeButton__2o89T {
  background-color: #fff;
  border: 2px solid #C9C9C9;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .dashboard_upgradeSection__28orJ {
    display: block;
  }
  .dashboard_actionPart__2xhr4 {   
    min-height: 30px;
  }
}
.dashboard_storageBarText__1zUT7 {
  font-size: 14px;
  color: white;
  font-family: 'Poppins';
  text-align: center;
  margin: 2px;
  font-weight: 500;
}

.dashboard_storageInfoBar__2X1DM {
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  margin-top: 0.5rem;
  overflow: hidden;
}

.dashboard_storageInfoBarContent__2LUiy {
  color: #fff;
  display: inline;
  line-height: 1.6;
  margin-left: -2.5px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dashboard_storageInfoBarContent__2LUiy.dashboard_storageInfoBarVideos__3E55u {
  background-color: #5aa5ef;
  /* width: 38.4476%; */
}

.dashboard_storageInfoBarContent__2LUiy.dashboard_storageInfoBarPhotos__zadzJ {
  background-color: #ff4040;
  /* width: 27.0758%; */
}

.dashboard_storageInfoBarContent__2LUiy.dashboard_storageInfoBarAvailable__1OdwY {
  text-align: right;
  background-color: #000;
  flex-grow: 1;
}

.dashboard_accessRequestNotes__UvoMH {
  line-height: 5;
  text-align: center;
  font-family: 'Poppins';
}

.dashboard_storageInfoText__2aq_X {
  font-weight: 500;
  font-family: 'Poppins';
}

@media only screen and (max-width: 767px) {
  .dashboard_sectionsContainer__2drcU {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 4px;
  }
  .dashboard_uploadIcon__1R12k {
    height: 41px;
    width: 41px;
  }
  .dashboard_videoUploadSection__2ePLZ {
    height: 184px;
  }
  .dashboard_videoAnalyticsSection__DH8JN {
    /* height: 216px; */
    min-height: auto;
  }
  .dashboard_section__MEWcX {
    border-radius: 12.65625px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .dashboard_videoUploadSectionContent__aMe_O {
    border-radius: 8.90625px;
  }
  .dashboard_addUserButton__Z081c {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .dashboard_userRequestName__1Xero {
    font-size: 17px;
  }
  .dashboard_userRequestEmail__803yG {
    font-size: 9px;
  }
  .dashboard_sectionTitle__3Dx19 {
    margin-bottom: 4px;
  }
  .dashboard_userRequest__6f8wS {
    margin-top: 22px;
  }
  .dashboard_userRequestActionButton__66ppY {
    font-size: 10px;
    height: 26px;
    width: 75px;
  }
  .dashboard_videoAnalyticsList__1rvN_,
  .dashboard_storageInfoBar__2X1DM {
    font-size: 14px;
  }
  .dashboard_storageInfoText__2aq_X {
    font-size: 13.5px;
  }
  .dashboard_storageManagementButton__1bOXT {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .dashboard_upgradeButton__2o89T {
    font-size: 12.375000000000002px;
    height: 34px;
    width: 75.6875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .dashboard_sectionsContainer__2drcU {
    grid-gap: 14.375000000000002px;
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .dashboard_uploadIcon__1R12k {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .dashboard_section__MEWcX {
    border-radius: 16.875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .dashboard_videoUploadSectionContent__aMe_O {
    border-radius: 11.875px;
  }
  .dashboard_addUserButton__Z081c {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .dashboard_userRequestName__1Xero {
    font-size: 17px;
  }
  .dashboard_userRequestEmail__803yG {
    font-size: 9px;
  }
  .dashboard_sectionTitle__3Dx19 {
    margin-bottom: 4px;
  }
  .dashboard_userRequest__6f8wS {
    margin-top: 22px;
  }
  .dashboard_userRequestActionButton__66ppY {
    font-size: 10px;
    height: 27.75px;
    width: 75px;
  }
  .dashboard_videoAnalyticsList__1rvN_,
  .dashboard_storageInfoBar__2X1DM {
    font-size: 14px;
  }
  .dashboard_storageInfoText__2aq_X {
    font-size: 11.5px;
  }
  .dashboard_storageManagementButton__1bOXT {
    font-size: 12.375px;
    height: 21px;
    width: 78.25px;
  }
  .dashboard_upgradeButton__2o89T {
    font-size: 12.375px;
    height: 24px;
    width: 78.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .dashboard_sectionsContainer__2drcU {
    grid-gap: 17.25px;
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .dashboard_uploadIcon__1R12k {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .dashboard_section__MEWcX {
    border-radius: 20.25px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .dashboard_videoUploadSectionContent__aMe_O {
    border-radius: 14.25px;
  }
  .dashboard_addUserButton__Z081c {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .dashboard_userRequestName__1Xero {
    font-size: 18.75px;
  }
  .dashboard_userRequestEmail__803yG {
    font-size: 10.5px;
  }
  .dashboard_sectionTitle__3Dx19 {
    margin-bottom: 4px;
  }
  .dashboard_userRequest__6f8wS {
    margin-top: 22px;
  }
  .dashboard_userRequestActionButton__66ppY {
    font-size: 12px;
    height: 27.75px;
    width: 75px;
  }
  .dashboard_videoAnalyticsList__1rvN_,
  .dashboard_storageInfoBar__2X1DM {
    font-size: 15px;
  }
  .dashboard_storageInfoText__2aq_X {
    font-size: 12.5px;
  }
  .dashboard_storageManagementButton__1bOXT {
    font-size: 12.25px;
    height: 22px;
    width: 95.5px;
  }
  .dashboard_upgradeButton__2o89T {
    font-size: 12.25px;
    height: 26px;
    width: 95.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dashboard_sectionsContainer__2drcU {
    grid-gap: 20.484375px;
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .dashboard_uploadIcon__1R12k {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .dashboard_section__MEWcX {
    border-radius: 24.046875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .dashboard_videoUploadSectionContent__aMe_O {
    border-radius: 16.921875px;
  }
  .dashboard_addUserButton__Z081c {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .dashboard_userRequestName__1Xero {
    font-size: 22.265625px;
  }
  .dashboard_userRequestEmail__803yG {
    font-size: 12.46875px;
  }
  .dashboard_sectionTitle__3Dx19 {
    margin-bottom: 7px;
  }
  .dashboard_userRequest__6f8wS {
    margin-top: 25.828125px;
  }
  .dashboard_userRequestActionButton__66ppY {
    font-size: 14.25px;
    height: 32.953125px;
    width: 89.0625px;
  }
  .dashboard_videoAnalyticsList__1rvN_,
  .dashboard_storageInfoBar__2X1DM {
    font-size: 17.8125px;
  }
  .dashboard_storageInfoText__2aq_X {
    font-size: 14.03125px;
  }
  .dashboard_storageManagementButton__1bOXT {
    font-size: 15.484375px;
    height: 28px;
    width: 140.90625px;
  }
  .dashboard_upgradeButton__2o89T {
    font-size: 15.484375px;
    height: 28px;
    width: 140.90625px;
  }
}

@media only screen and (min-width: 1710px) {
  .dashboard_sectionsContainer__2drcU {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 33px;
  }
  .dashboard_uploadIcon__1R12k {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .dashboard_section__MEWcX {
    border-radius: 27px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
    height: 63vh;
  }
  .dashboard_videoUploadSectionContent__aMe_O {
    border-radius: 19px;
  }
  .dashboard_addUserButton__Z081c {
    font-size: 36px;
    height: 44px;
    width: 44px;
    font-family: 'Poppins';
  }
  .dashboard_userRequestName__1Xero {
    font-size: 25px;
  }
  .dashboard_userRequestEmail__803yG {
    font-size: 14px;
  }
  .dashboard_sectionTitle__3Dx19 {
    margin-bottom: 8px;
  }
  .dashboard_userRequest__6f8wS {
    margin-top: 29px;
  }
  .dashboard_userRequestActionButton__66ppY {
    font-size: 16px;
    height: 37px;
    width: 100px;
  }
  .dashboard_videoAnalyticsList__1rvN_,
  .dashboard_storageInfoBar__2X1DM {
    font-size: 20px;
  }
  .dashboard_storageInfoText__2aq_X {
    font-size: 16px;
  }
  .dashboard_storageManagementButton__1bOXT {
    font-size: 23px;
    height: 34px;
    font-family: 'Poppins';
    width: 202px;
  }
  .dashboard_upgradeButton__2o89T {
    font-size: 19px;
    height: 34px;
    width: 140px;
  }
}
.dashboard_headerTheme__3rZWJ {
  background-color: black;
}

.dashboard_lineStyle__1OJOW {
  margin-top: 41px;
  display: flex;
  justify-content: center;
}

.dashboard_iconColumn__35im3 {
  display: flex;
  justify-content: center;
}

.dashboard_videoTitle__SQCTY {
  width: 28ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -100px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -100px;
  }  
  .dashboard_lineStyle__1OJOW {
    height: 40px !important;
    margin-top: 1px;
  }  
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -1px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -1px;
  }  
  .dashboard_lineStyle__1OJOW {
    height: 44px !important;
    margin-top: 41px;
  }  
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -3px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -3px;
  }  
  .dashboard_lineStyle__1OJOW {
    height: 48px !important;
    margin-top: 46px;
  }  
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -3px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -3px;
  }
  .dashboard_lineStyle__1OJOW {
    height: 67px !important;
    margin-top: 36px;
  }  
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -20px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -20px;
  }  
  .dashboard_lineStyle__1OJOW {
    height: 78px !important;
    margin-top: 39px;
  }  
}

@media only screen and (min-width: 1710px) {
  .dashboard_videoUploadIconPosition__288MB {
    margin-right: -35px;
  }
  .dashboard_albumUploadIconPosition__Izmwj {
    margin-left: -35px;
  }  
}

.dashboard_pendingInvite__ZIioZ {
  color: #FF0000;
  margin-right: 10px;
  font-size: 16px;
  width: 153px;
  background: transparent;
  font-family: 'Montserrat';
  float: right;
  border: none;
  position: relative;
  left: 35px;
  cursor: pointer;
  margin-top: 0px;
}

.dashboard_needYourAttention__1DGC6 {
  display: grid;
  align-items: center;
  min-height: 100%;
  text-align: center;
  justify-content: center;
}

.dashboard_needYourAttentionBox__713WT {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  max-width: 950px;
  padding: 42px;
}

.dashboard_updatePaymentButton__2U_b8 {
  background-color: #5AA5EF;
  border: 0.5px solid #707070;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 230px;
  font-size: 14px;
  margin-top: 20px;
  padding: 12px;
}

.dashboard_needAttentionTitle__2YXYk {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 500;
}

.dashboard_attentionDescription__10_Fr {
  margin-top: 10px;
  font-size: 15px;
}

.dashboard_circularLoader__3Btga {
  text-align: center;
  padding-top: 30px;
}
.dashboard_referFriendWrapper__1Wlbz {
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.dashboard_referText__3MS-B {
  font-size: 13px;
}

.dashboard_inviteButton__Hfdxh {
  min-width: 150px;
  max-width: 200px;
  max-height: 30px;
  background-color: #5aa5ef;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  border-radius: 5px;
}

.dashboard_inviteButtonWrapper__RiSzs {
  width: 100%;
  justify-content: center;
  display: flex;
}

.dashboard_remainingDays__12qrb {
  color: #5AA5EF;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.dashboard_viewerList__2dta6 {
  /* max-height: 180px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard_viewerList__2dta6::-webkit-scrollbar {
  width: 5px;
}

.dashboard_viewerList__2dta6::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.dashboard_viewerList__2dta6::-webkit-scrollbar-thumb {
  background: #6E6F6E; 
  border-radius: 25px;
}

.dashboard_viewerList__2dta6::-webkit-scrollbar-thumb:hover {
  background: #6E6F6E; 
}

.dashboard_referralRewardmsg__1utS4 {
  color: red;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

.dashboard_redeemCreditWrapper__1wJao {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.dashboard_textAlign__28nfq {
  display: flex;
  align-items: center;
}

@media only screen and (max-height: 640px) {
  .dashboard_viewerList__2dta6 {
    max-height: 120px;
  }
}

@media only screen and (min-height: 641px) and (max-height: 740px) {
  .dashboard_viewerList__2dta6 {
    max-height: 150px;
  }
}

@media only screen and (min-height: 741px) and (max-height: 840px) {
  .dashboard_viewerList__2dta6 {
    max-height: 180px;
  }
}

@media only screen and (min-height: 841px) and (max-height: 940px) {
  .dashboard_viewerList__2dta6 {
    max-height: 250px;
  }
}

@media only screen and (min-height: 941px) and (max-height: 1040px) {
  .dashboard_viewerList__2dta6 {
    max-height: 300px;
  }
}

@media only screen and (min-height: 1041px) and (max-height: 1140px) {
  .dashboard_viewerList__2dta6 {
    max-height: 380px;
  }
}

@media only screen and (min-height: 1141px) {
  .dashboard_viewerList__2dta6 {
    max-height: 450px;
  }
}

.step-status_stepStatus__1srhe {
  color: #fff;
  margin-bottom: 1em;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .step-status_stepStatus__1srhe {
    text-align: center;
  }
}

.forgot-password_signInLink__xbTjW {
  color: #808080 !important;
}

.getting-started_pageContainer__3QwQf {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}

.getting-started_page__JNre- {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .getting-started_page__JNre- {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .getting-started_page__JNre- {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .getting-started_page__JNre- {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .getting-started_page__JNre- {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .getting-started_page__JNre- {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .getting-started_page__JNre- {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .getting-started_page__JNre- {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .getting-started_page__JNre- {
      padding-right: 40px;
    }
  }
}

.getting-started_pageContent__2rkGI {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.getting-started_pageContent__2rkGI,
.getting-started_pageContent__2rkGI * {
  font-family: 'Montserrat';
}

.getting-started_gettingStarted___TdsW {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  position: relative;
}

@media only screen and (min-width: 768px) {
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-top: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-bottom: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(12px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(16px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(20px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(24px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(28px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(32px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(36px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: max(40px, env(safe-area-inset-left));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-left: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(12px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(16px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(20px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(24px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(28px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(32px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(36px, env(safe-area-inset-right));
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: max(40px, env(safe-area-inset-right));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .getting-started_gettingStarted___TdsW {
        padding-right: 40px;
      }
    }
  }
}

.getting-started_message__REX26 {
  color: #fff;
  font-weight: 900;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .getting-started_message__REX26 {
    font-size: 34px;
    margin-bottom: 20px;
    margin-top: 64px;
  }
  .getting-started_actionsContainer__sABSa {
    grid-gap: 16px;
    gap: 16px;
    -ms-grid-columns: 274px;
        grid-template-columns: 274px;
    margin-bottom: 64px;
    margin-top: 32px;
  }
  .getting-started_action__zcOdX {
    border-width: 3px;
    height: 160px;
    padding: 24px;
  }
  .getting-started_actionImage__AtXsP {
    width: 53px;
  }
  .getting-started_actionText__3_BfJ {
    font-size: 12px;
    margin-left: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .getting-started_message__REX26 {
    font-size: 35px;
    margin-bottom: 25px;
  }
  .getting-started_actionsContainer__sABSa {
    grid-gap: 20px;
    gap: 20px;
    -ms-grid-columns: 243.75px 243.75px;
        grid-template-columns: 243.75px 243.75px;
  }
  .getting-started_action__zcOdX {
    border-width: 2px;
    height: 243.75px;
    padding: 15px;
  }
  .getting-started_actionImage__AtXsP {
    height: 72.5px;
    width: 72.5px;
  }
  .getting-started_actionText__3_BfJ {
    font-size: 12.5px;
    margin-top: 25.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .getting-started_message__REX26 {
    font-size: 42px;
    margin-bottom: 30px;
  }
  .getting-started_actionsContainer__sABSa {
    grid-gap: 24px;
    gap: 24px;
    -ms-grid-columns: 292.5px 292.5px;
        grid-template-columns: 292.5px 292.5px;
  }
  .getting-started_action__zcOdX {
    border-width: 2px;
    height: 292.5px;
    padding: 18px;
  }
  .getting-started_actionImage__AtXsP {
    height: 87px;
    width: 87px;
  }
  .getting-started_actionText__3_BfJ {
    font-size: 15px;
    margin-top: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .getting-started_message__REX26 {
    font-size: 49.875px;
    margin-bottom: 35.625px;
  }
  .getting-started_actionsContainer__sABSa {
    grid-gap: 28.5px;
    gap: 28.5px;
    -ms-grid-columns: 347.34375px 347.34375px;
        grid-template-columns: 347.34375px 347.34375px;
  }
  .getting-started_action__zcOdX {
    border-width: 2.671875px;
    height: 347.34375px;
    padding: 21.375px;
  }
  .getting-started_actionImage__AtXsP {
    height: 103.3125px;
    width: 103.3125px;
  }
  .getting-started_actionText__3_BfJ {
    font-size: 17.8125px;
    margin-top: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .getting-started_message__REX26 {
    font-size: 56px;
    margin-bottom: 40px;
  }
  .getting-started_actionsContainer__sABSa {
    grid-gap: 32px;
    gap: 32px;
    -ms-grid-columns: 390px 390px;
        grid-template-columns: 390px 390px;
  }
  .getting-started_action__zcOdX {
    border-width: 3px;
    height: 390px;
    padding: 24px;
  }
  .getting-started_actionImage__AtXsP {
    height: 116px;
    width: 116px;
  }
  .getting-started_actionText__3_BfJ {
    font-size: 20px;
    margin-top: 41px;
  }
}

.getting-started_actionsContainer__sABSa {
  display: -ms-grid;
  display: grid;
}

.getting-started_action__zcOdX {
  align-items: center;
  border-color: #5aa5ef;
  border-radius: 10px;
  border-style: solid;
  color: #fff;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .getting-started_action__zcOdX {
    flex-direction: column;
  }
}

.getting-started_actionImage__AtXsP {
  flex-shrink: 0;
  object-fit: contain;
  object-position: center;
}

.getting-started_actionText__3_BfJ {
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .getting-started_actionText__3_BfJ {
    text-align: left;
  }
}

@media only screen and (min-width: 768px) {
  .getting-started_actionText__3_BfJ {
    text-align: center;
  }
}

.header_header__Mk9sm {
  width: 100%;
}

.header_headerContent__3CxP6 {
  align-items: center;
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .header_headerContent__3CxP6 {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .header_headerContent__3CxP6 {
    justify-content: space-between;
  }
}

.header_homeLink__FmV9R {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  text-decoration: none;
}

.header_headerLinksContainer__1ONmY {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header_headerLinksContainer__1ONmY {
    margin-top: 44px;
  }
}

.header_headerLink__1jht2 {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .header_headerLink__1jht2 {
    font-size: 12px;
  }
  .header_headerLink__1jht2:first-of-type {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_headerLink__1jht2 {
    margin-left: 16.40625px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_headerLink__1jht2 {
    margin-left: 21.875px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_headerLink__1jht2 {
    margin-left: 16px;
    font-size: 16px !important;
    height: 40px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_headerLink__1jht2 {
    margin-left: 20px;
    font-size: 20px !important;
    height: 48px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .header_headerLink__1jht2 {
    margin-left: 24px;
    font-size: 24px !important;
    height: 56px !important;
  }
}

.footer_footer__TsNWN {
  width: 100%;
}

.footer_footerContent__36NmJ {
  color: #cecece;
  font-weight: 300;
  letter-spacing: 0.75px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .footer_footerContent__36NmJ {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footer_footerContent__36NmJ {
    margin-top: 72px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_footerContent__36NmJ {
    margin-top: 85.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_footerContent__36NmJ {
    margin-top: 96px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_brandName__25RsG {
    margin-bottom: 96px;
    margin-top: 76.80000000000001px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .footer_brandName__25RsG {
    margin-bottom: 96px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_brandName__25RsG {
    margin-bottom: 114px;
    margin-top: 171px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_brandName__25RsG {
    margin-bottom: 128px;
    margin-top: 192px;
  }
}

.footer_linksContainer__Pcbj5 {
  /* display: -ms-grid; */
  /* display: grid; */
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .footer_linksContainer__Pcbj5 {
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .footer_linksContainer__Pcbj5 {
    -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .footer_linksContainer__Pcbj5 {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .footer_linksContainer__Pcbj5 {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    justify-content: space-between;
  }
}

.footer_link__1_2T6 {
  color: #cecece;
  display: table-cell;
  text-align: center;
}

@media all and (hover: hover) {
  .footer_link__1_2T6:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .footer_link__1_2T6:active, .footer_link__1_2T6:target {
    color: #fff;
    text-decoration: underline;
  }
}

.footer_copyright__3EAzm {
  margin-top: 64px;
  text-align: center;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer_copyright__3EAzm {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer_copyright__3EAzm {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer_copyright__3EAzm {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer_copyright__3EAzm {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer_copyright__3EAzm {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer_copyright__3EAzm {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer_copyright__3EAzm {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer_copyright__3EAzm {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer_copyright__3EAzm {
      padding-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .footer_link__1_2T6,
  .footer_copyright__3EAzm {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footer_link__1_2T6,
  .footer_copyright__3EAzm {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_link__1_2T6,
  .footer_copyright__3EAzm {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_link__1_2T6,
  .footer_copyright__3EAzm {
    font-size: 22px;
  }
}

.footer_linkWrapper__1W5ef {
  display: table;
  width: 100%;
  table-layout: fixed; 
}

.page_page__3KwlB {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  color: #fff;
  position: relative;
  width: 100%;
}

.page_page__3KwlB *, .page_page__3KwlB {
  font-family: 'Montserrat', sans-serif !important;
}

.page_pageContent__gC1BE {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__gC1BE {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__gC1BE {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__gC1BE {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__gC1BE {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__gC1BE {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__gC1BE {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__gC1BE {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__gC1BE {
      padding-right: 40px;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page_pageContent__gC1BE {
    max-width: 1400px;
  }
}

.page_mainContent__359wq {
  margin: auto;
  max-width: 1920px !important;
  width: 100%;
}

.home_heroContainer__34cVK {
  margin: 48px auto 0 auto;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .home_heroContainer__34cVK {
    display: flex;
    justify-content: space-between;
  }
}

.home_heroImageContainer__2RslK {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .home_heroImageContainer__2RslK.home_strechEdge__rcRlj {
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .home_heroImageContainer__2RslK.home_strechEdge__rcRlj {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .home_heroImageContainer__2RslK.home_strechEdge__rcRlj {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .home_heroImageContainer__2RslK.home_strechEdge__rcRlj {
    min-height: 414px;
  }
}

.home_heroImage__2cKme {
  flex-shrink: 0;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  /* opacity: 0; */
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .home_heroImage__2cKme {
    height: 399px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .home_heroImage__2cKme {
    height: 100%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_heroImage__2cKme {
    height: 473.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_heroImage__2cKme {
    height: 532px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_heroImageContainer__2RslK,
  .home_heroText__3Sic6 {
    width: calc(50% - (50px / 2));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_heroImageContainer__2RslK,
  .home_heroText__3Sic6 {
    width: calc(50% - (60px / 2));
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_heroImageContainer__2RslK,
  .home_heroText__3Sic6 {
    width: calc(50% - (71.25px / 2));
  }
}

@media only screen and (min-width: 1710px) {
  .home_heroImageContainer__2RslK,
  .home_heroText__3Sic6 {
    width: calc(50% - (80px / 2));
  }
}

.home_heroText__3Sic6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_heroHeading__1nBm1 {
  color: #1172d2;
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 899px) {
  .home_heroHeading__1nBm1 {
    font-size: 26.875px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .home_heroHeading__1nBm1 {
    font-size: 32.25px;
    margin-bottom: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_heroHeading__1nBm1 {
    font-size: 39.421875px;
    margin-bottom: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_heroHeading__1nBm1 {
    font-size: 45px;
    margin-bottom: 26px;
  }
}

.home_heroSubheading__1PalA {
  color: #fff;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .home_heroSubheading__1PalA {
    font-size: 26.875px;
    margin-bottom: 16.25px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .home_heroSubheading__1PalA {
    font-size: 32.25px;
    line-height: 30px !important;
    margin-bottom: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_heroSubheading__1PalA {
    font-size: 39.421875px;
    line-height: 40px !important;
    margin-bottom: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_heroSubheading__1PalA {
    font-size: 45px;
    line-height: 40px !important;
    margin-bottom: 26px;
  }
}

.home_heroDescription__UXey- {
  color: #cacaca;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .home_heroDescription__UXey- {
    font-size: 17.5px;
    margin-bottom: 16.25px;
    margin-top: 12.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .home_heroDescription__UXey- {
    font-size: 22px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_heroDescription__UXey- {
    font-size: 28px;
    margin-top: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_heroDescription__UXey- {
    font-size: 28px;
    margin-top: 20px;
  }
}

.home_planPricingDetails__1PfqS .home_planPricingDetailsButton__2uR7c {
  border-width: 2px;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 auto;
  width: 80%;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 767px) {
  .home_planPricingDetails__1PfqS .home_planPricingDetailsButton__2uR7c {
    line-height: 1.2;
    width: 100% !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_planPricingDetails__1PfqS .home_planPricingDetailsButton__2uR7c {
    height: 64px;
    line-height: 1.2;
  }
}

.home_planPricingDetails__1PfqS .home_signUpLink__3yZh2 {
  color: #fff;
  display: block;
  font-weight: 300;
  letter-spacing: 0.75px;
  margin-top: 3em;
  text-align: center;
  text-decoration: underline;
}

.home_devices__1Pddd {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .home_devices__1Pddd {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_devices__1Pddd {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_devices__1Pddd {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_devices__1Pddd {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_devices__1Pddd {
    max-width: 1536px;
  }
}

.home_devicesTitle__2Lqnr {
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .home_devicesTitle__2Lqnr {
    font-size: 20px;
    margin-bottom: 33.599999999999994px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .home_devicesTitle__2Lqnr {
    font-size: 20px;
    margin-bottom: 39.375px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_devicesTitle__2Lqnr {
    font-size: 22.875px;
    margin-bottom: 52.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_devicesTitle__2Lqnr {
    font-size: 26.25px;
    margin-bottom: 62.99999999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_devicesTitle__2Lqnr {
    font-size: 31.171875000000004px;
    margin-bottom: 74.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_devicesTitle__2Lqnr {
    font-size: 35px;
    margin-bottom: 84px;
  }
}

@media only screen and (max-width: 767px) {
  .home_deviceList__1ydxY {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .home_deviceList__1ydxY {
    display: flex;
    justify-content: space-around;
  }
}

.home_deviceItem__vwm0C {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .home_deviceItem__vwm0C {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .home_deviceItem__vwm0C {
    flex-basis: 33.33%;
  }
}

.home_deviceFrame__1uxnY {
  color: #fff;
  height: 128px;
  max-width: 128px;
  object-fit: contain;
  object-position: center bottom;
  position: relative;
  width: 100%;
}

.home_deviceCategory__1gu4V {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

@media only screen and (max-width: 1440px) {
  .home_deviceCategory__1gu4V {
    font-size: 21px;
  }
}

@media only screen and (max-width: 1440px) {
  .home_deviceCategory__1gu4V {
    margin: 33.28125px 0 26.71875px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_deviceCategory__1gu4V {
    margin: 44.375px 0 35.625px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_deviceCategory__1gu4V {
    margin: 53.25px 0 42.75px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_deviceCategory__1gu4V {
    font-size: 24.9375px;
    margin: 63.234375px 0 50.765625px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .home_deviceCategory__1gu4V {
    font-size: 28px;
    margin: 71px 0 57px 0;
  }
}

.home_deviceNameList__1f9N1 {
  position: relative;
}

.home_deviceName__2uN-p {
  color: #cecece;
  font-weight: 300;
}

@media only screen and (max-width: 1199px) {
  .home_deviceName__2uN-p {
    font-size: 16px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_deviceName__2uN-p {
    font-size: 16.5px;
    margin-bottom: 3.33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_deviceName__2uN-p {
    font-size: 19.59375px;
    margin-bottom: 3.7px;
  }
}

@media only screen and (min-width: 1710px) {
  .home_deviceName__2uN-p {
    font-size: 22px;
    margin-bottom: 4px;
  }
}

.home_pageEndDetails__1l6FB {
  display: flex;
  justify-content: center;
  background-image: url(/static/media/FooterImg.88d9e5f3.png);
  align-items: center;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .home_pageEndDetails__1l6FB {
    /* margin-bottom: 25.6px; */
    /* margin-top: 76.80000000000001px; */
    height: 50vh;
    background-size: 100% 100%;
  }
}

/* 
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .pageEndDetails {
    margin-bottom: 30px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageEndDetails {
    margin-bottom: 40px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageEndDetails {
    margin-bottom: 48px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageEndDetails {
    margin-bottom: 57px;
    margin-top: 171px;
  }
}

@media only screen and (min-width: 1710px) {
  .pageEndDetails {
    margin-bottom: 64px;
    margin-top: 192px;
  }
}
*/
.home_pageEndSignUpLink__1gLY9 {
  letter-spacing: 0.5px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 16px !important;
    height: 40px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 20px !important;
    height: 48px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .home_pageEndSignUpLink__1gLY9 {
    font-size: 24px !important;
    height: 56px !important;
  }
}

.home_brandName__3S7as {
  height: 67px;
}

.video-autoplayer_videoPlayer__3JRte {
    /* position: absolute; */
    background: transparent;
    /* z-index: 10; */
    transition: transform 0.5s cubic-bezier(0,0,0.2,1),opacity 0.5s cubic-bezier(0,0,0.2,1),-webkit-transform .5s cubic-bezier(0,0,0.2,1),opacity .5s cubic-bezier(0,0,0.2,1);
    transition: all 0.5s ease-in-out;
    aspect-ratio: 16/10;
  }

  
.video-autoplayer_videoPlayerWraper__PflbM {
  width: 100%;
  height: 200px;
  position: relative;
}

.video-autoplayer_thumbnailClass__2RWJj {
  width: 100%;
  height: 100%;
}

.video-autoplayer_react_player__E67Js {
  z-index: 0;
  height: 100% !important;
  background: #07080c !important;
}

.video-autoplayer_detailsWrapper__20JDD {
  position: relative;
  top: 160px;
  color: #fff;
  /* padding: 0 10px; */
  background-color: transparent;
}

.video-autoplayer_timeStamp__3xwSI {
  font-family: 'Montserrat';
  font-size: 13px;
}

.video-autoplayer_thumbNail__1sLyT {
  position: relative;
  width: 100%;
  top: 0px;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;

}
.video-autoplayer_thumbNail__1sLyT:hover{
  border: 2px solid white;
}
.ImageGroup_pageContainer__VazbM {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}
/* .previewTitle .title2{
  margin-top: 60px;

} */
.ImageGroup_gridz__3mi1U{
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 310px);
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
}
.ImageGroup_mainContent__JmeF0 {
  flex-grow: 1;
  width: 100%;
}

.ImageGroup_mainContent__JmeF0.ImageGroup_loading__2PhgL {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ImageGroup_heading__2Zt79 {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .ImageGroup_heading__2Zt79 {
    height: 183px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_heading__2Zt79 {
    border-radius: 13.125px;
    height: 159.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_heading__2Zt79 {
    border-radius: 15.749999999999998px;
    height: 191.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_heading__2Zt79 {
    border-radius: 18.703125px;
    height: 227.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_heading__2Zt79 {
    border-radius: 21px;
    height: 255px;
  }
}

.ImageGroup_headingBG__2OOM3 {
  border: none;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageGroup_headingBGFade__21BLi {
  background-color: rgba(255, 255, 255, 0.2);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageGroup_headingText__1eQrT {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: uppercase;
  /* z-index: 10; */
}

@media only screen and (max-width: 767px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .ImageGroup_headingText__1eQrT {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 25.78125px;
    margin-right: 33.75px;
    max-width: 240px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 34.375px;
    margin-right: 45px;
    max-width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 41.25px;
    margin-right: 54px;
    max-width: 384px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 48.984375px;
    margin-right: 64.125px;
    max-width: 456px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_headingText__1eQrT {
    font-size: 55px;
    margin-right: 72px;
    max-width: 512px;
  }
}

.ImageGroup_imageGroup__2g6IE {
  position: relative;

  margin-bottom: 1%;
  /* margin-bottom: 3%; */
  padding-top: 1rem;
}

.ImageGroup_paddingTop0__K8jjl{
  padding-top: 0;
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroup {
      padding-top: max(8px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroup {
      padding-top: max(10px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroup {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroup {
      padding-top: max(14px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroup {
      padding-top: max(16px, env(safe-area-inset-top));
      margin-left: -45px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroup {
      padding-top: max(18px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroup {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroup {
      padding-top: max(22px, env(safe-area-inset-top));
    }
  }
} */

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 8px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 10px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 14px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 18px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroup__2g6IE {
      padding-top: 22px;
    }
  }
}

.ImageGroup_imageGroupTitle__23__G {
  color: #faf7f7;
}

/* .marginTitle {
  margin: 0;
} */
.ImageGroup_paddingTopForImage__RlltC {
  padding-top: 35px !important;
}

.ImageGroup_imageGroupTitle__23__G::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupTitle__23__G {
    font-size: 28px;
  }
}

.ImageGroup_imageGroupImagesWrapper__1Wwtq {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
.ImageGroup_imageGroupImagesAllVideo__1k-wE {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesWrapper__1Wwtq {
    --site-padding: 82.5px !important;
  }
  .ImageGroup_imageGroupImagesAllVideo__1k-wE {
    --site-padding: 82.5px !important;
  }
}
@media only screen and (max-width: 709px) {
  .ImageGroup_imageGroupImagesWrapper__1Wwtq {
    padding-bottom: 30px;
  }
  .ImageGroup_imageGroupImagesAllVideo__1k-wE {
    padding-bottom: 100px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesWrapper__1Wwtq {
    --site-padding: 97.96875px !important;
  }
  .ImageGroup_imageGroupImagesAllVideo__1k-wE {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesWrapper__1Wwtq {
    --site-padding: 110px !important;
  }
  .ImageGroup_imageGroupImagesAllVideo__1k-wE {
    --site-padding: 110px !important;
  }
}

.ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) {
      padding-right: var(--site-padding);
    }
  }
}

.ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImagesContent__2V4gS {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_imageGroupImageLink__QZ3LJ,
  .ImageGroup_imageGroupImagesContainer__W6o8Q:not(.ImageGroup_isGrid__2DN1l) .ImageGroup_categoryButton__Z2A14 {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l {
  display: block;
}

.ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImagesContent__2V4gS {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .ImageGroup_carousels__2mOvj{
    justify-content: center !important;
  }
}

.ImageGroup_imageGroupImagesContainer__W6o8Q.ImageGroup_isGrid__2DN1l .ImageGroup_imageGroupImageLink__QZ3LJ {
  width: 100%;
}

.ImageGroup_imageGroupImageLink__QZ3LJ {
  color: #ffffff;
}


.ImageGroup_imageGroupImageLink__QZ3LJ h4::first-letter {
  text-transform: uppercase;
}

.ImageGroup_videoPlayer__FbuT1 {
  /* position: absolute; */
  width: 350px;
  height: 300px;
  background-color: red;
  z-index: 10;
  transition: transform .1s cubic-bezier(0,0,0.2,1),opacity .1s cubic-bezier(0,0,0.2,1),-webkit-transform .1s cubic-bezier(0,0,0.2,1),opacity .1s cubic-bezier(0,0,0.2,1);
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
} */

.ImageGroup_imageGroupImageLink__QZ3LJ.ImageGroup_dummy__3jcjv {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}
.ImageGroup_imageGroupImageCarousel__1zsDJ {
  height: 250px;
}

.ImageGroup_imageGroupImage__3KmXH {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 5px;
  z-index: 1 !important;
}
.ImageGroup_imageGroupImage__3KmXH:hover {
  border: 2px solid white;
}
.ImageGroup_imageGroupImageInner__3wOJx {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
  border-radius: 5px;
  z-index: 1 !important;
}
.ImageGroup_imageGroupImageInner__3wOJx:hover {
  border: 2px solid white;
}
.ImageGroup_albumTransition__2q3Ia:hover {
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 8px 4px 8px 4px rgba(0, 0, 0, 0.2), 20px 6px 20px 6px rgba(0, 0, 0, 0.19);
}


.ImageGroup_imageGroupImageLink__QZ3LJ.ImageGroup_categoryButton__Z2A14::before {
  display: none !important;
}

.ImageGroup_categoriesLoader__1XeA8 {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 256px;
}

.ImageGroup_categoryButton__Z2A14 {
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.ImageGroup_categoryButton__Z2A14.ImageGroup_active__doz76 {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 14px;
    height: 79.21875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 14px;
    height: 105.62500000000001px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 17.5px;
    height: 126.75000000000001px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 21px;
    height: 150.515625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 24.9375px;
    height: 169px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_categoryButton__Z2A14 {
    font-size: 28px;
    height: 110px;  }
}

.ImageGroup_categoryButton__Z2A14.ImageGroup_dummy__3jcjv {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.ImageGroup_carousel-container__3rIej {
  width: 500px;
  height: 200px;
}

.ImageGroup_item-align__2VzaO {
  padding: 20px 0 20px 20px;
}
.ImageGroup_image-item-isGrid__3Qlh5 {
  flex: none !important ;
}

.ImageGroup_gallery__1SYeL {
  float: left;
  width: 300px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
}

.ImageGroup_headingWrapper__2tpLK {
  display: flex;
  align-items: center;
}

.ImageGroup_clearAll__2Tw36 {
  color: #faf7f7;
  margin-left: 15px;
  /* margin-top: 60px; */
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .ImageGroup_clearAll__2Tw36 {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 32px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_clearAll__2Tw36 {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 32px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_clearAll__2Tw36 {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 76px; */
    cursor: pointer;
    font-size: small;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_clearAll__2Tw36 {
      color: #faf7f7;
      margin-left: 15px;
      /* margin-top: 86px; */
      cursor: pointer;
      font-size: small;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_clearAll__2Tw36 {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 26px; */
    cursor: pointer;
    
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_clearAll__2Tw36 {
    color: #faf7f7;
    margin-left: 15px;
    /* margin-top: 26px; */
    cursor: pointer;
  }
}

.ImageGroup_imageGroupImagePreview__1XE14 {
  height: calc(100% - 76px) !important;
  
}

.ImageGroup_previewTitle__1sUhH {
  position: relative;
  bottom: 40px;
}

.ImageGroup_hoverZoom__3lEel:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.ImageGroup_hoverZoom__3lEel:hover > a > :first-child {
  border: white 2px solid;
  border-radius: 5px;
}

.ImageGroup_imageGroupImagesContainer__W6o8Q {
  /* padding-right: max(var(--site-padding), env(safe-area-inset-right)); */
}

/* .left-arrow {
  right: calc(1% + -10px);
  position: fixed;
  z-index: 1000;
  margin-left: -2px;
  scale: 0.7;
  margin-top: -10px;
} */

.ImageGroup_carouselGrid__3JMqC {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
}

.ImageGroup_pageContainer__teRmy {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.ImageGroup_mainContent__2iBEN {
  flex-grow: 1;
  width: 100%;
}

.ImageGroup_mainContent__2iBEN.ImageGroup_loading__33WYK {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ImageGroup_heading__x9mt6 {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .ImageGroup_heading__x9mt6 {
    height: 183px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_heading__x9mt6 {
    border-radius: 13.125px;
    height: 159.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_heading__x9mt6 {
    border-radius: 15.749999999999998px;
    height: 191.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_heading__x9mt6 {
    border-radius: 18.703125px;
    height: 227.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_heading__x9mt6 {
    border-radius: 21px;
    height: 255px;
  }
}

.ImageGroup_headingBG__3DVrl {
  border: none;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageGroup_headingBGFade__3ZKbw {
  background-color: rgba(255, 255, 255, 0.2);
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ImageGroup_headingText__3qbks {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: uppercase;
  z-index: 10;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_headingText__3qbks {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .ImageGroup_headingText__3qbks {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_headingText__3qbks {
    font-size: 25.78125px;
    margin-right: 33.75px;
    max-width: 240px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_headingText__3qbks {
    font-size: 34.375px;
    margin-right: 45px;
    max-width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_headingText__3qbks {
    font-size: 41.25px;
    margin-right: 54px;
    max-width: 384px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_headingText__3qbks {
    font-size: 48.984375px;
    margin-right: 64.125px;
    max-width: 456px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_headingText__3qbks {
    font-size: 55px;
    margin-right: 72px;
    max-width: 512px;
  }
}

.ImageGroup_imageGroup__BKWk9 {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(8px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(10px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(14px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(18px, env(safe-area-inset-top));
      
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: max(22px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 8px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 10px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 14px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 18px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroup__BKWk9 {
      padding-top: 22px;
    }
  }
}

.ImageGroup_imageGroupTitle__39_D8 {
  color: #faf7f7;
}

.ImageGroup_marginTitle__3jITX {
  margin: 0;
}
.ImageGroup_paddingTopForImage__Vz9S5 {
  padding-top: 35px !important;
}

.ImageGroup_imageGroupTitle__39_D8::first-letter {
  text-transform: uppercase;
  
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 14px;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 17.5px;
    
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupTitle__39_D8 {
    font-size: 28px;
  }
}

.ImageGroup_imageGroupImagesWrapper__1aP-T {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesWrapper__1aP-T {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesWrapper__1aP-T {
    --site-padding: 97.96875px !important;
    /* margin-top: -80px; */
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesWrapper__1aP-T {
    --site-padding: 110px !important;
  }
}

.ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) {
      padding-right: var(--site-padding);
    }
  }
}

.ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImagesContent__3swSB {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_imageGroupImageLink__14uKF,
  .ImageGroup_imageGroupImagesContainer__2GEuB:not(.ImageGroup_isGrid__1KCTo) .ImageGroup_categoryButton__3lFK- {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo {
  display: block;
}

.ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImagesContent__3swSB {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.ImageGroup_imageGroupImagesContainer__2GEuB.ImageGroup_isGrid__1KCTo .ImageGroup_imageGroupImageLink__14uKF {
  width: 100%;
}

.ImageGroup_imageGroupImageLink__14uKF {
  color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.ImageGroup_imageGroupImageLink__14uKF::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.ImageGroup_imageGroupImageLink__14uKF div {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: left;
}

.ImageGroup_imageGroupImageLink__14uKF h4::first-letter {
  text-transform: uppercase;
}

/* @supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .imageGroupImageLink:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
} */

.ImageGroup_imageGroupImageLink__14uKF.ImageGroup_dummy__1Bou5 {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.ImageGroup_imageGroupImage__3omZp {
  height: calc(100% - 36px);
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
  border-radius: 5px;
}
.ImageGroup_imageGroupImage__3omZp:hover {
  border: 2px solid white;
}

.ImageGroup_imageGroupImageLink__14uKF:hover {
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 8px 4px 8px 4px rgba(0, 0, 0, 0.2), 20px 6px 20px 6px rgba(0, 0, 0, 0.19);
  z-index: 1000;
}


.ImageGroup_imageGroupImageLink__14uKF.ImageGroup_categoryButton__3lFK-::before {
  display: none !important;
}

.ImageGroup_categoriesLoader__2aa1N {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 256px;
}

.ImageGroup_categoryButton__3lFK- {
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.ImageGroup_categoryButton__3lFK-.ImageGroup_active__2GOb5 {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 14px;
    height: 79.21875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 14px;
    height: 105.62500000000001px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 17.5px;
    height: 126.75000000000001px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 21px;
    height: 150.515625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 24.9375px;
    height: 169px;
  }
}

@media only screen and (min-width: 1710px) {
  .ImageGroup_categoryButton__3lFK- {
    font-size: 28px;
    height: 169px;
  }
}

.ImageGroup_categoryButton__3lFK-.ImageGroup_dummy__1Bou5 {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.ImageGroup_carousel-container__2EwmA {
  width: 500px;
  height: 200px;
}

.ImageGroup_item-align__4mhfV {
  padding: 20px 0 20px 20px;
}
.ImageGroup_image-item__2KaNr {
  padding: 20px 0 20px 20px;
}
.ImageGroup_gallery__1xkJo {
  float: left;
  width: 300px !important;
  margin-right: 20px !important;
  margin-top: 30px !important;
}

.ImageGroup_headingWrapper__6IyWk {
  display: flex;
}

.ImageGroup_clearAll__2zA0A {
  color: #faf7f7;
  margin-left: 15px;
  margin-top: 8px;
  cursor: pointer;
}

.my-profile_pageContainer__2fa_J {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.my-profile_pageContainerVideoBanner__3Kr38 {
  background: #0A0B0F !important;
}

.my-profile_mainContent__2jxvH {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.my-profile_mainContent__2jxvH.my-profile_loading__1dFeJ {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.my-profile_heading__1irQh, .my-profile_whatsNewHeading__2L5gY, .my-profile_imageBannerHeading__3q8Mw {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}

.my-profile_heading__1irQh {
  margin-bottom: 4%;
}

@media only screen and (max-width: 435px) {
  .my-profile_whatsNewHeading__2L5gY {
    height: 270px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 209px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 767) {
  .my-profile_imageBannerHeading__3q8Mw {
    height: 330px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 899px) {
  .my-profile_whatsNewHeading__2L5gY {
    height: 240px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 330px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_heading__1irQh {
    border-radius: 13.125px;
  }
  .my-profile_whatsNewHeading__2L5gY {
    height: 250px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_heading__1irQh {
    border-radius: 15.749999999999998px;
  }
  .my-profile_whatsNewHeading__2L5gY {
    height: 260px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 300px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_heading__1irQh {
    border-radius: 18.703125px;
  }
  .my-profile_whatsNewHeading__2L5gY {
    height: 262px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 300px;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_heading__1irQh {
    border-radius: 21px;
  }
  .my-profile_whatsNewHeading__2L5gY {
    height: 262px;
  }
}

.my-profile_headingBG__1hBMd {
  border: none;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.my-profile_headingBGFade__l0FKh {
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.my-profile_headingText__3wVrH {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: capitalize;
  z-index: 10;
  display: table-cell;
  vertical-align: middle;
  font-size: 2.2rem;
}

.my-profile_subHeadingText__21qBm {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: capitalize;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}

.my-profile_noContentText__2PxD2 {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}

@media only screen and (max-width: 767px) {
  .my-profile_headingText__3wVrH {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
  .my-profile_whatsNewHeadingText__3tYGF {
    font-size: 16px !important;
  }
  .my-profile_imageBannerHeadingText__16KAJ {
    font-size: 16px;
  }
  .my-profile_heroImage__2KVAk {
    height: 180px !important;
  }
  /* .albumBannerImage {
    height: 10px !important;
  } */
}

@media only screen and (min-width: 768px) {
  .my-profile_headingText__3wVrH {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_headingText__3wVrH {
    font-size: 25.78125px;
    max-width: 440px;
    width: 55%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_headingText__3wVrH {
    font-size: 34.375px;
    max-width: 540px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_headingText__3wVrH {
    font-size: 41.25px;
    max-width: 640px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_headingText__3wVrH {
    font-size: 30px;
    width: 40%;
    max-width: 740px;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_headingText__3wVrH {
    font-size: 55px;
    max-width: 850px;
  }
  .my-profile_imageBannerHeading__3q8Mw {
    height: 300px;
  }
}

.my-profile_imageGroup__vS-PI {
  position: relative;
  margin-bottom: 3%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -99px;
      margin-bottom: -63px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -99px;
      margin-bottom: -63px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -92px;
      margin-bottom: -67px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -92px;
      margin-bottom: -67px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -95px;
      margin-bottom: -69px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -95px;
      margin-bottom: -69px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -90px;
      margin-bottom: -60px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -90px;
      margin-bottom: -60px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -90px;
      margin-bottom: -47px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_layoutImageGroup__WnrsG {
      margin-top: -65px;
      margin-bottom: -54px;
    }
    .my-profile_videoBannerImageGroup__lao1E {
      margin-top: -65px;
      margin-bottom: -54px;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroup__vS-PI {
      padding-top: 40px;
    }
  }
}

.my-profile_imageGroupTitle__3VPta {
  color: #faf7f7;
}

.my-profile_imageGroupTitle__3VPta::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_imageGroupTitle__3VPta {
    font-size: 28px;
  }
}

.my-profile_imageGroupImagesWrapper__2jSDy {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_imageGroupImagesWrapper__2jSDy {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_imageGroupImagesWrapper__2jSDy {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_imageGroupImagesWrapper__2jSDy {
    --site-padding: 110px !important;
  }
}

.my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) {
      padding-right: var(--site-padding);
    }
  }
}

.my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImagesContent__3q-aQ {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_imageGroupImageLink__VkylY,
  .my-profile_imageGroupImagesContainer__1jm5t:not(.my-profile_isGrid__3kDR5) .my-profile_categoryButton__1eRj0 {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 {
  display: block;
}

.my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImagesContent__3q-aQ {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.my-profile_imageGroupImagesContainer__1jm5t.my-profile_isGrid__3kDR5 .my-profile_imageGroupImageLink__VkylY {
  width: 100%;
}

.my-profile_imageGroupImageLink__VkylY {
  color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.my-profile_imageGroupImageLink__VkylY::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.my-profile_imageGroupImageLink__VkylY div {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: left;
}

.my-profile_imageGroupImageLink__VkylY:hover {
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 7px 4px 6px 4px rgba(0, 0, 0, 0.3), 7px 4px 6px 4px rgba(0, 0, 0, 0.3);
}


.my-profile_imageGroupImageLink__VkylY h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .my-profile_imageGroupImageLink__VkylY:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.my-profile_imageGroupImageLink__VkylY.my-profile_dummy__1cb8I {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.my-profile_imageGroupImageLink__VkylY.my-profile_categoryButton__1eRj0::before {
  display: none !important;
}

.my-profile_categoriesLoader__3Ov0V {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 256px;
}

.my-profile_categoryButton__1eRj0 {
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.my-profile_categoryButton__1eRj0.my-profile_active__20my0 {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 14px;
    height: 79.21875px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 40px;
    font-size: 10px;
  }
  .my-profile_albumTitle__334yT {
    font-size: 25px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 14px;
    height: 80.62500000000001px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 50px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 17.5px;
    height: 88.75000000000001px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 21px;
    height: 100.515625px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 24.9375px;
    height: 120px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_categoryButton__1eRj0 {
    font-size: 28px;
    height: 120px;
  }
  .my-profile_bannerTypeCategoryButton__10DzL {
    height: 50px;
    font-size: 12px;
  }
}

.my-profile_categoryButton__1eRj0.my-profile_dummy__1cb8I {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.my-profile_selectedCategoryButton__Uvu_7 {
  margin-top: 23px;
  border: 3px solid;
  border-radius: 6vh;
  /* height: 8vh; */
  border-color: #fff;
  display: flex;
  max-width: 180px;
  float: left;
}

.my-profile_selectedCategoryTitle__1Yvvk {
  /* font-size: 1vw; */
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 4px;
  display: flex;
  align-items: center;
}

.my-profile_closeButton__1RzbV {
  color: #fff;
  z-index: 100;
}

.my-profile_closeButtonWrapper__FL--d {
  display: flex;
  align-items: center;
  padding: 5px;
}

@media only screen and (max-width: 319px) {
  .my-profile_selectedCategoryButton__Uvu_7 {
    margin-top: 5px !important;
    height: 21px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 8px;
  }
  .my-profile_selectedCategoryButton__Uvu_7 {
    margin-top: 6px;
    height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 10px;
  }
  .my-profile_selectedCategoryButton__Uvu_7 {
    margin-top: 9px;
    height: 29px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 11px;
  }
  .my-profile_selectedCategoryButton__Uvu_7 {
    margin-top: 10px;
    height: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 12px;
  }
  .my-profile_selectedCategoryButton__Uvu_7 {
    margin-top: 15px;  
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .my-profile_selectedCategoryTitle__1Yvvk {
    font-size: 14px;
  }
  /* .headingText {
    margin-right: 110px !important;
  } */
}
/* .albumBannerContainer {
  width: 90%;
} */

.my-profile_textAlignFit__Icr0y {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.my-profile_headerWrapper__3sHMp {
  display: flex;
  width: 100%;
  margin-bottom: -10px;
}

.my-profile_imageBannerHeaderWrapper__1bD3T {
  display: flex;
  width: 100%;
  align-items: center;
}

.my-profile_whatsNewWrapper__3SW_B {
  width: 90%;
}

.my-profile_headingContainer__3M-Ek {
  display: table;
  width: 0%;
  margin-bottom: 300px;
}
.my-profile_imageBannerContainer__3wGZ3 {
  width: 70%;
  margin: auto;
}
.my-profile_imageBannerHeadingText__16KAJ {
  /* text-align: start !important; */
  /* vertical-align: top !important; */
    align-items: center;
    margin: auto;
    justify-content: center;
    position: absolute;
    /* width: 40%; */
    padding: 10px;
}

.my-profile_heroImage__2KVAk {
  width: 100%;
  height: 275px;
}

.my-profile_albumBannerImage__25RDc {
  width: 100%;
  height: 275px;
  position: relative;
  cursor: pointer;
}

.my-profile_albumTitle__334yT {
  position: absolute; 
  top: 30px; 
  left: 50px; 
  color: #fff;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.my-profile_rounded__2y6hB {
  border-radius: 50px;
}
.profile_pageContainer__14XVk {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.profile_pageContainerVideoBanner__1WBeb {
  background: #0a0b0f !important;
}
.profile_ending__2INrQ{
  display: flex;
  justify-content: end;
}
.profile_mainContent__2wQ4B {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.profile_marginBottom0__KUwJO {
  margin-bottom: 0 !important;
}
.profile_mainContent__2wQ4B.profile_loading__HcfuY {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile_heading__2qLmN,
.profile_whatsNewHeading__2wBQZ,
.profile_imageBannerHeading__x9wU9 {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}

.profile_heading__2qLmN {
  margin-bottom: 4%;
}

@media only screen and (max-width: 435px) {
  .profile_whatsNewHeading__2wBQZ {
    height: 270px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 209px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 767) {
  .profile_imageBannerHeading__x9wU9 {
    height: 330px;
  }
}

@media only screen and (min-width: 436px) and (max-width: 899px) {
  .profile_whatsNewHeading__2wBQZ {
    height: 240px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 330px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_heading__2qLmN {
    border-radius: 13.125px;
  }
  .profile_whatsNewHeading__2wBQZ {
    height: 250px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_heading__2qLmN {
    border-radius: 15.749999999999998px;
  }
  .profile_whatsNewHeading__2wBQZ {
    height: 260px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 300px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_heading__2qLmN {
    border-radius: 18.703125px;
  }
  .profile_whatsNewHeading__2wBQZ {
    height: 262px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 300px;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_heading__2qLmN {
    border-radius: 21px;
  }
  .profile_whatsNewHeading__2wBQZ {
    height: 262px;
  }
}

.profile_headingBG__1Dn_l {
  border: none;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.profile_headingBGFade__1RTCn {
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.profile_headingText__A7yW1 {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-transform: capitalize;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  padding-bottom: 36px;
  z-index: 10;
  font-size: 2.2rem;
}

.profile_subHeadingText__1PueM {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: capitalize;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}

.profile_noContentText__3JNDO {
  color: #ffffff;
  font-weight: 500;
  line-height: 1.2;
  margin-left: auto;
  /* position: relative; */
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  z-index: 10;
  display: flex;
  vertical-align: middle;
  font-size: 25px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* display: block; */
  margin: 70px 0;
}
.profile_bottomBar__1WpKt {
  display: none;
}
@media only screen and (max-width: 767px) {
  .profile_menu__1jt_o {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .profile_menu__1jt_o > p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 5px;
  font-family: "poppins";
  color: white;
  }
  .profile_bottomBar__1WpKt {
    position: fixed;
    z-index: 170;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: 100px;
    background: #0f0f0f;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid white;
  }
  .profile_headingText__A7yW1 {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
  .profile_whatsNewHeadingText__v_18z {
    font-size: 16px !important;
  }
  .profile_imageBannerHeadingText__34wdB {
    font-size: 16px;
  }
  .profile_heroImage__2gePE {
    height: 180px !important;
  }
}

@media only screen and (min-width: 768px) {
  .profile_headingText__A7yW1 {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_headingText__A7yW1 {
    font-size: 25.78125px;
    max-width: 440px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_headingText__A7yW1 {
    font-size: 34.375px;
    max-width: 540px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_headingText__A7yW1 {
    font-size: 41.25px;
    max-width: 640px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_headingText__A7yW1 {
    font-size: 48.984375px;
    max-width: 740px;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_headingText__A7yW1 {
    font-size: 55px;
    max-width: 850px;
  }
  .profile_imageBannerHeading__x9wU9 {
    height: 300px;
  }
}

.profile_imageGroup__ewaLL {
  position: relative;
  /* height: 230px; */
  /* margin-bottom: 3%; */
  margin-bottom: 1%;
}

@supports (padding: max(0px)) {
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -92px;
      margin-bottom: -67px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -95px;
      margin-bottom: -69px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -90px;
      margin-bottom: -60px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    /* .imageGroup {
      padding-top: max(36px, env(safe-area-inset-top));
    } */
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -90px;
      margin-bottom: -47px;
    }
  }
  @media only screen and (min-width: 1710px) {
    /* .imageGroup {
      padding-top: max(10px, env(safe-area-inset-top));
    } */
    .profile_layoutImageGroup__3H5d_ {
      margin-top: -65px;
      margin-bottom: -54px;
    }
  }
}

.profile_imageGroupTitle__1__EU {
  color: #faf7f7;
}

.profile_imageGroupTitle__1__EU::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_imageGroupTitle__1__EU {
    font-size: 28px;
  }
}

.profile_imageGroupImagesWrapper__3cw_L {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_imageGroupImagesWrapper__3cw_L {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_imageGroupImagesWrapper__3cw_L {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_imageGroupImagesWrapper__3cw_L {
    --site-padding: 110px !important;
  }
}

.profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) {
      padding-right: var(--site-padding);
    }
  }
}

.profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImagesContent__1Sq7G {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_imageGroupImageLink__3Trf_,
  .profile_imageGroupImagesContainer__bNDSZ:not(.profile_isGrid__2Opyu) .profile_categoryButton__OiL2l {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu {
  display: block;
}

.profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImagesContent__1Sq7G {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.profile_imageGroupImagesContainer__bNDSZ.profile_isGrid__2Opyu .profile_imageGroupImageLink__3Trf_ {
  width: 100%;
}

.profile_imageGroupImageLink__3Trf_ {
  color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.profile_imageGroupImageLink__3Trf_::before {
  content: " ";
  display: block;
  padding-top: 60%;
  width: 100%;
}

.profile_imageGroupImageLink__3Trf_ div {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: left;
}

.profile_imageGroupImageLink__3Trf_:hover {
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 8px 4px 8px 4px rgba(0, 0, 0, 0.2),
    20px 6px 20px 6px rgba(0, 0, 0, 0.19);
}

.profile_imageGroupImageLink__3Trf_ h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .profile_imageGroupImageLink__3Trf_:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.profile_imageGroupImageLink__3Trf_.profile_dummy__2fmLL {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.profile_imageGroupImageLink__3Trf_.profile_categoryButton__OiL2l::before {
  display: none !important;
}

.profile_categoriesLoader__1lN0l {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 256px;
}

.profile_categoryButton__OiL2l {
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.profile_categoryButton__OiL2l.profile_active__ALIip {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .profile_categoryButton__OiL2l {
    font-size: 14px;
    height: 79.21875px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 40px;
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_categoryButton__OiL2l {
    font-size: 14px;
    height: 80.62500000000001px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 50px;
    font-size: 11px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_categoryButton__OiL2l {
    font-size: 17.5px;
    height: 88.75000000000001px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_categoryButton__OiL2l {
    font-size: 21px;
    height: 100.515625px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_categoryButton__OiL2l {
    font-size: 24.9375px;
    height: 100px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 50px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_categoryButton__OiL2l {
    font-size: 28px;
    height: 120px;
  }
  .profile_bannerTypeCategoryButton__3nD4A {
    height: 50px;
    font-size: 12px;
  }
}

.profile_categoryButton__OiL2l.profile_dummy__2fmLL {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}

.profile_selectedCategoryButton__3t35l {
  margin-top: 23px;
  border: 3px solid;
  border-radius: 6vh;
  border-color: #fff;
  display: flex;
  max-width: 180px;
  float: left;
}

.profile_selectedCategoryTitle__1xoa9 {
  /* font-size: 1vw; */
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  margin: 4px;
  display: flex;
  align-items: center;
}

.profile_closeButton__qfrc9 {
  color: #fff;
  z-index: 100;
}

.profile_closeButtonWrapper__1t2RO {
  display: flex;
  align-items: center;
  padding: 5px;
}

@media only screen and (max-width: 319px) {
  .profile_selectedCategoryButton__3t35l {
    margin-top: 5px !important;
    height: 21px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 8px;
  }
  .profile_selectedCategoryButton__3t35l {
    margin-top: 6px;
    height: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 10px;
  }
  .profile_selectedCategoryButton__3t35l {
    margin-top: 9px;
    height: 29px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 11px;
  }
  .profile_selectedCategoryButton__3t35l {
    margin-top: 10px;
    height: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 12px;
  }
  .profile_selectedCategoryButton__3t35l {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .profile_selectedCategoryTitle__1xoa9 {
    font-size: 14px;
  }
}

.profile_textAlignFit__13TMi {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile_headerWrapper__hmBXR {
  display: flex;
  width: 100%;
  margin-bottom: -10px;
}

.profile_whatsNewWrapper__2S29J {
  width: 70%;
}

.profile_headingContainer__1U4j- {
  display: table;
  width: 30%;
}

.profile_rounded__3VtLm {
  border-radius: 50px;
}

.notifications_switch__2HlP6 {
  border: none;
  border-radius: var(--toggle-height);
  cursor: pointer;
  height: var(--toggle-height);
  margin: 0;
  position: relative;
  transition-duration: 200ms;
  width: calc(2.5 * var(--toggle-height));
}

.notifications_button__36Fdn {
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 2px;
  height: calc(1.5 * var(--toggle-height));
  position: absolute;
  top: calc(-0.25 * var(--toggle-height));
  transition-duration: 200ms;
  width: calc(1.5 * var(--toggle-height));
}

.notifications_button__36Fdn:not(.notifications_isOn__momc1) {
  left: calc(-0.25 * var(--toggle-height));
}

.notifications_button__36Fdn.notifications_isOn__momc1 {
  left: calc(1.25 * var(--toggle-height));
}

.plans_pageContainer__3v838 {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}

.plans_message__2lZn2 {
  color: white;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .plans_message__2lZn2 {
    font-size: 18.125px;
  }
}
.plans_daysTrails__1zqVO {
  text-align: center;
  color: #0075ff;
  font-weight: 700;
  margin-top: 0;
  font-size: 20px;
  font-family: "Montserrat" !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .plans_message__2lZn2 {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .plans_message__2lZn2 {
    font-size: 25.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .plans_message__2lZn2 {
    font-size: 29px;
  }
}

.plans_plansList__2wPrw {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .plans_plansList__2wPrw {
    align-items: center;
    flex-direction: column;
    padding: 0 30px;
  }

  .plans_col__1gPe8 {
    margin: 20px 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  .plans_eachPlan__P13Yi {
    width: 85% !important;
    margin: 30px auto 0;
  }
}

@media only screen and (min-width: 768px) {
  .plans_plansList__2wPrw {
    justify-content: center;
  }
}

.plans_plan__2L-h4 {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.plans_plans__1f8gK {
  display: flex;
  place-content: center;
}

@media (max-width: 600px) {
  .plans_plans__1f8gK {
    flex-direction: column;
  }
}

.plans_col__1gPe8 {
  width: 33.33333%;
  margin: 0 30px;
}

.plans_plan_head__wJAfl {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-bottom: 25px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.10); */
}

/* .eachPlan {
  width: 35%;
  display: block;
  padding: 10px 28px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
} */

.plans_eachPlan__P13Yi {
  /* width: 100%; */
  display: block;
  padding: 18px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
  min-width: 250px;
  width: 290px;
}
.plans_feat__1YThr{
  font-size: 14px;
  font-weight: 600
}
.plans_plan_name__2C6Xw p,
.plans_plan_price__iYIdI p {
  font-weight: 700;
  margin: 0;
  font-size: 20px;
}

.plans_plan_name__2C6Xw span,
.plans_plan_price__iYIdI span {
  font-size: 14px;
  opacity: 0.7;
}

.plans_plan_price__iYIdI {
  text-align: right;
}

@media (max-width: 992px) {
  .plans_plan_name__2C6Xw p,
  .plans_plan_price__iYIdI p {
    font-size: 18px;
  }

  .plans_plan_body__Sx9_- li {
    font-size: 14px;
  }

  .plans_eachPlan__P13Yi {
    padding: 20px;
  }
}

.plans_plan_body__Sx9_- {
  width: 100%;
  padding: 20px 0;
  margin: 0;
}

.plans_plan_body__Sx9_- li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  list-style: none;
  margin-bottom: 10px;
  grid-gap: 10px;
  grid-gap: 0;
  gap: 0;
  width: 160px;
}

.plans_plan_blue__2nKe_ {
  color: #fff;
  background-color: #0075ff;
}

.plans_eachPlan__P13Yi .plans_btn__2MUYq {
  width: 100%;
  display: flex;
  margin: 10px 0 0 0;
  padding: 10px 28px;
  height: auto;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 700;
}

.plans_btn__2MUYq {
  border-radius: 35px;
  font-size: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 8px 28px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.3s;
  cursor: pointer;
}
.plans_buyNow__3S7rp:hover{
  box-shadow: 0 0 10px black !important;
}
.plans_buyNow__3S7rp{
  width: 100% !important;
  display: flex !important;;
  margin: 10px 0 0 0 !important;
  padding: 10px 28px !important;
  height: auto !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none !important;
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 35px !important;
  font-size: 15px !important;
  text-align: center !important;
  padding: 8px 28px !important;
  color: black !important;
  text-decoration: none !important;
  border: 1px solid black !important;
  background-color: transparent !important;
  transition: all 0.3s !important;
  cursor: pointer !important;
}
.plans_tickster__3XUEA{
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.plans_disabled__3RPqr {
  cursor: not-allowed !important;
  opacity: 0.6;
  pointer-events: none;
}

.plans_featureText__2ZQpY {
  font-size: 16px;
}

.plans_featureTextWhite__1JGeD {
  color: #fff;
}

.plans_featureTextBlue__1hFrn {
  color: #000;
}

.plans_plan__2L-h4 > * {
  flex-shrink: 0;
}

.plans_plan__2L-h4,
.plans_plan__2L-h4 * {
  font-family: "Poppins";
}

.plans_plan__2L-h4:not(.plans_suggested__2uT7I) .plans_name__20iTh {
  background-color: #5aa5ef48;
  color: #585858;
}

.plans_plan__2L-h4:not(.plans_suggested__2uT7I) .plans_tryPlanButton__2nOD9 {
  border-color: #5aa5ef;
  border-width: 1px;
  color: #5aa5ef;
}

@media all and (hover: hover) {
  .plans_plan__2L-h4:not(.plans_suggested__2uT7I) .plans_tryPlanButton__2nOD9:hover {
    background-color: #5aa5ef;
  }
}

@media all and (pointer: coarse) {
  .plans_plan__2L-h4:not(.plans_suggested__2uT7I) .plans_tryPlanButton__2nOD9:active,
  .plans_plan__2L-h4:not(.plans_suggested__2uT7I) .plans_tryPlanButton__2nOD9:target {
    background-color: #5aa5ef;
  }
}

/* .plan.suggested::before {
  background-color: #5aa5ef;
  bottom: 100%;
  display: block;
  height: 29px;
  content: ' ';
  left: 0;
  position: absolute;
  width: 100%;
} */

.plans_plan__2L-h4.plans_auto__1IE-R::before,
.plans_plan__2L-h4.plans_upgradePlan__MNxo6::before,
.plans_plan__2L-h4.plans_currentPlan__2grl6::before,
.plans_plan__2L-h4.plans_currentFreePlan__j_X63::before,
.plans_plan__2L-h4.plans_degradePlan__wJp6I::before {
  background-color: #5aa5ef;
  bottom: 100%;
  display: flex;
  height: 29px;
  left: 0;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* color: white; */
}

.plans_plan__2L-h4.plans_auto__1IE-R::before {
  content: " ";
}

.plans_plan__2L-h4.plans_upgradePlan__MNxo6::before {
  content: "Upgrade Plan";
}

.plans_plan__2L-h4.plans_degradePlan__wJp6I::before {
  content: "Degrade Plan";
}

.plans_plan__2L-h4.plans_currentPlan__2grl6::before {
  content: "Current Plan";
}

.plans_plan__2L-h4.plans_currentFreePlan__j_X63::before {
  content: "Current Free Plan";
}

.plans_plan__2L-h4.plans_suggested__2uT7I .plans_name__20iTh {
  background-color: #1172d2;
  color: #fff;
}

.plans_plan__2L-h4.plans_suggested__2uT7I .plans_tryPlanButton__2nOD9 {
  background-color: #1172d2;
  border-color: #1172d2;
  color: #fff;
}

@media all and (hover: hover) {
  .plans_plan__2L-h4.plans_suggested__2uT7I .plans_tryPlanButton__2nOD9:hover {
    background-color: #1172d2 !important;
  }
}

@media all and (pointer: coarse) {
  .plans_plan__2L-h4.plans_suggested__2uT7I .plans_tryPlanButton__2nOD9:active,
  .plans_plan__2L-h4.plans_suggested__2uT7I .plans_tryPlanButton__2nOD9:target {
    background-color: #1172d2 !important;
  }
}

@media only screen and (max-width: 767px) {
  .plans_plan__2L-h4 {
    margin-bottom: 96px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .plans_plan__2L-h4 {
    margin: 0 24.375px;
  }
}

@media only screen and (max-width: 1199px) {
  .plans_plan__2L-h4 {
    height: 376.25px;
    width: 255px;
  }

  .plans_plan__2L-h4.plans_suggested__2uT7I::before,
  .plans_plan__2L-h4.plans_auto__1IE-R::before {
    height: 24.375px;
  }

  .plans_planContent__Als0S {
    padding: 0 15px 15px 15px;
  }

  .plans_name__20iTh {
    font-size: 16.875px;
    height: 60.625px;
    padding: 0 15px;
  }

  .plans_price__3QVIg {
    margin: 23.75px 0;
  }

  .plans_priceAmount__373i8 {
    font-size: 31.875px;
  }

  .plans_priceDetails__sSACP {
    font-size: 8.75px;
    margin-left: 4.375px;
  }

  .plans_featureIcon__2Qedd {
    height: 18.75px;
    margin-right: 9.375px;
    width: 18.75px;
  }

  .plans_featureIcon__2Qedd .plans_MuiRadio-root__1Lgec {
    padding: "0";
    color: "#5aa5ef";
  }

  .plans_featureIcon__2Qedd .plans_MuiRadio-colorPrimary__2BOKN {
    color: "#5aa5ef";
  }

  .plans_feature__3OWxp {
    font-size: 14px;
    margin: 12.5px 0;
    /* height: 60px; */
  }

  .plans_tryPlanButton__2nOD9 {
    font-size: 13.125px !important;
    height: 36.25px !important;
  }

  .plans_purchase__2nGuw {
    font-size: 16.25px;
    margin-top: 6.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .plans_plan__2L-h4 {
    height: 451.5px;
    margin: 0 29.25px;
    width: 306px;
  }

  .plans_plan__2L-h4.plans_suggested__2uT7I::before,
  .plans_plan__2L-h4.plans_auto__1IE-R::before {
    height: 29.25px;
  }

  .plans_planContent__Als0S {
    padding: 0 18px 18px 18px;
  }

  .plans_name__20iTh {
    font-size: 20.25px;
    height: 72.75px;
    padding: 0 18px;
  }

  .plans_price__3QVIg {
    margin: 28.5px 0;
  }

  .plans_priceAmount__373i8 {
    font-size: 38.25px;
  }

  .plans_priceDetails__sSACP {
    font-size: 10.5px;
    margin-left: 5.25px;
  }

  .plans_featureIcon__2Qedd {
    height: 22.5px;
    margin-right: 11.25px;
    width: 22.5px;
  }

  .plans_feature__3OWxp {
    font-size: 17px;
    margin: 15px 0;
  }

  .plans_tryPlanButton__2nOD9 {
    font-size: 15.749999999999998px !important;
    height: 43.5px !important;
  }

  .plans_purchase__2nGuw {
    font-size: 19.5px;
    margin-top: 7.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .plans_plan__2L-h4 {
    height: 536.15625px;
    margin: 0 34.73438px;
    width: 363.375px;
  }

  .plans_plan__2L-h4.plans_suggested__2uT7I::before,
  .plans_plan__2L-h4.plans_auto__1IE-R::before {
    height: 34.734375px;
  }

  .plans_planContent__Als0S {
    padding: 0 21.375px 21.375px 21.375px;
  }

  .plans_name__20iTh {
    font-size: 24.046875px;
    height: 86.390625px;
    padding: 0 21.375px;
  }

  .plans_price__3QVIg {
    margin: 33.84375px 0;
  }

  .plans_priceAmount__373i8 {
    font-size: 45.421875px;
  }

  .plans_priceDetails__sSACP {
    font-size: 12.46875px;
    margin-left: 6.23438px;
  }

  .plans_featureIcon__2Qedd {
    height: 26.71875px;
    margin-right: 13.35938px;
    width: 26.71875px;
  }

  .plans_feature__3OWxp {
    font-size: 18.703125px;
    margin: 17.8125px 0;
  }

  .plans_tryPlanButton__2nOD9 {
    font-size: 18.703125px !important;
    height: 51.65625px !important;
  }

  .plans_purchase__2nGuw {
    font-size: 23.15625px;
    margin-top: 8.90625px;
  }
}

@media only screen and (min-width: 1710px) {
  .plans_plan__2L-h4 {
    height: 602px;
    margin: 0 39px;
    width: 408px;
  }

  .plans_plan__2L-h4.plans_suggested__2uT7I::before,
  .plans_plan__2L-h4.plans_auto__1IE-R::before {
    height: 39px;
  }

  .plans_planContent__Als0S {
    padding: 0 24px 24px 24px;
  }

  .plans_name__20iTh {
    font-size: 27px;
    height: 97px;
    padding: 0 24px;
  }

  .plans_price__3QVIg {
    margin: 38px 0;
  }

  .plans_priceAmount__373i8 {
    font-size: 51px;
  }

  .plans_priceDetails__sSACP {
    font-size: 14px;
    margin-left: 7px;
  }

  .plans_featureIcon__2Qedd {
    height: 30px;
    margin-right: 15px;
    width: 30px;
  }

  .plans_feature__3OWxp {
    font-size: 21px;
    margin: 20px 0;
  }

  .plans_tryPlanButton__2nOD9 {
    font-size: 21px !important;
    height: 58px !important;
  }

  .plans_purchase__2nGuw {
    font-size: 26px;
    margin-top: 10px;
  }
}

.plans_name__20iTh {
  align-items: center;
  display: flex;
  font-weight: 600;
}

.plans_planContent__Als0S {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.plans_price__3QVIg {
  color: #585858;
  font-weight: 300;
}

.plans_priceAmount__373i8 {
  line-height: 1.35;
}

.plans_priceDetails__sSACP {
  line-height: 0.9;
}

.plans_featuresList__1jNZV {
  display: block;
}

.plans_feature__3OWxp {
  align-items: center;
  color: #585858;
  display: flex;
  font-weight: 300;
  text-align: left;
  height: auto;
}

.plans_featureIcon__2Qedd {
  object-fit: contain;
  object-position: center;
}

.plans_tryPlan__Z85oR {
  color: #000;
  margin-top: auto;
  text-align: center;
}

.plans_tryPlanButton__2nOD9 {
  text-transform: none !important;
}

.plans_purchaseLink__1J_wc {
  color: #1172d2;
  text-decoration: none;
}

@media all and (hover: hover) {
  .plans_purchaseLink__1J_wc:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .plans_purchaseLink__1J_wc:active,
  .plans_purchaseLink__1J_wc:target {
    text-decoration: underline;
  }
}

.plans_planContainer__Ao4Qt {
  width: 1320px;
  padding: 0 15px;
  margin: auto;
}

@media (max-width: 992px) {
  .plans_planContainer__Ao4Qt {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .plans_planContainer__Ao4Qt {
    width: 960px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .plans_planContainer__Ao4Qt {
    width: 1140px;
  }
}

.page-layout_header__37-1r {
  align-items: center;
  background-color: #000;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.page-layout_flexicos__3yqBz{
  display: flex;
  flex-direction: row;
}
.page-layout_flexMe__Jz-NE{
  display: flex;
  flex-direction: row;
  grid-gap: 51px;
  gap: 51px;
  margin-top: 0px;
}
@media only screen and (max-width: 1000px) {
  .page-layout_paymentFieldInputs__2n_QL {
    /* margin-top: 360px !important; */
    margin-top: 50px !important;
    width: 80%;
    padding-left: 0px;
  }
}

@media only screen and (max-width: 426px) {
  .page-layout_paymentFieldInputs__2n_QL {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 321px) {
  .page-layout_paymentFieldInputs__2n_QL {
    margin-top: 450px !important;
  }
}
@media only screen and (max-width: 480px) {
  .page-layout_flexMe__Jz-NE{
    margin-top: 0px;
    justify-content: center;
  }
  .page-layout_flexicos__3yqBz{
    display: flex;
    flex-direction: column-reverse;
  }
}
.page-layout_back__1d6Ol{
  cursor: pointer;
  font-weight: 600;
  padding-left: 28px;
  padding-top: 20px;
  font-family: Montserrat-Regular;
  letter-spacing: 0px;
  font-size: 20px;
}
@media only screen and (max-width: 899px) {
  .page-layout_header__37-1r {
    height: 66.25px;
    padding: 0 calc(66.25px / 3);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_header__37-1r {
    height: 79.5px;
    padding: 0 calc(79.5px / 3);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_header__37-1r {
    height: 94.40625px;
    padding: 0 calc(94.40625px / 3);
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_header__37-1r {
    height: 106px;
    padding: 0 calc(106px / 3);
  }
}

.page-layout_logo__15oFf {
  height: 70%;
  position: relative;
  cursor: pointer;
}

.page-layout_helpCenterButton__3MaRV {
  color: #667583;
  font: normal normal normal 15px/21px Poppins;
}

.page-layout_page__3z3aP {
  margin: 0 auto;
  max-width: 1920px;
}

@media only screen and (max-width: 419px) {
  .page-layout_page__3z3aP {
    padding: 32px 16px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 899px) {
  .page-layout_page__3z3aP {
    padding: 26px 32px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page-layout_page__3z3aP {
    padding: 44px 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page-layout_page__3z3aP {
    padding: 25.25px 95.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_page__3z3aP {
    padding: 15px 127px 53px 127px;
    height: calc(100vh - 94px);
    overflow: auto; /* Ensures content inside doesn't overflow */
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_page__3z3aP {
    padding: 30px 127px 0 127px;
    height: calc(100vh - 94px);
    overflow: auto; /* Ensures content inside doesn't overflow */
  }
}

.page-layout_title__2PHMG {
  color: #1c1f2b;
  font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .page-layout_title__2PHMG {
    font-size: 15px;
    /* line-height: 22.03125px;
    margin-bottom: 6.874999999999999px;
    width: 400px; */
    /* margin-left: 40px; */
  }

}


@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_title__2PHMG {
    font-size: 22px;
    line-height: 29.375px;
    margin-bottom: 8.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_title__2PHMG {
    font-size: 26px;
    line-height: 35.25px;
    margin-bottom: 9.796875px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_title__2PHMG {
    font-size: 26px;
    line-height: 35.25px;
    margin-bottom: 11px;
  }
}

.page-layout_subTitle__1RHmI,
.page-layout_terms__qdiRG {
  color: #1c1f2b;
  font-weight: 300;
  margin: 20px 0 0 0 !important;
}

@media only screen and (max-width: 899px) {

  .page-layout_subTitle__1RHmI,
  .page-layout_terms__qdiRG {
    font-size: 11.875px;
    line-height: 16.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {

  .page-layout_subTitle__1RHmI,
  .page-layout_terms__qdiRG {
    font-size: 14.25px;
    line-height: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {

  .page-layout_subTitle__1RHmI,
  .page-layout_terms__qdiRG {
    font-size: 16.921875px;
    line-height: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {

  .page-layout_subTitle__1RHmI,
  .page-layout_terms__qdiRG {
    font-size: 19px;
    line-height: 26px;
  }
}

.page-layout_termsContainer__3_hOa {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .page-layout_termsContainer__3_hOa {
    margin-top: 36.25px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_termsContainer__3_hOa {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_termsContainer__3_hOa {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_termsContainer__3_hOa {
    margin-top: 32px;
  }
}

.page-layout_termsCheckbox__2TrtS {
  background-color: #fff;
  border: 1px solid #bbc2c9;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 20px;
  position: relative;
}

.page-layout_termsCheckbox__2TrtS.page-layout_checked__N_sPw {
  background-color: #007aff !important;
  border-color: #006aff !important;
}

@media only screen and (max-width: 899px) {
  .page-layout_termsCheckbox__2TrtS {
    border-radius: 3.125px;
    height: 14.375000000000002px;
    margin-right: 12.75px;
    width: 14.375000000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_termsCheckbox__2TrtS {
    border-radius: 3.75px;
    height: 17.25px;
    margin-right: 12.75px;
    width: 17.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_termsCheckbox__2TrtS {
    border-radius: 4.453125px;
    height: 20.484375px;
    margin-right: 15.140625px;
    width: 20.484375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_termsCheckbox__2TrtS {
    border-radius: 5px;
    height: 23px;
    margin-right: 17px;
    width: 23px;
  }
}

.page-layout_termsCheckboxIcon__2f5Kj {
  color: #fff;
  height: 100% !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100% !important;
}

.page-layout_terms__qdiRG a {
  color: #1172d2 !important;
  font-weight: 500;
  text-decoration: none;
}

@media all and (hover: hover) {
  .page-layout_terms__qdiRG a:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {

  .page-layout_terms__qdiRG a:active,
  .page-layout_terms__qdiRG a:target {
    text-decoration: underline;
  }
}

.page-layout_continueButton__3lHD- {
  border: 1px solid #5aa5ef;
  background-color: white;
  color: #000 !important;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 10px;
  /* cursor: pointer; */
}

button.page-layout_continueButton__3lHD-:hover {
  /* color: white;
  background-color: #5aa5ef; */
}

.page-layout_continueButton__3lHD-:not(:disabled) {
  background-color: #fff;
cursor: pointer;
}

.page-layout_continueButton__3lHD-:disabled {
  color: #999 !important;
  cursor: default;
}

@media only screen and (max-width: 899px) {
  .page-layout_continueButton__3lHD- {
    font-size: 11.875px;
    height: 26.875px;
    margin-top: 36.25px;
    width: 195.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page-layout_continueButton__3lHD- {
    font-size: 14.25px;
    height: 32.25px;
    width: 234.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page-layout_continueButton__3lHD- {
    font-size: 16.921875px;
    height: 38.296875px;
    width: 278.765625px;
  }
}

@media only screen and (min-width: 1710px) {
  .page-layout_continueButton__3lHD- {
    font-size: 19px;
    height: 43px;
    width: 231px;
  }
}
.SAgreement .lst-kix_list_4-1 > li {
  counter-increment: lst-ctn-kix_list_4-1;
}
.SAgreement .lst-kix_list_2-1 > li {
  counter-increment: lst-ctn-kix_list_2-1;
}
.SAgreement .lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.SAgreement ol.lst-kix_list_2-6.start {
  counter-reset: lst-ctn-kix_list_2-6 0;
}
.SAgreement .lst-kix_list_3-0 > li:before {
  content: '\0025cf  ';
}
.SAgreement .lst-kix_list_3-1 > li:before {
  content: 'o  ';
}
.SAgreement .lst-kix_list_3-2 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
.SAgreement .lst-kix_list_4-0 > li {
  counter-increment: lst-ctn-kix_list_4-0;
}
.SAgreement ol.lst-kix_list_2-3.start {
  counter-reset: lst-ctn-kix_list_2-3 0;
}
.SAgreement .lst-kix_list_3-5 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_3-4 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
.SAgreement .lst-kix_list_3-3 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_3-8 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_2-0 > li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.SAgreement .lst-kix_list_2-3 > li {
  counter-increment: lst-ctn-kix_list_2-3;
}
.SAgreement .lst-kix_list_3-6 > li:before {
  content: '\0025aa  ';
}
.SAgreement .lst-kix_list_4-3 > li {
  counter-increment: lst-ctn-kix_list_4-3;
}
.SAgreement .lst-kix_list_3-7 > li:before {
  content: '\0025aa  ';
}
.SAgreement ol.lst-kix_list_4-5.start {
  counter-reset: lst-ctn-kix_list_4-5 0;
}
.SAgreement .lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.SAgreement ol.lst-kix_list_4-2.start {
  counter-reset: lst-ctn-kix_list_4-2 0;
}
.SAgreement ol.lst-kix_list_2-2 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-3 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
.SAgreement .lst-kix_list_4-4 > li {
  counter-increment: lst-ctn-kix_list_4-4;
}
.SAgreement ol.lst-kix_list_2-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
.SAgreement ol.lst-kix_list_2-1 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_4-8 > li:before {
  content: '' counter(lst-ctn-kix_list_4-8, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-7 > li:before {
  content: '' counter(lst-ctn-kix_list_4-7, upper-latin) '. ';
}
.SAgreement ol.lst-kix_list_4-1.start {
  counter-reset: lst-ctn-kix_list_4-1 0;
}
.SAgreement ol.lst-kix_list_4-8.start {
  counter-reset: lst-ctn-kix_list_4-8 0;
}
.SAgreement ol.lst-kix_list_2-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-7 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-8 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.SAgreement ol.lst-kix_list_4-0.start {
  counter-reset: lst-ctn-kix_list_4-0 0;
}
.SAgreement .lst-kix_list_2-5 > li {
  counter-increment: lst-ctn-kix_list_2-5;
}
.SAgreement .lst-kix_list_2-8 > li {
  counter-increment: lst-ctn-kix_list_2-8;
}
.SAgreement .lst-kix_list_2-2 > li {
  counter-increment: lst-ctn-kix_list_2-2;
}
.SAgreement ol.lst-kix_list_2-4.start {
  counter-reset: lst-ctn-kix_list_2-4 0;
}
.SAgreement ol.lst-kix_list_4-7.start {
  counter-reset: lst-ctn-kix_list_4-7 0;
}
.SAgreement ol.lst-kix_list_1-3 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-6 > li:before {
  content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
}
.SAgreement .lst-kix_list_2-7 > li:before {
  content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
}
.SAgreement .lst-kix_list_2-7 > li {
  counter-increment: lst-ctn-kix_list_2-7;
}
.SAgreement ol.lst-kix_list_1-5 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-4 > li:before {
  content: '' counter(lst-ctn-kix_list_2-4, lower-latin) '. ';
}
.SAgreement .lst-kix_list_2-5 > li:before {
  content: '' counter(lst-ctn-kix_list_2-5, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-8 > li:before {
  content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
}
.SAgreement ol.lst-kix_list_1-1 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-7 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-6.start {
  counter-reset: lst-ctn-kix_list_4-6 0;
}
.SAgreement ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-2 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-3.start {
  counter-reset: lst-ctn-kix_list_4-3 0;
}
.SAgreement ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_4-7 > li {
  counter-increment: lst-ctn-kix_list_4-7;
}
.SAgreement .lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
.SAgreement ol.lst-kix_list_1-8 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-6 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-3 {
  list-style-type: none;
}
.SAgreement ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_2-5.start {
  counter-reset: lst-ctn-kix_list_2-5 0;
}
.SAgreement .lst-kix_list_4-0 > li:before {
  content: '' counter(lst-ctn-kix_list_4-0, upper-latin) '. ';
}
.SAgreement .lst-kix_list_2-6 > li {
  counter-increment: lst-ctn-kix_list_2-6;
}
.SAgreement .lst-kix_list_4-1 > li:before {
  content: '' counter(lst-ctn-kix_list_4-1, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-6 > li {
  counter-increment: lst-ctn-kix_list_4-6;
}
.SAgreement ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.SAgreement .lst-kix_list_4-4 > li:before {
  content: '' counter(lst-ctn-kix_list_4-4, upper-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-2.start {
  counter-reset: lst-ctn-kix_list_2-2 0;
}
.SAgreement .lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.SAgreement .lst-kix_list_4-3 > li:before {
  content: '' counter(lst-ctn-kix_list_4-3, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-5 > li:before {
  content: '' counter(lst-ctn-kix_list_4-5, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-2 > li:before {
  content: '' counter(lst-ctn-kix_list_4-2, upper-latin) '. ';
}
.SAgreement .lst-kix_list_4-6 > li:before {
  content: '' counter(lst-ctn-kix_list_4-6, upper-latin) '. ';
}
.SAgreement .lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
.SAgreement ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
.SAgreement ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.SAgreement ol.lst-kix_list_4-0 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-1 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-4.start {
  counter-reset: lst-ctn-kix_list_4-4 0;
}
.SAgreement ol.lst-kix_list_4-2 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-3 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_2-4 > li {
  counter-increment: lst-ctn-kix_list_2-4;
}
.SAgreement ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
.SAgreement ol.lst-kix_list_2-8.start {
  counter-reset: lst-ctn-kix_list_2-8 0;
}
.SAgreement ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
.SAgreement ol.lst-kix_list_4-8 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-0 > li:before {
  content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
}
.SAgreement ol.lst-kix_list_4-4 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-5 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-1 > li:before {
  content: '' counter(lst-ctn-kix_list_1-1, lower-latin) '. ';
}
.SAgreement .lst-kix_list_1-2 > li:before {
  content: '' counter(lst-ctn-kix_list_1-2, lower-roman) '. ';
}
.SAgreement ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.SAgreement ol.lst-kix_list_4-6 {
  list-style-type: none;
}
.SAgreement ol.lst-kix_list_4-7 {
  list-style-type: none;
}
.SAgreement .lst-kix_list_1-3 > li:before {
  content: '' counter(lst-ctn-kix_list_1-3, decimal) '. ';
}
.SAgreement .lst-kix_list_1-4 > li:before {
  content: '' counter(lst-ctn-kix_list_1-4, lower-latin) '. ';
}
.SAgreement .lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.SAgreement .lst-kix_list_4-8 > li {
  counter-increment: lst-ctn-kix_list_4-8;
}
.SAgreement .lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.SAgreement .lst-kix_list_1-7 > li:before {
  content: '' counter(lst-ctn-kix_list_1-7, lower-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-7.start {
  counter-reset: lst-ctn-kix_list_2-7 0;
}
.SAgreement .lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.SAgreement .lst-kix_list_1-5 > li:before {
  content: '' counter(lst-ctn-kix_list_1-5, lower-roman) '. ';
}
.SAgreement .lst-kix_list_1-6 > li:before {
  content: '' counter(lst-ctn-kix_list_1-6, decimal) '. ';
}
.SAgreement li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.SAgreement .lst-kix_list_2-0 > li:before {
  content: '' counter(lst-ctn-kix_list_2-0, decimal) '. ';
}
.SAgreement .lst-kix_list_2-1 > li:before {
  content: '' counter(lst-ctn-kix_list_2-1, lower-latin) '. ';
}
.SAgreement ol.lst-kix_list_2-1.start {
  counter-reset: lst-ctn-kix_list_2-1 0;
}
.SAgreement .lst-kix_list_4-5 > li {
  counter-increment: lst-ctn-kix_list_4-5;
}
.SAgreement .lst-kix_list_1-8 > li:before {
  content: '' counter(lst-ctn-kix_list_1-8, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-2 > li:before {
  content: '' counter(lst-ctn-kix_list_2-2, lower-roman) '. ';
}
.SAgreement .lst-kix_list_2-3 > li:before {
  content: '' counter(lst-ctn-kix_list_2-3, decimal) '. ';
}
.SAgreement .lst-kix_list_4-2 > li {
  counter-increment: lst-ctn-kix_list_4-2;
}
.SAgreement ol {
  margin: 0;
  padding: 0;
}
table td,
.SAgreement table th {
  padding: 0;
}
.SAgreement .c5 {
  /* background-color: #ffffff; */
  color: #0070e0;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c0 {
  margin-left: 60pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 9pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c17 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt;
}
.SAgreement .c7 {
  /* background-color: #ffffff; */
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c2 {
  margin-left: 58.5pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c15 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c8 {
  /* background-color: #ffffff; */
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c3 {
  /* color: #0d0d0d; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c19 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c4 {
  /* color: #0d0d0d; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c1 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 18pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c10 {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.SAgreement .c24 {
  margin-left: 60pt;
  padding-top: 0pt;
  padding-bottom: 9pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .c28 {
  /* background-color: #ffffff; */
  -webkit-text-decoration-skip: none;
  /* color: #000000; */
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
.SAgreement .c12 {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.SAgreement .c9 {
  font-size: 10pt;
  font-family: 'Arial';
  /* color: #0d0d0d; */
  font-weight: 400;
}
.SAgreement .c16 {
  -webkit-text-decoration-skip: none;
  color: #0563c1;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
.SAgreement .c11 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.SAgreement .c18 {
  font-weight: 400;
  font-size: 12pt;
  font-family: 'Times New Roman';
}
.SAgreement .c22 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 700;
}
.SAgreement .c14 {
  font-weight: 400;
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement .c26 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.SAgreement .c21 {
  margin-left: 18pt;
  padding-left: 0pt;
}
.SAgreement .c27 {
  vertical-align: baseline;
  font-style: normal;
}
.SAgreement .c13 {
  color: inherit;
  text-decoration: inherit;
}
.SAgreement .c23 {
  padding: 0;
  margin: 0;
}
.SAgreement .c20 {
  height: 12pt;
}
.SAgreement .title {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement li {
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement p {
  margin: 0;
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.SAgreement h1 {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h2 {
  padding-top: 18pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h3 {
  padding-top: 14pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h4 {
  padding-top: 12pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h5 {
  padding-top: 11pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement h6 {
  padding-top: 10pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.SAgreement {
  padding: 1rem 2rem;
}

.form-section_formSection__3Hu3h {
  display: block;
}

.form-section_title__2mmgd {
  color: #60676d;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .form-section_title__2mmgd {
    font-size: 11.875px;
    line-height: 16.25px;
    margin-bottom: -5px;
    margin-right: 30px;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .form-section_title__2mmgd {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .form-section_title__2mmgd {
    font-size: 14.25px;
    line-height: 19.5px;
    margin-bottom: -6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .form-section_title__2mmgd {
    font-size: 16.921875px;
    line-height: 23.15625px;
    margin-bottom: -7.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .form-section_title__2mmgd {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: -8px;
  }
}

.form-section_content__eJI0L {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media only screen and (max-width: 767px) {
  .form-section_content__eJI0L {
    grid-gap: 20px;
    gap: 20px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .form-section_content__eJI0L {
    grid-gap: 24px;
    gap: 24px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .form-section_content__eJI0L {
    grid-gap: 9.5px;
    gap: 9.5px;
    margin-top: 27px;
  }
}

@media only screen and (min-width: 1710px) {
  .form-section_content__eJI0L {
    grid-gap: 15px;
    gap: 15px;
    margin-top: 32px;
  }
}

.input-grid_inputGrid__3kqTb {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 430px) {
  .input-grid_inputGrid__3kqTb {
    grid-gap: 20px;
    gap: 20px;
    -ms-grid-columns: 1fr !important;
        grid-template-columns: 1fr !important;
        padding-right: 12px;
  }
}
@media only screen and (max-width: 889px) {
  .input-grid_inputGrid__3kqTb {
    margin-left: 5px;
  }
}


@media only screen and (min-width: 420px) and (max-width: 767px) {
  .input-grid_inputGrid__3kqTb {
    grid-gap: 20px;
    gap: 20px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .input-grid_inputGrid__3kqTb {
    grid-gap: 24px 28px;
    gap: 24px 28px;
    -ms-grid-columns: 174px 174px 174px;
        grid-template-columns: 174px 174px 174px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .input-grid_inputGrid__3kqTb {
    grid-gap: 28.5px 45.421875px;
    gap: 28.5px 45.421875px;
    -ms-grid-columns: 206.625px 206.625px 206.625px;
        grid-template-columns: 206.625px 206.625px 206.625px;
  }
}

@media only screen and (min-width: 1710px) {
  .input-grid_inputGrid__3kqTb {
    grid-gap: 15px 51px;
    gap: 15px 51px;
    -ms-grid-columns: 232px 232px 232px;
        grid-template-columns: 232px 232px 232px;
  }
}

.input-field_inputField__2cXV8,
form .input-field_card-element__37CWJ {
  border-color: #bdc4c9;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  outline-color: #1172d2;
}

@media only screen and (max-width: 899px) {
  .input-field_inputField__2cXV8,
  form .input-field_card-element__37CWJ {
    border-radius: 3.125px;
    border-width: 0.46875px;
    font-size: 10px;
    height: 30px;
    line-height: 21px;
    padding: 0 7.5px;
  }
}
@media only screen and (max-width: 480px) {
  .input-field_inputField__2cXV8 {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .input-field_inputField__2cXV8,
  form .input-field_card-element__37CWJ {
    border-radius: 3.75px;
    border-width: 0.5625px;
    font-size: 11.25px;
    height: 36px;
    line-height: 21px;
    padding: 0 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .input-field_inputField__2cXV8,
  form .input-field_card-element__37CWJ {
    border-radius: 4.453125px;
    border-width: 0.66796875px;
    font-size: 13.359375px;
    height: 42.75px;
    line-height: 21px;
    padding: 0 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  .input-field_inputField__2cXV8,
  form .input-field_card-element__37CWJ {
    border-radius: 5px;
    border-width: 0.75px;
    font-size: 15px;
    height: 48px;
    line-height: 21px;
    padding: 0 12px;
  }
}

.input-field_inputField__2cXV8,
form .input-field_card-element__37CWJ::-webkit-input-placeholder {
  color: #767676;
}

.input-field_inputField__2cXV8,
form .input-field_card-element__37CWJ:-ms-input-placeholder {
  color: #767676;
}

form .input-field_card-element__37CWJ::-webkit-input-placeholder {
  color: #767676;
}

form .input-field_card-element__37CWJ:-ms-input-placeholder {
  color: #767676;
}

.input-field_inputField__2cXV8,
form .input-field_card-element__37CWJ::placeholder {
  color: #767676;
}

@media (min-width: 744px) and (max-width: 1023px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    margin-top: 350px !important;
    position: absolute;
    width: 80%;
    padding: 2%;
  }
}
.checkout-price-layout_invalid__NepvK {
  font-weight: 500;
  font-family: "Poppins";
  color: red;
  margin: 5px 0;
  font-size: 12px;
  
}
.checkout-price-layout_mangerx__1vCHw {
  font-weight: 500;
  color: black;
  font-family: "Poppins";
  margin-bottom: 0;
  margin-top: 12px;
  font-size: 14px;
}
.checkout-price-layout_affiliatedCode__1X6NX {
  border-bottom-style: solid;
  border-bottom-color: #d4d4d4;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-color: #d4d4d4;
  border-top-width: 1px;
  color: #686868;
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: auto;
  font-family: "Poppins";
  font-size: 18px;
  width: 100%;
  border-left: navajowhite;
  border-right: navajowhite;
}
.checkout-price-layout_affiliatedCode__1X6NX ::-webkit-input-placeholder {
  color: #686868;
  font-size: 18px;
}
.checkout-price-layout_affiliatedCode__1X6NX :-ms-input-placeholder {
  color: #686868;
  font-size: 18px;
}
.checkout-price-layout_affiliatedCode__1X6NX ::placeholder {
  color: #686868;
  font-size: 18px;
}
@media (min-width: 420px) and (max-width: 744px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    margin-top: 350px !important;
    position: relative;
    width: 80%;
    padding: 2%;
  }
}

@media (max-width: 420px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    margin-top: 450px;
    padding: 2%;
    position: relative;
    width: 80%;
  }
  .checkout-price-layout_projectorSubTitle__3cukD {
    font-size: 12px !important;
  }
}

@media (max-width: 1023px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    margin-left: 0%;
  }
}

/*
  .title {
    transform: scale(1.1);
    margin-left: 50px;

  }

  .subTitle {
    transform: scale(1.1);
    margin-left: 50px;

  }

  .priceMain {} */

/* .subPrice {
    margin-right: 20px;
    transform: translate(128px, -18px);
    scale: 0.85;
  } */
/* 
  .promoCode {
    scale: 0.85;
  }

  .total {
    transform: scale(0.85);
  }

  .logoDiv {
    transform: scale(0.85);
  }
} */

@media (min-width: 1023px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    align-items: end;
    border-left-style: solid;
    border-left-color: #d4d4d4;
    border-left-width: 1px;
    padding-left: 100px;
    padding-right: 20px;
    overflow: hidden;
    /* margin-block: auto; */
    min-width: 400px;
  }
}

.checkout-price-layout_projectorPriceDiv__1aQd9 {
  margin-left: auto;
}

.checkout-price-layout_price__3omHK {
  font-weight: 700;
  margin-top: auto;
}

.checkout-price-layout_subTotal__3fTc8 {
  margin-top: auto;
  font-weight: 700;
}

.checkout-price-layout_title__1cto8 {
  font-size: 18px;
  color: #848484;
  font-weight: 400;
  margin-top: 0;
}

.checkout-price-layout_subTitle__2h1s9 {
  font-size: 36px;
  font-weight: bold;
  margin: 20px 0px;
}

.checkout-price-layout_logoDiv__1FBNb {
  display: flex;
}

.checkout-price-layout_logoDivImg__2zkC- {
  background-color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  padding-left: 1%;
  padding-top: 1%;
  text-align: center;
}

.checkout-price-layout_projectorTitleDiv__1gSJ_ {
  margin-left: 10%;
}

.checkout-price-layout_projectorTitle__3L7rx {
  font-weight: 700;
  margin-top: 12px;
  font-size: 18px;
}
.checkout-price-layout_promoTitle__j_xuC {
  font-weight: 700;
  margin-top: auto;
  margin-bottom: 0;
  font-size: 14px;
  color: #848484;
}

.checkout-price-layout_projectorSubTitle__3cukD {
  font-size: 11px;
  margin-top: -20px;
  font-weight: 300;
  color: #848484;
}

.checkout-price-layout_total__3LDf4 {
  display: flex;
  font-weight: 500;
  /* border-top-style: solid;
  border-top-color: #d4d4d4;
  border-top-width: 1px; */
}

.checkout-price-layout_totalPrice__3SR1K {
  margin-left: auto;
  font-weight: 700;
   margin-top: 12px;
}
.checkout-price-layout_promoPrice__Cs9qC {
  margin-left: auto;
  margin-top: 0px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  color: #bb2124;
}

.checkout-price-layout_regularDate__YYaBp{
  display: flex;
}
.checkout-price-layout_promoCode__1KDtS {
  /* border-top-style: solid; */
  border-top-color: #d4d4d4;
  border-top-width: 1px;
  /* color: #686868; */
  margin-top: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: auto;
  width: 250px;

  font-size: 10px;
  font-weight: 500;
}

@media only screen and (max-width: 1000px) {
  .checkout-price-layout_mainDiv__1W-i8 {
    margin-top: 10px !important;
  }
}

.payment-field_cardNumber__2Inko {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.payment-field_expirationDate__3O6OU {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

@media only screen and (max-width: 419px) {
  .payment-field_expirationDate__3O6OU {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
  }
}

.payment-field_cvvNumber__Tq7aA {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

@media only screen and (max-width: 419px) {
  .payment-field_cvvNumber__Tq7aA {
    -ms-grid-column: 1 !important;
    -ms-grid-column-span: 2 !important;
    grid-column: 1 / 3 !important;
    -ms-grid-row: 3 !important;
    -ms-grid-row-span: 1 !important;
    grid-row: 3 / 4 !important;
  }
}

.CheckoutForm_link__3b_ae {
  color: #1172d2;
  cursor: pointer;
  font-weight: 600 !important;
  text-decoration: none;
}

@media all and (hover: hover) {
  .CheckoutForm_link__3b_ae:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .CheckoutForm_link__3b_ae:active,
  .CheckoutForm_link__3b_ae:target {
    text-decoration: underline;
  }
}

form #card-element {
  border-color: #bdc4c9;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  outline-color: #1172d2;
}

@media only screen and (max-width: 899px) {
  form #card-element {
    border-radius: 3.125px;
    border-width: 0.46875px;
    font-size: 10px;
    height: 30px;
    line-height: 21px;
    padding: 7.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  form #card-element {
    border-radius: 3.75px;
    border-width: 0.5625px;
    font-size: 11.25px;
    height: 36px;
    line-height: 21px;
    padding: 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  form #card-element {
    border-radius: 4.453125px;
    border-width: 0.66796875px;
    font-size: 13.359375px;
    height: 42.75px;
    line-height: 21px;
    padding: 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  form #card-element {
    border-radius: 5px;
    border-width: 0.75px;
    font-size: 15px;
    height: 48px;
    line-height: 21px;
    padding: 12px;
  }
}

form #card-element::-webkit-input-placeholder {
  color: #767676;
}

form #card-element:-ms-input-placeholder {
  color: #767676;
}

form #card-element::placeholder {
  color: #767676;
}

.card {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .card {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .card {
    width: 31.5%;
  }
}

.confirmation-modal_title__4s7Z8 {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  font-family: 'Montserrat' !important;

}
.confirmation-modal_content__3w4VA {
  text-align: center;
  color: #000;
  font-family: 'Montserrat' !important;
  font-weight: 500;
  padding: 0 10px 0;

}
.confirmation-modal_actionContainer__3KDFs {
  display: grid;
}
.confirmation-modal_cancelButton__ly69f{
  text-transform: none;
  font-family: 'Montserrat' !important;
  color: #FFFFFF;
  background-color:#5AA5EF;
  font-size: 16px !important;
  font-weight: 500;

}
.confirmation-modal_continueButton__3Lago{
  text-transform: none;
  color: #000000;
  font-family: 'Montserrat' !important;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  padding: 15px 0;
}
.confirmation-modal_dialogContainer__22zen {
  border-radius: 15px;
}

.PPLLC .lst-kix_list_2-6 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-7 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_2-4 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-5 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-8 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_2-8 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-0 > li:before {
  content: '\0025cf  ';
}
.PPLLC ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-3 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-0 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-2 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-1 > li:before {
  content: 'o  ';
}
.PPLLC .lst-kix_list_1-2 > li:before {
  content: '\0025aa  ';
}
.PPLLC ul.lst-kix_list_1-8 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-4 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.PPLLC ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.PPLLC .lst-kix_list_1-3 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-4 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-7 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-5 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_1-6 > li:before {
  content: '\0025aa  ';
}
.PPLLC li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.PPLLC .lst-kix_list_2-0 > li:before {
  content: '\0025cf  ';
}
.PPLLC .lst-kix_list_2-1 > li:before {
  content: 'o  ';
}
.PPLLC .lst-kix_list_1-8 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-2 > li:before {
  content: '\0025aa  ';
}
.PPLLC .lst-kix_list_2-3 > li:before {
  content: '\0025aa  ';
}
.PPLLC ol {
  margin: 0;
  padding: 0;
}
table td,
.PPLLC table th {
  padding: 0;
}
.PPLLC .c9 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c7 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c5 {
  /* background-color: #ffffff; */
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c1 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.PPLLC .c4 {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.PPLLC .c0 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c18 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}

.PPLLC .c19 {
  text-decoration: underline;
}
.PPLLC .c20 {
  margin-left: 20pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c27 {
  vertical-align: baseline;
  font-style: normal;
}
.PPLLC .c15 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c17 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c14 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .c2 {
  font-size: 10pt;
  font-family: 'Arial';
  /* color: #000000; */
  font-weight: 400;
}
.PPLLC .c8 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.PPLLC .c12 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.PPLLC .c13 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-left: 0pt;
}
.PPLLC .c16 {
  padding: 0;
  margin: 0;
}
.PPLLC .c10 {
  height: 12pt;
}
.PPLLC .c3 {
  font-style: italic;
}
.PPLLC .title {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC li {
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.PPLLC p {
  margin: 0;
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.PPLLC h1 {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h2 {
  padding-top: 0pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h3 {
  padding-top: 14pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h4 {
  padding-top: 12pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h5 {
  padding-top: 11pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.PPLLC h6 {
  padding-top: 10pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.TermsAndCondition .lst-kix_list_2-6 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_2-7 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.TermsAndCondition .lst-kix_list_2-4 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_2-5 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_2-8 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition ul.lst-kix_list_2-8 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-3 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.TermsAndCondition .lst-kix_list_1-0 > li:before {
  content: '\0025cf  ';
}
.TermsAndCondition ul.lst-kix_list_1-4 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-3 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-1 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-0 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-2 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-7 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.TermsAndCondition .lst-kix_list_1-1 > li:before {
  content: 'o  ';
}
.TermsAndCondition .lst-kix_list_1-2 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition ul.lst-kix_list_1-8 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-4 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.TermsAndCondition ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.TermsAndCondition .lst-kix_list_1-3 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_1-4 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_1-7 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_1-5 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_1-6 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.TermsAndCondition .lst-kix_list_2-0 > li:before {
  content: '\0025cf  ';
}
.TermsAndCondition .lst-kix_list_2-1 > li:before {
  content: 'o  ';
}
.TermsAndCondition .lst-kix_list_1-8 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_2-2 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition .lst-kix_list_2-3 > li:before {
  content: '\0025aa  ';
}
.TermsAndCondition ol {
  margin: 0;
  padding: 0;
}
table td,
.TermsAndCondition table th {
  padding: 0;
}
.TermsAndCondition .c9 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c7 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c5 {
  /* background-color: #ffffff; */
  padding-top: 14pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c6 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 3pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c1 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.TermsAndCondition .c4 {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: normal;
}
.TermsAndCondition .c0 {
  /* background-color: #ffffff; */
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c18 {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Calibri';
  font-style: normal;
}

.TermsAndCondition .c19 {
  text-decoration: underline;
}
.TermsAndCondition .c20 {
  margin-left: 20pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c15 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c17 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c14 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .c2 {
  font-size: 10pt;
  font-family: 'Arial';
  /* color: #000000; */
  font-weight: 400;
}
.TermsAndCondition .c8 {
  font-size: 10pt;
  font-family: 'Arial';
  font-weight: 400;
}
.TermsAndCondition .c12 {
  /* background-color: #ffffff; */
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.TermsAndCondition .c13 {
  /* background-color: #ffffff; */
  margin-left: 36pt;
  padding-left: 0pt;
}
.TermsAndCondition .c16 {
  padding: 0;
  margin: 0;
}
.TermsAndCondition .c10 {
  height: 12pt;
}
.TermsAndCondition .c3 {
  font-style: italic;
}
.TermsAndCondition .title {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition .subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: 'Georgia';
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition li {
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.TermsAndCondition p {
  margin: 0;
  /* color: #000000; */
  font-size: 12pt;
  font-family: 'Calibri';
}
.TermsAndCondition h1 {
  padding-top: 24pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition h2 {
  padding-top: 0pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 0pt;
  font-family: 'Times New Roman';
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition h3 {
  padding-top: 14pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition h4 {
  padding-top: 12pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition h5 {
  padding-top: 11pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.TermsAndCondition h6 {
  padding-top: 10pt;
  /* color: #000000; */
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Calibri';
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.search_pageContainer__IGdaR {
  background: radial-gradient(
    closest-side at 50% 50%,
    #292e40 0%,
    #07080c 100%
  );
}

.search_mainContent__1NzX3 {
  flex-grow: 1;
  width: 100%;
}

.search_mainContent__1NzX3.search_loading__2ytTv {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search_searchInput__1Hyv8 {
  /* background-color: #ffffffee; */
  background-color: #656565;
  color: white;
  border: none;
  font-size: 32px;
  margin: 0;
  padding: 13pt 10pt;
  width: 100%;
  border-radius: 1pt;
  /* height: 50pt; */
}
.search_searchInput__1Hyv8::-webkit-input-placeholder {
  font-weight: 500;
  color: white;
}
.search_searchInput__1Hyv8:-ms-input-placeholder {
  font-weight: 500;
  color: white;
}
.search_searchInput__1Hyv8::placeholder {
  font-weight: 500;
  color: white;
}
.search_imageGroup__1834N {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroup__1834N {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroup__1834N {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroup__1834N {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroup__1834N {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroup__1834N {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroup__1834N {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroup__1834N {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroup__1834N {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroup__1834N {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroup__1834N {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroup__1834N {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroup__1834N {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroup__1834N {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroup__1834N {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroup__1834N {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroup__1834N {
      padding-top: 40px;
    }
  }
}

.search_imageGroupTitle__1QDhp {
  color: #faf7f7;
}

.search_imageGroupTitle__1QDhp::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 14px;
  }
  .search_searchInput__1Hyv8 {
    margin-top: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .search_imageGroupTitle__1QDhp {
    font-size: 28px;
  }
}

.search_imageGroupImagesWrapper__1et8G {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .search_imageGroupImagesWrapper__1et8G {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .search_imageGroupImagesWrapper__1et8G {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .search_imageGroupImagesWrapper__1et8G {
    --site-padding: 110px !important;
  }
}

.search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
    margin-left: min(
      calc(-1 * var(--site-padding)),
      calc(-1 * env(safe-area-inset-left))
    );
    max-width: calc(
      100% + max(var(--site-padding), env(safe-area-inset-left)) +
        max(var(--site-padding), env(safe-area-inset-right))
    );
  }
}

@supports not (padding: max(0px)) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) {
      padding-right: var(--site-padding);
    }
  }
}

.search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImagesContent__3FgaF {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 26.71875px;
    width: calc(25% - 20.039px);
  }
}

@media only screen and (min-width: 1710px) {
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_imageGroupImageLink__2mlzc,
  .search_imageGroupImagesContainer__mgV3c:not(.search_isGrid__3-Ngo) .search_categoryButton__2gnGo {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo {
  display: block;
}

.search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImagesContent__3FgaF {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.search_imageGroupImagesContainer__mgV3c.search_isGrid__3-Ngo .search_imageGroupImageLink__2mlzc {
  width: 100%;
}

.search_imageGroupImageLink__2mlzc {
  color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.search_imageGroupImageLink__2mlzc::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.search_imageGroupImageLink__2mlzc div {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: left;
}

.search_imageGroupImageLink__2mlzc h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: max(
        var(--site-padding),
        env(safe-area-inset-right)
      ) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .search_imageGroupImageLink__2mlzc:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.search_imageGroupImageLink__2mlzc.search_dummy__2ywNN {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.search_imageGroupImage__1qRoB {
  height: calc(100% - 36px);
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.search_imageGroupImageLink__2mlzc.search_categoryButton__2gnGo::before {
  display: none !important;
}

.sign-in_forgotPasswordLink__3t0po {
  color: #808080 !important;
}
.sign-in_newsletter__2NFwK {
  align-items: center;
  display: flex;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .sign-in_newsletter__2NFwK {
    margin: 16px 8px 24px 0;
  }
}

@media only screen and (min-width: 768px) {
  .sign-in_newsletter__2NFwK {
    margin: 16px 8px 32px 4px;
  }
}

@media only screen and (max-width: 899px) {
  .sign-in_newsletter__2NFwK .MuiSvgIcon-root {
    height: 25.625px;
    width: 25.625px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-in_newsletter__2NFwK .MuiSvgIcon-root {
    height: 36.515625px !important;
    width: 36.515625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-in_newsletter__2NFwK .MuiSvgIcon-root {
    height: 30.75px;
    width: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-in_newsletter__2NFwK .MuiSvgIcon-root {
    height: 36.515625px;
    width: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-in_newsletter__2NFwK .MuiSvgIcon-root {
    height: 41px;
    width: 41px;
  }
}

.sign-in_newsletterCheckbox__1EAN0 {
  border-radius: 50% !important;
  color: #5aa5ef !important;
}

.sign-in_newsletterText__2MVAK {
  color: #eee;
  cursor: pointer;
  line-height: 1.25;
  margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
  .sign-in_newsletterText__2MVAK {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-in_newsletterText__2MVAK {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-in_newsletterText__2MVAK {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-in_newsletterText__2MVAK {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-in_newsletterText__2MVAK {
    font-size: 18px;
  }
}

.sign-in_termsContainer__sQBC_ {
  background-color: #31343e;
}

@media only screen and (max-width: 899px) {
  .sign-in_termsContainer__sQBC_ {
    border-radius: 6.25px;
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-in_termsContainer__sQBC_ {
    border-radius: 6.25px !important;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-in_termsContainer__sQBC_ {
    border-radius: 7.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-in_termsContainer__sQBC_ {
    border-radius: 8.90625px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-in_termsContainer__sQBC_ {
    border-radius: 10px;
    padding: 16px;
  }
}

.sign-in_terms__3dWx4 {
  color: #eee;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8;
}

@media only screen and (max-width: 899px) {
  .sign-in_terms__3dWx4 {
    font-size: 11px;
    line-height: 1.5 !important;
    margin-bottom: 27.2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-in_terms__3dWx4 {
    margin-bottom: 13.6px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-in_terms__3dWx4 {
    font-size: 12px;
    margin-bottom: 25.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-in_terms__3dWx4 {
    font-size: 14.25px;
    margin-bottom: 30.28125px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-in_terms__3dWx4 {
    font-size: 16px;
    margin-bottom: 34px;
    padding: 16px;
  }
}

.sign-in_nameFields__1tHl_ {
  display: flex;
}

.sign-in_nameFields__1tHl_ > *:first-child {
  margin-right: 10px;
}

.sign-in_backLink__1N2sA {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .sign-in_backLink__1N2sA:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .sign-in_backLink__1N2sA:active, .sign-in_backLink__1N2sA:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .sign-in_backLink__1N2sA {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .sign-in_backLink__1N2sA {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-in_backLink__1N2sA {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-in_backLink__1N2sA {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-in_backLink__1N2sA {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-in_backLink__1N2sA {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.sign-in_reCaptchaContainer__1xq6O {
  /* display: flex;
  justify-content: center; */
  margin-bottom: 40px;
  margin-left: 18px;
}

.sign-up_signInLink__Hd1_o {
  color: #888888;
  position: absolute;
}
.sign-up_backLink__3G3Jy {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .sign-up_signInLink__Hd1_o:hover {
    color: #fff;
    text-decoration: none;
  }
  .sign-up_backLink__3G3Jy:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .sign-up_signInLink__Hd1_o:active, .sign-up_signInLink__Hd1_o:target {
    color: #fff;
    text-decoration: none;
  }
  .sign-up_backLink__3G3Jy:active, .sign-up_backLink__3G3Jy:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .sign-up_signInLink__Hd1_o {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    right: 18px;
    top: 18px;
  }
  .sign-up_backLink__3G3Jy {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .sign-up_signInLink__Hd1_o {
    font-weight: 600;
  }
  .sign-up_backLink__3G3Jy {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_signInLink__Hd1_o {
    font-size: 15.625px !important;
    right: 50px !important;
    top: 50px !important;
  }
  .sign-up_backLink__3G3Jy {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_signInLink__Hd1_o {
    font-size: 18.75px;
    right: 60px;
    top: 60px;
  }
  .sign-up_backLink__3G3Jy {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_signInLink__Hd1_o {
    font-size: 22.265625px;
    right: 71.25px;
    top: 71.25px;
  }
  .sign-up_backLink__3G3Jy {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_signInLink__Hd1_o {
    font-size: 25px;
    right: 80px;
    top: 80px;
  }
  .sign-up_backLink__3G3Jy {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.sign-up_termsContainer__cC39j {
  background-color: #31343e;
}

@media only screen and (max-width: 899px) {
  .sign-up_termsContainer__cC39j {
    border-radius: 6.25px;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_termsContainer__cC39j {
    border-radius: 6.25px !important;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_termsContainer__cC39j {
    border-radius: 7.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_termsContainer__cC39j {
    border-radius: 8.90625px;
    padding: 12.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_termsContainer__cC39j {
    border-radius: 10px;
    padding: 16px;
  }
}

.sign-up_terms__1poOC {
  color: #eee;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.8;
}

@media only screen and (max-width: 899px) {
  .sign-up_terms__1poOC {
    font-size: 11px;
    line-height: 1.5 !important;
    margin-bottom: 27.2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_terms__1poOC {
    margin-bottom: 13.6px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_terms__1poOC {
    font-size: 12px;
    margin-bottom: 25.5px;
    padding: 12px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_terms__1poOC {
    font-size: 14.25px;
    margin-bottom: 30.28125px;
    padding: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_terms__1poOC {
    font-size: 16px;
    margin-bottom: 34px;
    padding: 16px;
  }
}

.sign-up_newsletter__3GeF_ {
  align-items: center;
  display: flex;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .sign-up_newsletter__3GeF_ {
    margin: 16px 8px 24px 0;
  }
}

@media only screen and (min-width: 768px) {
  .sign-up_newsletter__3GeF_ {
    margin: 16px 8px 32px 4px;
  }
}

@media only screen and (max-width: 899px) {
  .sign-up_newsletter__3GeF_ .MuiSvgIcon-root {
    height: 25.625px;
    width: 25.625px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_newsletter__3GeF_ .MuiSvgIcon-root {
    height: 36.515625px !important;
    width: 36.515625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_newsletter__3GeF_ .MuiSvgIcon-root {
    height: 30.75px;
    width: 30.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_newsletter__3GeF_ .MuiSvgIcon-root {
    height: 36.515625px;
    width: 36.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_newsletter__3GeF_ .MuiSvgIcon-root {
    height: 41px;
    width: 41px;
  }
}

.sign-up_newsletterCheckbox__3ljI- {
  border-radius: 50% !important;
  color: #5aa5ef !important;
}

.sign-up_newsletterText__sXk0f {
  color: #eee;
  cursor: pointer;
  line-height: 1.25;
  margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
  .sign-up_newsletterText__sXk0f {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_newsletterText__sXk0f {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_newsletterText__sXk0f {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_newsletterText__sXk0f {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_newsletterText__sXk0f {
    font-size: 18px;
  }
}

.sign-up_notes__3sGJ1 {
  color: #ddd;
  font-weight: 300;
  line-height: 1.7;
  position: relative;
}

.sign-up_notes__3sGJ1::before {
  background-color: #5aa5ef;
  border-radius: 4px;
  content: ' ';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .sign-up_notes__3sGJ1::before {
    width: 2px;
  }
}

@media only screen and (min-width: 768px) {
  .sign-up_notes__3sGJ1::before {
    width: 4px;
  }
}

@media only screen and (max-width: 899px) {
  .sign-up_notes__3sGJ1 {
    font-size: 12px;
    margin: 44px 0;
    padding-left: 18.5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_notes__3sGJ1 {
    font-size: 13.749999999999998px !important;
    margin: 50px 0 !important;
    padding-left: 16.5625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_notes__3sGJ1 {
    font-size: 16.5px;
    margin: 60px 0;
    padding-left: 16.875px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_notes__3sGJ1 {
    font-size: 19.59375px;
    margin: 71.25px 0;
    padding-left: 16.4765625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_notes__3sGJ1 {
    font-size: 22px;
    margin: 80px 0;
    padding-left: 18.5px;
  }
}

.sign-up_notesEmail__3OX26 {
  color: #fff;
  font-weight: 500;
}

@media only screen and (max-width: 899px) {
  .sign-up_notesEmail__3OX26 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .sign-up_notesEmail__3OX26 {
    font-size: 15.625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .sign-up_notesEmail__3OX26 {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sign-up_notesEmail__3OX26 {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .sign-up_notesEmail__3OX26 {
    font-size: 25px;
  }
}

.sign-up_nameFields__4BscV {
  display: flex;
}

.sign-up_nameFields__4BscV > *:first-child {
  margin-right: 10px;
}

.sign-up_linkClass__28xoy > a {
  color: #5AA5EF;
}

.sign-up_reCaptchaContainer__50vvj {
  /* display: flex; */
  /* justify-content: center; */
  margin-bottom: 40px;
  margin-left: 18px;
}
.newSign-Up_signInLink__2HhNn {
    color: #888888;
    position: absolute;
}

.newSign-Up_backLink__1mrLk {
    color: #888888;
    position: absolute;
    cursor: pointer;
}

@media all and (hover: hover) {
    .newSign-Up_signInLink__2HhNn:hover {
        color: #fff;
        text-decoration: none;
    }

    .newSign-Up_backLink__1mrLk:hover {
        color: #fff;
        text-decoration: none;
    }
}

@media all and (pointer: coarse) {

    .newSign-Up_signInLink__2HhNn:active,
    .newSign-Up_signInLink__2HhNn:target {
        color: #fff;
        text-decoration: none;
    }

    .newSign-Up_backLink__1mrLk:active,
    .newSign-Up_backLink__1mrLk:target {
        color: #fff;
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .newSign-Up_signInLink__2HhNn {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        right: 18px;
        top: 18px;
    }

    .newSign-Up_backLink__1mrLk {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        left: 18px;
        top: 18px;
    }
}

@media only screen and (min-width: 768px) {
    .newSign-Up_signInLink__2HhNn {
        font-weight: 600;
    }

    .newSign-Up_backLink__1mrLk {
        font-weight: 600;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_signInLink__2HhNn {
        font-size: 15.625px !important;
        right: 50px !important;
        top: 50px !important;
    }

    .newSign-Up_backLink__1mrLk {
        font-size: 15.625px !important;
        left: 50px !important;
        top: 50px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_signInLink__2HhNn {
        font-size: 18.75px;
        right: 60px;
        top: 60px;
    }

    .newSign-Up_backLink__1mrLk {
        font-size: 18.75px;
        left: 60px;
        top: 60px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_signInLink__2HhNn {
        font-size: 22.265625px;
        right: 71.25px;
        top: 71.25px;
    }

    .newSign-Up_backLink__1mrLk {
        font-size: 22.265625px;
        left: 71.25px;
        top: 71.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_signInLink__2HhNn {
        font-size: 25px;
        right: 80px;
        top: 80px;
    }

    .newSign-Up_backLink__1mrLk {
        font-size: 25px;
        left: 80px;
        top: 80px;
    }
}

.newSign-Up_termsContainer__2IVqJ {
    background-color: #31343e;
}

@media only screen and (max-width: 899px) {
    .newSign-Up_termsContainer__2IVqJ {
        border-radius: 6.25px;
        padding: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_termsContainer__2IVqJ {
        border-radius: 6.25px !important;
        padding: 10px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_termsContainer__2IVqJ {
        border-radius: 7.5px;
        padding: 12px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_termsContainer__2IVqJ {
        border-radius: 8.90625px;
        padding: 12.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_termsContainer__2IVqJ {
        border-radius: 10px;
        padding: 16px;
    }
}

.newSign-Up_terms__1VGz2 {
    color: #eee;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.8;
}

@media only screen and (max-width: 899px) {
    .newSign-Up_terms__1VGz2 {
        font-size: 11px;
        line-height: 1.5 !important;
        margin-bottom: 27.2px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_terms__1VGz2 {
        margin-bottom: 13.6px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_terms__1VGz2 {
        font-size: 12px;
        margin-bottom: 25.5px;
        padding: 12px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_terms__1VGz2 {
        font-size: 14.25px;
        margin-bottom: 30.28125px;
        padding: 14.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_terms__1VGz2 {
        font-size: 16px;
        margin-bottom: 34px;
        padding: 16px;
    }
}

.newSign-Up_newsletter__1tdiY {
    align-items: center;
    display: flex;
    line-height: 1.2;
}

@media only screen and (max-width: 767px) {
    .newSign-Up_newsletter__1tdiY {
        margin: 16px 8px 24px 0;
    }
}

@media only screen and (min-width: 768px) {
    .newSign-Up_newsletter__1tdiY {
        margin: 16px 8px 32px 4px;
    }
}

@media only screen and (max-width: 899px) {
    .newSign-Up_newsletter__1tdiY .MuiSvgIcon-root {
        height: 25.625px;
        width: 25.625px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_newsletter__1tdiY .MuiSvgIcon-root {
        height: 36.515625px !important;
        width: 36.515625px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_newsletter__1tdiY .MuiSvgIcon-root {
        height: 30.75px;
        width: 30.75px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_newsletter__1tdiY .MuiSvgIcon-root {
        height: 36.515625px;
        width: 36.515625px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_newsletter__1tdiY .MuiSvgIcon-root {
        height: 41px;
        width: 41px;
    }
}

.newSign-Up_newsletterCheckbox__1vsFv {
    border-radius: 50% !important;
    color: #5aa5ef !important;
}

.newSign-Up_newsletterText__3lYqL {
    color: #eee;
    cursor: pointer;
    line-height: 1.25;
    margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
    .newSign-Up_newsletterText__3lYqL {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_newsletterText__3lYqL {
        font-size: 13.5px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_newsletterText__3lYqL {
        font-size: 13.5px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_newsletterText__3lYqL {
        font-size: 16.03125px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_newsletterText__3lYqL {
        font-size: 18px;
    }
}

.newSign-Up_notes__27jfO {
    color: #ddd;
    font-weight: 300;
    line-height: 1.7;
    position: relative;
}

.newSign-Up_notes__27jfO::before {
    background-color: #5aa5ef;
    border-radius: 4px;
    content: ' ';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
}

@media only screen and (max-width: 767px) {
    .newSign-Up_notes__27jfO::before {
        width: 2px;
    }
}

@media only screen and (min-width: 768px) {
    .newSign-Up_notes__27jfO::before {
        width: 4px;
    }
}

@media only screen and (max-width: 899px) {
    .newSign-Up_notes__27jfO {
        font-size: 12px;
        margin: 44px 0;
        padding-left: 18.5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_notes__27jfO {
        font-size: 13.749999999999998px !important;
        margin: 50px 0 !important;
        padding-left: 16.5625px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_notes__27jfO {
        font-size: 16.5px;
        margin: 60px 0;
        padding-left: 16.875px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_notes__27jfO {
        font-size: 19.59375px;
        margin: 71.25px 0;
        padding-left: 16.4765625px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_notes__27jfO {
        font-size: 22px;
        margin: 80px 0;
        padding-left: 18.5px;
    }
}

.newSign-Up_notesEmail__1iCrR {
    color: #fff;
    font-weight: 500;
}

@media only screen and (max-width: 899px) {
    .newSign-Up_notesEmail__1iCrR {
        font-size: 12px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-Up_notesEmail__1iCrR {
        font-size: 15.625px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-Up_notesEmail__1iCrR {
        font-size: 18.75px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-Up_notesEmail__1iCrR {
        font-size: 22.265625px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-Up_notesEmail__1iCrR {
        font-size: 25px;
    }
}

.newSign-Up_nameFields__zm_q4 {
    display: flex;
}

.newSign-Up_nameFields__zm_q4>*:first-child {
    margin-right: 10px;
}

.newSign-Up_linkClass__2xm1X>a {
    color: #5AA5EF;
}

.newSign-Up_reCaptchaContainer__2CwAy {
    /* display: flex; */
    /* justify-content: center; */
    margin-bottom: 40px;
    margin-left: 18px;
}

.newSign-Up_userInput__Swern {
    font-size: 20px !important;
}
/* .bgLayer, */
.video-details_bgDarkLayer__1PakQ {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.video-details_bgDarkLayer__1PakQ {
  background-color: rgba(0, 0, 0, 0.25);
}
.video-details_centerCirlce__1WWE4 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
@media only screen and (max-width: 899px) {
  .video-details_page__-U2_P {
    padding-bottom: 0 !important;
  }
  .video-details_containerx__3lvka {
    /* width: 60% !important; */
    width: 100% !important;
    height: 70%;
  }
}

.video-details_pageContainer__3PTmK {
  background: #000000;
}

@media only screen and (max-width: 899px) {
  .video-details_main__2xMFC {
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.video-details_previewContainer__1VWIp {
  min-width: 100%;
}

.video-details_topContainer__3vyvw,
.video-details_bottomContainer__1FvZ8 {
  width: 100%;
  /* display:flex; */
  justify-content: center;
}

.video-details_topContainer__3vyvw {
  position: relative;
  /* padding-bottom:0.5em; */
  /* height: 75%; */
}

.video-details_bottomContainer__1FvZ8 {
  background-color: #000000;
  align-items: flex-start;
  /* padding-top:0.5em; */
  /* height: 25%; */
  padding-left: 100px;
  padding-right: 100px;
}

.video-details_bgLayer__3UTCs {
  /* background-attachment: fixed; */
  background-color: #000000;
  position: relative;
  /* width: 100%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset; */
  /* border-radius: 0vw 3vw 3vw 0vw; */
}
.video-details_image_container__6GZ1H {
  position: relative;
  display: inline-block; /* or block, depending on your layout */
}
.video-details_image_container__6GZ1H img {
  display: block;
  width: 100%;
  height: auto;
}
.video-details_gradient_overlay__3HF6F {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1));
  /* Gradients for both right-to-left and bottom-to-top */
}
.video-details_containerx__3lvka {
  position: absolute;
  right: 0;
  width: 45%;
  aspect-ratio: 19 / 12;
  overflow: hidden;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.video-details_backImg__2zwNl {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.video-details_backButtonBlock__3uIpt {
  /* position: relative; */
  margin-left: 90px;
  /* margin-top: 40px; */

  /* left: 2%;
  top: 5%; */
  cursor: pointer;
}

.video-details_videoBackButton__q9rNl {
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  width: 50px;
  margin-top: 50px;
}

.video-details_backText__3vmYj {
  vertical-align: middle;
  font-size: 28px;
  padding: 15px;
}

.video-details_videoDetailsContainer__3cKDD {
  color: #fff;
  /* width: 60%; */
  top: 25%;
  position: absolute;
  left: 10%;
  /* margin-left: 116px; */
  /* margin-top: 100px; */
}

@media only screen and (max-width: 899px) {
  .video-details_videoDetailsContainer__3cKDD {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh !important; */
  }

  .video-details_backButtonBlock__3uIpt {
    margin-left: 30px;
  }

  .video-details_bottomContainer__1FvZ8 {
    padding-left: 30px;
    padding-right: 100px;
  }

  @media only screen and (max-width: 899px) {
    .video-details_bottomContainer__1FvZ8 {
      padding-left: 50px;
      display: none;
    }

    .video-details_topContainer__3vyvw {
      height: 100vh;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 1622px) {
    .video-details_bottomContainer__1FvZ8 {
      background-color: #000000;
      align-items: flex-start;
      /* padding-top:0.5em; */
      /* height: 25%; */
      padding-left: 100px;
      margin-top: 10px;
    }
  }

  .video-details_videoDetails__3wfuY {
    padding-left: 30px !important;
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1710px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1710px) {
      .video-details_videoDetailsContainer__3cKDD {
        padding-bottom: 0;
      }
    }
  }

  .video-details_videoPlayButton__140TT {
    display: flex !important;
    width: 230px;
  }
}

.video-details_videoTitle__7fCFK {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  margin-bottom: 3%;
}

@media only screen and (max-width: 899px) {
  .video-details_videoTitle__7fCFK {
    /* margin-bottom: auto; */
    font-size: 22.400000000000002px;
  }

  .video-details_videoTitle__7fCFK {
    left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .video-details_videoTitle__7fCFK {
    font-size: 27.400000000000002px;
    z-index: 5;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoTitle__7fCFK {
    font-size: 24.937500000000004px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoTitle__7fCFK {
    font-size: 29.25px;
    /* margin-right: 45%; */
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoTitle__7fCFK {
    font-size: 26.50000000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoTitle__7fCFK {
    font-size: 33.78125000000001px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoTitle__7fCFK {
    font-size: 35px;
    margin-right: 50%;
  }
}

.video-details_videoDetails__3wfuY {
  display: flex;
  bottom: 0;
  width: 100%;
  color: #fff;
  /* position: absolute; */
  padding-left: 100px;
  flex-direction: column;
  top: 350px;
  margin-top: 8%;
  /* margin-bottom: 20px; */
}

.video-details_videoActions__33z0t {
  align-items: center;
  display: flex;
}

/* @media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoActions {
    margin-top: 10px;
   
   
  }
} */

/* @media only screen and (min-width: 1490px) {
  .videoActions {
    margin-top: -0px;
  }
} */

/* @media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoActions {
    margin-top: -60px;
  }
} */

.video-details_videoPlayButton__140TT {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  position: relative;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .video-details_videoPlayButton__140TT {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoPlayButton__140TT {
    font-size: 16px;
    height: 56px;
    padding: 0 30px;
    width: 150px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoPlayButton__140TT {
    font-size: 16.25px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoPlayButton__140TT {
    font-size: 19.5px;
    height: 55.5px;
    padding: 0 34.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoPlayButton__140TT {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
  }
}

/* @media only screen and (min-width: 973px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
    margin-top: 150px;
  }
} */
/* @media only screen and (width: 1622px) {
  .videoPlayButton {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
    margin-top: 150px;
  }
} */
@media only screen and (min-width: 1710px) {
  .video-details_videoPlayButton__140TT {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
  }
}

/* @media only screen and (min-width: 973px) and (max-width: 1622px) {
  .videoPlayButton {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
    margin-top: -150px;
  }
} */
@media only screen and (max-width: 767px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 27px;
    margin-right: 17.25px;
    width: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 32.0625px;
    margin-right: 20.48438px;
    width: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoPlayIcon__1wsvQ {
    height: 36px;
    margin-right: 23px;
    width: 36px;
  }
}

.video-details_alignButton__1IsVc {
  margin-left: 1%;
}

@media only screen and (min-width: 900px) {
  .video-details_header__1h5Co {
    display: none;
  }
}

.video-details_videoAddButton__7wENx {
  position: relative;
}

.video-details_videoAddButton__7wENx {
  align-items: center;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 1px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .video-details_videoAddButton__7wENx {
    border-width: 2px;
    font-size: 22.4px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoAddButton__7wENx {
    border-width: 2px;
    font-size: 26.25px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoAddButton__7wENx {
    border-width: 3px;
    font-size: 35px;
    height: 56px;
    width: 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoAddButton__7wENx {
    border-width: 3px;
    font-size: 42px;
    height: 55px;
    width: 55px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoAddButton__7wENx {
    border-width: 4px;
    font-size: 49.875px;
    height: 56.125px;
    width: 56.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoAddButton__7wENx {
    border-width: 4px;
    font-size: 56px;
    height: 68px;
    width: 68px;
  }
}

.video-details_videoInfo__2wkaH {
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .video-details_videoInfo__2wkaH {
    font-size: 15px;
    /* margin-left: -20px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoInfo__2wkaH {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoInfo__2wkaH {
    font-size: 15px;
    /* margin-left: -45px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoInfo__2wkaH {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoInfo__2wkaH {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoInfo__2wkaH {
    font-size: 20px;
  }
}

.video-details_videoInfoSeparator__1ZqFP {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 15px;
    margin: 0 7.5px;
    width: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 15px;
    margin: 0 9px;
    width: 2px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 17.8125px;
    margin: 0 10.6875px;
    width: 3px;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoInfoSeparator__1ZqFP {
    height: 20px;
    margin: 0 12px;
    width: 3px;
  }
}

.video-details_videoDescription__19vwO {
  font-weight: 600;
  line-height: 0.045em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .video-details_videoDescription__19vwO {
    font-size: 14px;
    /* margin-top: 15px; */
    line-height: 1.25;
    /* margin-left: -20px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_videoDescription__19vwO {
    font-size: 14px;
    margin-right: 20%;
    /* margin-top: 15.4375px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_videoDescription__19vwO {
    font-size: 15px;
    margin-right: 30%;
    /* margin-top: 10.250px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoDescription__19vwO {
    font-size: 15px;
    /* margin-right: 45%; */
    margin-right: 50%;
    /* margin-top: 6.5px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_videoDescription__19vwO {
    font-size: 15.375px;
    margin-right: 45%;
    /* margin-top: 10.53125px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_videoDescription__19vwO {
    font-size: 24px;
    margin-right: 45%;
    /* margin-top: 50px; */
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_videoInfoWrap__3CjrG {
    display: block;
  }
}

.video-details_loadingText__2jz8f {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
}

.video-details_thumbnailDiv__14nrB {
  position: fixed;
  top: 0;
  right: 25px;
  width: 35%;
  height: 40%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
}

@media only screen and (max-width: 767px) {
  .video-details_thumbnailDiv__14nrB {
    right: 4px;
    margin-top: 5px;
    width: 300px;
    height: 230px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-details_thumbnailDiv__14nrB {
    right: 5px;
    margin-top: 10px;
    width: 350px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-details_thumbnailDiv__14nrB {
    right: 20px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-details_thumbnailDiv__14nrB {
    right: 20px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-details_thumbnailDiv__14nrB {
    /* right:20px;  */
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .video-details_thumbnailDiv__14nrB {
    right: 1px;
  }
}

/* .react-multiple-carousel__arrow--right{
  margin-left: 100px;
} */

.video-details_videoInfoWrap__3CjrG {
  margin-top: 5px;
}

.components_imageGroupImage__3Cb6B {
  bottom: 160px;
  height: 130px;
  right: 30px;
  object-fit: fill;
  object-position: center;
  position: absolute;
  width: 260px;
  border-radius: 5px;
}
.components_imageGroupImage__3Cb6B:hover {
    border: 2px solid white;
}

.components_nextVideoWrapper__27O1O {
  display: block;
  cursor: pointer;
}

.components_titleContainer__38aRR {
  width: 260px;
  height: 50px;
  position: absolute;
  right: 30px;
  bottom: 130px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.components_titleContainer__38aRR h4 {
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 150px; 
  height: 2em; 
  white-space: nowrap;
}

.components_nextVideoText__1Uqe- {
  width: 260px;
  height: 50px;
  position: absolute;
  right: 30px;
  bottom: 100px;
  color: #fff;
}

.video-player-0_react_player__QbYFu {
  z-index: 0;
}

.video-player-0_colorChangePlay__1ksai  {
  fill: #909298;
}
   
.video-player-0_colorChangePlay__1ksai:hover {
  fill: #fff;
}

.video-player-0_toolTip__QTWS_:after {
  content: " ";
  position: absolute;
  right: 62px;
  bottom: -9px;
  border-top: 9px solid #707070;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

.video-player-0_timeStampInThumb__7Pini {
  position: relative;
  bottom: 77.5px;
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: 500;
}

.video-player-0_timeStampInThumbWithOutImage__2j2xh {
  position: relative;
  bottom: 75px;
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: 500;
}

.video-player-0_toolTipWithOutImage__2HLFf:after {
  content: " ";
  position: absolute;
  right: 17px;
  bottom: -9px;
  border-top: 9px solid #707070;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: none;
}

.video-player-0_backButton__1PKHR {
  margin: 14px 20px;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Montserrat';
  z-index: 2;
  width: 25%;
}

@media only screen and (max-width: 899px) {
  .video-player-0_backButton__1PKHR {
    font-size: 16px;
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-player-0_backButton__1PKHR {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-player-0_backButton__1PKHR {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-player-0_backButton__1PKHR {
    margin: 12px 19px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .video-player-0_backButton__1PKHR {
    margin: 12px 20px !important;
  }
}

.video-player-0_colorChangeControl__I5T1S {
  opacity: 0.51;
}

.video-player-0_colorChangeControl__I5T1S:hover {
  opacity: 1;
}

.video-player-0_box__Rl1vS {
  width: 50px;
  height: 170px;
  background-color: #707070;
  color: #000;
  padding: 24px 15px 0px 15px;  /* position: relative; */
  margin: 40px;
  float: left;
  display: block;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  /* right: 90px; */
  top: 65px;
}

.video-player-0_box__Rl1vS.video-player-0_arrowTop__22GNK {
  margin-top: 10px;
}

.video-player-0_box__Rl1vS.video-player-0_arrowTop__22GNK:after {
  content: " ";
  position: absolute;
  right: 19px;
  top: -8px;
  border-top: none;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 8px solid #707070;
}

@media only screen and (max-width: 319px) {
  .video-player-0_box__Rl1vS {
    right: 1%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .video-player-0_box__Rl1vS {
    right: 1%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 550px) {
  .video-player-0_box__Rl1vS {
    right: 1%;
  }
}
@media only screen and (min-width: 551px) and (max-width: 767px) {
  .video-player-0_box__Rl1vS {
    right: 3%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .video-player-0_box__Rl1vS {
    right: 6%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .video-player-0_box__Rl1vS {
    right: 7%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .video-player-0_box__Rl1vS {
    right: 8%;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .video-player-0_box__Rl1vS {
    right: 8%;
  }
}
@media only screen and (min-width: 1710px) {
  .video-player-0_box__Rl1vS {
    right: 9%;
  }
}

.video-player-0_playAgain__1VoDu {
  font-weight: 700;
  font-family: 'Montserrat';
  font-size: 26px;
  color: #fff;
}

.video-player-0_colorChangePause__WWi2m {
  stroke: #959595
}

.video-player-0_colorChangePause__WWi2m:hover {
  stroke: #fff;
} 

.video-player-0_timeStamp__1sxQn {
  font-family: 'Montserrat';
  font-size: 16px;
}
.watchlist_pageContainer__16VtW {
  background: radial-gradient(closest-side at 50% 50%, #292e40 0%, #07080c 100%);
}

.watchlist_mainContent__SbITL {
  flex-grow: 1;
  width: 100%;
}

.watchlist_mainContent__SbITL.watchlist_loading__3stMM {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.watchlist_heading__2PzFm {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 899px) {
  .watchlist_heading__2PzFm {
    height: 183px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_heading__2PzFm {
    border-radius: 13.125px;
    height: 159.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_heading__2PzFm {
    border-radius: 15.749999999999998px;
    height: 191.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_heading__2PzFm {
    border-radius: 18.703125px;
    height: 227.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_heading__2PzFm {
    border-radius: 21px;
    height: 255px;
  }
}

.watchlist_headingBG__37AbW {
  border: none;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.watchlist_headingBGFade__Qk6Vi {
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.watchlist_headingText__2hjF0 {
  color: #ffffff;
  font-weight: 800;
  line-height: 1.2;
  margin-left: auto;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 16px;
  text-transform: uppercase;
  z-index: 10;
}

@media only screen and (max-width: 767px) {
  .watchlist_headingText__2hjF0 {
    font-size: 36px;
    margin-right: auto;
    padding: 24px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .watchlist_headingText__2hjF0 {
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .watchlist_headingText__2hjF0 {
    font-size: 25.78125px;
    margin-right: 33.75px;
    max-width: 240px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_headingText__2hjF0 {
    font-size: 34.375px;
    margin-right: 45px;
    max-width: 320px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_headingText__2hjF0 {
    font-size: 41.25px;
    margin-right: 54px;
    max-width: 384px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_headingText__2hjF0 {
    font-size: 48.984375px;
    margin-right: 64.125px;
    max-width: 456px;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_headingText__2hjF0 {
    font-size: 55px;
    margin-right: 72px;
    max-width: 512px;
  }
}

.watchlist_imageGroup__24eF3 {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroup__24eF3 {
      padding-top: 40px;
    }
  }
}

.watchlist_imageGroupTitle__3Jvb2 {
  color: #faf7f7;
}

.watchlist_imageGroupTitle__3Jvb2::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 14px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_imageGroupTitle__3Jvb2 {
    font-size: 28px;
  }
}

.watchlist_imageGroupImagesWrapper__3P6BY {
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_imageGroupImagesWrapper__3P6BY {
    --site-padding: 82.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_imageGroupImagesWrapper__3P6BY {
    --site-padding: 97.96875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_imageGroupImagesWrapper__3P6BY {
    --site-padding: 110px !important;
  }
}

.watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
  display: flex;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw !important;
}

.watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad)::-webkit-scrollbar {
  display: none;
}

@supports (padding: max(0px)) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
    margin-left: min(calc(-1 * var(--site-padding)), calc(-1 * env(safe-area-inset-left)));
    max-width: calc(100% + max(var(--site-padding), env(safe-area-inset-left)) + max(var(--site-padding), env(safe-area-inset-right)));
  }
}

@supports not (padding: max(0px)) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
    margin-left: var(--site-padding);
    max-width: calc(100% + (2 * var(--site-padding)));
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: max(var(--site-padding), env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-left: var(--site-padding);
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: max(var(--site-padding), env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: var(--site-padding);
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) {
      padding-right: var(--site-padding);
    }
  }
}

.watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImagesContent__3EY5u {
  display: flex;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 12px;
    width: calc(50% - 6px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 14.0625px;
    width: calc(33.33% - 9.375px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 18.75px;
    width: calc(33.33% - 12.5px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 22.5px;
    width: calc(25% - 16.875px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 26.71875px;
    width: calc(25% - 20.0390px);
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_imageGroupImageLink__1XbKG,
  .watchlist_imageGroupImagesContainer__2EAHe:not(.watchlist_isGrid__2T3Ad) .watchlist_categoryButton__1-w_e {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
}

.watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad {
  display: block;
}

.watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 12px;
    gap: 12px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 14.0625px;
    gap: 14.0625px;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 18.75px;
    gap: 18.75px;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 22.5px;
    gap: 22.5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 26.71875px;
    gap: 26.71875px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImagesContent__3EY5u {
    grid-gap: 30px;
    gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.watchlist_imageGroupImagesContainer__2EAHe.watchlist_isGrid__2T3Ad .watchlist_imageGroupImageLink__1XbKG {
  width: 100%;
}

.watchlist_imageGroupImageLink__1XbKG {
  color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  text-align: center;
}

.watchlist_imageGroupImageLink__1XbKG::before {
  content: ' ';
  display: block;
  padding-top: 60%;
  width: 100%;
}

.watchlist_imageGroupImageLink__1XbKG div {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: left;
}

.watchlist_imageGroupImageLink__1XbKG h4::first-letter {
  text-transform: uppercase;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(var(--site-padding), env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(var(--site-padding), env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: max(var(--site-padding), env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .watchlist_imageGroupImageLink__1XbKG:nth-last-child(2) {
      margin-right: var(--site-padding) !important;
    }
  }
}

.watchlist_imageGroupImageLink__1XbKG.watchlist_dummy__36ap- {
  border: none !important;
  margin-right: -1px !important;
  min-width: auto !important;
  opacity: 0 !important;
  width: 1px !important;
}

.watchlist_imageGroupImage__2NNJY {
  height: calc(100% - 36px);
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.watchlist_imageGroupImageLink__1XbKG.watchlist_categoryButton__1-w_e::before {
  display: none !important;
}

.watchlist_categoriesLoader__6xuMz {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 256px;
}

.watchlist_categoryButton__1-w_e {
  align-items: center;
  background-color: #2f303d;
  border: 3px solid #3d3e49;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: capitalize !important;
}

.watchlist_categoryButton__1-w_e.watchlist_active__3xGhl {
  border: 3px solid #c1c1c1 !important;
}

@media only screen and (max-width: 767px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 14px;
    height: 79.21875px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 14px;
    height: 105.62500000000001px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 17.5px;
    height: 126.75000000000001px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 21px;
    height: 150.515625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 24.9375px;
    height: 169px;
  }
}

@media only screen and (min-width: 1710px) {
  .watchlist_categoryButton__1-w_e {
    font-size: 28px;
    height: 169px;
  }
}

.watchlist_categoryButton__1-w_e.watchlist_dummy__36ap- {
  border: none !important;
  border-right: 1px solid transparent !important;
  opacity: 0 !important;
  width: 1px !important;
}
.watchlist_watchlistContainer__34U2H{
  margin-top: -100px;
}
.content-layout-details_loader__33bUj {
  align-items: center;
  display: flex;
  padding: 2em var(--site-padding);
  position: relative;
  width: 100%;
}
.content-layout-details_existButtun__2D8WK {
  /* margin: 0 15px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3D3E49;
  border-radius: 9px;
  opacity: 1;
  min-width: 89px;
  /* max-width: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  cursor: pointer;
  color: #282D33;
  opacity: 1;
  padding: 6.5px 16.5px;
}
.content-layout-details_existButtun__2D8WK:hover {
  /* background: #579ce1 !important; */
  color: #579ce1;
}
.content-layout-details_discard__mSe8o:hover {
  color: white;
}
.content-layout-details_discard__mSe8o {
  color: #F74140;
  background: transparent;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.content-layout-details_accessedCompny__KOn87 {
  display: flex;
  background-color: #5AA5EF;
  width: 94%;
  grid-gap: 20px;
  grid-gap: 20px;
  gap: 20px;
  z-index: 111;
  cursor: pointer;
  position: fixed;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px !important;
  top: 111px;
  padding: 5px 23px;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: -3%;
}
@media only screen and (max-width: 899px) {
  .content-layout-details_accessedCompny__KOn87 {
    top:64px;
  }
}
@media only screen and (min-width: 1710px) {
  .content-layout-details_accessedCompny__KOn87 {
    top: 111px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_accessedCompny__KOn87 {
    top:70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_accessedCompny__KOn87 {
    top:83.25px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_accessedCompny__KOn87 {
    top: 98.859375px;
  }
}
@media only screen and (max-width: 899px) {
  .content-layout-details_accessedCompny__KOn87 {
    width: 100%;
    justify-content: start;
    bottom: -1px;
    padding: 5px 13px;
    height: 40px;
    top: unset;
    margin-left: -3%;
  }
}
@media only screen and (max-width: 767px) {
  .content-layout-details_loader__33bUj {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .content-layout-details_loader__33bUj {
    justify-content: flex-start;
  }
}

.content-layout-details_loaderContent__LWSbi {
  display: inline;
  white-space: nowrap;
}

.content-layout-details_page__MczCq {
  background-color: #ffffff;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_pageTitle__iYEEP {
    display: none;
  }
}

.content-layout-details_pageContent__3PlBQ {
  padding-left: 100px !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  background-color: #f7f7f7;
}

@media only screen and (max-width: 1199px) {
  .content-layout-details_name__2IcDU {
    margin-bottom: 11.51px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_name__2IcDU {
    margin-bottom: 11.625000000000002px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_name__2IcDU {
    margin-bottom: 13.804687500000002px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_name__2IcDU {
    margin-bottom: 15.5px;
  }
}

.content-layout-details_categoryContainer__2J2iI,
.content-layout-details_categoryContainerImages__3d3C9 {
  align-items: center;
  display: flex;
}

.content-layout-details_categoryContainer__2J2iI {
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_categoryContainer__2J2iI {
    justify-content: space-between;
    width: 100%;
  }
  .content-layout-details_pageContent__3PlBQ {
    padding-left: 0px !important;
  }
}

.content-layout-details_editIcon__1dmrx {
  cursor: pointer;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_editIcon__1dmrx {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-details_editIcon__1dmrx {
    display: none;
  }
}

.content-layout-details_dragIconContainer__2874x {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_dragIconContainer__2874x {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_dragIconContainer__2874x {
    margin-right: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_dragIconContainer__2874x {
    margin-right: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_dragIconContainer__2874x {
    margin-right: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_dragIconContainer__2874x {
    margin-right: 33.33px;
  }
}

.content-layout-details_dragIcon__opLsv {
  color: #a0a0a0;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_dragIcon__opLsv {
    height: 15px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_dragIcon__opLsv {
    height: 20.83125px;
    width: 20.83125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_dragIcon__opLsv {
    height: 24.9975px;
    width: 24.9975px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_dragIcon__opLsv {
    height: 29.68453125px;
    width: 29.68453125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_dragIcon__opLsv {
    height: 33.33px;
    width: 33.33px;
  }
}

.content-layout-details_contentGroupContainer__23-i4 {
  padding-left: 3%;
}
@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(-1px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(28px, env(safe-area-inset-top));
      padding-left: 4%;

    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(23px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentGroupContainer__23-i4 {
      padding-top: 40px;
    }
  }
}

.content-layout-details_contentGroup__1hDL7 {
  border-collapse: collapse;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentGroup__1hDL7 {
    margin-bottom: 44px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentGroup__1hDL7 {
    margin-bottom: 65.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroup__1hDL7 {
    margin-bottom: 78.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroup__1hDL7 {
    margin-bottom: 93.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroup__1hDL7 {
    margin-bottom: 105px;
  }
}

.content-layout-details_contentGroupHead__3ASX5,
.content-layout-details_contentGroupBody__310pV {
  display: block;
}

.content-layout-details_contentGroupHeadRow__Ts38v,
.content-layout-details_contentGroupBodyRow__3GC-a {
  display: flex;
  padding: 0 var(--site-padding);
  position: relative;
}

.content-layout-details_contentGroupBodyRow__3GC-a {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.content-layout-details_contentGroupBodyRow__3GC-a.content-layout-details_dragged__1ZqkP {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px 0px;
}

.content-layout-details_contentGroupBodyRow__3GC-a:not(.content-layout-details_dragged__1ZqkP)::before {
  border-top: 1px solid rgba(112, 112, 112, 0.18);
  content: ' ';
  display: block;
  left: var(--site-padding);
  position: absolute;
  top: 0;
  width: calc(100% - (2 * var(--site-padding)));
}

.content-layout-details_contentGroupHeadCell__L7Jz1 {
  border-bottom: 2px solid rgba(112, 112, 112, 0.18);
}

@media only screen and (max-width: 767px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1) {
    font-size: 23px !important;
    line-height: 26.875px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1) {
    font-size: 26.25px !important;
    line-height: 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1) {
    font-size: 31.171875000000004px !important;
    line-height: 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1) {
    font-size: 35px !important;
    line-height: 43px !important;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2) {
    height: 38.12px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2) {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2) {
    height: 48px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2) {
    height: 57px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2) {
    height: 64px;
  }
}

.content-layout-details_contentGroupHeadCell__L7Jz1,
.content-layout-details_contentGroupBodyCell__1Lxz4 {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
    width: calc(100% - 98.125px);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
    width: calc(100% - 117.75px);
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
    width: calc(100% - 139.828125px);
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(1),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
    width: calc(100% - 157px);
  }
}

.content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
.content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
    width: 98.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
    width: 117.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
    width: 139.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupHeadCell__L7Jz1:nth-child(2),
  .content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(2) {
    width: 157px;
  }
}

.content-layout-details_contentGroupBodyCell__1Lxz4:nth-child(1) {
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentGroupBodyCell__1Lxz4 {
    height: 130px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentGroupBodyCell__1Lxz4 {
    height: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupBodyCell__1Lxz4 {
    height: 143.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupBodyCell__1Lxz4 {
    height: 170.109375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupBodyCell__1Lxz4 {
    height: 191px;
  }
}

.content-layout-details_contentGroupText__aXNf4 {
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .content-layout-details_contentGroupText__aXNf4 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentGroupText__aXNf4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentGroupText__aXNf4 {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentGroupText__aXNf4 {
    font-size: 24px;
  }
}

.content-layout-details_imageContainer__v672J {
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .content-layout-details_imageContainer__v672J {
    height: 71px;
    width: 132px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_imageContainer__v672J {
    height: 80.25px;
    width: 150px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_imageContainer__v672J {
    height: 95.296875px;
    width: 178.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_imageContainer__v672J {
    height: 107px;
    width: 200px;
  }
}

.content-layout-details_image__-mtNb {
  background-color: #eee;
  border: 1px solid #707070;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) -7px 0 10px -10px;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.content-layout-details_image__-mtNb:nth-child(2) {
  margin-left: 20%;
}

.content-layout-details_image__-mtNb:nth-child(3) {
  margin-left: 40%;
}

.content-layout-details_image__-mtNb:nth-child(4),
.content-layout-details_image__-mtNb:nth-child(5) {
  margin-left: 60%;
}

.content-layout-details_image__-mtNb:nth-child(5) {
  background-color: transparent !important;
  border: none !important;
}

.content-layout-details_totalImages__3mfuP {
  align-items: center;
  background-color: #f5f4f4;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  left: 100%;
  padding: 3px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1199px) {
  .content-layout-details_totalImages__3mfuP {
    font-size: 13px;
    height: 29px;
    min-width: 29px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_totalImages__3mfuP {
    font-size: 15px;
    height: 32.25px;
    min-width: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_totalImages__3mfuP {
    font-size: 17.8125px;
    height: 38.296875px;
    min-width: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_totalImages__3mfuP {
    font-size: 20px;
    height: 43px;
    min-width: 43px;
  }
}

.content-layout-details_contentLayoutHeader__2qco_ {
  align-items: center;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_contentLayoutHeader__2qco_ {
    height: 40px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_contentLayoutHeader__2qco_ {
    height: 82.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_contentLayoutHeader__2qco_ {
    height: 99.00000000000001px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_contentLayoutHeader__2qco_ {
    height: 117.56250000000001px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_contentLayoutHeader__2qco_ {
    height: 132px;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .content-layout-details_contentLayoutHeader__2qco_ {
      padding-right: 40px;
    }
  }
}

.content-layout-details_createButtonsContainer__gQIh0 {
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createButtonsContainer__gQIh0 {
    justify-content: flex-end;
    width: 100%;
  }
}

.content-layout-details_createButtonWrapper__16kSI {
  position: relative;
}

.content-layout-details_createButtonWrapper__16kSI,
.content-layout-details_createButtonWrapper__16kSI * {
  font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuContainer__1kF7o {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 16px !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-details_createMenuContainer__1kF7o {
    position: relative;
  }
}

.content-layout-details_createMenu__3Sca3 {
  background-color: #ffffff;
  border: 0.5px solid #707070;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 10;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenu__3Sca3 {
    max-width: 420px;
    padding: 18px 24px !important;
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-details_createMenu__3Sca3 {
    position: absolute;
    right: 0;
    top: calc(100% - 4px);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenu__3Sca3 {
    padding: 0 27.5px 9.16667px 27.5px;
    width: 369.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenu__3Sca3 {
    padding: 0 33px 11px 33px;
    width: 443.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenu__3Sca3 {
    padding: 0 39.1875px 13.0625px 39.1875px;
    width: 526.359375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenu__3Sca3 {
    padding: 0 44px 14.66667px 44px;
    width: 591px;
  }
}

.content-layout-details_createMenuTitle__3NNMs {
  font-weight: 500;
  line-height: 2;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuTitle__3NNMs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenuTitle__3NNMs {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenuTitle__3NNMs {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenuTitle__3NNMs {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenuTitle__3NNMs {
    font-size: 20px;
  }
}

.content-layout-details_categoryDropdownContainer__PQJA_ {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-layout-details_createMenuInput__2atzw {
  border: 1px solid #5aa5ef;
  display: block;
  font-size: 1em;
  margin-bottom: 1.5em;
  margin-top: 0.3em;
  outline: none;
  padding: 0 0.4em;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuInput__2atzw {
    height: 45px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenuInput__2atzw {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenuInput__2atzw {
    height: 33.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenuInput__2atzw {
    height: 40.078125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenuInput__2atzw {
    height: 45px;
  }
}

.content-layout-details_createMenuDropdown__1r0Ky {
  display: inline-block;
  position: relative;
}

.content-layout-details_createMenuDropdownScrim__361Vg,
.content-layout-details_createButtonScrim__2FuCn {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.content-layout-details_createMenuDropdownList__3VQ0M {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  line-height: 2.2;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  white-space: nowrap;
  z-index: 120;
}

.content-layout-details_createMenuDropdownList__3VQ0M:not(.content-layout-details_alignRight__1MvEZ) {
  left: 0;
}

.content-layout-details_createMenuDropdownList__3VQ0M.content-layout-details_alignRight__1MvEZ {
  right: 0;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuDropdownList__3VQ0M {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenuDropdownList__3VQ0M {
    border-radius: 6.25px;
    font-size: 16.03125px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenuDropdownList__3VQ0M {
    border-radius: 7.5px;
    font-size: 16.03125px;
    padding: 13.5px 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenuDropdownList__3VQ0M {
    border-radius: 8.90625px;
    font-size: 16.03125px;
    padding: 16px 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenuDropdownList__3VQ0M {
    border-radius: 10px;
    font-size: 18px;
    padding: 18px 36px;
  }
}

.content-layout-details_createMenuDropdownInput__oTnqs {
  align-items: center;
  display: inline-flex;
}

@media only screen and (max-width: 767px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenuDropdownInput__oTnqs {
    font-size: 16px;
  }
}

.content-layout-details_createMenuDropdownInput__oTnqs,
.content-layout-details_createMenuDropdownItem__3cAmd {
  cursor: pointer;
  position: relative;
}

.content-layout-details_createMenuButtons__3ZLRa {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuButtons__3ZLRa {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
}

.content-layout-details_createMenuButtonsRight__Oa0BP {
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuButtonsRight__Oa0BP {
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-details_createMenuButtonsRight__Oa0BP {
    margin-left: auto;
  }
}

.content-layout-details_createMenuCancelButton__377Lr {
  background-color: transparent;
  border: 1px solid #707070;
}

.content-layout-details_createMenuCreateButton__RX3a4 {
  background-color: #5aa5ef;
  border: none;
  color: #fff;
}

.content-layout-details_createMenuCancelButton__377Lr,
.content-layout-details_createMenuCreateButton__RX3a4 {
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

@media only screen and (min-width: 900px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    margin-left: 0.8em;
  }
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    flex-grow: 1;
    font-size: 13px;
    height: 37px;
    margin: 2em 0.8em 0 0.8em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    font-size: 8.125px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    font-size: 9.75px;
    height: 33px;
    width: 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    font-size: 11.578125px;
    height: 39.1875px;
    width: 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createMenuCancelButton__377Lr,
  .content-layout-details_createMenuCreateButton__RX3a4 {
    font-size: 13px;
    height: 44px;
    width: 86px;
  }
}

.content-layout-details_createButton__1-M3J {
  align-items: center;
  background-color: #fff;
  font-family: 'Poppins';
  border: 1px solid transparent;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  color: #000 !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

@media only screen and (max-width: 899px) {
  .content-layout-details_createButton__1-M3J:not(.content-layout-details_compact__h_wQf) {
    display: none;
  }
  .content-layout-details_createButton__1-M3J.content-layout-details_compact__h_wQf {
    background-color: transparent;
    border-radius: 4px;
    color: #000000 !important;
    font-size: 13px;
    font-weight: 500;
    height: 32px;
    margin-left: 8.2px;
  }
}

@media only screen and (min-width: 900px) {
  .content-layout-details_createButton__1-M3J.content-layout-details_compact__h_wQf {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_createButton__1-M3J {
    border-radius: 6.25px;
    font-size: 13px;
    height: 50px;
    margin-left: 25.625px;
    padding: 0 25.625px;
    width: 223.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_createButton__1-M3J {
    border-radius: 7.5px;
    font-size: 15px;
    height: 60px;
    margin-left: 30.75px;
    padding: 0 30.75px;
    width: 267.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_createButton__1-M3J {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 71.25px;
    margin-left: 36.515625px;
    padding: 0 36.515625px;
    width: 317.953125px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_createButton__1-M3J {
    border-radius: 10px;
    font-size: 20px;
    height: 80px;
    margin-left: 41px;
    padding: 0 41px;
    width: 357px;
  }
}

@media only screen and (max-width: 767px) {
  .content-layout-details_addIcon__2gO8c {
    height: 12.8px;
    margin-right: -6.4px;
    width: 12.8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .content-layout-details_addIcon__2gO8c {
    height: 15px;
    margin-right: -7.5px;
    width: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_addIcon__2gO8c {
    height: 20px;
    margin-right: -10px;
    width: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_addIcon__2gO8c {
    height: 24px;
    margin-right: -12px;
    width: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_addIcon__2gO8c {
    height: 28.5px;
    margin-right: -14.25px;
    width: 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_addIcon__2gO8c {
    height: 32px;
    margin-right: -16px;
    width: 32px;
  }
}

.content-layout-details_backButton__2-eah {
  margin: 14px 20px;
  font-size: 23px;
  color: #5983C3;
  font-weight: 600;
  cursor: pointer;
}

.content-layout-details_editButton__AzCaD {
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.content-layout-details_editButton__AzCaD:hover {
  background-color: #707070;
} 

a.content-layout-details_editMenuButton__3k-SL,
button.content-layout-details_editMenuButton__3k-SL {
  align-items: center;
  font-size: 16px;
  font-family: 'Poppins';
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 0;
  color: #000000;
  background-color: #ffffff;
  padding: 1rem 1.5rem;
}
a.content-layout-details_editbutton__1SoYN {
  border-top: solid;
}
@media only screen and (max-width: 899px) {
  .content-layout-details_editButton__AzCaD {
    display: none;
  }
  .content-layout-details_backButton__2-eah {
    font-size: 16px;
    margin: 12px 13px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_editButton__AzCaD {
    border-radius: 6.25px;
    font-size: 12.5px;
    height: 45.62499999999999px;
    padding: 0 15.625px;
    width: 98.125px;
  }
  .content-layout-details_backButton__2-eah {
    margin: 12px 13px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_editButton__AzCaD {
    border-radius: 7.5px;
    font-size: 15px;
    height: 54.74999999999999px;
    padding: 0 18.75px;
    width: 117.75px;
  }
  .content-layout-details_backButton__2-eah {
    margin: 12px 13px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_editButton__AzCaD {
    border-radius: 8.90625px;
    font-size: 17.8125px;
    height: 65.015625px;
    padding: 0 22.265625px;
    width: 139.828125px;
  }
  .content-layout-details_backButton__2-eah {
    margin: 12px 13px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_editButton__AzCaD {
    border-radius: 10px;
    font-size: 20px;
    height: 73px;
    padding: 0 25px;
    width: 157px;
  }
  .content-layout-details_backButton__2-eah {
    margin: 12px 13px !important;
  }
}

.content-layout-details_editButtonDropdown__3n91q {
  background-color: #ffffff33;
  border-radius: 50%;
  flex-shrink: 0;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .content-layout-details_editButtonDropdown__3n91q {
    font-size: 16.25px;
    height: 19.375000000000004px;
    width: 19.375000000000004px;
  }
  .content-layout-details_backButton__2-eah {
    font-size: 18px;
    margin: auto 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .content-layout-details_editButtonDropdown__3n91q {
    font-size: 19.5px;
    height: 23.250000000000004px;
    width: 23.250000000000004px;
  }
  .content-layout-details_backButton__2-eah {
    font-size: 18px;
    margin: auto 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content-layout-details_editButtonDropdown__3n91q {
    font-size: 23.15625px;
    height: 27.609375000000004px;
    width: 27.609375000000004px;
  }
  .content-layout-details_backButton__2-eah {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1710px) {
  .content-layout-details_editButtonDropdown__3n91q {
    font-size: 26px;
    height: 31px;
    width: 31px;
  }
}

.content-layout-details_headerTheme__3FWar {
  background-color: black;
}

.content-layout-details_dropDownAnimation__31OSE  {
  -webkit-animation: content-layout-details_growOut__21FJ6 700ms ease-in-out forwards;
          animation: content-layout-details_growOut__21FJ6 700ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center
}

@-webkit-keyframes content-layout-details_growOut__21FJ6 {
  0% {
     -webkit-transform: scale(0);
             transform: scale(0)
 }
 80% {
     -webkit-transform: scale(1.1);
             transform: scale(1.1)
 }
 100% {
     -webkit-transform: scale(1);
             transform: scale(1)
 }
}

@keyframes content-layout-details_growOut__21FJ6 {
  0% {
     -webkit-transform: scale(0);
             transform: scale(0)
 }
 80% {
     -webkit-transform: scale(1.1);
             transform: scale(1.1)
 }
 100% {
     -webkit-transform: scale(1);
             transform: scale(1)
 }
}


.content-layout-details_pageLink__2uzPd:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.content-layout-details_pagination__vJ76n {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.content-layout-details_pageLink__2uzPd {
  padding: 0.375rem 0.75rem;
}

.content-layout-details_pageLink__2uzPd {
  font-size: 15px;
  font-weight: 600;
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.content-layout-details_pageItem__1hH2f.content-layout-details_active__3lURa .content-layout-details_pageLink__2uzPd {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.content-layout-details_pageItem__1hH2f:not(:first-child) .content-layout-details_pageLink__2uzPd {
  margin-left: -1px;
}

.content-layout-details_pageItem__1hH2f {
  cursor: pointer !important;
}

/* .bgLayer, */
.album-details_bgDarkLayer__3ml0l {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.album-details_centerCirlce__xjjs9 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.album-details_bgDarkLayer__3ml0l {
  background-color: rgba(0, 0, 0, 0.25);
}
.album-details_gradient_overlay__3mlha {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1)),
  linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1));
/* Gradients for both right-to-left and bottom-to-top */
}
.album-details_containerx__1rhQC {
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
  aspect-ratio: 19 / 10;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1)),
  linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1));
  overflow: hidden;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.album-details_backImg__PCocN{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media only screen and (max-width: 899px) {
  .album-details_page__1w72n {
    padding-bottom: 0 !important;
  }
  .album-details_containerx__1rhQC {
    /* width: 60%; */
    width: 100%;
    height: 70%;
  }
}

.album-details_pageContainer__1XUa5 {
  background: #000000;
}

@media only screen and (max-width: 899px) {
  .album-details_main__v80X9 {
    /* -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; */
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    min-height: 100vh !important;
  }
}


.album-details_previewContainer__2nHtl {
  min-width: 100%;
}

.album-details_topContainer__BzYDE,
.album-details_bottomContainer__3wvym {
  width: 100%;
  /* display:flex; */
  justify-content: center;
}

.album-details_topContainer__BzYDE {
  position: relative;
  /* padding-bottom:0.5em; */
  /* height: 75%; */
}

.album-details_bottomContainer__3wvym {
  background-color: #000000;
  align-items: flex-start;
  /* padding-top:0.5em; */
  /* height: 25%; */
  padding-left: 100px;
  padding-right: 100px;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_bottomContainer__3wvym {
    background-color: #000000;
    align-items: flex-start;
    /* padding-top:0.5em; */
    /* height: 25%; */
    /* padding-left: 100px; */
    /* margin-top: -74px; */
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_bottomContainer__3wvym {
    background-color: #000000;
    align-items: flex-start;
    /* padding-top:0.5em; */
    /* height: 25%; */
    /* padding-left: 25px; */
  }

}

.album-details_bgLayer__kpsS8 {
  /* background-attachment: fixed; */
  background-color: #000000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 240px 0 inset;
  /* border-radius: 0vw 3vw 3vw 0vw; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.album-details_backButtonBlock__1Xmh3 {
  /* position: relative; */
  margin-left: 90px;
  /* position: absolute; */
  /* top: 50px; */
  /* left: 2%;
  top: 5%; */
  cursor: pointer;
}

.album-details_videoBackButton__nrmNU {
  cursor: pointer;
  margin-top: 50px;
  position: relative;
  vertical-align: middle;
  width: 50px;
}

.album-details_backText__whW6- {
  vertical-align: middle;
  font-size: 28px;
  padding: 15px;
}

.album-details_videoDetailsContainer__3WF3d {
  color: #fff;
  /* width: 60%; */
  top: 25%;
  position: absolute;
  left: 10%;
  /* margin-left: 116px; */
  /* margin-top: 100px; */
}

@media only screen and (max-width: 899px) {
  .album-details_videoDetailsContainer__3WF3d {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh !important; */
  }

  .album-details_backButtonBlock__1Xmh3 {
    margin-left: 50px;
  }

  .album-details_bottomContainer__3wvym {
    padding-left: 50px;
  }

  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .album-details_bottomContainer__3wvym .album-details_imageGroup__2cVwI {
      /* padding-left: 50px;
      margin-top: -10px; */
      padding-top: max(30px, env(safe-area-inset-top));

    }
  }

  /* @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .bottomContainer .imageGroup {
        padding-left: 50px;
        margin-top: -10px;
        padding-top: max(30px, env(safe-area-inset-top));
        transform: translate(500px);
      }
      } */
  /* @media only screen and (min-width: 453px) {
    .bottomContainer {
      padding-left: 50px;
      margin-top: -20px;
    }
    } */
  @media only screen and (max-width: 899px) {
    .album-details_bottomContainer__3wvym {
      padding-left: 50px;
      display: none;
    }
    .album-details_topContainer__BzYDE {
      height: 100vh;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
  }

  /* @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .bottomContainer {
        padding-left: 50px;
        margin-bottom: 20px;
      }
      } */
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    .album-details_bottomContainer__3wvym {
      padding-left: 50px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .album-details_bottomContainer__3wvym {
      padding-left: 50px;
    }
  }

  .album-details_videoDetails__pLKQb {
    padding-left: 50px !important;
  }

  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }

    @media only screen and (min-width: 1710px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: max(0, env(safe-area-inset-bottom));
      }
    }
  }

  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 12px;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 16px;
      }
    }

    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 20px;
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 24px;
      }
    }

    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 0;
      }
    }

    @media only screen and (min-width: 1710px) {
      .album-details_videoDetailsContainer__3WF3d {
        padding-bottom: 0;
      }
    }
  }

  .album-details_videoActions__2-Asx {
    display: block !important;
  }

  .album-details_alignButton__3J5s3 {
    margin-left: 0px;
  }

  .album-details_videoPlayButton__8vKQf {
    margin-bottom: 10px;
    display: flex !important;
    width: 230px;
  }
}

.album-details_videoTitle__2h4O- {
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fff;
  margin-bottom: 3%;
}

@media only screen and (max-width: 899px) {
  .album-details_videoTitle__2h4O- {
    font-size: 22.400000000000002px;
  }
}

@media only screen and (max-width: 767px) {
  .album-details_videoTitle__2h4O- {
    font-size: 22.400000000000002px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoTitle__2h4O- {
    font-size: 29.937500000000004px;
    z-index: 5;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoTitle__2h4O- {
    font-size: 30.25px;
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoTitle__2h4O- {
    font-size: 30.50000000000001px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoTitle__2h4O- {
    font-size: 33.78125000000001px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoTitle__2h4O- {
    font-size: 35px;
  }
}

.album-details_videoDetails__pLKQb {
  display: flex;
  /* bottom: -48px; */
  width: 100%;
  color: #fff;
  /* position: absolute; */
  margin-top: 8%;
  padding-left: 100px;
  flex-direction: column;
}

.album-details_videoActions__2-Asx {
  align-items: center;
  display: flex;
}


.album-details_videoPlayButton__8vKQf {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;
  position: relative;
  text-shadow: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 16px;
    height: 50px;
    width: 150px;
    padding: 0 30px;

  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 16px;
    height: 56px;
    width: 180px;
    /* margin-top: 20px; */
    padding: 0 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 16.25px;
    height: 56px;
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 19.5px;
    height: 55.5px;
    padding: 0 34.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 23.15625px;
    height: 56.59375px;
    padding: 0 40.96875px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoPlayButton__8vKQf {
    font-size: 26px;
    height: 68px;
    padding: 0 46px;
  }
}

@media only screen and (max-width: 767px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 24px;
    margin-right: 15px;
    width: 24px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 27px;
    margin-right: 17.25px;
    width: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 32.0625px;
    margin-right: 20.48438px;
    width: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoPlayIcon__24fc4 {
    height: 36px;
    margin-right: 23px;
    width: 36px;
  }
}

@media only screen and (min-width: 900px) {
  .album-details_header__2Clhi {
    display: none;
  }

  .album-details_alignButton__3J5s3 {
    margin-left: 25px;
  }
}

.album-details_videoAddButton__AXtnS {
  position: relative;
}

.album-details_videoAddButton__AXtnS {
  align-items: center;
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  border-radius: 50%;
  border-style: solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 2px;
    font-size: 22.4px;
    height: 56px;
    margin-left: 11.2px;
    width: 56px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 2px;
    font-size: 26.25px;
    height: 56px;
    margin-left: 13.125px;
    width: 56px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 3px;
    font-size: 35px;
    height: 56px;
    margin-left: 17.5px;
    width: 56px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 3px;
    font-size: 42px;
    height: 55px;
    margin-left: 21px;
    width: 55px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 4px;
    font-size: 49.875px;
    height: 56.125px;
    margin-left: 24.9375px;
    width: 56.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoAddButton__AXtnS {
    border-width: 4px;
    font-size: 56px;
    height: 68px;
    margin-left: 28px;
    width: 68px;
  }
}

.album-details_videoInfo__1u3It {
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .album-details_videoInfo__1u3It {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoInfo__1u3It {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoInfo__1u3It {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoInfo__1u3It {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoInfo__1u3It {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoInfo__1u3It {
    font-size: 20px;
  }
}


.album-details_videoInfoSeparator__3Eldv {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 15px;
    margin: 0 5.625px;
    width: 2px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 15px;
    margin: 0 7.5px;
    width: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 15px;
    margin: 0 9px;
    width: 2px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 17.8125px;
    margin: 0 10.6875px;
    width: 3px;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoInfoSeparator__3Eldv {
    height: 20px;
    margin: 0 12px;
    width: 3px;
  }
}

.album-details_videoDescription__3cHFO {
  font-weight: 600;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .album-details_videoDescription__3cHFO {
    font-size: 15px;
    /* margin-top: 10px; */
    /* margin-bottom: 48px; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoDescription__3cHFO {
    font-size: 20px;
    margin-right: 20%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_videoDescription__3cHFO {
    font-size: 20px;
    margin-right: 0%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_videoDescription__3cHFO {
    font-size: 18px;
    /* margin-right: 45%; */
    margin-right: 50%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {

  .album-details_videoDescription__3cHFO {
    font-size: 21.375px;
    margin-right: 45%;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoDescription__3cHFO {
    font-size: 24px;
    margin-right: 45%;
  }
}

.album-details_videoInfoWrap__3M9kx {
  display: block;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_videoInfoWrap__3M9kx {
    display: block;
    font-size: 50px;
  }

}



.album-details_loadingText__OVPji {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
}

.album-details_thumbnailDiv__3rNP5 {
  position: fixed;
  width: 40%;
  margin-left: 900px;
  margin-top: -80px;
  height: 40%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
}

@media only screen and (max-width: 767px) {
  .album-details_thumbnailDiv__3rNP5 {
    position: fixed;
    width: 59%;
    margin-left: 290px;
    margin-top: -90px;
    height: 40%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .album-details_thumbnailDiv__3rNP5 {
    position: absolute;
    /* top: 0; 
    right: 2; */
    width: 23%;
    margin-left: 380px;
    margin-top: -90px;
    height: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .album-details_thumbnailDiv__3rNP5 {
    position: fixed;
    width: 56%;
    margin-left: 500px;
    margin-top: -90px;
    height: 40%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .album-details_thumbnailDiv__3rNP5 {
    position: fixed;
    /* top: 0; 
    right: 2; */
    width: 56%;
    margin-left: 700px;
    margin-top: -90px;
    height: 40%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: rgb(0 0 0) 22px -7px 12px -8px inset;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {

  .album-details_videoDescription__3cHFO {
    font-size: 21.375px;
    margin-right: 45%;
  }
}

@media only screen and (min-width: 1710px) {
  .album-details_videoDescription__3cHFO {
    font-size: 24px;
    margin-right: 45%;
  }
}
.page_settingsPage__3dekm {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  padding-left: 93px;
}

.page_pageContent__23GV3 {
  background-color: #fff;
  padding: 0;
}

.page_pageContentRes__wortr {
  background-color:rgba(240, 240, 240, 0.6);
  padding: 0;
}

.page_contentWrapper__3MkFP {
  display: block;
  flex-grow: 1;
}

@media only screen and (max-width: 767px) {
  .page_contentWrapper__3MkFP {
    padding: 22px 16px;
  }
  .page_settingsPage__3dekm {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .page_contentWrapper__3MkFP {
    padding: 27.5px 36.5625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_contentWrapper__3MkFP {
    padding: 30px 48.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_contentWrapper__3MkFP {
    padding: 42.6px 58.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_contentWrapper__3MkFP {
    padding: 57.15px 69.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_contentWrapper__3MkFP {
    padding: 65.8px 78px;
  }
}

.page_pageTitle__1cAQ4 {
  color: #02071a;
  font-weight: 600;
  line-height: 1;
}

.page_restrictedPageTitle__3G29P {
  color: #707070 !important;
}

@media only screen and (max-width: 899px) {
  .page_pageTitle__1cAQ4 {
    font-size: 22px;
  }
  .page_addNewTitle__3QLj9 {
    font-size: 15px;
  }

}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageTitle__1cAQ4 {
    font-size: 21.875px;
  }
  .page_addNewTitle__3QLj9 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageTitle__1cAQ4 {
    font-size: 26.25px;
  }
  .page_addNewTitle__3QLj9 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageTitle__1cAQ4 {
    font-size: 31.171875000000004px;
  }
  .page_addNewTitle__3QLj9 {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageTitle__1cAQ4 {
    font-size: 35px;
  }
  .page_addNewTitle__3QLj9 {
    font-size: 25px;
  }
}

.page_pageDescription__2gTdP {
  color: #02071a;
  font-weight: 300;
  opacity: 0.32;
}

@media only screen and (max-width: 899px) {
  .page_pageDescription__2gTdP {
    font-size: 17px;
    line-height: 1.3;
    margin: 8px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageDescription__2gTdP {
    font-size: 27.499999999999996px;
    margin: 23.125px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageDescription__2gTdP {
    font-size: 33px;
    margin: 27.75px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageDescription__2gTdP {
    font-size: 39.1875px;
    margin: 32.953125px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageDescription__2gTdP {
    font-size: 44px;
    margin: 37px 0;
  }
}

.page_pageDivider__RAEun {
  background-color: #707070;
  border: none;
  height: 1px;
  opacity: 0.18;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .page_pageDivider__RAEun {
    margin-top: 22.2px;
    margin-bottom: 29.6px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_pageDivider__RAEun {
    margin-bottom: 34.6875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_pageDivider__RAEun {
    margin-bottom: 41.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_pageDivider__RAEun {
    margin-bottom: 49.42969px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_pageDivider__RAEun {
    margin-bottom: 55.5px;
  }
}

.page_sidebar__2BTt- {
  background-color: #f0f0f0;
  display: block;
  min-height: calc(100vh - var(--header-height));
}

@media only screen and (max-width: 899px) {
  .page_sidebar__2BTt- {
    display: none;
  }
  .page_subTitle__2-XcJ {
    font-size: 8px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_sidebar__2BTt- {
    width: 192.50000000000003px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_sidebar__2BTt- {
    width: 231.00000000000003px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_sidebar__2BTt- {
    width: 274.3125px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_sidebar__2BTt- {
    width: 308px;
  }
}

.page_sidebarContent__2hfBO {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--header-height));
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: var(--header-height);
}

.page_sidebarLink__XbK76 {
  align-items: center;
  color: #02071a;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-family: 'Montserrat';
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_sidebarLink__XbK76 {
    font-size: 12.5px;
    height: 68.75px;
    margin-top: 26.875px;
    padding: 0 53.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_sidebarLink__XbK76 {
    font-size: 15px;
    height: 82.5px;
    margin-top: 32.25px;
    padding: 0 64.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_sidebarLink__XbK76 {
    font-size: 17.8125px;
    height: 97.96875px;
    margin-top: 38.29688px;
    padding: 0 76.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_sidebarLink__XbK76 {
    font-size: 20px;
    height: 110px;
    margin-top: 43px;
    padding: 0 86px;
  }
}

.page_currentSidebarLink__1i0TH {
  cursor: pointer;
}

.page_currentSidebarLink__1i0TH::before, .page_currentSidebarLink__1i0TH::after {
  content: ' ';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
}

.page_currentSidebarLink__1i0TH::before {
  background-color: #5aa5ef;
  left: 0;
  opacity: 0.08;
  width: 100%;
}

.page_currentSidebarLink__1i0TH::after {
  background-color: #5aa5ef;
  right: 0;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .page_currentSidebarLink__1i0TH::after {
    width: 4.375px;
  }
  .page_subTitle__2-XcJ {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .page_currentSidebarLink__1i0TH::after {
    width: 5.25px;
  }
  .page_subTitle__2-XcJ {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .page_currentSidebarLink__1i0TH::after {
    width: 6.234375px;
  }
  .page_subTitle__2-XcJ {
    font-size: 19px;
  }
}

@media only screen and (min-width: 1710px) {
  .page_currentSidebarLink__1i0TH::after {
    width: 7px;
  }
  .page_subTitle__2-XcJ {
    font-size: 22px;
  }
}
.page_headerTheme__gVuzu {
  background-color: black;
}

.page_headingWrapper__Qrv9k {
  display: flex;
  justify-content: space-between;
}
.page_content__314u- {
  height: 100%;
  position: relative;
}
.page_addNewTitle__3QLj9 {
  vertical-align: middle;
  font-weight: 500;
  color: #5AA5EF;
  cursor: pointer;
  font-family: 'Poppins';
}

.page_disabledbutton__2MOld {
  pointer-events: none !important;
  opacity: 0.4 !important;
}

.page_addIconStyle__1E-1g {
  vertical-align: middle;
  color: #5AA5EF;
  cursor: pointer;
}

.page_subTitle__2-XcJ {
  color: #989898;
}

.page_mouseOverClass__3kL33 {
  color: #707070;
}

@media only screen and (max-width: 899px) {
  .data-view_dataView__14S2i,
  .data-view_dataRow__2lofL,
  .data-view_dataCell__1lrF- {
    display: block;
  }
  .data-view_dataRow__2lofL {
    margin-bottom: 24px;
  }
  .data-view_dataRow__2lofL.data-view_isFlex__3I4fi {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
  }
  .data-view_labelCell__3LS6s {
    margin-bottom: 6px;
  }
}

@media only screen and (min-width: 900px) {
  .data-view_dataView__14S2i {
    border-collapse: collapse;
    display: table;
    width: 100%;
  }
  .data-view_dataRow__2lofL {
    display: table-row;
  }
  .data-view_dataCell__1lrF- {
    display: table-cell;
    vertical-align: top;
  }
  .data-view_labelCell__3LS6s {
    border-right: 1px solid rgba(112, 112, 112, 0.12);
    width: 40%;
  }
}

.data-view_labelTitle__1cNkP {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.data-view_labelTitleText__2-YFD {
  font-weight: 600;
  line-height: 1.25;
  display: flex;
  align-items: center;
}

.data-view_labelDescription__1mXnf {
  font-family: Montserrat, sans-serif;
  color: #02071a;
  line-height: 1.75;
}

.data-view_labelTitleIcon__1TQyx {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .data-view_dataCell__1lrF-.data-view_isToggle__2Wacx {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 15px;
  }
  .data-view_labelTitleText__2-YFD {
    font-size: 16px;
  }
  .data-view_labelDescription__1mXnf {
    font-size: 13px;
  }
  .data-view_labelTitleIcon__1TQyx {
    width: 48.1125px;
  }
  .data-view_labelTitleAddIcon__3MAzH {
    height: 24.056250000000002px;
    width: 24.056250000000002px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .data-view_dataCell__1lrF- {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .data-view_dataCell__1lrF-.data-view_isToggle__2Wacx {
    --toggle-padding-top: (30px / 2);
    --toggle-height: 18.125px;
  }
  .data-view_labelDescription__1mXnf {
    font-size: 11.875px;
  }
  .data-view_labelTitleText__2-YFD {
    font-size: 18.125px;
  }
  .data-view_labelTitleIcon__1TQyx {
    width: 64.15px;
  }
  .data-view_labelTitleAddIcon__3MAzH {
    height: 32.075px;
    width: 32.075px;
  }
  .data-view_dataCell__1lrF-:nth-of-type(2) {
    padding-left: 32.075px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .data-view_dataCell__1lrF- {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .data-view_dataCell__1lrF-.data-view_isToggle__2Wacx {
    --toggle-padding-top: (36px / 2);
    --toggle-height: 21.75px;
  }
  .data-view_labelDescription__1mXnf {
    font-size: 14.25px;
  }
  .data-view_labelTitleText__2-YFD {
    font-size: 21.75px;
  }
  .data-view_labelTitleIcon__1TQyx {
    width: 76.98px;
  }
  .data-view_labelTitleAddIcon__3MAzH {
    height: 38.49px;
    width: 38.49px;
  }
  .data-view_dataCell__1lrF-:nth-of-type(2) {
    padding-left: 38.49px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .data-view_dataCell__1lrF- {
    padding-bottom: 21.375px;
    padding-top: 21.375px;
  }
  .data-view_dataCell__1lrF-.data-view_isToggle__2Wacx {
    --toggle-padding-top: (42.75px / 2);
    --toggle-height: 25.828125px;
  }
  .data-view_labelDescription__1mXnf {
    font-size: 16.921875px;
  }
  .data-view_labelTitleText__2-YFD {
    font-size: 25.828125px;
  }
  .data-view_labelTitleIcon__1TQyx {
    width: 91.41375px;
  }
  .data-view_labelTitleAddIcon__3MAzH {
    height: 45.706875000000004px;
    width: 45.706875000000004px;
  }
  .data-view_dataCell__1lrF-:nth-of-type(2) {
    padding-left: 45.706875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .data-view_dataCell__1lrF- {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .data-view_dataCell__1lrF-.data-view_isToggle__2Wacx {
    --toggle-padding-top: (48px / 2);
    --toggle-height: 29px;
  }
  .data-view_labelDescription__1mXnf {
    font-size: 19px;
  }
  .data-view_labelTitleText__2-YFD {
    font-size: 29px;
  }
  .data-view_labelTitleIcon__1TQyx {
    width: 102.62px;
  }
  .data-view_labelTitleAddIcon__3MAzH {
    height: 51.32px;
    width: 51.31px;
  }
  .data-view_dataCell__1lrF-:nth-of-type(2) {
    padding-left: 51.31px;
  }
}

.data-view_labelTitleAddIcon__3MAzH {
  cursor: pointer;
}

.viewers_hiddenFormField__1E3va {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.viewers_accountDataContainer__3p99X {
  align-items: center;
  display: flex;
  width: 100%;
}

.viewers_accountData__2McoM {
  flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .viewers_accountData__2McoM {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewers_accountData__2McoM {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewers_accountData__2McoM {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewers_accountData__2McoM {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewers_accountData__2McoM {
    font-size: 20px;
  }
}

.viewers_actionButton__1SIge {
  color: #000 !important;
}

.viewers_inputFieldContainer__3voTy {
  background-color: transparent !important;
}

.viewers_inputFieldContainer__3voTy img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.viewers_inputField__3bdGx {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.viewers_inputFieldLabel__1arU7 {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.viewers_passwordStrengthBar__JxCXh {
  background-color: #ddd;
}

.viewers_passwordStrengthNotes__3YxE_ {
  color: #444;
}

.viewers_avatarEditButton__2RdKB {
  color: #5aa5ef;
  font-weight: 600;
}

.viewers_accountDataAction__3R0HB {
  color: rgba(2, 7, 26, 0.4);
}

.viewers_avatarEditButton__2RdKB,
.viewers_accountDataAction__3R0HB {
  cursor: pointer;
  flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .viewers_avatarEditButton__2RdKB:hover,
  .viewers_accountDataAction__3R0HB:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .viewers_avatarEditButton__2RdKB:active, .viewers_avatarEditButton__2RdKB:target,
  .viewers_accountDataAction__3R0HB:active,
  .viewers_accountDataAction__3R0HB:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .viewers_avatarEditButton__2RdKB,
  .viewers_accountDataAction__3R0HB {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewers_avatarEditButton__2RdKB,
  .viewers_accountDataAction__3R0HB {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewers_avatarEditButton__2RdKB,
  .viewers_accountDataAction__3R0HB {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewers_avatarEditButton__2RdKB,
  .viewers_accountDataAction__3R0HB {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewers_avatarEditButton__2RdKB,
  .viewers_accountDataAction__3R0HB {
    font-size: 15px;
    margin-left: 30px;
  }
}

.viewers_profilePicture__F-Laj {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .viewers_profilePicture__F-Laj {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .viewers_profilePicture__F-Laj {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .viewers_profilePicture__F-Laj {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewers_profilePicture__F-Laj {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewers_profilePicture__F-Laj {
    height: 98px;
    width: 98px;
  }
}

.viewers_profilePictureIcon__25e3y {
  height: 50%;
  width: 50%;
}

.viewers_allViewersContainer__1FHph {
  /* float: left;
  width: 50%;  
  padding: 32px 41px; */
}

.viewers_pendingViewersContainer__27iTx {
  /* float: left;
  width: 50%;  
  padding: 32px 24px; */
}

.viewers_pendingTitle__1-2pW {
  font-family: 'Poppins';
  font-weight: 500;
  margin-left: 15px;
}

.viewers_profileIcon__SXHOm {
  content:attr(data-letters);
  display:inline-block;
  font-size:1.3em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:#F0F0F0;
  vertical-align:middle;
  margin-right:1em;
  color:#000;
  border: 1px solid #000;  
}

.viewers_viewerSearchinput__w8lD4 {
  background-color: #F0F0F0;
  border: none;
  color: #8E8E8E;
  font-weight: 500;
  width: 95%;
}
input.viewers_viewerSearchinput__w8lD4[type="text"] {
  padding: 0px 15px;
}

.viewers_userRequest__2eEeW {
  align-items: center;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; */
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 115px;
  padding: 0 10px;
}

.viewers_userRequestText__AwgYM {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.viewers_userRequestName__2rljm {
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
}

.viewers_userRequestEmail__1Gnj6 {
  color: #b2b2b2;
}

.viewers_userRequestName__2rljm,
.viewers_userRequestEmail__1Gnj6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
}

.viewers_userRequestActionsContainer__kLCtP {
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

.viewers_userRequestActionButton__2oXjc {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
  margin-top: -1px;
}

@media all and (hover: hover) {
  .viewers_userRequestActionButton__2oXjc.viewers_delete__13y9h:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .viewers_userRequestActionButton__2oXjc.viewers_delete__13y9h:active,
  .viewers_userRequestActionButton__2oXjc.viewers_delete__13y9h:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .viewers_userRequestActionButton__2oXjc:not(.viewers_delete__13y9h):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .viewers_userRequestActionButton__2oXjc:not(.viewers_delete__13y9h):active,
  .viewers_userRequestActionButton__2oXjc:not(.viewers_delete__13y9h):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 319px) {
  .viewers_imageGroup__1fX55 {
    padding-top: 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .viewers_imageGroup__1fX55 {
    padding-top: 16px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .viewers_imageGroup__1fX55 {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .viewers_profileIcon__SXHOm {
    width: 1.5em !important;
    height: 1.5em !important;
    line-height: 1.5em !important;
    margin-right: .3em !important;
  }
}

@media only screen and (max-width: 767px) {
  .viewers_sectionsContainer__MQxI7 {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 4px;
  }
  .viewers_uploadIcon__1C0Qu {
    height: 41px;
    width: 41px;
  }
  .viewers_videoUploadSection__57oNu {
    height: 184px;
  }
  .viewers_videoAnalyticsSection__3-CB2 {
    height: 216px;
  }
  .viewers_section__qOG89 {
    border-radius: 12.65625px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .viewers_videoUploadSectionContent__1KTap {
    border-radius: 8.90625px;
  }
  .viewers_addUserButton__1qad6 {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .viewers_userRequestName__2rljm {
    font-size: 15px;
  }
  .viewers_userRequestEmail__1Gnj6 {
    font-size: 9px;
  }
  .viewers_sectionTitle__yhuRf {
    margin-bottom: 14px;
  }
  .viewers_userRequest__2eEeW {
    margin-top: 22px;
  }
  .viewers_userRequestActionButton__2oXjc {
    /* font-size: 7px;
    height: 14px;
    width: 39px; */
    font-size: 11px;
    height: 19px;
    width: 60px;
  }
  .viewers_videoAnalyticsList__2vjyg,
  .viewers_storageInfoBar__3aNH- {
    font-size: 14px;
  }
  .viewers_storageInfoText__2GRMD {
    font-size: 13.5px;
  }
  .viewers_storageManagementButton__253jS {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .viewers_storageInfoBar__3aNH- {
    height: 23px;
  }
  /* .pendingViewersContainer {
    padding: 32px 6px !important;
  } */
  /* .allViewersContainer {
    padding: 32px 12px 0px 0px;
  } */
  .viewers_viewerSearchinput__w8lD4 {
    font-size: 12px;
    height: 26px;
    /* width: 213px; */
  }  
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .viewers_sectionsContainer__MQxI7 {
    grid-gap: 14.375000000000002px;
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .viewers_uploadIcon__1C0Qu {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .viewers_section__qOG89 {
    border-radius: 16.875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .viewers_videoUploadSectionContent__1KTap {
    border-radius: 11.875px;
  }
  .viewers_addUserButton__1qad6 {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .viewers_userRequestName__2rljm {
    font-size: 17px;
  }
  .viewers_userRequestEmail__1Gnj6 {
    font-size: 11px;
  }
  .viewers_sectionTitle__yhuRf {
    margin-bottom: 14.25px;
  }
  .viewers_userRequest__2eEeW {
    margin-top: 22px;
  }
  .viewers_userRequestActionButton__2oXjc {
    font-size: 9px;
    height: 19.75px;
    width: 51px;
  }
  .viewers_videoAnalyticsList__2vjyg,
  .viewers_storageInfoBar__3aNH- {
    font-size: 14px;
  }
  .viewers_storageInfoText__2GRMD {
    font-size: 13.5px;
  }
  .viewers_storageManagementButton__253jS {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 126.25px;
  }
  .viewers_storageInfoBar__3aNH- {
    height: 23px;
  }
  .viewers_pendingTitle__1-2pW {
    font-size: 18px;  
  }
  /* .pendingViewersContainer {
    padding: 32px 6px !important;
  }
  .allViewersContainer {
    padding: 32px 18px 0px 0px;
  } */
  .viewers_viewerSearchinput__w8lD4 {
    font-size: 12px;
    height: 26px;
    /* width: 321px; */
  }
  .viewers_pendingInvite__1bJ9r {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1440px) {
  .viewers_profileIcon__SXHOm {
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin-right: .5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .viewers_sectionsContainer__MQxI7 {
    grid-gap: 17.25px;
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .viewers_uploadIcon__1C0Qu {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .viewers_section__qOG89 {
    border-radius: 20.25px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .viewers_videoUploadSectionContent__1KTap {
    border-radius: 14.25px;
  }
  .viewers_addUserButton__1qad6 {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .viewers_userRequestName__2rljm {
    font-size: 19.75px;
  }
  .viewers_userRequestEmail__1Gnj6 {
    font-size: 12.5px;
  }
  .viewers_sectionTitle__yhuRf {
    margin-bottom: 14.25px;
  }
  .viewers_userRequest__2eEeW {
    margin-top: 22px;
  }
  .viewers_userRequestActionButton__2oXjc {
    font-size: 12.25px;
    height: 21.953125px;
    width: 68.0625px;
  }
  .viewers_videoAnalyticsList__2vjyg,
  .viewers_storageInfoBar__3aNH- {
    font-size: 15px;
  }
  .viewers_storageInfoText__2GRMD {
    font-size: 13.5px;
  }
  .viewers_storageManagementButton__253jS {
    font-size: 17.25px;
    height: 34px;
    width: 151.5px;
  }
  .viewers_storageInfoBar__3aNH- {
    height: 23.250000000000004px;
  }
  .viewers_pendingTitle__1-2pW {
    font-size: 21px;  
  }
  /* .pendingViewersContainer {
    padding: 32px 6px !important;
  }
  .allViewersContainer {
    padding: 21px 34px 0px 0px;
  } */
  .viewers_viewerSearchinput__w8lD4 {
    font-size: 11px;
    height: 25px;
    /* width: 267px; */
  }
  .viewers_pendingInvite__1bJ9r {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .viewers_sectionsContainer__MQxI7 {
    grid-gap: 20.484375px;
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .viewers_uploadIcon__1C0Qu {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .viewers_section__qOG89 {
    border-radius: 24.046875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .viewers_videoUploadSectionContent__1KTap {
    border-radius: 16.921875px;
  }
  .viewers_addUserButton__1qad6 {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .viewers_userRequestName__2rljm {
    font-size: 20.265625px;
  }
  .viewers_userRequestEmail__1Gnj6 {
    font-size: 13.46875px;
  }
  .viewers_sectionTitle__yhuRf {
    margin-bottom: 16.921875px;
  }
  .viewers_userRequest__2eEeW {
    margin-top: 25.828125px;
  }
  .viewers_userRequestActionButton__2oXjc {
    font-size: 12.25px;
    height: 21.953125px;
    min-width: 68.0625px;
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .viewers_videoAnalyticsList__2vjyg,
  .viewers_storageInfoBar__3aNH- {
    font-size: 17.8125px;
  }
  .viewers_storageInfoText__2GRMD {
    font-size: 16.03125px;
  }
  .viewers_storageManagementButton__253jS {
    font-size: 20.484375px;
    height: 34px;
    width: 179.90625px;
  }
  .viewers_storageInfoBar__3aNH- {
    height: 27.609375000000004px;
  }
  .viewers_pendingTitle__1-2pW {
    font-size: 27px;  
  }
  /* .allViewersContainer {
    padding: 32px 70px 0px 0px;  
  } */
  .viewers_viewerSearchinput__w8lD4 {
    font-size: 15px;
    height: 33px;
    /* width: 407px; */
  }
  .viewers_pendingInvite__1bJ9r {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1710px) {
  .viewers_sectionsContainer__MQxI7 {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 33px;
  }
  .viewers_uploadIcon__1C0Qu {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .viewers_section__qOG89 {
    border-radius: 27px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
  }
  .viewers_videoUploadSectionContent__1KTap {
    border-radius: 19px;
  }
  .viewers_addUserButton__1qad6 {
    font-size: 36px;
    height: 44px;
    width: 44px;
  }
  .viewers_userRequestName__2rljm {
    font-size: 23px;
  }
  .viewers_userRequestEmail__1Gnj6 {
    font-size: 14px;
  }
  .viewers_sectionTitle__yhuRf {
    margin-bottom: 19px;
  }
  .viewers_userRequest__2eEeW {
    margin-top: 29px;
  }
  .viewers_userRequestActionButton__2oXjc {
    font-size: 15px;
    height: 29px;
    width: 84px;
  }
  .viewers_videoAnalyticsList__2vjyg,
  .viewers_storageInfoBar__3aNH- {
    font-size: 20px;
  }
  .viewers_storageInfoText__2GRMD {
    font-size: 18px;
  }
  .viewers_storageManagementButton__253jS {
    font-size: 23px;
    height: 34px;
    width: 202px;
  }
  
  .viewers_storageInfoBar__3aNH- {
    height: 31px;
  }
  .viewers_pendingTitle__1-2pW {
    font-size: 31px;  
  }
  /* .allViewersContainer {
    padding: 32px 98px 0px 0px;
  } */
  .viewers_viewerSearchinput__w8lD4 {
    font-size: 18px;
    height: 43px;
    /* width: 581px; */
  }
  .viewers_pendingInvite__1bJ9r {
    font-size: 16px;
  }
}

.viewers_deleteActive__2KWVG {
  background-color: #fff !important;
  color:#FF0000 !important;
  border-color: #000 !important;
}

.viewers_pendingInvite__1bJ9r {
  color: #FF0000;
  /* font-size: 16px; */
  width: 153px;
  background: transparent;
  font-family: 'Montserrat';
  float: right;
  border: none;
  position: relative;
  left: -5px;
  cursor: pointer;
  margin-top: -1px;
}

.viewers_viewersDiv__1pq5k {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

@media only screen and (max-width: 500px) {
  .viewers_viewersDiv__1pq5k {
    /* display: -ms-grid; */
    display: block;
  }
  .viewers_pendingViewersContainer__27iTx {
    margin-top: 20px;
  }
  .viewers_pendingTitle__1-2pW {
    font-size: 15px;  
  }
  .viewers_pendingInvite__1bJ9r {
    font-size: 14px;
  }
  .viewers_userRequest__2eEeW {
    grid-template-columns: 1fr 150px;
  }
  .viewers_userRequestActionButton__2oXjc {
    font-size: 15px;
    height: 25px;
    width: 80px;
  }
}

.viewers_viewerList__3juiT {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  overflow-x: hidden;
}

.viewers_viewerList__3juiT::-webkit-scrollbar {
  width: 5px;
}

.viewers_viewerList__3juiT::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.viewers_viewerList__3juiT::-webkit-scrollbar-thumb {
  background: #6E6F6E; 
  border-radius: 25px;
}

.viewers_viewerList__3juiT::-webkit-scrollbar-thumb:hover {
  background: #6E6F6E; 
}

.components_userRequest__2ZIQa {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .components_groupMemberStyle__3AfO1{
    display: flex;
  }

  .components_profileIcon__3x2A- {
    content:attr(data-letters);
    display:inline-block;
    font-size:1.3em;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:#F0F0F0;
    vertical-align:middle;
    margin-right:1em;
    color:#000;
    border: 1px solid #000;  
  }

  @media only screen and (max-width: 767px) {
    .components_profileIcon__3x2A- {
      width: 1.5em !important;
      height: 1.5em !important;
      line-height: 1.5em !important;
      margin-right: .3em !important;
    }
  }

  @media only screen and (max-width: 1440px) {
    .components_profileIcon__3x2A- {
      width: 2em;
      height: 2em;
      line-height: 2em;
      margin-right: .5em;
    }
  }
  
  .components_userRequestText__2zWHB {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
  }
  
  .components_userRequestName__3kAYM {
    font-weight: 600;
    line-height: 1.3;
    text-transform: capitalize;
  }
  
  .components_userRequestEmail__15pen {
    color: #b2b2b2;
  }
  
  .components_userRequestName__3kAYM,
  .components_userRequestEmail__15pen {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .components_userRequestActionsContainer__SWdLx {
    display: flex;
    place-self: center;
  }
  
  .components_userRequestActionButton__3eUBu {
    background-color: transparent;
    border: 2px solid #5aa5ef;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    flex-shrink: 0;
    font-weight: 600;
    line-height: 0;
    /* margin-left: 2%; */
    margin-right: 4rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  @media all and (hover: hover) {
    .components_userRequestActionButton__3eUBu.components_delete__37lHk:hover {
      background-color: #ff4040 !important;
      border-color: #ff4040 !important;
      color: #fff !important;
    }
  }
  
  @media all and (pointer: coarse) {
    .components_userRequestActionButton__3eUBu.components_delete__37lHk:active,
    .components_userRequestActionButton__3eUBu.components_delete__37lHk:target {
      background-color: #ff4040 !important;
      border-color: #ff4040 !important;
      color: #fff !important;
    }
  }
  
  @media all and (hover: hover) {
    .components_userRequestActionButton__3eUBu:not(.components_delete__37lHk):hover {
      background-color: #5aa5ef !important;
      color: #fff !important;
    }
  }
  
  @media all and (pointer: coarse) {
    .components_userRequestActionButton__3eUBu:not(.components_delete__37lHk):active,
    .components_userRequestActionButton__3eUBu:not(.components_delete__37lHk):target {
      background-color: #5aa5ef !important;
      color: #fff !important;
    }
  }
  @media only screen and (max-width: 319px) {
    .components_imageGroup__1ypjD {
      padding-top: 12px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .components_userRequestName__3kAYM {
      font-size: 17px;
      max-width: 130px;
    }
    .components_userRequestEmail__15pen {
      font-size: 13px;
      max-width: 130px;
    }  
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .components_imageGroup__1ypjD {
      padding-top: 16px;
    }
    .components_userRequest__2ZIQa {
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .components_userRequestName__3kAYM {
      font-size: 17px;
      max-width: 130px;
    }
    .components_userRequestEmail__15pen {
      font-size: 13px;
      max-width: 130px;
    }  
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .components_imageGroup__1ypjD {
      padding-top: 20px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 70px !important;
      width: 90% !important;
    }
    .components_userRequestName__3kAYM {
      font-size: 18px;
      max-width: 150px;
    }
    .components_userRequestEmail__15pen {
      font-size: 13px;
      max-width: 150px;
    }  
  }
  
  @media only screen and (max-width: 767px) {
    .components_profileIcon__3x2A- {
      width: 1.5em !important;
      height: 1.5em !important;
      line-height: 1.5em !important;
      margin-right: .3em !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .components_sectionsContainer__3Qxj7 {
      grid-gap: 23px;
      gap: 23px;
      margin-top: 4px;
    }
    .components_uploadIcon__20bgO {
      height: 41px;
      width: 41px;
    }
    .components_videoUploadSection__2IINN {
      height: 184px;
    }
    .components_videoAnalyticsSection__7BBPm {
      height: 216px;
    }
    .components_section__3QXNs {
      border-radius: 12.65625px;
      box-shadow: #00000029 0px 3px 6px;
      margin-top: 20px;
      padding: 15px;
    }
    .components_videoUploadSectionContent__1rKYx {
      border-radius: 8.90625px;
    }
    .components_addUserButton__3X9Xs {
      font-size: 25px;
      height: 30px;
      width: 30px;
    }
    .components_sectionTitle__13TJO {
      margin-bottom: 14px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .components_userRequestActionButton__3eUBu {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .components_videoAnalyticsList__14X4-,
    .components_storageInfoBar__3yHto {
      font-size: 14px;
    }
    .components_storageInfoText__10BdG {
      font-size: 13.5px;
    }
    .components_storageManagementButton__OwXkC {
      font-size: 14.375000000000002px;
      height: 34px;
      width: 94.6875px;
    }
    .components_storageInfoBar__3yHto {
      height: 23px;
    }
    .components_pendingViewersContainer__oQnSi {
      padding: 32px 6px !important;
    }
    .components_allViewersContainer__2429Z {
      padding: 32px 12px;
    }
    .components_viewerSearchinput__o0FtW {
      font-size: 12px;
      height: 26px;
      width: 321px;
    }
    .components_userRequestName__3kAYM {
      font-size: 20px;
      max-width: 200px;
    }
    .components_userRequestEmail__15pen {
      font-size: 14px;
      max-width: 200px;
    }  
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .components_sectionsContainer__3Qxj7 {
      grid-gap: 14.375000000000002px;
      gap: 14.375000000000002px;
      margin-top: 20.625px;
    }
    .components_uploadIcon__20bgO {
      height: 61.5625px;
      margin: 32px;
      width: 61.5625px;
    }
    .components_section__3QXNs {
      border-radius: 16.875px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 18.75px;
    }
    .components_videoUploadSectionContent__1rKYx {
      border-radius: 11.875px;
    }
    .components_addUserButton__3X9Xs {
      font-size: 25px;
      height: 30px;
      width: 30px;
    }
    .components_userRequestName__3kAYM {
      font-size: 20px;
      max-width: 200px;
    }
    .components_userRequestEmail__15pen {
      font-size: 14px;
      max-width: 200px;
    }
    .components_sectionTitle__13TJO {
      margin-bottom: 14.25px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .components_userRequestActionButton__3eUBu {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .components_videoAnalyticsList__14X4-,
    .components_storageInfoBar__3yHto {
      font-size: 14px;
    }
    .components_storageInfoText__10BdG {
      font-size: 13.5px;
    }
    .components_storageManagementButton__OwXkC {
      font-size: 14.375000000000002px;
      height: 34px;
      width: 126.25px;
    }
    .components_storageInfoBar__3yHto {
      height: 23px;
    }
    .components_pendingTitle__Wdqkb {
      font-size: 18px;  
    }
    .components_pendingViewersContainer__oQnSi {
      padding: 32px 6px !important;
    }
    .components_allViewersContainer__2429Z {
      padding: 32px 18px;
    }
    .components_viewerSearchinput__o0FtW {
      font-size: 12px;
      height: 26px;
      width: 321px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .components_profileIcon__3x2A- {
      width: 2em;
      height: 2em;
      line-height: 2em;
      margin-right: .5em;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    .components_sectionsContainer__3Qxj7 {
      grid-gap: 17.25px;
      gap: 17.25px;
      margin-top: 24.750000000000004px;
    }
    .components_uploadIcon__20bgO {
      height: 73.875px;
      margin: 32px;
      width: 73.875px;
    }
    .components_section__3QXNs {
      border-radius: 20.25px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 22.5px;
    }
    .components_videoUploadSectionContent__1rKYx {
      border-radius: 14.25px;
    }
    .components_addUserButton__3X9Xs {
      font-size: 27px;
      height: 33px;
      width: 33px;
    }
    .components_userRequestName__3kAYM {
      font-size: 19.75px;
      max-width: 200px;
    }
    .components_userRequestEmail__15pen {
      font-size: 10.5px;
    }
    .components_sectionTitle__13TJO {
      margin-bottom: 14.25px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 150px;
      width: 80%;
    }
    .components_userRequestActionButton__3eUBu {
      font-size: 19px;
      height: 30.75px;
      width: 101px;
    }
    .components_videoAnalyticsList__14X4-,
    .components_storageInfoBar__3yHto {
      font-size: 15px;
    }
    .components_storageInfoText__10BdG {
      font-size: 13.5px;
    }
    .components_storageManagementButton__OwXkC {
      font-size: 17.25px;
      height: 34px;
      width: 151.5px;
    }
    .components_storageInfoBar__3yHto {
      height: 23.250000000000004px;
    }
    .components_pendingTitle__Wdqkb {
      font-size: 21px;  
    }
    .components_pendingViewersContainer__oQnSi {
      padding: 32px 6px !important;
    }
    .components_allViewersContainer__2429Z {
      padding: 21px 34px 0px 0px;
    }
    .components_viewerSearchinput__o0FtW {
      font-size: 11px;
      height: 25px;
      width: 267px;
    }
  }

  @media only screen and (min-width: 1130px) and (max-width: 1440px) {
    .components_userRequestActionButton__3eUBu {
      font-size: 12.25px;
      height: 21.953125px;
      width: 68.0625px;
      margin-right: 2rem;
    }}
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .components_sectionsContainer__3Qxj7 {
      grid-gap: 20.484375px;
      gap: 20.484375px;
      margin-top: 29.390625000000004px;
    }
    .components_uploadIcon__20bgO {
      height: 87.7265625px;
      margin: 32px;
      width: 87.7265625px;
    }
    .components_section__3QXNs {
      border-radius: 24.046875px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 26.71875px;
    }
    .components_videoUploadSectionContent__1rKYx {
      border-radius: 16.921875px;
    }
    .components_addUserButton__3X9Xs {
      font-size: 32.0625px;
      height: 39.1875px;
      width: 39.1875px;
    }
    .components_userRequestName__3kAYM {
      font-size: 19.265625px;
    }
    .components_userRequestEmail__15pen {
      font-size: 15.46875px;
    }
    .components_sectionTitle__13TJO {
      margin-bottom: 16.921875px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 25.828125px;
    }
    .components_userRequestActionButton__3eUBu {
      font-size: 12.25px;
      height: 21.953125px;
      width: 68.0625px;
    }
    .components_videoAnalyticsList__14X4-,
    .components_storageInfoBar__3yHto {
      font-size: 17.8125px;
    }
    .components_storageInfoText__10BdG {
      font-size: 16.03125px;
    }
    .components_storageManagementButton__OwXkC {
      font-size: 20.484375px;
      height: 34px;
      width: 179.90625px;
    }
    .components_storageInfoBar__3yHto {
      height: 27.609375000000004px;
    }
    .components_pendingTitle__Wdqkb {
      font-size: 27px;  
    }
    .components_allViewersContainer__2429Z {
      padding: 32px 216px 0px 0px;  
    }
    .components_viewerSearchinput__o0FtW {
      font-size: 15px;
      height: 33px;
      width: 407px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .components_sectionsContainer__3Qxj7 {
      grid-gap: 23px;
      gap: 23px;
      margin-top: 33px;
    }
    .components_uploadIcon__20bgO {
      height: 98.5px;
      margin: 32px;
      width: 98.5px;
    }
    .components_section__3QXNs {
      border-radius: 27px;
      box-shadow: #00000029 0px 3px 6px;
      padding: 30px;
    }
    .components_videoUploadSectionContent__1rKYx {
      border-radius: 19px;
    }
    .components_addUserButton__3X9Xs {
      font-size: 36px;
      height: 44px;
      width: 44px;
    }
    .components_userRequestName__3kAYM {
      font-size: 22px;
    }
    .components_userRequestEmail__15pen {
      font-size: 14px;
    }
    .components_sectionTitle__13TJO {
      margin-bottom: 19px;
    }
    .components_userRequest__2ZIQa {
      margin-top: 29px;
    }
    .components_userRequestActionButton__3eUBu {
      font-size: 15px;
      height: 29px;
      width: 84px;
    }
    .components_videoAnalyticsList__14X4-,
    .components_storageInfoBar__3yHto {
      font-size: 20px;
    }
    .components_storageInfoText__10BdG {
      font-size: 18px;
    }
    .components_storageManagementButton__OwXkC {
      font-size: 23px;
      height: 34px;
      width: 202px;
    }
    
    .components_storageInfoBar__3yHto {
      height: 31px;
    }
    .components_pendingTitle__Wdqkb {
      font-size: 31px;  
    }
    .components_allViewersContainer__2429Z {
      padding: 32px 98px 0px 0px;
    }
    .components_viewerSearchinput__o0FtW {
      font-size: 18px;
      height: 43px;
      width: 581px;
    }
  }
  
  .components_deleteActive__2EG9I {
    background-color: #fff !important;
    color:#FF0000 !important;
    border-color: #000 !important;
  }
.groups_allGroupContainer__29Lm8 {
  float: left;
  width: 100%;
  padding: 20px 0px;
}

.groups_groupTitle__2yxSe {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 13px 25px 3px 0px;
  max-width: 396px;
  display: inline-block;
  vertical-align: middle;
}

.groups_memberContainer__A75fO {
  padding: 0px 0px 10px 0px;
  display: grid;
  /* grid-template-columns: 500px 1fr 1fr 150px; */
}

.groups_videoHeading__2DBDd {
  font-weight: 500;
  padding-bottom: 15px;
}

.groups_memberlist__1IIC8 {
  /* width: 40%;
  float: left; */
}
.groups_videoList__8lEyd {
  /* width: 25%;
  float: left; */
  padding: 10px 10px 10px 10px;
}

.groups_albumlist__13CRd {
  /* width: 25%;
  float: left; */
  padding: 10px 10px 10px 10px;
}

.groups_action__2edDP {
  /* width: 10%; */
}

.groups_itemList__3S6mo {
  max-height: 157px;
  overflow-y: auto;
}

.groups_saveButton__3_Sf3 {
  cursor: pointer;
  width: 95%;
}
.groups_loaderButton__2zbMc {
  background: white;
  border: #5aa5ef 2px solid;
  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 8px;
  min-height: 42px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 500;
  padding: 5px 25px;
}
.groups_loaderButton__2zbMc:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.groups_disableButton__2Qab5 {
  cursor: default;
  opacity: 0.4;
  width: 95%;
}

.groups_groupWrapper__2fjUS {
  min-height: 270px;
}

.groups_selectIcon__eaRe0 {
  margin-right: 7px;
  vertical-align: middle;
  cursor: pointer;
}

.groups_itemList__3S6mo::-webkit-scrollbar {
  width: 5px;
}

.groups_itemList__3S6mo::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.groups_itemList__3S6mo::-webkit-scrollbar-thumb {
  background: #6e6f6e;
  border-radius: 25px;
}

.groups_itemList__3S6mo::-webkit-scrollbar-thumb:hover {
  background: #6e6f6e;
}

/* .action{
  width: 10%;
  float: right;
} */

.groups_expandIcon__3IvV9 {
  vertical-align: middle;
  font-size: 45px !important;
  cursor: pointer;
  position: relative;
  top: 5px;
}

.groups_addMemberText__1g8bD {
  vertical-align: middle;
  font-weight: 600;
  color: #5aa5ef;
  cursor: pointer;
  font-family: "Poppins";
}

.groups_videoTitle__wS7iP {
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 11ch; */
  vertical-align: middle;
}

.groups_addIconStyle__3rw1L {
  vertical-align: middle;
  color: #5aa5ef;
  cursor: pointer;
}

.groups_deleteGroup__qN3vF {
  color: #ff0000;
  margin-left: 5%;
  font-weight: 600;
  vertical-align: middle;
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 319px) {
  .groups_groupTitle__2yxSe {
    font-size: 20px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 11px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 15px;
  }
  .groups_videoTitle__wS7iP {
    font-size: 13px;
    width: 200px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 11px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 419px) {
  .groups_groupTitle__2yxSe {
    font-size: 21px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 13px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 15px;
  }
  .groups_videoTitle__wS7iP {
    font-size: 13px;
    width: 200px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 12px;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .groups_groupTitle__2yxSe {
    font-size: 22px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 14px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 22px;
  }
  .groups_videoTitle__wS7iP {
    /* font-size: 14px;
    width: 200px; */
    font-size: 22px;
    width: 310px;
    margin-left: 7px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 13px;
  }
  .groups_action__2edDP {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .groups_groupTitle__2yxSe {
    font-size: 21.875px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 15px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 22px;
  }
  .groups_videoTitle__wS7iP {
    /* font-size: 7px;
    width: 56px; */
    font-size: 22px;
    width: 400px;
    margin-left: 7px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 14px;
  }
  .groups_action__2edDP {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1129px) {
  .groups_groupTitle__2yxSe {
    font-size: 26.25px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 16px;
  }
  .groups_albumlist__13CRd,
  .groups_videoList__8lEyd {
    padding: 10px 10px 10px 23px;
  }
  .groups_videoHeading__2DBDd {
    font-size: 18px;
  }
  .groups_videoTitle__wS7iP {
    /* font-size: 7px;
    width: 61px; */
    font-size: 22px;
    width: 400px;
    margin-left: 7px;
  }

  .groups_deleteGroup__qN3vF {
    font-size: 15px;
  }
  /* .action{
    width: 6%;
  } */
  .groups_selectIcon__eaRe0 {
    margin-right: 3px;
  }
  /* .memberContainer {
    grid-template-columns: 300px 1fr 1fr 90px;
  } */
  .groups_action__2edDP {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1130px) and (max-width: 1440px) {
  .groups_groupTitle__2yxSe {
    font-size: 26.25px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 16px;
  }
  /* .albumlist, .videoList {
    padding: 10px 10px 10px 28px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 18px;
  }
  .groups_videoTitle__wS7iP {
    font-size: 12px;
    width: 130px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 15px;
  }
  .groups_memberContainer__A75fO {
    grid-template-columns: 340px 1fr 1fr 90px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .groups_groupTitle__2yxSe {
    font-size: 31.171875000000004px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 17px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 50px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 19px;
  }
  .groups_videoTitle__wS7iP {
    font-size: 17px;
    width: 170px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 16px;
  }
  .groups_memberContainer__A75fO {
    grid-template-columns: 400px 1fr 1fr 100px;
  }
}

@media only screen and (min-width: 1710px) {
  .groups_groupTitle__2yxSe {
    font-size: 35px;
  }
  .groups_addMemberText__1g8bD {
    font-size: 18px;
  }
  /* .albumlist, .videoList {
    padding: 10px 20px 10px 20px;
  } */
  .groups_videoHeading__2DBDd {
    font-size: 20px;
  }
  .groups_videoTitle__wS7iP {
    font-size: 18px;
    width: 200px;
  }
  .groups_deleteGroup__qN3vF {
    font-size: 17px;
  }
  .groups_memberContainer__A75fO {
    grid-template-columns: 500px 1fr 1fr 150px;
  }
}

@media only screen and (max-width: 767px) {
  .groups_memberContainer__A75fO {
    display: block;
  }
}

.successor_hiddenFormField__2JIiZ {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.successor_accountDataContainer__14h_s {
  align-items: center;
  display: flex;
  width: 100%;
}

.successor_accountData__1sZKF {
  flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .successor_accountData__1sZKF {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .successor_accountData__1sZKF {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .successor_accountData__1sZKF {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .successor_accountData__1sZKF {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .successor_accountData__1sZKF {
    font-size: 20px;
  }
}

.successor_actionButton__24UXc {
  color: #000 !important;
}

.successor_inputFieldContainer__3CbVj {
  background-color: transparent !important;
}

.successor_inputFieldContainer__3CbVj img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.successor_inputField__2vVL4 {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.successor_inputFieldLabel__2KAvf {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.successor_passwordStrengthBar__2iKKS {
  background-color: #ddd;
}

.successor_passwordStrengthNotes__1AQV2 {
  color: #444;
}

.successor_avatarEditButton__2c9el {
  color: #5aa5ef;
  font-weight: 600;
}

.successor_accountDataAction__1AoEk {
  color: rgba(2, 7, 26, 0.4);
}

.successor_avatarEditButton__2c9el,
.successor_accountDataAction__1AoEk {
  cursor: pointer;
  flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .successor_avatarEditButton__2c9el:hover,
  .successor_accountDataAction__1AoEk:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .successor_avatarEditButton__2c9el:active, .successor_avatarEditButton__2c9el:target,
  .successor_accountDataAction__1AoEk:active,
  .successor_accountDataAction__1AoEk:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .successor_avatarEditButton__2c9el,
  .successor_accountDataAction__1AoEk {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .successor_avatarEditButton__2c9el,
  .successor_accountDataAction__1AoEk {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .successor_avatarEditButton__2c9el,
  .successor_accountDataAction__1AoEk {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .successor_avatarEditButton__2c9el,
  .successor_accountDataAction__1AoEk {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .successor_avatarEditButton__2c9el,
  .successor_accountDataAction__1AoEk {
    font-size: 15px;
    margin-left: 30px;
  }
}

.successor_profilePicture__1L50k {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: flex;
  justify-content: center;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .successor_profilePicture__1L50k {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .successor_profilePicture__1L50k {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .successor_profilePicture__1L50k {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .successor_profilePicture__1L50k {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .successor_profilePicture__1L50k {
    height: 98px;
    width: 98px;
  }
}

.successor_profilePictureIcon___FDhz {
  height: 50%;
  width: 50%;
}

.successor_allViewersContainer__2dZ-J {
  float: left;
  width: 50%;  
  padding: 32px 0px;
}

.successor_pendingViewersContainer__3N0fu {
  float: left;
  width: 50%;  
  padding: 32px 24px;
}

.successor_pendingTitle__CSgvX {
  font-family: 'Poppins';
  font-weight: 500;
}

.successor_profileIcon__3qVx7 {
  content:attr(data-letters);
  display:inline-block;
  font-size:1.3em;
  width:2.5em;
  height:2.5em;
  line-height:2.5em;
  text-align:center;
  border-radius:50%;
  background:#F0F0F0;
  vertical-align:middle;
  margin-right:1em;
  color:#000;
  border: 1px solid #000;  
}

.successor_viewerSearchinput__12uyN {
  background-color: #F0F0F0;
  border: none;
  color: #8E8E8E;
  font-weight: 500;
}
input.successor_viewerSearchinput__12uyN[type="text"] {
  padding: 0px 15px;
}

.successor_userRequest__33EgP {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.successor_userRequestText__3vu53 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

.successor_userRequestName__2Vlcv {
  font-weight: 600;
  line-height: 1.3;
  text-transform: capitalize;
}

.successor_userRequestEmail__3MOAQ {
  color: #b2b2b2;
}

.successor_userRequestName__2Vlcv,
.successor_userRequestEmail__3MOAQ {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.successor_userRequestActionsContainer__2f1Xm {
  display: flex;
}

.successor_userRequestActionButton__3iMKR {
  background-color: transparent;
  border: 2px solid #5aa5ef;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 0;
  margin-left: 2%;
  margin-top: -1px;
}

@media all and (hover: hover) {
  .successor_userRequestActionButton__3iMKR.successor_delete__10VBD:hover {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .successor_userRequestActionButton__3iMKR.successor_delete__10VBD:active,
  .successor_userRequestActionButton__3iMKR.successor_delete__10VBD:target {
    background-color: #ff4040 !important;
    border-color: #ff4040 !important;
    color: #fff !important;
  }
}

@media all and (hover: hover) {
  .successor_userRequestActionButton__3iMKR:not(.successor_delete__10VBD):hover {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .successor_userRequestActionButton__3iMKR:not(.successor_delete__10VBD):active,
  .successor_userRequestActionButton__3iMKR:not(.successor_delete__10VBD):target {
    background-color: #5aa5ef !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 319px) {
  .successor_imageGroup__1Idwx {
    padding-top: 12px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .successor_imageGroup__1Idwx {
    padding-top: 16px;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .successor_imageGroup__1Idwx {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .successor_profileIcon__3qVx7 {
    width: 1.5em !important;
    height: 1.5em !important;
    line-height: 1.5em !important;
    margin-right: .3em !important;
  }
}

@media only screen and (max-width: 767px) {
  .successor_sectionsContainer__3rjft {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 4px;
  }
  .successor_uploadIcon__2VSQd {
    height: 41px;
    width: 41px;
  }
  .successor_videoUploadSection__23kHX {
    height: 184px;
  }
  .successor_videoAnalyticsSection__Aitfw {
    height: 216px;
  }
  .successor_section__1HGRS {
    border-radius: 12.65625px;
    box-shadow: #00000029 0px 3px 6px;
    margin-top: 20px;
    padding: 15px;
  }
  .successor_videoUploadSectionContent__NZZ_- {
    border-radius: 8.90625px;
  }
  .successor_addUserButton__142lN {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .successor_userRequestName__2Vlcv {
    font-size: 15px;
  }
  .successor_userRequestEmail__3MOAQ {
    font-size: 10px;
  }
  .successor_sectionTitle__82k2Y {
    margin-bottom: 14px;
  }
  .successor_userRequest__33EgP {
    margin-top: 22px;
  }
  .successor_userRequestActionButton__3iMKR {
    font-size: 7px;
    height: 14px;
    width: 39px;
  }
  .successor_videoAnalyticsList__2fV0_,
  .successor_storageInfoBar__1Rn-8 {
    font-size: 14px;
  }
  .successor_storageInfoText__2-y6b {
    font-size: 13.5px;
  }
  .successor_storageManagementButton__2WTTL {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 94.6875px;
  }
  .successor_storageInfoBar__1Rn-8 {
    height: 23px;
  }
  .successor_pendingViewersContainer__3N0fu {
    padding: 32px 6px !important;
  }
  .successor_allViewersContainer__2dZ-J {
    padding: 32px 0px 0px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .successor_sectionsContainer__3rjft {
    grid-gap: 14.375000000000002px;
    gap: 14.375000000000002px;
    margin-top: 20.625px;
  }
  .successor_uploadIcon__2VSQd {
    height: 61.5625px;
    margin: 32px;
    width: 61.5625px;
  }
  .successor_section__1HGRS {
    border-radius: 16.875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 18.75px;
  }
  .successor_videoUploadSectionContent__NZZ_- {
    border-radius: 11.875px;
  }
  .successor_addUserButton__142lN {
    font-size: 25px;
    height: 30px;
    width: 30px;
  }
  .successor_userRequestName__2Vlcv {
    font-size: 17px;
  }
  .successor_userRequestEmail__3MOAQ {
    font-size: 11px;
  }
  .successor_sectionTitle__82k2Y {
    margin-bottom: 14.25px;
  }
  .successor_userRequest__33EgP {
    margin-top: 22px;
  }
  .successor_userRequestActionButton__3iMKR {
    font-size: 9px;
    height: 19.75px;
    width: 51px;
  }
  .successor_videoAnalyticsList__2fV0_,
  .successor_storageInfoBar__1Rn-8 {
    font-size: 14px;
  }
  .successor_storageInfoText__2-y6b {
    font-size: 13.5px;
  }
  .successor_storageManagementButton__2WTTL {
    font-size: 14.375000000000002px;
    height: 34px;
    width: 126.25px;
  }
  .successor_storageInfoBar__1Rn-8 {
    height: 23px;
  }
  .successor_pendingTitle__CSgvX {
    font-size: 18px;  
  }
  .successor_pendingViewersContainer__3N0fu {
    padding: 32px 6px !important;
  }
  .successor_allViewersContainer__2dZ-J {
    padding: 32px 0px 0px 0px;
  }
  .successor_viewerSearchinput__12uyN {
    font-size: 12px;
    height: 26px;
    width: 321px;
  }
}
@media only screen and (max-width: 1440px) {
  .successor_profileIcon__3qVx7 {
    width: 2em;
    height: 2em;
    line-height: 2em;
    margin-right: .5em;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .successor_sectionsContainer__3rjft {
    grid-gap: 17.25px;
    gap: 17.25px;
    margin-top: 24.750000000000004px;
  }
  .successor_uploadIcon__2VSQd {
    height: 73.875px;
    margin: 32px;
    width: 73.875px;
  }
  .successor_section__1HGRS {
    border-radius: 20.25px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 22.5px;
  }
  .successor_videoUploadSectionContent__NZZ_- {
    border-radius: 14.25px;
  }
  .successor_addUserButton__142lN {
    font-size: 27px;
    height: 33px;
    width: 33px;
  }
  .successor_userRequestName__2Vlcv {
    font-size: 19.75px;
  }
  .successor_userRequestEmail__3MOAQ {
    font-size: 12.5px;
  }
  .successor_sectionTitle__82k2Y {
    margin-bottom: 14.25px;
  }
  .successor_userRequest__33EgP {
    margin-top: 22px;
  }
  .successor_userRequestActionButton__3iMKR {
    font-size: 12.25px;
    height: 21.953125px;
    width: 68.0625px;
  }
  .successor_videoAnalyticsList__2fV0_,
  .successor_storageInfoBar__1Rn-8 {
    font-size: 15px;
  }
  .successor_storageInfoText__2-y6b {
    font-size: 13.5px;
  }
  .successor_storageManagementButton__2WTTL {
    font-size: 17.25px;
    height: 34px;
    width: 151.5px;
  }
  .successor_storageInfoBar__1Rn-8 {
    height: 23.250000000000004px;
  }
  .successor_pendingTitle__CSgvX {
    font-size: 21px;  
  }
  .successor_pendingViewersContainer__3N0fu {
    padding: 32px 6px !important;
  }
  .successor_allViewersContainer__2dZ-J {
    padding: 21px 0px 0px 0px;
  }
  .successor_viewerSearchinput__12uyN {
    font-size: 11px;
    height: 25px;
    width: 267px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .successor_sectionsContainer__3rjft {
    grid-gap: 20.484375px;
    gap: 20.484375px;
    margin-top: 29.390625000000004px;
  }
  .successor_uploadIcon__2VSQd {
    height: 87.7265625px;
    margin: 32px;
    width: 87.7265625px;
  }
  .successor_section__1HGRS {
    border-radius: 24.046875px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 26.71875px;
  }
  .successor_videoUploadSectionContent__NZZ_- {
    border-radius: 16.921875px;
  }
  .successor_addUserButton__142lN {
    font-size: 32.0625px;
    height: 39.1875px;
    width: 39.1875px;
  }
  .successor_userRequestName__2Vlcv {
    font-size: 20.265625px;
  }
  .successor_userRequestEmail__3MOAQ {
    font-size: 13.46875px;
  }
  .successor_sectionTitle__82k2Y {
    margin-bottom: 16.921875px;
  }
  .successor_userRequest__33EgP {
    margin-top: 25.828125px;
  }
  .successor_userRequestActionButton__3iMKR {
    font-size: 12.25px;
    height: 21.953125px;
    width: 68.0625px;
  }
  .successor_videoAnalyticsList__2fV0_,
  .successor_storageInfoBar__1Rn-8 {
    font-size: 17.8125px;
  }
  .successor_storageInfoText__2-y6b {
    font-size: 16.03125px;
  }
  .successor_storageManagementButton__2WTTL {
    font-size: 20.484375px;
    height: 34px;
    width: 179.90625px;
  }
  .successor_storageInfoBar__1Rn-8 {
    height: 27.609375000000004px;
  }
  .successor_pendingTitle__CSgvX {
    font-size: 27px;  
  }
  .successor_allViewersContainer__2dZ-J {
    padding: 32px 0px 0px 0px;  
  }
  .successor_viewerSearchinput__12uyN {
    font-size: 15px;
    height: 33px;
    width: 407px;
  }
}

@media only screen and (min-width: 1710px) {
  .successor_sectionsContainer__3rjft {
    grid-gap: 23px;
    gap: 23px;
    margin-top: 33px;
  }
  .successor_uploadIcon__2VSQd {
    height: 98.5px;
    margin: 32px;
    width: 98.5px;
  }
  .successor_section__1HGRS {
    border-radius: 27px;
    box-shadow: #00000029 0px 3px 6px;
    padding: 30px;
  }
  .successor_videoUploadSectionContent__NZZ_- {
    border-radius: 19px;
  }
  .successor_addUserButton__142lN {
    font-size: 36px;
    height: 44px;
    width: 44px;
  }
  .successor_userRequestName__2Vlcv {
    font-size: 23px;
  }
  .successor_userRequestEmail__3MOAQ {
    font-size: 14px;
  }
  .successor_sectionTitle__82k2Y {
    margin-bottom: 19px;
  }
  .successor_userRequest__33EgP {
    margin-top: 29px;
  }
  .successor_userRequestActionButton__3iMKR {
    font-size: 15px;
    height: 29px;
    width: 84px;
  }
  .successor_videoAnalyticsList__2fV0_,
  .successor_storageInfoBar__1Rn-8 {
    font-size: 20px;
  }
  .successor_storageInfoText__2-y6b {
    font-size: 18px;
  }
  .successor_storageManagementButton__2WTTL {
    font-size: 23px;
    height: 34px;
    width: 202px;
  }
  
  .successor_storageInfoBar__1Rn-8 {
    height: 31px;
  }
  .successor_pendingTitle__CSgvX {
    font-size: 31px;  
  }
  .successor_allViewersContainer__2dZ-J {
    padding: 32px 0px 0px 0px;
  }
  .successor_viewerSearchinput__12uyN {
    font-size: 18px;
    height: 43px;
    width: 581px;
  }
}

.successor_deleteActive__28kU6 {
  background-color: #fff !important;
  color:#FF0000 !important;
  border-color: #000 !important;
}

.successor_successorRestrictContainer__3gvZy {
  display: grid;
  align-items: center;
  min-height: 100%;
  text-align: center;
  justify-content: center;

}

.successor_restrictBox__2Uz-S {
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  max-width: 450px;
  padding: 42px;
}

.successor_message__3xWoM {
  font-weight: 700;
}
.successor_upgradeButton__1MxtR {
  background-color: #fff;
  border: 1px solid#C9C9C9;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  width: 120px;
  font-size: 14px;
  margin-top: 20px;
}

.manage-device_signInLink__1T_AO {
  color: #808080 !important;
}

.email-verification_timer__2nkOk {
  color: #fff !important;
  text-align: center;
  padding-top: 10px;
}

.email-verification_notifyText__1rsNV {
  padding: 11px;
  font-size: 20px;
  text-align: center;
}

.email-verification_timer__2nkOk button {
  color: #fff !important;
  background-color: #5aa5ef !important;
  border-radius: 5px !important;
}

.email-verification_otpWrapper__MQ3ri {
  display: flex;
  justify-content: space-between;
}

.email-verification_backLink__3lX3I {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

@media all and (hover: hover) {
  .email-verification_backLink__3lX3I:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .email-verification_backLink__3lX3I:active, .email-verification_backLink__3lX3I:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .email-verification_backLink__3lX3I {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .email-verification_backLink__3lX3I {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .email-verification_backLink__3lX3I {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .email-verification_backLink__3lX3I {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .email-verification_backLink__3lX3I {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .email-verification_backLink__3lX3I {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
}

.email-verification_skipDiv__t1H3_ {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.email-verification_skipDiv__t1H3_:hover {
  color: #707070;
}
.email-verification-link_emailVerifyWrapper__1_cli {
  text-align: center;
}
.email-verification-link_verifyingEmail__2n6ns {
  color: #5aa5ef;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}
.email-verification-link_verified__27MCI {
  color: green;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}
.email-verification-link_notVerified__Gulo4 {
  color: red;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}
.header_header__2dtqo {
  width: 100%;
}

.header_headerContent__2YFTa {
  align-items: center;
  display: flex;
  width: 100%;
  /* padding: 40px; */
}

@media only screen and (max-width: 767px) {
  .header_headerContent__2YFTa {
    flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .header_headerContent__2YFTa {
    justify-content: space-between;
  }
}

.header_homeLink__2p9iy {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  text-decoration: none;
  /* margin: 30px; */
}

.header_headerLinksContainer__1v2_h {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header_headerLinksContainer__1v2_h {
    margin-top: 44px;
  }
}

.header_headerLink__10w-J {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .header_headerLink__10w-J {
    font-size: 12px;
  }
  .header_headerLink__10w-J:first-of-type {
    margin-right: 18px;
  }
  .header_headerContent__2YFTa {
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .header_headerLink__10w-J {
    margin-left: 16.40625px;
    font-size: 12px;
  }
  .header_headerContent__2YFTa {
    padding: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .header_headerLink__10w-J {
    margin-left: 21.875px;
    font-size: 12px;
  }
  .header_headerContent__2YFTa {
    padding: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .header_headerLink__10w-J {
    margin-left: 16px;
    font-size: 16px !important;
    height: 40px !important;
  }
  .header_headerContent__2YFTa {
    padding: 25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_headerLink__10w-J {
    margin-left: 20px;
    font-size: 20px !important;
    height: 48px !important;
  }
  .header_headerContent__2YFTa {
    padding: 30px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_headerLink__10w-J {
    margin-left: 24px;
    font-size: 24px !important;
    height: 56px !important;
  }
  .header_headerContent__2YFTa {
    padding: 40px;
  }
  .header_homeLink__2p9iy {
    margin: 15px 25px;
  }
}

.header_signUpButton__3Bd9j {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border-style: none !important;
  /* border: 0.5px solid #707070; */
  /* background-color: #084FF9; */
  background-image: linear-gradient(to right, #084FF9 0%, #04287D 100%);
  /* box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset; */
  /* -webkit-box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset; */
  height: 40px !important;
  margin: 0 10px;
  font-weight: 700;
  font-size: 16px !important;
}
.header_logInButton__3VdYV {
  text-transform: none;
  color: #fff;
  background-color: transparent;
  border-radius: 30px !important;
  border: 2px solid #FFFFFF !important;
  height: 40px !important;
  margin: 0 30px 0 10px;
  font-weight: 700;
  font-size: 16px !important;
}

.header_heroHeading__1DTG- {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 899px) {
  .header_heroHeading__1DTG- {
    font-size: 34px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
  .header_textStyle__pMYe6 {
    font-size: 24px;
  }
  .header_appForFree__Snaao {
    height: 60px !important;
  }
  .header_helpCenterWrapper__30X0M {
    margin: 0px 33px 0 143px;
    bottom: 3px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .header_heroHeading__1DTG- {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .header_textStyle__pMYe6 {
    font-size: 35px;
  }
  .header_appForFree__Snaao {
    height: 65px !important;
  }
  .header_helpCenterWrapper__30X0M {
    margin: 0px 33px 0 156px;
    bottom: 8px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .header_heroHeading__1DTG- {
    font-size: 50px;
    margin-bottom: 23.15625px;
  }
  .header_textStyle__pMYe6 {
    font-size: 40px;
  }
  .header_appForFree__Snaao {
    height: 68px !important;
  }
  .header_helpCenterWrapper__30X0M {
    margin: 0px 33px 0 189px;
    bottom: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .header_heroHeading__1DTG- {
    font-size: 55px;
    margin-bottom: 26px;
  }
  .header_textStyle__pMYe6 {
    font-size: 45px;
  }
  .header_appForFree__Snaao {
    height: 70px !important;
    margin-bottom: 3rem !important;
  }
  .header_headingWrapper__7QFlO {
    margin-top: 10px;
  }
  .header_helpCenterWrapper__30X0M {
    margin: 0px 33px 0 201px;
    bottom: 25px;
  }
}
.header_headingWrapper__7QFlO {
    width: 100%;
    /* float: left; */
    text-align: center;
    margin-top: 40px;
    /* background-image: url(../../../../../Assets/landing-background.png);
    background-size: cover; */
}

.header_headerContainer__3-oLb {
  /* height: 100vh; */
  /* background-image: linear-gradient(
                  rgba(0, 0, 0, 0.9),
                  rgba(0, 0, 0, 0.7)
                ), url(../../../../../Assets/landing-background.png); */
    background-size: 100% 100%;
    background-image: url(/static/media/landing-background.02597316.png);
}

.header_mainHeaderWrap__Osoza {
  /* min-height: 100vh; */
}

.header_viewSharePreserveWrapper__ke4AM {
  padding: 20px 0 60px 0;
}

.header_textStyle__pMYe6 {
    /* font-size: 45px; */
    padding: 0 20px;
    font-weight: 700;
}

.header_appForFree__Snaao {
  
  color: #fff;
  text-transform: none;
  border-radius: 40px !important;
  border: 1px solid #707070;
  /* background-color: #084FF9; */
  background-image: linear-gradient(to right, #0185FF 0%, #015BFB 60%, #0139F7 100%);
  border-style: none !important;
  margin-bottom: 5rem;
  font-weight: 600;
}

.header_deviceWrapper__28cqe {
  margin-top: -7rem;
  text-align: center;
}
.header_deviceIcon__2oN2q {
  margin: 0 20px;
}
.header_adjustChromCastIcon__1lQyb {
  position: relative;
  top: 97px;
}

.header_adjustFireTV__1s-Ea {
  position: relative;
  top: 25px;
}

@media only screen and (max-width: 1260px) {
  .header_hideIcon__2nSmM {
    display: none !important;
  }
}

@media only screen and (max-width: 1260px) {
  .header_deviceWrapper__28cqe {
    margin-top: 3rem !important;
  }
}

@media only screen and (max-width: 392px) {
  .header_deviceWrapper__28cqe {
    display: flex;
  }
}

.header_helpCenterWrapper__30X0M {
  /* margin: 0px 33px 0 201px; */
  justify-content: end;
  display: flex;
  position: relative;
  /* bottom: 25px; */
  color: #FFFFFF;
}

.header_helpCenterText__2Dv7e {
  font-weight: 500;
  font-size: 16px;
}

.header_helpCenterIcon__1xAdW {
  position: relative;
  top: 3px;
  right: 5px;
}

.header_adjustRoku__3i-3u {
  position: relative;
  top: 20px;
}

.header_rightContainer__QEOrC {
  position: relative;
  bottom: 10px;
}
.footer_footer__2XDmR {
  width: 100%;
}

.footer_footerContent__1CFGF {
  color: #cecece;
  font-weight: 300;
  letter-spacing: 0.75px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .footer_footerContent__1CFGF {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footer_footerContent__1CFGF {
    margin-top: 72px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_footerContent__1CFGF {
    margin-top: 85.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_footerContent__1CFGF {
    margin-top: 96px;
  }
}

@media only screen and (max-width: 767px) {
  .footer_brandName__3AiQD {
    margin-bottom: 96px;
    margin-top: 76.80000000000001px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .footer_brandName__3AiQD {
    margin-bottom: 96px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_brandName__3AiQD {
    margin-bottom: 114px;
    margin-top: 171px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_brandName__3AiQD {
    margin-bottom: 128px;
    margin-top: 192px;
  }
}

.footer_linksContainer__2xePW {
  /* display: -ms-grid; */
  /* display: grid; */
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .footer_linksContainer__2xePW {
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .footer_linksContainer__2xePW {
    -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .footer_linksContainer__2xePW {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .footer_linksContainer__2xePW {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    justify-content: space-between;
  }
}

.footer_link__2JC8D {
  color: #cecece;
  display: table-cell;
  text-align: center;
  padding: 15px;
}

@media all and (hover: hover) {
  .footer_link__2JC8D:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .footer_link__2JC8D:active, .footer_link__2JC8D:target {
    color: #fff;
    text-decoration: underline;
  }
}

.footer_copyright__2eM0_ {
  /* margin-top: 13px; */
  text-align: center;
  margin-left: 22px;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
      margin-top: 9.5px;
    }
    .footer_terms__497bu{
      margin: 110px 100px 0px 0px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
      margin-top: 11px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer_copyright__2eM0_ {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
      margin-top: 13px;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .footer_copyright__2eM0_ {
      padding-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .footer_link__2JC8D,
  .footer_copyright__2eM0_ {
    font-size: 9px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footer_link__2JC8D,
  .footer_copyright__2eM0_ {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footer_link__2JC8D,
  .footer_copyright__2eM0_ {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .footer_link__2JC8D,
  .footer_copyright__2eM0_ {
    font-size: 15px;
  }
}

.footer_linkWrapper__3w-Xc {
  display: table;
  width: 100%;
  table-layout: fixed; 
}

.footer_footerDiv__lPnm0 {
  justify-content: space-around;
}

.footer_logoPart__3IfbT {
  float: left;
  display: inline-flex;
  /* margin: 50px; */
}

.footer_terms__497bu {
  float: right;
  /* margin: 73px 65px 0px 0px; */
}

/* .logoSizeChange {
  height: 50px !important;
} */

@media only screen and (min-width: 1273px) {
  .footer_terms__497bu {
    margin: 113px 100px 0px 0px;
  }
  .footer_logoPart__3IfbT {
    margin: 116px 0px 0px 73px;
  }
}

@media only screen and (max-width: 1272px) {
  .footer_footerDiv__lPnm0 {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .footer_terms__497bu {
    justify-content: space-around;
    display: flex;
  }
  .footer_logoPart__3IfbT {
    justify-content: center;
    clear: both;
  }
}
.page_page__28Do3 {
  /* background: radial-gradient(circle at center, #1f2330, #07080c); */
  /* background-image: radial-gradient(circle, red, yellow, green); */
  background-image: radial-gradient(#23232E, #030202 40%) !important;
  color: #fff;
  position: relative;
  width: 100%;
}

.page_page__28Do3 *, .page_page__28Do3 {
  font-family: 'Montserrat', sans-serif !important;
}

.page_pageContent__Y8KRk {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .page_pageContent__Y8KRk {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .page_pageContent__Y8KRk {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .page_pageContent__Y8KRk {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .page_pageContent__Y8KRk {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .page_pageContent__Y8KRk {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .page_pageContent__Y8KRk {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .page_pageContent__Y8KRk {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .page_pageContent__Y8KRk {
      padding-right: 40px;
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .page_pageContent__Y8KRk {
    max-width: 1400px;
  }
}

.page_mainContent__2VqJi {
  margin: auto;
  max-width: 1920px !important;
  width: 100%;
}


.heading_heroHeading__1X7vr {
    color: #FFFFFF;
    font-weight: normal;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
  }
  
  @media only screen and (max-width: 899px) {
    .heading_heroHeading__1X7vr {
      font-size: 34px;
      margin-bottom: 16.25px;
      margin-top: 28px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    .heading_heroHeading__1X7vr {
      font-size: 45px;
      margin-bottom: 19.5px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .heading_heroHeading__1X7vr {
      font-size: 50px;
      margin-bottom: 23.15625px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .heading_heroHeading__1X7vr {
      font-size: 55px;
      margin-bottom: 26px;
    }
  }
  .heading_headingWrapper__3ReyW {
      width: 100%;
      text-align: center;
      /* margin-top: 40px; */
  }

  .heading_viewSharePreserveWrapper__3n7yR {
    padding: 10px;
  }

  .heading_textStyle__2_4Ez {
      font-size: 30px;
      padding: 0 20px;
  }

  .heading_signUpButton__bH2Sr {
    
    color: #fff;
    text-transform: none;
    border-radius: 30px !important;
    border: 1px solid #707070;
    background-color: #084FF9;
    box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset;
    -webkit-box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset;
    height: 51px !important;
    margin: 80px 10px 30px 10px;
  }

  .heading_deviceWrapper__CD-rP {
    margin-top: -6rem;
      /* display: inline; */
  }
  .heading_deviceIcon__1YTvO {
    margin: 0 20px;
  }
  .heading_adjustChromCastIcon__rJHN0 {
    position: relative;
    top: 97px;
  }

  .heading_adjustFireTV__3vQ9m {
    position: relative;
    top: 25px;
  }

  @media only screen and (max-width: 1260px) {
    .heading_hideIcon__39AUZ {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1260px) {
    .heading_deviceWrapper__CD-rP {
      margin-top: 3rem !important;
    }
  }

  @media only screen and (max-width: 392px) {
    .heading_deviceWrapper__CD-rP {
      display: flex;
    }
  }
.pricing-and-plan_planWrapper__1Gpwx {
  text-align: center;
  padding-top: 6rem;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .pricing-and-plan_planWrapper__1Gpwx {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pricing-and-plan_planWrapper__1Gpwx {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pricing-and-plan_planWrapper__1Gpwx {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pricing-and-plan_planWrapper__1Gpwx {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .pricing-and-plan_planWrapper__1Gpwx {
    max-width: 1536px;
  }
}
.pricing-and-plan_heroHeading__2I5DC {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: none;
}

.pricing-and-plan_description__1pvKh {
  color: #FFFFFF;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  text-transform: none;
  font-size: 19px;
  padding: 0 26%;
  opacity:0.70;
}

@media only screen and (max-width: 767px) {
  .pricing-and-plan_description__1pvKh {
    padding: 0 8%;
  }
}

@media only screen and (min-width: 528px) and  (max-width: 899px) {
  .pricing-and-plan_heroHeading__2I5DC {
    font-size: 40px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
  .pricing-and-plan_planBox__3H9Kb {
    width: 400px !important;
  } 
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 350px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pricing-and-plan_heroHeading__2I5DC {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .pricing-and-plan_planBox__3H9Kb {
    width: 400px !important;
  }
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 290px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pricing-and-plan_heroHeading__2I5DC {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .pricing-and-plan_planBox__3H9Kb {
    width: 400px !important;
  }
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 320px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pricing-and-plan_heroHeading__2I5DC {
    font-size: 50px;
    margin-bottom: 23.15625px;
  }
  .pricing-and-plan_planBox__3H9Kb {
    width: 450px !important;
  }
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 380px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .pricing-and-plan_heroHeading__2I5DC {
    font-size: 55px;
    margin-bottom: 26px;
  }
  .pricing-and-plan_planBox__3H9Kb {
    width: 500px !important;
  }
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 400px !important;
  }
}

.pricing-and-plan_planList__2ViPU {
  padding-top: 6rem;
}

@media only screen and (max-width: 1119px) {
  .pricing-and-plan_planList__2ViPU {
    display: table !important; 
    margin: 0 auto;
  }
  /* .planBox {
    width: 430px !important;
  } */
}

/* @media only screen and (min-width: 528px) and (max-width: 1119px) {
  .planBox {
    width: 400px !important;
  } 
  .signUpButton {
    width: 350px !important;
  }
} */

@media only screen and (max-width: 527px) {
  .pricing-and-plan_planBox__3H9Kb {
    width: 280px !important;
  }
  .pricing-and-plan_signUpButton__3pXZ- {
    width: 240px !important;
  }
}

@media only screen and (min-width: 768px) {
  .pricing-and-plan_planList__2ViPU {
    display: flex;
    justify-content: space-around;
  }
}

.pricing-and-plan_planBox__3H9Kb {
  max-width: 530px;
  max-height: 400px;
  background-color: #FFFFFF;
  border-radius: 10px;
  min-width: 320px;
  margin: 0 20px;
}

@media only screen and (min-width: 1200px) {
  /* .planBox {
    width: 380px !important;
  } */
  /* .signUpButton {
    width: 316px !important;
  } */
}

@media only screen and (max-width: 1200px) {
  .pricing-and-plan_planBox__3H9Kb {
    margin-top: 40px;
  }
}

.pricing-and-plan_blueBackGround__-mdB9 {
  background-color: #0075FF;
}

.pricing-and-plan_header__2tqs5 {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}

.pricing-and-plan_title__2c_iE {
  color: #000;
  font-size: 20px;
  padding: 5px;
}

.pricing-and-plan_planDescription__10hnJ {
  color: #1D263A;
  font-size: 15px;
  padding: 10px 5px;
}
.pricing-and-plan_horizontalLine__MbJcE {
  margin: 20px 30px 10px 30px;
}


.pricing-and-plan_planDetails__1IdCT {
  color: #000;
  font-size: 18px;
  padding: 0 10px;
}

.pricing-and-plan_signUpButton__3pXZ- {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border: 1px solid #707070;
  background-color: #0075FF;
  height: 51px !important;
  margin: 50px 10px 50px 10px;
  font-size: 18px !important;
  /* min-width: 280px !important; */
}

.pricing-and-plan_textColourWhite__25k_n {
  color: #fff !important;
}

.pricing-and-plan_backGroundWhite__1EIJc {
  background-color: #fff !important;
}

.pricing-and-plan_textColourBlue__2Hbco {
  color: #0075FF !important;
}

.pricing-and-plan_planNameWrapper__1ltPI {
  text-align: left;
}

.pricing-and-plan_planPriceWrapper__lcbSM {
  text-align: right;
}

.pricing-and-plan_price__3UWPp {
  color: #000;
  font-size: 30px;
}
.how-to-use-projector_planWrapper__Hb6rD {
  text-align: center;
  padding-top: 6rem;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .how-to-use-projector_planWrapper__Hb6rD {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .how-to-use-projector_planWrapper__Hb6rD {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .how-to-use-projector_planWrapper__Hb6rD {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .how-to-use-projector_planWrapper__Hb6rD {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .how-to-use-projector_planWrapper__Hb6rD {
    max-width: 1536px;
  }
}
.how-to-use-projector_heroHeading__316Ed {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: none;
}

.how-to-use-projector_description__2FxSl {
  color: #707070;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
  text-transform: none;
  font-size: 20px;
}

@media only screen and (max-width: 899px) {
  .how-to-use-projector_heroHeading__316Ed {
    font-size: 45px;
    margin-bottom: 140px;
    margin-top: 6rem;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .how-to-use-projector_heroHeading__316Ed {
    font-size: 50px;
    margin-bottom: 7rem;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .how-to-use-projector_heroHeading__316Ed {
    font-size: 55px;
    margin-bottom: 8rem;
  }
}

@media only screen and (min-width: 1710px) {
  .how-to-use-projector_heroHeading__316Ed {
    font-size: 60px;
    margin-bottom: 8rem;
  }
}

@media only screen and (max-width: 767px) {
  .how-to-use-projector_useList__3prbr {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .how-to-use-projector_useList__3prbr {
    display: flex;
    justify-content: space-around;
  }
}

.how-to-use-projector_planBox__3z0gZ {
  min-width: 400px;
  min-height: 370px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.how-to-use-projector_blueBackGround__2uOXA {
  background-color: #0075FF;
}

.how-to-use-projector_header__2eTDf {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.how-to-use-projector_title__bVjDD {
  color: #fff;
  font-size: 30px;
  padding: 17px;
  font-weight: 700;
}

.how-to-use-projector_planDescription__2sOVG {
  color: #1D263A;
  font-size: 15px;
}
.how-to-use-projector_horizontalLine__2ElHA {
  margin: 30px;
}

.how-to-use-projector_planDetails__1p2tG {
  color: #000;
  font-size: 18px;
  padding: 0 20px;
}

.how-to-use-projector_signUpButton__3_cb8 {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border: 1px solid #707070;
  background-color: #0075FF;
  height: 51px !important;
  margin: 80px 10px 30px 10px;
  font-size: 18px !important;
  min-width: 280px !important;
}

.how-to-use-projector_textColourWhite__g5KZ_ {
  color: #fff !important;
}

.how-to-use-projector_backGroundWhite__23shN {
  background-color: #fff !important;
}

.how-to-use-projector_textColourBlue__dshh4 {
  color: #0075FF !important;
}

.how-to-use-projector_planNameWrapper__1G3T3 {
  text-align: left;
}

.how-to-use-projector_planPriceWrapper__3lWZt {
  text-align: right;
}

.how-to-use-projector_price__2sA6n {
  color: #000;
  font-size: 30px;
}

.how-to-use-projector_useWrapper__3t3Xw {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .how-to-use-projector_useWrapper__3t3Xw {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .how-to-use-projector_useWrapper__3t3Xw {
    flex-basis: 33.33%;
  }
}

.how-to-use-projector_useFrame__V4Q8m {
  color: #fff;
  height: 300px;
  max-width: 350px;
  object-fit: contain;
  object-position: center bottom;
  position: relative;
  width: 100%;
}

.how-to-use-projector_firstIconChange__3Vs17 {
  height: 329px !important;
  max-width: 270px !important;
}

.how-to-use-projector_secondIconChange__n16Xc {
  height: 377px !important;
  max-width: 418px !important;
  left: 22px;
}

.how-to-use-projector_adjustPosition1__2ksk3 {
  position: relative !important;
  bottom: 28px !important;
}

.how-to-use-projector_adjustPosition2__3isoa {
  position: relative !important;
  bottom: 68px !important;
  left: 5px !important;
}
.how-to-use-projector_adjustTitle__3YORs {
  position: relative;
  bottom: 12px;
}

.how-to-use-projector_contentWrapper__GrN4q {
  margin-top: 12px;
}
.access-your-video_devices__v0gtY {
    margin: 0rem auto;
    max-width: 1280px;
    position: relative;
  }
  
  @media only screen and (max-width: 767px) {
    .access-your-video_devices__v0gtY {
      max-width: 512px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access-your-video_devices__v0gtY {
      max-width: 900px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access-your-video_devices__v0gtY {
      max-width: 1280px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access-your-video_devices__v0gtY {
      max-width: 1408px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .access-your-video_devices__v0gtY {
      max-width: 1536px;
    }
  }
  
  .access-your-video_devicesTitle__3f0jB {
    font-weight: 700;
    margin-top: 24px;
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 25px;
      margin-bottom: 33.599999999999994px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 28px;
      margin-bottom: 39.375px;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 30px;
      margin-bottom: 52.5px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 34px;
      margin-bottom: 62.99999999999999px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 38px;
      margin-bottom: 74.8125px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .access-your-video_devicesTitle__3f0jB {
      font-size: 40px;
      margin-bottom: 84px;
    }
  }


  .access-your-video_deviceList__1hv5x {
    display: flex;
    justify-content: space-around;
  }
  
    @media only screen and (max-width: 767px) {
      .access-your-video_deviceNameList__169cv {
        display: none;
      }
    }

  .access-your-video_deviceItem__86bLc {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  @media only screen and (max-width: 767px) {
    .access-your-video_deviceItem__86bLc {
      margin-bottom: 24px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .access-your-video_deviceItem__86bLc {
      flex-basis: 33.33%;
    }
  }
  
  .access-your-video_deviceFrame__3Q661 {
    color: #fff;
    height: 180px;
    max-width: 200px;
    object-fit: contain;
    object-position: center bottom;
    position: relative;
    width: 100%;
  }
  
  .access-your-video_deviceCategory__1uqEJ {
    font-size: 23px;
    font-weight: 700;
    text-transform: uppercase;
    color: #CACACA;
  }
  
  @media only screen and (max-width: 1440px) {
    .access-your-video_deviceCategory__1uqEJ {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    .access-your-video_deviceCategory__1uqEJ {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 767px) {
    .access-your-video_deviceFrame__3Q661 {
      width: 100px;
    }
  }
  
  @media only screen and (max-width: 1440px) {
    .access-your-video_deviceCategory__1uqEJ {
      margin: 15.28125px 0 0 0;
    }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access-your-video_deviceCategory__1uqEJ {
      margin: 44.375px 0 35.625px 0;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access-your-video_deviceCategory__1uqEJ {
      margin: 53.25px 0 42.75px 0;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access-your-video_deviceCategory__1uqEJ {
      font-size: 26.9375px;
      margin: 63.234375px 0 50.765625px 0;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .access-your-video_deviceCategory__1uqEJ {
      font-size: 30px;
      margin: 71px 0 57px 0;
    }
  }
  
  .access-your-video_deviceNameList__169cv {
    position: relative;
  }
  
  .access-your-video_deviceName__v4gj1 {
    color: #CECECE;
    /* font-weight: 300; */
  }
  
  @media only screen and (max-width: 1199px) {
    .access-your-video_deviceName__v4gj1 {
      font-size: 19px;
      margin-bottom: 3px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access-your-video_deviceName__v4gj1 {
      font-size: 18.5px;
      margin-bottom: 3.33px;
    }
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access-your-video_deviceName__v4gj1 {
      font-size: 21.59375px;
      margin-bottom: 3.7px;
    }
  }
  
  @media only screen and (min-width: 1710px) {
    .access-your-video_deviceName__v4gj1 {
      font-size: 25px;
      margin-bottom: 4px;
    }
  }
  
.access-to-projector_accesswrapper__2Ptn0 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* max-height: auto; */
    /* position: fixed; */
}

.access-to-projector_leftPart__kcgUO {
    /* flex-grow: 1; */
    text-align: center;
    /* display: inline-block; */
    /* position:relative; */
    float: left;
    width: 50%;
}

.access-to-projector_rightPart__1iU0- {
    /* flex-grow: 1; */
    text-align: center;
    overflow-x: hidden;
    overflow-y: hidden;
    float: right;
    width: 50%;
}

@media only screen and (max-width: 767px) {
    .access-to-projector_leftPart__kcgUO {
      display: none;
    }
    .access-to-projector_rightPart__1iU0- {
        text-align: center;
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .access-to-projector_accessBox__qBYLn {
        max-width: 261px !important;
    height: 261px !important;
    }
    .access-to-projector_signUpButton__1lZGx {
        min-width: 160px !important;
    }
    .access-to-projector_yellowCap__3MCRb {
        left: 12% !important;
    }
}

@media only screen and (min-width: 768px) {
    .access-to-projector_signUpButton__1lZGx {
        min-width: 148px !important;
    }
}

.access-to-projector_accessBox__qBYLn {
    /* max-width: 400px; */
    /* min-height: 350px; */
    /* max-height: 300px; */
    background-color: #fff;
    border-radius: 50px;
    margin-top: 50%;
}

.access-to-projector_accessTitle__1o98d {
    color: #000;
    font-weight: 700;
    font-size: 30px;
    text-align: left !important;
    /* padding: 23px; */
}

.access-to-projector_signUpButton__1lZGx {
    color: #fff;
    text-transform: none;
    border-radius: 30px !important;
    border: 1px solid #707070;
    background-color: #0075FF;
    height: 51px !important;
    font-size: 19px !important;
    float: left;
    /* margin-left: 20px !important; */
    font-weight: 500 !important;
}

.access-to-projector_imageBox__AuEHS {
  display: inline-block;
  position:relative;
}

.access-to-projector_image__1Qgk3 {
    max-width: 90%;
    max-height: 100%;
    width: 90%;
    float: right;
    /* max-width: 100%; */
   
}

.access-to-projector_description__3NZvo {
    color: #000;
    font-size: 18px;
    text-align: left !important;
    /* padding: 0 23px; */
}

.access-to-projector_treeImage__2bVGX {
    position: absolute;
    right: 60%;
    top: 69%;
    width: 25%;
    max-width: 25%;
}

.access-to-projector_yellowSquare__SW2Cm {
    /* position: absolute; */
    right: 60%;
    top: 69%;
    width: 23%;
    max-width: 23%;
}

.access-to-projector_accessContainer__3maEN {
  display: inline-block;
  position:relative;
}

.access-to-projector_yellowCap__3MCRb {
    position: absolute;
    top: 36%;
    left: 44%;
}

@media only screen and (max-width: 767px) {
    .access-to-projector_accessBox__qBYLn {
        width: 292px;
        height: 227px;
        border-radius: 27px;
        padding: 23px;
      }
      .access-to-projector_yellowCap__3MCRb {
          left: 55% !important;
          width: 93% !important;
          top: 40%;
      }
      .access-to-projector_accessTitle__1o98d {
          font-size: 20px !important;
          padding-bottom: 0px;
      }
      .access-to-projector_description__3NZvo {
          font-size: 12px;
      }
      .access-to-projector_accessContainer__3maEN {
          margin-bottom: 7px;
      }
      .access-to-projector_signUpButton__1lZGx {
        height: 33px !important;
        font-size: 15px !important;
      }
      .access-to-projector_rightArrow__2TY4s {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .access-to-projector_accessBox__qBYLn {
        width: 292px;
        height: 227px;
        border-radius: 27px;
        padding: 23px;
      }
      .access-to-projector_yellowCap__3MCRb {
          left: 55% !important;
          width: 93% !important;
          top: 40%;
      }
      .access-to-projector_accessTitle__1o98d {
          font-size: 20px !important;
          padding-bottom: 0px;
      }
      .access-to-projector_description__3NZvo {
          font-size: 12px;
      }
      .access-to-projector_accessContainer__3maEN {
          margin-bottom: 7px;
      }
      .access-to-projector_signUpButton__1lZGx {
        height: 33px !important;
        font-size: 15px !important;
      }
      .access-to-projector_rightArrow__2TY4s {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .access-to-projector_accessBox__qBYLn {
        width: 330px;
        height: 265px;
        border-radius: 25px;
        padding: 20px;
      }
      .access-to-projector_yellowCap__3MCRb {
          left: 55% !important;
          width: 95% !important;
          top: 40%;
      }
      .access-to-projector_accessTitle__1o98d {
          font-size: 25px !important;
          padding-bottom: 0px;
      }
      .access-to-projector_description__3NZvo {
          font-size: 14px;
      }
      .access-to-projector_accessContainer__3maEN {
          margin-bottom: 55px;
      }
      .access-to-projector_signUpButton__1lZGx {
        height: 42px !important;
        font-size: 16px !important;
      }
      .access-to-projector_rightArrow__2TY4s {
        width: 10px !important;
        height: 10px !important;
        position: relative;
        top: 6px;
        left: 13px;
      }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .access-to-projector_accessBox__qBYLn {
        width: 395px;
        height: 320px;
        padding: 30px;
      }
      .access-to-projector_yellowCap__3MCRb {
          left: 45% !important;
      }
      .access-to-projector_accessTitle__1o98d {
          font-size: 30px !important;
          padding-bottom: 2px;
      }
      .access-to-projector_description__3NZvo {
          font-size: 17px;
      }
      .access-to-projector_accessContainer__3maEN {
          margin-bottom: 28px;
      }
      .access-to-projector_signUpButton__1lZGx {
          height: 43px !important;
          font-size: 18px !important;
      }
      .access-to-projector_rightArrow__2TY4s {
        position: relative;
        top: 6px;
        left: 16px;
    } 
  }
  
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .access-to-projector_accessBox__qBYLn {
        width: 487px;
        height: 390px;
        padding: 40px;
      }
      .access-to-projector_yellowCap__3MCRb {
          left: 55% !important;
      }
      .access-to-projector_accessTitle__1o98d {
          font-size: 37px !important;
      }
      .access-to-projector_description__3NZvo {
          font-size: 19px;
      }
      .access-to-projector_accessContainer__3maEN {
          margin-bottom: 28px;
      }
      .access-to-projector_rightArrow__2TY4s {
        position: relative;
        top: 7px;
        left: 16px;
    } 
  }
  
  @media only screen and (min-width: 1710px) {
    .access-to-projector_accessBox__qBYLn {
      width: 500px;
      height: 400px;
      padding: 50px;
    }
    .access-to-projector_yellowCap__3MCRb {
        left: 54% !important;
    }
    .access-to-projector_accessTitle__1o98d {
        font-size: 40px !important;
    }
    .access-to-projector_description__3NZvo {
        font-size: 19px;
    }
    .access-to-projector_rightArrow__2TY4s {
        position: relative;
        top: 6px;
        left: 16px;
    } 
  }

.access-to-projector_signUpButtonWrapper__2Tm2i {
    display: inline-flex;
}

.access-to-projector_rightArrow__2TY4s {
    width: 12px;
    height: 12px;
}
.landingPage_reactPlayerWrapper__3PtBo {
    height: 70vh;
}

.landingPage_reactPlayerWrapper__3PtBo video {
    object-fit: fill;
}

.landingPage_playButton__OS658 {
    position: absolute;
    top: 50%;
    right: 50%;
    font-size: x-large !important;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    height: 3em !important;
    width: 3em !important;
    z-index: 2;
    color: gray;
}

.landingPage_openButton__TItsm {
    color: white !important;
    border-radius: 30px !important;
    text-decoration: none;
    text-transform: none !important;
}

.landingPage_logoMini__2Jlnb{
    width:24px;
    height: 24px;
}

.landingPage_openAppTitle__1Vwbo {
    font-size: 14px !important;
    text-align: left;
    font-weight: 600 !important;
    color: white !important;
}

.landingPage_openAppCaption__-pGs2 {
    font-size: 12px !important;
    text-align: left;
    color: rgb(184, 182, 182);
}

@media (max-width: 600px) {
    .landingPage_openInAppContainer__CUg7g {
        display: flex !important;
    }
}
.plansCard_plan__1VQbI {
    padding: 30px;
    border-radius: 15px;
    color: #1D263A;
    background-color: #fff;
    transition: all 0.5s;
}
.plansCard_tickster__3FZ6u{
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
.plansCard_feat__1gUCL{
    font-size: 14px;
    font-weight: 600
  }
.plansCard_plan__1VQbI:hover {
    -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
    transition: all 0.5s;
}

.plansCard_plan-blue__1J3on {
    color: #fff;
    background-color: #0075FF;
}

.plansCard_planHead__17-DZ {
    display: flex;
    justify-content: space-between;
    align-items: start;
    /* padding-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10); */
    font-family: Montserrat-Regular;
}

.plansCard_planName__2tbPl p {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.plansCard_planPrice__3m627 p {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.plansCard_planPrice__3m627 {
    text-align: right;
}

.plansCard_planName__2tbPl span,
.plansCard_planPrice__3m627 span {
    font-family: "Montserrat" !important;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.plansCard_planBody__3BQ27 {
    width: 100%;
    padding: 25px 0;
    margin: 0;
    font-family: Montserrat-Regular;
}

.plansCard_planBody__3BQ27 li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    list-style: none;
    margin-bottom: 10px;
    grid-gap: 10px;
    grid-gap: 0;
    grid-gap: 0;
    gap: 0;
    width: 160px;
}
.plansCard_buyNow__2YKrS:hover{
    box-shadow: 0 0 10px black !important;
  }
  .plansCard_buyNow__2YKrS{
    width: 100% !important;
    display: flex !important;;
    margin: 10px 0 0 0 !important;
    padding: 10px 28px !important;
    height: auto !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: none !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 35px !important;
    font-size: 15px !important;
    text-align: center !important;
    padding: 8px 28px !important;
    color: black !important;
    text-decoration: none !important;
    border: 1px solid black !important;
    background-color: transparent !important;
    transition: all 0.3s !important;
    cursor: pointer !important;
  }
.plansCard_trail__26Hzu:hover{
    box-shadow: 0 0 10px #002E9A;
    background-image: linear-gradient(90deg, #0185FF 0, #0139F7);
  }
  .plansCard_trail__26Hzu{
    width: 100% !important;
    display: flex !important;;
    margin: 10px 0 0 0 !important;
    padding: 10px 28px !important;
    height: auto !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: "Montserrat" !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border-radius: 35px !important;
    font-size: 15px !important;
    text-align: center !important;
    padding: 8px 28px !important;
    color: white !important;
    text-decoration: none !important;
    background-image: linear-gradient(90deg, #0185FF 0, #0139F7);
    transition: all 0.3s !important;
    cursor: pointer !important;
  }
.plansCard_planBtn__1kD44 {
    width: 100%;
    display: block;
    margin: 0;
    padding: 10px 28px;
    background: #0075FF;
    box-shadow: none;
    border-radius: 35px;
    font-weight: 400;
    font-size: 15px;
    color: white;
    text-align: center;
    text-transform: none;
}

@media only screen and (max-width: 1024px) {
    .plansCard_planName__2tbPl p {
        font-size: 22px !important;
    }

    .plansCard_planPrice__3m627 p {
        font-size: 28px !important;
    }
    
}
@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url(/static/media/HelveticaNeue-Bold.61acdee6.ttf);
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url(/static/media/Montserrat-Regular.ac98a5f5.ttf);
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url(/static/media/Montserrat-Medium.3a091734.ttf);
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(/static/media/Montserrat-Bold.63fae500.ttf);
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(/static/media/Montserrat-SemiBold.04af9a9e.ttf);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular;
  font-size: 18px;
  /* color: #fff; */
  overflow-x: hidden;
  background: radial-gradient(#222634, #07080c) no-repeat center;
}

.section {
  padding-top: 50px;
}

.container {
  width: 1320px;
  padding: 0 15px;
  margin: auto;
}

.title {
  font-family: Montserrat-Bold;
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
  color: #fff;
}

.subtitle {
  width: 650px;
  max-width: 100%;
  margin: 0 auto 100px auto;
  line-height: 32px;
  text-align: center;
  font-size: 18px;
  opacity: 0.75;
  font-family: Montserrat-Regular;
  color: #fff;
  margin-bottom: 10px;
}

.MuiCircularProgress-colorPrimary {
  margin: 0 auto;
  display: flex;
}
.external-header {
  width: 100%;
  height: 600px;
  font-family: Montserrat-Bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url(/static/media/header.a7a9a28f.png) center no-repeat;
  background-size: cover;
}

.btn {
  border-radius: 35px;
  font-size: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 8px 28px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.3s;
}

h1 {
  font-size: 45px;
  text-transform: uppercase;
}

.header-contents span {
  font-size: 35px;
}

.header-contents img {
  height: 15px;
  width: 15px;
  margin: 0 20px;
}

.header-contents .btn {
  font-family: Montserrat-SemiBold;
  display: block;
  font-size: 22px;
  border-radius: 55px;
  padding: 25px 60px;
  margin-top: 10%;
}

.header-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

nav {
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  padding: 15px 90px;
  place-content: center space-between;
}

.logo {
  width: 200px;
  margin-top: 20px;
}

.help-btn {
  font-family: Montserrat-Medium;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  line-height: 20px;
  text-decoration: none;
}

.help-btn img {
  height: 18px;
  margin-right: 6px;
}

.signup-btns {
  font-family: HelveticaNeue-Bold;
  margin-top: 25px;
}

.transparent:hover {
  color: #555;
  background-color: #fff;
  transition: all 0.3s;
}

.blue {
  border-color: transparent;
  border-style: none;
  box-shadow: 0 0 6px #002e9a;
  background-image: linear-gradient(90deg, #0185ff 0, #0139f7);
}

.blue:hover {
  box-shadow: 0 0 20px #002e9a;
}

.white {
  color: #1787fc;
  background-color: #fff;
}

.white:hover {
  box-shadow: 0 0 20px #fff;
}

.platforms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
}

.platforms img {
  max-height: 60px;
}

.platforms img:first-child,
.platforms img:last-child {
  /* height: 40px; */
}

.col {
  width: calc(100% / 3);
  margin: 0 15px;
}
.clearWidfth {
  width: 290px;
  margin: 0 15px;
}
.how-to-use {
  color: #fff;
}

.plans,
.how-to-use,
.access {
  display: flex;
  place-content: center;
}

.plan {
  padding: 16px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
  transition: all 0.5s;
}

.plan:hover {
  -webkit-transform: translate(0, -10px);
          transform: translate(0, -10px);
  transition: all 0.5s;
}

.plan-blue {
  color: #fff;
  background-color: #0075ff;
}

.plan-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.plan-name p,
.plan-price p {
  margin: 0;
  font-size: 24px;
}

.plan-price {
  text-align: right;
}

.plan-name span,
.plan-price span {
  font-size: 13px;
  opacity: 0.7;
}

.plan-body {
  width: 100%;
  padding: 25px 0;
  margin: 0;
}

.plan-body li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  list-style: none;
  margin-bottom: 10px;
}

.plan .btn {
  width: 100%;
  display: block;
  margin: 0;
  padding: 10px 28px;
}

.plan .blue {
  background: #0075ff;
  box-shadow: none;
}

.how-to-use,
.access {
  text-align: center;
}

.title-margin {
  margin-bottom: 50px;
}

.how-to-use .col img {
  height: 250px;
  max-width: 100%;
}

.how-to-use .col div,
.access .col div {
  font-family: Montserrat-Bold;
  font-size: 30px;
  margin: 10px 0 30px;
}

.how-to-use .col p,
.access .col p {
  font-size: 18px;
  margin: 0;
  opacity: 0.7;
}

.access-title {
  font-size: 35px;
  color: #fff;
}

.start-streaming {
  color: #fff;
  font-size: 55px !important;
}

.view-share-preserve {
  color: #fff;
}

.access .col img {
  width: 120px;
}

.access .col div {
  text-transform: uppercase;
  font-size: 25px;
}

.access .col div,
.access .col p {
  color: #cacaca;
  opacity: 1;
}

.access .col p {
  line-height: 25px;
}

footer {
  padding-top: 100px;
}

footer .row {
  display: flex;
  place-content: center;
}

footer .col {
  width: 40%;
  padding: 0 15px;
}

footer .col:first-child {
  width: 60%;
}

footer .col img {
  max-width: 100%;
}

.footer-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.footer-content .content {
  position: relative;
  padding: 40px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 72px 108px rgba(23, 135, 252, 0.12);
}

.footer-content .content div {
  font-family: Montserrat-Bold;
  color: #213053;
  font-size: 35px;
}

.footer-content .content p {
  color: #000;
  font-size: 16px;
}

.footer-content .content img {
  height: 350px;
  position: absolute;
  top: 70px;
  right: -170px;
  z-index: 1;
  -webkit-animation: cube 6s infinite;
          animation: cube 6s infinite;
}

.footer-content .content .btn {
  width: 165px;
  display: block;
  margin: 50px auto 0 auto;
  border-radius: 10px;
  padding: 15px 30px;
  box-shadow: 0 26px 92px rgba(0, 117, 255, 0.5);
  background: #0075ff;
  font-family: Montserrat-Regular;
}

@-webkit-keyframes cube {
  0% {
    -webkit-transform: translateY(-80px) rotateZ(-25deg);
            transform: translateY(-80px) rotateZ(-25deg);
  }

  50% {
    -webkit-transform: translateY(0px) rotateZ(0deg);
            transform: translateY(0px) rotateZ(0deg);
  }

  100% {
    -webkit-transform: translateY(-80px) rotateZ(-25deg);
            transform: translateY(-80px) rotateZ(-25deg);
  }
}

@keyframes cube {
  0% {
    -webkit-transform: translateY(-80px) rotateZ(-25deg);
            transform: translateY(-80px) rotateZ(-25deg);
  }

  50% {
    -webkit-transform: translateY(0px) rotateZ(0deg);
            transform: translateY(0px) rotateZ(0deg);
  }

  100% {
    -webkit-transform: translateY(-80px) rotateZ(-25deg);
            transform: translateY(-80px) rotateZ(-25deg);
  }
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  padding: 30px 30px 10px;
  margin-top: 80px;
}

.footer-bottom div {
  display: flex;
  align-items: center;
}

.footer-bottom img {
  height: 32px;
}

.footer-bottom p {
  font-size: 11px;
  margin-left: 15px;
  color: #fff;
  white-space: nowrap;
}

.footer-bottom ul {
  padding: 0;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
}

.footer-bottom ul li {
  display: inline-block;
  margin-left: 14px;
  list-style: none;
  font-size: 11px;
}

.footer-bottom ul li a {
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
}

.footer-bottom ul li a:hover {
  color: #0075ff;
  transition: all 0.3s;
}

/* Small devices (landscape phones, 576px and up) */

/* @media (min-width: 576px) { */

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
  .start-streaming {
    color: #fff;
    font-size: 40px !important;
  }
  .col {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .hide {
    display: none;
  }

  .platforms {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .platforms img {
    padding: 5px;
  }

  .platforms .google-cast {
    height: 25px !important;
    width: 105px;
    margin: auto 0 auto 0;
  }

  .google-cast-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container {
    width: 100%;
  }

  nav {
    padding: 0px;
  }

  footer .col {
    width: 50%;
  }

  .footer-content .content div {
    font-size: 30px;
  }

  .footer-bottom {
    flex-direction: column-reverse;
    padding: 30px 15px 10px;
  }

  .platforms img {
    /* height: 35px !important; */
    /* max-width: 130px !important; */
  }

  .plan {
    padding: 20px;
  }

  .plan-name p,
  .plan-price p {
    font-size: 18px;
  }

  .plan-body li {
    font-size: 14px;
  }

  .how-to-use .col img {
    height: 200px;
  }

  .access-title {
    font-size: 25px;
  }

  .how-to-use .col div,
  .access .col div {
    font-size: 20px;
  }

  .how-to-use .col p,
  .access .col p {
    font-size: 16px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }

  .footer-bottom ul {
    padding: 0 120px 0 0;
  }
}

@media (max-width: 463px) {
  .signup-btns .btn {
    padding: 10px 20px !important;
    border-radius: 20px;
    font-size: 14px;
  }

  .header-contents .btn {
    font-size: 12px !important;
    padding: 12px 26px !important;
    width: 70%;
  }

  .pricing .subtitle {
    font-size: 13px;
  }
}

.pricing .subtitle {
  padding: 0 25px 0 25px;
}

@media (max-width: 600px) {
  .logo {
    width: 50px;
    margin-top: 15px;
  }

  h1 {
    font-size: 32px;
  }

  .header-contents span {
    font-size: 20px;
  }

  .header-contents .btn {
    font-size: 13px;
    padding: 15px 26px;
  }

  .store-icons img {
    height: 50px !important;
  }

  .platforms .play-icon {
    /* margin-left: 10px; */
  }

  /* .platforms{
        justify-content: space-around;
    } */

  .footer-bottom ul {
    justify-content: center;
  }

  .title {
    font-size: 30px;
  }

  .plans {
    flex-direction: column;
    padding: 0 30px;
  }

  .how-to-use {
    flex-direction: column;
    padding: 0 25px;
  }

  .plan {
    width: 85%;
    margin: 30px auto 0;
  }

  .how-to-use .col:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .how-to-use .col:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .how-to-use .col:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  /* .how-to-use .col{
        text-align: left;
        margin-top: 30px;
    } */

  /* .how-to-use .col:nth-child(2){
        text-align: right;
    } */

  /* .how-to-use .col:nth-child(1) div{
        padding-left: 20px;
    }
    
    .how-to-use .col:nth-child(2) div p{
        padding-right: 20px;
    }

    .how-to-use .col:nth-child(3) div p{
        padding-left: 50px;
    } */

  footer .col {
    width: 100%;
  }

  .access .col img {
    width: 70px;
  }
}

@media (max-width: 463px) {
  .mob-spacing {
    margin: 0 3px !important;
  }

  .footer-bottom ul li {
    margin-left: 14px;
    text-align: center;
    justify-content: center;
  }
}

.how-to-use div {
  font-size: 32px !important;
}

.platforms img {
  height: 35px;
}

.mobile-verification_backLink__1wvY1 {
  color: #888888;
  position: absolute;
  cursor: pointer;
}

.mobile-verification_signInLink__y5u2Z {
  color: #888888;
  position: absolute;
}

@media all and (hover: hover) {
  .mobile-verification_backLink__1wvY1:hover {
    color: #fff;
    text-decoration: none;
  }
  .mobile-verification_signInLink__y5u2Z:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .mobile-verification_backLink__1wvY1:active, .mobile-verification_backLink__1wvY1:target {
    color: #fff;
    text-decoration: none;
  }
  .mobile-verification_signInLink__y5u2Z:active, .mobile-verification_signInLink__y5u2Z:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-verification_backLink__1wvY1 {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    left: 18px;
    top: 18px;
  }
  .mobile-verification_signInLink__y5u2Z {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
    right: 18px;
    top: 18px;
  }
}

@media only screen and (min-width: 767px) {
  .mobile-verification_backLink__1wvY1 {
    font-weight: 600;
  }
  .mobile-verification_signInLink__y5u2Z {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .mobile-verification_backLink__1wvY1 {
    font-size: 15.625px !important;
    left: 50px !important;
    top: 50px !important;
  }
  .mobile-verification_signInLink__y5u2Z {
    font-size: 15.625px !important;
    right: 50px !important;
    top: 50px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .mobile-verification_backLink__1wvY1 {
    font-size: 18.75px;
    left: 60px;
    top: 60px;
  }
  .mobile-verification_signInLink__y5u2Z {
    font-size: 18.75px;
    right: 60px;
    top: 60px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .mobile-verification_backLink__1wvY1 {
    font-size: 22.265625px;
    left: 71.25px;
    top: 71.25px;
  }
  .mobile-verification_signInLink__y5u2Z {
    font-size: 22.265625px;
    right: 71.25px;
    top: 71.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .mobile-verification_backLink__1wvY1 {
    font-size: 25px;
    left: 80px;
    top: 80px;
  }
  .mobile-verification_signInLink__y5u2Z {
    font-size: 25px;
    right: 80px;
    top: 80px;
  }
}

.mobile-verification_inputLabel__2_bIl {
  color: #ffffff;
  display: block;
  font-weight: bold;
  position: relative;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .mobile-verification_inputLabel__2_bIl {
    font-size: 16px;
    margin-bottom: 13.59375px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .mobile-verification_inputLabel__2_bIl {
    font-size: 21.875px;
    margin-bottom: 18.125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .mobile-verification_inputLabel__2_bIl {
    font-size: 26.25px;
    margin-bottom: 21.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .mobile-verification_inputLabel__2_bIl {
    font-size: 31.171875000000004px;
    margin-bottom: 25.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .mobile-verification_inputLabel__2_bIl {
    font-size: 35px;
    margin-bottom: 29px;
  }
}

.mobile-verification_description__2DaCg {
  color: #888888;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.mobile-verification_expireTime__NNtCF {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.mobile-verification_buttonBlock__2g6-8 {
  display: flex;
  justify-content: center;
  
}

.mobile-verification_alignForm__1P70E {
  margin-top: -30px;
}

.mobile-verification_skipDiv__39iUF {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.mobile-verification_resendDiv__24G_F {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}

.mobile-verification_resendDiv__24G_F button {
  width: 200px;
  height: 50px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
}

.mobile-verification_resendDiv__24G_F button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.mobile-verification_otpInput__JNq5v {
  width: 55px;
  height: 50px;
  border-radius: 10px;
}

@media only screen and (max-width: 419px) {
  .mobile-verification_otpInputs__1rtlw input[type=tel] {
    width: 45px !important;
    height: 45px !important;
  }
}


.newMobile-verification_backLink__3qxJs {
    color: #888888;
    position: absolute;
    cursor: pointer;
}

.newMobile-verification_signInLink__2S5s0 {
    color: #888888;
    position: absolute;
}

@media all and (hover: hover) {
    .newMobile-verification_backLink__3qxJs:hover {
        color: #fff;
        text-decoration: none;
    }

    .newMobile-verification_signInLink__2S5s0:hover {
        color: #fff;
        text-decoration: none;
    }
}

@media all and (pointer: coarse) {

    .newMobile-verification_backLink__3qxJs:active,
    .newMobile-verification_backLink__3qxJs:target {
        color: #fff;
        text-decoration: none;
    }

    .newMobile-verification_signInLink__2S5s0:active,
    .newMobile-verification_signInLink__2S5s0:target {
        color: #fff;
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .newMobile-verification_backLink__3qxJs {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        left: 18px;
        top: 18px;
    }

    .newMobile-verification_signInLink__2S5s0 {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        right: 18px;
        top: 18px;
    }
}

@media only screen and (min-width: 767px) {
    .newMobile-verification_backLink__3qxJs {
        font-weight: 600;
    }

    .newMobile-verification_signInLink__2S5s0 {
        font-weight: 600;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newMobile-verification_backLink__3qxJs {
        font-size: 15.625px !important;
        left: 50px !important;
        top: 50px !important;
    }

    .newMobile-verification_signInLink__2S5s0 {
        font-size: 15.625px !important;
        right: 50px !important;
        top: 50px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newMobile-verification_backLink__3qxJs {
        font-size: 18.75px;
        left: 60px;
        top: 60px;
    }

    .newMobile-verification_signInLink__2S5s0 {
        font-size: 18.75px;
        right: 60px;
        top: 60px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newMobile-verification_backLink__3qxJs {
        font-size: 22.265625px;
        left: 71.25px;
        top: 71.25px;
    }

    .newMobile-verification_signInLink__2S5s0 {
        font-size: 22.265625px;
        right: 71.25px;
        top: 71.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newMobile-verification_backLink__3qxJs {
        font-size: 25px;
        left: 80px;
        top: 80px;
    }

    .newMobile-verification_signInLink__2S5s0 {
        font-size: 25px;
        right: 80px;
        top: 80px;
    }
}

.newMobile-verification_inputLabel__xvHsJ {
    color: #ffffff;
    display: block;
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .newMobile-verification_inputLabel__xvHsJ {
        font-size: 16px;
        margin-bottom: 13.59375px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .newMobile-verification_inputLabel__xvHsJ {
        font-size: 21.875px;
        margin-bottom: 18.125px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .newMobile-verification_inputLabel__xvHsJ {
        font-size: 26.25px;
        margin-bottom: 21.75px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newMobile-verification_inputLabel__xvHsJ {
        font-size: 31.171875000000004px;
        margin-bottom: 25.828125px;
    }
}

@media only screen and (min-width: 1710px) {
    .newMobile-verification_inputLabel__xvHsJ {
        font-size: 35px;
        margin-bottom: 29px;
    }
}

.newMobile-verification_description__1tYFs {
    color: #888888;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.newMobile-verification_expireTime__2d-Hm {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.newMobile-verification_buttonBlock__19FDJ {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.newMobile-verification_phoneButtonBlock__40mN1 {
    display: flex;
    justify-content: center;
}

.newMobile-verification_alignForm__3z75s {
    margin-top: -30px;
}

.newMobile-verification_skipDiv__wxDmt {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.newMobile-verification_resendDiv__I-_KD {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
}

.newMobile-verification_resendDiv__I-_KD button {
    width: 200px;
    height: 50px;
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}

.newMobile-verification_resendDiv__I-_KD button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.newMobile-verification_otpInput__1652_ {
    width: 55px;
    height: 50px;
    border-radius: 10px;
}

@media only screen and (max-width: 419px) {
    .newMobile-verification_otpInputs__2gVcq input[type=tel] {
        width: 45px !important;
        height: 45px !important;
    }
}
.newSign-in_forgotPasswordLink__2gNLe {
    color: #808080 !important;
}

.newSign-in_newsletter__u7vUq {
    align-items: center;
    display: flex;
    line-height: 1.2;
}

@media only screen and (max-width: 767px) {
    .newSign-in_newsletter__u7vUq {
        margin: 16px 8px 24px 0;
    }
}

@media only screen and (min-width: 768px) {
    .newSign-in_newsletter__u7vUq {
        margin: 16px 8px 32px 4px;
    }
}

@media only screen and (max-width: 899px) {
    .newSign-in_newsletter__u7vUq .MuiSvgIcon-root {
        height: 25.625px;
        width: 25.625px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-in_newsletter__u7vUq .MuiSvgIcon-root {
        height: 36.515625px !important;
        width: 36.515625px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-in_newsletter__u7vUq .MuiSvgIcon-root {
        height: 30.75px;
        width: 30.75px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-in_newsletter__u7vUq .MuiSvgIcon-root {
        height: 36.515625px;
        width: 36.515625px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-in_newsletter__u7vUq .MuiSvgIcon-root {
        height: 41px;
        width: 41px;
    }
}

.newSign-in_newsletterCheckbox__3X3KV {
    border-radius: 50% !important;
    color: #5aa5ef !important;
}

.newSign-in_newsletterText__2WK2V {
    color: #eee;
    cursor: pointer;
    line-height: 1.25;
    margin-bottom: 0;
}

@media only screen and (max-width: 899px) {
    .newSign-in_newsletterText__2WK2V {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-in_newsletterText__2WK2V {
        font-size: 13.5px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-in_newsletterText__2WK2V {
        font-size: 13.5px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-in_newsletterText__2WK2V {
        font-size: 16.03125px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-in_newsletterText__2WK2V {
        font-size: 18px;
    }
}

.newSign-in_termsContainer__2dPlc {
    background-color: #31343e;
}

@media only screen and (max-width: 899px) {
    .newSign-in_termsContainer__2dPlc {
        border-radius: 6.25px;
        padding: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-in_termsContainer__2dPlc {
        border-radius: 6.25px !important;
        padding: 10px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-in_termsContainer__2dPlc {
        border-radius: 7.5px;
        padding: 12px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-in_termsContainer__2dPlc {
        border-radius: 8.90625px;
        padding: 14.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-in_termsContainer__2dPlc {
        border-radius: 10px;
        padding: 16px;
    }
}

.newSign-in_terms__25STX {
    color: #eee;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.8;
}

@media only screen and (max-width: 899px) {
    .newSign-in_terms__25STX {
        font-size: 11px;
        line-height: 1.5 !important;
        margin-bottom: 27.2px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-in_terms__25STX {
        margin-bottom: 13.6px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-in_terms__25STX {
        font-size: 12px;
        margin-bottom: 25.5px;
        padding: 12px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-in_terms__25STX {
        font-size: 14.25px;
        margin-bottom: 30.28125px;
        padding: 14.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-in_terms__25STX {
        font-size: 16px;
        margin-bottom: 34px;
        padding: 16px;
    }
}

.newSign-in_nameFields__1xjrN {
    display: flex;
}

.newSign-in_nameFields__1xjrN>*:first-child {
    margin-right: 10px;
}

.newSign-in_backLink__2ujWH {
    color: #888888;
    position: absolute;
    cursor: pointer;
}

@media all and (hover: hover) {
    .newSign-in_backLink__2ujWH:hover {
        color: #fff;
        text-decoration: none;
    }
}

@media all and (pointer: coarse) {

    .newSign-in_backLink__2ujWH:active,
    .newSign-in_backLink__2ujWH:target {
        color: #fff;
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .newSign-in_backLink__2ujWH {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        left: 18px;
        top: 18px;
    }
}

@media only screen and (min-width: 768px) {
    .newSign-in_backLink__2ujWH {
        font-weight: 600;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .newSign-in_backLink__2ujWH {
        font-size: 15.625px !important;
        left: 50px !important;
        top: 50px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .newSign-in_backLink__2ujWH {
        font-size: 18.75px;
        left: 60px;
        top: 60px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .newSign-in_backLink__2ujWH {
        font-size: 22.265625px;
        left: 71.25px;
        top: 71.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .newSign-in_backLink__2ujWH {
        font-size: 25px;
        left: 80px;
        top: 80px;
    }
}

.newSign-in_reCaptchaContainer__1ibi7 {
    /* display: flex;
    justify-content: center; */
    margin-bottom: 40px;
    margin-left: 18px;
}

.newSign-in_customUserInput__3afNN {
    font-size: 20px !important;
}
.OTPInput_otpinput__3plsT {
    width: 50px;
    height: 55px;
    border-radius: 10px;
    background-color: #31343e;
    border: '2px solid #6d6f76';
    color: '#ffffff';
    font-size: '20px';
    font-weight: '700';
    text-align: 'center';
}
.verificationCode_backLink__2zQlt {
    color: #888888;
    position: absolute;
    cursor: pointer;
}

.verificationCode_signInLink__26Jok {
    color: #888888;
    position: absolute;
}

@media all and (hover: hover) {
    .verificationCode_backLink__2zQlt:hover {
        color: #fff;
        text-decoration: none;
    }

    .verificationCode_signInLink__26Jok:hover {
        color: #fff;
        text-decoration: none;
    }
}

@media all and (pointer: coarse) {

    .verificationCode_backLink__2zQlt:active,
    .verificationCode_backLink__2zQlt:target {
        color: #fff;
        text-decoration: none;
    }

    .verificationCode_signInLink__26Jok:active,
    .verificationCode_signInLink__26Jok:target {
        color: #fff;
        text-decoration: none;
    }
}

@media only screen and (max-width: 767px) {
    .verificationCode_backLink__2zQlt {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        left: 18px;
        top: 18px;
    }

    .verificationCode_signInLink__26Jok {
        border: 1px solid #707070;
        border-radius: 5px;
        font-weight: 500;
        padding: 6px 12px;
        right: 18px;
        top: 18px;
    }
}

@media only screen and (min-width: 767px) {
    .verificationCode_backLink__2zQlt {
        font-weight: 600;
    }

    .verificationCode_signInLink__26Jok {
        font-weight: 600;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .verificationCode_backLink__2zQlt {
        font-size: 15.625px !important;
        left: 50px !important;
        top: 50px !important;
    }

    .verificationCode_signInLink__26Jok {
        font-size: 15.625px !important;
        right: 50px !important;
        top: 50px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
    .verificationCode_backLink__2zQlt {
        font-size: 18.75px;
        left: 60px;
        top: 60px;
    }

    .verificationCode_signInLink__26Jok {
        font-size: 18.75px;
        right: 60px;
        top: 60px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .verificationCode_backLink__2zQlt {
        font-size: 22.265625px;
        left: 71.25px;
        top: 71.25px;
    }

    .verificationCode_signInLink__26Jok {
        font-size: 22.265625px;
        right: 71.25px;
        top: 71.25px;
    }
}

@media only screen and (min-width: 1710px) {
    .verificationCode_backLink__2zQlt {
        font-size: 25px;
        left: 80px;
        top: 80px;
    }

    .verificationCode_signInLink__26Jok {
        font-size: 25px;
        right: 80px;
        top: 80px;
    }
}

.verificationCode_inputLabel__1UAZ5 {
    color: #ffffff;
    display: block;
    font-weight: bold;
    position: relative;
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .verificationCode_inputLabel__1UAZ5 {
        font-size: 16px;
        margin-bottom: 13.59375px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .verificationCode_inputLabel__1UAZ5 {
        font-size: 21.875px;
        margin-bottom: 18.125px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .verificationCode_inputLabel__1UAZ5 {
        font-size: 26.25px;
        margin-bottom: 21.75px;
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .verificationCode_inputLabel__1UAZ5 {
        font-size: 31.171875000000004px;
        margin-bottom: 25.828125px;
    }
}

@media only screen and (min-width: 1710px) {
    .verificationCode_inputLabel__1UAZ5 {
        font-size: 35px;
        margin-bottom: 29px;
    }
}

.verificationCode_description__2UMCC {
    color: #888888;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.verificationCode_expireTime__BId-J {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    font-weight: 600;
}

.verificationCode_buttonBlock__1fD_w {
    display: flex;
    justify-content: center;

}

.verificationCode_alignForm__3Onlt {
    margin-top: -30px;
}

.verificationCode_skipDiv__YN3TT {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.verificationCode_resendDiv__2jHng {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
}

.verificationCode_resendDiv__2jHng button {
    width: 200px;
    height: 50px;
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
}

.verificationCode_resendDiv__2jHng button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.verificationCode_otpInput__29_7i {
    width: 55px;
    height: 50px;
    border-radius: 10px;
}

@media only screen and (max-width: 419px) {
    .verificationCode_otpInputs__2H1dy input[type=tel] {
        width: 45px !important;
        height: 45px !important;
    }
}

.verificationCode_codeInput__1CAYc {
    width: 50px;
    height: 55px;
    border-radius: 10px;
    background-color: #31343e;
    border: 2px solid #6d6f76;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    /*Firefox*/
    -moz-appearance: textfield;

}

.verificationCode_codeInput__1CAYc::-webkit-outer-spin-button,
.verificationCode_codeInput__1CAYc::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.rectangle {
  width: 70%;
  height: 100px;
  background: radial-gradient(#222634, #07080c) no-repeat center;
  margin: auto;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.text {
  color: #FFFFFF;
  position: absolute;
  margin: auto;
}
.carousal {
  margin-left: 6%;
  margin-right: 6%;
}

.carousel-container {
  height: 100% !important;
}
/* .react-multi-carousel-list{
  justify-content: center;
} */
.carousal .react-multi-carousel-track {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: start;
  grid-gap: 20px 15px !important;
  gap: 20px 15px !important;
  margin: 0 auto;
}
/* .carousal .react-multi-carousel-track {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  grid-gap: 20px 15px !important;
  gap: 20px 15px !important;
  margin: 0 auto;
}
@media screen and (max-width: 780px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr 1fr;
  }
} */
@media screen and (max-width: 480px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr ;
  justify-content: center;
  /* margin: 0 auto; */
  }
}
@media only screen and (max-width: 1300px) and (min-width: 481px) {
  .carousal .react-multi-carousel-track {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  }
}
.carousal .react-multiple-carousel__arrow--right {
  display: none;
}
.carousal .react-multiple-carousel__arrow--right .image {
    width: 100%;
    /* height: 100%; */
    margin: auto;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .7;
    display: flex;
    align-items: center;
    justify-content: center;

}
/* .react-multiple-carousel__arrow--left {
  display: none;
} */
.innerpage_rectangle__bl66d {
  width: 70%;
  height: 200px;
  background: radial-gradient(#222634, #07080c) no-repeat center;
  margin: auto;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.innerpage_image__1sw71 {
  margin: auto;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.innerpage_titleText__2Q--3 {
  color: #FFFFFF;
  position: absolute;
  text-transform: capitalize;
}
.innerpage_carousal__1S6h5 {
  margin-left: 70px;
  margin-top: -50px;
}


@media only screen and (max-width: 767px) {
  .Trail_logoImage__1-iGv {
    height: 52px !important;
  }
}
.Trail_logoDiv__z7_b7 {
  margin-bottom: 44px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.Trail_logoImage__1-iGv {
  height: 106px;
}
.Trail_flexico__2n-Dk {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}
.Trail_each__3nTmz {
  max-width: 444px;
  display: block; /* Ensure block-level behavior */
}

.Trail_each__3nTmz > img {
  display: block; /* Ensure the image is treated as a block element */
  margin: 0 auto; /* Center the image horizontally */
}
.Trail_headx__1xMPN {
  font-family: "Montserrat" !important;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  color: white;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.Trail_marginTop44__3VhjR{
  margin-top: 44px;
}
.Trail_subheadx__nLHp5 {
  font-family: "Montserrat" !important;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  white-space: pre-wrap;
  color: #808080;
  margin-bottom: 47px;
}
@media only screen and (min-width: 768px) {
  .Trail_logo__3OQCq {
    margin-bottom: 64px;
    margin-top: 24px;
  }
}

.Trail_authPage__1dzfX {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  min-height: 100vh;
  position: relative;
  width: 100%;
  padding: 40px;
}

.Trail_authPage__1dzfX,
.Trail_authPage__1dzfX * {
  font-family: "Montserrat" !important;
}
.Trail_backLink__159aH {
  color: #888888;
  position: absolute;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .Trail_marginTop44__3VhjR{
    margin-top: 30px;
  }
  .Trail_logo__3OQCq {
    margin-bottom: 17px;
    margin-top: 77px;
  }
  .Trail_authPage__1dzfX {
    padding: 20px 20px 100px 20px;
  }
  .Trail_flexico__2n-Dk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    width: 100%;
  }
  .Trail_each__3nTmz {
    max-width: 430px;
    display: block; /* Ensure block-level behavior */
  }
  .Trail_each__3nTmz > img {
    width: 105px;
  }
  .Trail_headx__1xMPN {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 19px;
  }
  .Trail_subheadx__nLHp5 {
    font-size: 13px;
    text-align: center;
    color: #808080;
    margin-bottom: 25px;
  }
}
@media all and (hover: hover) {
  .Trail_backLink__159aH:hover {
    color: #fff;
    text-decoration: none;
  }
}

@media all and (pointer: coarse) {
  .Trail_backLink__159aH:active,
  .Trail_backLink__159aH:target {
    color: #fff;
    text-decoration: none;
  }
}

@media only screen and (max-width: 767px) {
  .Trail_backLink__159aH {
    border: 1px solid #707070;
    border-radius: 5px;
    font-weight: 500;
    padding: 6px 12px;
  }
}

@media only screen and (min-width: 768px) {
  .Trail_backLink__159aH {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .Trail_backLink__159aH {
    font-size: 15.625px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .Trail_backLink__159aH {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .Trail_backLink__159aH {
    font-size: 22.265625px;
  }
}

@media only screen and (min-width: 1710px) {
  .Trail_backLink__159aH {
    font-size: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .Trail_authPageContent__SvpcV {
    max-width: 600px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .Trail_authPageContent__SvpcV {
    max-width: 650px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .Trail_authPageContent__SvpcV {
    max-width: 700px;
  }
}

@media only screen and (min-width: 1710px) {
  .Trail_authPageContent__SvpcV {
    max-width: 750px;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}
.bodayContainer {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #23232e 0%, #030202 100%) 0% 0%
    no-repeat padding-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
img {
  object-fit: cover;
  user-drag: none !important;
  -moz-user-drag: none !important;
  -webkit-user-drag: none !important;
}
html {
  scroll-behavior: smooth !important;
  background-color: #07080c;
}
/* p {
  margin-bottom: 0;
}
a {
  text-decoration: none;
} */
pre {
  text-align: center;
  color: #000;
  font-family: 'Montserrat' !important;
  font-weight: 500;
  padding: 0 10px 0;

}
.backOut {
  text-align: left;
  font-family: Montserrat-Regular;
  letter-spacing: 0px;
  color: #888888;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
}
.useamin{    
  color: white;
  margin-top: 9px;
  font-size: 13px;
  padding: 0px 13px;
}
.mosert{
  font-family: 'Montserrat' !important;
}
.widthController {
  max-width: 1920px;
  padding: 50px 50px;
  margin: 0 auto;
}
.marginerAutos {
  margin: 0 auto;
}
.Logosz {
  width: 250px;
  margin-bottom: 40px;
  object-fit: contain;
}
.flexSyntdom {
  display: flex;
  justify-content: center;
}
.middleFledge {
  max-width: 36%;
  margin: 0 auto;
}
.newAccount {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 30px;
  font-weight: 700;
}
.Enter {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0 0 0;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  font-weight: 500;
}
.inputFull {
  width: 100% !important;
  padding: 20px;
  height: 70px !important;
  font-family: Montserrat-Regular;
  color: white;
  background: #31343e;
  border: none;
  font-size: 20px !important;
  border-radius: 10px;
  margin-top: 20px;
}
.positionOfLoader {
  position: absolute;
  top: 36%;
  right: 0px;
}
.inputFullEmail {
  width: 100% !important;
  padding: 20px;
  height: 70px !important;
  font-family: Montserrat-Regular;
  color: white;
  background: #31343e;
  border: none;
  font-size: 20px !important;
  border-radius: 10px;
  margin-top: 20px;
}
.PhoneInputInput{
  font-size: 20px !important;
}
.flexMan {
  display: flex;
  justify-content: center;
  align-items: start;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}
.inputFull ::-webkit-input-placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFull :-ms-input-placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFull ::placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFullEmail ::-webkit-input-placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFullEmail :-ms-input-placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.inputFullEmail ::placeholder {
  font-family: 20px;
  font-family: Montserrat-Regular;
  color: #8e8e8e;
}
.Thebg {
    width: 100%;
    padding: 14px 13px;
    height: auto;
    font-family: Montserrat-Regular;
    color: white;
    background: #31343e;
    border: none;
    font-size: 12px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: left;
}
.privacy {
  color: #5aa5ef;
  font-weight: 600;
}
.privacy:hover {
  color: #04519d;
  font-weight: 600;
}
.redErr{
    font-size: 12px;
    color: red;
    margin: 0;
}
.aggre {
  background: #5aa5ef 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  height: 85px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}
.disabledaggre{
  background: #5aa5ef 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  height: 85px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  width: 100%;
  margin-top: 10px;
  opacity: 0.6 !important;
}
.marginx{
    margin-top: 20px;
}
.redisher{
    color: red;
    font-size: 15px;
}
@media only screen and (max-width: 600px) {
    .middleFledge {
        max-width:100%;
      }
      .widthController {
        max-width: 100%;
        padding: 10px 10px;
      }
      .newAccount {
        font-size: 24px;
        font-weight: 600;
      }
      .Enter {
        font-size: 15px;
      }
  }
.app_app__cewju {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
}
.app_hideOrShow__ZjwU4 {
  background-color: white !important;
    color: black !important;
    padding: 15px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 33%;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 19999999;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
.app_Sppped__83SIp {
  background-color: white !important;
  color: black !important;
  padding: 8px 15px;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  width: -webkit-max-content;
  width: max-content;
  font-size: 16px;
  border-radius: 50px 50px 50px 50px;
  display: flex;
  border: solid 2px #5aa5ef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 19999999;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  margin-top: 0;
}
@-webkit-keyframes app_slideIn__1oZ8I {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  50%{
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes app_slideIn__1oZ8I {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  50%{
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.app_fullSpeed__35NfB {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100000000;
  grid-gap: 10px;
  gap: 10px;
  right: 3%;
  bottom: 15px;
  opacity: 1;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation: app_slideIn__1oZ8I 0.3s ease-out;
          animation: app_slideIn__1oZ8I 0.3s ease-out;
  -webkit-transform: translateX(0);
          transform: translateX(0); /* Initial state for the transform */
}

@media only screen and (max-width: 1199px) {
  .app_hideOrShow__ZjwU4 {
    right: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .app_hideOrShow__ZjwU4 {
    right: 12%;
  }
}
.app_hideOrShow__ZjwU4 > img {
  width: 20px;
}
.app_toastContainerStyle__3e9Ql button {
  margin-top: 0 !important;
}
/* Initial state for toastContainerShow */
.app_toastContainerShow__1r96S {
  display: block;
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-animation: app_toastShow__3Yc-y 0.5s ease-in-out forwards;
          animation: app_toastShow__3Yc-y 0.5s ease-in-out forwards;
  max-height: 400px;
  overflow-y: scroll;
}

.app_toastContainerShow__1r96S::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

.app_toastContainerShow__1r96S::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

.app_toastContainerShow__1r96S::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}


/* Initial state for toastContainerNone */
.app_toastContainerNone__3Hv80 {
  /* display: none; */
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0); /* Initial state is fully visible */
  -webkit-animation: app_toastHide__3JrRb 0.5s ease-in-out forwards;
          animation: app_toastHide__3JrRb 0.5s ease-in-out forwards;
}

@-webkit-keyframes app_toastShow__3Yc-y {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); /* Start from below the viewport */
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Slide in from the bottom */
  }
}

@keyframes app_toastShow__3Yc-y {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); /* Start from below the viewport */
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Slide in from the bottom */
  }
}

@-webkit-keyframes app_toastHide__3JrRb {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Start at the current position */
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); /* Slide out to below the viewport */
  }
}

@keyframes app_toastHide__3JrRb {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Start at the current position */
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); /* Slide out to below the viewport */
  }
}


.app_autoSignInSpinnerContainer__yydNx {
  align-items: center;
  background: radial-gradient(circle at center, #1f2330, #07080c);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.app_changingTheme__2VZx9 *,
.app_changingTheme__2VZx9 *::before,
.app_changingTheme__2VZx9 *::after {
  transition-property: none !important;
}
.app_dialogContent__1J8iq {
  text-align: center !important;
  white-space: pre-line;
  padding: 20px;
}

.app_dialogTitle__1pKSO {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4caf50;
  text-align: center;
}

.app_dialogButton__32JM6 {
  display: block !important;
  margin: 0px auto 20px !important;
  padding: 10px 20px !important;
  font-size: 1rem;
  background-color: #4caf50 !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer;
  transition: background 0.3s ease !important;
}

.app_dialogButton__32JM6:hover {
  background-color: #388e3c;
}

