.formSection {
  display: block;
}

@media only screen and (max-width: 899px) {
  .formSection {
    margin-bottom: calc(28.125px + 5px);
    margin-top: 28.125px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .formSection {
    margin-bottom: calc(33.75px + 6px);
    margin-top: 33.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .formSection {
    margin-bottom: calc(40.078125px + 7.125px);
    margin-top: 40.078125px;
  }
}

@media only screen and (min-width: 1710px) {
  .formSection {
    margin-bottom: calc(45px + 8px);
    margin-top: 45px;
  }
}

.title {
  color: #60676d;
  font-weight: 300;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .title {
    font-size: 11.875px;
    line-height: 16.25px;
    margin-bottom: -5px;
    justify-content: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .title {
    font-size: 14.25px;
    line-height: 19.5px;
    margin-bottom: -6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .title {
    font-size: 16.921875px;
    line-height: 23.15625px;
    margin-bottom: -7.125px;
  }
}

@media only screen and (min-width: 1710px) {
  .title {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: -8px;
  }
}

.content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

@media only screen and (max-width: 767px) {
  .content {
    gap: 20px;
    margin-top: 20px;
  }
}


@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .content {
    gap: 24px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .content {
    gap: 28.5px;
    margin-top: 28.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .content {
    gap: 32px;
    margin-top: 32px;
  }
}
