.footer {
  width: 100%;
}

.footerContent {
  color: #cecece;
  font-weight: 300;
  letter-spacing: 0.75px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .footerContent {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .footerContent {
    margin-top: 72px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .footerContent {
    margin-top: 85.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .footerContent {
    margin-top: 96px;
  }
}

@media only screen and (max-width: 767px) {
  .brandName {
    margin-bottom: 96px;
    margin-top: 76.80000000000001px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .brandName {
    margin-bottom: 96px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .brandName {
    margin-bottom: 114px;
    margin-top: 171px;
  }
}

@media only screen and (min-width: 1710px) {
  .brandName {
    margin-bottom: 128px;
    margin-top: 192px;
  }
}

.linksContainer {
  /* display: -ms-grid; */
  /* display: grid; */
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .linksContainer {
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .linksContainer {
    -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .linksContainer {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .linksContainer {
    -ms-grid-columns: auto auto auto auto;
        grid-template-columns: auto auto auto auto;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.link {
  color: #cecece;
  display: table-cell;
  text-align: center;
  padding: 15px;
}

@media all and (hover: hover) {
  .link:hover {
    color: #fff;
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .link:active, .link:target {
    color: #fff;
    text-decoration: underline;
  }
}

.copyright {
  /* margin-top: 13px; */
  text-align: center;
  margin-left: 22px;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .copyright {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .copyright {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .copyright {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .copyright {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .copyright {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .copyright {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
      margin-top: 9.5px;
    }
    .terms{
      margin: 110px 100px 0px 0px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .copyright {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
      margin-top: 11px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .copyright {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
      margin-top: 13px;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .copyright {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .copyright {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .copyright {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .copyright {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .copyright {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .copyright {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .copyright {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .copyright {
      padding-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .link,
  .copyright {
    font-size: 9px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .link,
  .copyright {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .link,
  .copyright {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .link,
  .copyright {
    font-size: 15px;
  }
}

.linkWrapper {
  display: table;
  width: 100%;
  table-layout: fixed; 
}

.footerDiv {
  justify-content: space-around;
}

.logoPart {
  float: left;
  display: inline-flex;
  /* margin: 50px; */
}

.terms {
  float: right;
  /* margin: 73px 65px 0px 0px; */
}

/* .logoSizeChange {
  height: 50px !important;
} */

@media only screen and (min-width: 1273px) {
  .terms {
    margin: 113px 100px 0px 0px;
  }
  .logoPart {
    margin: 116px 0px 0px 73px;
  }
}

@media only screen and (max-width: 1272px) {
  .footerDiv {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .terms {
    justify-content: space-around;
    display: flex;
  }
  .logoPart {
    justify-content: center;
    clear: both;
  }
}