@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Ubuntu:wght@400;500&display=swap");
body {
  font-family: "Montserrat", sans-serif;
}
.bodayContainer {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #23232e 0%, #030202 100%) 0% 0%
    no-repeat padding-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
img {
  object-fit: cover;
  user-drag: none !important;
  -moz-user-drag: none !important;
  -webkit-user-drag: none !important;
}
html {
  scroll-behavior: smooth !important;
  background-color: #07080c;
}
/* p {
  margin-bottom: 0;
}
a {
  text-decoration: none;
} */
pre {
  text-align: center;
  color: #000;
  font-family: 'Montserrat' !important;
  font-weight: 500;
  padding: 0 10px 0;

}