.header {
  width: 100%;
}

.headerContent {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  /* padding: 40px; */
}

@media only screen and (max-width: 767px) {
  .headerContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media only screen and (min-width: 768px) {
  .headerContent {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.homeLink {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  text-decoration: none;
  /* margin: 30px; */
}

.headerLinksContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .headerLinksContainer {
    margin-top: 44px;
  }
}

.headerLink {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .headerLink {
    font-size: 12px;
  }
  .headerLink:first-of-type {
    margin-right: 18px;
  }
  .headerContent {
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .headerLink {
    margin-left: 16.40625px;
    font-size: 12px;
  }
  .headerContent {
    padding: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headerLink {
    margin-left: 21.875px;
    font-size: 12px;
  }
  .headerContent {
    padding: 20px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headerLink {
    margin-left: 16px;
    font-size: 16px !important;
    height: 40px !important;
  }
  .headerContent {
    padding: 25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerLink {
    margin-left: 20px;
    font-size: 20px !important;
    height: 48px !important;
  }
  .headerContent {
    padding: 30px;
  }
}

@media only screen and (min-width: 1710px) {
  .headerLink {
    margin-left: 24px;
    font-size: 24px !important;
    height: 56px !important;
  }
  .headerContent {
    padding: 40px;
  }
  .homeLink {
    margin: 15px 25px;
  }
}

.signUpButton {
  color: #fff;
  text-transform: none;
  border-radius: 30px !important;
  border-style: none !important;
  /* border: 0.5px solid #707070; */
  /* background-color: #084FF9; */
  background-image: linear-gradient(to right, #084FF9 0%, #04287D 100%);
  /* box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset; */
  /* -webkit-box-shadow: #084ff9 131px 0px 21px 1px inset, #002e9a 121px 0px 83px 6px inset; */
  height: 40px !important;
  margin: 0 10px;
  font-weight: 700;
  font-size: 16px !important;
}
.logInButton {
  text-transform: none;
  color: #fff;
  background-color: transparent;
  border-radius: 30px !important;
  border: 2px solid #FFFFFF !important;
  height: 40px !important;
  margin: 0 30px 0 10px;
  font-weight: 700;
  font-size: 16px !important;
}

.heroHeading {
  color: #FFFFFF;
  font-weight: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 899px) {
  .heroHeading {
    font-size: 34px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
  .textStyle {
    font-size: 24px;
  }
  .appForFree {
    height: 60px !important;
  }
  .helpCenterWrapper {
    margin: 0px 33px 0 143px;
    bottom: 3px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroHeading {
    font-size: 45px;
    margin-bottom: 19.5px;
  }
  .textStyle {
    font-size: 35px;
  }
  .appForFree {
    height: 65px !important;
  }
  .helpCenterWrapper {
    margin: 0px 33px 0 156px;
    bottom: 8px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroHeading {
    font-size: 50px;
    margin-bottom: 23.15625px;
  }
  .textStyle {
    font-size: 40px;
  }
  .appForFree {
    height: 68px !important;
  }
  .helpCenterWrapper {
    margin: 0px 33px 0 189px;
    bottom: 13px;
  }
}

@media only screen and (min-width: 1710px) {
  .heroHeading {
    font-size: 55px;
    margin-bottom: 26px;
  }
  .textStyle {
    font-size: 45px;
  }
  .appForFree {
    height: 70px !important;
    margin-bottom: 3rem !important;
  }
  .headingWrapper {
    margin-top: 10px;
  }
  .helpCenterWrapper {
    margin: 0px 33px 0 201px;
    bottom: 25px;
  }
}
.headingWrapper {
    width: 100%;
    /* float: left; */
    text-align: center;
    margin-top: 40px;
    /* background-image: url(../../../../../Assets/landing-background.png);
    background-size: cover; */
}

.headerContainer {
  /* height: 100vh; */
  /* background-image: linear-gradient(
                  rgba(0, 0, 0, 0.9),
                  rgba(0, 0, 0, 0.7)
                ), url(../../../../../Assets/landing-background.png); */
    background-size: 100% 100%;
    background-image: url(../../../../../Assets/landing-background.png);
}

.mainHeaderWrap {
  /* min-height: 100vh; */
}

.viewSharePreserveWrapper {
  padding: 20px 0 60px 0;
}

.textStyle {
    /* font-size: 45px; */
    padding: 0 20px;
    font-weight: 700;
}

.appForFree {
  
  color: #fff;
  text-transform: none;
  border-radius: 40px !important;
  border: 1px solid #707070;
  /* background-color: #084FF9; */
  background-image: linear-gradient(to right, #0185FF 0%, #015BFB 60%, #0139F7 100%);
  border-style: none !important;
  margin-bottom: 5rem;
  font-weight: 600;
}

.deviceWrapper {
  margin-top: -7rem;
  text-align: center;
}
.deviceIcon {
  margin: 0 20px;
}
.adjustChromCastIcon {
  position: relative;
  top: 97px;
}

.adjustFireTV {
  position: relative;
  top: 25px;
}

@media only screen and (max-width: 1260px) {
  .hideIcon {
    display: none !important;
  }
}

@media only screen and (max-width: 1260px) {
  .deviceWrapper {
    margin-top: 3rem !important;
  }
}

@media only screen and (max-width: 392px) {
  .deviceWrapper {
    display: flex;
  }
}

.helpCenterWrapper {
  /* margin: 0px 33px 0 201px; */
  justify-content: end;
  display: flex;
  position: relative;
  /* bottom: 25px; */
  color: #FFFFFF;
}

.helpCenterText {
  font-weight: 500;
  font-size: 16px;
}

.helpCenterIcon {
  position: relative;
  top: 3px;
  right: 5px;
}

.adjustRoku {
  position: relative;
  top: 20px;
}

.rightContainer {
  position: relative;
  bottom: 10px;
}