.hiddenFormField {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.accountDataContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.accountData {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-weight: bold;
}

@media only screen and (max-width: 899px) {
  .accountData {
    font-size: 14px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .accountData {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .accountData {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .accountData {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .accountData {
    font-size: 20px;
  }
}

.actionButton {
  color: #000 !important;
}

.inputFieldContainer {
  background-color: transparent !important;
}

.inputFieldContainer img {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}

.inputField {
  background-color: #eee;
  border-color: #ccc !important;
  color: #444;
}

.inputFieldLabel {
  color: #666;
  font-weight: 500;
  margin-bottom: 0;
}

.passwordStrengthBar {
  background-color: #ddd;
}

.passwordStrengthNotes {
  color: #444;
}

.avatarEditButton {
  color: #5aa5ef;
  font-weight: 600;
}

.accountDataAction {
  color: rgba(2, 7, 26, 0.4);
}

.avatarEditButton,
.accountDataAction {
  cursor: pointer;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

@media all and (hover: hover) {
  .avatarEditButton:hover,
  .accountDataAction:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .avatarEditButton:active, .avatarEditButton:target,
  .accountDataAction:active,
  .accountDataAction:target {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 899px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 14.0625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 12px;
    margin-left: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 13.359375px;
    margin-left: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .avatarEditButton,
  .accountDataAction {
    font-size: 15px;
    margin-left: 30px;
  }
}

.profilePicture {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media only screen and (max-width: 899px) {
  .profilePicture {
    height: 98px;
    width: 98px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .profilePicture {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .profilePicture {
    height: 73.5px;
    width: 73.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .profilePicture {
    height: 87.28125px;
    width: 87.28125px;
  }
}

@media only screen and (min-width: 1710px) {
  .profilePicture {
    height: 98px;
    width: 98px;
  }
}

.profilePictureIcon {
  height: 50%;
  width: 50%;
}
