input.input {
  border: none;
  height: 100%;
  background-color: transparent;
  font-size: inherit;
  width: 100%;
}
span.emailTags {
  margin-right: 0.5rem;
  border-radius: 999999999999999999999999px;
  border: 3px solid;
  /* display: flex; */
  padding: 0.2rem 0.5rem;
}
span.emailTags > .MuiSvgIcon-root {
  font-size: inherit;
  margin: 0 0 -2px 0;
  /* color: red; */
  cursor: pointer;
}

.popperComponent {
  width: 100px
}

