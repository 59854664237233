.hiddenFormField {
  height: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 0 !important;
}

.loader {
  margin-top: -4px;
  color: #5AA5EF;
}

.shareScreen {
  background-color: transparent;
  width: 100%;
}

.shareScreen,
.shareScreen * {
  font-family: Poppins, sans-serif;
}

.shareScreen :global(.MuiDialog-paper) {
  margin: calc(var(--site-padding)) !important;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .shareScreen :global(.MuiDialog-paper) {
    max-width: 499.21875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreen :global(.MuiDialog-paper) {
    max-width: 665.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreen :global(.MuiDialog-paper) {
    max-width: 798.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreen :global(.MuiDialog-paper) {
    max-width: 948.515625px;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreen :global(.MuiDialog-paper) {
    max-width: 1065px;
  }
}

.linkShare {
  background-color: #fff;
  display: block !important;
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .linkShare,
  .shareScreenActions,
  .shareScreenHeading {
    border-radius: 5.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .linkShare,
  .shareScreenActions,
  .shareScreenHeading {
    border-radius: 7.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .linkShare,
  .shareScreenActions,
  .shareScreenHeading {
    border-radius: 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkShare,
  .shareScreenActions,
  .shareScreenHeading {
    border-radius: 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  .linkShare,
  .shareScreenActions,
  .shareScreenHeading {
    border-radius: 12px;
  }
}

.shareScreenHeading {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media only screen and (max-width: 899px) {
  .shareScreenHeading {
    padding: 24px 24px 0 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenHeading {
    padding: 31px 31px 0 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenHeading {
    padding: 32.25px 32.25px 0 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenHeading {
    padding: 38.296875px 38.296875px 0 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenHeading {
    padding: 43px 43px 0 43px !important;
  }
}

.shareScreenHeadingContent {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400 !important;
  line-height: 1.2;
}

@media only screen and (max-width: 899px) {
  .shareScreenHeadingContent {
    font-size: 16.875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenHeadingContent {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenHeadingContent {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenHeadingContent {
    font-size: 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenHeadingContent {
    font-size: 36px;
  }
}

.addPersonIconContainer {
  border-radius: 50%;
  color: #fff;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-bottom: auto;
  position: relative;
}

.addPersonImageContainer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.addPersonImageContainer:not(.hasBG) {
  border: 2px solid gray;
}

.addPersonImageContainer.hasBG {
  background-color: #5aa5ef;
}

label.addPersonImageContainer {
  cursor: pointer;
}

.imagePreview {
  border: none;
  border-radius: 50%;
  display: block;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.imageDeleteButton {
  background-color: #ff0000;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 50%;
  left: -10%;
  position: absolute;
  top: -10%;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  width: 50%;
}

@media all and (hover: hover) {
  .imageDeleteButton:hover {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media all and (pointer: coarse) {
  .imageDeleteButton:active, .imageDeleteButton:target {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

.imageDeleteButton::after {
  background-color: #fff;
  content: ' ';
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50%;
}

@media only screen and (max-width: 1199px) {
  .addPersonIcon {
    height: 27px;
    width: 27px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addPersonIcon {
    height: 28.5px;
    width: 28.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addPersonIcon {
    height: 33.84375px;
    width: 33.84375px;
  }
}

@media only screen and (min-width: 1710px) {
  .addPersonIcon {
    height: 38px;
    width: 38px;
  }
}

.shareScreenContent {
  position: relative;
}

.shareScreenContent::before {
  background-color: #fff;
  content: ' ';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

@media only screen and (max-width: 899px) {
  .shareScreenContent {
    padding: 12px 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenContent {
    padding: 15.5px 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenContent {
    padding: 16.125px 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenContent {
    padding: 19.14844px 38.29688px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenContent {
    padding: 21.5px 43px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .addPersonIconContainer,
  .personButtonDP {
    height: 44px;
    margin-right: 11px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addPersonIconContainer,
  .personButtonDP {
    height: 45.75px;
    margin-right: 11.4375px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addPersonIconContainer,
  .personButtonDP {
    height: 54.328125px;
    margin-right: 13.58203px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .addPersonIconContainer,
  .personButtonDP {
    height: 60px;
    margin-right: 15px;
    width: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .titleInput {
    height: 44px;
    width: 44px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .titleInput {
    height: 45.75px;
    width: 45.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .titleInput {
    height: 54.328125px;
    width: 54.328125px;
  }
}

@media only screen and (min-width: 1710px) {
  .titleInput {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (max-width: 1199px) {
  .shareScreenEmailInput {
    /* height: 54px; */
    margin-top: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenEmailInput {
    /* height: 58.5px; */
    margin-top: 29.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenEmailInput {
    /* height: 69.46875px; */
    margin-top: 34.734375px;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenEmailInput {
    /* height: 78px; */
    margin-top: 39px;
  }
}

.titleInput,
.shareScreenEmailInput {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #f8f9fa;
  border: 2px solid transparent;
  border-left: none;
  border-radius: 4px;
  border-right: none;
  color: #000;
  font-size: 16px;
  outline: none;
  /* position: relative; */
  width: 100%;
  /* margin-bottom: 76px; */
}

.titleInput::-webkit-input-placeholder,
.shareScreenEmailInput::-webkit-input-placeholder {
  color: #8e8e8e;
}

.titleInput:-ms-input-placeholder,
.shareScreenEmailInput:-ms-input-placeholder {
  color: #8e8e8e;
}

.titleInput::-ms-input-placeholder,
.shareScreenEmailInput::-ms-input-placeholder {
  color: #8e8e8e;
}

.titleInput::placeholder,
.shareScreenEmailInput::placeholder {
  color: #8e8e8e;
}

.titleInput:focus,
.shareScreenEmailInput:focus {
  border-bottom-color: #5aa5ef;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 1199px) {
  .titleInput,
  .shareScreenEmailInput {
    font-size: 17px;
    /* padding: 0 22px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .titleInput,
  .shareScreenEmailInput {
    font-size: 18px;
    /* padding: 0 24px; */
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .titleInput,
  .shareScreenEmailInput {
    font-size: 21.375px;
    /* padding: 0 28.5px; */
  }
}

@media only screen and (min-width: 1710px) {
  .titleInput,
  .shareScreenEmailInput {
    font-size: 24px;
    /* padding: 0 32px; */
  }
}

.personButton {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  justify-content: start;
  /* margin-left: -10px; */
  /* margin-bottom: 4px; */
  text-align: left;
  /* width: 100%; */
}

.personButton.selected {
  border: 2px solid #007aff;
}

@media only screen and (max-width: 1199px) {
  .personButton {
    border-radius: 6.25px;
    /* margin-bottom: 6px; */
    /* padding: 12px; */
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButton {
    border-radius: 7.5px;
    /* margin-bottom: 6.75px; */
    /* padding: 13.5px; */
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButton {
    border-radius: 8.90625px;
    /* margin-bottom: 8.01562px; */
    /* padding: 16.03125px; */
  }
}

@media only screen and (min-width: 1710px) {
  .personButton {
    border-radius: 10px;
    /* margin-bottom: 9px; */
    /* padding: 18px; */
  }
}

@media all and (hover: hover) {
  .personButton:hover {
    background-color: #eee;
    border-radius: 6px;
  }
}

@media all and (pointer: coarse) {
  .personButton:active, .personButton:target {
    background-color: #eee;
    border-radius: 6px;
  }
}

.personButtonDP {
  border-radius: 50%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
}

.personDPImage,
.personDPText {
  border-radius: 50%;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.personDPText {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--color-black);
  /* background-color: #14f47b; */
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}

.personButtonTextContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.personButtonName,
.personButtonEmail {
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.personButtonName {
  color: #000000;
  text-transform: capitalize;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .personButtonName {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .personButtonName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .personButtonName {
    font-size: 21.375px;
  }
}

@media only screen and (min-width: 1710px) {
  .personButtonName {
    font-size: 24px;
  }
}

.notes,
.selectionHeading {
  color: #8e8e8e;
}

@media only screen and (max-width: 1199px) {
  .selectionHeading {
    margin-bottom: 6px;
    margin-top: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectionHeading {
    margin-bottom: 6.75px;
    margin-top: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectionHeading {
    margin-bottom: 8.01562px;
    margin-top: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectionHeading {
    margin-bottom: 9px;
    margin-top: 18px;
  }
}

.notes {
  line-height: 3.5;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 15px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .notes,
  .personButtonEmail,
  .selectionHeading {
    font-size: 22px;
  }
}

.personButtonEmail {
  color: #63676c;
  text-transform: lowercase;
}

.shareScreenActions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shareScreenActions:nth-of-type(1) {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media only screen and (max-width: 899px) {
  .shareScreenActions {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    padding: 12px 24px !important;
  }
}

@media only screen and (min-width: 900px) {
  .shareScreenActions {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenActions {
    padding: 15.5px 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenActions {
    padding: 16.125px 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenActions {
    padding: 19.14844px 38.29688px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenActions {
    padding: 21.5px 43px !important;
  }
}

.linkShare {
  background-color: #fff;
}

@media only screen and (max-width: 899px) {
  .linkShare {
    font-size: 14px !important;
    margin-top: 13.71429px !important;
    padding: 24px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .linkShare {
    font-size: 15px !important;
    margin-top: 17.71429px !important;
    padding: 31px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .linkShare {
    font-size: 16.5px !important;
    margin-top: 18.42857px !important;
    padding: 32.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkShare {
    font-size: 19.59375px !important;
    margin-top: 21.88393px !important;
    padding: 38.296875px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .linkShare {
    font-size: 22px !important;
    margin-top: 24.57143px !important;
    padding: 43px !important;
  }
}

.shareScreenDoneButton {
  font-size: 16px;
  height: 44px !important;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .shareScreenDoneButton {
    font-size: 15px !important;
    height: 34px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenDoneButton {
    font-size: 15px !important;
    height: 37.5px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenDoneButton {
    font-size: 15px !important;
    height: 45px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenDoneButton {
    font-size: 17.8125px !important;
    height: 53.4375px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenDoneButton {
    font-size: 20px !important;
    height: 60px !important;
  }
}

@media only screen and (max-width: 899px) {
  .linkIcon {
    height: 20.625px;
    margin-right: 10.3125px;
    width: 20.625px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .linkIcon {
    height: 27.499999999999996px;
    margin-right: 13.75px;
    width: 27.499999999999996px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .linkIcon {
    height: 33px;
    margin-right: 16.5px;
    width: 33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkIcon {
    height: 39.1875px;
    margin-right: 19.59375px;
    width: 39.1875px;
  }
}

@media only screen and (min-width: 1710px) {
  .linkIcon {
    height: 44px;
    margin-right: 22px;
    width: 44px;
  }
}

@media only screen and (max-width: 899px) {
  .shareScreenLinkText {
    margin: 11.25px 0 6.42857px 0 !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .shareScreenLinkText {
    margin: 11.25px 0 6.42857px 0 !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .shareScreenLinkText {
    margin: 13.5px 0 7.71429px 0 !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .shareScreenLinkText {
    margin: 16.03125px 0 9.16071px 0 !important;
  }
}

@media only screen and (min-width: 1710px) {
  .shareScreenLinkText {
    margin: 18px 0 10.28571px 0 !important;
  }
}

.shareScreenLinkButton {
  color: #5aa5ef;
  cursor: pointer;
  text-align: right;
}

.titleInput {
  background-color: transparent !important;
  border-bottom-color: #ccc;
  border-radius: 0 !important;
  padding: 0 12px !important;
}

.subTitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}
