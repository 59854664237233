.publishedContainer,
.publishedContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.shimming {
  border-radius: 5px;
  background-color: #d6d6d6;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
/* .progressTracker{
  border: none !important;
} */
.addToExisting {
text-align: center;
}
.req {
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.Cancelxx{
  width: 20px;
  margin-top: 5px;
  height: 20px;
  object-fit: cover;
  object-position: center;
}
.customDiv {
  width: 100%;
  background: white;
  position: absolute;
  height: 100%;
  font-size: 16px;
  padding: 0 5px;
  color: black;
  font-family: "POPPINS";
}
.fleo {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: start;
  margin-top: 2px;
  justify-content: start;
}
.cancelPlz {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  min-width: 70px;
  font-family: 'Poppins';
}
.noCancel {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Poppins';
}
.closeMeBro {
  margin-top: 0 !important;
  cursor: pointer;
  position: absolute;
  background-color: transparent !important;
  color: gray;
  top: 0;
  height: 100%;
  right: -2px;
  display: flex;
  align-items: start;
}
.loaderAfterClose {
}
.textx {
  margin: 0 auto;
  width: max-content;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000000;
}
.marginX {
  z-index: 1000000000;
  margin: 0 auto;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absol {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.6;
  z-index: 100000;
  top: 50%;
  left: 50%;
}
.optionalInput {
  font-size: 13px;
  color: gray;
  font-weight: 400;
}

.videoPublishedIcon {
  height: 20vh !important;
  width: 20vh !important;
}

.videoPublishedText {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  text-align: center;
}
.customloader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #766df4);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
@media only screen and (max-width: 767px) {
  .videoPublishedText {
    color: #fff !important;
    font-size: 15px;
  }
  .loadingAlbum {
    font-size: 14px;
    margin-left: 45px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPublishedText {
    font-size: 17px;
  }
  .loadingAlbum {
    font-size: 14px;
    margin-left: 34px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPublishedText {
    font-size: 17px;
  }
  .loadingAlbum {
    font-size: 14px;
    margin-left: 47px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .videoPublishedText {
    font-size: 17px;
  }
  .loadingAlbum {
    font-size: 15px;
    margin-left: 42px;
    margin-top: 7px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPublishedText {
    font-size: 19.59375px;
  }
  .loadingAlbum {
    font-size: 17px;
    margin-left: 47px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoPublishedText {
    font-size: 22px;
  }
  .loadingAlbum {
    font-size: 18px;
    margin-left: 57px;
    margin-top: 10px;
  }
}

.selectBoxContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.selectBoxContainer.disabled {
  cursor: not-allowed !important;
  opacity: 0.4 !important;
}

@media only screen and (min-width: 768px) {
  .selectBoxContainer.playlist {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2 / 3;
  }
  .selectBoxContainer.playlist .selectBox {
    margin-top: 0 !important;
  }
}

.selectBox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #d9d9da;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .selectBox {
    border-radius: 4px;
    padding: 16px;
  }
}

.selectBoxText {
  max-width: 100%;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .selectBoxText {
    width: calc(100% - 20px - (20px / 2));
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxText {
    width: calc(100% - 23.90625px - (23.90625px / 2));
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxText {
    width: calc(100% - 31.875px - (31.875px / 2));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxText {
    width: calc(100% - 38.25px - (38.25px / 2));
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxText {
    width: calc(100% - 45.421875px - (45.421875px / 2));
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxText {
    width: calc(100% - 51px - (51px / 2));
  }
}

.selectBoxLabel {
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .selectBoxLabel {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxLabel {
    font-size: 17px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxLabel {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxLabel {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxLabel {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxLabel {
    font-size: 22px;
  }
}

.selectBoxDescription {
  font-weight: 300;
  margin-top: 0.5em;
  display: flex;
    gap: 10px;
    align-items: center;
}

@media only screen and (max-width: 767px) {
  .selectBoxDescription {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxDescription {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxDescription {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxDescription {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxDescription {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxDescription {
    font-size: 16px;
  }
}

.selectBoxDropdownIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .selectBoxDropdownIcon {
    border: 1px solid #000000;
    font-size: 1em;
    height: 20px;
    width: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .selectBoxDropdownIcon {
    border: 1px solid #707070;
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxDropdownIcon {
    height: 24px;
    width: 24px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxDropdownIcon {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxDropdownIcon {
    height: 38.5px;
    width: 38.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxDropdownIcon {
    height: 45.5px;
    width: 45.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxDropdownIcon {
    height: 51px;
    width: 51px;
  }
}

.selectBoxCreateHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.selectBoxCreateInput {
  border: 1px solid #000;
  font-size: 1em;
  margin: 1em 0;
  padding: 0.5em;
  width: 100%;
}

.selectBoxCreateTitle {
  display: block;
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}

.selectBackgroundText {
  display: block;
  margin-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  margin-top: 7px;
}

@media only screen and (max-width: 767px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 14px;
  }
  .selectBackgroundText {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 12px;
  }
  .selectBackgroundText {
    font-size: 10px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 12px;
  }
  .selectBackgroundText {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 14.25px;
  }
  .selectBackgroundText {
    font-size: 12.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 14.25px;
  }
  .selectBackgroundText {
    font-size: 12.25px;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxCreateTitle,
  .selectBoxCreateInput,
  .selectBoxCreateBackButton,
  .selectBoxCreateAddButton {
    font-size: 16px;
  }
  .selectBackgroundText {
    font-size: 14px;
  }
}

.selectBoxCreateBackButton,
.selectBoxCreateAddButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
}

.selectBoxCreateAddButton {
  border: 2px solid #5aa5ef;
  border-radius: 0.5em;
  color: #5aa5ef;
  padding: 0.25em 0.75em;
  margin-top: 15px;
}

.selectBoxCreateAddButton:disabled {
  opacity: 0.75;
}

.selectBoxCreateFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 16px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 18.75px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 30px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 35.625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBox,
  .selectBoxMenuContent {
    padding: 40px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBox,
  .selectBoxMenu {
    border-radius: 4.6875px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBox,
  .selectBoxMenu {
    border-radius: 6.25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBox,
  .selectBoxMenu {
    border-radius: 7.5px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBox,
  .selectBoxMenu {
    border-radius: 8.90625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBox,
  .selectBoxMenu {
    border-radius: 10px !important;
  }
}

.selectBoxOptionsTitle {
  font-weight: 600 !important;
}

@media only screen and (max-width: 767px) {
  .selectBoxOptionsTitle {
    margin-bottom: 16px !important;
  }
}

@media only screen and (min-width: 768px) {
  .selectBoxOptionsTitle {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxOptionsTitle {
    margin-bottom: 18.75px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxOptionsTitle {
    margin-bottom: 25px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxOptionsTitle {
    margin-bottom: 30px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxOptionsTitle {
    margin-bottom: 35.625px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxOptionsTitle {
    margin-bottom: 40px !important;
  }
}

.selectBoxMenuContent {
  background-color: #fff;
}

.selectBoxMenu {
  margin-top: -24px;
}

@media only screen and (max-width: 767px) {
  .selectBoxMenu {
    bottom: 0 !important;
    height: 90% !important;
    left: 0 !important;
    max-width: unset !important;
    position: fixed !important;
    top: unset !important;
    width: 100% !important;
  }
}

.selectBoxOptionsList {
  position: relative;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-top: max(12px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-top: max(16px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-top: max(20px, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-top: max(0, env(safe-area-inset-top)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-top: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-top: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-top: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-top: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-top: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-bottom: max(12px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-bottom: max(16px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-bottom: max(20px, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-bottom: max(0, env(safe-area-inset-bottom)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-bottom: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-bottom: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-bottom: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-bottom: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-bottom: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-left: max(0, env(safe-area-inset-left)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-left: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-left: 0 !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-right: max(0, env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .selectBoxOptionsList {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .selectBoxOptionsList {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .selectBoxOptionsList {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .selectBoxOptionsList {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .selectBoxOptionsList {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .selectBoxOptionsList {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .selectBoxOptionsList {
      padding-right: 0 !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .selectBoxOptionsList {
      padding-right: 0 !important;
    }
  }
}

.selectBoxOption {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 2.5 !important;
}

.selectBoxOption.addButton {
  color: #5aa5ef !important;
}

@media all and (hover: hover) {
  .selectBoxOption:not(.addButton):hover {
    color: #5aa5ef !important;
  }
}

@media all and (pointer: coarse) {
  .selectBoxOption:not(.addButton):active,
  .selectBoxOption:not(.addButton):target {
    color: #5aa5ef !important;
  }
}

@media only screen and (max-width: 767px) {
  .selectBoxOption {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .selectBoxOption {
    font-weight: 600;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .selectBoxOption {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .selectBoxOption {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .selectBoxOption {
    font-size: 15px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .selectBoxOption {
    font-size: 17.8125px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .selectBoxOption {
    font-size: 20px !important;
  }
}

.categoriesContainer {
  display: -ms-grid;
  display: grid;
}

@media only screen and (max-width: 767px) {
  .categoriesContainer {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .categoriesContainer {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.visibilityContainer {
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px rgba(180, 145, 145, 0.416);
  box-shadow: 0px 3px 6px rgba(180, 145, 145, 0.416);
  margin: 2em 0;
}

.visibilityContainer :global(.MuiTypography-root) {
  font-weight: 300;
}

.visibilityTitle {
  margin: 0;
  opacity: 0.9;
}

.visibilityDescription {
  font-weight: 300;
  margin: 0 0 1.75em 0;
}

.visibilityRadio,
.visibilityRadio * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (max-width: 767px) {
  .visibilityTitle {
    font-size: 20.03125px;
    margin-top: 12px;
  }
  .visibilityDescription {
    font-size: 14px;
  }
  .visibilityContainer {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .visibilityTitle {
    font-size: 16.5px;
  }
  .visibilityDescription {
    font-size: 12px;
  }
  .visibilityContainer {
    padding: 25.875px;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .visibilityTitle {
    font-size: 16.5px;
  }
  .visibilityDescription {
    font-size: 12px;
  }
  .visibilityContainer {
    padding: 34.5px;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .visibilityTitle {
    font-size: 16.5px;
  }
  .visibilityDescription {
    font-size: 12px;
  }
  .visibilityContainer {
    padding: 41.4px;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .visibilityTitle {
    font-size: 19.59375px;
  }
  .visibilityDescription {
    font-size: 14.25px;
  }
  .visibilityContainer {
    padding: 49.1625px;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 14.25px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .visibilityTitle {
    font-size: 22px;
  }
  .visibilityDescription {
    font-size: 16px;
  }
  .visibilityContainer {
    padding: 55.2px;
  }
  .visibilityContainer :global(.MuiTypography-root) {
    font-size: 16px !important;
  }
}

.navButton {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  gap: 5px;
  text-transform: capitalize !important;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .navButton {
    color: #fff !important;
    /* width: calc(50% - 8px); */
    /* width: 130px; */
  }
  .navButton:last-of-type {
    margin-left: auto;
  }
}

@media only screen and (min-width: 768px) {
  .navButton {
    color: #000 !important;
  }
}

@media all and (hover: hover) {
  .navButton:disabled:hover {
    background-color: transparent !important;
  }
}

@media all and (pointer: coarse) {
  .navButton:disabled:active,
  .navButton:disabled:target {
    background-color: transparent !important;
  }
}

@media all and (hover: hover) {
  .navButton:not(:disabled):hover {
    color: #fff !important;
  }
}

@media all and (pointer: coarse) {
  .navButton:not(:disabled):active,
  .navButton:not(:disabled):target {
    color: #fff !important;
  }
}

.videoPreview {
  background-color: #b6b6b64b;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  height: 100%;
}

.videoPreviewThumbnail {
  background-color: #fff;
  border: none;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.videoPreviewDetails td:first-of-type {
  color: #8d8d8d;
  padding: 0;
}

.videoPreviewDetails td:nth-child(2) {
  padding: 0 0 0 10px;
}

.videoPreviewDetailsTitle {
  font-weight: 500;
  margin: 0;
}

.videoPreviewLink {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #b9b8b8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 1em;
  padding: 1em;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.videoPreviewLink::-moz-selection {
  background-color: #000;
  color: #fff;
}

.videoPreviewLink::selection {
  background-color: #000;
  color: #fff;
}

.contentBoxTitleContainer {
  position: relative;
}

.contentBoxTitle {
  color: #585858;
  line-height: 1;
  margin: 0;
}

.contentBoxDescription {
  color: #918e8e;
  font-weight: 300;
  line-height: 1.25;
  margin: 6px 0 0 0;
}

.generateThumbnailProgress {
  color: #313030;
  font-weight: 400;
  line-height: 1.25;
  margin: 2px 0 0 0;
  font-size: 14px !important;
}

@media only screen and (max-width: 767px) {
  .contentBoxDescription {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .summaryBoxSection {
    width: 100%;
  }
  .summaryBoxSection .contentBoxSectionWrapper {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
  }
  .summaryBoxSection .visibilityContainer {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
  }
  .summaryBoxSection .visibilityContainer,
  .summaryBoxSection .videoPreview {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 767px) {
  .summaryBoxSection {
    width: 100%;
  }
  .summaryBoxSection .contentBoxTitleContainer {
    display: none;
  }
}

.thumbnailBoxSection {
  background-color: transparent !important;
}

.thumbnailBoxSection .contentBoxTitle {
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .thumbnailBoxSection .contentBoxTitle {
    color: #fff;
    margin-bottom: 24px;
  }
}

.videoThumbnailsContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.thumbnailUploadButton {
  background-color: #b7b7b7;
  cursor: pointer;
}

.videoThumbnail {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.videoThumbnail::before {
  content: " ";
  display: block;
  height: 100%;
  padding-top: 80%;
  width: 100%;
}

.videoThumbnailAddIcon {
  height: 40%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  width: 40%;
}

.videoThumbnailImage {
  border: none;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  position: absolute;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentBoxContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentBoxContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentBoxContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentBoxContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentBoxContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentBoxContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentBoxContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentBoxContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .contentBoxContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .contentBoxContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .contentBoxContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .contentBoxContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .contentBoxContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .contentBoxContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .contentBoxContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .contentBoxContainer {
      padding-bottom: 40px;
    }
  }
}

.contentBoxSectionWrapper {
  position: relative;
}

.contentBoxSection {
  background-color: #fff;
  display: block;
}

.contentBoxSection:nth-child(2) {
  margin-top: 5%;
}

.contentBox {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.contentBox.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.inputField {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #dadadb;
  cursor: text !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.inputField.alignLeft,
.inputField.alignLeft * {
  text-align: left !important;
}

.inputField.inputFieldFocus {
  border-color: #5aa5ef !important;
}

.inputField.inputFieldFocus .inputFieldLabel {
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.inputFieldText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
}

.inputFieldLabel {
  color: #000 !important;
  font-weight: 600 !important;
  opacity: 0.9 !important;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

@media only screen and (max-width: 767px) {
  .inputFieldLabel {
    -webkit-transform-origin: top center;
    transform-origin: top center;
  }
}

@media only screen and (min-width: 768px) {
  .inputFieldLabel {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
}

.input {
  border: none;
  color: #918e8e;
  font-family: "Montserrat" !important;
  line-height: 1;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  outline: none;
  padding: 0;
  resize: none;
  text-overflow: ellipsis;
  width: 100%;
}

.progressTracker {

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 10;
}

.currentStepName {
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .currentStepName {
    display: none;
  }
}

.progressTrackerStepContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.progressTrackerStepContainer:first-of-type {
  margin-left: 0 !important;
}

.progressTrackerStepBar {
  border: 1px solid #02071a;
  height: 0px;
  opacity: 0.1;
}

.progressTrackerStepCount {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ededed;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.currentProgressStep .progressTrackerStepCount {
  background-color: #01040f;
  color: #fff;
}

.progressTrackerStepName {
  color: #b9b8b8;
}

.currentProgressStep .progressTrackerStepName {
  color: #5aa5ef;
}

.backButton,
.closeButton {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.backButton {
  color: #fff;
  cursor: pointer;
  margin-left: -12px;
}

@media only screen and (min-width: 768px) {
  .backButton {
    display: none;
  }
}

.closeButton {
  cursor: pointer;
  margin-left: 2%;
}

@media only screen and (max-width: 767px) {
  .closeButton {
    display: none;
  }
}

.header {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.headerContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom-color: rgba(112, 112, 112, 0.15);
  border-bottom-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 48px;
}

@media only screen and (max-width: 767px) {
  .headerContent {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.headerText {
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerTextSelectable {
  user-select: text;
  cursor: text;
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .body {
    background-color: #1a1d2a;
  }
  .addToExisting {
    font-size: 12px;
    color: #fff;
  }
  .addAsNewButton {
    padding: 0px !important;
    height: 20px !important;
    width: 53px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
  .chooseAlbum {
    padding: 0px !important;
    height: 20px !important;
    width: 53px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
  .cancelButton {
    border-color: #fff !important;
    width: 100px !important;
  }
  .nextButton {
    color: #fff !important;
    width: 100px !important;
  }
  .iconContainer {
    /* width: 50px !important;
    height: 38px !important; */
    display: none;
  }
  .processingText {
    font-size: 10px !important;
    color: #fff;
    margin-top: 3px;
  }
  /* .addMorePhoto {
    width: 90% !important;
    height: 150px !important;
  }
  .selectedImage {
    width: 90% !important;
    height: 150px !important;
  } */
}

@media only screen and (min-width: 768px) {
  .body {
    background-color: #ffffff;
    width: calc(
      100vw - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)
    );
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .body {
    height: calc(100vh - (var(--header-height))) !important;
  }
}

@media only screen and (min-width: 900px) {
  .body {
    height: calc(100vh - (var(--header-height))) !important;
    /* upload-modal-height-rs */
    /* height: calc(100vh - (var(--header-height) * 2) - ((var(--header-height) / 8) * 2)) !important; */
  }
}

@media only screen and (max-height: 568px) {
  .body {
    border-radius: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.addFilesSection {
  cursor: pointer;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .addFilesSection {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .addFilesSection {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .addFilesSection {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .addFilesSection {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .addFilesSection {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .addFilesSection {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .addFilesSection {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .addFilesSection {
      padding-right: 40px;
    }
  }
}

.addFilesSection,
.addFilesSectionImageContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.addFilesSectionImageContainer {
  background-color: #faf8f8;
  border-radius: 50%;
}

.addFilesSectionImage {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  width: 100%;
}

.addFilesSectionText {
  color: #9f9e9e;
  text-align: center;
}

.addFilesSectionTextTitle {
  font-weight: 500;
}

.addFilesSectionTextDescription {
  font-weight: 300;
}

.addFilesSectionButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-color: #dbdbdb;
  border-style: solid;
  color: #7b7c7e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}

.addFilesSectionButton:hover {
  background-color: #9f9e9e !important;
}

.previewFilesSectionButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-color: #dbdbdb;
  border-style: solid;
  color: #7b7c7e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}

.footer {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-top: 24px !important;
}

@media only screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}

.footerContent {
  border-top-color: rgba(112, 112, 112, 0.15);
  border-top-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}

.footerContentInTwoEnd {
  border-top-color: rgba(112, 112, 112, 0.15);
  border-top-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .footerContent {
    margin-left: auto;
    max-width: 360px;
  }
  /* .footerContentInTwoEnd {
    margin-left: auto;
    max-width: 360px;
  } */
}

.uploadTerms {
  color: #5c5757;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .videoPreview {
    margin-left: 16px;
    margin-top: 16px;
  }
  .thumbnailBoxSection .contentBoxDescription {
    margin-bottom: 24.75px;
  }
  .videoThumbnailsContainer {
    gap: 11px;
  }
  .videoThumbnail {
    border-radius: 5px;
  }
  .contentBoxSection {
    border-radius: 5px;
    padding: 16px;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 51px;
  }
  .thumbnailBoxSection {
    padding: 0;
  }
  .thumbnailBoxSection .contentBoxTitleContainer {
    padding: 0;
  }
  .categoriesContainer {
    gap: 16px;
  }
  .selectBox,
  .inputField {
    border-radius: 5px !important;
  }
  .inputField {
    margin-top: 16px;
  }
  .inputFieldLabel {
    font-size: 17px;
    height: 17px;
    margin-bottom: 17px;
    text-align: center;
  }
  .input {
    font-size: 15px;
    text-align: center;
  }
  .contentBoxTitleContainer {
    padding: 0 0 12px 0;
  }
  .contentBoxTitle {
    font-size: 20px;
    margin-bottom: 8px;
    margin-top: 18px;
  }
  .videoPreview {
    display: none;
  }
  .inputField {
    border-radius: 10px;
  }
  .inputField {
    padding: 20px;
  }
  .progressTracker {
    display: -ms-grid !important;
    display: grid !important;
    gap: 14.65px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 12px 0 24px 0;
  }
  .progressTrackerStepContainer {
    background-color: #707070;
    border-radius: 5px;
    height: 5px;
    opacity: 0.33;
  }
  .currentProgressStep {
    background-color: #fff !important;
    opacity: 1 !important;
  }
  .progressTrackerStepBar {
    display: none;
  }
  .progressTrackerStepCount {
    display: none;
  }
  .progressTrackerStepName {
    display: none;
  }
  .addFilesSectionImageContainer {
    height: 70px;
    margin-bottom: 12.714px;
    width: 70px;
  }
  .addFilesSectionButton {
    border-radius: 4.6875px;
    border-width: 3px;
    display: none;
  }
  .previewFilesSectionButton {
    border-radius: 4.6875px;
    border-width: 3px;
    margin: auto;
  }
  .addFilesSectionImage {
    width: 36.5625px;
  }
  .addFilesSectionText {
    font-size: 9px;
  }
  .addFilesSectionButton {
    margin: auto;
  }
  .addFilesSection {
    background-color: #fff;
    border-radius: 5px;
    padding: 51px 0;
    width: 100%;
  }
  .footerContent {
    border-top-width: 0px;
  }
  .footerContentInTwoEnd {
    border-top-width: 0px;
  }
  .progressTracker {
    border-bottom-width: 0px;
  }
  .headerContent {
    border-bottom-width: 0px;
    padding-bottom: 28px;
  }
  .closeButton {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
  .headerText {
    color: #fff !important;
    font-size: 21px !important;
    font-weight: 500 !important;
  }
  .body {
    height: 100%;
  }
  .header {
    padding: 24px 24px 0 24px !important;
  }
  .content {
    padding: 0 24px !important;
  }
  .footer {
    padding-top: 24px;
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-bottom: max(32px, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-bottom: max(unset, env(safe-area-inset-bottom)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-bottom: 32px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-bottom: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-bottom: unset !important;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-left: max(24px, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-left: max(unset, env(safe-area-inset-left)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-left: 24px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-left: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-left: unset !important;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-right: max(24px, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-right: max(unset, env(safe-area-inset-right)) !important;
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .footer {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .footer {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .footer {
        padding-right: 24px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .footer {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .footer {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .footer {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .footer {
        padding-right: unset !important;
      }
    }
    @media only screen and (min-width: 1710px) {
      .footer {
        padding-right: unset !important;
      }
    }
  }
  .uploadTerms {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .videoPreview {
    margin-left: 10px;
    margin-top: 28.75px;
  }
  .thumbnailBoxSection .contentBoxDescription {
    margin-bottom: 27.21094px;
  }
  .videoThumbnailsContainer {
    gap: 12.09375px;
  }
  .videoThumbnail {
    border-radius: 5px;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 28.359375px;
  }
  .categoriesContainer {
    gap: 10px;
  }
  .selectBox,
  .inputField {
    margin-top: 10px;
  }
  .inputFieldLabel {
    font-size: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .videoPreviewDetails,
  .input {
    font-size: 14px;
  }
  .videoPreviewDetails {
    padding: 22.5px;
  }
  .videoPreview,
  .videoPreviewThumbnail,
  .videoPreviewDetails,
  .inputField {
    border-radius: 6.25px;
  }
  .videoPreviewThumbnail {
    height: 160px;
  }
  .videoPreview,
  .videoPreviewThumbnail {
    width: 286.21931260229px;
  }
  .inputField {
    padding: 22.5px 26.25px;
  }
  .progressTracker {
    padding-bottom: 23px;
    padding-top: 23px;
  }
  .progressTrackerStepContainer {
    margin-left: 8.125px;
  }
  .progressTrackerStepBar {
    margin-left: 8.125px;
    width: 102.5px;
  }
  .progressTrackerStepCount {
    font-size: 12.5px;
    height: 40px;
    width: 40px;
  }
  .progressTrackerStepName {
    font-size: 10px;
    margin-left: 8.125px;
  }
  .addFilesSectionImageContainer {
    height: 118.125px;
    margin-bottom: 11.55262px;
    width: 118.125px;
  }
  .addFilesSectionButton {
    border-radius: 6.25px;
    border-width: 1.875px;
    height: 39.375px;
    margin-top: 9.68625px;
    padding: 0 31.875px;
    width: 121.875px;
  }
  .previewFilesSectionButton {
    border-radius: 6.25px;
    border-width: 1.875px;
    height: 39.375px;
    margin-top: 9.68625px;
    padding: 0 31.875px;
    width: 121.875px;
  }
  .addFilesSectionImage {
    height: 48.75px;
    width: 48.75px;
  }
  .navButton,
  .contentBoxDescription,
  .addFilesSectionButton,
  .addFilesSectionText .previewFilesSectionButton {
    font-size: 12px !important;
  }
  .addFilesSection {
    margin: auto;
  }
  .footerContent {
    border-top-width: 1.78125px;
    padding-top: 19.21875px;
  }
  .footerContentInTwoEnd {
    border-top-width: 1.78125px;
    padding-top: 19.21875px;
  }
  .progressTracker {
    border-bottom-width: 1.78125px;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .closeButton {
    height: 15px;
    margin-right: -22.5px;
    margin-top: -30px;
    width: 15px;
  }
  .contentBoxTitle,
  .headerText {
    font-size: 18.75px;
  }
  .body {
    border-radius: 5px;
    max-width: 1033.5px;
  }
  .header {
    padding: 19.21875px 30.703124999999996px 0 30.703124999999996px !important;
  }
  .content {
    padding: 23px 30.70312px !important;
  }
  .footer {
    padding: 0 30.703124999999996px 19.21875px 30.703124999999996px !important;
  }
  .uploadTerms {
    font-size: 10.5px;
    max-width: 573.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .videoPreviewThumbnail {
    height: 190.9375px !important;
  }
  .videoPreview,
  .videoPreviewThumbnail {
    width: 341.56249999999994px !important;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 37.8125px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .videoPreview {
    margin-left: 12px;
    margin-top: 34.5px;
  }
  .thumbnailBoxSection .contentBoxDescription {
    margin-bottom: 32.65313px;
  }
  .videoThumbnailsContainer {
    gap: 14.512500000000001px;
  }
  .videoThumbnail {
    border-radius: 5px;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 15px;
  }
  .categoriesContainer {
    gap: 12px;
  }
  .selectBox,
  .inputField {
    margin-top: 12px;
  }
  .inputFieldLabel {
    font-size: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .videoPreviewDetails,
  .input {
    font-size: 14px;
  }
  .videoPreviewDetails {
    padding: 27px;
  }
  .videoPreview,
  .videoPreviewThumbnail,
  .videoPreviewDetails,
  .inputField {
    border-radius: 7.5px;
  }
  .videoPreviewThumbnail {
    height: 229.125px;
  }
  .videoPreview,
  .videoPreviewThumbnail {
    width: 409.87499999999994px;
  }
  .inputField {
    padding: 10px 21.499999999999996px;
  }
  .progressTracker {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .progressTrackerStepContainer {
    margin-left: 9.75px;
  }
  .progressTrackerStepBar {
    margin-left: 9.75px;
    width: 123px;
  }
  .progressTrackerStepCount {
    font-size: 15px;
    height: 48px;
    width: 48px;
  }
  .progressTrackerStepName {
    font-size: 12px;
    margin-left: 9.75px;
  }
  .addFilesSectionImageContainer {
    height: 141.75px;
    margin-bottom: 13.86315px;
    width: 141.75px;
  }
  .addFilesSectionButton {
    border-radius: 7.5px;
    border-width: 2.25px;
    height: 47.25px;
    margin-top: 11.6235px;
    padding: 0 38.25px;
    width: 146.25px;
  }
  .previewFilesSectionButton {
    border-radius: 7.5px;
    border-width: 2.25px;
    height: 47.25px;
    margin-top: 11.6235px;
    padding: 0 38.25px;
    width: 146.25px;
  }
  .addFilesSectionImage {
    height: 58.5px;
    width: 58.5px;
  }
  .navButton,
  .contentBoxDescription,
  .addFilesSectionButton,
  .addFilesSectionText,
  .previewFilesSectionButton {
    font-size: 12px !important;
  }
  .addFilesSection {
    margin: auto;
  }
  .footerContent {
    border-top-width: 1.78125px;
    padding-top: 10px;
  }
  .footerContentInTwoEnd {
    border-top-width: 1.78125px;
    padding-top: 30.75px;
  }
  .progressTracker {
    border: none;
    border-bottom-width: 1.78125px;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom:11px;
  }
  .closeButton {
    height: 18px;
    margin-right: -27px;
    margin-top: -36px;
    width: 18px;
  }
  .contentBoxTitle,
  .headerText {
    font-size: 22.5px;
  }
  .body {
    border-radius: 7.5px;
    max-width: 1033.5px;
  }
  .header {
    padding: 20px 49.12499999999999px 0 49.12499999999999px !important;
  }
  .content {
    padding: 0px 49.125px !important;
  }
  .footer {
    padding: 0 49.12499999999999px 30.75px 49.12499999999999px !important;
  }
  .uploadTerms {
    font-size: 10.5px;
    max-width: 688.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .videoPreview {
    margin-left: 14.25px;
    margin-top: 40.96875px;
  }
  .thumbnailBoxSection .contentBoxDescription {
    margin-bottom: 38.77559px;
  }
  .videoThumbnailsContainer {
    gap: 17.23359375px;
  }
  .videoThumbnail {
    border-radius: 5px;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 15px;
  }
  .categoriesContainer {
    gap: 14.25px;
  }
  .selectBox,
  .inputField {
    margin-top: 14.25px;
  }
  .inputFieldLabel {
    font-size: 17.8125px;
    height: 17.8125px;
    margin-bottom: 17.8125px;
  }
  .videoPreviewDetails,
  .input {
    font-size: 15px;
  }
  .videoPreviewDetails {
    padding: 32.0625px;
  }
  .videoPreview,
  .videoPreviewThumbnail,
  .videoPreviewDetails,
  .inputField {
    border-radius: 8.90625px;
  }
  .videoPreviewThumbnail {
    height: 272.0859375px;
  }
  .videoPreview,
  .videoPreviewThumbnail {
    width: 486.72656249999994px;
  }
  .inputField {
    padding: 10px 20.40625px;
  }
  .progressTracker {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .progressTrackerStepContainer {
    margin-left: 11.578125px;
  }
  .progressTrackerStepBar {
    margin-left: 11.578125px;
    width: 146.0625px;
  }
  .progressTrackerStepCount {
    font-size: 17.8125px;
    height: 57px;
    width: 57px;
  }
  .progressTrackerStepName {
    font-size: 14.25px;
    margin-left: 11.578125px;
  }
  .addFilesSectionImageContainer {
    height: 168.328125px;
    margin-bottom: 16.46249px;
    width: 168.328125px;
  }
  .addFilesSectionButton {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 56.109375px;
    margin-top: 13.80291px;
    padding: 0 45.421875px;
    width: 173.671875px;
  }
  .previewFilesSectionButton {
    border-radius: 8.90625px;
    border-width: 2.671875px;
    height: 56.109375px;
    margin-top: 13.80291px;
    padding: 0 45.421875px;
    width: 173.671875px;
  }
  .addFilesSectionImage {
    height: 69.46875px;
    width: 69.46875px;
  }
  .navButton,
  .contentBoxDescription,
  .addFilesSectionButton,
  .addFilesSectionText,
  .previewFilesSectionButton {
    font-size: 14.25px !important;
  }
  .addFilesSection {
    margin: auto;
  }
  .footerContent {
    border-top-width: 1.78125px;
    padding-top: 10px;
  }
  .footerContentInTwoEnd {
    border-top-width: 1.78125px;
    padding-top: 36.515625px;
  }
  .progressTracker {
    border-bottom-width: 1.78125px;
  }
  .headerContent {
    border-bottom-width: 1.78125px;
    padding-bottom: 11.5px;
  }
  .closeButton {
    height: 21.375px;
    margin-right: -32.0625px;
    margin-top: -42.75px;
    width: 21.375px;
  }
  .contentBoxTitle,
  .headerText {
    font-size: 26.71875px;
  }
  .body {
    border-radius: 8.90625px;
    max-width: 1227.28125px;
  }
  .header {
    padding: 20px 58.33593749999999px 0 58.33593749999999px !important;
  }
  .content {
    padding: 0px 58.33594px !important;
  }
  .footer {
    padding: 0 58.33593749999999px 10px 58.33593749999999px !important;
  }
  .uploadTerms {
    font-size: 12.46875px;
    max-width: 817.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .videoPreview {
    margin-left: 16px;
    margin-top: 46px;
  }
  .thumbnailBoxSection .contentBoxDescription {
    margin-bottom: 43.5375px;
  }
  .videoThumbnailsContainer {
    gap: 19.35px;
  }
  .videoThumbnail {
    border-radius: 5px;
  }
  .contentBoxSection:nth-child(2) {
    margin-top: 30.5px;
  }
  .categoriesContainer {
    gap: 16px;
  }
  .selectBox,
  .inputField {
    margin-top: 16px;
  }
  .inputFieldLabel {
    font-size: 20px;
    height: 20px;
    margin-bottom: 20px;
  }
  .videoPreviewDetails,
  .input {
    font-size: 16px;
  }
  .videoPreviewDetails {
    padding: 36px;
  }
  .videoPreview,
  .videoPreviewThumbnail,
  .videoPreviewDetails,
  .inputField {
    border-radius: 10px;
  }
  .videoPreviewThumbnail {
    height: 305.5px;
  }
  .videoPreview,
  .videoPreviewThumbnail {
    width: 546.5px;
  }
  .inputField {
    padding: 20px 20px;
  }
  .progressTracker {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .progressTrackerStepContainer {
    margin-left: 13px;
  }
  .progressTrackerStepBar {
    margin-left: 13px;
    width: 164px;
  }
  .progressTrackerStepCount {
    font-size: 20px;
    height: 64px;
    width: 64px;
  }
  .progressTrackerStepName {
    font-size: 16px;
    margin-left: 13px;
  }
  .addFilesSectionImageContainer {
    height: 189px;
    margin-bottom: 18.4842px;
    width: 189px;
  }
  .addFilesSectionButton {
    border-radius: 10px;
    border-width: 3px;
    height: 63px;
    margin-top: 15.498px;
    padding: 0 51px;
    width: 195px;
  }
  .previewFilesSectionButton {
    border-radius: 10px;
    border-width: 3px;
    height: 63px;
    margin-top: 15.498px;
    padding: 0 51px;
    width: 195px;
  }
  .addFilesSectionImage {
    height: 78px;
    width: 78px;
  }
  .navButton,
  .contentBoxDescription,
  .addFilesSectionButton,
  .addFilesSectionText,
  .previewFilesSectionButton {
    font-size: 16px !important;
  }
  .addFilesSection {
    margin: auto;
  }
  .footerContent {
    border-top-width: 2px;
    padding-top: 16px;
  }
  .footerContentInTwoEnd {
    border-top-width: 2px;
    padding-top: 41px;
  }
  .progressTracker {
    border-bottom-width: 2px;
  }
  .headerContent {
    border-bottom-width: 2px;
    padding-bottom: 11.5px;
  }
  .closeButton {
    height: 24px;
    margin-right: -36px;
    margin-top: -48px;
    width: 24px;
  }
  .contentBoxTitle,
  .headerText {
    font-size: 30px;
  }
  .body {
    border-radius: 10px;
    max-width: 1378px;
  }
  .header {
    padding: 25px 65.5px 0 65.5px !important;
  }
  .content {
    padding: 0px 65.5px !important;
  }
  .footer {
    padding: 0 65.5px 14px 65.5px !important;
  }
  .uploadTerms {
    font-size: 14px;
    max-width: 918px;
  }
}

.iconColumn {
  display: flex;
  justify-content: center !important;
  flex-direction: initial;
}

.separator {
  display: flex;
  justify-content: center;
}

.resizeSeparator {
  border-left: 2px solid rgba(112, 112, 112, 0.15) !important;
  height: 800px;
  margin-top: -92px;
}

.actionContainer {
  margin-top: 10px;
}

.alignCenterContainer {
  margin: 0 auto;
  margin-bottom: 100%;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}

.gallery img {
  width: 100%;
  height: auto;
}

.gallery {
  border: 1px solid #ccc;
}

.imageColumn {
  /* float: left;
  width: 33.33%; */
  padding: 10px;
  position: relative;
}

.closeIcon {
  position: absolute;
  right: 35px;
  color: #fff;
  background-color: #000;
  margin-top: 4px;
  cursor: pointer;
}

.alignRow {
  overflow: auto;
}

.addMorePhoto {
  border-color: #1583dd;
  border-width: 2px;
  border-style: dashed;
  text-align: center;
  height: 200px;
  width: 100%;
}

.addIconContainer {
  position: relative;
  top: calc(50% - 10px);
}

.addIcon {
  color: #1583dd;
  width: 100%;
  max-width: 400px;
}

.addPhotoText {
  color: #1583dd;
  font-family: "Proxima Nova";
  font-weight: 600;
  font-size: 17px;
}

.cancelButton {
  border-color: #000000;
  color: #ff0000;
  margin: 14px;
}

.nextButton {
  border-color: #5aa5ef;
  color: #000;
  margin: 14px;
}

.albumList {
  max-height: 128px;
  overflow-y: auto;
  width: 101%;
  display: grid;
  overflow-x: hidden;
}

.alignRow::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.alignRow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.alignRow::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.alignRow::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.albumTitle {
  font-weight: 600;
}

.albumList::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.albumList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.albumList::-webkit-scrollbar-thumb {
  background: #1172d2;
  border-radius: 25px;
}

/* Handle on hover */
.albumList::-webkit-scrollbar-thumb:hover {
  background: #1172d2;
}

.addToExisting {
  font-weight: 600;
}

.albumTitleText {
  font-weight: 600;
  font-family: "Poppins";
  position: relative;
  bottom: 2px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 18ch;
  vertical-align: middle;
}

.iconTextContainer {
  display: inline-block;
}

.checkBox {
  vertical-align: middle;
}

.albumListContainer {
  border-radius: 4vh;
  border: 3px solid;
  border-color: #eaeaea;
  width: 150%;
  margin-left: -30%;
  margin-top: 13px;
  padding: 9px;
}
.albumListContainer {
  border-radius: 4vh;
  border: 3px solid;
  border-color: #eaeaea;
width: 100%;
    margin-left: unset;
  margin-top: 13px;
  padding: 9px;
}


.buttonGroup {
  /* position: absolute; */
  /* width: 100%; */
  /* left: 50%;
  transform: translate(-50%, 0px); */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  /* grid-gap: 10px; */
  gap: 10px;
}


.addAsNewButton {
  font-weight: 600;
  background: #eaeaea;
  border-radius: 15px;
  padding: 9px;
  cursor: pointer;
  height: 42px;
  width: 130px;
  text-align: center;
}

.chooseAlbum {
  background: #1172d2;
  font-weight: 600;
  border-radius: 15px;
  padding: 9px;
  color: #fff;
  cursor: pointer;
  height: 42px;
  width: 130px;
  text-align: center;
}

.noAlbumFound {
  color: #000;
  text-align: center;
  font-weight: 500;
}

.iconColor {
  color: #707070 !important;
  margin-right: 6px;
}

.stopIcon {
  margin-left: -6px;
  color: #1172d2 !important;
}

.photoUploadColumn {
  position: relative;
}

.videoUploadColumn {
  position: relative;
}

@media only screen and (max-width: 319px) {
  .addAsNewButton {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .photoUploadColumn {
    left: 102px;
  }
  .videoUploadColumn {
    right: 19px;
  }
  .albumListContainer {
    border-radius: 2vh;
    width: 60%;
    margin-left: -25%;
    padding: 11px;
  }
  .addToExisting {
    font-size: 8px !important;
  }
  .addToExisting {
    position: relative;
    right: 27px;
  }
  .chooseAlbum {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .alignRow {
    grid-template-columns: 1fr !important;
  }
  .navButton {
    width: 80px;
  }
  .photoUploadColumn .addFilesSectionImageContainer {
    left: -15px;
    position: relative;
  }
  .withoutAlbum {
    left: 14px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 419px) {
  .addAsNewButton {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .photoUploadColumn {
    left: 102px;
  }
  .videoUploadColumn {
    right: 19px;
  }
  .albumListContainer {
    border-radius: 2vh;
    width: 65%;
    margin-left: -25%;
    padding: 11px;
  }
  .addToExisting {
    position: relative;
    right: 32px;
  }
  .addToExisting {
    font-size: 9px !important;
  }
  .chooseAlbum {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .alignRow {
    grid-template-columns: 1fr 1fr !important;
  }
  .navButton {
    width: 100px;
  }
  .photoUploadColumn .addFilesSectionImageContainer {
    left: -15px;
    position: relative;
  }
  .withoutAlbum {
    left: 14px !important;
  }
}
@media only screen and (min-width: 420px) and (max-width: 767px) {
  .addAsNewButton {
    border-radius: 6px;
    padding: 5px;
    height: 37px;
    width: 115px;
  }
  .photoUploadColumn {
    left: 48px !important;
  }
  .videoUploadColumn {
    /* right: 19px; */
    left: 19px;
  }
  .albumListContainer {
    border-radius: 2vh;
    width: 81%;
    margin-left: -14%;
    padding: 11px;
  }
  .addToExisting {
    position: relative;
    right: 5px;
  }
  .albumTitleText {
    font-size: 15px;
  }
  .chooseAlbum {
    border-radius: 6px;
    padding: 5px;
    height: 37px;
    width: 115px;
  }
  .withoutAlbum {
    left: -21px !important;
  }
  .alignRow {
    grid-template-columns: 1fr 1fr !important;
  }
  .navButton {
    width: 130px;
  }
  .photoUploadColumn .addFilesSectionImageContainer {
    left: 27px;
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .addAsNewButton {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .photoUploadColumn {
    left: 35px;
  }
  .videoUploadColumn {
    left: 33px;
    /* right: 19px; */
  }
  .albumListContainer {
    border-radius: 2vh;
    width: 103%;
    margin-left: -25%;
    padding: 11px;
  }
  .addToExisting {
    position: relative;
    right: 26px;
  }
  .chooseAlbum {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 115px;
  }
  .withoutAlbum {
    left: -33px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .addAsNewButton {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .photoUploadColumn {
    /* left: 93px; */
    left: 36px;
  }
  .videoUploadColumn {
    /* right: 19px; */
    left: 37px;
  }
  .albumListContainer {
    border-radius: 3vh;
    width: 107%;
    margin-left: -26%;
    padding: 13px;
  }
  .addToExisting {
    position: relative;
    right: 15px;
  }
  .chooseAlbum {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .withoutAlbum {
    left: -50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .addAsNewButton {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .photoUploadColumn {
    left: 0;
  }
  .videoUploadColumn {
    /* right: 42px; */
    /* left: 37px; */
  }
  .albumListContainer {
    border-radius: 3vh;
    width: 100%;
    margin-left: unset;
    padding: 14px;
  }
  .addToExisting {
    position: relative;
    /* right: 15px; */
  }
  .chooseAlbum {
    border-radius: 7px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .withoutAlbum {
    left: -47px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .addAsNewButton {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .photoUploadColumn {
    /* left: 66px; */
    right: 9px;
  }
  .videoUploadColumn {
    /* right: 42px; */
    /* left: 49px; */
  }
  .albumListContainer {
    border-radius: 3vh;
    width: 100%;
    margin-left: unset;
    padding: 14px;
  }
  .albumList {
    max-height: 138px;
  }
  .chooseAlbum {
    border-radius: 8px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .withoutAlbum {
    right: 60px;
  }
}

@media only screen and (min-width: 1710px) {
  .addAsNewButton {
    border-radius: 10px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
  .photoUploadColumn {
    left: -3px;
  }
  .albumListContainer {
    /* margin-left: -40%; */
    padding: 16px;
  }
  .albumList {
    padding: 12px;
    max-height: 150px;
  }
  .videoUploadColumn {
    /* right: 56px; */
    /* left: 41px; */
  }
  .withoutAlbum {
    left: -41px;
  }
  .chooseAlbum {
    border-radius: 10px;
    padding: 9px;
    height: 42px;
    width: 130px;
  }
}

@media only screen and (min-height: 740px) and (max-height: 840px) {
  .albumList {
    max-height: 105px;
  }
  .addAsNewButton {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .chooseAlbum {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .albumListContainer {
    margin-top: 1px;
    padding: 11px;
  }
  .addFilesSectionImageContainer {
    margin-bottom: 1.86315px;
  }
}

@media only screen and (max-height: 739px) {
  .albumList {
    max-height: 100px !important;
  }
  .addAsNewButton {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .chooseAlbum {
    padding: 4px;
    height: 33px;
    width: 126px;
  }
  .albumListContainer {
    margin-top: 1px;
    padding: 11px;
  }
  .addFilesSectionImageContainer {
    margin-bottom: 1.86315px;
  }
}

.progressbar {
  width: 50%;
  padding: 10px;
  /* position: relative; */
  /* right: 10%; */
  /* display: inline-block; */
}

.progressbarIn1 {
  right: 42%;
}

.progressbarIn2 {
  right: 34%;
}

.progressbarIn3 {
  right: 33%;
}

.backgroundImageList {
  display: grid;
  grid-template-columns: 200px 200px;
  /* display: flex;
  flex-grow: unset;
  flex-wrap: wrap; */
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.backgroundImageList::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.backgroundImageList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.backgroundImageList::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
.backgroundImageList::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .backgroundImageList .backGroundImage {
  width: 50%;
} */
/* 
.backgroundImageList img {
  width: calc(100% - (30px * 2));
  margin: 30px;
} */

.buttonOnTwoEnd {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.buttonOnEnd {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.iconContainer {
  width: 125px;
  height: 50px;
  border-radius: 12px;
  background-color: #b7b7b7;
}

.videoUploadIcon {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leftContainer {
  width: 80%;
  display: flex;
}

.processingText {
  font-weight: 600;
  font-size: 15px;
}

.dropDownAnimation {
  animation: growOut 700ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.alignRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.selectedImage {
  height: 200px;
  width: 100%;
}

.progressContainer {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
/* upload percentage */
.uploadpercentage {
  position: absolute;
  background: #fff;
  border: 2px solid #dadadb;
  border-radius: 7.5px;
  /* top: 50%; */
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  place-items: center;
  /* width: 268px; */
  /* left: 50%; */
  /* min-height: 5rem; */
  /* transform: translate(-50%, 17%); */
}
/* @media (max-width:900px) {
  .uploadpercentage{
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 3rem;
    transform: translate(-50%, 17%);
    transform: translate(-41, -50px);
  }
} */
@media (max-width: 600px) {
  .uploadpercentage {
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 5rem;
    transform: translate(-50%, 17%);
    transform: translate(-41, -50px);
  }
}
@media (max-width: 480px) {
  .uploadpercentage {
    top: 50%;
    width: 268px;
    left: 50%;
    min-height: 5rem;
    transform: translate(-50%, 17%);
    transform: translate(-41, -50px);
  }
}
.generatingMsg {
  padding-left: 20px;
  padding-right: 20px;
}
