.pageContainer {
  background: radial-gradient(circle at center, #1f2330, #07080c);
  width: 100%;
}

.message {
  color: white;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .message {
    font-size: 18.125px;
  }
}
.daysTrails {
  text-align: center;
  color: #0075ff;
  font-weight: 700;
  margin-top: 0;
  font-size: 20px;
  font-family: "Montserrat" !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .message {
    font-size: 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .message {
    font-size: 25.828125px;
  }
}

@media only screen and (min-width: 1710px) {
  .message {
    font-size: 29px;
  }
}

.plansList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .plansList {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 30px;
  }

  .col {
    margin: 20px 30px !important;
  }
}

@media only screen and (max-width: 600px) {
  .eachPlan {
    width: 85% !important;
    margin: 30px auto 0;
  }
}

@media only screen and (min-width: 768px) {
  .plansList {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.plan {
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.plans {
  display: flex;
  place-content: center;
}

@media (max-width: 600px) {
  .plans {
    flex-direction: column;
  }
}

.col {
  width: 33.33333%;
  margin: 0 30px;
}

.plan_head {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  padding-bottom: 25px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.10); */
}

/* .eachPlan {
  width: 35%;
  display: block;
  padding: 10px 28px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
} */

.eachPlan {
  /* width: 100%; */
  display: block;
  padding: 18px;
  border-radius: 15px;
  color: #1d263a;
  background-color: #fff;
  min-width: 250px;
  width: 290px;
}
.feat{
  font-size: 14px;
  font-weight: 600
}
.plan_name p,
.plan_price p {
  font-weight: 700;
  margin: 0;
  font-size: 20px;
}

.plan_name span,
.plan_price span {
  font-size: 14px;
  opacity: 0.7;
}

.plan_price {
  text-align: right;
}

@media (max-width: 992px) {
  .plan_name p,
  .plan_price p {
    font-size: 18px;
  }

  .plan_body li {
    font-size: 14px;
  }

  .eachPlan {
    padding: 20px;
  }
}

.plan_body {
  width: 100%;
  padding: 20px 0;
  margin: 0;
}

.plan_body li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  list-style: none;
  margin-bottom: 10px;
  grid-gap: 10px;
  gap: 0;
  width: 160px;
}

.plan_blue {
  color: #fff;
  background-color: #0075ff;
}

.eachPlan .btn {
  width: 100%;
  display: flex;
  margin: 10px 0 0 0;
  padding: 10px 28px;
  height: auto;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 700;
}

.btn {
  border-radius: 35px;
  font-size: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 8px 28px;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.3s;
  cursor: pointer;
}
.buyNow:hover{
  box-shadow: 0 0 10px black !important;
}
.buyNow{
  width: 100% !important;
  display: flex !important;;
  margin: 10px 0 0 0 !important;
  padding: 10px 28px !important;
  height: auto !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: none !important;
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 35px !important;
  font-size: 15px !important;
  text-align: center !important;
  padding: 8px 28px !important;
  color: black !important;
  text-decoration: none !important;
  border: 1px solid black !important;
  background-color: transparent !important;
  transition: all 0.3s !important;
  cursor: pointer !important;
}
.tickster{
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
  pointer-events: none;
}

.featureText {
  font-size: 16px;
}

.featureTextWhite {
  color: #fff;
}

.featureTextBlue {
  color: #000;
}

.plan > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.plan,
.plan * {
  font-family: "Poppins";
}

.plan:not(.suggested) .name {
  background-color: #5aa5ef48;
  color: #585858;
}

.plan:not(.suggested) .tryPlanButton {
  border-color: #5aa5ef;
  border-width: 1px;
  color: #5aa5ef;
}

@media all and (hover: hover) {
  .plan:not(.suggested) .tryPlanButton:hover {
    background-color: #5aa5ef;
  }
}

@media all and (pointer: coarse) {
  .plan:not(.suggested) .tryPlanButton:active,
  .plan:not(.suggested) .tryPlanButton:target {
    background-color: #5aa5ef;
  }
}

/* .plan.suggested::before {
  background-color: #5aa5ef;
  bottom: 100%;
  display: block;
  height: 29px;
  content: ' ';
  left: 0;
  position: absolute;
  width: 100%;
} */

.plan.auto::before,
.plan.upgradePlan::before,
.plan.currentPlan::before,
.plan.currentFreePlan::before,
.plan.degradePlan::before {
  background-color: #5aa5ef;
  bottom: 100%;
  display: flex;
  height: 29px;
  left: 0;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* color: white; */
}

.plan.auto::before {
  content: " ";
}

.plan.upgradePlan::before {
  content: "Upgrade Plan";
}

.plan.degradePlan::before {
  content: "Degrade Plan";
}

.plan.currentPlan::before {
  content: "Current Plan";
}

.plan.currentFreePlan::before {
  content: "Current Free Plan";
}

.plan.suggested .name {
  background-color: #1172d2;
  color: #fff;
}

.plan.suggested .tryPlanButton {
  background-color: #1172d2;
  border-color: #1172d2;
  color: #fff;
}

@media all and (hover: hover) {
  .plan.suggested .tryPlanButton:hover {
    background-color: #1172d2 !important;
  }
}

@media all and (pointer: coarse) {
  .plan.suggested .tryPlanButton:active,
  .plan.suggested .tryPlanButton:target {
    background-color: #1172d2 !important;
  }
}

@media only screen and (max-width: 767px) {
  .plan {
    margin-bottom: 96px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .plan {
    margin: 0 24.375px;
  }
}

@media only screen and (max-width: 1199px) {
  .plan {
    height: 376.25px;
    width: 255px;
  }

  .plan.suggested::before,
  .plan.auto::before {
    height: 24.375px;
  }

  .planContent {
    padding: 0 15px 15px 15px;
  }

  .name {
    font-size: 16.875px;
    height: 60.625px;
    padding: 0 15px;
  }

  .price {
    margin: 23.75px 0;
  }

  .priceAmount {
    font-size: 31.875px;
  }

  .priceDetails {
    font-size: 8.75px;
    margin-left: 4.375px;
  }

  .featureIcon {
    height: 18.75px;
    margin-right: 9.375px;
    width: 18.75px;
  }

  .featureIcon .MuiRadio-root {
    padding: "0";
    color: "#5aa5ef";
  }

  .featureIcon .MuiRadio-colorPrimary {
    color: "#5aa5ef";
  }

  .feature {
    font-size: 14px;
    margin: 12.5px 0;
    /* height: 60px; */
  }

  .tryPlanButton {
    font-size: 13.125px !important;
    height: 36.25px !important;
  }

  .purchase {
    font-size: 16.25px;
    margin-top: 6.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .plan {
    height: 451.5px;
    margin: 0 29.25px;
    width: 306px;
  }

  .plan.suggested::before,
  .plan.auto::before {
    height: 29.25px;
  }

  .planContent {
    padding: 0 18px 18px 18px;
  }

  .name {
    font-size: 20.25px;
    height: 72.75px;
    padding: 0 18px;
  }

  .price {
    margin: 28.5px 0;
  }

  .priceAmount {
    font-size: 38.25px;
  }

  .priceDetails {
    font-size: 10.5px;
    margin-left: 5.25px;
  }

  .featureIcon {
    height: 22.5px;
    margin-right: 11.25px;
    width: 22.5px;
  }

  .feature {
    font-size: 17px;
    margin: 15px 0;
  }

  .tryPlanButton {
    font-size: 15.749999999999998px !important;
    height: 43.5px !important;
  }

  .purchase {
    font-size: 19.5px;
    margin-top: 7.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .plan {
    height: 536.15625px;
    margin: 0 34.73438px;
    width: 363.375px;
  }

  .plan.suggested::before,
  .plan.auto::before {
    height: 34.734375px;
  }

  .planContent {
    padding: 0 21.375px 21.375px 21.375px;
  }

  .name {
    font-size: 24.046875px;
    height: 86.390625px;
    padding: 0 21.375px;
  }

  .price {
    margin: 33.84375px 0;
  }

  .priceAmount {
    font-size: 45.421875px;
  }

  .priceDetails {
    font-size: 12.46875px;
    margin-left: 6.23438px;
  }

  .featureIcon {
    height: 26.71875px;
    margin-right: 13.35938px;
    width: 26.71875px;
  }

  .feature {
    font-size: 18.703125px;
    margin: 17.8125px 0;
  }

  .tryPlanButton {
    font-size: 18.703125px !important;
    height: 51.65625px !important;
  }

  .purchase {
    font-size: 23.15625px;
    margin-top: 8.90625px;
  }
}

@media only screen and (min-width: 1710px) {
  .plan {
    height: 602px;
    margin: 0 39px;
    width: 408px;
  }

  .plan.suggested::before,
  .plan.auto::before {
    height: 39px;
  }

  .planContent {
    padding: 0 24px 24px 24px;
  }

  .name {
    font-size: 27px;
    height: 97px;
    padding: 0 24px;
  }

  .price {
    margin: 38px 0;
  }

  .priceAmount {
    font-size: 51px;
  }

  .priceDetails {
    font-size: 14px;
    margin-left: 7px;
  }

  .featureIcon {
    height: 30px;
    margin-right: 15px;
    width: 30px;
  }

  .feature {
    font-size: 21px;
    margin: 20px 0;
  }

  .tryPlanButton {
    font-size: 21px !important;
    height: 58px !important;
  }

  .purchase {
    font-size: 26px;
    margin-top: 10px;
  }
}

.name {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
}

.planContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.price {
  color: #585858;
  font-weight: 300;
}

.priceAmount {
  line-height: 1.35;
}

.priceDetails {
  line-height: 0.9;
}

.featuresList {
  display: block;
}

.feature {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #585858;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
  text-align: left;
  height: auto;
}

.featureIcon {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.tryPlan {
  color: #000;
  margin-top: auto;
  text-align: center;
}

.tryPlanButton {
  text-transform: none !important;
}

.purchaseLink {
  color: #1172d2;
  text-decoration: none;
}

@media all and (hover: hover) {
  .purchaseLink:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .purchaseLink:active,
  .purchaseLink:target {
    text-decoration: underline;
  }
}

.planContainer {
  width: 1320px;
  padding: 0 15px;
  margin: auto;
}

@media (max-width: 992px) {
  .planContainer {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .planContainer {
    width: 960px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .planContainer {
    width: 1140px;
  }
}
