.imageGroupImage {
  bottom: 160px;
  height: 130px;
  right: 30px;
  object-fit: fill;
  object-position: center;
  position: absolute;
  width: 260px;
  border-radius: 5px;
}
.imageGroupImage:hover {
    border: 2px solid white;
}

.nextVideoWrapper {
  display: block;
  cursor: pointer;
}

.titleContainer {
  width: 260px;
  height: 50px;
  position: absolute;
  right: 30px;
  bottom: 130px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.titleContainer h4 {
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 150px; 
  height: 2em; 
  white-space: nowrap;
}

.nextVideoText {
  width: 260px;
  height: 50px;
  position: absolute;
  right: 30px;
  bottom: 100px;
  color: #fff;
}
