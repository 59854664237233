.emailVerifyWrapper {
  text-align: center;
}
.verifyingEmail {
  color: #5aa5ef;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}
.verified {
  color: green;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}
.notVerified {
  color: red;
  font-size: 30px;
  padding: 20px;
  font-weight: 600;
}