.sidebar {
  background-color: transparent;
  color: #000 !important;
  font-weight: 600 !important;
  margin-bottom: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebarContent {
  position: fixed !important;
  left: 0;
  background: white;
  z-index: 100 !important;
}
.existButtun {
  margin: 0 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  opacity: 1;
  width: 164px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  letter-spacing: 0px;
  cursor: pointer;
  color: #1a1c22;
  opacity: 1;
}
.existButtun:hover {
  background: #579ce1 !important;
}
.accessedCompny {
  display: flex;
  background-color: #1172d2;
  width: 100%;
  z-index: 111;
  cursor: pointer;
  position: fixed;
  height: 39px;
  align-items: center;
  justify-content: start;
  text-align: left;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  padding: 5px 23px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.link {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  color: #000 !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  font-weight: 600 !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  position: relative !important;
  /* height: 80px; */
}
@media only screen and (min-width: 900px) {
  .sidebar {
    position: relative;
  }
}
.currentLink {
  align-items: center !important;
  color: #000 !important;
  display: flex !important;
  flex-direction: column !important;
  font-weight: 600 !important;
  margin-bottom: 44px;
  justify-content: center !important;
  position: relative !important;
  border-right: 1px solid #5aa5ef;
}
@media only screen and (max-width: 899px) {
  .sidebar {
    bottom: 0;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 98;
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar {
        padding-bottom: max(-1px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebar {
        padding-bottom: -1px;
      }
    }
  }
  .sidebar.noBottomBar {
    display: none !important;
  }
}

.noFixedSidebar {
  display: none !important;
}

@media only screen and (max-width: 899px) {
  .noBottomBar {
    display: none !important;
  }
}

.sidebarFill {
  opacity: 0;
  -webkit-box-ordinal-group: 101;
  -ms-flex-order: 100;
  order: 100;
  position: relative !important;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebarFill {
      padding-bottom: max(-1px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .sidebarFill {
      padding-bottom: -1px;
    }
  }
}

@media only screen and (min-width: 900px) {
  .sidebarFill {
    display: none;
  }
}

.sidebarContent {
  width: 100%;
  position: fixed;
  left: 0;
  background: transparent;
  z-index: 10000000;
}

.sidebarContent::-webkit-scrollbar {
  width: 4px;
}

.sidebarContent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebarContent::-webkit-scrollbar-thumb {
  background: #6e6f6e;
  border-radius: 25px;
}

.sidebarContent::-webkit-scrollbar-thumb:hover {
  background: #6e6f6e;
}

@media only screen and (max-width: 899px) {
  .sidebarContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 49px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
  }
}

@media only screen and (min-width: 900px) {
  .sidebarContent {
    display: block;
    max-height: calc(100vh - var(--header-height));
    overflow-y: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: var(--header-height);
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-top: max(12px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-top: max(16px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-top: max(20px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-top: max(24px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-top: max(28px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-top: max(32px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-top: max(36px, env(safe-area-inset-top));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-top: max(40px, env(safe-area-inset-top));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-top: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-top: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-top: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-top: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-top: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-top: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-top: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-top: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-bottom: max(12px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-bottom: max(16px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-bottom: max(20px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-bottom: max(24px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-bottom: max(28px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-bottom: max(32px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-bottom: max(36px, env(safe-area-inset-bottom));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-bottom: max(40px, env(safe-area-inset-bottom));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-bottom: 12px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-bottom: 16px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-bottom: 20px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-bottom: 24px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-bottom: 28px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-bottom: 32px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-bottom: 36px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-bottom: 40px;
      }
    }
  }
  @supports (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-left: max(-1px, env(safe-area-inset-left));
      }
    }
  }
  @supports not (padding: max(0px)) {
    @media only screen and (max-width: 319px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 419px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 420px) and (max-width: 767px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 899px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 900px) and (max-width: 1199px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1441px) and (max-width: 1709px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
    @media only screen and (min-width: 1710px) {
      .sidebarContent {
        padding-left: -1px;
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .sidebarContent {
    width: 77.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .sidebarContent {
    width: 91.734375px;
  }
}

@media only screen and (min-width: 1710px) {
  .sidebarContent {
    width: 103px;
  }
}

.link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-weight: 600;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  /* height: 80px; */
}

@media only screen and (max-width: 899px) {
  .link {
    height: 100%;
    width: 49px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .link {
    margin: 33px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .link {
    margin: 39.1875px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .link {
    margin: 44px 0;
  }
}

.link:first-of-type {
  margin-top: 0 !important;
}

@media only screen and (max-width: 899px) {
  .currentLink {
    color: #5aa5ef;
    height: 100%;
    width: 49px;
  }
}

.currentLink::before {
  background-color: #5aa5ef;
  content: " ";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 899px) {
  .currentLink::before {
    display: none !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .currentLink::before {
    width: 3px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .currentLink::before {
    width: 3.5625px;
  }
}

@media only screen and (min-width: 1710px) {
  .currentLink::before {
    width: 4px;
  }
}

.linkIcon {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .linkIcon {
    height: 28.9px;
    width: 28.9px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .linkIcon {
    height: 32.625px;
    margin-top: 12px;
    width: 32.625px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkIcon {
    height: 38.7421875px;
    width: 38.7421875px;
    margin: 14.25px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
}

@media only screen and (min-width: 1710px) {
  .linkIcon {
    height: 43.5px;
    margin-top: 16px;
    width: 43.5px;
  }
}

.linkName {
  line-height: 1.2;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .linkName {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .linkName {
    font-size: 10.5px;
    margin-bottom: 7.5px;
    margin-top: 6px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .linkName {
    font-size: 12.46875px !important;
    margin-bottom: 8.90625px !important;
    margin-top: 7.125px !important;
    color: #000 !important;
    font-family: "Poppins";
  }
}

@media only screen and (min-width: 1710px) {
  .linkName {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 8px;
  }
}

.slidingSidebarContainer {
  position: relative;
}

.slidingSidebar {
  max-width: 360px;
  width: calc(100vw - 32px);
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebar {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebar {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebar {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebar {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebar {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebar {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebar {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebar {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebar {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebar {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebar {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebar {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebar {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebar {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebar {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebar {
      padding-bottom: 40px;
    }
  }
}

.logoContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 16px;
}

@media only screen and (max-width: 899px) {
  .logoContainer {
    background-color: #000;
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-top: max(12px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-top: max(16px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-top: max(20px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-top: max(24px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-top: max(28px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-top: max(32px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-top: max(36px, env(safe-area-inset-top));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-top: max(40px, env(safe-area-inset-top));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-top: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-top: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-top: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-top: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-top: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-top: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-top: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-bottom: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-left: max(12px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-left: max(16px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-left: max(20px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-left: max(24px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-left: max(28px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-left: max(32px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-left: max(36px, env(safe-area-inset-left));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-left: max(40px, env(safe-area-inset-left));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-left: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-left: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-left: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-left: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-left: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-left: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-left: 40px;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-right: max(12px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-right: max(16px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-right: max(24px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-right: max(28px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-right: max(32px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-right: max(36px, env(safe-area-inset-right));
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-right: max(40px, env(safe-area-inset-right));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .logoContainer {
      padding-right: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .logoContainer {
      padding-right: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .logoContainer {
      padding-right: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .logoContainer {
      padding-right: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .logoContainer {
      padding-right: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .logoContainer {
      padding-right: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .logoContainer {
      padding-right: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .logoContainer {
      padding-right: 40px;
    }
  }
}

.slidingSidebarLinksContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(12px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(16px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(24px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(28px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(32px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(36px, env(safe-area-inset-bottom));
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLinksContainer {
      padding-bottom: max(40px, env(safe-area-inset-bottom));
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 12px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 16px;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 24px;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 28px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 32px;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 36px;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLinksContainer {
      padding-bottom: 40px;
    }
  }
}

.slidingSidebarLink {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  padding: 12px 0;
  width: 100%;
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-left: max(12px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-left: max(16px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-left: max(20px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-left: max(24px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-left: max(28px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-left: max(32px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-left: max(36px, env(safe-area-inset-left)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-left: max(40px, env(safe-area-inset-left)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-left: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-left: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-left: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-left: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-left: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-left: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-left: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-left: 40px !important;
    }
  }
}

@supports (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-right: max(12px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-right: max(16px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-right: max(20px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-right: max(24px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-right: max(28px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-right: max(32px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-right: max(36px, env(safe-area-inset-right)) !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-right: max(40px, env(safe-area-inset-right)) !important;
    }
  }
}

@supports not (padding: max(0px)) {
  @media only screen and (max-width: 319px) {
    .slidingSidebarLink {
      padding-right: 12px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 419px) {
    .slidingSidebarLink {
      padding-right: 16px !important;
    }
  }
  @media only screen and (min-width: 420px) and (max-width: 767px) {
    .slidingSidebarLink {
      padding-right: 20px !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 899px) {
    .slidingSidebarLink {
      padding-right: 24px !important;
    }
  }
  @media only screen and (min-width: 900px) and (max-width: 1199px) {
    .slidingSidebarLink {
      padding-right: 28px !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .slidingSidebarLink {
      padding-right: 32px !important;
    }
  }
  @media only screen and (min-width: 1441px) and (max-width: 1709px) {
    .slidingSidebarLink {
      padding-right: 36px !important;
    }
  }
  @media only screen and (min-width: 1710px) {
    .slidingSidebarLink {
      padding-right: 40px !important;
    }
  }
}

@media all and (hover: hover) {
  .slidingSidebarLink:hover {
    background-color: #eee;
  }
}

@media all and (pointer: coarse) {
  .slidingSidebarLink:active,
  .slidingSidebarLink:target {
    background-color: #eee;
  }
}

.currentSlidingSidebarLink {
  color: #5aa5ef;
}

.slidingSidebarLinkIcon {
  height: 23px;
  width: 23px;
}

.slidingSidebarLinkName {
  font-size: 15px;
  margin-left: 21.5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slidingSidebarChildLink:not(.currentSlidingSidebarLink) {
  color: #777777;
}

.slidingSidebarChildLink .slidingSidebarLinkName {
  font-size: 15px;
  margin-left: 72px;
}

.chevronUp {
  margin-left: 12px;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}
