.app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  width: 100%;
}
.hideOrShow {
  background-color: white !important;
    color: black !important;
    padding: 15px;
    gap: 10px;
    border-radius: 33%;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 19999999;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
.Sppped {
  background-color: white !important;
  color: black !important;
  padding: 8px 15px;
  grid-gap: 10px;
  gap: 10px;
  width: max-content;
  font-size: 16px;
  border-radius: 50px 50px 50px 50px;
  display: flex;
  border: solid 2px #5aa5ef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 19999999;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  margin-top: 0;
}
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  50%{
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}

.fullSpeed {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100000000;
  gap: 10px;
  right: 3%;
  bottom: 15px;
  opacity: 1;
  animation-fill-mode: forwards;
  animation: slideIn 0.3s ease-out;
  transform: translateX(0); /* Initial state for the transform */
}

@media only screen and (max-width: 1199px) {
  .hideOrShow {
    right: 20%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .hideOrShow {
    right: 12%;
  }
}
.hideOrShow > img {
  width: 20px;
}
.toastContainerStyle button {
  margin-top: 0 !important;
}
/* Initial state for toastContainerShow */
.toastContainerShow {
  display: block;
  opacity: 0;
  transform: translateY(100%);
  animation: toastShow 0.5s ease-in-out forwards;
  max-height: 400px;
  overflow-y: scroll;
}

.toastContainerShow::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
}

.toastContainerShow::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners of the thumb */
}

.toastContainerShow::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
}


/* Initial state for toastContainerNone */
.toastContainerNone {
  /* display: none; */
  opacity: 1;
  transform: translateY(0); /* Initial state is fully visible */
  animation: toastHide 0.5s ease-in-out forwards;
}

@keyframes toastShow {
  0% {
    opacity: 0;
    transform: translateY(100%); /* Start from below the viewport */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Slide in from the bottom */
  }
}

@keyframes toastHide {
  0% {
    opacity: 1;
    transform: translateY(0); /* Start at the current position */
  }
  100% {
    opacity: 0;
    transform: translateY(100%); /* Slide out to below the viewport */
  }
}


.autoSignInSpinnerContainer {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: radial-gradient(circle at center, #1f2330, #07080c);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.changingTheme *,
.changingTheme *::before,
.changingTheme *::after {
  -webkit-transition-property: none !important;
  transition-property: none !important;
}
.dialogContent {
  text-align: center !important;
  white-space: pre-line;
  padding: 20px;
}

.dialogTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4caf50;
  text-align: center;
}

.dialogButton {
  display: block !important;
  margin: 0px auto 20px !important;
  padding: 10px 20px !important;
  font-size: 1rem;
  background-color: #4caf50 !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer;
  transition: background 0.3s ease !important;
}

.dialogButton:hover {
  background-color: #388e3c;
}
