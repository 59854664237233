.heroContainer {
  margin: 48px auto 0 auto;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .heroContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.heroImageContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 899px) {
  .heroImageContainer.strechEdge {
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .heroImageContainer.strechEdge {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .heroImageContainer.strechEdge {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroImageContainer.strechEdge {
    min-height: 414px;
  }
}

.heroImage {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  /* opacity: 0; */
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .heroImage {
    height: 399px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroImage {
    height: 100%;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroImage {
    height: 473.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .heroImage {
    height: 532px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .heroImageContainer,
  .heroText {
    width: calc(50% - (50px / 2));
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .heroImageContainer,
  .heroText {
    width: calc(50% - (60px / 2));
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroImageContainer,
  .heroText {
    width: calc(50% - (71.25px / 2));
  }
}

@media only screen and (min-width: 1710px) {
  .heroImageContainer,
  .heroText {
    width: calc(50% - (80px / 2));
  }
}

.heroText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heroHeading {
  color: #1172d2;
  font-weight: normal;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 899px) {
  .heroHeading {
    font-size: 26.875px;
    margin-bottom: 16.25px;
    margin-top: 28px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroHeading {
    font-size: 32.25px;
    margin-bottom: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroHeading {
    font-size: 39.421875px;
    margin-bottom: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .heroHeading {
    font-size: 45px;
    margin-bottom: 26px;
  }
}

.heroSubheading {
  color: #fff;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  text-transform: capitalize;
}

@media only screen and (max-width: 899px) {
  .heroSubheading {
    font-size: 26.875px;
    margin-bottom: 16.25px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroSubheading {
    font-size: 32.25px;
    line-height: 30px !important;
    margin-bottom: 19.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroSubheading {
    font-size: 39.421875px;
    line-height: 40px !important;
    margin-bottom: 23.15625px;
  }
}

@media only screen and (min-width: 1710px) {
  .heroSubheading {
    font-size: 45px;
    line-height: 40px !important;
    margin-bottom: 26px;
  }
}

.heroDescription {
  color: #cacaca;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
}

@media only screen and (max-width: 899px) {
  .heroDescription {
    font-size: 17.5px;
    margin-bottom: 16.25px;
    margin-top: 12.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .heroDescription {
    font-size: 22px;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .heroDescription {
    font-size: 28px;
    margin-top: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .heroDescription {
    font-size: 28px;
    margin-top: 20px;
  }
}

.planPricingDetails .planPricingDetailsButton {
  border-width: 2px;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 auto;
  width: 80%;
  text-transform: capitalize !important;
}

@media only screen and (max-width: 767px) {
  .planPricingDetails .planPricingDetailsButton {
    line-height: 1.2;
    width: 100% !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .planPricingDetails .planPricingDetailsButton {
    height: 64px;
    line-height: 1.2;
  }
}

.planPricingDetails .signUpLink {
  color: #fff;
  display: block;
  font-weight: 300;
  letter-spacing: 0.75px;
  margin-top: 3em;
  text-align: center;
  text-decoration: underline;
}

.devices {
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .devices {
    max-width: 512px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .devices {
    max-width: 900px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .devices {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .devices {
    max-width: 1408px;
  }
}

@media only screen and (min-width: 1710px) {
  .devices {
    max-width: 1536px;
  }
}

.devicesTitle {
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .devicesTitle {
    font-size: 20px;
    margin-bottom: 33.599999999999994px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .devicesTitle {
    font-size: 20px;
    margin-bottom: 39.375px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .devicesTitle {
    font-size: 22.875px;
    margin-bottom: 52.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .devicesTitle {
    font-size: 26.25px;
    margin-bottom: 62.99999999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .devicesTitle {
    font-size: 31.171875000000004px;
    margin-bottom: 74.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .devicesTitle {
    font-size: 35px;
    margin-bottom: 84px;
  }
}

@media only screen and (max-width: 767px) {
  .deviceList {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .deviceList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.deviceItem {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .deviceItem {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .deviceItem {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
  }
}

.deviceFrame {
  color: #fff;
  height: 128px;
  max-width: 128px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center bottom;
  object-position: center bottom;
  position: relative;
  width: 100%;
}

.deviceCategory {
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}

@media only screen and (max-width: 1440px) {
  .deviceCategory {
    font-size: 21px;
  }
}

@media only screen and (max-width: 1440px) {
  .deviceCategory {
    margin: 33.28125px 0 26.71875px 0;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .deviceCategory {
    margin: 44.375px 0 35.625px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .deviceCategory {
    margin: 53.25px 0 42.75px 0;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .deviceCategory {
    font-size: 24.9375px;
    margin: 63.234375px 0 50.765625px 0;
  }
}

@media only screen and (min-width: 1710px) {
  .deviceCategory {
    font-size: 28px;
    margin: 71px 0 57px 0;
  }
}

.deviceNameList {
  position: relative;
}

.deviceName {
  color: #cecece;
  font-weight: 300;
}

@media only screen and (max-width: 1199px) {
  .deviceName {
    font-size: 16px;
    margin-bottom: 3px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .deviceName {
    font-size: 16.5px;
    margin-bottom: 3.33px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .deviceName {
    font-size: 19.59375px;
    margin-bottom: 3.7px;
  }
}

@media only screen and (min-width: 1710px) {
  .deviceName {
    font-size: 22px;
    margin-bottom: 4px;
  }
}

.pageEndDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url(../../../../Assets/FooterImg.png);
  align-items: center;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .pageEndDetails {
    /* margin-bottom: 25.6px; */
    /* margin-top: 76.80000000000001px; */
    height: 50vh;
    background-size: 100% 100%;
  }
}

/* 
@media only screen and (min-width: 768px) and (max-width: 899px) {
  .pageEndDetails {
    margin-bottom: 30px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageEndDetails {
    margin-bottom: 40px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageEndDetails {
    margin-bottom: 48px;
    margin-top: 144px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageEndDetails {
    margin-bottom: 57px;
    margin-top: 171px;
  }
}

@media only screen and (min-width: 1710px) {
  .pageEndDetails {
    margin-bottom: 64px;
    margin-top: 192px;
  }
}
*/
.pageEndSignUpLink {
  letter-spacing: 0.5px;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .pageEndSignUpLink {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
  .pageEndSignUpLink {
    font-size: 12px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .pageEndSignUpLink {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .pageEndSignUpLink {
    font-size: 16px !important;
    height: 40px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .pageEndSignUpLink {
    font-size: 20px !important;
    height: 48px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .pageEndSignUpLink {
    font-size: 24px !important;
    height: 56px !important;
  }
}

.brandName {
  height: 67px;
}
