.reactPlayerWrapper {
    height: 70vh;
}

.reactPlayerWrapper video {
    object-fit: fill;
}

.playButton {
    position: absolute;
    top: 50%;
    right: 50%;
    font-size: x-large !important;
    transform: translate(50%, -50%);
    height: 3em !important;
    width: 3em !important;
    z-index: 2;
    color: gray;
}

.openButton {
    color: white !important;
    border-radius: 30px !important;
    text-decoration: none;
    text-transform: none !important;
}

.logoMini{
    width:24px;
    height: 24px;
}

.openAppTitle {
    font-size: 14px !important;
    text-align: left;
    font-weight: 600 !important;
    color: white !important;
}

.openAppCaption {
    font-size: 12px !important;
    text-align: left;
    color: rgb(184, 182, 182);
}

@media (max-width: 600px) {
    .openInAppContainer {
        display: flex !important;
    }
}