/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .newCategoryContainer {
    font-size: 11.25px;
  }
}
.customDropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  cursor: pointer;
  user-select: none;
}
.selectedOption {
  font-size: 11px;
    margin-left: 5px;
    font-weight: 400;
}
.dropdownHeader {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownArrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  max-height: 350px;
  overflow-y: auto;
  list-style-type: none;
}

.dropdownItem {
  padding: 10px;
  transition: background-color 0.2s ease;
}

.dropdownItem:hover {
  background-color: #e0e0e0;
}

.dropdownItem.selected {
  background-color: #d0d0d0;
  font-weight: bold;
}

.linex {
  height: 0.5px;
  background: #e1e2eb;
  opacity: 0.1;
  margin: 0;
  width: 89%;
  position: fixed;
  margin-right: 60px;
  margin-top: -3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.linexr {
  height: 0.5px;
  background: #e1e2eb;
  opacity: 0.3;
  margin: 0;
  width: 89%;
  position: fixed;
  margin-right: 60px;
  margin-top: -3px;
}
.sticlyMe {
  position: sticky;
  top: 20%;
  z-index: 1000;
}
.actionButton {
  color: #000 !important;
}
.search_input {
  padding: 10px;
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 4px; /* Add rounded corners */
  font-size: 16px;
  width: 200px; /* Adjust the width as needed */
}
.search_container {
  display: flex;
  align-items: center;
}
.search_button {
  padding: 10px 11px;
  background-color: #5aa5ef;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-size: 17px;
  border-radius: 11px;
  cursor: pointer;
  margin-left: 10px;
}
.search_button > img {
  width: 20px;
}
.search_input::placeholder {
  font-family: "Poppins";
  font-size: 17px;
}
.flexBorad {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.droperSelection {
  width: max-content;
  display: flex;
  height: auto;
  background-color: #5aa5ef;
  padding: 4px 6px;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border: none;
  border-radius: 8px;
}

.droperMain {
  cursor: pointer;
  text-align: center;
  color: white;
  display: flex;
  font-weight: 600;
  height: 100%;
  font-family: "Poppins";
  background-color: #5aa5ef;
  border: none;
  border-radius: 8px;
  font-size: 17px;
}
.droperMain > option {
  border: none;
  font-size: 17px;
  font-family: "Poppins";
  color: black;
  background-color: white;
  font-weight: 600;
}
.fiterRemover {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.dropdiv {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 60px;
  margin-bottom: 18px;
  padding-left: 10px;
}
.cancelPlz {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  font-family: "Poppins";
  color: #5aa5ef;
  background-color: white;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
}
.cancelPlz:hover {
  margin-top: 0;
  border-radius: 12px 12px 12px 12px;
  height: auto;
  padding: 5px;
  color: white;
  background-color: #5aa5ef;
  border: 1px solid #5aa5ef;
  cursor: pointer;
  font-size: 12px;
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .newCategoryContainer {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .newCategoryContainer {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .newCategoryContainer {
    font-size: 18px;
  }
}

.newCategoryInput {
  border: 1px solid #000;
  display: block;
  font-size: inherit;
  margin: 0;
  padding: 6px 12px;
  width: 100%;
}

.newCategoryBar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.newCategoryButton {
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.newCategoryButtonCreate {
  background-color: #000;
  color: #fff;
  line-height: 1.5;
  margin-top: 1em;
  padding: 0 16px;
}

.preLoadDataContainer {
  position: relative;
}

.preLoadData {
  position: relative;
}

.preloadDataContent {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4em;
  position: relative;
  white-space: nowrap;
}

.fixedPreferenceContainer {
  --useless: none;
}

.cancelEditMenu {
  color: #5aa5ef;
  font-weight: 500;
}

@media all and (hover: hover) {
  .cancelEditMenu:hover {
    text-decoration: underline;
  }
}

@media all and (pointer: coarse) {
  .cancelEditMenu:active,
  .cancelEditMenu:target {
    text-decoration: underline;
  }
}

.page {
  background-color: #ffffff;
}

.preferences {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 !important;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.theFlex {
  display: flex;
  flex-direction: row;
}
.preferences .headerCell {
  border: none !important;
}

.preferenceContainerFill {
  display: none;
  position: relative;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .preferencesContent,
  .preferenceContainerFill {
    height: 51.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .preferencesContent,
  .preferenceContainerFill {
    height: 61.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .preferencesContent,
  .preferenceContainerFill {
    height: 73.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .preferencesContent,
  .preferenceContainerFill {
    height: 82px;
  }
}

.preferencesContent {
  width: 100%;
}

.preferencesContent,
.preferencesContentButtons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prefOptions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: flex;
  gap: 28px;
  margin-left: 35px;
}
.editOptions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: flex;
  gap: 28px;
}
.numVideosSelected {
  color: #fff;
}
.numVideosSelected:hover {
  color: #449ce1;
}
.prefDropDown {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
}

.prefDropDownText {
  color: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .numVideosSelected,
  .prefDropDownText {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .numVideosSelected,
  .prefDropDownText {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .numVideosSelected,
  .prefDropDownText {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .numVideosSelected,
  .prefDropDownText {
    font-size: 20px;
  }
}

.prefDropDownIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff33;
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .prefDropDownIcon {
    font-size: 12.5px;
    height: 19.375000000000004px;
    margin-left: 3.75px;
    width: 19.375000000000004px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .prefDropDownIcon {
    font-size: 15px;
    height: 23.250000000000004px;
    margin-left: 4.5px;
    width: 23.250000000000004px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .prefDropDownIcon {
    font-size: 17.8125px;
    height: 27.609375000000004px;
    margin-left: 5.34375px;
    width: 27.609375000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .prefDropDownIcon {
    font-size: 20px;
    height: 31px;
    margin-left: 6px;
    width: 31px;
  }
}

.prefDropDownIcon.prefDropDownActive {
  background-color: #ffffffd1;
  color: #000;
}

.prefDropdownScrim {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.preferenceContainer {
  border-bottom: 0 !important;
  position: relative;
  width: 100%;
  z-index: 97;
}

.categoriesMenuOptionsContainer {
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  left: 0 !important;
  max-height: 200px;
  overflow-y: auto;
  -webkit-transform: none !important;
  transform: none !important;
  width: 80%;
}

.categoriesMenuOption {
  cursor: pointer;
}

.categoriesMenuOption.categoriesMenuOptionAdd {
  background-color: transparent;
  border: none;
  color: #5aa5ef;
  display: block;
  font-weight: 500;
  line-height: 2;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoriesMenuOption,
  .categoriesMenuOption.categoriesMenuOptionAdd {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoriesMenuOption,
  .categoriesMenuOption.categoriesMenuOptionAdd {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoriesMenuOption,
  .categoriesMenuOption.categoriesMenuOptionAdd {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoriesMenuOption,
  .categoriesMenuOption.categoriesMenuOptionAdd {
    font-size: 18px;
  }
}

.categoriesMenuOptionsContainer,
.optionsContainer {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  left: 50%;
  line-height: 2.2;
  position: absolute;
  top: calc(100% + 4px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 120;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoriesMenuOptionsContainer,
  .optionsContainer {
    border-radius: 6.25px;
    font-size: 11.25px;
    padding: 11.25px 22.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoriesMenuOptionsContainer,
  .optionsContainer {
    border-radius: 7.5px;
    font-size: 13.5px;
    padding: 13.5px 27px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoriesMenuOptionsContainer,
  .optionsContainer {
    border-radius: 8.90625px;
    font-size: 16.03125px;
    padding: 16px 32.0625px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoriesMenuOptionsContainer,
  .optionsContainer {
    border-radius: 10px;
    font-size: 18px;
    padding: 18px 36px;
  }
}

.option {
  white-space: nowrap;
}

.option:hover {
  color: #579ce1;
}

.categoriesMenuContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.categoriesMenu {
  margin-right: 5%;
  position: relative;
  width: 100%;
}

.categoriesMenuTitle,
.categoriesMenuInput {
  display: block;
  width: 100%;
}

.editMenu {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  color: #000;
  left: 0;
  position: relative;
  top: 100%;
  width: 100%;
  z-index: 5;
}

.editMenu,
.editMenu * {
  font-family: "Poppins", sans-serif !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editMenu {
    padding: 0 29.375px 29.375px 29.375px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editMenu {
    padding: 0 35.25px 35.25px 35.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editMenu {
    padding: 0 41.859375px 41.859375px 41.859375px;
  }
}

@media only screen and (min-width: 1710px) {
  .editMenu {
    padding: 0 47px 47px 47px;
  }
}

.editMenuUpdateButton {
  background-color: #000;
  border: none;
  color: #fff;
  line-height: 2;
  margin: 0;
  padding: 0 22px;
}

.editMenuUpdateButton:disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

.editMenuUpdateButton:not(:disabled) {
  cursor: pointer;
}

@media all and (hover: hover) {
  .editMenuUpdateButton:not(:disabled):hover {
    opacity: 0.8;
  }
}

@media all and (pointer: coarse) {
  .editMenuUpdateButton:not(:disabled):active,
  .editMenuUpdateButton:not(:disabled):target {
    opacity: 0.8;
  }
}

.editMenuTitle,
.editMenuInput {
  font-family: inherit !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editMenuTitle,
  .editMenuInput {
    width: 423.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editMenuTitle,
  .editMenuInput {
    width: 508.50000000000006px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editMenuTitle,
  .editMenuInput {
    width: 603.84375px;
  }
}

@media only screen and (min-width: 1710px) {
  .editMenuTitle,
  .editMenuInput {
    width: 678px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editMenuTitle,
  .categoriesMenuTitle,
  .editMenuUpdateButton {
    font-size: 13.749999999999998px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editMenuTitle,
  .categoriesMenuTitle,
  .editMenuUpdateButton {
    font-size: 16.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editMenuTitle,
  .categoriesMenuTitle,
  .editMenuUpdateButton {
    font-size: 19.59375px;
  }
}

@media only screen and (min-width: 1710px) {
  .editMenuTitle,
  .categoriesMenuTitle,
  .editMenuUpdateButton {
    font-size: 22px;
  }
}

.editMenuTitle,
.categoriesMenuTitle {
  font-weight: 500;
  line-height: 2.5;
}

.categoriesMenuInput {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #8b8b8b;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 2.5% !important;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoriesMenuInput {
    height: 40.625px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoriesMenuInput {
    height: 48.75px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoriesMenuInput {
    height: 57.89062499999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoriesMenuInput {
    height: 65px;
  }
}

.categoriesMenuInputIcon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoriesMenuInputIcon {
    font-size: 12.5px !important;
    height: 32px;
    width: 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoriesMenuInputIcon {
    font-size: 15px !important;
    height: 38px;
    width: 38px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoriesMenuInputIcon {
    font-size: 17.8125px !important;
    height: 45.5px;
    width: 45.5px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoriesMenuInputIcon {
    font-size: 20px !important;
    height: 51px;
    width: 51px;
  }
}

.editMenuInput {
  resize: none;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .editMenuInput {
    height: 88.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .editMenuInput {
    height: 106.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .editMenuInput {
    height: 126.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .editMenuInput {
    height: 142px;
  }
}

.categoriesMenuInput,
.editMenuInput {
  border: 1px solid #707070;
  outline: none;
  padding: 4px;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .categoriesMenuInput,
  .editMenuInput {
    font-size: 11.25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .categoriesMenuInput,
  .editMenuInput {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .categoriesMenuInput,
  .editMenuInput {
    font-size: 16.03125px;
  }
}

@media only screen and (min-width: 1710px) {
  .categoriesMenuInput,
  .editMenuInput {
    font-size: 18px;
  }
}

.closeButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}

@media all and (hover: hover) {
  .closeButton:hover {
    background-color: #000;
    color: #fff;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media all and (pointer: coarse) {
  .closeButton:active,
  .closeButton:target {
    background-color: #000;
    color: #fff;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .closeButton {
    font-size: 21.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .closeButton {
    font-size: 26.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .closeButton {
    font-size: 31.171875000000004px;
  }
}

@media only screen and (min-width: 1710px) {
  .closeButton {
    font-size: 35px;
  }
}

.filterBar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 899px) {
  .filterBar {
    background-color: #f4f4f4;
    height: 40px;
    padding: 0 12px;
    width: 100vw !important;
  }
  @supports (padding: max(0px)) {
    .filterBar {
      margin-left: min(
        calc(-1 * var(--site-padding)),
        calc(-1 * env(safe-area-inset-left))
      );
      max-width: calc(
        100% + max(var(--site-padding), env(safe-area-inset-left)) +
          max(var(--site-padding), env(safe-area-inset-right))
      );
    }
  }
  @supports not (padding: max(0px)) {
    .filterBar {
      margin-left: var(--site-padding);
      max-width: calc(100% + (2 * var(--site-padding)));
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .filterBar {
    height: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .filterBar {
    height: 41.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .filterBar {
    height: 48.984375px;
  }
}

@media only screen and (min-width: 1710px) {
  .filterBar {
    height: 55px;
  }
}

@media only screen and (max-width: 899px) {
  .filterButtonIcon {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .filterButtonIcon {
    font-size: 18.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .filterButtonIcon {
    font-size: 22.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .filterButtonIcon {
    font-size: 26.71875px;
  }
}

@media only screen and (min-width: 1710px) {
  .filterButtonIcon {
    font-size: 30px;
  }
}

@media only screen and (max-width: 899px) {
  .filterButtonText {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .filterButtonText {
    font-size: 12.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .filterButtonText {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .filterButtonText {
    font-size: 17.8125px;
  }
}

@media only screen and (min-width: 1710px) {
  .filterButtonText {
    font-size: 20px;
  }
}

.filterButtonText {
  color: #b7b7b7;
  line-height: 1.5;
}

@media only screen and (max-width: 899px) {
  .filterButtonText {
    display: none;
  }
}

.filterButton {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 899px) {
  .filterButtonIcon {
    color: #909090;
  }
}

@media only screen and (min-width: 900px) {
  .filterButtonIcon {
    color: #5aa5ef !important;
  }
}

@media only screen and (min-width: 900px) {
  .filterBarOrder {
    display: none;
  }
}

.pageContent {
  overflow-y: hidden;
  padding-left: 103px;
}

.videoListContainer::-webkit-scrollbar {
  width: 80px; /* Adjust this to make the scrollbar thinner */
  height: 8px; /* Adjust this to make the scrollbar thinner */
}
.videoListContainer {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 57vh;
  min-height: 57vh;
  width: 100%;
}
.tableHeaderSticky {
  position: sticky;
  top: -1px;
  z-index: 90;
  background: #faf8f8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1); /* Add a box shadow at the bottom */
}
.tableBody {
  overflow: auto;
  max-height: 100px;
}
@media only screen and (max-width: 899px) {
  .pageTitle {
    display: none !important;
  }
  .pageContent {
    padding-top: 0 !important;
    padding-left: 10px;
  }
  .tableHeader {
    display: none !important;
  }
  .tableBody * {
    border: none !important;
  }
  .tableRow:first-of-type .videoPreviewCell {
    padding-top: 18px !important;
  }
  .videoPreviewCell {
    padding-bottom: 9px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 9px !important;
  }
  .bodyCell:not(.videoPreviewCell) {
    display: none !important;
  }
}

@media only screen and (max-width: 899px) {
  .headerCell {
    font-size: 15px !important;
    height: 60.5px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .headerCell {
    font-size: 15px !important;
    height: 37.8125px !important;
  }
  .headerCell.checkboxCell {
    width: 40.3125px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .headerCell {
    font-size: 15px !important;
    height: 45.375px !important;
  }
  .headerCell.checkboxCell {
    width: 48.375px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .headerCell {
    font-size: 17.8125px !important;
    height: 53.8828125px !important;
  }
  .headerCell.checkboxCell {
    width: 57.44531px;
  }
}

@media only screen and (min-width: 1710px) {
  .headerCell {
    font-size: 20px !important;
    height: 60.5px !important;
  }
  .headerCell.checkboxCell {
    width: 64.5px;
  }
}

.bodyCell {
  font-size: 20px;
}

.bodyCell.videoPreviewCell {
  border-right-style: solid;
  border-right-width: 1px;
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .bodyCell {
    height: 86.56249999999999px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .bodyCell {
    height: 103.87499999999999px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .bodyCell {
    height: 123.35156249999999px;
  }
}

@media only screen and (min-width: 1710px) {
  .bodyCell {
    height: 138.5px;
  }
}

@media only screen and (min-width: 900px) {
  .selectedRow {
    background-color: #ededed;
  }
}

.previewContainer,
.previewText {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.previewImage {
  background-color: #0002;
  border-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

@media only screen and (max-width: 899px) {
  .previewImage {
    height: 78px;
    width: 145.8px;
  }
  .albumImage {
    width: 72px !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .previewImage,
  .previewText {
    height: 66.875px;
    width: 125px;
  }
  .albumImage {
    width: 62.5px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .previewImage,
  .previewText {
    height: 80.25px;
    width: 150px;
  }
  .albumImage {
    width: 75px !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewImage,
  .previewText {
    height: 95.296875px;
    width: 178.125px;
  }
  .albumImage {
    width: 89px !important;
  }
}

@media only screen and (min-width: 1710px) {
  .previewImage,
  .previewText {
    height: 107px;
    width: 200px;
  }
  .albumImage {
    width: 100px !important;
  }
}

.previewText {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 899px) {
  .previewText {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .previewText {
    margin-left: 17.5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .previewText {
    margin-left: 21px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewText {
    margin-left: 24.9375px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewText {
    margin-left: 28px;
  }
}

.previewTitle::first-letter,
.previewDescription::first-letter {
  text-transform: capitalize;
}

.previewTitle {
  color: #02071a;
  font-weight: 600;
  line-height: 1.5;
  opacity: 0.72;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.previewDescription {
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}
@media only screen and (max-width: 899px) {
  .previewTitle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .previewTitle {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .previewTitle {
    font-size: 14.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewTitle {
    font-size: 16.921875px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewTitle {
    font-size: 19px;
  }
}

.previewDescription {
  color: #02071a;
  line-height: 1.2;
  opacity: 0.9;
}

@media only screen and (max-width: 899px) {
  .previewDescription {
    font-size: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .tableHeaderSticky {
    display: none;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .previewDescription {
    font-size: 8.75px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .previewDescription {
    font-size: 10.5px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .previewDescription {
    font-size: 12.46875px;
  }
}

@media only screen and (min-width: 1710px) {
  .previewDescription {
    font-size: 14px;
  }
}

.previewTitle,
.previewDescription {
  display: block;
  text-align: left;
  width: 100%;
}

.checkbox {
  background-color: #0002;
  border-radius: 50%;
  cursor: pointer;
  height: 44px;
  position: relative;
  width: 44px;
  border: 1px solid !important;
}

.checkbox.checked,
.checkbox.indeterminate {
  background-color: #000;
  /* border-color: #fff; */
  border-style: solid;
}

.checkbox.indeterminate.header {
  border-color: #000;
}

.checkbox.indeterminate::after {
  background-color: #fff;
  content: " ";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
}

@media only screen and (max-width: 899px) {
  .checkbox {
    border-width: 3.75px;
    height: 26.875px;
    width: 26.875px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .checkbox {
    border-width: 3.75px;
    height: 26.875px;
    width: 26.875px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .checkbox {
    border-width: 4.5px;
    height: 32.25px;
    width: 32.25px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .checkbox {
    border-width: 5.34375px;
    height: 38.296875px;
    width: 38.296875px;
  }
}

@media only screen and (min-width: 1710px) {
  .checkbox {
    border-width: 6px;
    height: 43px;
    width: 43px;
  }
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexLeft {
  justify-content: left !important;
}

.headerTheme {
  background-color: black;
}

.expandIcon {
  vertical-align: middle;
  color: #1172d2;
  font-size: 45px !important;
  cursor: pointer;
  position: relative;
  left: 112px;
  bottom: 25px;
}

.albumPhotoContainer {
  display: flex;
  justify-content: left;
  margin: 23px 24px;
}

.photoStyle {
  display: inline-block;
}

.showImage {
  width: 200px !important;
  margin-right: 22px;
}

.photolistWrapper {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (max-width: 899px) {
  .photolistWrapper {
    width: 870px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
  .photolistWrapper {
    width: 850px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .photolistWrapper {
    width: 1151px;
  }
  .showImage {
    width: 180px !important;
    margin-right: 22px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .photolistWrapper {
    width: 100%;
  }
  .showImage {
    width: 188px !important;
    margin-right: 22px;
  }
}

@media only screen and (min-width: 1710px) {
  .photolistWrapper {
    width: 1400px;
  }
}

.addMorePhotoContainer {
  position: relative;
  float: left;
  margin-left: 4px;
}
.addMorePhoto {
  vertical-align: middle;
  color: #02071a;
  font-weight: 600;
  font-family: "Poppins";
  font-size: 13px;
}
.addMoreIcon {
  vertical-align: middle;
  width: 16px !important;
}

/* .titleAndIcon{
  display: inline-flex;
  
} */

.customArrow {
  background-color: #707070;
  width: 3px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dotFlashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashingAnimated 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dotFlashing::before,
.dotFlashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dotFlashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashingAnimated 1s infinite alternate;
  animation-delay: 0s;
}

.dotFlashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashingAnimated 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashingAnimated {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.pageLink:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.pageLink {
  padding: 0.375rem 0.75rem;
}

.pageLink {
  font-size: 15px;
  font-weight: 600;
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pageItem.active .pageLink {
  z-index: 3;
  color: #fff;
  background-color: #000;
  border-color: #fff;
}

.pageItem:not(:first-child) .pageLink {
  margin-left: -1px;
}

.pageItem {
  cursor: pointer !important;
}

.editMouseOver:hover {
  color: #579ce1;
}

.prefDropDownText:hover {
  color: #579ce1;
}

.progressIndicatorTitle {
  font-family: "Montserrat-Bold" !important;
  white-space: nowrap;
}

.progressIndicatorSubTitle {
  font-family: "Montserrat-Regular" !important;
  white-space: nowrap;
}

.expand {
  margin-left: 15px;
  border: 1px solid;
  border-radius: 25px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.processingVideo {
  background-color: rgb(225, 225, 225);
}

.dummyLoaderImageCaption {
  width: 100%;
}
