.inputField,
form .card-element {
  border-color: #bdc4c9;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  outline-color: #1172d2;
}

@media only screen and (max-width: 899px) {
  .inputField,
  form .card-element {
    border-radius: 3.125px;
    border-width: 0.46875px;
    font-size: 10px;
    height: 30px;
    line-height: 21px;
    padding: 0 7.5px;
  }
}
@media only screen and (max-width: 480px) {
  .inputField {
    font-size: 12px !important;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1440px) {
  .inputField,
  form .card-element {
    border-radius: 3.75px;
    border-width: 0.5625px;
    font-size: 11.25px;
    height: 36px;
    line-height: 21px;
    padding: 0 9px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1709px) {
  .inputField,
  form .card-element {
    border-radius: 4.453125px;
    border-width: 0.66796875px;
    font-size: 13.359375px;
    height: 42.75px;
    line-height: 21px;
    padding: 0 10.6875px;
  }
}

@media only screen and (min-width: 1710px) {
  .inputField,
  form .card-element {
    border-radius: 5px;
    border-width: 0.75px;
    font-size: 15px;
    height: 48px;
    line-height: 21px;
    padding: 0 12px;
  }
}

.inputField,
form .card-element::-webkit-input-placeholder {
  color: #767676;
}

.inputField,
form .card-element:-ms-input-placeholder {
  color: #767676;
}

.inputField,
form .card-element::-ms-input-placeholder {
  color: #767676;
}

.inputField,
form .card-element::placeholder {
  color: #767676;
}
